import React, { ChangeEvent } from 'react';

import { Formik } from 'formik';
import { patchAccount } from 'api/request.api';
import { CloseIcon } from 'common/components/icons';
import useLoanAccount from 'auth/hooks/useLoanAccount';
import { IInitialCounter } from 'account/hooks/useAccountDetail';
import CircularSpinner from 'common/components/spinner/circular-spinner';
import { INameValue, MMSelectInput } from 'common/components/input/select.input';

interface IAssociateLoan {
  id: number;
  isClosable?: boolean;
  onClose: () => void;
  associatedLoanId?: number;
  handleRefresh: (key: keyof IInitialCounter) => void;
}

const AssociateLoan: React.FC<IAssociateLoan> = ({
  id,
  onClose,
  handleRefresh,
  associatedLoanId,
  isClosable = true,
}) => {
  const { loanAccounts, fetchingLoanAccount } = useLoanAccount();
  const associateLoan = () => {
    if (fetchingLoanAccount || !loanAccounts) {
      return <CircularSpinner />;
    }

    const loanOptions: INameValue[] = loanAccounts.map((loanAccount) => ({
      name: loanAccount.accountName,
      value: loanAccount.id,
    }));

    return (
      <Formik
        initialValues={{
          associatedLoan: associatedLoanId || '',
        }}
        onSubmit={async (values, actions) => {
          const { data, error } = await patchAccount(`${id}`, values);
          actions.setSubmitting(false);

          if (!error || data) {
            handleRefresh('detail');

            return onClose();
          }
        }}
      >
        {(props) => {
          const { values, setFieldValue, handleSubmit, isSubmitting } = props;

          const handleSelectChange = (e: ChangeEvent<any>) => {
            const value = e.target.value;

            return setFieldValue('associatedLoan', +value);
          };

          return (
            <form className='associate-card__form' onSubmit={handleSubmit}>
              <MMSelectInput
                title={loanOptions.find((datum) => +datum.value === +values.associatedLoan)?.name || ''}
                data={loanOptions}
                name='associatedLoan'
                onChange={handleSelectChange}
                value={values.associatedLoan}
              />
              <div className='associate-card__form--action-wrapper'>
                <button
                  className='mm-btn-animate btn-primary mm-btn-primary'
                  disabled={isSubmitting || !values.associatedLoan}
                >
                  Save
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  };

  return (
    <div className='associate-card-wrapper'>
      {isClosable ? (
        <div className='close-icon'>
          <CloseIcon role='button' onClick={onClose} />
        </div>
      ) : null}
      <h2>Loan</h2>
      <p>Associate a loan to this business</p>
      {associateLoan()}
    </div>
  );
};

export default AssociateLoan;
