import { getManualAccountFormModalField, getManualAccountHoldingsField } from 'auth/auth.helper';
import { TKeyFormField, TPartialFormKeys } from 'auth/auth.types';

const useManualAccountFormModalFields = (type: TKeyFormField) => {
  const formFields = getManualAccountFormModalField(type) || [];

  const getField = (name: string) => {
    return formFields.find((f) => f.name === name);
  };

  const hasFormField = (name: string) => !!getField(name);

  const getFieldTitle = (name: TPartialFormKeys) => getField(name)?.label || '';

  const holdingsFields = getManualAccountHoldingsField(type) || [];

  const getHoldingField = (name: string) => {
    return holdingsFields.find((f) => f.name === name);
  };

  const hasHoldingFormField = (name: string) => !!getHoldingField(name);

  const getHoldingFieldTitle = (name: TPartialFormKeys) => getHoldingField(name)?.label || '';

  return { hasFormField, getField, getFieldTitle, hasHoldingFormField, getHoldingFieldTitle };
};

export default useManualAccountFormModalFields;
