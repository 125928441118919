import React from 'react';

import { Formik } from 'formik';
import { Modal } from 'common/components/modal';
import { IAddEditEntityModal } from 'setting/setting.type';
import useInvestingEntity from 'setting/hooks/useInvestingEntity';

const AddEditEntityModal: React.FC<IAddEditEntityModal> = ({ addEditEntityModal, currentEntity, refresh }) => {
  const title = currentEntity ? 'Edit Entity' : 'Add Entity';
  const { addInvestingEntity, updateInvestingEntity } = useInvestingEntity();

  return (
    <Modal {...addEditEntityModal.props} title={title} size='sm' canBeClosed>
      <div className='modal-wrapper add-edit-entity-modal-wrapper'>
        <Formik
          enableReinitialize
          initialValues={{ name: currentEntity?.name || '' }}
          onSubmit={async (values, action) => {
            if (currentEntity) {
              await updateInvestingEntity(`${currentEntity.id}`, values);
              action.setSubmitting(false);
              refresh();
              action.resetForm();

              return addEditEntityModal.close();
            }

            await addInvestingEntity(values);
            action.setSubmitting(false);
            refresh();
            action.resetForm();

            return addEditEntityModal.close();
          }}
        >
          {(props) => {
            return (
              <form onSubmit={props.handleSubmit}>
                <div className='input-wrapper'>
                  <label className='form-subheading'>Description</label>
                  <div className='input-wrap'>
                    <input
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.name}
                      name='name'
                      placeholder='Entity name'
                    />
                  </div>
                </div>
                <div className='action-wrapper'>
                  <button
                    type='reset'
                    className='btn-outline-primary mm-btn-animate'
                    disabled={props.isSubmitting}
                    onClick={addEditEntityModal.close}
                  >
                    Cancel
                  </button>
                  <button type='submit' className='mm-btn-animate mm-btn-primary' disabled={props.isSubmitting}>
                    Save Entity
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddEditEntityModal;
