import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import { ReactComponent as ConnectedAccountImg } from 'assets/icons/download.svg';
import { ReactComponent as ManualAccountImg } from 'assets/icons/mm-default-provider.svg';

interface IChooseAccount {
  manualLoading: boolean;
  onClose: VoidFunction;
  onLearnMoreClick: VoidFunction;
  onConnectedAccountClick: VoidFunction;
  onManualAccountClick: VoidFunction;
}

const ChooseAccount: React.FC<IChooseAccount> = ({
  manualLoading,
  onClose,
  onLearnMoreClick,
  onConnectedAccountClick,
  onManualAccountClick,
}) => {
  return (
    <div className='choose-account app-modal'>
      <div className='app-modal__header'>
        <h2 className='app-modal__title'>Add Account</h2>

        <button onClick={onClose}>
          <AiOutlineClose className='modal-close' />
        </button>
      </div>

      <p className='app-modal__description'>
        Do you want to connect one of your financial institutions or add an account you can manage manually?
      </p>

      <div className='row mb-4 mb-md-5'>
        <div className='col-12 col-md-6 flex-grow-1'>
          <button
            className='d-flex choose-account__button choose-account__button--connected'
            onClick={() => onConnectedAccountClick()}
          >
            <ConnectedAccountImg />
            <div className='choose-account__button-content'>
              <span>Connected</span>
              <p>Automatically import balances, holdings and transactions.</p>
            </div>
          </button>
        </div>
        <div className='col-12 col-md-6 flex-grow-1'>
          <button
            className='d-flex choose-account__button choose-account__button--manual'
            onClick={() => onManualAccountClick()}
          >
            {manualLoading ? (
              <div className='loading'>
                <span className='spinner-grow' role='status' aria-hidden='true' />
              </div>
            ) : (
              <>
                <ManualAccountImg />
                <div className='choose-account__button-content'>
                  <span>Manual</span>
                  <p>
                    Start with your current balance and take control by entering your own balances, holdings and
                    transactions.
                  </p>
                </div>
              </>
            )}
          </button>
        </div>
      </div>

      <span className='choose-account__help'>
        Connected or Manual?{' '}
        <button type='button' onClick={() => onLearnMoreClick()}>
          Learn more
        </button>
        to help you decide.
      </span>
    </div>
  );
};

export default ChooseAccount;
