import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import { EProviderType } from 'auth/auth.types';
import BBBImg from 'assets/images/home/acc_bus.png';
import { IAccountState } from 'account/account.type';
import { ReactComponent as BackImg } from 'assets/icons/back.svg';
import { ReactComponent as SSLSecure } from 'assets/icons/ssl-secure.svg';
import { ReactComponent as LoginLockIcon } from 'assets/images/login/lock-icon.svg';
import { ReactComponent as NortonImg } from 'assets/images/account/norton_black.svg';
import { ReactComponent as TrustPilotImg } from 'assets/images/account/trustpilot_black.svg';

import ConnectedAccountsOptions from './connected-accounts-options';

interface IConnectedAccountsProps {
  isLoading: boolean;
  zaboLoading: boolean;
  autoLoading: boolean;
  onBack: VoidFunction;
  onClose: VoidFunction;
  state?: IAccountState;
  handleCryptoExchange: VoidFunction;
  handleFastlinkConnection: VoidFunction;
  handleConnectAccountSuccess: (provider: EProviderType) => Promise<void>;
}

const ConnectedAccounts: React.FC<IConnectedAccountsProps> = ({
  state,
  onBack,
  onClose,
  isLoading,
  zaboLoading,
  autoLoading,
  handleCryptoExchange,
  handleFastlinkConnection,
  handleConnectAccountSuccess,
}) => {
  return (
    <>
      <div className='connected-accounts app-modal'>
        <div className='app-modal__header connected-accounts__header'>
          <div className='d-flex align-items-center mt-2'>
            <BackImg role='button' onClick={() => onBack()} />
            <h2 className='app-modal__title'>Connected Accounts</h2>
          </div>

          <button onClick={() => onClose()}>
            <AiOutlineClose className='modal-close' />
          </button>
        </div>

        <p className='app-modal__description'>
          Connected accounts are through our integration partners. We never handle or see your login details.
        </p>

        <ConnectedAccountsOptions
          state={state}
          isLoading={isLoading}
          autoLoading={autoLoading}
          zaboLoading={zaboLoading}
          handleCryptoExchange={handleCryptoExchange}
          handleFastlinkConnection={handleFastlinkConnection}
          handleConnectAccountSuccess={handleConnectAccountSuccess}
        />

        <div className='security-container mb-4'>
          <span className='locked-icon hide-sm'>
            <LoginLockIcon />
          </span>

          <span className='security-link'>
            The security of your information is our top priority (
            <a className='purple-links' href='/security' target='_blank'>
              learn more
            </a>
            )
          </span>
        </div>

        <div className='row align-items-center'>
          <div className='col-6 col-md-2 mb-3 mb-md-0 mr-md-4'>
            <SSLSecure />
          </div>
          <div className='col-6 col-md-2 mb-3 mb-md-0'>
            <NortonImg />
          </div>
          <div className='col-6 col-md-2 '>
            <a href='https://www.trustpilot.com/review/www.moneyminx.com' target='_blank' rel='noopener noreferrer'>
              <TrustPilotImg />
            </a>
          </div>
          <div className='col-6 col-md-2'>
            <img height='38' src={BBBImg} alt='Money Minx Acc Bus' />
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectedAccounts;
