import axios from 'axios';

import appEnv from 'app/app.env';
import { withData, withError } from 'common/common-helper';

const client = axios.create({
  baseURL: appEnv.BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const post = async <D>(url: string, data: D, params = {}) => {
  try {
    const response = await client({
      method: 'post',
      url,
      data,
      params,
    });

    return withData(response.data);
  } catch (error: any) {
    return withError(error?.response?.data);
  }
};

export const get = async <P>(url: string, params?: P) => {
  try {
    const response = await client({
      method: 'get',
      url,
      params,
    });

    return withData(response.data);
  } catch (error: any) {
    return withError(error?.response?.data);
  }
};
