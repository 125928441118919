import React, { useEffect, useState } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import appEnv from 'app/app.env';
import ICONS from 'common/components/icons';
import useToast from 'common/hooks/useToast';
import { useAppState } from 'app/app.context';
import Switch from 'common/components/switch';
import useProfile from 'auth/hooks/useProfile';
import { loadStripe } from '@stripe/stripe-js';
import { TChangeEvent } from 'common/dom.types';
import validation from 'lang/en/validation.json';
import { useModal } from 'common/components/modal';
import ProBadge from 'assets/badges/pro-badge.svg';
import VipBadge from 'assets/badges/vip-badge.svg';
import { enumerateStr } from 'common/common-helper';
import useSettings from 'setting/hooks/useSettings';
import FreeBadge from 'assets/badges/free-badge.svg';
import SaveSettings from 'setting/inc/save-settings';
import PlusBadge from 'assets/badges/plus-badge.svg';
import { SettingPageEnum } from 'setting/setting.type';
import GreenBadge from 'assets/badges/green-badge.svg';
import { updateEmailAddress } from 'auth/auth.actions';
import { initProfitWell } from 'common/profitwell.helper';
import InvestingEntity from 'setting/inc/investing-entity';
import { validateEmail } from 'common/helper/email.helper';
import EnableMFAModal from 'setting/components/enable-mfa-modal';
import { useAuthDispatch, useAuthState } from 'auth/auth.context';
import DisableMFAModal from 'setting/components/disable-mfa-modal';
import CurrencyInput from 'common/components/input/currency.input';
import ChangePasswordModal from 'setting/inc/change-password.modal';
import useCurrentSubscription from 'auth/hooks/useCurrentSubscription';
import CircularSpinner from 'common/components/spinner/circular-spinner';
import { patchEmailSubscription, patchProfile, postUpdateStripePaymentMethod } from 'api/request.api';

import { StripeSubscriptionStatus, EPortfolioUpdateEmailSettings } from '../setting.enum';

const stripePromise = loadStripe(appEnv.STRIPE_PUBLIC_KEY);

interface SettingOverviewProps {
  changeTab: (pageName: SettingPageEnum) => void;
}

export const SettingOverview: React.FC<SettingOverviewProps> = ({ changeTab }) => {
  useProfile();
  const { mmToast } = useToast();
  const { user } = useAuthState();
  const enableMFAModal = useModal();
  const disableMFAModal = useModal();
  const dispatch = useAuthDispatch();
  const changePasswordModal = useModal();
  const { setting: data } = useAppState();
  const { loading, error } = useSettings();
  const { fetchingCurrentSubscription, currentSubscription } = useCurrentSubscription();

  const [currency, setCurrency] = useState<string>('USD');
  const [statusText, setStatusText] = useState('Save Changes');
  const [mailChimpSubscription, setMailChimpSubscription] = useState<boolean>(false);
  const [portfolioUpdateEmailSettings, setPortfolioUpdateEmailSettings] = useState<string>('');
  const [showArchivedAccounts, setShowArchivedAccounts] = useState<boolean>(false);

  const [emailError, setEmailError] = useState('');
  const [email, setEmail] = useState(user?.email || '');

  useEffect(() => {
    if (data) {
      setMailChimpSubscription(data.mailChimpSubscription);
      setCurrency(data.currency);
      setPortfolioUpdateEmailSettings(data.portfolioUpdateEmailSettings);
      setShowArchivedAccounts(data.showArchivedAccounts);
    }
  }, [data]);

  useEffect(() => {
    setEmail(user?.email || '');
  }, [user?.email]);

  if (error) {
    mmToast('Error on fetching settings');
  }

  if (loading || fetchingCurrentSubscription) {
    return <CircularSpinner />;
  }

  const handleSave = async () => {
    setStatusText('Saving...');

    if (user?.email !== email) {
      if (!email) {
        setStatusText('Save Changes');

        return setEmailError(validation.EMAIL_IS_EMPTY);
      }

      if (!validateEmail(email)) {
        setStatusText('Save Changes');

        return setEmailError(validation.INVALID_EMAIL);
      }

      const { error: patchEmailError } = await patchProfile({ email });

      if (patchEmailError) {
        setStatusText('Save Changes');

        return setEmailError(validation.EMAIL_ALREADY_REGISTERED);
      }

      setStatusText('Save Changes');
      initProfitWell(email);
      return dispatch(updateEmailAddress(email));
    }

    const { error: pathError } = await patchEmailSubscription({
      mailChimpSubscription,
      currency,
      portfolioUpdateEmailSettings,
      showArchivedAccounts,
    });

    if (pathError) {
      return mmToast('Error on Adding Subscription', { type: 'error' });
    }

    setStatusText('Saved');

    setTimeout(() => {
      setStatusText('Save Changes');
    }, 1000);
  };

  const handleEmailChange = (e: TChangeEvent) => {
    setEmailError('');

    return setEmail(e.target.value);
  };

  const handleMFAChange = () => {
    const hasMFA = data?.mfaEnabled;

    if (!hasMFA) {
      return enableMFAModal.open();
    }

    return disableMFAModal.open();
  };

  const handlePaymentMethodChange = async () => {
    const stripe = await stripePromise;

    const { data, error } = await postUpdateStripePaymentMethod();

    if (error) {
      return mmToast('Error retrieving stripe checkout id', { type: 'error' });
    }

    const checkoutId = data?.checkoutId;

    if (checkoutId && stripe) {
      const result = await stripe.redirectToCheckout({
        sessionId: checkoutId,
      });

      if (result.error) {
        return mmToast('Something went wrong with Stripe', { type: 'error' });
      }
    }
  };

  // used to determine if the user can choose currency other than USD
  const isFreeUser =
    currentSubscription?.subscriptionStatus !== StripeSubscriptionStatus.TRIALING &&
    currentSubscription?.subscriptionStatus !== StripeSubscriptionStatus.ACTIVE;

  return (
    <section>
      <HelmetProvider>
        <Helmet>
          <title>Settings | Money Minx</title>
        </Helmet>
      </HelmetProvider>
      <div className='card mm-setting-card'>
        <div className='card-body'>
          <form>
            <div className='mm-setting-card--title'>Preferences</div>
            <div className='mm-setting-form form-group mt-3 mb-5 row align-items-center'>
              <label className='col-sm-3 col-md-4 mb-0'>Base currency</label>
              <div className='col-sm-3 col-md-3'>
                <div className='form-wrap currency-select'>
                  {currentSubscription && isFreeUser ? (
                    <span>{currency}</span>
                  ) : (
                    <CurrencyInput
                      value={currency}
                      name='currency'
                      onChange={(currencyValue) => setCurrency(currencyValue)}
                    />
                  )}
                </div>
              </div>
              {currentSubscription && isFreeUser && (
                <label className='col-sm-6 col-md-6 text--pink'>
                  Free accounts are limited to USD. For multi currency support{' '}
                  <a href={'/settings?active=Plan'} className='pink-links'>
                    upgrade to pro.
                  </a>
                </label>
              )}
            </div>

            <div className='mm-setting-form form-group mt-3 mb-5 row align-items-center'>
              <label className='col-sm-3 col-md-4 mb-0'>Portfolio Update</label>
              <div className='col-sm-3 col-md-3'>
                <select
                  className='form-control form-control-lg mr-sm-2'
                  name='portfolioUpdateEmailSettings'
                  id='portfolioUpdateEmailSettings'
                  onChange={(e) => setPortfolioUpdateEmailSettings(e.target.value)}
                  value={portfolioUpdateEmailSettings}
                >
                  {enumerateStr(EPortfolioUpdateEmailSettings).map((emailSetting) => {
                    return (
                      <option
                        value={emailSetting}
                        aria-selected={portfolioUpdateEmailSettings === emailSetting}
                        key={emailSetting}
                      >
                        {emailSetting}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className='mm-setting-form form-group mb-5 mt-3 row'>
              <label className='col-sm-3 col-md-4'>Notifications</label>
              <div className='col-sm-9 col-md-8'>
                <div className='form-wrap'>
                  <span className='checkbox-item'>
                    <label className='check-box'>
                      Get our diversified investor newsletter
                      <input
                        type='checkbox'
                        className='newsletter-checkbox'
                        name='mailChimpSubscription'
                        value='true'
                        aria-checked={mailChimpSubscription}
                        checked={mailChimpSubscription}
                        onChange={() => setMailChimpSubscription(!mailChimpSubscription)}
                      />
                      <span className='geekmark' />
                    </label>
                  </span>
                </div>
              </div>
            </div>

            <div className='mm-setting-form form-group mt-3'>
              <div className='row'>
                <label className='col-sm-3 col-md-4'>Show closed accounts</label>
                <div className='col-sm-9 col-md-8'>
                  <Switch
                    name='account-toggle'
                    checked={showArchivedAccounts}
                    className='mr-4'
                    onClick={setShowArchivedAccounts}
                  />
                </div>
              </div>
              <p className='closed-accounts-description'>
                {showArchivedAccounts
                  ? 'Show closed accounts on the dashboard, net worth and other screens.'
                  : 'Hide closed accounts on the dashboard, net worth and other screens.'}
              </p>
            </div>
          </form>
        </div>
      </div>
      <InvestingEntity />
      <div className='card mm-setting-card'>
        <div className='card-body'>
          <form>
            <div className='mm-setting-card--title'>Security</div>
            <div className='mm-setting-form row'>
              <label className='col-5 col-md-3 col-form-label'>Email Address</label>
              <span className='col-7 col-md-9 mm-setting-form-info col-form-label ml-0 mb-4'>
                <FormControl
                  type='email'
                  value={email}
                  onChange={handleEmailChange}
                  isInvalid={!!emailError}
                  className='form-control__no-icon'
                />
                <div className='feedback text-right'>{emailError}</div>
              </span>
            </div>
            <div className='mm-setting-form form-group row'>
              <label className='col-5 col-md-3 col-form-label'>Password</label>
              <span className='col-7 col-md-3 mm-setting-form-info col-form-label ml-0 mb-4'>
                <ICONS.PASSWORD />
              </span>
              <div className='col-12 col-md-6'>
                <button
                  type='button'
                  className='mm-btn-settings mm-btn-animate float-right'
                  onClick={() => changePasswordModal.open()}
                >
                  Change Password
                </button>
              </div>
            </div>
          </form>

          <div className='mfa-container'>
            <div className='mfa-text-container'>
              <span>Turn on Two Factor Authentication</span>
              <p>Protect your account by enabling your email based two factor authentication.</p>
            </div>
            <div className='mfa-toggle-container'>
              <span className='mm-switch-block'>
                <input
                  value='true'
                  name='enable-mfa'
                  type='checkbox'
                  aria-checked='true'
                  className='mm-switch-input'
                  checked={data?.mfaEnabled}
                  onChange={handleMFAChange}
                />
                <label className='mm-switch' role='button' onClick={handleMFAChange} />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className='card mm-setting-card'>
        <div className='card-body'>
          <form>
            <div className='mm-setting-card--title'>Subscription</div>
            <div className='mm-setting-form form-group row mt-3'>
              <label className='col-5 col-md-3 col-form-label m-t-5'>Current Plan</label>
              <span className='col-7 col-md-4 mm-setting-form-info col-form-label ml-0 mb-4 plan-badge'>
                {currentSubscription?.subscriptionStatus !== 'active' ? (
                  <img src={FreeBadge} alt='Free badge' />
                ) : currentSubscription?.name === 'Green' ? (
                  <img src={GreenBadge} alt='Green badge' />
                ) : currentSubscription?.name === 'Plus' ? (
                  <img src={PlusBadge} alt='Plus badge' />
                ) : currentSubscription?.name === 'Pro' ? (
                  <img src={ProBadge} alt='Pro badge' />
                ) : currentSubscription?.name === 'VIP' ? (
                  <img src={VipBadge} alt='Vip badge' />
                ) : (
                  <img src={FreeBadge} alt='Free badge' />
                )}
              </span>
              <div className='col-12 col-md-5'>
                <button
                  type='button'
                  className='mm-btn-settings mm-btn-animate float-right m-t-5'
                  onClick={() => changeTab(SettingPageEnum.PLAN)}
                >
                  {currentSubscription?.subscriptionStatus !== 'active' ? 'Upgrade' : 'Downgrade'}
                </button>
              </div>
            </div>

            <div className='mm-setting-form form-group row mt-5 mt-md-3 align-items-center'>
              <label className='col-12 col-md-6 mb-0'>Payment Method</label>

              <div className='col-12 col-md-6 mt-5 mt-md-0'>
                <button
                  type='button'
                  className='mm-btn-settings mm-btn-animate float-right'
                  onClick={handlePaymentMethodChange}
                >
                  Change Payment Method
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <ChangePasswordModal changePasswordModal={changePasswordModal} />

      <SaveSettings handleSave={handleSave} statusText={statusText} />
      {enableMFAModal.props.open ? <EnableMFAModal mfaModal={enableMFAModal} /> : null}
      {disableMFAModal.props.open ? <DisableMFAModal disableMFAModal={disableMFAModal} /> : null}
    </section>
  );
};

export default SettingOverview;
