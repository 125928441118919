import { useHistory } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';

import { AuthState } from 'auth/auth.enum';
import { logger } from 'common/logger.helper';
import classNames from 'common/classes.helper';
import { useAuthState } from 'auth/auth.context';
import insightsLogo from 'assets/icons/insights.svg';
import { appRouteConstants } from 'app/app-route.constant';
import insightsClose from 'assets/icons/insights-close.svg';
import { getStringDate, isToday } from 'common/moment.helper';
import { StripeSubscriptionStatus } from 'setting/setting.enum';
import useCurrentSubscription from 'auth/hooks/useCurrentSubscription';
import { getAllInsights, getProfile, patchInsightsRead } from 'api/request.api';

import { MessageBubbleProps, Insight } from './insights.type';

const SCROLL_OFFSET = 16;

const MessageBubble: React.FC<MessageBubbleProps> = ({ id, text, timestamp }) => {
  const timestampText = isToday(timestamp)
    ? `Today at ${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} `
    : getStringDate(timestamp);

  return (
    <div className='message-bubble' id={id}>
      <p className='message-bubble__text'>{text}</p>
      <span className='message-bubble__timestamp'>{timestampText}</span>
    </div>
  );
};

const Insights: React.FC = () => {
  const history = useHistory();
  const { authState } = useAuthState();

  const messageBubbleContainerRef = useRef<HTMLDivElement>(null);
  const insightsHeaderRef = useRef<HTMLDivElement>(null);

  const [insights, setInsights] = useState<Insight[]>([]);
  const [isInsightsVisible, setIsInsightsVisible] = useState<boolean>(false);
  const [isInsightButtonHighlighted, setIsInsightButtonHighlighted] = useState<boolean>(false);

  const { currentSubscription } = useCurrentSubscription();

  const allowInsights =
    currentSubscription?.subscriptionStatus === StripeSubscriptionStatus.TRIALING ||
    currentSubscription?.subscriptionStatus === StripeSubscriptionStatus.ACTIVE;

  useEffect(() => {
    if (authState !== AuthState.AUTHENTICATED) {
      return;
    }

    try {
      (async () => {
        const { data: profileData } = await getProfile();
        const { data: insightsData } = await getAllInsights();

        if (profileData && profileData.insightsCount) {
          setIsInsightButtonHighlighted(profileData.insightsCount > 0);
        }
        setInsights(insightsData || []);
      })();
    } catch (err) {
      logger.log('Could not fetch data from api', err);
    }
  }, [authState]);

  const scrollToUnreadInsight = () => {
    const messageBubbleContainerElement = messageBubbleContainerRef.current;
    const insightsHeaderElement = insightsHeaderRef.current;

    if (!messageBubbleContainerElement || !insightsHeaderElement) {
      return;
    }

    const firstUnreadMessage = insights.find((insight) => !insight.read);

    if (!firstUnreadMessage) {
      // Scroll to bottom
      messageBubbleContainerElement.scrollTop = messageBubbleContainerElement.scrollHeight;
      return;
    }

    // Scroll to first unread message
    const { id } = firstUnreadMessage;

    const messageElement = document.getElementById(`message-bubble-${id}`);

    if (!messageElement) {
      return;
    }

    const offsetTop = messageElement.offsetTop - insightsHeaderElement.offsetHeight - SCROLL_OFFSET;

    messageBubbleContainerElement.scrollTo({ top: offsetTop });
  };

  const handleInsightsClick = async () => {
    try {
      if (isInsightButtonHighlighted) {
        await patchInsightsRead();
      }
      setIsInsightsVisible((prevValue) => !prevValue);
      setIsInsightButtonHighlighted(false);
      scrollToUnreadInsight();
    } catch (err) {
      logger.log('Could not mark the insights as read', err);
    }
  };

  const handleAddAccountClick = () => {
    setIsInsightsVisible(false);
    history.push(appRouteConstants.auth.CONNECT_ACCOUNT);
  };

  const handleGoProClick = () => {
    setIsInsightsVisible(false);
    history.push('/settings?active=Plan');
  };

  const renderInsightsBody = () => {
    if (!allowInsights) {
      return (
        <div className='message-bubble'>
          <p className='message-bubble__text message-bubble__text--empty'>
            Upgrade your account to start getting insights.
          </p>
        </div>
      );
    }

    if (insights.length) {
      return insights.map(({ id, insightText, createdAt }) => (
        <MessageBubble key={id} id={`message-bubble-${id}`} text={insightText} timestamp={createdAt} />
      ));
    }

    return (
      <div className='message-bubble'>
        <p className='message-bubble__text message-bubble__text--empty'>
          We don't have insights for your account yet, add more accounts or check back in a few days.
        </p>
      </div>
    );
  };

  if (authState !== AuthState.AUTHENTICATED) {
    return null;
  }

  return (
    <div className='insights'>
      <button
        className={classNames(
          'insights__button',
          (isInsightButtonHighlighted || !allowInsights) && 'insights__button--highlight'
        )}
        onClick={handleInsightsClick}
      >
        <img src={insightsLogo} alt='Insights' />
      </button>

      <div className={`insights__content ${isInsightsVisible ? 'insights__content--visible' : ''}`}>
        <div className='insights__content-header' ref={insightsHeaderRef}>
          <h4>Minx Insights</h4>
          <button>
            <img src={insightsClose} alt='Close' onClick={() => setIsInsightsVisible(false)} />
          </button>
        </div>

        <div className='insights__content-body' ref={messageBubbleContainerRef}>
          {renderInsightsBody()}
        </div>

        {insights.length === 0 && allowInsights && (
          <div className='insights__content-footer'>
            <button className='mm-btn-primary mm-btn-animate' onClick={handleAddAccountClick}>
              Add an Account
            </button>
          </div>
        )}

        {!allowInsights && (
          <div className='insights__content-footer'>
            <button className='mm-btn-primary mm-btn-animate' onClick={handleGoProClick}>
              Go Pro!
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Insights;
