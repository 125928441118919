export const pricingDetailConstant = {
  NAME: 'Name',
  ALLOCATION_CHART_HISTORY: 'Allocation History',
  COMMUNITY_PRO: 'Community Pro Access',
  COMPARE_ALLOCATION: 'Compare Allocation',
  COMPARE_NET_WORTH: 'Compare Net Worth',
  CONNECTED_ACCOUNT: 'No of connected accounts',
  CRYPTO: 'Connect Crypto Wallets',
  CURRENCY: 'Currency',
  INTERNATIONAL_ACCOUNTS: 'International Accounts',
  INVESTING_ENTITY: 'No of investing entities',
  MANUAL_ACCOUNT: 'No of manual accounts',
  MINX_INSIGHTS: 'Minx Insights',
  PERFORMANCE: 'Performance(future)',
  PRECIOUS_METALS: 'Precious Metals',
  REAL_ESTATE_EXTRAS: 'Real Estate Extras',
  USER: 'No of users(future)'
};
