import PinInput from 'react-pin-input';
import React, { useState, useEffect } from 'react';

import useMFA from 'auth/hooks/useMFA';
import TickIcon from 'assets/images/login/tick-icon';
import { Modal, ModalType } from 'common/components/modal';

interface IEnableMfaModal {
  mfaModal: ModalType;
}

const EnableMFAModal: React.FC<IEnableMfaModal> = ({ mfaModal }) => {
  const [pin, setPin] = useState('');
  const { enableMFA, loading, verifyMFA, codeError, emailSent, resendMFA } = useMFA();

  const enableMFARef = React.useRef(enableMFA);

  useEffect(() => {
    (async () => {
      await enableMFARef.current();
    })();
  }, []);

  const handleConfirmMFACode = async () => {
    const { error } = await verifyMFA(pin);

    if (!error) {
      mfaModal.close();
    }
  };

  const handleResendMFACode = async () => {
    await resendMFA();
  };

  const renderCodeInfo = () => {
    if (emailSent) {
      return (
        <div className='check-inbox-wrapper'>
          <TickIcon className='icon' fill='#10C273' stroke='#FFFFFF' />
          <p>Check your inbox</p>
        </div>
      );
    }
    return (
      <div className='mfa-error-resend-wrapper'>
        <div className='error-text-wrapper'>{codeError && <span>That is not correct, try again.</span>}</div>
        <div className='resend-code' role='button' onClick={handleResendMFACode}>
          Resend Code
        </div>
      </div>
    );
  };

  return (
    <Modal {...mfaModal.props} title='Enable MFA' size='md' canBeClosed>
      <div className='modal-wrapper add-mfa-modal-wrapper'>
        <p>
          We first need to confirm your email address. Check your email and enter the 6 digit code we sent you.
        </p>
        <div className='mfa-input-wrapper'>
          <PinInput length={6}
                    type='numeric'
                    inputMode='number'
                    focus
                    onChange={setPin}
                    onComplete={setPin} />
        </div>
        {renderCodeInfo()}
        <div className='modal-btn-wrapper'>
          <button className='mm-btn-animate mm-btn-primary' disabled={loading || !pin} onClick={handleConfirmMFACode}>
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EnableMFAModal;
