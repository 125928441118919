import { TimeIntervalEnum } from 'networth/networth.enum';
import { EAccountTypeAction } from 'account-types/account-types.enum';
import { IAccountType, IAccountTypeAction } from 'account-types/account-types.types';

export const setAccountType = (accountType: IAccountType): IAccountTypeAction => ({
  type: EAccountTypeAction.SET_ACCOUNT_TYPE,
  payload: {
    accountType,
  },
});

export const resetAccountType = (): IAccountTypeAction => ({
  type: EAccountTypeAction.RESET_ACCOUNT_TYPE,
});

export const setFromDate = (fromDate: string): IAccountTypeAction => ({
  type: EAccountTypeAction.SET_FROM_DATE,
  payload: {
    fromDate,
  },
});

export const setToDate = (toDate: string): IAccountTypeAction => ({
  type: EAccountTypeAction.SET_TO_DATE,
  payload: {
    toDate,
  },
});

export const setTimeInterval = (timeInterval: TimeIntervalEnum): IAccountTypeAction => ({
  type: EAccountTypeAction.SET_TIME_INTERVAL,
  payload: {
    timeInterval,
  },
});

export const clearAccountTypeFilter = (): IAccountTypeAction => ({
  type: EAccountTypeAction.CLEAR_FILTERS,
});
