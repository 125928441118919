import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import ReactDatePicker from 'react-datepicker';

import moment from 'moment';
import { Formik } from 'formik';
import useToast from 'common/hooks/useToast';
import { Modal } from 'common/components/modal';
import { getISOString } from 'common/moment.helper';
import { getCurrencySymbol } from 'common/currency-helper';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/icon-delete.svg';
import { IAccountValue, IDailyBalancesModalProps } from 'account/account.type';
import { getDailyBalances, deleteDailyBalance, patchAccountBalances } from 'api/request.api';

interface IDailyBalancesFormValues {
  startingDate: Date | undefined;
  endingDate: Date | undefined;
  balanceValues: IAccountValue[];
}

const initialValues: IDailyBalancesFormValues = {
  startingDate: undefined,
  endingDate: undefined,
  balanceValues: [],
};

const DailyBalancesModal: React.FC<IDailyBalancesModalProps> = ({ account, dailyBalancesModal, onSuccess }) => {
  const { mmToast } = useToast();

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        if (!account) {
          return mmToast('Something went wrong', { type: 'error' });
        }

        try {
          const updateBalancesPayload = values.balanceValues.map(({ date, balance }) => ({
            date,
            balance,
          }));

          setLoading(true);
          await patchAccountBalances(account.id, updateBalancesPayload);
          mmToast('Balance successfully updated', { type: 'success' });

          dailyBalancesModal.close();
          return onSuccess();
        } catch (err) {
          mmToast('Error updating the balance values', { type: 'error' });
        } finally {
          setLoading(false);
        }
      }}
    >
      {(props) => {
        const { values, setValues, setFieldValue, handleChange, handleSubmit, resetForm } = props;

        const onCloseModal = () => {
          resetForm();
          dailyBalancesModal.close();
        };

        const handleDateChange = async (name: keyof IDailyBalancesFormValues, val: Date) => {
          const value = moment(val).toDate();
          setFieldValue(name, value);

          const currentValues: IDailyBalancesFormValues = { ...values, [name]: value };
          const { startingDate, endingDate } = currentValues;

          if (!startingDate || !endingDate || !account) {
            return;
          }

          try {
            const { data } = await getDailyBalances(account.id, getISOString(startingDate), getISOString(endingDate));

            if (data.balances) {
              setValues({
                ...currentValues,
                balanceValues: data.balances,
              });
            }
          } catch (err) {
            mmToast('Error fetching account balances', { type: 'error' });
          }
        };

        const handleDailyBalanceValueDelete = async (date: string) => {
          if (!account) {
            return mmToast('Something went wrong', { type: 'error' });
          }

          try {
            const body = {
              dates: [date],
            };

            await deleteDailyBalance(account.id, body);

            const newBalanceValues = values.balanceValues.filter((item) => item.date !== date);
            setFieldValue('balanceValues', newBalanceValues);

            mmToast('Balance successfully deleted', { type: 'success' });
          } catch (err) {
            mmToast('Error deleting account balance', { type: 'error' });
          }
        };

        return (
          <Modal {...dailyBalancesModal.props} title={account?.accountName || ''} size='mdx' onClose={onCloseModal}>
            <form className='daily-balances' onSubmit={handleSubmit}>
              <p className='daily-balances__description'>
                Here are all of the values we have on file for this account.
              </p>

              <div className='row mb-4'>
                <div className='col-12 col-md-6 mb-4 mb-md-0'>
                  <label htmlFor='startingDate'>Starting Date</label>
                  <div className='date-wrapper'>
                    <ReactDatePicker
                      id='startingDate'
                      name='startingDate'
                      placeholderText='Pick a date'
                      className='form-control form-control-lg'
                      selected={values.startingDate}
                      onChange={(val: Date) => {
                        handleDateChange('startingDate', val);
                      }}
                    />
                    <CalendarIcon className='icon' />
                  </div>
                </div>
                <div className='col-12 col-md-6 mb-4 mb-md-0'>
                  <label htmlFor='endingDate'>Ending Date</label>
                  <div className='date-wrapper'>
                    <ReactDatePicker
                      id='endingDate'
                      name='endingDate'
                      placeholderText='Pick a date'
                      className='form-control form-control-lg'
                      selected={values.endingDate}
                      onChange={(val: Date) => {
                        handleDateChange('endingDate', val);
                      }}
                    />
                    <CalendarIcon className='icon' />
                  </div>
                </div>
              </div>

              {values.balanceValues.length > 0 && (
                <>
                  <div className='row md-hide'>
                    <div className='col-12 col-md-4 mb-4 mb-md-0'>
                      <span className='daily-balances__label'>Date</span>
                    </div>

                    <div className='col-12 col-md-6 mb-4 mb-md-0'>
                      <span className='daily-balances__label'>Value</span>
                    </div>
                  </div>

                  <div className='daily-balances__values'>
                    {values.balanceValues.map(({ id, date, dateString, balance, balanceCurrency }, index) => (
                      <div className='row align-items-center mb-4 mb-md-3' key={id}>
                        <div className='col-12 col-md-4 mb-3 mb-md-0'>
                          <label className='daily-balances__label md-show'>Date</label>
                          <span className='daily-balances__date'>{dateString}</span>
                        </div>

                        <div className='col-12 col-md-6 mb-3 mb-md-0'>
                          <label className='daily-balances__label md-show'>Value</label>

                          <div className='currency-input'>
                            <Form.Control
                              type='number'
                              name={`balanceValues.${index}.balance`}
                              value={balance}
                              onChange={handleChange}
                              step='any'
                            />
                            <span className='currency-input__symbol'>{getCurrencySymbol(balanceCurrency)}</span>
                          </div>
                        </div>

                        <div className='col-12 col-md-2 mb-3 mb-md-0 d-flex justify-content-start justify-content-md-end'>
                          <DeleteIcon role='button' onClick={() => handleDailyBalanceValueDelete(date)} />
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}

              <div className='d-flex flex-column flex-md-row justify-content-start pt-4 daily-balances__footer'>
                <button
                  type='button'
                  className='btn-outline-primary mm-btn-animate mr-md-4 mb-2 mb-md-0'
                  onClick={onCloseModal}
                >
                  Cancel
                </button>
                <button type='submit' className='mm-btn-animate mm-btn-primary' disabled={!values.balanceValues.length}>
                  {loading ? (
                    <>
                      <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
                      <span className='ml-1'>Saving...</span>
                    </>
                  ) : (
                    <> Save Changes </>
                  )}
                </button>
              </div>
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default DailyBalancesModal;
