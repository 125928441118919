import React from 'react';

import { fNumber, numberWithCommas } from 'common/number.helper';
import { IAccountDataForInterval } from 'account-types/account-types.types';

import AccountDataByTypeChart from './account-data-by-type-chart';

interface IAccountTypesChart {
  currencySymbol: string;
  total: IAccountDataForInterval[];
}

const AccountTypesChart: React.FC<IAccountTypesChart> = ({ currencySymbol, total }) => {
  const netValue = total.find((balance) => balance.interval === 'Current')?.balance || 0;

  return (
    <div className='account-types-chart-container mb-40'>
      <div className='account-types-chart-container__summary mb-5'>
        <span className='chart-label'>Value</span>
        <span className='chart-value'>
          {currencySymbol}
          {numberWithCommas(fNumber(netValue, 2))}
        </span>
      </div>
      <div className='account-types-chart-container__chart chartbox hide-sm'>
        <AccountDataByTypeChart currencySymbol={currencySymbol} balances={total} />
      </div>
    </div>
  );
};

export default AccountTypesChart;
