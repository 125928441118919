import { Link } from 'react-router-dom';
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { events } from '@mm/data/event-list';
import GALink from 'common/components/ga-link';
import LandingLayout from 'landing/landing.layout';
import { appRouteConstants } from 'app/app-route.constant';
import Hunter from 'assets/images/testimonials/hunter.jpg';
import Joe from 'assets/images/testimonials/joe-magnotti.jpeg';
import SignupToday from 'website/partials/signup-today.footer';
import Faraz from 'assets/images/testimonials/faraz-sharafi.jpeg';
import { ReactComponent as LogoVanguard } from 'assets/logos/1/vanguard.svg';
import { ReactComponent as LogoRobinhood } from 'assets/logos/1/robinhood.svg';
import { ReactComponent as LogoSchwab } from 'assets/logos/1/charlesschwab.svg';
import { ReactComponent as LogoWellsFargo } from 'assets/logos/1/wellsfargo.svg';
import { ReactComponent as LogoWealthfront } from 'assets/logos/1/wealthfront.svg';
import { ReactComponent as LogoN26 } from 'assets/logos/1/n26.svg';
import { ReactComponent as LogoBitstamp } from 'assets/logos/1/bitstamp.svg';
import { ReactComponent as LogoDegiro } from 'assets/logos/1/degiro.svg';
import { ReactComponent as LogoGenerali } from 'assets/logos/1/generali.svg';
import { ReactComponent as LogoSocieteGeneral } from 'assets/logos/2/societegenerale.svg';
import { ReactComponent as LogoRevolut } from 'assets/logos/2/revolut.svg';
import { ReactComponent as LogoAlly } from 'assets/logos/2/ally.svg';
import { ReactComponent as LogoBofA } from 'assets/logos/2/bankofamerica.svg';
import { ReactComponent as LogoChase } from 'assets/logos/2/chase.svg';
import { ReactComponent as LogoCiti } from 'assets/logos/2/citi.svg';
import { ReactComponent as LogoFidelity } from 'assets/logos/2/fidelityinvestments.svg';
import { ReactComponent as LogoPoint } from 'assets/logos/2/point.svg';
import { ReactComponent as LogoJPMorgan } from 'assets/logos/2/jpmorgan.svg';
import { ReactComponent as LogoBankin } from 'assets/logos/3/bankin.svg';
import { ReactComponent as LogoBanquePopulare } from 'assets/logos/3/banquepopulaire.svg';
import { ReactComponent as LogoeTrade } from 'assets/logos/3/etrade.svg';
import { ReactComponent as LogoBinance } from 'assets/logos/3/binance.svg';
import { ReactComponent as LogoCoinbase } from 'assets/logos/3/coinbase.svg';
import { ReactComponent as LogoKraken } from 'assets/logos/3/kraken.svg';
import { ReactComponent as LogoStash } from 'assets/logos/3/stash.svg';
import { ReactComponent as LogoAmeritrade } from 'assets/logos/3/tdameritrade.svg';
import { ReactComponent as LogoTrezor } from 'assets/logos/3/trezor.svg';
import { ReactComponent as LogoInteractiveBrokers } from 'assets/logos/4/interactivebrokers.svg';
import { ReactComponent as LogoNalo } from 'assets/logos/4/nalo.svg';
import { ReactComponent as LogoCA } from 'assets/logos/4/ca.svg';
import { ReactComponent as LogoGoldman } from 'assets/logos/4/goldmansachs.svg';
import { ReactComponent as LogoFortuneo } from 'assets/logos/4/fortuneo.svg';
import { ReactComponent as LogoAg2rLamondiale } from 'assets/logos/4/ag2rlamondiale.svg';
import { ReactComponent as LogoUBS } from 'assets/logos/4/ubs.svg';
import { ReactComponent as LogoBNP } from 'assets/logos/4/bnpparibas.svg';
import { ReactComponent as LogoMetamask } from 'assets/logos/4/metamask.svg';
import { ReactComponent as Quotes } from 'assets/images/testimonials/quote.svg';
import { ReactComponent as SecurityLockIcon } from 'assets/images/security/lock.svg';
import { ReactComponent as HomeBannerImg } from 'assets/images/home/home-banner-img.svg';
import { ReactComponent as SecurityShieldIcon } from 'assets/images/security/shield.svg';
import { ReactComponent as PerformanceImg } from 'assets/images/features/performance.svg';

const LandingNetWorthCalculator = () => {
  return (
    <LandingLayout>
      <HelmetProvider>
        <Helmet>
          <title>Track All Your Investments in One Place | Money Minx</title>
          <meta name='description' content='Savvy investors use Money Minx to track their crypto, alts, net worth, investments, assets and debt in one place.' />
          {/*Facebook tags*/}
          <meta property='og:title' content='Track All Your Investments in One Place' />
          <meta property='og:description' content='Savvy investors use Money Minx to track their crypto, alts, net worth, investments, assets and debt in one place.' />
          <meta property='og:url' content='https://www.moneyminx.com' />
          <meta property='og:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
          <meta property='og:image:secure_url' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
          <meta property='og:image:alt' content='Track, analyze and optimize a diversified portfolio with Money Minx. Our technology gives everyday investors pro-level insights to reach their net worth goals.' />
          {/*Twitter tags*/}
          <meta property='twitter:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
          <meta property='twitter:image:alt' content='Track, analyze and optimize a diversified portfolio with Money Minx. Our technology gives everyday investors pro-level insights to reach their net worth goals.' />
        </Helmet>
      </HelmetProvider>
      <div className='mm-new-container'>
        <HomeTopSection />
        <HomeAccountTypes />
        <HomeTestimonials />
        <HomeConnections />
        <CTASectionPricing />
        <HomePerformanceSection />
        <HomeSecuritySection />
      </div>
    </LandingLayout>
  );
};

export default LandingNetWorthCalculator;

export const HomeTopSection = () => {
  return (
    <section>
      <div className='row'>
        <div className='col-xl-7'>
          <div className='mm-home-left-banner'>
            <h1>Manage your net worth and personal finances</h1>
            <p className='text'>
              Personal financial planning and investments tracking for the modern, everyday investor
            </p>
            <GALink
              role='menuitem'
              to={appRouteConstants.auth.SIGNUP}
              eventArgs={{
                ...events.ctaSignUp,
                label: 'Start My Free Trial',
              }}
            >
              <button className='mm-btn-animate mm-btn-primary'>Get Started</button>
            </GALink>
            <small className='d-block mt-3 mt-md-4'>It's Free.</small>
          </div>
        </div>
        <div className='col-xl-5'>
          <div className='mm-home-right-img-banner text-center mt-xl-n5'>
            <HomeBannerImg />
          </div>
        </div>
      </div>
      <div className='mm-scroll-container-parent mm-mb-hide'>
        <div className='mm-scroll-container'>
          <div className='mouse'>
            <div className='scroll' />
          </div>
        </div>
      </div>
    </section>
  );
};
export const HomeAccountTypes = () => {
  return (
    <section>
      <div className='mm-home-feature-section account-types'>
        <p className='account-types-heading'>Save Time and Energy - Grow Your Financial Future</p>
        <p className='account-types-subheading'>Multiple assets, multiple currencies, history and projections  - track it all in once place with our modern financial planner and portfolio tracker</p>
        <div className='account-types-list'>
          <div className='account-types-single'>
            <p className='account-types-single-emoji'>📈</p>
            <p>Equities</p>
          </div>
          <div className='account-types-single background-two'>
            <p className='account-types-single-emoji'>👾</p>
            <p>Crypto</p>
          </div>
          <div className='account-types-single'>
            <p className='account-types-single-emoji'>💵</p>
            <p>Cash</p>
          </div>
          <div className='account-types-single background-two'>
            <p className='account-types-single-emoji'>🏡</p>
            <p>Real Estate</p>
          </div>
          <div className='account-types-single'>
            <p className='account-types-single-emoji'>🎉</p>
            <p>Alternatives</p>
          </div>
          <div className='account-types-single background-two'>
            <p className='account-types-single-emoji'>💳</p>
            <p>Cards</p>
          </div>
          <div className='account-types-single'>
            <p className='account-types-single-emoji'>🏦</p>
            <p>Loans</p>
          </div>
          <div className='account-types-single background-two'>
            <p className='account-types-single-emoji'>🎭</p>
            <p>Collectibles</p>
          </div>
          <div className='account-types-single'>
            <p className='account-types-single-emoji'>🌏</p>
            <p>Forex</p>
          </div>
          <div className='account-types-single background-two'>
            <p className='account-types-single-emoji'>🔅</p>
            <p>NFTs</p>
          </div>
          <div className='account-types-single'>
            <p className='account-types-single-emoji'>⚖️</p>
            <p>Precious Metals</p>
          </div>
          <div className='account-types-single background-two'>
            <p className='account-types-single-emoji'>📜</p>
            <p>Insurance</p>
          </div>
          <div className='account-types-single'>
            <p className='account-types-single-emoji'>💼</p>
            <p>Businesses</p>
          </div>
          <div className='account-types-single background-two'>
            <p className='account-types-single-emoji'>🏝</p>
            <p>More</p>
          </div>
        </div>
      </div>
    </section>
  );
};
export const HomeTestimonials = () => {
  return (
    <section className='testimonial'>
      <div>
        <div className='trusted-by'>
          <div className='col-12 col-md-9 black title-in-post medium-heading-light'>Trusted by investors just like you</div>
          <div className='col-12 col-md-3 assets-tracked'>
            <span className='amount'>$200m</span>
            <br />
            <span className='metric'>Tracked in Money Minx</span>
          </div>
        </div>
        <div className='cards-row'>
          <div className='cards-single col-4'>
            <Quotes className='testimonial-quote'/>
            <div className='testimonial-card'>
              <p>I love seeing the fancy charts telling me where my money is at in multiple currencies!</p>
            </div>
            <img className='testimonial-user' src={Joe} alt='Joe M.'/>
            <div className='card-caption'>Joe M.</div>
          </div>
          <div className='cards-single col-4'>
            <Quotes className='testimonial-quote'/>
            <div className='testimonial-card'>
              <p>I am most excited to see the accumulated value of my portfolio with a future forecast.</p>
            </div>
            <img className='testimonial-user' src={Faraz} alt='Faraz Sharafi'/>
            <div className='card-caption'>Faraz S.</div>
          </div>
          <div className='cards-single col-4'>
            <Quotes className='testimonial-quote'/>
            <div className='testimonial-card'>
              <p>MoneyMinx is designed to support less “traditional” investors with support for crypto, different currencies, viewing asset allocation, etc.</p>
            </div>
            <img className='testimonial-user' src={Hunter} alt='Hunter Fairchild'/>
            <div className='card-caption'>Hunter F.</div>
          </div>
        </div>
      </div>
    </section>
  );
};
export const HomeConnections = () => {
  return (
    <section>
      <div className='mm-home-feature-section connections'>
        <p className='connections-heading'>Connect with over 21,000 institutions and watch the magic happen</p>
        <p className='connections-subheading'>Psst, yes, we also support manually tracking your accounts</p>
        <div className='connections-logos'>
          <div className='connections-logo-row'>
            <div className='connections-logo'><LogoRobinhood /></div>
            <div className='connections-logo'><LogoVanguard /></div>
            <div className='connections-logo'><LogoWealthfront /></div>
            <div className='connections-logo'><LogoWellsFargo /></div>
            <div className='connections-logo'><LogoSchwab /></div>
            <div className='connections-logo'><LogoN26 /></div>
            <div className='connections-logo'><LogoBitstamp /></div>
            <div className='connections-logo p-t-2'><LogoDegiro /></div>
            <div className='connections-logo'><LogoGenerali /></div>
            <div className='connections-logo'><LogoRobinhood /></div>
            <div className='connections-logo'><LogoVanguard /></div>
            <div className='connections-logo'><LogoWealthfront /></div>
            <div className='connections-logo'><LogoWellsFargo /></div>
            <div className='connections-logo'><LogoSchwab /></div>
            <div className='connections-logo'><LogoN26 /></div>
            <div className='connections-logo'><LogoBitstamp /></div>
            <div className='connections-logo p-t-2'><LogoDegiro /></div>
            <div className='connections-logo'><LogoGenerali /></div>
          </div>
          <div className='connections-logo-row reversed'>
            <div className='connections-logo'><LogoSocieteGeneral /></div>
            <div className='connections-logo'><LogoRevolut /></div>
            <div className='connections-logo'><LogoAlly /></div>
            <div className='connections-logo'><LogoBofA /></div>
            <div className='connections-logo'><LogoChase /></div>
            <div className='connections-logo'><LogoCiti /></div>
            <div className='connections-logo'><LogoFidelity /></div>
            <div className='connections-logo'><LogoPoint /></div>
            <div className='connections-logo'><LogoJPMorgan /></div>
            <div className='connections-logo'><LogoSocieteGeneral /></div>
            <div className='connections-logo'><LogoRevolut /></div>
            <div className='connections-logo'><LogoAlly /></div>
            <div className='connections-logo'><LogoBofA /></div>
            <div className='connections-logo'><LogoChase /></div>
            <div className='connections-logo'><LogoCiti /></div>
            <div className='connections-logo'><LogoFidelity /></div>
            <div className='connections-logo'><LogoPoint /></div>
            <div className='connections-logo'><LogoJPMorgan /></div>
          </div>
          <div className='connections-logo-row'>
            <div className='connections-logo'><LogoBankin /></div>
            <div className='connections-logo'><LogoBanquePopulare /></div>
            <div className='connections-logo'><LogoeTrade /></div>
            <div className='connections-logo'><LogoBinance /></div>
            <div className='connections-logo'><LogoCoinbase /></div>
            <div className='connections-logo p-t-1'><LogoKraken /></div>
            <div className='connections-logo'><LogoStash /></div>
            <div className='connections-logo'><LogoAmeritrade /></div>
            <div className='connections-logo'><LogoTrezor /></div>
            <div className='connections-logo'><LogoBankin /></div>
            <div className='connections-logo'><LogoBanquePopulare /></div>
            <div className='connections-logo'><LogoeTrade /></div>
            <div className='connections-logo'><LogoBinance /></div>
            <div className='connections-logo'><LogoCoinbase /></div>
            <div className='connections-logo p-t-1'><LogoKraken /></div>
            <div className='connections-logo'><LogoStash /></div>
            <div className='connections-logo'><LogoAmeritrade /></div>
            <div className='connections-logo'><LogoTrezor /></div>
          </div>
          <div className='connections-logo-row reversed'>
            <div className='connections-logo'><LogoInteractiveBrokers /></div>
            <div className='connections-logo'><LogoNalo /></div>
            <div className='connections-logo'><LogoCA /></div>
            <div className='connections-logo'><LogoGoldman /></div>
            <div className='connections-logo'><LogoFortuneo /></div>
            <div className='connections-logo'><LogoAg2rLamondiale /></div>
            <div className='connections-logo'><LogoUBS /></div>
            <div className='connections-logo p-t-4'><LogoBNP /></div>
            <div className='connections-logo p-t-2'><LogoMetamask /></div>
            <div className='connections-logo'><LogoInteractiveBrokers /></div>
            <div className='connections-logo'><LogoNalo /></div>
            <div className='connections-logo'><LogoCA /></div>
            <div className='connections-logo'><LogoGoldman /></div>
            <div className='connections-logo'><LogoFortuneo /></div>
            <div className='connections-logo'><LogoAg2rLamondiale /></div>
            <div className='connections-logo'><LogoUBS /></div>
            <div className='connections-logo p-t-4'><LogoBNP /></div>
            <div className='connections-logo p-t-2'><LogoMetamask /></div>
          </div>
        </div>
      </div>
    </section>
  );
};
export const CTASectionPricing = () => {
  return (
    <section className='m-t-240'>
      <div>
        <SignupToday footerCTA={false} pricing={true}/>
      </div>
    </section>
  );
}
export const HomePerformanceSection = () => {
  return (
    <section className='feature-section feature-text-left m-t-240 performance'>
      <div className='row'>
        <div className='col-lg-5 feature-content'>
          <h2>Make sure your returns are hitting your targets</h2>
          <p className='feature-text'>
            Money Minx AI keeps an eye on your assets and compares real returns to what you had planned. Gives you a quick way to catch your under performing assets.
          </p>
          <Link to={'/features'}>
            <button className='mm-btn-animate mm-btn-primary'>Explore all features</button>
          </Link>
        </div>
        <div className='col-lg-7 feature-image performance-image'>
          <PerformanceImg />
        </div>
      </div>
    </section>
  );
};
export const HomeSecuritySection = () => {
  return (
    <section className='home-security'>
      <div className='mm-home-feature-section'>
        <div className='row'>
          <p className='large-heading-light p-l-3 p-r-3 text-white'>Bank Level Security</p>
          <p className='text-wrap p-l-3 p-r-3 sub-heading'>Your sensitive information is always protected, always secure.</p>
          <div className='col-lg-6'>
            <div className='protected-div'>
            <span className='block mb-25'>
              <SecurityShieldIcon />
            </span>
              <p className='text p-t-5'>
                We use the highest security standard and encryption with your data to make sure it is always safe.
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='protected-div'>
            <span className='block mb-25'>
              <SecurityLockIcon />
            </span>
              <p className='text p-t-5'>
                We don’t store your credentials, we have read-only access. Our team cannot move funds or make transfers. You data can only be accessed if you need help and consent.
              </p>
            </div>
          </div>
          <div className='col-12 security-link'>
            <a href='/security'>Learn more about our security</a>
          </div>
        </div>
      </div>
    </section>
  );
};
