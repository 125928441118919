import * as Yup from 'yup';

import validation from 'lang/en/validation.json';
import { ECryptoConnections } from './auth.enum';
import { EEnterBalance } from 'account/account.type';
import { EMMAccountType } from 'account/enum/account-type';

import { dateSchema } from '../app/app.validation';
import { isBalanceFieldRequired } from './auth.helper';

const { REQUIRED_FIELD, INVALID_EMAIL, INVALID_PASSWORD, PASSWORD_ERROR, PASSWORD_MATCH } = validation;

const passwordRegex =
  /(?=.*[A-Za-z])(?=.*\d+)(?=.*[~`!@#$%^&*()_+\-={[}\]|\\:;"'<,>.?/])[A-Za-z\d~`!@#$%^&*()_+\-={[}\]|\\:;"'<,>.?/]{8,}$/;
const emailRegex = /^[a-zA-Z0-9.+-_]+@[a-zA-Z0-9.+-_]+(?:\.[a-zA-Z0-9-]+)*$/;

export const XPUB_REGEX = /^[xy]pub[a-km-zA-HJ-NP-Z0-9]{107}$/;
export const BITCOIN_REGEX = /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/;

const ethereumAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;

const terraAddressRegex = /^(terra1[a-z0-9]{38})$/;

export const loginValidationSchema = Yup.object({
  email: Yup.string().matches(emailRegex, INVALID_EMAIL),
  password: Yup.string().matches(passwordRegex, INVALID_PASSWORD),
});

export const registerValidationSchema = Yup.object({
  email: Yup.string().matches(emailRegex, INVALID_EMAIL),
  password: Yup.string().matches(passwordRegex, PASSWORD_ERROR),
});

export const forgotPasswordValidation = Yup.object({
  email: Yup.string().matches(emailRegex, INVALID_EMAIL).required(REQUIRED_FIELD),
});

export const resetPasswordValidation = Yup.object({
  password: Yup.string().matches(passwordRegex, PASSWORD_ERROR).required(REQUIRED_FIELD),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], PASSWORD_MATCH)
    .required(REQUIRED_FIELD),
});

export const addManualAccountValidation = Yup.object({
  mmAccountType: Yup.string().required(validation.MANUAL_ACCOUNT_REQUIRED_FIELD),
  mmAccountSubType: Yup.string(),
  investingEntityId: Yup.string().required(validation.MANUAL_ACCOUNT_REQUIRED_FIELD),
  accountName: Yup.string().required(validation.MANUAL_ACCOUNT_REQUIRED_FIELD),
  streetAddress: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  zipCode: Yup.string(),
  country: Yup.string(),
  hasHoldings: Yup.string().required(validation.MANUAL_ACCOUNT_REQUIRED_FIELD),
  balance: Yup.number().test({
    name: 'balanceTest',
    exclusive: true,
    message: validation.MANUAL_ACCOUNT_REQUIRED_FIELD,
    test: function balanceTest(value) {
      if (this.parent.useZestimate === 'yes' && !value) {
        return true;
      }

      if (this.parent.hasHoldings !== 'yes' && !value) {
        return false;
      }

      return true;
    },
  }),
  currency: Yup.string().required(validation.MANUAL_ACCOUNT_REQUIRED_FIELD),
  liquidity: Yup.string(),
  useZestimate: Yup.string().when('mmAccountType', {
    is: (mmAccountType) => mmAccountType === 'Real Estate',
    then: Yup.string(),
  }),
});

export const addManualAccountFormValidation = Yup.object({
  mmAccountType: Yup.string().required(validation.MANUAL_ACCOUNT_REQUIRED_FIELD),
  mmAccountSubType: Yup.string(),
  investingEntityId: Yup.string().required(validation.MANUAL_ACCOUNT_REQUIRED_FIELD),
  accountName: Yup.string().required(validation.MANUAL_ACCOUNT_REQUIRED_FIELD),
  streetAddress: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  zipCode: Yup.string(),
  country: Yup.string(),
  balance: Yup.number().test({
    name: 'balanceTest',
    exclusive: true,
    message: validation.MANUAL_ACCOUNT_REQUIRED_FIELD,
    test: function balanceTest(value) {
      const isRequired = isBalanceFieldRequired(this.parent.mmAccountType);

      if (this.parent.mmAccountType === EMMAccountType.REAL_ESTATE && this.parent.useZestimate === 'yes' && !value) {
        return true;
      }

      if (isRequired && !value) {
        return false;
      }

      return true;
    },
  }),
  currency: Yup.string().required(validation.MANUAL_ACCOUNT_REQUIRED_FIELD),
  liquidity: Yup.string(),
  useZestimate: Yup.string().when('mmAccountType', {
    is: (mmAccountType) => mmAccountType === EMMAccountType.REAL_ESTATE,
    then: Yup.string(),
  }),
});

export const updateAccountValueValidation = Yup.object({
  updateForDateRange: Yup.boolean().required(validation.UPDATE_ACCOUNT_VALUE_REQUIRED_FIELD),
  asOfDate: dateSchema.when('updateForDateRange', {
    is: false,
    then: dateSchema.required(validation.UPDATE_ACCOUNT_VALUE_REQUIRED_FIELD),
    otherwise: dateSchema,
  }),
  accountValue: Yup.number().when('updateForDateRange', {
    is: false,
    then: Yup.number().required(validation.UPDATE_ACCOUNT_VALUE_REQUIRED_FIELD),
    otherwise: Yup.number(),
  }),
  startingDate: dateSchema.when('updateForDateRange', {
    is: true,
    then: dateSchema.required(validation.UPDATE_ACCOUNT_VALUE_REQUIRED_FIELD),
    otherwise: dateSchema,
  }),
  endingDate: dateSchema.when('updateForDateRange', {
    is: true,
    then: dateSchema.required(validation.UPDATE_ACCOUNT_VALUE_REQUIRED_FIELD),
    otherwise: dateSchema,
  }),
  howToEnterBalance: Yup.string(),
  startingValue: Yup.number().when('updateForDateRange', {
    is: true,
    then: Yup.number().required(validation.UPDATE_ACCOUNT_VALUE_REQUIRED_FIELD),
    otherwise: Yup.number(),
  }),
  endingValue: Yup.number().when('howToEnterBalance', {
    is: EEnterBalance.INCREASE,
    then: Yup.number().required(validation.UPDATE_ACCOUNT_VALUE_REQUIRED_FIELD),
    otherwise: Yup.number(),
  }),
});

export const addCryptoAccountValidation = Yup.object({
  connectionType: Yup.string(),
  apiKey: Yup.string(),
  apiSecret: Yup.string(),
  apiPassphrase: Yup.string(),
  bitcoinAddress: Yup.string().test({
    name: 'bitcoinAddressTest',
    exclusive: true,
    message: validation.INVALID_BITCOIN_ADDRESS,
    test: function bitcoinAddressTest(value) {
      if (this.parent.connectionType === ECryptoConnections.BITCOIN) {
        return [BITCOIN_REGEX, XPUB_REGEX].some((regex) => regex.test(value));
      }

      return true;
    },
  }),
  ethereumBlockchainAddress: Yup.string().test({
    name: 'ethereumBlockchainAddressTest',
    exclusive: true,
    message: validation.INVALID_ETHEREUM_ADDRESS,
    test: function ethereumBlockchainAddressTest(value) {
      if (this.parent.connectionType === ECryptoConnections.ETHEREUM) {
        return ethereumAddressRegex.test(value);
      }

      return true;
    },
  }),
  terraAddress: Yup.string().test({
    name: 'terraAddressTest',
    exclusive: true,
    message: validation.INVALID_TERRA_ADDRESS,
    test: function terraAddressTest(value) {
      if (this.parent.connectionType === ECryptoConnections.TERRA) {
        return terraAddressRegex.test(value);
      }

      return true;
    },
  }),
});
