import PinInput from 'react-pin-input';
import React, { useState } from 'react';

import useMFA from 'auth/hooks/useMFA';
import Message from 'auth/views/inc/message';
import { useAuthDispatch } from 'auth/auth.context';
import Checkbox from 'common/components/input/checkbox.input';

interface ILoginMFAForm {
  setRefreshLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginMFAForm: React.FC<ILoginMFAForm> = (props: ILoginMFAForm) => {
  const [pin, setPin] = useState('');
  const dispatch = useAuthDispatch();
  const [rememberDevice, setRememberDevice] = useState(false);
  const { emailSent, setEmailSent, resendAuthMFA, verifyAuthMFA, codeError } = useMFA();

  const handleVerifyMFA = async () => {
    props.setRefreshLoading(true);
    await verifyAuthMFA(dispatch)(+pin, rememberDevice);
    props.setRefreshLoading(false);
  };

  return (
    <div className='mfa-form-wrapper'>
      <PinInput length={6}
                type='numeric'
                inputMode='number'
                focus
                onChange={setPin}
                onComplete={setPin} />
      <div className='mfa-error-resend-wrapper'>
        {codeError ? (
          <div className='error-text-wrapper'>
            <span>That is not correct, try again.</span>
          </div>
        ) : null}
        <div className='resend-code' role='button' onClick={resendAuthMFA}>
          Resend Code
        </div>
      </div>
      <div className='remember-device-wrapper'>
        <Checkbox
          name='rememberDevice'
          checked={rememberDevice}
          onChange={() => setRememberDevice(!rememberDevice)}
          label='Remember this device (do not check for public devices)'
        />
      </div>
      <div className='mfa-action-wrapper'>
        <button className='mm-btn-animate mm-btn-primary' onClick={handleVerifyMFA} disabled={pin.length !== 6}>
          Continue
        </button>
      </div>
      {emailSent ? (
        <Message type='success' message='Check your email for a new code' onDismiss={() => setEmailSent(false)} />
      ) : null}
    </div>
  );
};

export default LoginMFAForm;
