import Form from 'react-bootstrap/Form';
import React, { useRef, useState } from 'react';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';

import omit from 'lodash/omit';
import { Formik } from 'formik';
import { logger } from 'common/logger.helper';
import { TChangeEvent } from 'common/dom.types';
import MMToolTip from 'common/components/tooltip';
import { isUSAText } from 'common/country.helper';
import { getZestimateValue } from 'api/request.api';
import useSettings from 'setting/hooks/useSettings';
import { enumerateStr } from 'common/common-helper';
import useAccountType from 'auth/hooks/useAccountType';
import { camelCase } from 'common/helper/string.helper';
import { getCurrencySymbol } from 'common/currency-helper';
import { EMMAccountType } from 'account/enum/account-type';
import useAccountSubtype from 'auth/hooks/useAccountSubtype';
import { IFormField, IManualAccount } from 'auth/auth.types';
import { LiquidityOptions } from 'auth/enum/liquidity-options';
import zillowIcon from 'assets/images/account/zillow_icon.svg';
import { StripeSubscriptionStatus } from 'setting/setting.enum';
import { fNumber, numberWithCommas } from 'common/number.helper';
import useInvestingEntity from 'setting/hooks/useInvestingEntity';
import CurrencyInput from 'common/components/input/currency.input';
import { addManualAccountFormValidation } from 'auth/auth.validation';
import zillowProvided from 'assets/images/account/zillow_provided.svg';
import useCurrentSubscription from 'auth/hooks/useCurrentSubscription';
import { ReactComponent as InfoIcon } from 'assets/images/signup/info.svg';
import { CurrencyOptions, CurrencySymbols } from 'auth/enum/currency-options';
import { MMSelectInput, SelectInput } from 'common/components/input/select.input';
import useManualAccountFormModalFields from 'auth/hooks/useManualAccountFormModalFields';
import { manualAccountFormFieldList, manualAccountInitialData } from 'auth/data/manual-account.data';

interface IManualAccountsFormProps {
  loading: boolean;
  handleClose: VoidFunction;
  handleSubmit: (formValues: Partial<IManualAccount>) => void;
  initialFormValues?: Partial<IManualAccount> | null;
}

const ManualAccountsForm: React.FC<IManualAccountsFormProps> = ({
  loading,
  initialFormValues,
  handleClose,
  handleSubmit,
}) => {
  const { investingEntities } = useInvestingEntity();
  const { data: accountTypes } = useAccountType(true);
  const { currentSubscription } = useCurrentSubscription();
  const { data: settingsData } = useSettings();

  const [accountType, setAccountType] = useState<keyof IFormField>('cash');
  const { subType: accountSubTypes } = useAccountSubtype(accountType, true);

  const { hasFormField, getFieldTitle } = useManualAccountFormModalFields(accountType);
  const hasAccountSubType = accountSubTypes.some(Boolean);
  const addManualAccountFormRef = useRef<any>(null);
  const [zestimateValue, setZestimateValue] = useState<number | null | undefined>(undefined);
  const currencySymbol = getCurrencySymbol(settingsData?.currency || CurrencySymbols.USD);

  const isFreeUser =
    currentSubscription?.subscriptionStatus !== StripeSubscriptionStatus.TRIALING &&
    currentSubscription?.subscriptionStatus !== StripeSubscriptionStatus.ACTIVE;

  return (
    <div className='manual-account__body mm-manual-account-modal mt-3'>
      <Formik
        validationSchema={addManualAccountFormValidation}
        initialValues={initialFormValues || manualAccountInitialData}
        innerRef={addManualAccountFormRef}
        onSubmit={async (values, actions) => {
          const type = camelCase(values.mmAccountType || '');

          const hasHoldings = values.hasHoldings === 'yes' || values.hasHoldings === true;
          const isCryptoOrMetals =
            values.mmAccountType === EMMAccountType.PRECIOUS_METALS ||
            values.mmAccountType === EMMAccountType.CRYPTOCURRENCIES;

          values.hasHoldings = 'yes' === values.hasHoldings ? true : false;
          values.useZestimate = values.useZestimate === 'yes' ? true : false;
          values.currency = hasHoldings && isCryptoOrMetals ? CurrencyOptions.USD : values.currency;

          // for real-estate
          if ('realEstate' === type) {
            values.streetAddress = values.accountName;
          }

          if (values.hasHoldings) {
            actions.setFieldTouched('balance', false);
            values = omit(values, 'balance');
          }

          // For zestimate
          if (values.useZestimate) {
            actions.setFieldTouched('balance', false);
            values = omit(values, 'balance');
          }

          handleSubmit(values);
        }}
      >
        {(props) => {
          const {
            values,
            errors,
            touched,
            setValues,
            setFieldValue,
            handleReset,
            handleChange,
            isSubmitting,
            setFieldTouched,
          } = props;

          const handleCancel = () => {
            handleReset();
            setAccountType('cash');
            setZestimateValue(undefined);
            handleClose();
          };

          const renderAction = () => {
            return (
              <div className='action-wrapper mt-3'>
                <button className='btn-outline-primary mm-btn-animate' type='button' onClick={handleCancel}>
                  Cancel
                </button>
                <button
                  className='mm-btn-animate mm-btn-primary d-flex align-items-center justify-content-center'
                  type='submit'
                  disabled={isSubmitting}
                >
                  {loading ? (
                    <>
                      <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
                      <span className='ml-1'>Adding...</span>
                    </>
                  ) : (
                    <>Next</>
                  )}
                </button>
              </div>
            );
          };

          const handleAccountChange = (e: React.ChangeEvent<any>) => {
            setAccountType(e.target.value);
            setFieldTouched(e.target.name);

            // For useZestimate
            let newValues: Partial<IManualAccount> = { ...values };
            if (e.target.value === 'Real Estate') {
              newValues = { ...newValues, useZestimate: 'no' };
            } else {
              newValues = omit(newValues, 'useZestimate');
              setValues(newValues);
            }

            return setValues({ ...newValues, [e.target.name]: e.target.value, mmAccountSubType: undefined });
          };

          const handleSelectChange = (e: React.ChangeEvent<any>) => {
            const name: string = e.target.name;
            let value = e.target.value;
            const numberFields: string[] = ['investingEntityId'];
            value = numberFields.includes(name) ? +value : value;
            setFieldTouched(name);

            return setValues({ ...values, [name]: value });
          };

          const getSelectedEntity = () => {
            if (investingEntities) {
              return investingEntities.find((entity) => +entity.id === Number(values?.investingEntityId))?.name || '';
            }

            return '';
          };

          const getInvestingEntityData = () => {
            if (investingEntities) {
              return investingEntities.map((entity) => ({
                name: entity.name,
                value: `${entity.id}`,
              }));
            }

            return [
              {
                name: '',
                value: '',
              },
            ];
          };

          const onChange = (e: TChangeEvent) => {
            const name = e.target.name;
            const value = e.target.value;
            const type = e.target.type;

            setFieldTouched(name);
            if ('number' === type) {
              if (value) {
                return setValues({ ...values, [name]: +value });
              }

              return setValues({ ...values, [name]: undefined });
            }

            if (e.target.name === 'country') {
              setZestimateValue(undefined);
            }

            handleChange(e);
          };

          const renderError = (name: keyof IManualAccount) => {
            const title = getFieldTitle(name);

            if (errors[name] && touched[name]) {
              return <div className='mt-2 feedback'>{errors[name]?.replace(':fieldName', title)}</div>;
            }

            return null;
          };

          const fetchZestimateValue = async () => {
            try {
              const { accountName, city, state, zipCode } = values;
              let address = '';
              address = accountName ? address + accountName : address;
              address = city ? address + ' ' + city : address;
              address = state ? address + ' ' + state : address;
              address = zipCode ? address + ' ' + zipCode : address;

              const { data: zillowData } = await getZestimateValue(encodeURIComponent(address));
              if (!zillowData) {
                setZestimateValue(null);
                return;
              }

              setZestimateValue(zillowData.zestimate || null);
            } catch (err) {
              logger.log('Could not fetch zillow value', err);
            }
          };

          /**
           * Individual Elements here
           */
          const accountTypeElem = (
            <li>
              <FormGroup>
                <span className='form-subheading'>{getFieldTitle('mmAccountType')}</span>
                <SelectInput
                  args={accountTypes}
                  onChange={handleAccountChange}
                  value={values.mmAccountType ?? ''}
                  name='mmAccountType'
                />
                {renderError('mmAccountType')}
              </FormGroup>
            </li>
          );

          const accountSubTypeElem = hasAccountSubType ? (
            <li>
              <div className='account-list-content'>
                <span className='form-subheading'>{getFieldTitle('mmAccountSubType')}</span>
                <SelectInput
                  args={accountSubTypes}
                  onChange={handleSelectChange}
                  value={values.mmAccountSubType ?? ''}
                  name='mmAccountSubType'
                />
              </div>
            </li>
          ) : (
            <React.Fragment />
          );

          const entityNameElem = (
            <li className='mm-form-row-double'>
              <Form.Group>
                <Form.Label className='form-subheading'>
                  {getFieldTitle('investingEntityId')}
                  <MMToolTip
                    placement='top'
                    message='You can update the list of investing entities in the settings screen.'
                  >
                    <InfoIcon />
                  </MMToolTip>
                </Form.Label>
                <MMSelectInput
                  data={getInvestingEntityData()}
                  name='investingEntityId'
                  onChange={handleSelectChange}
                  value={values.investingEntityId ?? ''}
                  title={getSelectedEntity()}
                />
                {renderError('investingEntityId')}
              </Form.Group>
            </li>
          );

          const accountNameElem = (
            <li className='full-width'>
              <FormGroup>
                <span className='form-subheading'>{getFieldTitle('accountName')}</span>
                <input
                  type='text'
                  className='w-100'
                  onChange={onChange}
                  value={values.accountName ?? ''}
                  name='accountName'
                />
                {renderError('accountName')}
              </FormGroup>
            </li>
          );

          const streetAddressElem = (
            <li>
              <FormGroup>
                <span className='form-subheading'>{getFieldTitle('streetAddress')}</span>
                <input
                  type='text'
                  name='streetAddress'
                  onChange={(e) => {
                    values.accountName = e.target.value;
                    handleChange(e);
                  }}
                  value={values.streetAddress ?? ''}
                />
                {errors.accountName ? <div className='mt-2 feedback'>Street address is required</div> : ''}
              </FormGroup>
            </li>
          );

          const cityElem = (
            <li>
              <span className='form-subheading'>{getFieldTitle('city')}</span>
              <input type='text' name='city' onChange={handleChange} value={values.city ?? ''} />
            </li>
          );

          const stateElem = (
            <li>
              <span className='form-subheading'>{getFieldTitle('state')}</span>
              <input type='text' name='state' onChange={handleChange} value={values.state ?? ''} />
            </li>
          );

          const zipCodeElem = (
            <li>
              <span className='form-subheading'>{getFieldTitle('zipCode')}</span>
              <input type='text' name='zipCode' onChange={handleChange} value={values.zipCode ?? ''} />
            </li>
          );

          const countryElem = (
            <li>
              <span className='form-subheading'>{getFieldTitle('country')}</span>
              <input type='text' name='country' onChange={onChange} value={values.country ?? ''} />
            </li>
          );

          const balanceElem =
            values.hasHoldings === 'yes' ||
            values.hasHoldings === true ||
            (values.mmAccountType === 'Real Estate' &&
              (!values.country || (values.country && isUSAText(values.country)))) ? (
              <React.Fragment />
            ) : (
              <li>
                <FormGroup>
                  <span className='form-subheading'>{getFieldTitle('balance')}</span>
                  <div className='form-field-group'>
                    <FormControl
                      onChange={onChange}
                      type='number'
                      name='balance'
                      value={values.balance ?? ''}
                      step='any'
                    />
                    <span className='input-add-on'>{CurrencySymbols[values.currency ?? ''] || '$'}</span>
                  </div>
                  {renderError('balance')}
                </FormGroup>
              </li>
            );

          const currencyElem = () => {
            const hasHoldings = values.hasHoldings === 'yes' || values.hasHoldings === true;
            const isCryptoOrMetals =
              values.mmAccountType === EMMAccountType.PRECIOUS_METALS ||
              values.mmAccountType === EMMAccountType.CRYPTOCURRENCIES;

            if (hasHoldings && isCryptoOrMetals) {
              return <React.Fragment />;
            }

            return (
              <li className='currency-select'>
                <FormGroup>
                  <span className='form-subheading'>{getFieldTitle('currency')}</span>

                  {isFreeUser ? (
                    <span className='mm-form-field-read'>{values.currency}</span>
                  ) : (
                    <CurrencyInput
                      value={values.currency ?? ''}
                      name='currency'
                      onChange={(newCurrencyValue) => setFieldValue('currency', newCurrencyValue)}
                    />
                  )}

                  {isFreeUser && (
                    <label className='mm-form-field-error text--pink'>
                      Your plan only supports USD. To enable multi currency support <a href={'/settings?active=Plan'} className='pink-links'>upgrade to pro.</a>
                    </label>
                  )}

                  {renderError('currency')}
                </FormGroup>
              </li>
            );
          };

          const liquidityElem = (
            <li>
              <span className='form-subheading'>{getFieldTitle('liquidity')}</span>
              <MMToolTip message='When can you liquidate these funds and make them available from today?'>
                <InfoIcon className='sm-hide' />
              </MMToolTip>
              <SelectInput
                args={enumerateStr(LiquidityOptions)}
                onChange={handleSelectChange}
                value={values.liquidity ?? ''}
                name='liquidity'
                sort={false}
              />
            </li>
          );

          const hasHoldingsElem = (
            <li className='full-width p-t-4'>
              <FormGroup className='input-inline'>
                <span className='form-subheading'>
                  {getFieldTitle('hasHoldings')}
                  <MMToolTip message='Answer no if you want to manage the balance of this account at the account level. Yes if you want to manage the balance at each position held in this account.'>
                    <InfoIcon className='sm-hide' />
                  </MMToolTip>
                </span>
                <div className='form-check'>
                  <FormControl
                    type='radio'
                    value='yes'
                    onChange={onChange}
                    name='hasHoldings'
                    checked={values.hasHoldings === 'yes'}
                    aria-checked={values.hasHoldings === 'yes'}
                  />
                  <label className='radio-label'>Yes</label>
                </div>
                <div className='form-check'>
                  <FormControl
                    type='radio'
                    value='no'
                    onChange={onChange}
                    name='hasHoldings'
                    checked={values.hasHoldings === 'no'}
                    aria-checked={values.hasHoldings === 'no'}
                  />
                  <label className='radio-label'>No</label>
                </div>
                {renderError('hasHoldings')}
              </FormGroup>
            </li>
          );

          const useZestimateElem =
            values.mmAccountType === 'Real Estate' && (!values.country || isUSAText(values.country)) ? (
              <li className='full-width zestimate'>
                <FormGroup>
                  <span className='form-subheading'>{getFieldTitle('useZestimate')}</span>

                  <div className='row'>
                    <div className='col-12 col-sm-6'>
                      <div className='form-check mb-2'>
                        <FormControl
                          type='radio'
                          value='no'
                          onChange={onChange}
                          name='useZestimate'
                          checked={values.useZestimate === 'no' || values.useZestimate === false}
                          aria-checked={values.useZestimate === 'no' || values.useZestimate === false}
                        />
                        <label className='radio-label'>Use my own estimate</label>
                      </div>

                      {(values.useZestimate === 'no' || values.useZestimate === false) && (
                        <FormGroup>
                          <FormControl
                            onChange={onChange}
                            type='number'
                            name='balance'
                            value={values.balance ?? ''}
                            step='any'
                            placeholder='$500,000'
                          />
                          {renderError('balance')}
                        </FormGroup>
                      )}

                      {values.useZestimate === 'yes' && zestimateValue && (
                        <>
                          <span className='zestimate-value'>
                            {currencySymbol} {numberWithCommas(fNumber(zestimateValue, 0))}
                          </span>
                          <img src={zillowProvided} alt='Provided by zillow' className='zestimate__provided-img' />
                        </>
                      )}

                      {values.useZestimate === 'yes' && zestimateValue === null && (
                        <span className='zestimate-value--not-found'>Value not found</span>
                      )}
                    </div>
                    <div className='col-12 col-sm-6'>
                      <div className='form-check mb-2'>
                        <FormControl
                          type='radio'
                          value='yes'
                          onChange={onChange}
                          name='useZestimate'
                          checked={values.useZestimate === 'yes' || values.useZestimate === true}
                          aria-checked={values.useZestimate === 'yes' || values.useZestimate === true}
                        />
                        <label className='radio-label font-weight-medium'>Use Zestimate® for home value</label>
                      </div>

                      {(values.useZestimate === 'yes' || values.useZestimate === true) && (
                        <button
                          type='button'
                          className='mm-btn-animate mm-btn-primary d-flex align-items-center justify-content-center zestimate-button'
                          onClick={() => fetchZestimateValue()}
                          disabled={!values.accountName}
                        >
                          <img src={zillowIcon} alt='Zillow Logo' className='mr-2' />
                          Check Zestimate
                        </button>
                      )}
                    </div>
                  </div>
                </FormGroup>
              </li>
            ) : (
              <React.Fragment />
            );

          const renderFormField = (name: keyof IManualAccount) => {
            const formElementMapper: Record<keyof IManualAccount, JSX.Element> = {
              mmAccountType: accountTypeElem,
              mmAccountSubType: accountSubTypeElem,
              investingEntityId: entityNameElem,
              accountName: accountNameElem,
              streetAddress: streetAddressElem,
              city: cityElem,
              state: stateElem,
              zipCode: zipCodeElem,
              country: countryElem,
              hasHoldings: hasHoldingsElem,
              balance: balanceElem,
              currency: currencyElem(),
              liquidity: liquidityElem,
              useZestimate: useZestimateElem,
            };

            return hasFormField(name) ? formElementMapper[name] || null : null;
          };

          const renderManualAccountFormFields = () => {
            return manualAccountFormFieldList.map((formField, index) => {
              return <React.Fragment key={index}>{renderFormField(formField as keyof IManualAccount)}</React.Fragment>;
            });
          };

          return (
            <Form onSubmit={props.handleSubmit} className='manual-account-form'>
              <div className='account-type'>
                <ul className='account-type-list'>{renderManualAccountFormFields()}</ul>
              </div>
              {renderAction()}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ManualAccountsForm;
