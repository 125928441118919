import React, { useCallback, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import debounce from 'lodash/debounce';
import useSize from 'common/hooks/useSize';
import { fetchMoreData } from 'discover/discover.actions';
import { useDiscoverDispatch, useDiscoverState } from 'discover/discover.context';

import Opportunity from './opportunity';
import OpportunityLoadingSkeleton from './opportunity-loading-skeleton';

const DiscoverMainSection: React.FC = () => {
  const { width } = useSize();
  const dispatch = useDiscoverDispatch();
  const { opportunities, pagination } = useDiscoverState();

  const { hasMoreData } = pagination;

  const scrollThreshold = useMemo(() => {
    return width < 768 ? '70%' : '55%';
  }, [width]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLoadMoreData = useCallback(
    debounce(() => {
      dispatch(fetchMoreData());
    }, 500),
    [dispatch]
  );

  const renderOpportunities = () => {
    return opportunities.map((opportunity, index) => (
      <div key={`${opportunity.id}-${index}`} className='col-12 col-lg-4 mb-4'>
        <Opportunity opportunity={opportunity} />
      </div>
    ));
  };

  return (
    <InfiniteScroll
      dataLength={opportunities.length}
      next={debouncedLoadMoreData}
      hasMore={hasMoreData}
      loader={<OpportunityLoadingSkeleton />}
      scrollThreshold={scrollThreshold}
      hasChildren={!!opportunities.length}
      style={{ overflowX: 'clip' }}
    >
      <div className='discover-row mt-4'>{renderOpportunities()}</div>
    </InfiniteScroll>
  );
};

export default DiscoverMainSection;
