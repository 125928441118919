export const urls = {
  auth: {
    PROFILE: '/me',
    TOKEN: '/token',
    LOGIN_IN: '/login',
    SIGN_OUT: '/signout',
    REGISTER: '/register',
    SETTINGS: '/settings/me',
    PATCH_PROFILE: '/profile',
    COMPLETE_SIGNUP: '/complete',
    PATCH_ACCOUNT: '/account/:id',
    FACEBOOK_LOGIN: '/facebook/login',
    PROFILE_PICTURE: '/profile/picture',
    PEERBOARD_TOKEN: '/peerboard/token',
    NEW_ACCOUNTS: '/account/me?new=true',
    ACCOUNTS_COUNT: '/account/me/counts',
    MANUAL_ACCOUNT: '/account/me/manual',
    UPDATE_PASSWORD: '/profile/password',
    MORTGAGE_ACCOUNT: '/account/mortgage',
    ACCOUNTS: '/account/me?refresh=false',
    ACCOUNT_CATEGORY: '/account/category',
    ACCOUNT_TYPE: '/account/account-type',
    ASSOCIATE_LOGIN: '/facebook/associate',
    COMPLETE_PROFILE: '/account/me/complete',
    RESET_PASSWORD: '/profile/password/reset',
    LOAN_ACCOUNT: '/account/me/loan-accounts',
    FORGOT_PASSWORD: '/profile/password/forgot',
    ACCOUNT_HOLDINGS: '/account-details/holdings',
    ACCOUNT_ACTIVITY: '/account-details/transactions',
    ASSOCIATE_MORTGAGE: '/account/me/mortgage-accounts',
    ACTIVITY_TYPES: '/account-details/transactions/types',
    REAL_ESTATE_ACCOUNTS: '/account/me/real-estate-accounts',
    HOLDINGS_DETAILS: '/account-details/holdings/:positionId',
    LATEST_PROVIDER_ACCOUNTS: '/account/me?latestProvider=true',
    MANUAL_ACCOUNT_TYPE: '/account/yodlee/manual/account-types',
    ACTIVITY_DETAILS: '/account-details/transactions/:activityId',
    VERIFY_RESET_PASSWORD_TOKEN: '/profile/password/verify-token',
    ACCOUNT_REFRESH: '/account/me?connectionInfo=true&refresh=true',
    CLASSIFICATION: '/allocations/asset-classifications?filter=:filter',
    IGNORED_ACCOUNTS: '/account/me?fetchIgnored=true&connectionInfo=true&fetchArchived=true',
    ACCOUNT_SUBTYPE: '/account/account-subtype?accountType=:accountType',
    ACCOUNT_CONNECTION_INFO: '/account/me?connectionInfo=true&refresh=false',
    ASSOCIATED_MORTGAGE_ACCOUNTS: '/account/real-estate/:realEstateId/mortgage',
    ACCOUNT_DETAILS: '/account/:id?connectionInfo=true&baseCurrency=:baseCurrency',
    ASSOCIATED_REAL_ESTATE_ACCOUNTS: '/account/mortgage/:mortgageAccId/real-estate',
    ACCOUNTS_WITH_PROVIDER: '/account/me?baseCurrency=true&connectionInfo=true&refresh=false',
    HOLDINGS_ACCOUNTS_BY_DESCRIPTION: '/account-details/holdings/accounts?description=:description',
    FORM_FIELD_FILTER: '/account/form-fields-filter?accountType=:accountType&accountSubType=:accountSubType',
  },
  subscription: {
    CURRENT_SUB: '/subscription/me',
    SUB: '/subscription/stripe/subscriptions',
    STRIPE_CHECKOUT: '/subscription/stripe/checkout',
    CANCEL: '/subscription/me/cancel',
    UPDATE_PAYMENT_METHOD: '/subscription/stripe/checkout/update-payment',
  },
  yodlee: {
    FAST_LINK: '/account/yodlee/fastlink',
    FAST_LINK_UPDATE: '/account/yodlee/fastlink?update=:update&refresh=:refresh&accountId=:accountId',
  },
  networth: {
    NETWORTH: '/networth',
    NETWORTH_BY_TYPE: '/networth/types',
    NW_SIMILAR: '/networth/similar',
    NETWORH_CHART: '/networth/chart',
    NETWORTH_HISTORY: '/networth/history',
  },
  performance: {
    PERFORMANCE: '/performance',
  },
  allocations: {
    ALLOCATIONS: '/allocations',
    SIMILAR_ALLOCATIONS: '/allocations/similar',
    CHART_SETTINGS: '/allocations/me/chart-settings',
    UPLOAD_CHART: '/allocations/upload-chart',
    HOLDING_TYPES: '/allocations/holding-types',
  },
  account: {
    ZABO_ACCOUNT: '/zabo/account',
    BALANCES: '/account-details/balances',
    BALANCE_ACCOUNT: '/account-details/balances/account/:accountId',
    CLASSIFICATION_STATUS: '/account/:accountId/classification-status',
    MANUAL_CRYPTO_ACCOUNTS: '/account/me/manual-crypto-accounts',
    MANUAL_METAL_ACCOUNTS: '/account/me/manual-precious-metal-accounts',
    MORTGAGE_PAYMENT: '/account/calculate-mortgage-payment',
    UPDATE_ACCOUNT_BALANCE: '/account-details/:accountId/daily-balances',
    UPDATE_BALANCE_RANGE: '/account-details/:accountId/balance-range',
    DAILY_BALANCES: '/account-details/:accountId/daily-balances?fromDate=:fromDate&toDate=:toDate',
    DELETE_DAILY_BALANCE: '/account-details/:accountId/daily-balances',
    PORTFOLIO_MOVERS_ACCOUNTS: '/account-details/portfolio-movers/accounts',
    PORTFOLIO_MOVERS_HOLDINGS: '/account-details/portfolio-movers/holdings',
    ACCOUNTS_HISTORY: '/account/me/history',
    ACCOUNTS_ASSETS: '/account/me/assets',
    ACCOUNTS_ASSETS_HISTORY: '/account/me/assets/history',
    ACCOUNTS_LIABILITIES: '/account/me/liabilities',
    ACCOUNTS_LIABILITIES_HISTORY: '/account/me/liabilities/history',
    ACCOUNT_TRANSACTIONS: '/account-details/transactions/all',
    ACCOUNT_TYPES: '/account/me/account-type',
    ACCOUNT_DATA_BY_TYPE: '/account/me/types',
    ACCOUNT_TYPE_HOLDINGS: '/account-details/holdings/account-types',
  },
  investingEntity: {
    INVESTING_ENTITY: '/investing-entity',
    INVESTING_ENTITY_LIST: '/investing-entity/me',
  },
  saltEdge: {
    CONNECT: '/salt-edge/connect?apiType=PARTNER_API',
    CONNECT_WORLD: '/salt-edge/connect?apiType=OPEN_BANKING_GATEWAY_API',
  },
  filter: {
    FILTER: '/filter',
    FILTER_BY_ID: '/filter/:id',
    CURRENT_FILTER: '/filter/me',
  },
  insights: {
    ALL: '/insights?status=ALL',
    MARK_READ: '/insights/read',
  },
  zestimate: {
    VALUE: '/zillow?address=',
  },
  coinGecko: {
    GET_NAME: '/coin-gecko/coin?name=',
    PATCH_COIN_GECKO: '/coin-gecko/account/:accountId/coin',
    GET_COINS_FOR_ACCOUNT: '/coin-gecko/account/:accountId/coin',
    DELETE_GECKO_COIN: '/coin-gecko/account/:accountId/coin/:coinGeckoId',
  },
  preciousMetals: {
    ALL: '/precious-metal',
    PATCH_PRECIOUS_METAL: '/precious-metal/account/:accountId/metal',
    GET_METALS_FOR_ACCOUNT: '/precious-metal/account/:accountId/metal',
    DELETE_PRECIOUS_METAL: '/precious-metal/account/:accountId/metal/:metalId',
    GET_METAL_VALUE: '/precious-metal/:metalSymbol/market-value?quantity=:quantity&unit=:unit&karat=:karat',
  },
  lease: {
    ALL: '/:accountId/lease',
    POST_LEASE: '/:accountId/lease',
    PATCH_LEASE: '/:accountId/lease/:leaseId',
    DELETE_LEASE: '/:accountId/lease/:leaseId',
  },
  mfa: {
    MFA_RESEND: '/resend-mfa',
    MFA_DISABLE: '/settings/me',
    MFA_VERIFY_AUTH: '/verify-mfa',
    MFA_ENABLE: '/settings/me/enable-mfa',
    MFA_VERIFY: '/settings/me/verify-mfa',
  },
  log: {
    USER_LOG: '/user-log',
  },
  refer: {
    REFERRED_USERS: '/profile/referred-user',
  },
  qroka: {
    QROKA_ACCOUNT: '/qroka/account',
    QROKA_CONNECTION_URL: '/qroka/connection-url',
  },
  income: {
    INCOME: '/income',
  },
  discover: {
    COMPANIES: '/investment-opportunity',
  },
  featured: {
    FEATURED: '/featured',
  },
};
