import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import AppHeader from 'common/app.header';
import AppFooter from 'common/app.footer';
import AppSidebar from 'common/app.sidebar';

const DiscoverLayout: React.FC = ({ children }) => {
  const [openRightNav, setOpenRightNav] = useState<boolean>(false);
  const [openLeftNav, setOpenLeftNav] = useState<boolean>(false);
  const closeRightNav = () => setOpenRightNav(false);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Discover | Money Minx</title>
          <meta name='description' content='Discover, discuss and profit from new investment opportunities.' />
          {/*Facebook tags*/}
          <meta property='og:title' content='Community | Money Minx' />
          <meta property='og:description' content='Discover, discuss and profit from new investment opportunities.' />
          <meta property='og:url' content='https://www.moneyminx.com/community' />
          <meta property='og:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Discover.png' />
          <meta property='og:image:secure_url' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Discover.png' />
          <meta property='og:image:alt' content='Discover, discuss and profit from new investment opportunities.' />
          {/*Twitter tags*/}
          <meta property='twitter:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Discover.png' />
          <meta property='twitter:image:alt' content='Discover, discuss and profit from new investment opportunities.' />
        </Helmet>
      </HelmetProvider>
      <AppHeader
        toggleLeftMenu={() => setOpenLeftNav(!openLeftNav)}
        toggleRightMenu={() => setOpenRightNav(!openRightNav)}
        open={openRightNav}
        shadow={true}
      />
      <AppSidebar openLeft={openLeftNav} openRight={openRightNav} />
      <div className='mm-slider-bg-overlay' onClick={closeRightNav} role='button' />
      {children}
      <AppFooter />
    </>
  );
};

export default DiscoverLayout;
