import { useState } from 'react';

import { ConnectType, useWallet } from '@terra-money/wallet-provider';

const useTerraSupportWallet = () => {
  const terraStationWallet = useWallet();
  const [error, setError] = useState<string>('');

  if (!terraStationWallet) {
    return {
      connectWithExtension: () => {},
      connectWithMobile: () => {},
      wallets: [],
      error: '',
    };
  }

  const { connect, availableConnectTypes, wallets, disconnect } = terraStationWallet;

  const isExtenstionSupported = availableConnectTypes.includes(ConnectType.EXTENSION);
  const isMobileWalletSupported = availableConnectTypes.includes(ConnectType.WALLETCONNECT);

  return {
    error,
    wallets,
    connectWithExtension: () => {
      if (isExtenstionSupported) {
        return connect(ConnectType.EXTENSION);
      }

      return setError('Please install terra support wallet extension');
    },
    connectWithMobile: () => {
      if (isMobileWalletSupported) {
        return connect(ConnectType.WALLETCONNECT);
      }

      return setError('Please install terra support wallet mobile app');
    },

    disconnect: () => {
      return disconnect();
    },
  };
};

export default useTerraSupportWallet;
