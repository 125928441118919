import React from 'react';
import { Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import useSettings from 'setting/hooks/useSettings';
import { appRouteConstants } from 'app/app-route.constant';
import { ReactComponent as BackImg } from 'assets/icons/back.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';

import AppSubHeader from '../../common/app.sub-header';
import { AccountSubNavigationProps } from '../account.type';

export const AccountSubNavigation: React.FC<AccountSubNavigationProps> = ({
  AccountDetails,
  baseCurrency,
  mmAccountType,
  toggleBaseCurrency,
}) => {
  const history = useHistory();
  const { data } = useSettings();

  const handleNavigateToAccountType = () => {
    if (!mmAccountType) {
      return;
    }

    const path = appRouteConstants.account.ACCOUNT_TYPES.replace(':type', mmAccountType);
    return history.push(path);
  };

  const handleNavigateToDashboard = () => {
    const path = appRouteConstants.dashboard.DASHBOARD;

    return history.replace(path);
  };

  return (
    <section>
      <div className='content-container mm-account-sub-nav'>
        <div className='app-subheader-container px-4 account align-items-center'>
          {!!mmAccountType ? (
            <div className='d-flex align-items-center account-types-nav'>
              <button className='account-types-head__back' onClick={handleNavigateToAccountType}>
                <BackImg />
              </button>

              <button className='account-types-head__from' onClick={handleNavigateToDashboard}>
                Dashboard
                <ArrowRight className='ml-3' />
              </button>
              <button className='account-types-head__account-type ml-3' onClick={handleNavigateToAccountType}>
                {mmAccountType}
                <ArrowRight className='ml-3' />
              </button>

              <span className='account-name'>{AccountDetails.accountName}</span>
            </div>
          ) : (
            <AppSubHeader AccountDetails={AccountDetails} />
          )}

          {AccountDetails.providerLogo ? (
            <Image src={AccountDetails.providerLogo} className='providerLogo d-none d-md-block' />
          ) : (
            <span className='providerLogo d-none d-md-block'>
              {AccountDetails.providerName ? AccountDetails.providerName : ''}
            </span>
          )}
          <span className='mm-switch-block align-items-center base-currency-toggle'>
            <input
              value='true'
              name='baseCurrency'
              type='checkbox'
              aria-checked={baseCurrency}
              className='mm-switch-input'
              checked={baseCurrency}
            />
            <label className='mm-switch' onClick={toggleBaseCurrency} role='button' />
            <span className='d-none d-md-block ml-2 view-in-base-currency'>View in base currency</span>
            <span className='ml-2 view-in-base-currency'> ({data?.currency})</span>
          </span>
        </div>
        <div className='d-md-none pb-3 text-center'>
          {AccountDetails.providerLogo ? (
            <Image src={AccountDetails.providerLogo} className='s-providerLogo' />
          ) : (
            <span>{AccountDetails.providerName ? AccountDetails.providerName : ''}</span>
          )}
        </div>
      </div>
    </section>
  );
};

export default AccountSubNavigation;
