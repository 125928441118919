import { IFormFieldItem } from 'auth/auth.types';
import { camelCase } from 'common/helper/string.helper';
import { fNumber, numberWithCommas } from 'common/number.helper';

import { holdingFields, financialFields, numericalFields, defaultFields } from '../data/add-holding-fields.json';

export interface IAddHoldingFields {
  securities: IFormFieldItem[];
  businesses: IFormFieldItem[];
  collectibles: IFormFieldItem[];
  nfTs: IFormFieldItem[];
  stockOptions: IFormFieldItem[];
  startups: IFormFieldItem[];
}

export type TAddHoldingKeys = keyof IAddHoldingFields;
export type TPartialHoldingTableKey = Partial<TAddHoldingKeys>;

class AddHoldingHelper {
  private key?: TAddHoldingKeys;
  private currentFormFields: IFormFieldItem[];
  private fields: IAddHoldingFields = holdingFields;
  private financialFields: string[] = financialFields;
  private numericalFields: string[] = numericalFields;

  constructor(type?: string) {
    this.key = type ? (camelCase(type) as TAddHoldingKeys) : undefined;
    this.currentFormFields = this.key ? (this.fields[this.key] ? this.fields[this.key] : defaultFields) : [];
  }
  get() {
    return this.currentFormFields || [];
  }

  getField(name: string) {
    return this.currentFormFields?.find((f) => f.name === name);
  }

  getTitle(name: string) {
    return this.getField(name)?.label || '';
  }

  hasValue(value: any): boolean {
    return value ?? false;
  }

  formatValue(name: string, value: any, currencySymbol: string) {
    if (this.financialFields.includes(name)) {
      return this.hasValue(value) ? `${currencySymbol} ${numberWithCommas(fNumber(value, 2))}` : '--';
    }

    if (this.numericalFields.includes(name)) {
      return this.hasValue(value) ? numberWithCommas(fNumber(value, 2)) : '--';
    }

    return this.hasValue(value) ? value : '--';
  }

  getHoldingAction(): string {
    const mapper: Record<string, string> = {
      securities: 'Security',
      collectibles: 'Collectible',
      businesses: 'Business',
      nfTs: 'NFT',
      stockOptions: 'Grant',
      startups: 'Startup',
    };

    return this.key ? (mapper[this.key] ? mapper[this.key] : '') : '';
  }
}

const addHoldingFields = (type?: string) => new AddHoldingHelper(type);

export default addHoldingFields;
