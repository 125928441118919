import React from 'react';
import omit from 'lodash/omit';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import { AiOutlineClose } from 'react-icons/ai';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';

import { TChangeEvent } from 'common/dom.types';
import { isUSAText } from 'common/country.helper';
import { EMMAccountType } from 'account/enum/account-type';
import { CurrencySymbols } from 'auth/enum/currency-options';
import { IFormField, IManualAccount } from 'auth/auth.types';
import { StripeSubscriptionStatus } from 'setting/setting.enum';
import { ReactComponent as BackImg } from 'assets/icons/back.svg';
import { addManualAccountValidation } from 'auth/auth.validation';
import CurrencyInput from 'common/components/input/currency.input';
import useCurrentSubscription from 'auth/hooks/useCurrentSubscription';
import { manualAccountFormFieldList } from 'auth/data/manual-account.data';
import useManualAccountFormModalFields from 'auth/hooks/useManualAccountFormModalFields';

interface IManualAccountHoldingsProps {
  loading: boolean;
  initialFormValues: Partial<IManualAccount>;
  onBack: VoidFunction;
  onClose: VoidFunction;
  handleSubmit: (formValues: Partial<IManualAccount>) => void;
}

const ManualAccountHoldings: React.FC<IManualAccountHoldingsProps> = ({
  loading,
  initialFormValues,
  onBack,
  onClose,
  handleSubmit,
}) => {
  const { currentSubscription } = useCurrentSubscription();

  const { hasHoldingFormField, getHoldingFieldTitle } = useManualAccountFormModalFields(
    initialFormValues.mmAccountType as keyof IFormField
  );

  const isFreeUser =
    currentSubscription?.subscriptionStatus !== StripeSubscriptionStatus.TRIALING &&
    currentSubscription?.subscriptionStatus !== StripeSubscriptionStatus.ACTIVE;

  return (
    <div className='manual-account__body mm-manual-account-modal mt-3'>
      <Formik
        validationSchema={addManualAccountValidation}
        initialValues={initialFormValues}
        onSubmit={async (values, actions) => {
          if (values.hasHoldings) {
            actions.setFieldTouched('balance', false);
            values = omit(values, 'balance');
          }

          handleSubmit(values);
        }}
      >
        {(props) => {
          const {
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setValues,
            setFieldTouched,
          } = props;

          const onChange = (e: TChangeEvent) => {
            const name = e.target.name;
            const value = e.target.value;
            const type = e.target.type;

            setFieldTouched(name);
            if ('number' === type) {
              if (value) {
                return setValues({ ...values, [name]: +value });
              }

              return setValues({ ...values, [name]: undefined });
            }

            handleChange(e);
          };

          const renderError = (name: keyof IManualAccount) => {
            const title = getHoldingFieldTitle(name);

            if (errors[name] && touched[name]) {
              return <div className='mt-2 feedback'>{errors[name]?.replace(':fieldName', title)}</div>;
            }

            return null;
          };

          const renderAction = () => {
            return (
              <div className='action-wrapper mt-3'>
                <button className='btn-outline-primary mm-btn-animate' type='button' onClick={onClose}>
                  Cancel
                </button>
                <button
                  className='mm-btn-animate mm-btn-primary d-flex align-items-center justify-content-center'
                  type='submit'
                  disabled={isSubmitting}
                >
                  {loading ? (
                    <>
                      <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
                      <span className='ml-1'>Adding...</span>
                    </>
                  ) : (
                    <>Next</>
                  )}
                </button>
              </div>
            );
          };

          const balanceElem =
            values.hasHoldings === 'yes' ||
            values.hasHoldings === true ||
            (values.mmAccountType === 'Real Estate' &&
              (!values.country || (values.country && isUSAText(values.country)))) ? (
              <React.Fragment />
            ) : (
              <li>
                <FormGroup>
                  <span className='form-subheading'>{getHoldingFieldTitle('balance')}</span>
                  <div className='form-field-group'>
                    <FormControl
                      onChange={onChange}
                      type='number'
                      name='balance'
                      value={values.balance ?? ''}
                      step='any'
                    />
                    <span className='input-add-on'>{CurrencySymbols[values.currency ?? ''] || '$'}</span>
                  </div>
                  {renderError('balance')}
                </FormGroup>
              </li>
            );

          const currencyElem = () => {
            const hasHoldings = values.hasHoldings === 'yes' || values.hasHoldings === true;
            const isCryptoOrMetals =
              values.mmAccountType === EMMAccountType.PRECIOUS_METALS ||
              values.mmAccountType === EMMAccountType.CRYPTOCURRENCIES;

            if (hasHoldings && isCryptoOrMetals) {
              return <React.Fragment />;
            }

            return (
              <li className='currency-select'>
                <FormGroup>
                  <span className='form-subheading'>{getHoldingFieldTitle('currency')}</span>

                  {isFreeUser ? (
                    <span className='mm-form-field-read'>{values.currency}</span>
                  ) : (
                    <CurrencyInput
                      value={values.currency ?? ''}
                      name='currency'
                      onChange={(newCurrencyValue) => setFieldValue('currency', newCurrencyValue)}
                    />
                  )}

                  {isFreeUser && (
                    <label className='mm-form-field-error text--pink'>
                      Your plan only supports USD. To enable multi currency support <a href={'/settings?active=Plan'} className='pink-links'>upgrade to pro.</a>
                    </label>
                  )}

                  {renderError('currency')}
                </FormGroup>
              </li>
            );
          };

          const renderFormField = (name: keyof IManualAccount) => {
            const formElementMappper: any = {
              balance: balanceElem,
              currency: currencyElem(),
            };

            return hasHoldingFormField(name) ? formElementMappper[name] || null : null;
          };

          const renderHoldingsField = () => {
            return manualAccountFormFieldList.map((formField, index) => {
              return <React.Fragment key={index}>{renderFormField(formField as keyof IManualAccount)}</React.Fragment>;
            });
          };

          return (
            <div className='app-modal manual-account'>
              <div className='app-modal__header manual-account__header'>
                <div className='d-flex align-items-center mt-2'>
                  <BackImg role='button' onClick={onBack} />
                  <h2 className='app-modal__title'>Current Balance</h2>
                </div>

                <button onClick={onClose}>
                  <AiOutlineClose className='modal-close' />
                </button>
              </div>

              <p className='app-modal__description'>Let's get the account started with the right balance.</p>

              <Form onSubmit={handleSubmit} className='manual-account-form manual-account-holdings-form'>
                <div className='account-type'>
                  <ul className='account-type-list'>{renderHoldingsField()}</ul>
                </div>
                {renderAction()}
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default ManualAccountHoldings;
