import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { ToastContainer } from 'react-toastify';

import Main from 'app/main';
import * as Sentry from '@sentry/react';
import { AppProvider } from 'app/app.context';
import { AuthProvider } from 'auth/auth.context';

import env from './app.env';
import { EAppEnv } from './app.enum';

// tslint:disable-next-line: ordered-imports
import 'assets/css/app.scss';
import 'react-input-range/lib/css/index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.min.css';

function App() {
  return (
    <Sentry.ErrorBoundary fallback={Fallback}>
      <CookiesProvider>
        <AppProvider>
          <AuthProvider>
            <Main />
          </AuthProvider>
          <ToastContainer />
        </AppProvider>
      </CookiesProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;

export const Fallback = ({ error, componentStack, resetError }: any) => {
  if (env.APP_ENV === EAppEnv.PROD) {
    return <React.Fragment />;
  }

  return (
    <div className='error-fallback-wrapper'>
      <h4 className='error-message'>Sorry, Something went wrong!!</h4>
      <ErrorTraceContainer error={error} componentStack={componentStack} />
      <button onClick={resetError} className='btn btn-secondary'>
        Reset
      </button>
    </div>
  );
};

export const ErrorTraceContainer = (props: any) => {
  const { error = '', componentStack } = props;

  return (
    <div className='error-trace-container'>
      <div className='error-title'>{error?.toString()}</div>
      <div className='error-stack'>{componentStack}</div>
    </div>
  );
};
