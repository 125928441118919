import { ECryptoConnections } from 'auth/auth.enum';
import { providerLogo } from 'common/data/crypto-provider-logo';

export const cryptoConnections = [
  {
    name: 'Vinovest',
    url: 'www.vinovest.co',
    type: ECryptoConnections.VINOVEST,
    logo: <img src={providerLogo.Vinovest} alt='Vinovest' />,
  },
  {
    name: 'Binance',
    url: 'www.binance.com',
    logo: <img src={providerLogo.Binance} alt='Binance' />,
    type: ECryptoConnections.BINANCE,
  },
  {
    name: 'Binance.us',
    url: 'www.binance.us',
    type: ECryptoConnections.BINANCE_US,
    logo: <img src={providerLogo['Binance US']} alt='Binance US' />,
  },
  {
    name: 'Coinbase',
    url: 'www.coinbase.com',
    type: ECryptoConnections.COINBASE,
    logo: <img src={providerLogo.Coinbase} alt='Coinbase' />,
  },
  {
    name: 'Coinbase Pro',
    url: 'pro.coinbase.com',
    type: ECryptoConnections.COINBASE_PRO,
    logo: <img src={providerLogo['Coinbase Pro']} alt='Coinbase Pro' />,
  },
  {
    name: 'Ledger',
    url: 'www.ledger.com',
    type: ECryptoConnections.LEDGER,
    logo: <img src={providerLogo.Ledger} alt='Ledger' />,
  },
  {
    name: 'MetaMask',
    url: 'www.metamask.io',
    type: ECryptoConnections.METAMASK,
    logo: <img src={providerLogo.Metamask} alt='Metamask' />,
  },
  {
    name: 'Gemini',
    url: 'www.gemini.com',
    type: ECryptoConnections.GEMINI,
    logo: <img src={providerLogo.Gemini} alt='Gemini Wallet' />,
  },
  {
    name: 'Celsius',
    url: 'celsius.network',
    type: ECryptoConnections.CELSIUS,
    logo: <img src={providerLogo.Celsius} alt='Celsius' />,
  },
  {
    name: 'Trust Wallet',
    url: 'trustwallet.com',
    type: ECryptoConnections.TRUST_WALLET,
    logo: <img src={providerLogo['Trust Wallet']} alt='Trust Wallet' />,
  },
  {
    name: 'Bittrex',
    url: 'www.bittrex.com',
    type: ECryptoConnections.BITTREX,
    logo: <img src={providerLogo.Bittrex} alt='Bittrex' />,
  },
  {
    name: 'Coinbase Wallet',
    url: 'wallet.coinbase.com',
    type: ECryptoConnections.COINBASE_WALLET,
    logo: <img src={providerLogo['Coinbase Wallet']} alt='Coinbase Wallet' />,
  },
  {
    name: 'Ethereum Address',
    url: 'www.ethereum.org',
    type: ECryptoConnections.ETHEREUM,
    logo: <img src={providerLogo.Ethereum} alt='Ethereum' />,
  },
  {
    name: 'Bitcoin Address',
    url: 'www.bitcoin.org',
    type: ECryptoConnections.BITCOIN,
    logo: <img src={providerLogo.Bitcoin} alt='Bitcoin' />,
  },
  {
    name: 'Binance Smart Chain Address',
    url: 'www.binance.org',
    type: ECryptoConnections.BINANCE_SMART_CHAIN,
    logo: <img src={providerLogo['Binance Smart Chain']} alt='Binance Smart Chain' />,
  },
  {
    name: 'Terra Address',
    url: 'www.terra.money',
    type: ECryptoConnections.TERRA,
    logo: <img src={providerLogo.Terra} alt='Terra' />,
  },
  {
    name: 'Terra Station',
    url: 'station.terra.money',
    type: ECryptoConnections.TERRA_STATION_WALLET,
    logo: <img src={providerLogo['Terra Station Wallet']} alt='Terra Station Wallet' />,
  },
  {
    name: 'Coinbase Wallet',
    url: 'wallet.coinbase.com',
    type: ECryptoConnections.COINBASE_WALLET,
    logo: <img src={providerLogo['Coinbase Wallet']} alt='Coinbase Wallet' />,
  },
];

export type TCryptoConnection = typeof cryptoConnections[0];
