import React from 'react';

import { storage } from 'app/app.storage';
import { StorageKey } from 'app/app.types';
import { setSetting } from 'app/app.actions';
import { useDispatch } from 'app/app.context';
import { Dispatch } from 'common/common.types';
import { setLoginSuccess } from 'auth/auth.actions';
import { getRefreshedAccount } from 'auth/auth.service';
import { patchDisableMFA, postEnableMFA, postResendMFA, postVerifyAuthMFA, postVerifyMFA } from 'api/request.api';

const useMFA = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [codeError, setCodeError] = React.useState(false);
  const [emailSent, setEmailSent] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (emailSent) {
        setEmailSent(false);
      }

      if (codeError) {
        setCodeError(false);
      }
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [emailSent, codeError]);

  const enableMFA = async () => {
    setLoading(true);
    const { error, data } = await postEnableMFA();
    setLoading(false);

    if (!error) {
      dispatch(setSetting(data));

      return { error, data };
    }

    return { error, data };
  };

  const disableMFA = async () => {
    setLoading(true);
    const { error, data } = await patchDisableMFA();
    setLoading(false);

    if (!error) {
      dispatch(setSetting(data));

      return { error, data };
    }

    return { error, data };
  };

  const verifyMFA = async (code: string) => {
    setLoading(true);
    const { error, data } = await postVerifyMFA(+code);
    setLoading(false);

    if (!error) {
      dispatch(setSetting(data));
      setCodeError(false);

      return { error, data };
    }

    setCodeError(true);

    return { error, data };
  };

  const resendMFA = async () => {
    const { error, data } = await enableMFA();

    if (!error) {
      setEmailSent(true);
    }

    return { error, data };
  };

  const resendAuthMFA = async () => {
    setLoading(true);
    const { error, data } = await postResendMFA();
    setLoading(false);

    if (!error) {
      setEmailSent(true);
    }

    return { error, data };
  };

  const verifyAuthMFA = (authDispatch: Dispatch) => async (code: number, rememberDevice: boolean) => {
    setLoading(true);
    const { error, data } = await postVerifyAuthMFA({
      code,
      rememberDevice,
      token: storage.accessToken(),
    });

    if (error) {
      setCodeError(true);
    }

    if (!error) {
      storage.set(StorageKey.AUTH, data);
      await getRefreshedAccount({ dispatch: authDispatch });

      authDispatch(setLoginSuccess(data));
    }

    setLoading(false);
    return { error, data };
  };

  return {
    loading,
    enableMFA,
    verifyMFA,
    disableMFA,
    codeError,
    emailSent,
    resendMFA,
    resendAuthMFA,
    setEmailSent,
    verifyAuthMFA,
  };
};

export default useMFA;
