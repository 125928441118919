import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useLocation, Redirect } from 'react-router-dom';

import { storage } from 'app/app.storage';
import classNames from 'common/classes.helper';
import { AuthLayout } from 'layouts/auth.layout';
import LoadingScreen from 'common/loading-screen';
import LoginForm from 'auth/components/login-form';
import LoginMFAForm from 'auth/components/login-mfa-form';
import { ReactComponent as LogoImg } from 'assets/icons/logo.svg';
import LoginGuideContent from 'auth/components/login-guide-content';

const Login = () => {
  return (
    <AuthLayout>
      <HelmetProvider>
        <Helmet>
          <title>Log in | Money Minx</title>
          <meta
            name='description'
            content='Login to your Money Minx account and start tracking your net worth and investments in one place.'
          />
          {/*Facebook tags*/}
          <meta property='og:title' content='Login | Money Minx' />
          <meta
            property='og:description'
            content='Login to your Money Minx account and start tracking your net worth and investments in one place.'
          />
          <meta property='og:url' content='https://www.moneyminx.com/signup' />
          <meta
            property='og:image'
            content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png'
          />
          <meta
            property='og:image:secure_url'
            content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png'
          />
          <meta
            property='og:image:alt'
            content='Login to your Money Minx account and start tracking your net worth and investments in one place.'
          />
          {/*Twitter tags*/}
          <meta
            property='twitter:image'
            content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png'
          />
          <meta
            property='twitter:image:alt'
            content='Login to your Money Minx account and start tracking your net worth and investments in one place.'
          />
        </Helmet>
      </HelmetProvider>
      <LoginMainSection />
    </AuthLayout>
  );
};

export default Login;
interface IFrom {
  from: {
    pathname: string;
  };
}

export const LoginMainSection = () => {
  const { search } = useLocation();

  const location = useLocation<IFrom>();
  const isAuthenticated = storage.isSignedIn();
  const [refreshLoading, setRefreshLoading] = useState<boolean>(false);

  const [redirectToReferrer, setRedirectToReferrer] = useState(false);

  const { from }: { from: { pathname: string } } = location.state || { from: { pathname: '/dashboard' } };

  const hasMFA = search.includes('?action=mfa');
  const isExpired = search.includes('?expired=true');
  const isLoggedOut = search.includes('?action=logout');

  useEffect(() => {
    setRedirectToReferrer(isAuthenticated);
  }, [isAuthenticated]);

  if (redirectToReferrer) {
    return <Redirect to={from} />;
  }

  const renderFormContent = () => {
    if (hasMFA) {
      return <LoginMFAForm setRefreshLoading={setRefreshLoading} />;
    }

    return <LoginForm refreshLoading={setRefreshLoading} />;
  };

  return (
    <div className='main-table-wrapper'>
      {refreshLoading ? (
        <LoadingScreen />
      ) : (
        <div className=''>
          <div className='row login-wrapper'>
            <LoginGuideContent />
            <div className='bg-white credentials-wrapper'>
              <div className='credentials-content'>
                <Link to='/' className='logo-img-wrapper'>
                  <LogoImg className='auth-logo' />
                </Link>
                <h2>Welcome back</h2>
                <p>Your accounts are ready for you. Hope you will reach your goals</p>
                <div className={classNames('session-expired', !isLoggedOut && 'hide-me')}>
                  <p>Thanks for visiting. See you next time.</p>
                </div>
                <div className={classNames('session-expired', !isExpired && 'hide-me')}>
                  <p>We thought you left, so we logged you out to protect your account.</p>
                </div>
                <div className={classNames('session-expired', !hasMFA && 'hide-me')}>
                  <p>Enter the code we emailed you to continue.</p>
                </div>
                {renderFormContent()}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
