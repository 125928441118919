export interface Children {
  children: React.ReactNode;
}

export type Dispatch = (action: any) => void;

export interface StringKeyObject {
  [key: string]: any;
}

export enum FileType {
  SVG = 'SVG',
  PNG = 'PNG',
}

export enum ERequestStatus {
  INITIAL,
  PROCESSING,
  WITH_DATA,
  WITH_ERROR,
}

/**
 * FIXME: avoid repetition
 */
export enum EState {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  WITH_DATA = 'WITH_DATA',
  WITH_ERROR = 'WITH_ERROR',
}

export interface ICurrencyOption {
  abbr: string;
  name: string;
  imageURL: string;
}

export enum ECookie {
  REFER = '_mm_refer_code',
}

/**
 * Money minx connections status.
 */
export enum EConnectionStatus {
  GOOD = 'GOOD',
  WAIT = 'WAIT',
  STALE = 'STALE',
  ERROR = 'ERROR',
  ARCHIVED = 'ARCHIVED',
  ATTENTION = 'ATTENTION',
  REFRESHING = 'REFRESHING',
}

export enum EWebSocketEvent {
  TEST = 'test',
  SYNC_COMPLETE = 'sync-complete',
  ACCOUNT_STATUS_UPDATE = 'account-status-update',
}
