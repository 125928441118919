import { toast, ToastContent, ToastOptions } from 'react-toastify';

import env from 'app/app.env';
import { useRef } from 'react';

export enum TEnv {
  DEV = 'development',
  PROD = 'production',
}

const useToast = () => {
  const mmToast = (content: ToastContent, options?: ToastOptions) => {
    if (TEnv.DEV === env.APP_ENVIRONMENT) {
      toast(content, options);
    }
  };

  const toastRef = useRef(mmToast);

  return {
    mmToast,
    toastRef,
  };
};

export default useToast;
