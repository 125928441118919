import { useLocation } from 'react-router-dom';

// FIXME: This file and useSearchParam are very similar.

const useMMLocation = () => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  return {
    location,
    search: {
      get: (key: string) => params.get(key),
    },
    removeParam: (key: string) => params.delete(key),
    addQuery: (existingQuery: string, newQuery: string) => {
      const existingParams = new URLSearchParams(existingQuery);
      const newParams = new URLSearchParams(newQuery);

      return existingParams.toString() + '&' + newParams.toString();
    },
  };
};

export default useMMLocation;
