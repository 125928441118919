import React from 'react';

import ICONS from 'common/components/icons';

interface IAllocationSortIcon {
  order: 'ASC' | 'DESC';
}

const AllocationSortIcon = (props: IAllocationSortIcon) => {
  if (props.order === 'DESC') {
    return <ICONS.ARROW_DOWN />;
  }

  return <ICONS.ARROW_DOWN className='rotate-180 m-0 p-0' />;
};

export default AllocationSortIcon;
