import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import useSize from 'common/hooks/useSize';
import { appRouteConstants } from 'app/app-route.constant';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as MoneyMinxLogoMobile } from 'assets/icons/money-minx-logo-mobile.svg';

interface LeftMenuType {
  all: string;
  menus: string;
}
const WebsiteHeader = () => {
  const { width } = useSize();
  const { pathname, search } = useLocation();
  const [expand, setExpand] = useState<keyof LeftMenuType>();

  const handleToggleMenu = () => {
    if (expand) {
      setExpand(undefined);
      return;
    }

    if (width <= 768) {
      setExpand('all');
    }
  };

  const navClass = (label: string) => (pathname.includes(label) ? 'mm-nav-item active' : 'mm-nav-item');

  return (
    <div>
      <a className='home-banner' href='/blog/money-minx-is-joining-arta-financial/'>
        <span className='banner-text'>Money Minx has been acquired by Arta Finance</span>
        <svg width='1.5rem' height='1.5rem' className='icon icon-tabler icon-tabler-confetti'
             viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' fill='none'
             stroke-linecap='round' stroke-linejoin='round'>
          <path stroke='none' d='M0 0h24v24H0z' fill='none'/>
          <path d='M4 5h2'/>
          <path d='M5 4v2'/>
          <path d='M11.5 4l-.5 2'/>
          <path d='M18 5h2'/>
          <path d='M19 4v2'/>
          <path d='M15 9l-1 1'/>
          <path d='M18 13l2 -.5'/>
          <path d='M18 19h2'/>
          <path d='M19 18v2'/>
          <path
            d='M14 16.518l-6.518 -6.518l-4.39 9.58a1.003 1.003 0 0 0 1.329 1.329l9.579 -4.39z'/>
        </svg>
      </a>
    <nav className='mm-navbar navbar-expand-lg navbar-light'>
      <div className='logo-btn-wrapper'>
        <Link className='navbar-brand' to='/' aria-label='Money Minx'>
          <Logo role='img' aria-labelledby='mmLogoTitle mmLogoDesc' />
        </Link>
      </div>
      <div className='right-menu-wrapper'>
        <div className={`${expand === 'all' ? 'expand-all nav-none' : 'expand-all nav-all'}`}>
          <div className={`menu-btn-wrapper`}>
            <div className={`menu-list-wrapper ${expand === 'menus' ? 'expand-menu' : ''}`}>
              <div className='mm-navbar-logo d-flex justify-content-between d-block d-md-none'>
                <div>
                  <MoneyMinxLogoMobile className='mr-3' />
                </div>
              </div>
              <ul className='navbar-nav mr-auto navbar-menu-list' role='menubar'>
                <li className={navClass('features')}>
                  <Link className='mm-nav-link' to='/features' role='menuitem'>
                    Features
                  </Link>
                </li>
                <li className={navClass('pricing')}>
                  <Link className='mm-nav-link' to='/pricing' role='menuitem'>
                    Pro
                  </Link>
                </li>
                <li className={navClass('login')}>
                  <Link className='mm-nav-link' to='/login' role='menuitem'>
                    Log In
                  </Link>
                </li>
                <li className='mm-nav-item'>
                  <Link
                    className='mm-nav-link'
                    to={{ pathname: appRouteConstants.auth.SIGNUP, search }}
                    role='menuitem'
                  >
                    <button className='w-100 mm-btn-signup mm-btn-primary mm-btn-animate mt-n2'>Sign up</button>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='mm-navbar-icon mr-4'>
        <div className='mm-menuToggle'>
          <input type='checkbox' onClick={handleToggleMenu} />
          <span />
          <span />
          <span />
        </div>
      </div>
    </nav>
    </div>
  );
};

export default WebsiteHeader;
