import React from 'react';

import { setSetting } from 'app/app.actions';
import useFetch from 'common/hooks/useFetch';
import { useDispatch } from 'app/app.context';
import { SettingType } from 'setting/setting.type';
import { getCurrentSettings } from 'api/request.api';

const useSettings = () => {
  const dispatch = useDispatch();

  const { res, loading } = useFetch(getCurrentSettings, {});

  React.useEffect(() => {
    if (res?.data) {
      dispatch(setSetting(res.data));
    }
  }, [res?.data, dispatch]);

  return { loading, data: res?.data as SettingType, error: res?.error };
};

export default useSettings;
