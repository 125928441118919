import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import WebsiteLayout from 'website/website.layout';
import { ReactComponent as PricingPieChart } from 'assets/images/pricing/pricing-pie-chart.svg';
import { ReactComponent as PricingPieChartMobile } from 'assets/images/pricing/pricing-pie-chart-mobile.svg';
import SubscriptionPlans from 'subscription/subscription.plans';

const Pricing = () => {
  return (
    <WebsiteLayout isSignupToday={false}>
      <HelmetProvider>
       <Helmet>
         <title>Pricing | Money Minx</title>
         <meta name='description' content='Compare Money Minx plans and pricing.' />
         {/*Facebook tags*/}
         <meta property='og:title' content='Pricing | Money Minx' />
         <meta property='og:description' content='Compare Money Minx plans and pricing.' />
         <meta property='og:url' content='https://www.moneyminx.com/pricing' />
         <meta property='og:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
         <meta property='og:image:secure_url' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
         <meta property='og:image:alt' content='Compare Money Minx plans and pricing.' />
         {/*Twitter tags*/}
         <meta property='twitter:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
         <meta property='twitter:image:alt' content='Compare Money Minx plans and pricing.' />
       </Helmet>
      </HelmetProvider>
      <PricingTopSection />
      <SubscriptionPlans />
    </WebsiteLayout>
  );
};
export default Pricing;

export const PricingTopSection = () => {
  return (
    <div className='mm-container-right pricing-banner-container'>
      <div className='row pricing-top'>
        <div className='col-lg-5'>
          <div className='pricing-left-banner'>
            <h1>
              <span className='block'>Get started for free!</span>
            </h1>
            <p className='text'>
              Money Minx is free to use. Try Money Minx Pro free for 34 days and only upgrade when ready!
            </p>

            <button className='mm-btn-animate mm-btn-primary'>Get Started</button>
            <p className='info-text'>Free to use.</p>
          </div>
        </div>
        <div className='col-lg-7 pricing-chart-wrapper'>
          <div className='banner-piechart mm-banner-chart'>
            <PricingPieChart className='mm-show-md' />
            <PricingPieChartMobile className='mm-show-sm' />
          </div>
        </div>
      </div>
    </div>
  );
};
