import React, { useState } from 'react';

import appEnv from 'app/app.env';
import { Plan } from 'setting/setting.type';
import { events } from '@mm/data/event-list';
import GALink from 'common/components/ga-link';
import useSubscriptions from 'auth/hooks/useSubscriptions';
import { pricingDetailConstant } from 'common/common.constant';
import { EPlanDuration, EPlanType } from 'setting/setting.enum';
import FreePlanFeatures from 'auth/views/inc/free-plan-features';
import CircularSpinner from 'common/components/spinner/circular-spinner';
import { ReactComponent as PricingTickIcon } from 'assets/images/pricing/tick-icon.svg';

const SubscriptionPlans = () => {
  const [type, setType] = useState<string>('monthly');

  const { loading: fetchingSubscription, error: subError, subscriptions } = useSubscriptions();

  const monthlyPricingList = subscriptions?.filter(
    (sub: any) => sub.duration === EPlanDuration.MONTHLY && sub.active === true && sub.name === EPlanType.PRO
  );
  const annualPricingList = subscriptions?.filter(
    (sub: any) => sub.duration === EPlanDuration.YEARLY && sub.active === true && sub.name === EPlanType.PRO
  );
  const pricingList = type === 'monthly' ? monthlyPricingList : annualPricingList;

  if ((fetchingSubscription && !subscriptions && subError) || !pricingList) {
    return <CircularSpinner />;
  }

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='plan-section'>
          <div className='mm-plan-radios'>
            <input
              type='radio'
              id='mm-plan-month'
              value='monthly'
              name='mm-radio-time-interval'
              checked={type === 'monthly'}
              aria-checked={type === 'monthly'}
              readOnly
            />
            <label className='labels' htmlFor='mm-plan-month' onClick={() => setType('monthly')} role='button'>
              {' '}
              Monthly
            </label>
            <input
              type='radio'
              id='mm-plan-year'
              value='annually'
              name='mm-radio-time-interval'
              checked={type === 'yearly'}
              aria-checked={type === 'yearly'}
              readOnly
            />
            <label className='labels' htmlFor='mm-plan-year' onClick={() => setType('yearly')} role='button'>
              {' '}
              Annually
            </label>
            <span className='save-text' />
            <div className='mm-radio-bg' />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='pricing-table-wrapper'>
          <div className='price-table'>
            <div className='price-heading'>
              <span className='price-plan-name'>Free</span>
              <p>{type === 'yearly' ? `$0/Year` : `$0/Month`}</p>
            </div>

            <FreePlanFeatures />
            <GALink
              to={`/auth/signup?priceId=${appEnv.STRIPE_DEFAULT_PLAN}&planName=Free&planPrice=0`}
              eventArgs={{
                ...events.trialFromPricing,
                action: `Clicked on start free plan`,
                value: 0,
              }}
            >
              <button className='mm-btn-animate trial-btn ml-3 btn-xs-block'>Get Started</button>
            </GALink>
          </div>
          {pricingList?.map((pt: Plan, index: number) => {
            return (
              <div className='price-table' key={index}>
                <div className='price-heading'>
                  <span className='price-plan-name'>{pt.name}</span>
                  <p>
                    {type === 'yearly' ? `$${pt.price}/Year` : `$${pt.price}/Month`}
                    {type === 'yearly' ? <span className='save-percentage'>Save ${pt.save}</span> : null}
                  </p>
                </div>
                <ul className='features-list p-b-12'>
                  <li>
                    <div className='tick-icon'>
                      <PricingTickIcon />
                    </div>
                    Everything in free
                  </li>
                  <li>
                    <div className='tick-icon'>
                      <PricingTickIcon />
                    </div>
                    {'Current and '}
                    {pt.details[pricingDetailConstant.ALLOCATION_CHART_HISTORY] === 'Unlimited'
                      ? 'historical '
                      : `last ${pt.details[pricingDetailConstant.ALLOCATION_CHART_HISTORY]} months `}
                    asset allocation charts
                  </li>
                  <li>
                    <div className='tick-icon'>
                      <PricingTickIcon />
                    </div>
                    {pt.details[pricingDetailConstant.INVESTING_ENTITY] === '1'
                      ? '1 investing entity'
                      : pt.details[pricingDetailConstant.INVESTING_ENTITY] + ' investing entities'}
                  </li>
                  <li>
                    <div className='tick-icon'>
                      <PricingTickIcon />
                    </div>
                    {pt.details[pricingDetailConstant.CURRENCY] === 'USD'
                      ? 'USD support only '
                      : `Choose from over 130 supported currencies `}
                  </li>
                  {pt.details[pricingDetailConstant.COMPARE_ALLOCATION] === 'Yes' ? (
                    <li>
                      <div className='tick-icon'>
                        <PricingTickIcon />
                      </div>
                      Compare your net worth and asset allocation to others
                    </li>
                  ) : null}
                  <li>
                    <div className='tick-icon'>
                      <PricingTickIcon />
                    </div>
                    Get tips and insights with Money Minx AI
                  </li>
                  <li>
                    <div className='tick-icon'>
                      <PricingTickIcon />
                    </div>
                    Manage real estate leases, mortgages and expense
                  </li>
                  <li>
                    <div className='tick-icon'>
                      <PricingTickIcon />
                    </div>
                    Community {pt.details[pricingDetailConstant.NAME]} room access and badge
                  </li>
                </ul>
                <GALink
                  to={`/auth/signup?priceId=${pt.priceId}&planName=${pt.name}&planPrice=${pt.price}`}
                  eventArgs={{
                    ...events.trialFromPricing,
                    action: `Clicked on start ${pt.name} plan`,
                    value: pt.price,
                  }}
                >
                  <button className='mm-btn-animate trial-btn ml-3 btn-xs-block'>Start 34 day trial</button>
                </GALink>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlans;
