import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { getUTC } from 'common/moment.helper';
import { appRouteConstants } from 'app/app-route.constant';
import { IAccountType, IAccountDataByType } from 'account-types/account-types.types';
import { getAccountTypes, getValueForAccountType, getHoldingsForAccountType } from 'api/request.api';
import { clearAccountTypeFilter, setAccountType } from 'account-types/context/account-types.actions';
import { useAccountTypesState, useAccountTypesDispatch } from 'account-types/context/account-types.context';

const useAccountTypes = (mmAccountType: string) => {
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(true);
  const [accountTypes, setAccountTypes] = useState<IAccountType[]>([]);
  const [accountTypesHoldings, setAccountTypesHoldings] = useState<any[]>([]);
  const [defaultToDate, setDefaultToDate] = useState<string | undefined>(undefined);
  const [defaultFromDate, setDefaultFromDate] = useState<string | undefined>(undefined);
  const [accountDataByType, setAccountDataByType] = useState<IAccountDataByType | undefined>(undefined);

  const dispatch = useAccountTypesDispatch();
  const accountTypeState = useAccountTypesState();

  useEffect(() => {
    const fetchAccountTypes = async () => {
      setLoading(true);
      const { data, error } = await getAccountTypes();

      if (data && !error) {
        setAccountTypes(data);
      }
      setLoading(false);
    };

    fetchAccountTypes();
  }, []);

  useEffect(() => {
    if (!accountTypes.length) {
      return;
    }

    const accountType = accountTypes.find((type) => type.mmAccountType.toLowerCase() === mmAccountType?.toLowerCase());

    if (!accountType) {
      return history.replace(appRouteConstants.dashboard.DASHBOARD);
    }

    dispatch(setAccountType(accountType));

    return () => {
      dispatch(clearAccountTypeFilter());
    };
  }, [dispatch, history, accountTypes, mmAccountType]);

  useEffect(() => {
    const fetchDataForAccountType = async () => {
      const { fromDate, toDate, timeInterval, accountType } = accountTypeState;

      if (!accountType) {
        return;
      }

      setLoading(true);

      const params = {
        timeIntervals: timeInterval,
        fromDate: fromDate && getUTC(fromDate),
        toDate: toDate && getUTC(toDate),
        mmAccountType: accountType.mmAccountType,
      };

      const holdingsParams = {
        timeInterval,
        fromDate: fromDate && getUTC(fromDate),
        toDate: toDate && getUTC(toDate),
        mmAccountType: accountType.mmAccountType,
        baseCurrency: true,
      };

      const [accountTypeDataRes, holdingsRes] = await Promise.allSettled([
        getValueForAccountType(params),
        getHoldingsForAccountType(holdingsParams),
      ]);

      if (accountTypeDataRes.status === 'fulfilled') {
        const { data, error }: { data: IAccountDataByType; error: any } = accountTypeDataRes.value;

        if (data && !error) {
          setAccountDataByType(data);

          const from = data.total[0]?.interval;
          const to = data.total[data.total.length - 1]?.interval;

          if (from && from !== 'Current') {
            setDefaultFromDate(from);
          }

          if (to && to !== 'Current') {
            setDefaultToDate(to);
          }
        }
      }

      if (holdingsRes.status === 'fulfilled') {
        const { data, error } = holdingsRes.value;

        if (data && !error) {
          setAccountTypesHoldings(data);
        }
      }

      setLoading(false);
    };

    fetchDataForAccountType();
  }, [accountTypeState]);

  return { loading, defaultFromDate, defaultToDate, accountTypes, accountDataByType, accountTypesHoldings };
};

export default useAccountTypes;
