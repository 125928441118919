import React from 'react';

import { Modal, ModalType } from 'common/components/modal';

interface IFilterModal {
  filterModal: ModalType;
  clearFilterModal: () => void;
}

const FilterModal: React.FC<IFilterModal> = (props) => {
  const { children, filterModal, clearFilterModal } = props;

  return (
    <div className='dropdown-filter'>
      <Modal {...filterModal.props} title='Filters' size='sm' canBeClosed={true}>
        <div className='dropdown-filter__body'>{children}</div>
        <button className='mm-btn-animate btn-outline-danger w-100' type='button' onClick={clearFilterModal}>
          Clear Filters
        </button>
        <button className='mm-btn-primary mm-btn-animate w-100 m-t-5' type='button' onClick={filterModal.close}>
          Show Results
        </button>
      </Modal>
    </div>
  );
};

export default FilterModal;
