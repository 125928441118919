import { Range } from 'react-input-range';

import { EDiscoverFilterAction } from '../discover.enum';
import { IDiscoverFilterAction, IDiscoverFilterState } from '../discover.types';

export const setType = (type: string): IDiscoverFilterAction => ({
  type: EDiscoverFilterAction.SET_TYPE,
  payload: {
    type,
  },
});

export const setRisk = (risk: string): IDiscoverFilterAction => ({
  type: EDiscoverFilterAction.SET_RISK,
  payload: {
    risk,
  },
});

export const setReturnType = (returnType: string) => ({
  type: EDiscoverFilterAction.SET_RETURN_TYPE,
  payload: {
    returnType,
  },
});

export const setReturnFrequency = (returnFrequency: string): IDiscoverFilterAction => ({
  type: EDiscoverFilterAction.SET_RETURN_FREQUENCY,
  payload: {
    returnFrequency,
  },
});

export const setLiquidity = (liquidity: string): IDiscoverFilterAction => ({
  type: EDiscoverFilterAction.SET_LIQUIDITY,
  payload: {
    liquidity,
  },
});

export const setInvestorType = (investorType: string): IDiscoverFilterAction => ({
  type: EDiscoverFilterAction.SET_INVESTOR_TYPE,
  payload: {
    investorType,
  },
});

export const clearDiscoverFilters = (): IDiscoverFilterAction => ({
  type: EDiscoverFilterAction.CLEAR_FILTERS,
});

export const setMinimum = (range: Range): IDiscoverFilterAction => ({
  type: EDiscoverFilterAction.SET_MINIMUM,
  payload: {
    range,
  },
});

export const setTargetReturns = (range: Range): IDiscoverFilterAction => ({
  type: EDiscoverFilterAction.SET_TARGET_RETURNS,
  payload: {
    range,
  },
});

export const setSortField = (sortField: string): IDiscoverFilterAction => ({
  type: EDiscoverFilterAction.SET_SORT_FIELD,
  payload: {
    sortField,
  },
});

export const updateDiscoverFilterValues = (values: Partial<IDiscoverFilterState>) => ({
  type: EDiscoverFilterAction.PARTIAL_UPDATE,
  payload: {
    values,
  },
});
