import { useEffect, useState } from 'react';

import { ApiResponse } from 'app/app.types';
import { fetchProfile } from 'auth/auth.service';
import { useAuthDispatch } from 'auth/auth.context';

const useProfile = () => {
  const dispatch = useAuthDispatch();

  const [fetchCounter, setFetchCounter] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<ApiResponse>({ data: null, error: null });

  useEffect(() => {
    let mounted = true;

    const getUser = async () => {
      setLoading(true);

      const { data, error } = await fetchProfile({ dispatch });

      if (mounted) {
        setResponse({ data, error });
        setLoading(false);
      }
    };

    getUser();

    return () => {
      mounted = false;
    };
  }, [dispatch, fetchCounter]);

  const refreshProfile = () => setFetchCounter((c) => c + 1);

  return { loading, response, refreshProfile };
};

export default useProfile;
