import React from 'react';

import NetworthFilter from 'networth/views/inc/networth-filter';
import { INetworthFilterSection } from 'networth/networth.type';

const NetworthFilterSection: React.FC<INetworthFilterSection> = ({ handleLoad }) => {
  return (
    <div className='filter-wrapper mb-4 mb-lg-0'>
      <NetworthFilter handleLoad={handleLoad} />
    </div>
  );
};

export default NetworthFilterSection;
