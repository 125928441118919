import { ECryptoConnections } from 'auth/auth.enum';

export const providerLogo = {
  [ECryptoConnections.COINBASE]: 'https://moneyminx-files.s3.amazonaws.com/logos/coinbase.png',
  [ECryptoConnections.BINANCE]: 'https://moneyminx-files.s3.amazonaws.com/logos/binance.png',
  [ECryptoConnections.EXODUS]: 'https://moneyminx-files.s3.amazonaws.com/logos/exodus.png',
  [ECryptoConnections.FTX]: 'https://moneyminx-files.s3.amazonaws.com/logos/ftx.png',
  [ECryptoConnections.FTX_US]: 'https://moneyminx-files.s3.amazonaws.com/logos/ftxUS.png',
  [ECryptoConnections.GEMINI]: 'https://moneyminx-files.s3.amazonaws.com/logos/gemini.png',
  [ECryptoConnections.HUOBI]: 'https://moneyminx-files.s3.amazonaws.com/logos/huobi.png',
  [ECryptoConnections.KRAKEN]: 'https://moneyminx-files.s3.amazonaws.com/logos/kraken.png',
  [ECryptoConnections.KUCOIN]: 'https://moneyminx-files.s3.amazonaws.com/logos/kucoin.png',
  [ECryptoConnections.LEDGER]: 'https://moneyminx-files.s3.amazonaws.com/logos/ledger.png',
  [ECryptoConnections.METAMASK]: 'https://moneyminx-files.s3.amazonaws.com/logos/metamask.png',
  [ECryptoConnections.TREZOR]: 'https://moneyminx-files.s3.amazonaws.com/logos/trezor.png',
  [ECryptoConnections.BINANCE_US]: 'https://moneyminx-files.s3.amazonaws.com/logos/binanceUS.png',
  [ECryptoConnections.BITCOIN]: 'https://moneyminx-files.s3.amazonaws.com/logos/bitcoin-address.png',
  [ECryptoConnections.BITTREX]: 'https://moneyminx-files.s3.amazonaws.com/logos/bittrex.png',
  [ECryptoConnections.CELSIUS]: 'https://moneyminx-files.s3.amazonaws.com/logos/Celsius.png',
  [ECryptoConnections.COINBASE_WALLET]: 'https://moneyminx-files.s3.amazonaws.com/logos/coinbase-wallet.png',
  [ECryptoConnections.COINBASE_PRO]: 'https://moneyminx-files.s3.amazonaws.com/logos/coinbasePro.png',
  [ECryptoConnections.CRYPTO_COM]: 'https://moneyminx-files.s3.amazonaws.com/logos/cryptoCom.png',
  [ECryptoConnections.ETHEREUM]: 'https://moneyminx-files.s3.amazonaws.com/logos/ethereum.png',
  [ECryptoConnections.BINANCE_SMART_CHAIN]: 'https://moneyminx-files.s3.amazonaws.com/logos/binance-smart-chain.png',
  [ECryptoConnections.TERRA]: 'https://moneyminx-files.s3.amazonaws.com/logos/Terra-Address.png',
  [ECryptoConnections.VINOVEST]: 'https://moneyminx-files.s3.amazonaws.com/discover/Vinovest.png',
  [ECryptoConnections.RALLY_RD]: 'https://moneyminx-files.s3.amazonaws.com/discover/RallyRd.png',
  [ECryptoConnections.TERRA_STATION_WALLET]: 'https://moneyminx-files.s3.amazonaws.com/logos/Terra-Station.png',
  [ECryptoConnections.TRUST_WALLET]: 'https://moneyminx-files.s3.amazonaws.com/logos/trust-wallet.png',
};
