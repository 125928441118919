import React from 'react';
import { Link } from 'react-router-dom';

interface IAppFooterProps {
  showZillowDisclaimer?: boolean;
}

const AppFooter: React.FC<IAppFooterProps> = ({ showZillowDisclaimer = false }) => {
  return (
    <div className='container-fluid mm-container-final footer-second'>
      <div className='row'>
        <div className='footer-table-wrapper'>
          <div className='footer-content'>
            <div className='copyright-text'>© {new Date().getFullYear()} Money Minx, Inc. All Rights Reserved.</div>
            {showZillowDisclaimer && (
              <p className='zillow-disclaimer-text'>
                © Zillow, Inc., 2006-{new Date().getFullYear()}. Use is subject to{' '}
                <a href='https://www.zillow.com/z/corp/terms/' target='blank'>
                  Terms of Use
                </a>
              </p>
            )}
          </div>
          <div className='footer-content right-content'>
            <ul className='footer-list'>
              <li className='my-3 my-md-0'>
                <Link to='/privacy'>Privacy Policy</Link>
              </li>
              <li className='my-3 my-md-0'>
                <Link to='/terms'>Terms of Service</Link>
              </li>
              <li className='my-1 my-md-0'>
                <Link to='/notices'>Notices</Link>
              </li>
              <li className='my-1 my-md-0'>
                <Link to='/security'>Security</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
