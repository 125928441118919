import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { useModal } from 'common/components/modal';
import NetworthLayout from 'networth/networth.layout';
import { useFilterState } from 'filter/filter.context';
import useAccountCount from 'networth/hooks/useAccountCount';
import AddAccountsModal from 'account/views/add-account.modal';
import { toggleGroupByCategory } from 'networth/networth.actions';
import NetworthMainSection from 'networth/components/networth-main-section';
import NetworthFilterSection from 'networth/components/networth-filter-section';
import { useNetworthState, useNetworthDispatch } from 'networth/networth.context';
import NetworthFilterSkeleton from 'networth/components/networth-filter-skeleton';

import NetworthHead from './inc/networth-head';
import GettingStarted from './inc/getting-started';
import NetworthSkeleton from './inc/networth-skeleton';

const Networth: React.FC = () => {
  const addAccountModal = useModal();

  const dispatch = useNetworthDispatch();
  const { loading: fetchingAccount } = useAccountCount();
  const { accountCount, groupByCategory, processing } = useNetworthState();
  const { processing: fetchingFilter } = useFilterState();

  const fetchingNetworth = Object.values(processing).some(Boolean);
  const loading = fetchingAccount || fetchingNetworth;

  const toggleGroupCategory = () => {
    dispatch(toggleGroupByCategory());
  };

  const renderNetworth = () => {
    if (accountCount > 0 && !fetchingAccount) {
      return (
        <>
          <NetworthHead groupByCategory={groupByCategory} toggleGroupByCategory={toggleGroupCategory} />
          <hr className='m-0' />
          <div className='content-wrapper'>
            <div className='container'>
              <NetworthFilterSection handleLoad={() => {}} />
              <NetworthMainSection />
            </div>
          </div>
        </>
      );
    }

    if (loading || fetchingAccount || fetchingFilter) {
      return (
        <div className='content-wrapper'>
          <div className='container'>
            <NetworthFilterSkeleton />
          </div>
          <hr className='m-0' />
          <div className='container mt-1'>
            <NetworthFilterSkeleton />
          </div>
          <NetworthSkeleton />
        </div>
      );
    }

    return <GettingStarted onAddClick={() => addAccountModal.open()} />;
  };

  return (
    <NetworthLayout>
      <HelmetProvider>
        <Helmet>
          <title>Net Worth | Money Minx</title>
        </Helmet>
      </HelmetProvider>
      <section className='content-container networth-container'>
        {renderNetworth()}

        <AddAccountsModal addAccountModal={addAccountModal} />
      </section>
    </NetworthLayout>
  );
};

export default Networth;
