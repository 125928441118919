import React from 'react';
import Skeleton from 'react-loading-skeleton';

import classNames from 'common/classes.helper';
import { EDiffStatus } from 'dashboard/dashboard.enum';
import { ReactComponent as PositiveIndicator } from 'assets/images/dashboard/positive.svg';
import { ReactComponent as NegativeIndicator } from 'assets/images/dashboard/negative.svg';

interface IAssetLiabilityItemProps {
  emoji: string;
  name: string;
  balanceText: string;
  balanceDiff: string;
  balanceDiffPer: string;
  diffStatus: EDiffStatus;
  isLiability?: boolean;
  loading?: boolean;
  onClick?: VoidFunction;
}

const AssetLiabilityItem: React.FC<IAssetLiabilityItemProps> = ({
  emoji,
  name,
  diffStatus,
  balanceText,
  balanceDiff,
  balanceDiffPer,
  loading = false,
  onClick = () => {},
}) => {
  const percentageClass = classNames(
    'asset-liability-item__balance-diff-per',
    `asset-liability-item__balance-diff-per--${diffStatus}`
  );

  return (
    <div className='asset-liability-item' onClick={onClick} role='button'>
      <div className='asset-liability-item__header'>
        {emoji && <span className='asset-liability-item__header-emoji'>{emoji}</span>}
        <span className='asset-liability-item__header-name'>{name}</span>
      </div>

      <div className='asset-liability-item__body'>
        <span className='asset-liability-item__balance'>{balanceText}</span>
        <div className='asset-liability-item__balance-diff-container'>
          <span className='asset-liability-item__balance-diff'>
            {loading ? <Skeleton width={80} /> : `${balanceDiff} |`}
          </span>
          <span className={percentageClass}>{loading ? <Skeleton width={40} /> : balanceDiffPer}</span>
          <div className='asset-liability-item__indicator'>
            {diffStatus === EDiffStatus.POSITIVE ? <PositiveIndicator /> : <NegativeIndicator />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetLiabilityItem;
