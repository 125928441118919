import React from 'react';

import { Account, IAccountDetails } from 'auth/auth.types';
import { getCurrencySymbol } from 'common/currency-helper';
import { fNumber, numberWithCommas } from 'common/number.helper';
import { ReactComponent as DeleteIcon } from 'assets/icons/icon-trash.svg';

interface IAssociatedRealEstateItemProps {
  associatedAccount: Account;
  mortgageBalance: number | undefined;
  handleDelete: (accId: number) => void;
  navigateToRealEstateAccount: (accId: number) => void;
}

const currencyFields = ['balance', 'equity'];

const AssociatedRealEstateItem: React.FC<IAssociatedRealEstateItemProps> = ({
  associatedAccount,
  mortgageBalance,
  handleDelete,
  navigateToRealEstateAccount,
}) => {
  const balance = associatedAccount?.balance;
  const accountDetails = associatedAccount?.accountDetails;
  const currencySymbol = getCurrencySymbol(associatedAccount?.currency || '$');

  const getValue = (key: string) => {
    if (!accountDetails) {
      return '';
    }

    if ('balance' === key && balance) {
      return currencySymbol + numberWithCommas(fNumber(balance, 0));
    }

    const value = accountDetails[key as keyof IAccountDetails] || '';

    if (value && currencyFields.includes(key)) {
      return currencySymbol + numberWithCommas(fNumber(value as string | number));
    }

    return value;
  };

  const getEquity = () => {
    return currencySymbol + numberWithCommas(fNumber((associatedAccount?.balance || 0) - (mortgageBalance || 0), 0));
  };

  return (
    <div
      className='associated-real-estate__detail'
      onClick={() => navigateToRealEstateAccount(associatedAccount.id)}
      role='button'
    >
      <div className='d-flex justify-content-between align-items-start mb-4'>
        <h6>{associatedAccount.accountName}</h6>
        <DeleteIcon
          className='btn-delete ml-2'
          role='button'
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(associatedAccount.id);
          }}
        />
      </div>

      <ul>
        <li className='d-flex justify-content-between align-items-start mb-3'>
          <span className='associated-real-estate__detail-label'>Value</span>
          <span className='associated-real-estate__detail-value'>{getValue('balance') || '--'}</span>
        </li>

        <li className='d-flex justify-content-between align-items-start'>
          <span className='associated-real-estate__detail-label'>Equity</span>
          <span className='associated-real-estate__detail-value'>{getEquity() || '--'}</span>
        </li>
      </ul>
    </div>
  );
};

export default AssociatedRealEstateItem;
