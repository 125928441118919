import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import InputRange, { InputRangeProps, Range } from 'react-input-range';

import classNames from 'common/classes.helper';

interface IRangeSelectorProps extends InputRangeProps {
  label: string;
  value: Range;
  maxValue: number;
  minValue: number;
  labelClass?: string;
  contentClass?: string;
  containerClass?: string;
  valueFormatter?: (value: number) => string;
}

const RangeSelector: React.FC<IRangeSelectorProps> = ({
  label,
  value,
  minValue,
  maxValue,
  labelClass = '',
  contentClass = '',
  containerClass = '',
  valueFormatter = (value) => `${value}`,
  onChange,
  onChangeComplete,
  ...rest
}) => {
  const { min, max } = value;

  const validateValue = (value: number | Range): number | Range => {
    if (typeof value === 'number') {
      return value;
    }

    return {
      min: Math.max(minValue, value.min),
      max: Math.min(maxValue, value.max),
    };
  };

  const handleChange = (value: number | Range): void => {
    onChange(validateValue(value));
  };

  const handleComplete = (value: number | Range): void => {
    if (onChangeComplete) {
      onChangeComplete(validateValue(value));
    }
  };

  return (
    <Dropdown className={classNames('drop-box', 'mb-2', !!containerClass && containerClass)}>
      <Dropdown.Toggle variant='' className={labelClass}>
        {label}
      </Dropdown.Toggle>
      <Dropdown.Menu className={classNames('mm-dropdown-menu', !!contentClass && contentClass)}>
        <div className='mm-range-selector'>
          <div className='current-values-container'>
            <span>{valueFormatter(min)}</span>
            <span className='separator'>-</span>
            <span>{valueFormatter(max)}</span>
          </div>

          <InputRange
            minValue={minValue}
            maxValue={maxValue}
            value={value}
            onChange={handleChange}
            onChangeComplete={handleComplete}
            {...rest}
          />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default RangeSelector;
