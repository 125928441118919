import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import noticeData from '@mm/data/notice.json';
import WebsiteLayout from 'website/website.layout';

const { notice } = noticeData;

const noticeContentSection = () => {
  const noticeContent = notice.data.map((d, i) => {
    return (
      <div key={i}>
        <h2 className='notice-content-title'>{d.title}</h2>
        <div className='notice-content' dangerouslySetInnerHTML={{ __html: d.content }} />
      </div>
    );
  });
  return <>{noticeContent}</>;
};

const Notice = () => {
  return (
    <WebsiteLayout>
      <HelmetProvider>
       <Helmet>
         <title>Notices | Money Minx</title>
         <meta name='description' content='A few notices you need to know about Money Minx.' />
         {/*Facebook tags*/}
         <meta property='og:title' content='Notices | Money Minx' />
         <meta property='og:description' content='A few notices you need to know about Money Minx.' />
         <meta property='og:url' content='https://www.moneyminx.com/notices' />
         <meta property='og:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
         <meta property='og:image:secure_url' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
         <meta property='og:image:alt' content='A few notices you need to know about Money Minx.' />
         {/*Twitter tags*/}
         <meta property='twitter:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
         <meta property='twitter:image:alt' content='A few notices you need to know about Money Minx.' />
       </Helmet>
      </HelmetProvider>
      <div className='mm-container notice-wrapper'>
        <div className='notice-header'>
          <h1>{notice.title}</h1>
          <p>{notice.update.replace(':date', 'March 29 2021')}</p>
        </div>
        {noticeContentSection()}
      </div>
    </WebsiteLayout>
  );
};

export default Notice;
