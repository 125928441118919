import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';

import moment from 'moment';
import { Formik } from 'formik';
import useToast from 'common/hooks/useToast';
import { Modal } from 'common/components/modal';
import { TChangeEvent } from 'common/dom.types';
import { getISOString } from 'common/moment.helper';
import { updateAccountValueValidation } from 'auth/auth.validation';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { patchAccountBalanceRange, patchAccountBalances } from 'api/request.api';
import { EEnterBalance, IUpdateAccountFormValues, IUpdateAccountValueModalProps } from 'account/account.type';

import updateAccountValueFormFields from '../data/update-account-value.json';

const initialValues: IUpdateAccountFormValues = {
  updateForDateRange: false,
  asOfDate: undefined,
  accountValue: '',
  startingDate: undefined,
  endingDate: undefined,
  howToEnterBalance: EEnterBalance.SAME,
  startingValue: '',
  endingValue: '',
};

const UpdateAccountValueModal: React.FC<IUpdateAccountValueModalProps> = ({
  account,
  currencySymbol,
  updateAccountValueModal,
  onSuccess,
}) => {
  const { mmToast } = useToast();

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={updateAccountValueValidation}
      validateOnChange
      onSubmit={async (values, actions) => {
        try {
          const {
            updateForDateRange,
            asOfDate,
            accountValue,
            startingDate,
            endingDate,
            howToEnterBalance,
            startingValue,
            endingValue,
          } = values;

          setLoading(true);

          if (!account) {
            return mmToast('Something went wrong', { type: 'error' });
          }

          const accountId = account.id;

          if (!updateForDateRange) {
            await patchAccountBalances(accountId, [
              {
                date: getISOString(asOfDate),
                balance: accountValue,
              },
            ]);

            onSuccess();
            mmToast('Balance updated successfully', { type: 'success' });
            updateAccountValueModal.close();
            actions.setValues(initialValues);
            actions.setTouched({});
            return;
          }

          const updateBalancePayload = {
            fromDate: getISOString(startingDate),
            toDate: getISOString(endingDate),
            startValue: startingValue,
            endValue: howToEnterBalance === EEnterBalance.SAME ? startingValue : endingValue,
          };

          await patchAccountBalanceRange(accountId, updateBalancePayload);
          onSuccess();
          mmToast('Balance updated successfully', { type: 'success' });
          updateAccountValueModal.close();
          actions.setValues(initialValues);
          actions.setTouched({});
        } catch (err) {
          mmToast('Error occurred while updating values', { type: 'error' });
        } finally {
          setLoading(false);
        }
      }}
    >
      {(props) => {
        const {
          values,
          errors,
          touched,
          setValues,
          setErrors,
          setFieldValue,
          setFieldTouched,
          setTouched,
          handleChange,
          handleSubmit,
          resetForm,
        } = props;

        const toggleFormCheck = (name: keyof IUpdateAccountFormValues) => {
          setErrors({});
          setTouched({});
          setFieldValue(name, !values[name]);
        };

        const onCloseModal = () => {
          resetForm();
          updateAccountValueModal.close();
        };

        const onChange = (e: TChangeEvent) => {
          const name = e.target.name;
          const value = e.target.value;
          const type = e.target.type;

          setFieldTouched(name);
          if ('number' === type) {
            if (value) {
              return setValues({ ...values, [name]: +value });
            }

            return setValues({ ...values, [name]: '' });
          }

          handleChange(e);
        };

        const getFieldTitle = (name: string) => {
          return updateAccountValueFormFields.find((item) => item.name === name)?.label || '';
        };

        const renderError = (name: keyof IUpdateAccountFormValues) => {
          const title = getFieldTitle(name);

          if (errors[name] && touched[name]) {
            return <div className='mt-2 feedback'>{errors[name]?.replace(':fieldName', title)}</div>;
          }

          return null;
        };

        return (
          <Modal
            {...updateAccountValueModal.props}
            title={account?.accountName || ''}
            size='mdx'
            onClose={onCloseModal}
          >
            <form className='update-account-value' onSubmit={handleSubmit}>
              <p className='update-account-value__description'>Update the value on a specific date for this account</p>

              <div className='form-wrap mb-4'>
                <span className='checkbox-item'>
                  <label className='check-box' htmlFor='updateForDateRange'>
                    Update values for a date range
                    <input
                      id='updateForDateRange'
                      type='checkbox'
                      name='updateForDateRange'
                      value='true'
                      checked={values.updateForDateRange}
                      onChange={() => toggleFormCheck('updateForDateRange')}
                      aria-checked={values.updateForDateRange}
                    />
                    <span className='geekmark' />
                  </label>
                </span>
                {renderError('updateForDateRange')}
              </div>

              {!values.updateForDateRange ? (
                <>
                  <div className='row mb-4'>
                    <div className='col-12 col-md-6'>
                      <label htmlFor='asOfDate'>As of date</label>
                      <div className='date-wrapper'>
                        <ReactDatePicker
                          id='asOfDate'
                          name='asOfDate'
                          placeholderText='Pick a date'
                          maxDate={new Date()}
                          className='form-control form-control-lg '
                          selected={values.asOfDate}
                          onChange={(val: Date) => {
                            setFieldValue('asOfDate', val ? moment(val).toDate() : null);
                          }}
                        />
                        <CalendarIcon className='icon' />
                      </div>
                      {renderError('asOfDate')}
                    </div>
                  </div>

                  <div className='row mb-4'>
                    <div className='col-12 col-md-6'>
                      <label>Value</label>
                      <div className='currency-input'>
                        <Form.Control
                          type='number'
                          name='accountValue'
                          value={values.accountValue}
                          onChange={onChange}
                          step='any'
                        />
                        <span className='currency-input__symbol'>{currencySymbol}</span>
                      </div>
                      {renderError('accountValue')}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='row mb-4'>
                    <div className='col-12 col-md-6'>
                      <label htmlFor='startingDate'>Starting Date</label>
                      <div className='date-wrapper'>
                        <ReactDatePicker
                          id='startingDate'
                          name='startingDate'
                          placeholderText='Pick a date'
                          className='form-control form-control-lg'
                          maxDate={new Date()}
                          selected={values.startingDate}
                          onChange={(val: Date) => {
                            setFieldValue('startingDate', val ? moment(val).toDate() : null);
                          }}
                        />
                        <CalendarIcon className='icon' />
                      </div>
                      {renderError('startingDate')}
                    </div>
                    <div className='col-12 col-md-6'>
                      <label htmlFor='endingDate'>Ending Date</label>
                      <div className='date-wrapper'>
                        <ReactDatePicker
                          id='endingDate'
                          name='endingDate'
                          placeholderText='Pick a date'
                          className='form-control form-control-lg'
                          maxDate={new Date()}
                          selected={values.endingDate}
                          onChange={(val: Date) => {
                            setFieldValue('endingDate', val ? moment(val).toDate() : null);
                          }}
                        />
                        <CalendarIcon className='icon' />
                      </div>
                      {renderError('endingDate')}
                    </div>
                  </div>

                  <div className='row-mb-4'>
                    <span className='update-account-value__label'>How would you like to enter balances?</span>
                    <Form.Group className='radio-group'>
                      <div className='form-check'>
                        <Form.Control
                          type='radio'
                          value={EEnterBalance.SAME}
                          onChange={onChange}
                          name='howToEnterBalance'
                          checked={values.howToEnterBalance === EEnterBalance.SAME}
                          aria-checked={values.howToEnterBalance === EEnterBalance.SAME}
                        />
                        <label>Use the same balance for all dates in between</label>
                      </div>

                      <div className='form-check'>
                        <Form.Control
                          type='radio'
                          value={EEnterBalance.INCREASE}
                          onChange={onChange}
                          name='howToEnterBalance'
                          checked={values.howToEnterBalance === EEnterBalance.INCREASE}
                          aria-checked={values.howToEnterBalance === EEnterBalance.INCREASE}
                        />
                        <label>Increase the balance between the two dates</label>
                      </div>
                    </Form.Group>
                  </div>

                  <div className='row mb-4'>
                    <div className='col-12 col-md-6'>
                      <label>Starting Value</label>
                      <div className='currency-input'>
                        <Form.Control
                          type='number'
                          name='startingValue'
                          value={values.startingValue}
                          onChange={onChange}
                        />
                        <span className='currency-input__symbol'>{currencySymbol}</span>
                      </div>
                      {renderError('startingValue')}
                    </div>

                    {values.howToEnterBalance === EEnterBalance.INCREASE && (
                      <div className='col-12 col-md-6'>
                        <label>Ending Value</label>
                        <div className='currency-input'>
                          <Form.Control
                            type='number'
                            name='endingValue'
                            value={values.endingValue}
                            onChange={onChange}
                          />
                          <span className='currency-input__symbol'>{currencySymbol}</span>
                        </div>
                        {renderError('endingValue')}
                      </div>
                    )}
                  </div>
                </>
              )}

              <div className='d-flex flex-column flex-md-row justify-content-start pt-4'>
                <button
                  type='button'
                  className='btn-outline-primary mm-btn-animate mr-md-4 mb-2 mb-md-0'
                  onClick={onCloseModal}
                >
                  Cancel
                </button>
                <button type='submit' className='mm-btn-animate mm-btn-primary '>
                  {loading ? (
                    <>
                      <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
                      <span className='ml-1'>Saving...</span>
                    </>
                  ) : (
                    <> Save Changes </>
                  )}
                </button>
              </div>
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default UpdateAccountValueModal;
