import React, { useState } from 'react';

import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';

interface ICopyButtonProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  elementRef: React.MutableRefObject<HTMLInputElement | null>;
}

const CopyButton: React.FC<ICopyButtonProps> = ({ elementRef, className = '', ...props }) => {
  const [isTootipVisible, setIsTooltipVisible] = useState<boolean>(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (!elementRef || !elementRef.current) {
      return;
    }

    elementRef.current.select();
    document.execCommand('copy');
    setIsTooltipVisible(true);

    setTimeout(() => {
      setIsTooltipVisible(false);
    }, 3000);
  };

  return (
    <button {...props} type='button' className={`btn-copy ${className}`} onClick={handleClick}>
      {isTootipVisible && <span>Copied!</span>}
      <CopyIcon />
    </button>
  );
};

export default CopyButton;
