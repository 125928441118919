import React from 'react';
import Skeleton from 'react-loading-skeleton';

const NetworthFilterSkeleton = () => {
  return (
    <div className='row'>
      <div className='col-12 dropdowns-container'>
        <div className='dflex-center mb-15'>
          <Skeleton width={265} height={50} count={3} inline />
        </div>
      </div>
    </div>
  );
};

export default NetworthFilterSkeleton;
