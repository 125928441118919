import React, { useEffect, useState, useCallback } from 'react';

import groupBy from 'lodash/groupBy';
import { IAccount, IGroup } from 'networth/networth.type';
import { useNetworthState } from 'networth/networth.context';

import NetworthByTypeTable from './networth-by-type-table';

interface INetworthByType {
  groups?: IGroup[];
  accounts?: IAccount[];
  currencySymbol: string;
  handleAccountClick: (accountId: number) => void;
}

export function useForceUpdate() {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick((tick) => tick + 1);
  }, []);
  return update;
}

const NetworthByTypeSection: React.FC<INetworthByType> = ({ groups, currencySymbol, handleAccountClick, accounts }) => {
  const { groupByCategory } = useNetworthState();
  const [networthByType, setNetworthByType] = useState<IGroup[]>([]);

  const updateGroup = useCallback(() => {
    const accountByGroupName = groupBy(accounts, 'accountType');

    const newGroups = groups?.map((group) => {
      const found = Object.keys(accountByGroupName).includes(group.groupName);

      if (found) {
        return {
          ...group,
          accounts: accountByGroupName[group.groupName],
        };
      }

      return group;
    });

    setNetworthByType(newGroups || []);
  }, [accounts, groups]);

  useEffect(() => {
    updateGroup();
  }, [updateGroup]);

  const toggleGroupTable = (gName: string) => {
    const newNetworthByType = networthByType?.map((item: any) => {
      if (gName === item.groupName) {
        item.collapse = !item.collapse;
      }
      return item;
    });

    setNetworthByType(newNetworthByType);
  };

  const getSideColor = (accGroup: any) => {
    let investmentAssetsCount = 0;
    let otherAssetsCount = 0;
    let liabilitiesCount = 0;
    accGroup.forEach((item: any) => {
      if (item.category === 'Other Assets') otherAssetsCount++;
      if (item.category === 'Liabilities') liabilitiesCount++;
      if (item.category === 'Investment Assets') investmentAssetsCount++;
    });

    const counts = [investmentAssetsCount, otherAssetsCount, liabilitiesCount];
    const categories = ['Investment Assets', 'Other Assets', 'Liabilities'];
    const category = categories[counts.indexOf(Math.max(...counts))];

    if (category === 'Other Assets') {
      return 'box-g';
    }

    if (category === 'Liabilities') {
      return 'box-r';
    }

    return 'box-b';
  };

  return !groupByCategory ? (
    <>
      {networthByType.map((item, index) => (
        <div className='row mb-40' key={index}>
          <div className='col-12'>
            <div className={['ct-box', getSideColor(item.accounts)].join(' ')}>
              <div className='table-holder'>
                <NetworthByTypeTable
                  item={item}
                  currencySymbol={currencySymbol}
                  toggleGroupTable={toggleGroupTable}
                  handleAccountClick={handleAccountClick}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  ) : (
    <React.Fragment />
  );
};

export default NetworthByTypeSection;
