import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import WebsiteLayout from 'website/website.layout';
import { ReactComponent as NotFoundImage } from 'assets/images/notfound.svg';

const NotFound = () => {
  return (
    <WebsiteLayout>
      <HelmetProvider>
       <Helmet>
         <title>Page Not Found | Money Minx</title>
         <meta name='description' content='Money Minx Page Not Found page.' />
         {/*Facebook tags*/}
         <meta property='og:title' content='Page Not Found | Money Minx' />
         <meta property='og:description' content='Money Minx Page Not Found page.' />
         <meta property='og:url' content='https://www.moneyminx.com' />
         <meta property='og:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
         <meta property='og:image:secure_url' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
         <meta property='og:image:alt' content='Money Minx Page Not Found page.' />
         {/*Twitter tags*/}
         <meta property='twitter:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
         <meta property='twitter:image:alt' content='Money Minx Page Not Found page.' />
       </Helmet>
      </HelmetProvider>
      <div className='not-found-content-wrapper w-100'>
        <div className='not-found-svg-wrapper d-flex flex-column align-items-baseline justify-content-center '>
          <NotFoundImage />
        </div>
        <div className='nf-text-btn-wrapper'>
          <p>Sorry page not found :) </p>
        </div>
      </div>
    </WebsiteLayout>
  );
};

export default NotFound;
