import { IAccountDetails } from 'auth/auth.types';
import { TPartialExtraInfoKey } from 'account/account.type';
import { getExtraInfoFormField } from 'account/account.helper';

const useExtraInfo = (type?: string) => {
  const formFields = getExtraInfoFormField(type || '') || [];

  const getField = (name: TPartialExtraInfoKey) => {
    return formFields.find((f) => f.name === name);
  };

  const hasFormField = (name: TPartialExtraInfoKey) => !!getField(name);

  const getFieldTitle = (name: TPartialExtraInfoKey) => getField(name)?.label || '';

  const hasExtraInfo = (accountDetail: IAccountDetails) => {
    const isSomeFieldExist = formFields.some((field) => !!(accountDetail as any)[field.name]);

    return isSomeFieldExist;
  };

  return { hasFormField, getField, getFieldTitle, hasExtraInfo, formFields };
};

export default useExtraInfo;
