import React from 'react';

import { numberWithCommas, fNumber } from 'common/number.helper';

interface IPortfolioMoverItemProps {
  imgSrc?: string;
  title: string;
  balance: number;
  currencySymbol: string;
  description: string;
  onClick: VoidFunction;
}

const PortfolioMoverItem: React.FC<IPortfolioMoverItemProps> = ({
  imgSrc,
  title,
  balance,
  currencySymbol,
  description,
  onClick,
}) => {
  return (
    <div className='portfolio-mover-item' onClick={onClick} role='button'>
      {imgSrc && <img src={imgSrc} alt={title} />}
      <div className='portfolio-mover-item__info'>
        <span className='portfolio-mover-item__title'>{title}</span>
        <span className='portfolio-mover-item__balance'>
          {currencySymbol}
          {numberWithCommas(fNumber(balance, 2))}
        </span>
        <span className='portfolio-mover-item__description'>{description}</span>
      </div>
    </div>
  );
};

export default PortfolioMoverItem;
