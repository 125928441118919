import Form from 'react-bootstrap/Form';
import React, { useState, useEffect, useRef, ChangeEvent, useMemo } from 'react';

import debounce from 'lodash/debounce';
import classNames from 'common/classes.helper';
import { ICurrencyOption } from 'common/common.types';
import { getCurrencyName } from 'common/currency-helper';
import useClickOutside from 'common/hooks/useClickOutside';
import { CurrencySymbols } from 'auth/enum/currency-options';

import LazyLoad from '../lazy-load';

interface ICurrencyInputProps {
  value?: string;
  placeholder?: string;
  name: string;
  onChange: (currency: string) => void;
}

const BASE_IMAGE_URL = 'https://moneyminx-files.s3.amazonaws.com/flags';

const CurrencyInput: React.FC<ICurrencyInputProps> = ({
  value = '',
  name,
  placeholder = 'Type a currency',
  onChange,
}) => {
  const [showList, setShowList] = useState<boolean>(false);

  const [currencyText, setCurrencyText] = useState<string>(value);
  const [currencyOptions, setCurrencyOptions] = useState<ICurrencyOption[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<ICurrencyOption[]>([]);

  const ref = useRef<HTMLDivElement | null>(null);

  const currencyListRef = useRef<HTMLUListElement | null>(null);

  useClickOutside(ref, () => {
    setShowList(false);
    setFilteredOptions(currencyOptions);

    // Reset field if value is not selected from dropdown
    const isValidCurrency = currencyOptions.find((item) => item.abbr === currencyText);

    if (!isValidCurrency) {
      setCurrencyText(value);
      onChange(value);
    }
  });

  useEffect(() => {
    const options = Object.keys(CurrencySymbols).map(
      (abbr): ICurrencyOption => ({
        abbr,
        name: getCurrencyName(abbr),
        imageURL: `${BASE_IMAGE_URL}/${abbr}.svg`,
      })
    );

    setFilteredOptions(options);
    setCurrencyOptions(options);
  }, []);

  useEffect(() => {
    setCurrencyText(value);
  }, [value]);

  const handleCurrencyRowClick = (abbr: string) => {
    setCurrencyText(abbr);
    setFilteredOptions(currencyOptions);
    onChange(abbr);

    setTimeout(() => {
      setShowList(false);
    }, 100);
  };

  const filterCurrencies = useMemo(() => {
    return debounce((term: string) => {
      const newCurrencyOptions = currencyOptions.filter((option) => {
        const searchText = term.toLowerCase();
        const abbr = option.abbr.toLowerCase();
        const name = option.name.toLowerCase();

        return abbr.includes(searchText) || name.includes(searchText);
      });
      setFilteredOptions(newCurrencyOptions);
    }, 500);
  }, [currencyOptions]);

  const onSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrencyText(e.target.value);
    filterCurrencies(e.target.value);
  };

  return (
    <div className={classNames('currency-control', showList && 'currency-control--highlight')} ref={ref}>
      <Form.Control
        type='text'
        name={name}
        autoComplete='off'
        placeholder={placeholder}
        value={currencyText}
        onFocus={() => setShowList(true)}
        onChange={onSearchTermChange}
      />

      {showList && filteredOptions.length > 0 && (
        <ul className='currency-control__list' ref={currencyListRef}>
          {filteredOptions.map(({ abbr, name, imageURL }) => (
            <li 
              key={abbr} 
              role='button'
              className='currency-control__list-item' 
              onClick={() => handleCurrencyRowClick(abbr)}
              >
              <LazyLoad
                rootElement={currencyListRef.current}
                placeholderElement={<div className='currency-control__img-load' />}
                className='currency-control__img-container'
              >
                <img
                  src={imageURL}
                  alt={`${abbr} flag`}
                  onError={(image) => (image.currentTarget.src = `${BASE_IMAGE_URL}/404.svg`)}
                />
              </LazyLoad>

              <span className='currency-abbr'>{abbr}</span>
              <span className='currency-name'>{name}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CurrencyInput;
