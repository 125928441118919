import React, { createContext, useReducer, useContext } from 'react';

import { EDiscoverAction } from './discover.enum';
import { IDiscoverAction, IDiscoverState, TDiscoverDispatch } from './discover.types';

const initialState: IDiscoverState = {
  processing: false,
  opportunities: [],
  pagination: {
    loadMore: true,
    nextId: undefined,
    limit: 10,
    hasMoreData: true,
  },
  search: '',
};

const DiscoverStateContext = createContext<IDiscoverState | undefined>(undefined);
const DiscoverDispatchContext = createContext<TDiscoverDispatch | undefined>(undefined);

function discoverReducer(state: IDiscoverState, action: IDiscoverAction): IDiscoverState {
  switch (action.type) {
    case EDiscoverAction.SET_PROCESSING:
      return { ...state, processing: true };
    case EDiscoverAction.RESET_PROCESSING:
      return { ...state, processing: false };
    case EDiscoverAction.SET_OPPORTUNITIES:
      return { ...state, opportunities: [...state.opportunities, ...action.payload?.opportunities] };
    case EDiscoverAction.SET_PAGINATION:
      return { ...state, pagination: action.payload?.pagination };
    case EDiscoverAction.FETCH_MORE_DATA:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          loadMore: true,
        },
      };
    case EDiscoverAction.SET_SEARCH:
      return {
        ...state,
        search: action.payload?.search,
        pagination: {
          ...state.pagination,
          nextId: undefined,
          hasMoreData: true,
          loadMore: true,
        },
        opportunities: [],
      };
    case EDiscoverAction.PARTIAL_UPDATE:
      return { ...state, ...action.payload?.values };
    default:
      throw new Error('Unhandled action type in discover reducer');
  }
}

// Provider Wrapper
const DiscoverProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(discoverReducer, initialState);

  return (
    <DiscoverStateContext.Provider value={state}>
      <DiscoverDispatchContext.Provider value={dispatch}>{children}</DiscoverDispatchContext.Provider>
    </DiscoverStateContext.Provider>
  );
};

// Consumer Helpers
const useDiscoverState = () => {
  const context = useContext(DiscoverStateContext);

  if (context === undefined) {
    throw new Error('useDiscoverState must be used within a DiscoverProvider.');
  }

  return context;
};

const useDiscoverDispatch = () => {
  const context = useContext(DiscoverDispatchContext);

  if (context === undefined) {
    throw new Error('useDiscoverDispatch must be used within a DiscoverProvider.');
  }

  return context;
};

export { DiscoverProvider, useDiscoverState, useDiscoverDispatch };
