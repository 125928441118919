import React from 'react';

import AppSubHeader from 'common/app.sub-header';

const DashboardHead: React.FC = () => {
  return (
    <div className='app-subheader-container'>
      <AppSubHeader />
    </div>
  );
};

export default DashboardHead;
