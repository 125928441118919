import React from 'react';
import { useAuthState } from 'auth/auth.context';

import WebsiteLayout from 'website/website.layout';
import { DiscoverFilterProvider } from 'discover/context/discover-filter.context';

import Discover from './discover';
import DiscoverLayout from '../discover.layout';
import { DiscoverProvider } from '../discover.context';

const DiscoverContainer: React.FC = () => {
  const { isAuthenticated } = useAuthState();

  const renderContent = () => {
    return isAuthenticated ? (
      <DiscoverLayout>
        <Discover />
      </DiscoverLayout>
    ) : (
      <WebsiteLayout isSignupToday={false}>
        <Discover />
      </WebsiteLayout>
    );
  };

  return (
    <DiscoverProvider>
      <DiscoverFilterProvider>{renderContent()}</DiscoverFilterProvider>
    </DiscoverProvider>
  );
};

export default DiscoverContainer;
