import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Table from 'react-bootstrap/esm/Table';
import Dropdown from 'react-bootstrap/esm/Dropdown';

import { gc } from 'common/interval-parser';
import classNames from 'common/classes.helper';
import { parseAmount } from 'common/common-helper';
import { useModal } from 'common/components/modal';
import { IBalanceTable } from 'account/account.type';
import zillowProvided from 'assets/images/account/zillow_provided.svg';
import DailyBalancesModal from 'account/components/daily-balances-modal';
import AccountBalanceModal from 'account/components/account-balance-modal';
import { ReactComponent as MoreVertical } from 'assets/icons/more-vertical.svg';
import UpdateAccountValueModal from 'account/components/update-account-value-modal';

const BalanceTable: React.FC<IBalanceTable> = ({ balanceData, currencySymbol, account, handleRefresh }) => {
  const accountBalanceModal = useModal();
  const dailyBalancesModal = useModal();
  const updateAccountValueModal = useModal();

  if (!balanceData) {
    return <Skeleton width={1232} height={250} />;
  }

  const balances = balanceData.balances;
  const hasHoldings = account?.hasHoldings;
  const isManualArchived = account?.isArchived && account.isManual;

  const rowClasses = classNames(hasHoldings ? 'no-hover' : '');
  const useZestimate = account ? account.accountDetails.useZestimate : false;

  return (
    <section>
      <div className='row mb-40'>
        <div className='col-12'>
          <div className='ct-box'>
            <div className='table-holder'>
              <Table className='tb-responsive account balance-table' id='table-net-xls'>
                <thead>
                  <tr>
                    <th className='s-hide'>Description</th>
                    {balances?.map((balance, index) => (
                      <th key={index} className={gc(balance.interval)}>
                        {balance.interval}
                      </th>
                    ))}
                    {!hasHoldings && <th />}
                  </tr>
                </thead>
                <tbody>
                  <tr className={rowClasses}>
                    <td>{balanceData.accountName}</td>
                    {balances?.map((balanceObj, index) => (
                      <td
                        key={index}
                        className={[balanceObj.type === `projection` && `projection`, gc(balanceObj.interval)].join(
                          ' '
                        )}
                      >
                        {parseAmount(balanceObj.balance, currencySymbol)}
                      </td>
                    ))}

                    {!hasHoldings && !isManualArchived && (
                      <td className='more-icon-dropdown'>
                        <Dropdown>
                          <Dropdown.Toggle variant='light' className='bg-white border-0 p-0 no-arrow'>
                            <MoreVertical />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={updateAccountValueModal.open}>Update Value</Dropdown.Item>
                            <Dropdown.Item onClick={dailyBalancesModal.open}>Daily Balances</Dropdown.Item>
                            <Dropdown.Item onClick={accountBalanceModal.open}>Monthly Balances</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    )}
                  </tr>
                </tbody>
              </Table>
            </div>

            {useZestimate && <img src={zillowProvided} alt='Provided by Zillow' className='zillow-provided-img' />}
          </div>
        </div>
      </div>
      <AccountBalanceModal accountBalanceModal={accountBalanceModal} account={account} onSuccess={handleRefresh} />

      <UpdateAccountValueModal
        account={account}
        currencySymbol={currencySymbol}
        updateAccountValueModal={updateAccountValueModal}
        onSuccess={handleRefresh}
      />

      <DailyBalancesModal
        account={account}
        currencySymbol={currencySymbol}
        dailyBalancesModal={dailyBalancesModal}
        onSuccess={handleRefresh}
      />
    </section>
  );
};

export default BalanceTable;
