import React from 'react';

import { events } from '@mm/data/event-list';
import GALink from 'common/components/ga-link';
import { appRouteConstants } from 'app/app-route.constant';

interface SignupTodayProps {
  footerCTA: boolean;
  pricing: boolean;
}

const SignupToday: React.FC<SignupTodayProps> = ({ footerCTA = true, pricing= false }) => {
  return (
    <div className={footerCTA ? 'signup-today-wrapper bg-primary text-white footer' : 'signup-today-wrapper bg-primary text-white'}>
      <div className='row align-items-center'>
        <div className='text-wrapper'>
            <p className='large-heading-light'>Sign up today</p>
            <p>Find out why Money Minx is the simplest way to track your net worth and money goals - for free.</p>
          </div>
        <div className='signup-btn-wrapper'>
          <GALink
            role='menuitem'
            to={appRouteConstants.auth.SIGNUP}
            eventArgs={{
              ...events.ctaSignUp,
              label: 'Get Started',
            }}
          >
            <button className='mm-btn-animate bg-white text-primary ml-3 btn-xs-block ml-3 my-2'>Get Started</button>
          </GALink>
        </div>
      </div>
    </div>
  );
};

export default SignupToday;
