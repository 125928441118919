import { useState, useEffect } from 'react';

import { EIntervalOption } from 'dashboard/dashboard.enum';
import { CurrencyOptions } from 'auth/enum/currency-options';
import { getComparisonDate } from 'dashboard/dashboard.helper';
import { getAccountLiabilities, getAccountLiabilitiesHistory } from 'api/request.api';
import { ILiability, ILiabilitiesResponse, ILiabilitiesHistoryResponse } from 'dashboard/dashboard.types';

const useLiabilities = (interval: EIntervalOption) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [historyLoading, setHistoryLoading] = useState<boolean>(false);
  const [liabilities, setLiabilities] = useState<ILiability[]>([]);
  const [currencyOption, setCurrencyOption] = useState<string>(CurrencyOptions.USD);
  const [liabilitiesHistoryMap, setLiabilitiesHistoryMap] = useState<{ [key: string]: number } | null>(null);

  useEffect(() => {
    const fetchAccountLiabilities = async () => {
      setLoading(true);
      const { data, error }: { data: ILiabilitiesResponse; error: any } = await getAccountLiabilities();

      if (!error && data) {
        setCurrencyOption(data.currency);
        setLiabilities(data.liabilities);
      }

      setLoading(false);
    };

    fetchAccountLiabilities();
  }, []);

  useEffect(() => {
    const fetchAccountLiabilitiesHistory = async () => {
      const params: Record<string, string | boolean> = {};

      if (interval === EIntervalOption.ALL) {
        params.lastHistory = true;
      } else {
        params.date = getComparisonDate(interval).toISOString();
      }

      setHistoryLoading(true);
      const { data, error }: { data: ILiabilitiesHistoryResponse; error: any } = await getAccountLiabilitiesHistory(
        params
      );

      if (!error && data) {
        const historyMap: Record<string, number> = {};

        data.liabilities.forEach(({ mmAccountType, total }) => {
          historyMap[mmAccountType] = total;
        });

        setLiabilitiesHistoryMap(historyMap);
      }

      setHistoryLoading(false);
    };

    fetchAccountLiabilitiesHistory();
  }, [interval]);

  return { loading, historyLoading, liabilities, liabilitiesHistoryMap, currencyOption };
};

export default useLiabilities;
