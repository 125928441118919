import * as Yup from 'yup';

import { dateSchema } from '../app/app.validation';

export const addActivityValidation = Yup.object({
  description: Yup.string().required('Description is a required field'),
  type: Yup.string().required('Type is a required field'),
  amount: Yup.number().required('Amount is a required field'),
  balance: Yup.number().required('Balance is a required field'),
  date: dateSchema.required('Date is a required field'),
});

export const addHoldingValidation = Yup.object({
  description: Yup.string().required('Description is a required field'),
});
