import { IFormFieldItem } from 'auth/auth.types';
import { isExist } from 'common/common-helper';
import { camelCase } from 'common/helper/string.helper';
import { getDateFormattedString } from 'common/moment.helper';
import { fNumber, formatNumber, numberWithCommas } from 'common/number.helper';

import {
  dateFields,
  tableFields,
  defaultFields,
  financialFields,
  numericalFields,
} from '../data/holding-table-fields.json';

export interface IHoldingTableFields {
  securities: IFormFieldItem[];
  cryptocurrencies: IFormFieldItem[];
  collectibles: IFormFieldItem[];
  businesses: IFormFieldItem[];
  nfTs: IFormFieldItem[];
  stockOptions: IFormFieldItem[];
  startups: IFormFieldItem[];
  preciousMetals: IFormFieldItem[];
}

export type THoldingTableKey = keyof IHoldingTableFields;
export type TPartialHoldingTableKey = Partial<THoldingTableKey>;

class HoldingTableField {
  private key?: THoldingTableKey;
  private currentFormFields: IFormFieldItem[];
  private fields: IHoldingTableFields = tableFields;
  private financialFields: string[] = financialFields;
  private numericalFields: string[] = numericalFields;
  private dateFields: string[] = dateFields;

  constructor(type?: string) {
    this.key = type ? (camelCase(type) as THoldingTableKey) : undefined;
    this.currentFormFields = this.key ? (this.fields[this.key] ? this.fields[this.key] : defaultFields) : [];
  }
  get() {
    return this.currentFormFields || [];
  }

  getField(name: string) {
    return this.currentFormFields?.find((f) => f.name === name);
  }

  getTitle(name: string) {
    return this.getField(name)?.label || '';
  }

  isMetal(): boolean {
    return this.key === 'preciousMetals';
  }

  isMetalWeight(name: string): boolean {
    return this.isMetal() && name === 'quantity';
  }

  isCrypto(): boolean {
    return this.key === 'cryptocurrencies';
  }

  formatValue(name: string, value: any, currencySymbol: string) {
    if (this.financialFields.includes(name)) {
      if (this.isCrypto() && 'price' === name) {
        return isExist(value) ? `${currencySymbol} ${formatNumber(+value)}` : '--';
      }

      return isExist(value) ? `${currencySymbol} ${numberWithCommas(fNumber(value, 2))}` : '--';
    }

    if (this.numericalFields.includes(name)) {
      if (this.isCrypto() && 'quantity' === name) {
        return isExist(value) ? formatNumber(+value, { maxFraction: 8 }) : '--';
      }

      return isExist(value) ? numberWithCommas(fNumber(value, 2)) : '--';
    }

    if (this.dateFields.includes(name)) {
      return isExist(value) ? getDateFormattedString(value) : '--';
    }

    return isExist(value) ? value : '--';
  }
}

const holdingTableField = (type?: string) => new HoldingTableField(type);

export default holdingTableField;
