import { useState, useEffect } from 'react';

import useProfile from 'auth/hooks/useProfile';
import { useAuthState } from 'auth/auth.context';
import * as referConstants from 'refer/refer.constants';

const useInviteFriends = () => {
  useProfile();
  const { user } = useAuthState();

  const [loading, setLoading] = useState<boolean>(false);
  const [referralLink, setReferralLink] = useState<string>('');

  useEffect(() => {
    if (!user) {
      setLoading(true);
      return;
    }

    const { referralName, referralLevel } = user;

    if (!!referralName && !!referralLevel) {
      const link = `${referConstants.BASE_REFERRAL_LINK}?r=${referralName}`;

      setReferralLink(link);
    }

    setLoading(false);
  }, [user]);

  return { loading, referralLink };
};

export default useInviteFriends;
