import React, { useEffect, useState } from 'react';

import { getAccountDetails } from 'api/request.api';
import { getCurrencySymbol } from 'common/currency-helper';
import { fNumber, numberWithCommas } from 'common/number.helper';
import { IInitialCounter } from 'account/hooks/useAccountDetail';
import CircularSpinner from 'common/components/spinner/circular-spinner';
import { Account, IAccountDetails, IFormFieldItem } from 'auth/auth.types';

import AssociateLoan from './associate-loan';

interface IBusinessSideCard {
  id: number;
  associatedLoan?: number;
  formFields: IFormFieldItem[];
  handleRefresh: (key: keyof IInitialCounter) => void;
}

const currencyFields = ['balance', 'equity', 'amountDue'];

const BusinessSideCard: React.FC<IBusinessSideCard> = ({ id, handleRefresh, associatedLoan, formFields }) => {
  const [loading, setLoading] = useState(false);
  const [associatedAccount, setAssociatedAccount] = useState<Account>();
  const [showDropdown, setShowDropdown] = useState(!associatedLoan ? true : false);

  useEffect(() => {
    (async () => {
      if (associatedLoan) {
        setLoading(true);
        const { data, error }: { data: Account; error: any } = await getAccountDetails(`${associatedLoan}`, false);
        setLoading(false);

        if (!error && data) {
          setAssociatedAccount(data);
        }
      }
    })();
  }, [associatedLoan]);

  const balance = associatedAccount?.balance;
  const accountDetails = associatedAccount?.accountDetails;
  const currencySymbol = getCurrencySymbol(associatedAccount?.currency || '$');

  const getValue = (key: string) => {
    if (!accountDetails) {
      return '';
    }

    if ('balance' === key && balance) {
      return currencySymbol + numberWithCommas(fNumber(balance));
    }

    const value = accountDetails[key as keyof IAccountDetails] || '';

    if ('interestRate' === key) {
      return value + '%';
    }

    if (value && currencyFields.includes(key)) {
      return currencySymbol + numberWithCommas(fNumber(value as string | number));
    }

    return value;
  };

  const renderAssociateLoanSection = () => {
    return (
      <AssociateLoan
        id={id}
        handleRefresh={handleRefresh}
        isClosable={!!associatedLoan}
        onClose={() => setShowDropdown(false)}
        associatedLoanId={accountDetails?.associatedLoan}
      />
    );
  };

  const renderContent = () => {
    if (loading) {
      return <CircularSpinner />;
    }

    if (showDropdown) {
      return renderAssociateLoanSection();
    }

    if (!(associatedLoan && accountDetails)) {
      return renderAssociateLoanSection();
    }

    return (
      <div className='account-sidecard'>
        <p className='account-sidecard--title'>Associated Loan</p>
        <ul className='account-sidecard__list'>
          {formFields.map((datum, index) => {
            return (
              <li className='account-sidecard__list-item' key={index}>
                <span className='account-sidecard__list-item--title'>{datum.label}</span>
                <span className='account-sidecard__list-item--value'>{getValue(datum.name) || '--'}</span>
              </li>
            );
          })}
          <div className='account-sidecard--action'>
            <button className='mm-btn-animate' onClick={() => setShowDropdown(true)}>
              Change Loan
            </button>
          </div>
        </ul>
      </div>
    );
  };

  return <div className='col-lg-3 account-sidecard-wrapper'>{renderContent()}</div>;
};

export default BusinessSideCard;
