import { useState, useEffect } from 'react';

import { getRecentActivities } from 'api/request.api';
import { IRecentActivity } from 'dashboard/dashboard.types';

const useRecentActivity = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [recentActivities, setRecentActivities] = useState<IRecentActivity[]>([]);

  useEffect(() => {
    const fetchRecentActivity = async () => {
      setLoading(true);
      const { data, error }: { data: IRecentActivity[]; error: any } = await getRecentActivities();

      if (!error && data) {
        setRecentActivities(data);
      }

      setLoading(false);
    };

    fetchRecentActivity();
  }, []);

  return { loading, recentActivities };
};

export default useRecentActivity;
