import { config } from 'dotenv';

import { EAppEnv } from './app.enum';

config();

const env = {
  APP_ENVIRONMENT: process.env.NODE_ENV,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID,
  STRIPE_PUBLIC_KEY:
    process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
    'pk_test_51I4so7DLx1dWdl1laumKIkRWNNyviBj9qhCEgSw5oxfqgo970vyS3CUNs7g3EizldRbNO6Luh5aaWp25cqtJGpmy0024c7u8Zx',
  STRIPE_DEFAULT_PLAN: process.env.REACT_APP_STRIPE_DEFAULT_PLAN || 'price_1I608gDLx1dWdl1lZrpt4mrH',
  ZABO_CONFIGURATION: {
    ZABO_CLIENT_ID:
      process.env.REACT_APP_ZABO_CLIENT_ID || '4bz22yXnR2lCbbUp3k4lvKwTikXSAP61D0q6GSy0GnwHiXoQYPlrgtloX6AhBaeM',
    ZABO_ENV: process.env.REACT_APP_ZABO_ENV || 'sandbox',
  },
  GOOGLE_ANALYTICS_TRACKING_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || '',
  GOOGLE_ADS_TRACKING_ID: process.env.REACT_APP_GOOGLE_ADS_TRACKING_ID || '',
  FACEBOOK_PIXEL_CODE: process.env.REACT_APP_FACEBOOK_PIXEL_CODE || '',
  CRISP_WEBSITE_ID: process.env.REACT_APP_CRISP_WEBSITE_ID || 'b3e9c107-b8dc-4e81-88a4-cdfa7b113a8f',
  PEERBOARD_BOARD_ID: process.env.REACT_APP_PEERBOARD_BOARD_ID || 1610766973,
  GIST_ID: process.env.REACT_APP_GIST_ID || 49920495,
  GIST_APP_ID: process.env.REACT_APP_GIST_APP_ID || '4dvgn76o',
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || '',
  APP_ENV: process.env.REACT_APP_APP_ENV || EAppEnv.LOCAL,
  APP_VERSION: process.env.REACT_APP_VERSION || '',
  BASE_REFERRAL_LINK: process.env.REACT_APP_BASE_REFERRAL_LINK || 'http://localhost:3000',
  SOCKET_URL: process.env.REACT_APP_SOCKET_URL || 'https://devapi.moneyminx.com/events',
  ETH_JSONRPC_URL: process.env.REACT_APP_ETH_JSONRPC_URL || '',
  QROKA_APP_NAME: process.env.REACT_QROKA_APP_NAME || 'Qroka by Money Minx',
  GOOGLE_RECAPTCHA_SITE_KEY: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || '6LeBLCEpAAAAAMtKyo33OhpiGjz81YPsdhzyu0uW',
};

export default env;
