import React, { useState, useEffect } from 'react';

interface ISwitchProps {
  checked: boolean;
  label?: string;
  name: string;
  className?: string;
  onClick: (checked: boolean) => void;
}

const Switch: React.FC<ISwitchProps> = ({ checked, name, label, className = '', onClick }) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleClick = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    onClick(newValue);
  };

  return (
    <span className={`mm-switch-block d-flex align-items-center ${className}`}>
      <input
        value='true'
        name={name}
        type='checkbox'
        aria-checked={isChecked}
        className='mm-switch-input'
        checked={isChecked}
        onChange={() => {}}
      />
      <label className='mm-switch' onClick={handleClick} role='button' />
      {!!label && (
        <>
          <span className='d-none d-md-block ml-2 mm-switch-text'>{label}</span>
          <span className='d-md-none ml-2 mm-switch-text'>{label}</span>
        </>
      )}
    </span>
  );
};

export default Switch;
