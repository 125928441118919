import { Form } from 'react-bootstrap';
import React, { useState, useMemo, useRef, ChangeEvent, useEffect } from 'react';

import debounce from 'lodash/debounce';
import { logger } from 'common/logger.helper';
import { TApiResponse } from 'api/request.api';
import useClickOutside from 'common/hooks/useClickOutside';

interface ISearchInputProps {
  value?: string;
  placeholder?: string;
  icon?: string | null;
  onSearch: (text: string) => TApiResponse;
  onTextChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onRenderItemList: (items: any[]) => React.ReactNode;
}

const SearchInput: React.FC<ISearchInputProps> = ({
  value = '',
  icon = null,
  placeholder = 'Search',
  onSearch,
  onTextChange,
  onRenderItemList,
}) => {
  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isListVisible, setIsListVisible] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(value);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setSearchTerm(value);
  }, [value]);

  useClickOutside(ref, () => setIsListVisible(false));

  const fetchItems = useMemo(
    () =>
      debounce(async (term: string) => {
        try {
          setLoading(true);
          const { data } = await onSearch(term);
          setItems(data);
        } catch (err) {
          logger.log('Error while searching', err);
        } finally {
          setLoading(false);
          setIsListVisible(true);
        }
      }, 500),
    [onSearch]
  );

  const onSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    fetchItems(e.target.value);
    if (onTextChange) {
      onTextChange(e);
    }
  };

  return (
    <div className='search-input' onClick={() => setIsListVisible(false)} role='textbox' ref={ref}>
      {!!icon && !!value && <img src={icon} alt='Search' className='search-input__logo' />}
      <Form.Control
        type='text'
        value={searchTerm}
        autoComplete='off'
        placeholder={placeholder}
        className={icon ? 'search-input--highlighted' : ''}
        onChange={onSearchTermChange}
      />

      {!loading && isListVisible && !!items.length && (
        <div className='search-input__list'> {onRenderItemList(items)} </div>
      )}
    </div>
  );
};

export default SearchInput;
