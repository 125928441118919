import React from 'react';
import Skeleton from 'react-loading-skeleton';

import classNames from 'common/classes.helper';

interface IDashboardAccountItemProps {
  name: string;
  imgSrc?: string;
  updatedAt: string;
  balanceText: string;
  balanceInfo: string;
  className?: string;
  loading?: boolean;
  onClick: VoidFunction;
}

const DashboardAccountItem: React.FC<IDashboardAccountItemProps> = ({
  name,
  imgSrc,
  updatedAt,
  balanceText,
  balanceInfo,
  className,
  loading = false,
  onClick,
}) => {
  return (
    <div className={classNames('dashboard-account-item', className && className)} onClick={onClick} role='button'>
      {imgSrc && <img src={imgSrc} alt={name} />}

      <div
        className={classNames(
          'dashboard-account-item__content',
          !imgSrc && 'dashboard-account-item__content--full-width'
        )}
      >
        <div className='d-flex flex-column pr-2'>
          <span className='dashboard-account-item__name'>{name}</span>
          <span className='dashboard-account-item__updated'>{updatedAt}</span>
        </div>

        <div className='d-flex flex-column'>
          <span className='dashboard-account-item__balance-text'>{balanceText}</span>
          <span className='dashboard-account-item__balance-info'>
            {loading ? <Skeleton width={80} /> : balanceInfo}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DashboardAccountItem;
