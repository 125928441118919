import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';

import { Account } from 'auth/auth.types';
import { events } from '@mm/data/event-list';
import GALink from 'common/components/ga-link';
import { useModal } from 'common/components/modal';
import useSettings from 'setting/hooks/useSettings';
import { groupByProviderName } from 'auth/auth.helper';
import { MMPieChart } from 'common/components/pie-chart';
import SettingModal from 'allocation/modal/setting-modal';
import { getCurrencySymbol } from 'common/currency-helper';
import { base64Encode } from 'common/helper/string.helper';
import DefaultAvatar from 'assets/icons/default-avatar.svg';
import { mapAllocations } from 'allocation/allocation.helper';
import { StripeSubscriptionStatus } from 'setting/setting.enum';
import ChartShareModal from 'allocation/modal/chart-share-modal';
import { getStringDate, getMonthYear } from 'common/moment.helper';
import { AllocationSectionEnum } from 'allocation/allocation.enum';
import { getHoldingsAccountsByDescription } from 'api/request.api';
import SelectAccountModal from 'allocation/modal/select-account.modal';
import useCurrentSubscription from 'auth/hooks/useCurrentSubscription';
import useSimilarAllocation from 'allocation/hooks/useSimilarAllocation';
import AllocationSortIcon from 'allocation/components/allocation-sort-icon';
import { ReactComponent as Share } from 'assets/images/allocation/share.svg';
import AllocationTableBody from 'allocation/components/allocation-table-body';
import { Allocation, AllocationOverviewProps } from 'allocation/allocation.type';
import { ReactComponent as SettingsIcon } from 'assets/images/allocation/settings.svg';
import { ReactComponent as MeasureUpIcon } from 'assets/images/allocation/measure-up-icon.svg';
import { ReactComponent as AllocationChartSVG } from 'assets/images/allocation/allocation-chart.svg';
import { ReactComponent as AllocationLegendSVG } from 'assets/images/allocation/allocation-legend.svg';

import AllocationLegend from './allocation-legend';
import { SelectedAllocations } from './previous-allocation';

export interface IAllocations {
  allocationKey: string;
  allocations: Allocation[];
}

const AllocationOverview: React.FC<AllocationOverviewProps> = ({
  filter,
  chartData,
  allocationsRecord,
  accountWithIssues,
}) => {
  const history = useHistory();
  const { data } = useSettings();
  const chartShareModal = useModal();
  const chartSettingModal = useModal();
  const selectAccountModal = useModal();
  const { similarAllocationChartData } = useSimilarAllocation(filter);
  const { currentSubscription } = useCurrentSubscription();
  const isFreeUser =
    currentSubscription?.subscriptionStatus !== StripeSubscriptionStatus.TRIALING &&
    currentSubscription?.subscriptionStatus !== StripeSubscriptionStatus.ACTIVE;

  const [section, setSection] = useState<AllocationSectionEnum>(AllocationSectionEnum.MY_ALLOCATION);
  const [currencySymbol, setCurrencySymbol] = useState<string>('');
  const [hidden, setHidden] = useState<string[]>(['']);
  const [multiAccounts, setMultiAccounts] = useState<Account[]>([]);
  const [processingCollapse, setProcessingCollapse] = useState<boolean>(false);
  const [colorMap, setColorMap] = useState<Map<string, string>>(new Map<string, string>());

  const [sortOrder, setSortOrder] = useState<'DESC' | 'ASC'>('DESC');

  useEffect(() => {
    if (data) {
      setCurrencySymbol(getCurrencySymbol(data.currency));
    }
  }, [data]);

  const allocationsList = useMemo(() => {
    return mapAllocations(allocationsRecord);
  }, [allocationsRecord]);

  const getTotal = (key: string) => {
    return chartData.find((datum) => datum.group === key);
  };

  const toggleAllocation = (key: string) => {
    if (hidden.includes(key)) {
      const tempArr = hidden.filter((item) => item !== key);

      return setHidden(tempArr);
    }

    return setHidden([...hidden, key]);
  };

  const isHidden = (key: string) => hidden.includes(key);

  const changeAllocationSection = (sec: AllocationSectionEnum) => {
    setSection(sec);
  };

  const getSectionTitleClass = (sec: AllocationSectionEnum) => {
    if (section === sec) {
      return 'mm-allocation-overview__navigation-title mm-allocation-overview__navigation-title--active';
    }

    return 'mm-allocation-overview__navigation-title';
  };

  const getSectionClass = (sec: AllocationSectionEnum) => {
    if (section !== sec) {
      return 'col-xl-4 d-none d-xl-block';
    }

    return 'col-xl-4';
  };

  const gotoDetailPage = async (description: string) => {
    if (description.includes('&')) {
      description = description.replace(/&/g, '%26');
    }
    const { data, error } = await getHoldingsAccountsByDescription(description);

    if (!error) {
      setMultiAccounts(data);
      if (data.length === 1) {
        const encodedAccountId = base64Encode(data[0].id);
        return history.push('/account-details/' + encodedAccountId);
      } else {
        selectAccountModal.open();
      }
    }
  };

  const routeToAccountDetail = (accountId: number) => {
    const encodedAccountId = base64Encode(`${accountId}`);
    history.push('/account-details/' + encodedAccountId);
  };

  const handleChangeColorData = (colorData: Map<string, string>) => {
    setColorMap(colorData);
  };

  const accountsByProvider = groupByProviderName(accountWithIssues);

  return (
    <div className='content-wrapper'>
      <div className='container'>
        <section className='mm-allocation-overview'>
          <div className='mm-allocation-overview__wrapper'>
            <div className='mm-allocation-overview__navigation mb-3'>
              <div className='d-flex'>
                <div
                  className={getSectionTitleClass(AllocationSectionEnum.MY_ALLOCATION)}
                  onClick={() => changeAllocationSection(AllocationSectionEnum.MY_ALLOCATION)}
                  role='button'
                >
                  My Allocation
                </div>
                <div
                  className={getSectionTitleClass(AllocationSectionEnum.PREVIOUS_ALLOCATION)}
                  onClick={() => changeAllocationSection(AllocationSectionEnum.PREVIOUS_ALLOCATION)}
                  role='button'
                >
                  Previous Allocation
                </div>
                <div
                  className={getSectionTitleClass(AllocationSectionEnum.SIMILAR_ALLOCATION)}
                  onClick={() => changeAllocationSection(AllocationSectionEnum.SIMILAR_ALLOCATION)}
                  role='button'
                >
                  Similar Allocation
                </div>
              </div>
              <div className='mm-allocation-overview__line' />
            </div>
            {/*Todo change this logic in the future to show accounts not done with first load*/}
            {accountWithIssues.length > 100000 && (
              <div className='card mm-setting-card mt-0 processing-card'>
                <div className='title-section'>
                  <span
                    className={['processing', processingCollapse ? 'processing-collapse' : ''].join(' ')}
                    onClick={() => setProcessingCollapse(!processingCollapse)}
                    role='button'
                  >
                    Processing
                  </span>
                  <span className='desc'>These accounts are still loading. Check back soon.</span>
                </div>
                <div className={processingCollapse ? 'd-none' : ''}>
                  {Object.entries(accountsByProvider).map(([providerName, accounts], index) => (
                    <div key={index} className='content-section my-3'>
                      <div className='d-flex flex-direction-row justify-content-between'>
                        <img
                          src={accounts[0].providerLogo || DefaultAvatar}
                          className='mr-3 mr-md-4 accounts-provider-logo my-1'
                          alt={`${providerName} logo`}
                        />
                        <div className='provider-name my-1'>{providerName}</div>
                      </div>
                      {accounts.map((item, key) => (
                        <div key={key}>
                          <Link to='#' onClick={() => routeToAccountDetail(item.id)}>
                            <div className='account-name m-b-2'>{item.accountName}</div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className='row'>
              <div className={getSectionClass(AllocationSectionEnum.MY_ALLOCATION)}>
                <div className='mm-allocation-overview__block d-lg-block'>
                  <div className='allocation-card-top'>
                    <div className='mm-allocation-overview__block--date'>{getStringDate()}</div>
                    <div className='mm-allocation-overview__block--title'>Current allocation</div>
                    <p className='mm-allocation-overview__block--subtitle'>Current allocation based on your holdings</p>
                    <div className='mm-allocation-overview__block--action'>
                      <SettingsIcon title='Chart Settings' className='mr-3' onClick={() => chartSettingModal.open()} />
                      <Share title='Share Chart' onClick={() => chartShareModal.open()} />
                    </div>
                  </div>
                  <div className='allocation-content'>
                    <div className='text-center text-md-left d-xl-block d-md-flex align-items-md-center justify-content-md-center mm-allocation-overview__block-chart-overview'>
                      {Object.keys(allocationsRecord).length === 0 && chartData.length === 0 ? (
                        <div className='mm-allocation-overview__block-element text-center'>
                          <div className='mm-allocation-overview__block-element--middle'>
                            <div className='d-inline-flex align-items-center'>
                              <div className='mm-allocation-overview__block-element--text ml-2'>Not enough data</div>
                            </div>
                            <p>Historical charts will become available once your cross a month end.</p>
                          </div>
                        </div>
                      ) : (
                        <div
                          className='text-center text-md-left d-xl-block d-md-flex align-items-md-center p-b-4 allocation-page-chart-wrapper'
                          id='current-allocation-pie-chart'
                        >
                          <MMPieChart
                            chartData={chartData}
                            currencySymbol={currencySymbol}
                            colorData={colorMap}
                            handleChangeColorData={(changeColorData: Map<string, string>) =>
                              handleChangeColorData(changeColorData)
                            }
                          />
                          <AllocationLegend
                            chartData={chartData}
                            currencySymbol={currencySymbol}
                            colorData={colorMap}
                          />
                        </div>
                      )}
                    </div>
                    <div className='mm-allocation-overview__table'>
                      <table>
                        <thead>
                          <tr>
                            <th className='mm-allocation-overview__table--head'>Position</th>
                            <th className='mm-allocation-overview__table--head'>Allocation</th>
                            <th
                              className='mm-allocation-overview__table--head'
                              role='button'
                              onClick={() => {
                                setSortOrder(sortOrder === 'DESC' ? 'ASC' : 'DESC');
                              }}
                            >
                              Value
                              <AllocationSortIcon order={sortOrder} />
                            </th>
                          </tr>
                        </thead>

                        {allocationsList.map((allocationItem) => {
                          return (
                            <AllocationTableBody
                              sortOrder={sortOrder}
                              key={allocationItem.allocationKey}
                              getTotal={getTotal}
                              isHidden={isHidden}
                              allocations={allocationItem.allocations}
                              allocationKey={allocationItem.allocationKey}
                              currencySymbol={currencySymbol}
                              gotoDetailPage={gotoDetailPage}
                              toggleAllocation={toggleAllocation}
                            />
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className={getSectionClass(AllocationSectionEnum.PREVIOUS_ALLOCATION)}>
                <SelectedAllocations
                  filter={filter}
                  currencySymbol={currencySymbol}
                  gotoDetailPage={(d) => gotoDetailPage(d)}
                  colorData={colorMap}
                  handleChangeColorData={(changeColorData: Map<string, string>) =>
                    handleChangeColorData(changeColorData)
                  }
                />
              </div>

              <div className={getSectionClass(AllocationSectionEnum.SIMILAR_ALLOCATION)}>
                <div className='mm-allocation-overview__block d-lg-block'>
                  <div className='allocation-card-top'>
                    <div className='mm-allocation-overview__block--date'>{getMonthYear()}</div>
                    <div className='mm-allocation-overview__block--title'>
                      <MeasureUpIcon className='mb-1' /> Similar Investors
                    </div>
                    <p className='mm-allocation-overview__block--subtitle'>
                      Here's how investors with similar profiles are currently allocated
                    </p>
                    <div className='mm-allocation-overview__block--action' />
                  </div>
                  <div className='allocation-content'>
                    {isFreeUser ? (
                      <div className='text-center text-md-left d-xl-block d-md-flex align-items-md-center justify-content-md-center mm-allocation-overview__block-chart-overview'>
                        <AllocationChartSVG className='mm-allocation-overview__block--chart' />
                        <AllocationLegendSVG className='mm-allocation-overview__block--legend' />
                        <div className='mm-allocation-overview__block-element text-center'>
                          <div className='mm-allocation-overview__block-element--middle'>
                            <div className='d-inline-flex align-items-center'>
                              <MeasureUpIcon />
                              <div className='mm-allocation-overview__block-element--text ml-2'>Minx Measure-up</div>
                            </div>
                            <p>Upgrade to pro to unlock allocation comparison.</p>
                            <GALink
                              to='/settings?active=Plan'
                              className='mm-btn-animate mm-btn-primary'
                              eventArgs={events.upgradeFromAllocationSimilar}
                            >
                              Upgrade
                            </GALink>
                          </div>
                        </div>
                      </div>
                    ) : Object.keys(chartData).length > 2 ? (
                      <div className='text-center text-md-left d-xl-block d-md-flex align-items-md-center justify-content-md-center mm-allocation-overview__block-chart-overview'>
                        {similarAllocationChartData && (
                          <div
                            className='text-center text-md-left d-xl-block d-md-flex align-items-md-center p-b-4 allocation-page-chart-wrapper'
                            id='current-allocation-pie-chart'
                          >
                            <MMPieChart
                              chartData={similarAllocationChartData}
                              currencySymbol={currencySymbol}
                              colorData={colorMap}
                              handleChangeColorData={(changeColorData: Map<string, string>) =>
                                handleChangeColorData(changeColorData)
                              }
                            />
                            <AllocationLegend
                              chartData={similarAllocationChartData}
                              currencySymbol={currencySymbol}
                              colorData={colorMap}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className='text-center text-md-left d-xl-block d-md-flex align-items-md-center justify-content-md-center mm-allocation-overview__block-chart-overview'>
                        <AllocationChartSVG className='mm-allocation-overview__block--chart' />
                        <AllocationLegendSVG className='mm-allocation-overview__block--legend' />
                        <div className='mm-allocation-overview__block-element text-center'>
                          <div className='mm-allocation-overview__block-element--middle'>
                            <div className='d-inline-flex align-items-center'>
                              <MeasureUpIcon />
                              <div className='mm-allocation-overview__block-element--text ml-2'>Minx Measure-up</div>
                            </div>
                            <p>Allocation comparison unlocks when you have 3 classification types.</p>
                            <GALink
                              to='/connect-account'
                              className='mm-btn-animate mm-btn-primary'
                              eventArgs={events.connectFromAllocationSimilar}
                            >
                              Add Accounts
                            </GALink>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SettingModal settingModal={chartSettingModal} />
          <ChartShareModal
            chartShareModal={chartShareModal}
            chartComponent={
              <MMPieChart
                chartData={chartData}
                currencySymbol={currencySymbol}
                colorData={colorMap}
                handleChangeColorData={(changeColorData: Map<string, string>) => handleChangeColorData(changeColorData)}
                share
              />
            }
            chartLegendComponent={
              <AllocationLegend chartData={chartData} currencySymbol={currencySymbol} colorData={colorMap} sharing />
            }
          />
          <SelectAccountModal selectAccountModal={selectAccountModal} multiAccounts={multiAccounts} />
        </section>
      </div>
    </div>
  );
};

export default AllocationOverview;
