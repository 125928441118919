import React, { useEffect, useRef } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { useAuthState } from 'auth/auth.context';
import LoadingScreen from 'common/loading-screen';
import WebsiteLayout from 'website/website.layout';

import usePeerboard from './usePeerboard';
import CommunityLayout from './community.layout';

function Community() {
  const { isAuthenticated, loading } = useAuthState();

  if (loading) {
    return <LoadingScreen />;
  }

  return isAuthenticated ? (
    <CommunityLayout>
      <PeerBoardContainer isAuthenticated={isAuthenticated} />
    </CommunityLayout>
  ) : (
    <WebsiteLayout isSignupToday={false}>
      <PeerBoardContainer isAuthenticated={isAuthenticated} />
    </WebsiteLayout>
  );
}

function PeerBoardContainer({ isAuthenticated }: Record<string, boolean>) {
  const containerRef = useRef<any>(null);
  const { loading, error, createPeerBoard, createPeerboardAnonymously } = usePeerboard(containerRef);

  const createPeerBoardRef = useRef(createPeerBoard);
  const anonymousPeerboardRef = useRef(createPeerboardAnonymously);

  useEffect(() => {
    if (isAuthenticated) {
      createPeerBoardRef.current();
    } else {
      anonymousPeerboardRef.current();
    }
  }, [isAuthenticated]);

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Community | Money Minx</title>
          <meta name='description' content='Discover, discuss and profit from new investment opportunities.' />
          {/*Facebook tags*/}
          <meta property='og:title' content='Community | Money Minx' />
          <meta property='og:description' content='Discover, discuss and profit from new investment opportunities.' />
          <meta property='og:url' content='https://www.moneyminx.com/community' />
          <meta property='og:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
          <meta property='og:image:secure_url' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
          <meta property='og:image:alt' content='Discover, discuss and profit from new investment opportunities.' />
          {/*Twitter tags*/}
          <meta property='twitter:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
          <meta
            property='twitter:image:alt'
            content='Discover, discuss and profit from new investment opportunities.'
          />
        </Helmet>
      </HelmetProvider>
      <div className='mm-peerboard-wrapper'>
        {error}
        {loading && 'Loading...'}
        <div ref={containerRef} />
      </div>
    </div>
  );
}

export default Community;
