export enum EIncomeAction {
  SET_CURRENCY = 'SET_CURRENCY',
  SET_PROCESSING = 'SET_PROCESSING',
  RESET_PROCESSING = 'RESET_PROCESSING',
  SET_ACCOUNTS = 'SET_ACCOUNTS',
  SET_TOTALS = 'SET_TOTALS',
}

export enum EChartValueType {
  ACTUAL = 'actual',
  PROJECTION = 'projection',
}

export enum EIncomeTableType {
  GROUP_BY_ACCOUNT = 'GROUP_BY_ACCOUNT',
  GROUP_BY_TYPE = 'GROUP_BY_TYPE',
}
