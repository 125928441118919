import React, { Suspense } from 'react';

import { PerformanceView } from 'performance';
import LoadingScreen from 'common/loading-screen';
import { FilterProvider } from 'filter/filter.context';
import { NetworthProvider } from 'networth/networth.context';
import AuthorizedProvider from 'provider/authorized.provider';
import { PerformanceProvider } from 'performance/performance.context';

const PerformanceRoute: React.FC = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <AuthorizedProvider>
        <FilterProvider>
          <NetworthProvider>
            <PerformanceProvider>
              <PerformanceView />
            </PerformanceProvider>
          </NetworthProvider>
        </FilterProvider>
      </AuthorizedProvider>
    </Suspense>
  );
};

export default PerformanceRoute;
