import { useState } from 'react';

import useToast from 'common/hooks/useToast';
import { Dispatch } from 'common/common.types';
import { useAuthDispatch } from 'auth/auth.context';
import { setAccountSuccess } from 'auth/auth.actions';
import { getRefreshedAccount } from 'auth/auth.service';
import { Account, EProviderType } from 'auth/auth.types';
import { getAccount, getAllAccounts, getLatestProviderAccounts, getNewAccounts, TApiResponse } from 'api/request.api';

/**
 * Each account related functions and data will reside here
 * for now we will have single fetch new account method
 */
const useAccounts = () => {
  const { mmToast } = useToast();
  const dispatch = useAuthDispatch();
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState<Account[]>();

  const fetchAccountApi = async (cb: () => TApiResponse, errMessage: string) => {
    setLoading(true);
    const { error, data } = await cb();

    if (error) {
      setLoading(false);

      return mmToast(errMessage, { type: 'error' });
    }

    dispatch(setAccountSuccess(data));
    setAccounts(data);

    return setLoading(false);
  };

  return {
    fetchNewAccounts: async () => {
      await fetchAccountApi(getNewAccounts, 'Error occurred on fetching new Account');
    },

    fetchLatestProviderAccounts: async () => {
      await fetchAccountApi(getLatestProviderAccounts, 'Error occurred on fetching latest provider accounts');
    },

    fetchAccounts: async () => {
      await fetchAccountApi(getAccount, 'Error occurred on fetching Account');
    },

    fetchAllAccounts: async () => {
      await fetchAccountApi(getAllAccounts, 'Error occurred on fetching all Accounts');
    },

    fetchRefreshedAccounts: async (dispatcher: Dispatch, provider: EProviderType) => {
      setLoading(true);
      const { error, data } = await getRefreshedAccount({ dispatch: dispatcher, provider });

      if (error) {
        setLoading(false);
        mmToast('Error occurred on fetching refreshed account', { type: 'error' });

        return { error, data };
      }

      setLoading(false);

      return { error, data };
    },

    removeAccount: (accId: number) => {
      const filteredAccounts = accounts?.filter(({ id }) => id !== accId);

      setAccounts(filteredAccounts);
    },

    removeAccounts: (accIds: number[]) => {
      const filteredAccounts = accounts?.filter(({ id }) => !accIds.includes(id));
      setAccounts(filteredAccounts);
    },

    removeProviderAccounts: (acc: Account) => {
      const filteredAccounts = accounts?.filter(
        (account) => !(account.providerId === acc.providerId && account.mmAccountProvider === acc.mmAccountProvider)
      );

      setAccounts(filteredAccounts);
    },

    loading,
    accounts,
  };
};

export default useAccounts;
