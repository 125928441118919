import React from 'react';

import { getCurrencySymbol } from 'common/currency-helper';
import { IExistingManualAccount } from 'account/account.type';
import { fNumber, numberWithCommas } from 'common/number.helper';
import { ReactComponent as MMMetalIcon } from 'assets/icons/metals.svg';

interface IExistingMetalAccount {
  loading: boolean;
  accounts: IExistingManualAccount[];
  handleAddAccountClick: VoidFunction;
  handleExistingAccountClick: (accountId: number) => void;
}

const ExistingMetalAccount: React.FC<IExistingMetalAccount> = ({
  loading,
  accounts,
  handleAddAccountClick,
  handleExistingAccountClick,
}) => {
  return (
    <div className='existing-account app-modal'>
      <div className='app-modal__header'>
        <h2 className='app-modal__title'>Use an existing account?</h2>
      </div>

      <p className='app-modal__description'>
        Do you want to add this metals to one of these accounts or create a new account?
      </p>

      <div className='d-flex align-items-center existing-account__header'>
        <MMMetalIcon />
        <span className='existing-account__title pl-4'>Current Metal Accounts</span>
      </div>

      <div className='existing-account__body'>
        {accounts.map((account) => (
          <button
            className='d-flex justify-content-between existing-account__item'
            key={account.id}
            onClick={() => handleExistingAccountClick(account.id)}
          >
            <span>{account.accountName}</span>
            <span>
              {account.currency ? getCurrencySymbol(account.currency) : null}
              {`${numberWithCommas(fNumber(account.balance, 2))}`}
            </span>
          </button>
        ))}
      </div>

      <button type='button' className='mm-btn-primary mm-btn-animate' onClick={() => handleAddAccountClick()}>
        {loading ? (
          <>
            <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
            <span className='ml-1'>Adding...</span>
          </>
        ) : (
          <>Add a new account</>
        )}
      </button>
    </div>
  );
};

export default ExistingMetalAccount;
