import React from 'react';

import { Modal, ModalType } from 'common/components/modal';
import useMFA from 'auth/hooks/useMFA';

interface IDisableMFAModal {
  disableMFAModal: ModalType;
}

const DisableMFAModal: React.FC<IDisableMFAModal> = ({ disableMFAModal }) => {
  const { disableMFA, loading } = useMFA();

  const handleDisableMFA = async () => {
    await disableMFA();

    return disableMFAModal.close();
  };

  return (
    <Modal {...disableMFAModal.props} title='Are you sure?' size='md'>
      <div className='modal-wrapper disable-mfa-modal-wrapper'>
        <p>
          Are you sure you want to disable multi factor authentication? If someone is able to guess
          your password it will be easier for them to login to your account.
        </p>
        <div className='modal-btn-wrapper'>
          <button className='mm-btn-animate mm-btn-primary' onClick={disableMFAModal.close}>
            Never mind
          </button>
          <button className='mm-btn-text' disabled={loading} onClick={handleDisableMFA}>
            Turn MFA off
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DisableMFAModal;
