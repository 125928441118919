import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import { Formik } from 'formik';
import useToast from 'common/hooks/useToast';
import { patchAccount } from 'api/request.api';
import MMToolTip from 'common/components/tooltip';
import { IAccountDetails } from 'auth/auth.types';
import { IMonthlyExpenseFormValue } from 'account/account.type';
import { fNumber, numberWithCommas } from 'common/number.helper';

const initialFormValue: IMonthlyExpenseFormValue = {
  admin: '',
  insurance: '',
  managementFees: '',
  propertyTaxes: '',
  associationDues: '',
  utilities: '',
  mortgage: '',
};

interface IMonthlyExpenseProps {
  accountId: number;
  currencySymbol: string;
  accountDetails: IAccountDetails;
  handleReload: VoidFunction;
  locked: boolean;
}

const MonthlyExpense: React.FC<IMonthlyExpenseProps> = ({
  accountId,
  accountDetails,
  currencySymbol,
  handleReload,
  locked
}) => {
  const { mmToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [formInitialValues, setFormInitialValues] = useState<IMonthlyExpenseFormValue>(initialFormValue);

  useEffect(() => {
    const { admin, insurance, managementFees, propertyTaxes, associationDues, utilities, mortgage } = accountDetails;

    setFormInitialValues({
      admin: admin || '',
      insurance: insurance || '',
      managementFees: managementFees || '',
      propertyTaxes: propertyTaxes || '',
      associationDues: associationDues || '',
      utilities: utilities || '',
      mortgage: mortgage || '',
    });
  }, [accountDetails]);

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={async (values) => {
        try {
          const hasAssociatedMortgage = !!accountDetails.associatedMortgage;
          const { admin, insurance, managementFees, propertyTaxes, associationDues, utilities, mortgage } = values;

          const body = {
            ...(admin ? { admin } : {}),
            ...(insurance ? { insurance } : {}),
            ...(managementFees ? { managementFees } : {}),
            ...(propertyTaxes ? { propertyTaxes } : {}),
            ...(associationDues ? { associationDues } : {}),
            ...(utilities ? { utilities } : {}),
            ...(hasAssociatedMortgage || !mortgage ? {} : { mortgage }),
          };

          setLoading(true);
          await patchAccount(accountId.toString(), body);
          handleReload();
          mmToast('Successfully Updated', { type: 'success' });
        } catch (err) {
          mmToast('Something went wrong', { type: 'error' });
        } finally {
          setLoading(false);
        }
      }}
    >
      {(props) => {
        const { values, handleChange, handleSubmit } = props;

        const getTotalValue = () => {
          const total = Object.values(values).reduce((acc, current) => {
            if (!current) {
              return acc;
            }

            return acc + parseFloat(current);
          }, 0);

          return `${currencySymbol} ${numberWithCommas(fNumber(total, 2))}`;
        };

        return (
          <div className='monthly-expense'>
            {locked &&
            <div className='locked-area'>
              <div>
                <Link to='/settings?active=Plan' className='purple-links small-bold'>
                  Track your real estate expenses with a pro account.
                </Link>
              </div>
            </div>
            }
            <h5 className='monthly-expense__title'>Monthly Expenses</h5>
            <span className='monthly-expense__description'>Update your expenses below.</span>

            <form onSubmit={handleSubmit} className='monthly-expense__form'>
              <div className='row align-items-center justify-content-between real-estate-input-group'>
                <label className='col-8 pl-0 monthly-expense__form-label'>Admin</label>
                <div className='col-4 pr-0 currency-input'>
                  <Form.Control type='number' name='admin' value={values.admin} onChange={handleChange} step= 'any'/>
                  <span className='currency-input__symbol'>{currencySymbol}</span>
                </div>
              </div>

              <div className='row align-items-center justify-content-between real-estate-input-group'>
                <label className='col-8 pl-0 monthly-expense__form-label'>Insurance</label>
                <div className='col-4 pr-0 currency-input'>
                  <Form.Control type='number' name='insurance' value={values.insurance} onChange={handleChange} step= 'any'/>
                  <span className='currency-input__symbol'>{currencySymbol}</span>
                </div>
              </div>

              <div className='row align-items-center justify-content-between real-estate-input-group'>
                <label className='col-8 pl-0 monthly-expense__form-label'>Management Fees</label>
                <div className='col-4 pr-0 currency-input'>
                  <Form.Control
                    type='number'
                    name='managementFees'
                    value={values.managementFees}
                    onChange={handleChange}
                    step= 'any'
                  />
                  <span className='currency-input__symbol'>{currencySymbol}</span>
                </div>
              </div>

              <div className='row align-items-center justify-content-between real-estate-input-group'>
                <label className='col-8 pl-0 monthly-expense__form-label'>Property Taxes</label>
                <div className='col-4 pr-0 currency-input'>
                  <Form.Control
                    type='number'
                    name='propertyTaxes'
                    value={values.propertyTaxes}
                    onChange={handleChange}
                    step= 'any'
                  />
                  <span className='currency-input__symbol'>{currencySymbol}</span>
                </div>
              </div>

              <div className='row align-items-center justify-content-between real-estate-input-group'>
                <label className='col-8 pl-0 monthly-expense__form-label'>Association Dues</label>
                <div className='col-4 pr-0 currency-input'>
                  <Form.Control
                    type='number'
                    name='associationDues'
                    value={values.associationDues}
                    onChange={handleChange}
                    step= 'any'
                  />
                  <span className='currency-input__symbol'>{currencySymbol}</span>
                </div>
              </div>

              <div className='row align-items-center justify-content-between real-estate-input-group'>
                <label className='col-8 pl-0 monthly-expense__form-label'>Utilities</label>
                <div className='col-4 pr-0 currency-input'>
                  <Form.Control type='number' name='utilities' value={values.utilities} onChange={handleChange} step= 'any'/>
                  <span className='currency-input__symbol'>{currencySymbol}</span>
                </div>
              </div>

              <div className='row align-items-center justify-content-between real-estate-input-group'>
                <label className='col-8 pl-0 monthly-expense__form-label'>Mortgage</label>
                <div className='col-4 pr-0'>
                  {!!accountDetails.associatedMortgage ? (
                    <MMToolTip message='Mortgage expense will be calculated based on the details of the mortgage'>
                      <div className='currency-input'>
                        <Form.Control
                          type='number'
                          name='mortgage'
                          value={values.mortgage}
                          onChange={handleChange}
                          disabled
                        />
                        <span className='currency-input__symbol'>{currencySymbol}</span>
                      </div>
                    </MMToolTip>
                  ) : (
                    <div className='currency-input'>
                      <Form.Control type='number' name='mortgage' value={values.mortgage} onChange={handleChange} step= 'any'/>
                      <span className='currency-input__symbol'>{currencySymbol}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className='monthly-expense__total-container'>
                <span>Total</span>
                <span>{getTotalValue()}</span>
              </div>

              <button type='submit' className='mm-btn-animate mm-btn-primary monthly-expense__submit'>
                {loading ? (
                  <>
                    <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
                    <span className='ml-1'>Saving...</span>
                  </>
                ) : (
                  <span>Save Changes</span>
                )}
              </button>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default MonthlyExpense;
