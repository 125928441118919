import { useState, useEffect } from 'react';

import { NetworthItem } from 'networth/networth.type';
import { CurrencyOptions } from 'auth/enum/currency-options';
import { getNetworthChart, getNetworthHistory } from 'api/request.api';
import { EIntervalOption, ENetworthChartInterval } from 'dashboard/dashboard.enum';
import { INetworthChartResponse, INetworthHistory } from 'dashboard/dashboard.types';

import { getComparisonDate } from '../dashboard.helper';

const useDashboardChart = (interval: EIntervalOption) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [networthValue, setNetworthValue] = useState<number>(0);
  const [dailyChartData, setDailyChartData] = useState<NetworthItem[]>([]);
  const [monthlyChartData, setMonthlyChartData] = useState<NetworthItem[]>([]);
  const [currencyOption, setCurrencyOption] = useState<string>(CurrencyOptions.USD);
  const [networthHistory, setNetworthHistory] = useState<INetworthHistory | undefined>(undefined);

  useEffect(() => {
    const fetchChartData = async () => {
      setLoading(true);

      const { data: dailyData, error: dailyDataErr }: { data: INetworthChartResponse; error: any } =
        await getNetworthChart({
          type: ENetworthChartInterval.DAILY,
        });
      const { data: monthlyData, error: monthlyDataErr }: { data: INetworthChartResponse; error: any } =
        await getNetworthChart({
          type: ENetworthChartInterval.MONTHLY,
        });

      if (dailyData && !dailyDataErr) {
        setCurrencyOption(dailyData.currency);
        setDailyChartData(dailyData.networth);
      }

      if (monthlyData && !monthlyDataErr) {
        setMonthlyChartData(monthlyData.networth);

        // set today's networth value
        const currentNetworth = monthlyData.networth.find((item) => item.interval === 'Current');

        if (currentNetworth) {
          setNetworthValue(currentNetworth.networth);
        }
      }

      setLoading(false);
    };

    fetchChartData();
  }, []);

  useEffect(() => {
    const fetchNetworthHistory = async () => {
      const params: Record<string, boolean | string> = {};

      if (interval === EIntervalOption.ALL) {
        params.lastHistory = true;
      } else {
        params.date = getComparisonDate(interval).toISOString();
      }

      setLoading(true);
      const { data, error }: { data: INetworthHistory; error: any } = await getNetworthHistory(params);

      if (!error && data) {
        setNetworthHistory(data);
      }
      setLoading(false);
    };

    fetchNetworthHistory();
  }, [interval, networthValue]);

  return { loading, networthValue, networthHistory, dailyChartData, monthlyChartData, currencyOption };
};

export default useDashboardChart;
