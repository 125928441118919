import React from 'react';

import { events } from '@mm/data/event-list';
import GALink from 'common/components/ga-link';
import { isCurrent } from 'common/interval-parser';
import MeasureIcon from 'assets/images/networth/measure.svg';
import { useNetworthState } from 'networth/networth.context';
import { Placeholder } from 'networth/views/inc/placeholder';
import { StripeSubscriptionStatus } from 'setting/setting.enum';
import NetworthBarGraph from 'networth/views/networth-bar-graph';
import { fNumber, numberWithCommas } from 'common/number.helper';
import useNetWorthSimilar from 'networth/hooks/useNetWorthSimilar';
import useCurrentSubscription from 'auth/hooks/useCurrentSubscription';

interface INetworthCharBarSection {
  currencySymbol: string;
}

const NetworthCharBarSection: React.FC<INetworthCharBarSection> = ({ currencySymbol }) => {
  const { netWorthSimilar } = useNetWorthSimilar();
  const { accountCount, networth, fCategories } = useNetworthState();
  const { currentSubscription } = useCurrentSubscription();
  const isFreeUser =
    currentSubscription?.subscriptionStatus !== StripeSubscriptionStatus.TRIALING &&
    currentSubscription?.subscriptionStatus !== StripeSubscriptionStatus.ACTIVE;

  if (!networth?.length) {
    return <Placeholder type='chart' />;
  }

  const [curNetworthItem] = networth.filter((networthItem) => isCurrent(networthItem.interval));
  const currentNetworth = curNetworthItem?.networth || 0;
  const currentOtherAssets = curNetworthItem?.otherAssets || 0;
  const currentLiabilities = curNetworthItem?.liabilities || 0;
  const currentInvestmentAsset = curNetworthItem?.investmentAssets || 0;

  return (
    <div className='row mb-40'>
      <div className='col-lg-9 mob-btm'>
        <div className='ct-box'>
          <div className='graphbox'>
            <span className='title'>
              <img src={MeasureIcon} alt='Measure UP' className='m-r-2' />
              Your Portfolio
            </span>
            <ul>
              {(fCategories.length === 0 || fCategories.length === 3) && (
                <li className='nw-data'>
                  <span className='graphbox-label'>Net Worth</span>
                  <span className='graphbox-amount'>
                    {currencySymbol}
                    {numberWithCommas(fNumber(currentNetworth, 0))}
                  </span>
                </li>
              )}
              {(fCategories.length === 0 || fCategories.includes('Investment Assets')) && (
                <li className='inv-data'>
                  <span className='graphbox-label'>Investment Assets</span>
                  <span className='graphbox-amount'>
                    {currencySymbol}
                    {numberWithCommas(fNumber(currentInvestmentAsset, 0))}
                  </span>
                </li>
              )}
              {(fCategories.length === 0 || fCategories.includes('Other Assets')) && (
                <li className='other-data'>
                  <span className='graphbox-label'>Other Assets</span>
                  <span className='graphbox-amount'>
                    {currencySymbol}
                    {numberWithCommas(fNumber(currentOtherAssets, 0))}
                  </span>
                </li>
              )}
              {(fCategories.length === 0 || fCategories.includes('Liabilities')) && (
                <li className='lty-data'>
                  <span className='graphbox-label'>Liabilities</span>
                  <span className='graphbox-amount'>
                    {currencySymbol}
                    {numberWithCommas(fNumber(currentLiabilities, 0))}
                  </span>
                </li>
              )}
            </ul>
            <div className='chartbox hide-sm'>
              <NetworthBarGraph networth={networth} fCategories={fCategories} currencySymbol={currencySymbol} />
            </div>
          </div>
        </div>
      </div>

      <div className='col-lg-3 mob-btm'>
        <div className='ct-box measure'>
          {isFreeUser ? (
            <div className='measure-box padd-20'>
              <span className='title'>
                <img src={MeasureIcon} alt='Measure UP' />
                How You Compare
              </span>
              <div className='measure-box-locked'>
                <p>Upgrade to a pro account to unlock Net Worth comparison.</p>
                <GALink
                  to='/settings?active=Plan'
                  className='mm-btn-animate mm-btn-primary'
                  eventArgs={events.upgradeFromNWSimilar}
                >
                  Upgrade
                </GALink>
              </div>
            </div>
          ) : accountCount > 2 ? (
            <>
              <div className='measure-box padd-20'>
                <span className='title'>
                  <img src={MeasureIcon} alt='Measure UP' />
                  How You Compare
                </span>
                <ul>
                  <li className='nw-data'>
                    <span className='measure-label'>Net Worth</span>
                    <span className='measure-amount'>
                      {currencySymbol}
                      {numberWithCommas(fNumber(netWorthSimilar?.networth, 0))}
                    </span>
                  </li>
                  <li className='inv-data'>
                    <span className='measure-label'>Investment Assets</span>
                    <span className='measure-amount'>
                      {currencySymbol}
                      {numberWithCommas(fNumber(netWorthSimilar?.investmentAssets, 0))}
                    </span>
                  </li>
                  <li className='other-data'>
                    <span className='measure-label'>Other Assets</span>
                    <span className='measure-amount'>
                      {currencySymbol}
                      {numberWithCommas(fNumber(netWorthSimilar?.otherAssets, 0))}
                    </span>
                  </li>
                  <li className='lty-data'>
                    <span className='measure-label'>Liabilities</span>
                    <span className='measure-amount'>
                      {currencySymbol}
                      {numberWithCommas(fNumber(netWorthSimilar?.liabilities, 0))}
                    </span>
                  </li>
                </ul>
              </div>
              <GALink
                to='/settings?active=Profile'
                className='mm-btn-animate mm-btn-primary improve-comparison'
                eventArgs={events.completeProfile}
              >
                Improve Comparison
              </GALink>
            </>
          ) : (
            <div className='measure-box padd-20'>
              <span className='title'>
                <img src={MeasureIcon} alt='Measure UP' />
                How You Compare
              </span>
              <div className='measure-box-locked'>
                <p>Net Worth comparison unlocks when you add 3 accounts.</p>
                <GALink
                  to='/connect-account'
                  className='mm-btn-animate mm-btn-primary'
                  eventArgs={events.connectFromNWSimilar}
                >
                  Add Accounts
                </GALink>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NetworthCharBarSection;
