import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { useAuthState } from 'auth/auth.context';
import WebsiteLayout from 'website/website.layout';
import EFCapital from 'assets/images/discover/empire-flippers.png';
import { ReactComponent as StarIcon } from 'assets/icons/discover/star-icon.svg';

import DiscoverLayout from './discover.layout';

function DiscoverDetail() {
  const { isAuthenticated } = useAuthState();

  return isAuthenticated ? (
    <DiscoverLayout>
      <DiscoverContainer isAuthenticated={isAuthenticated} />
    </DiscoverLayout>
  ) : (
    <WebsiteLayout isSignupToday={false}>
      <DiscoverContainer isAuthenticated={isAuthenticated} />
    </WebsiteLayout>
  );
}

function DiscoverContainer({ isAuthenticated }: Record<string, boolean>) {

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Discover Detail | Money Minx</title>
          <meta name='description' content='Discover, discuss and profit from new investment opportunities.' />
          {/*Facebook tags*/}
          <meta property='og:title' content='Discover Investment Opportunities | Money Minx' />
          <meta property='og:description' content='Discover, discuss and profit from new investment opportunities.' />
          <meta property='og:url' content='https://www.moneyminx.com/discover' />
          <meta property='og:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Discover.png' />
          <meta property='og:image:secure_url' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Discover.png' />
          <meta property='og:image:alt' content='Discover, discuss and profit from new investment opportunities.' />
          {/*Twitter tags*/}
          <meta property='twitter:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Discover.png' />
          <meta property='twitter:image:alt' content='Discover, discuss and profit from new investment opportunities.' />
        </Helmet>
      </HelmetProvider>
      <div className='content-wrapper'>
        <div className='container'>
          <div className='discover-heading'>
            <h2>Discover investment opportunities</h2>
            <h3>Find your next investment</h3>
          </div>
          <div className='discover-list m-b-10'>
            <div className='discover-card'>
              <div className='text-center'>
                <img src={EFCapital} className='discover-logo' alt='Empire Flippers Capital'/>
              </div>
              <div className='discover-company-name'>
                <div>
                  <h3 className='discover-name'>Empire Flippers Capital</h3>
                  {/*Coming soon*/}
                  {/*<ShareIcon className='m-l-4' />
                  <FaveIcon className='m-l-4' />*/}
                </div>
                <div>
                  <span className='discover-type m-r-4'>Business</span>
                  <StarIcon className='discover-rating'/>
                  <StarIcon className='discover-rating'/>
                  <StarIcon className='discover-rating'/>
                  <StarIcon className='discover-rating'/>
                  <StarIcon className='discover-rating'/>
                  {/*<StarIcon className='discover-rating discover-star-off'/>*/}
                </div>
                <span className='discover-description'>
                Empire Flippers Capital allows you to invest in seasoned online business operators.
                With the raised capital, the operator will purchase 1 or more online businesses, grow them and sell them.
              </span>
              </div>
              <ul className='discover-card-metrics'>
                <li>
                  <span className='discover-metric-label'>Minimum</span>
                  <span className='discover-metric'>$10,000</span>
                </li>
                <li>
                  <span className='discover-metric-label'>Returns</span>
                  <span className='discover-metric'>9% - 12%</span>
                </li>
              </ul>
              <ul className='discover-card-metrics'>
                <li>
                  <span className='discover-metric-label'>Fee</span>
                  <span className='discover-metric'>10%</span>
                </li>
                <li>
                  <span className='discover-metric-label'>Term</span>
                  <span className='discover-metric'>1 - 3 years</span>
                </li>
              </ul>
              <div className='discover-card-classification-section'>
                <div className='discover-card-classification'>
                  <span className='discover-classification blue'>Income</span>
                  <span className='discover-classification red'>Accredited Only</span>
                  <a href='https://empireflippers.com/capital/?ref=moneyminx.com' className='mm-btn-animate mm-btn-primary' target='_blank'  rel='noopener noreferrer'>Visit Site</a>
                  <a href='https://www.moneyminx.com/blog/empire-flippers-capital-review/' className='btn-outline-primary mm-btn-animate' target='_blank'  rel='noopener noreferrer'>Read our Review</a>
                </div>
              </div>
            </div>

          <div className='discover-details-container'>
            <div className='discover-gallery-main'>
              <iframe width='560' height='315' src='https://www.youtube.com/embed/MRegc94ND74'
                      title='YouTube video player' frameBorder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen/>
            </div>
            <h1>Empire Flippers Capital</h1>

          </div>



          </div>
        </div>
      </div>
    </div>
  );
}

export default DiscoverDetail;
