export function getObjectURL(url: string) {
  return fetch(url)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      console.log(blob);

      return URL.createObjectURL(blob);
    })
    .catch((err) => {
      console.error(err);

      return '';
    });
}
