import React from 'react';
import Skeleton from 'react-loading-skeleton';

const AccountTypesLoading: React.FC = () => {
  return (
    <div className='content-wrapper'>
      <div className='container'>
        <div className='mb-15'>
          <Skeleton width={380} height={50} />
        </div>
      </div>
      <hr className='m-0 mb-3' />
      <div className='container mt-1'>
        <div className='mb-4'>
          <Skeleton width={420} height={50} />
        </div>
      </div>

      <div className='content-wrapper'>
        <div className='container'>
          <div className='mb-4'>
            <Skeleton height={380} />
          </div>

          <div className='mb-3'>
            <Skeleton height={50} width={380} />
          </div>

          <Skeleton height={380} />
        </div>
      </div>
    </div>
  );
};

export default AccountTypesLoading;
