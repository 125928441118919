import { useHistory, useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import queryString from 'query-string';
import AppHeader from 'common/app.header';
import AppSidebar from 'common/app.sidebar';
import ICONS from 'common/components/icons';
import useToast from 'common/hooks/useToast';
import { events } from '@mm/data/event-list';
import { STATUS_CODE } from 'app/app.status';
import { getFastlink } from 'api/request.api';
import { EProviderType } from 'auth/auth.types';
import useAccounts from 'auth/hooks/useAccounts';
import { AuthLayout } from 'layouts/auth.layout';
import { GistEvents } from '@mm/data/gist-events';
import LoadingScreen from 'common/loading-screen';
import FastLinkModal from 'yodlee/fast-link.modal';
import { useModal } from 'common/components/modal';
import BBBImg from 'assets/images/home/acc_bus.png';
import useAnalytics from 'common/hooks/useAnalytics';
import useGistEvents from 'common/hooks/useGistEvents';
import { getRefreshedAccount } from 'auth/auth.service';
import { FastLinkOptionsType } from 'yodlee/yodlee.type';
import { appRouteConstants } from 'app/app-route.constant';
import UpgradeAccountModal from 'common/upgrade-account.modal';
import { useAuthDispatch } from 'auth/auth.context';
import { accountConnectionCard } from 'auth/data/account-card-data.json';
import { ReactComponent as SSLSecure } from 'assets/icons/ssl-secure.svg';
import { ReactComponent as LoginLockIcon } from 'assets/images/login/lock-icon.svg';
import { ReactComponent as NortonImg } from 'assets/images/account/norton_black.svg';
import { ReactComponent as ManualAccountImg } from 'assets/icons/mm-default-provider.svg';
import { ReactComponent as RightArrow } from 'assets/icons/connect-account/right-arrow.svg';
import { ReactComponent as TrustPilotImg } from 'assets/images/account/trustpilot_black.svg';
import { ReactComponent as ConnectCryptoImg } from 'assets/icons/connect-account/connect-crypto.svg';

import ManualAccountModal from './inc/manual-account.modal';
import AccountConnectionCard from './inc/account-connection-card';
import CryptoConnectionModal from './inc/crypto-connection.modal';
import ConnectSaltEdgeWorld from './inc/account-connection-salt-edge-world';

const ConnectAccount = () => {
  const [openRightNav, setOpenRightNav] = useState<boolean>(false);
  const [openLeftNav, setOpenLeftNav] = useState<boolean>(false);

  const closeRightNav = () => {
    setOpenRightNav(false);
  };

  return (
    <AuthLayout>
      <AppHeader
        toggleLeftMenu={() => setOpenLeftNav(!openLeftNav)}
        toggleRightMenu={() => setOpenRightNav(!openRightNav)}
        open={openRightNav}
        shadow={true}
      />
      <AppSidebar openLeft={openLeftNav} openRight={openRightNav} />
      <div className='mm-slider-bg-overlay' onClick={closeRightNav} role='button' />
      <ConnectAccountMainSection />
    </AuthLayout>
  );
};
export default ConnectAccount;

export const ConnectAccountMainSection = () => {
  const location = useLocation();
  const { mmToast } = useToast();
  const { event } = useAnalytics();
  const manualAccountModal = useModal();
  const [fastLinkOptions, setFastLinkOptions] = useState<FastLinkOptionsType>({
    fastLinkURL: '',
    token: { tokenType: 'AccessToken', tokenValue: '' },
    config: { flow: '', configName: 'Aggregation', providerAccountId: 0 },
  });
  const gistEvents = useGistEvents();
  const dispatch = useAuthDispatch();
  const upgradeAccountModal = useModal();
  const [loading, setLoading] = useState(false);

  /*TODO remove me since not in use*/
  const [manualMax] = useState<boolean>(false);
  const [autoLoading] = useState<boolean>(false);

  /*TODO remove me since not in use*/
  const [availableNumber] = useState<number>(0);
  const [manualLoading] = useState<boolean>(false);
  const [zaboLoading] = useState<boolean>(false);
  const { loading: accountFetching, fetchLatestProviderAccounts, fetchAccounts } = useAccounts();

  const history = useHistory();
  const fastlinkModal = useModal();
  const cryptoConnectionModal = useModal();
  const parsedSearch = queryString.parse(location.search);
  const isFromSaltEdge = 'saltedge' === (parsedSearch.from as string)?.toLowerCase();
  const saltEdgeProvider = parsedSearch.apiType as EProviderType;
  const saltEdgeConnectionId = parsedSearch.connection_id;

  const isFromQroka = 'qrokaCoinbase' === (parsedSearch.from as string);
  const qrokaProvider = parsedSearch.provider as EProviderType;

  const memoizedHandleConnectAccountSuccess = useCallback((provider: EProviderType) => {
    handleConnectAccountSuccess(provider);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isFromSaltEdge && saltEdgeProvider && saltEdgeConnectionId) {
      memoizedHandleConnectAccountSuccess(saltEdgeProvider);
    }
  }, [isFromSaltEdge, saltEdgeProvider, saltEdgeConnectionId, memoizedHandleConnectAccountSuccess]);

  useEffect(() => {
    if (isFromQroka && qrokaProvider) {
      memoizedHandleConnectAccountSuccess(qrokaProvider);
    }
  }, [memoizedHandleConnectAccountSuccess, isFromQroka, qrokaProvider]);

  const handleConnectAccount = async () => {
    const { data, error } = await getFastlink();

    if (error) {
      return mmToast('Error Occurred to Get Fastlink', { type: 'error' });
    }

    const fLinkOptions: FastLinkOptionsType = {
      fastLinkURL: data.fastLinkUrl,
      token: data.accessToken,
      config: data.params,
    };

    setFastLinkOptions(fLinkOptions);

    event(events.connectAccount);

    return fastlinkModal.open();
  };

  const checkConnectedAccountLimit = async () => {
    return handleConnectAccount();
  };

  const checkManualAccountLimit = async () => {
    return handleManualAccount();
  };

  const handleManualAccount = () => {
    event(events.manualConnectAccount);

    return manualAccountModal.open();
  };

  const handleCryptoExchange = () => {
    cryptoConnectionModal.open();
  };

  const handleManualAccountSuccess = async () => {
    setLoading(true);
    await fetchLatestProviderAccounts();
    setLoading(false);

    gistEvents.track(GistEvents.ADDED_ACCOUNT, {
      provider: EProviderType.MANUAL,
    });

    location.pathname = appRouteConstants.dashboard.DASHBOARD;
    location.search = 'from=accountSettings';

    return history.push(location);
  };

  const handleConnectAccountSuccess = async (provider: EProviderType) => {
    setLoading(true);
    const { error, data } = await getRefreshedAccount({ dispatch, provider });

    if (STATUS_CODE.SERVER_ACCEPTED === error?.code) {
      await fetchAccounts();

      gistEvents.track(GistEvents.ADDED_ACCOUNT, {
        mmAccountProvider: provider,
      });

      setLoading(false);

      return history.push(appRouteConstants.dashboard.DASHBOARD);
    }

    await fetchLatestProviderAccounts();

    if (data) {
      setLoading(false);
    }

    if (error) {
      mmToast('Error Occurred on Fetching user Details', { type: 'error' });
    }

    gistEvents.track(GistEvents.ADDED_ACCOUNT, {
      mmAccountProvider: provider,
    });

    location.pathname = appRouteConstants.dashboard.DASHBOARD;
    location.search = `from=accountSettings`;

    return history.push(location);
  };

  if (loading || accountFetching) {
    return <LoadingScreen onAccountFetching />;
  }

  return (
    <div className='add-accounts-wrapper'>
      <div className='card'>
        <div className='card-body d-flex'>
          <div className='connect-options'>
            <div className='connected-accounts'>
              <div>
                <h2>Connected Accounts</h2>
                <p className='description'>
                  Connected accounts are through our integration partners. We never handle or see your login details.
                </p>
              </div>
              <button className='card-button m-r-4' type='submit' onClick={checkConnectedAccountLimit}>
                <div className='add-account-card green'>
                  <div className='card-body'>
                    {autoLoading ? (
                      <div className='loading'>
                        <span className='spinner-grow' role='status' aria-hidden='true' />
                      </div>
                    ) : (
                      <>
                        <div className='card-top'>
                          <ICONS.CONNECT_US />
                          <span className='title'>US, Canada and Others</span>
                          <RightArrow className='arrow' />
                        </div>
                        <div>
                          <p className='description'>Majority of US and Canadian institutions, some international.</p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </button>
              <AccountConnectionCard
                cardInfo={accountConnectionCard.saltEdge}
                icon={<ICONS.CONNECT_UKDE />}
                handleSuccess={handleConnectAccountSuccess}
              />
              {/*Crypto*/}
              <button className='card-button m-r-4' type='submit' onClick={handleCryptoExchange}>
                <div className='add-account-card pink'>
                  <div className='card-body'>
                    {zaboLoading ? (
                      <div className='loading'>
                        <span className='spinner-grow' role='status' aria-hidden='true' />
                      </div>
                    ) : (
                      <>
                        <div className='card-top'>
                          <ConnectCryptoImg />
                          <span className='title'>Crypto and Alts</span>
                          <RightArrow className='arrow' />
                        </div>
                        <div>
                          <p className='description'>Crypto wallets or address and Alts like Vinovest.</p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </button>

              {/*Rest of the World*/}
              <ConnectSaltEdgeWorld
                cardInfo={accountConnectionCard.saltEdgeWorld}
                icon={<ICONS.CONNECT_WORLD />}
                handleSuccess={handleConnectAccountSuccess}
              />
            </div>
            <div className='manual-accounts'>
              <div>
                <h2>Manual Accounts</h2>
                <p className='description'>
                  No logins required, update balances, transactions, and holdings - we do the rest.
                </p>
              </div>
              <button className='card-button m-r-4' type='submit' onClick={checkManualAccountLimit}>
                <div className='add-account-card blue'>
                  <div className='card-body'>
                    {manualLoading ? (
                      <div className='loading'>
                        <span className='spinner-grow' role='status' aria-hidden='true' />
                      </div>
                    ) : (
                      <>
                        <div className='card-top'>
                          <ManualAccountImg />
                          <span className='title'>Manual Accounts</span>
                          <RightArrow className='arrow' />
                        </div>
                        <div>
                          <p className='description'>No logins needed, add balances and transactions on your own.</p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className='card-bottom'>
          <div className='trust-badges'>
            <p>
              <span className='locked-icon hide-sm'>
                <LoginLockIcon />
              </span>
              <span className='security-link'>
                The security of your information is our top priority (
                <a className='purple-links' href='/security' target='_blank'>
                  learn more
                </a>
                )
              </span>
            </p>
            <SSLSecure className='m-r-4' />
            <a
              className='m-r-4'
              href='https://www.trustpilot.com/review/www.moneyminx.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              <TrustPilotImg />
            </a>
            <NortonImg className='m-r-4' />
            <img height='38' src={BBBImg} alt='Money Minx Acc Bus' />
          </div>
        </div>
      </div>

      <ManualAccountModal manualAccountModal={manualAccountModal} handleSuccess={handleManualAccountSuccess} />

      {fastlinkModal.props.open ? (
        <FastLinkModal
          fastLinkModal={fastlinkModal}
          fastLinkOptions={fastLinkOptions}
          handleSuccess={handleConnectAccountSuccess}
          handleOpenNewConnection={handleConnectAccount}
        />
      ) : null}

      {cryptoConnectionModal.props.open ? (
        <CryptoConnectionModal
          cryptoConnectionModal={cryptoConnectionModal}
          handleSuccess={handleConnectAccountSuccess}
        />
      ) : null}

      {(availableNumber || manualMax) && (
        <UpgradeAccountModal
          upgradeAccountModal={upgradeAccountModal}
          availableNumber={availableNumber}
          manualMax={manualMax}
        />
      )}
    </div>
  );
};
