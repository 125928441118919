import { logger } from 'common/logger.helper';

export const initProfitWell = (email: string) => {
  const isLoaded = window.profitwell?.isLoaded;

  if (isLoaded) {
    logger.log('Initialize profitwell account');

    window.profitwell?.('start', { user_email: email });
  }
};
