import React from 'react';

import env from 'app/app.env';
import WalletLink from 'walletlink';

const QROKA_APP_NAME = env.QROKA_APP_NAME;
const ETH_JSONRPC_URL = env.ETH_JSONRPC_URL;
const APP_LOGO_URL = 'https://moneyminx-files.s3.amazonaws.com/logos/coinbase-wallet.png';

const CHAIN_ID = 1;

export const walletLink = new WalletLink({
  appName: QROKA_APP_NAME,
  appLogoUrl: APP_LOGO_URL,
  darkMode: false,
});

const ethereum = walletLink.makeWeb3Provider(ETH_JSONRPC_URL, CHAIN_ID);

const useCoinbaseWallet = () => {
  const [error, setError] = React.useState<string>('');
  const [accounts, setAccounts] = React.useState<string[]>([]);

  const onConnect = () => {
    if (!ethereum) {
      return setError('WalletLink not found!!');
    }
    setError('');

    try {
      ethereum
        .send('eth_requestAccounts')
        .then((accList) => {
          setAccounts(accList);
        })
        .catch((err) => {
          setError(err.message || 'User denied account authorization');
        });
    } catch (error: any) {
      setError(error?.message || 'Error on getting accounts from WalletLink');
    }
  };

  const disconnect = () => {
    walletLink.disconnect();
  };

  return { ethereum, onConnect, accounts, disconnect, error };
};

export default useCoinbaseWallet;
