import React from 'react';

interface ICheckbox {
  name: string;
  label: string;
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const Checkbox: React.FC<ICheckbox> = (props) => {
  return (
    <div className='checkbox-wrapper'>
      <label className='custom-checkbox'>
        <input
          type='checkbox'
          name={props.name}
          value={props.name}
          checked={props.checked}
          aria-checked={props.checked}
          aria-describedby={props.name}
          onChange={props.onChange}
        />
        <span className='checkmark' />
      </label>
      <span className='checkbox-text'>{props.label}</span>
    </div>
  );
};

export default Checkbox;
