import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { logger } from 'common/logger.helper';
import useInterval from 'common/hooks/useInterval';
import { getNetworthByType } from 'api/request.api';
import { EWebSocketEvent } from 'common/common.types';
import { useNetworthState } from 'networth/networth.context';
import { IAccount, INetworthByType } from 'networth/networth.type';

import useSocket from './useSocket';
import useNetworthByType from './useNetworthByType';

interface IUpdatingAccountType {
  accountId: number;
  groupName: string;
  connectionStatus: string;
}

const useNetworthSocket = () => {
  const socket = useSocket();
  const { groupByCategory } = useNetworthState();
  const [accounts, setAccounts] = useState<IAccount[]>([]);
  const { loadingByType, groups, refresh } = useNetworthByType(groupByCategory);

  const previousAccounts = useRef<string>('');
  const updatingAccountTypes = useRef<IUpdatingAccountType[]>([]);
  const syncCompleted = useRef(false);

  const memoizedGroups = useMemo(() => groups, [groups]);
  const memoizedGroupAccounts = useMemo(() => {
    setAccounts([...(memoizedGroups?.map((g) => g.accounts).flat() || [])]);

    return [...(memoizedGroups?.map((group) => group.accounts).flat() || [])];
  }, [memoizedGroups]);

  const updateAccount = ({ accountId, connectionStatus }: Record<any, any>) => {
    const groupName = memoizedGroupAccounts.find((account) => +account.accountId === +accountId)?.accountType;

    if (!updatingAccountTypes.current) {
      updatingAccountTypes.current = [];
    }

    if (!groupName) {
      return;
    }

    const newAccount: IUpdatingAccountType = {
      accountId,
      connectionStatus,
      groupName,
    };

    updatingAccountTypes.current = [...updatingAccountTypes.current, newAccount];
  };

  const updateAccountCallback = useCallback(updateAccount, [memoizedGroupAccounts]);

  const updateGroupsOnInterval = async () => {
    if (syncCompleted.current) {
      return logger.log(`Sync Completed`);
    }

    const accountIds = updatingAccountTypes.current?.map((account) => account.accountId) || [];
    const tempUpdatingAccountTypes = updatingAccountTypes.current;
    const stringifiedAccountIds = accountIds.toString();

    const getFilteredAccounts = () => {
      return updatingAccountTypes.current.filter((account) => !accountIds.includes(account.accountId));
    };

    const getAccountStatus = (id: number) => {
      return tempUpdatingAccountTypes.find((account) => account.accountId === id)?.connectionStatus || '';
    };

    if (!stringifiedAccountIds) {
      return logger.log('Account id not found!!');
    }

    if (previousAccounts.current === stringifiedAccountIds) {
      updatingAccountTypes.current = getFilteredAccounts();

      previousAccounts.current = '';

      return logger.log(`Same number of payload =>`, { stringifiedAccountIds });
    }

    previousAccounts.current = stringifiedAccountIds;

    updatingAccountTypes.current = getFilteredAccounts();

    const { data, error }: { data: INetworthByType; error: any } = await getNetworthByType({
      accountId: stringifiedAccountIds,
    });

    if (!error && data) {
      const apiAccounts = data.groups.map((group) => group.accounts).flat();
      const updatingAccounts = accounts.map((account) => {
        const apiAccount = apiAccounts.find((a) => a.accountId === account.accountId);

        if (apiAccount) {
          return {
            ...account,
            ...apiAccount,
            connectionStatus: getAccountStatus(account.accountId),
          };
        }

        return account;
      });

      setAccounts(updatingAccounts);
    }
  };

  useInterval(updateGroupsOnInterval, [memoizedGroups]);

  const refreshCallback = useRef(() => {
    syncCompleted.current = true;

    refresh();
  });

  useEffect(() => {
    socket?.on(EWebSocketEvent.SYNC_COMPLETE, refreshCallback.current);
    socket?.on(EWebSocketEvent.ACCOUNT_STATUS_UPDATE, updateAccountCallback);
  }, [socket, updateAccountCallback]);

  return { loadingByType, groups, accounts };
};

export default useNetworthSocket;
