import React from 'react';
import { useHistory } from 'react-router-dom';

import useProfile from 'auth/hooks/useProfile';
import { useAuthState } from 'auth/auth.context';
import { useAlert } from 'common/components/alert';
import { useModal } from 'common/components/modal';
import useSettings from 'setting/hooks/useSettings';
import useNetworth from 'networth/hooks/useNetworth';
import useAnalytics from 'common/hooks/useAnalytics';
import useSearchParam from 'auth/hooks/useSearchParam';
import useInitialModal from 'auth/hooks/useInitialModal';
import { getCurrencySymbol } from 'common/currency-helper';
import { base64Encode } from 'common/helper/string.helper';
import { CurrencySymbols } from 'auth/enum/currency-options';
import { useNetworthState } from 'networth/networth.context';
import useNetworthSocket from 'networth/hooks/useNetworthSocket';

import NetworthSection from './networth-section';
import LiabilitiesSection from './liability-section';
import OtherAssetSection from './other-assets-section';
import NetworthByTypeSection from './networth-by-type';
import NetworthChartBarSection from './networth-bar-section';
import NetworthSkeleton from '../views/inc/networth-skeleton';
import InvestmentAssetSection from './investment-asset-section';
import AccountWithFirstLoadIssues from './account-with-first-load-issues';

const NetworthMainSection = () => {
  useProfile();
  const history = useHistory();
  const { data } = useSettings();
  const { event } = useAnalytics();
  const connectionAlert = useAlert();
  const signupDoneModal = useModal();
  const accountAddedModal = useModal();
  const { onboarded } = useAuthState();

  const { accounts, networth, groupByCategory } = useNetworthState();
  const { loading } = useNetworth(groupByCategory);

  const { groups, loadingByType, accounts: accountsByType } = useNetworthSocket();

  const from = useSearchParam('from');
  const isFromConnectAccount = from === 'accountSettings';
  const isSignupModal = !isFromConnectAccount && from === 'accountSettings' && onboarded !== undefined && !onboarded;

  useInitialModal(true, connectionAlert);
  useInitialModal(isFromConnectAccount, accountAddedModal);
  useInitialModal(isSignupModal, signupDoneModal);

  const currencySymbol = getCurrencySymbol(data?.currency || CurrencySymbols.USD);

  const hasAccountOrGroup = accounts || groups;

  if (!networth?.length || !hasAccountOrGroup || loading || loadingByType) {
    return <NetworthSkeleton />;
  }

  const handleAccountDetail = (accountId: number) => {
    const encodedAccountId = base64Encode(`${accountId}`);

    event({
      category: 'Net Worth',
      action: 'Clicked on Account',
      label: `Accessed account ${accountId} with encoded id ${encodedAccountId}`,
    });

    return history.push(`/account-details/${encodedAccountId}`);
  };

  return (
    <div>
      <NetworthChartBarSection currencySymbol={currencySymbol} />
      <AccountWithFirstLoadIssues />
      <InvestmentAssetSection currencySymbol={currencySymbol} handleAccountClick={handleAccountDetail} />
      <OtherAssetSection currencySymbol={currencySymbol} handleAccountClick={handleAccountDetail} />
      <LiabilitiesSection currencySymbol={currencySymbol} handleAccountClick={handleAccountDetail} />
      <NetworthByTypeSection
        groups={groups}
        accounts={accountsByType}
        currencySymbol={currencySymbol}
        handleAccountClick={handleAccountDetail}
      />
      <NetworthSection currencySymbol={currencySymbol} />
    </div>
  );
};

export default NetworthMainSection;
