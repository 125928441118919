import debounce from 'lodash/debounce';
import { Range } from 'react-input-range';
import Skeleton from 'react-loading-skeleton';
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useCallback, ChangeEvent } from 'react';

import {
  EInvestorType,
  EOpportunityRisk,
  EReturnFrequency,
  EReturnType,
  EDiscoverSortKeys,
} from 'discover/discover.enum';
import {
  useDiscoverFilterState,
  useDiscoverFilterDispatch,
  initialDiscoverFilterState,
} from 'discover/context/discover-filter.context';
import {
  setType,
  setRisk,
  setMinimum,
  setSortField,
  setLiquidity,
  setReturnType,
  setInvestorType,
  setTargetReturns,
  setReturnFrequency,
  clearDiscoverFilters,
} from 'discover/context/discover-filter.actions';
import { useModal } from 'common/components/modal';
import { LiquidityEnum } from 'networth/networth.enum';
import { camelCase } from 'common/helper/string.helper';
import FilterModal from 'networth/views/inc/filter-modal';
import { getCurrencySymbol } from 'common/currency-helper';
import { TDiscoverFilterKey } from 'discover/discover.types';
import { CurrencyOptions } from 'auth/enum/currency-options';
import { serialize, enumerateStr } from 'common/common-helper';
import { useDiscoverDispatch } from 'discover/discover.context';
import useDiscoverFilter from 'discover/hooks/useDiscoverFilter';
import { fNumber, numberWithCommas } from 'common/number.helper';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import RangeSelector from 'common/components/input/range-selector.input';
import { setSearch, updateDiscoverStateValues } from 'discover/discover.actions';
import { ReactComponent as DiscoverFilterIcon } from 'assets/icons/discover/discover-filter.svg';

const DiscoverTopSection: React.FC = () => {
  const filterModal = useModal();
  const currencySymbol = getCurrencySymbol(CurrencyOptions.USD);

  const dispatch = useDiscoverDispatch();
  const discoverFilterState = useDiscoverFilterState();
  const discoverFilterDispatch = useDiscoverFilterDispatch();

  const { types, risk, returnTypes, returnFrequencies, liquidity, investorType, minimum, targetReturns, sortField } =
    discoverFilterState;

  const {
    searchTerm,
    setSearchTerm,
    accountTypes,
    minimumRange,
    setMinimumRange,
    targetReturnsRange,
    setTargetReturnsRange,
  } = useDiscoverFilter(minimum, targetReturns);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((searchTerm: string) => {
      dispatch(setSearch(searchTerm));
    }, 500),
    [dispatch]
  );

  // Change Handlers
  const handleSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;

    setSearchTerm(text);
    debouncedSearch(text);
  };

  const handleFilterChange = (fn: VoidFunction) => {
    fn();

    dispatch(
      updateDiscoverStateValues({
        opportunities: [],
        pagination: {
          loadMore: true,
          nextId: undefined,
          limit: 10,
          hasMoreData: true,
        },
      })
    );
  };

  const handleAccountTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleFilterChange(() => discoverFilterDispatch(setType(e.target.value)));
  };

  const handleRiskChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleFilterChange(() => discoverFilterDispatch(setRisk(e.target.value)));
  };

  const handleSortFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleFilterChange(() => discoverFilterDispatch(setSortField(e.target.value)));
  };

  const handleReturnTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleFilterChange(() => discoverFilterDispatch(setReturnType(e.target.value)));
  };

  const handleReturnFrequencyChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleFilterChange(() => discoverFilterDispatch(setReturnFrequency(e.target.value)));
  };

  const handleLiquidityChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleFilterChange(() => discoverFilterDispatch(setLiquidity(e.target.value)));
  };

  const handleInvestorTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleFilterChange(() => discoverFilterDispatch(setInvestorType(e.target.value)));
  };

  const handleMinimumChange = (range: number | Range) => {
    if (typeof range !== 'number') {
      handleFilterChange(() => discoverFilterDispatch(setMinimum(range)));
    }
  };

  const handleTargetReturnsChange = (range: number | Range) => {
    if (typeof range !== 'number') {
      handleFilterChange(() => discoverFilterDispatch(setTargetReturns(range)));
    }
  };

  const handleClearFilter = () => {
    handleFilterChange(() => discoverFilterDispatch(clearDiscoverFilters()));
  };

  // Helpers
  const currencyFormatter = (value: number) => {
    return `${currencySymbol}${numberWithCommas(fNumber(value, 0))}`;
  };

  const percentageFormatter = (value: number) => {
    return `${value} %`;
  };

  const isFiltered = (key: TDiscoverFilterKey) => {
    if (serialize(discoverFilterState[key] as any) !== serialize(initialDiscoverFilterState[key] as any)) {
      return true;
    }

    return false;
  };

  const fc = (key: TDiscoverFilterKey) => (isFiltered(key) ? 'filtered' : '');

  const hasFiltered = () => {
    const filterKeys: TDiscoverFilterKey[] = [
      'risk',
      'types',
      'minimum',
      'liquidity',
      'sortField',
      'returnTypes',
      'investorType',
      'targetReturns',
      'returnFrequencies',
    ];

    return filterKeys.some((key) => isFiltered(key));
  };

  const renderSearch = () => {
    return (
      <div className='discover-search mb-2'>
        <SearchIcon />

        <input
          type='text'
          placeholder='Find your next investment'
          value={searchTerm}
          onChange={handleSearchTermChange}
        />
      </div>
    );
  };

  const renderDesktopFilters = () => {
    return (
      <div className='dflex-center justify-space-between flex-wrap-custom desktop-filters'>
        <Dropdown className='drop-box mb-2'>
          <Dropdown.Toggle variant='' className={fc('types')}>
            All Types
          </Dropdown.Toggle>
          <Dropdown.Menu className='mm-dropdown-menu'>
            <ul className='checkbox-list account-types-dropdown'>
              {accountTypes.map((accountType) => {
                return (
                  <li key={accountType}>
                    <label>
                      <input
                        name='types'
                        type='checkbox'
                        aria-describedby={accountType}
                        value={accountType}
                        aria-checked={!!types?.includes(accountType)}
                        checked={types?.includes(accountType)}
                        onChange={handleAccountTypeChange}
                      />
                      <span className='pl-3'>{accountType}</span>
                    </label>
                  </li>
                );
              })}
            </ul>
          </Dropdown.Menu>
        </Dropdown>

        <RangeSelector
          label='Minimum'
          labelClass={fc('minimum')}
          minValue={0}
          maxValue={100000}
          value={minimumRange}
          onChange={(value) => {
            if (typeof value !== 'number') {
              setMinimumRange(value);
            }
          }}
          onChangeComplete={handleMinimumChange}
          valueFormatter={currencyFormatter}
          formatLabel={currencyFormatter}
        />

        <Dropdown className='drop-box mb-2'>
          <Dropdown.Toggle variant='' className={fc('sortField')}>
            Sort By
          </Dropdown.Toggle>
          <Dropdown.Menu className='mm-dropdown-menu'>
            <ul className='radiolist radio--scroll'>
              {enumerateStr(EDiscoverSortKeys).map((discoverSortKey) => {
                return (
                  <li key={discoverSortKey}>
                    <label>
                      <input
                        type='radio'
                        name='sort-field'
                        aria-checked={sortField === camelCase(discoverSortKey)}
                        value={camelCase(discoverSortKey)}
                        checked={sortField === camelCase(discoverSortKey)}
                        onChange={handleSortFieldChange}
                      />
                      <span>{discoverSortKey}</span>
                    </label>
                  </li>
                );
              })}
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  const renderModalFilters = () => {
    return (
      <div className='dropdowns-container'>
        {renderDesktopFilters()}
        <div className='dflex-center justify-space-between flex-wrap-custom mb-4 pt-4'>
          <Dropdown className='drop-box mb-2'>
            <Dropdown.Toggle variant='' className={fc('risk')}>
              {risk || 'Risk'}
            </Dropdown.Toggle>
            <Dropdown.Menu className='mm-dropdown-menu dropsm'>
              <ul className='radiolist'>
                {enumerateStr(EOpportunityRisk).map((opportunityRisk) => {
                  return (
                    <li key={opportunityRisk}>
                      <label>
                        <input
                          type='radio'
                          name='opportunity-risk'
                          aria-checked={risk === opportunityRisk}
                          value={opportunityRisk}
                          checked={risk === opportunityRisk}
                          onChange={handleRiskChange}
                        />
                        <span>{opportunityRisk}</span>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </Dropdown.Menu>
          </Dropdown>

          <RangeSelector
            label='Target Returns'
            labelClass={fc('targetReturns')}
            minValue={0}
            maxValue={50}
            value={targetReturnsRange}
            onChange={(value) => {
              if (typeof value !== 'number') {
                setTargetReturnsRange(value);
              }
            }}
            onChangeComplete={handleTargetReturnsChange}
            valueFormatter={percentageFormatter}
            formatLabel={percentageFormatter}
          />

          <Dropdown className='drop-box mb-2'>
            <Dropdown.Toggle variant='' className={fc('returnTypes')}>
              Return Type
            </Dropdown.Toggle>
            <Dropdown.Menu className='mm-dropdown-menu'>
              <ul className='checkbox-list'>
                {enumerateStr(EReturnType).map((returnType) => {
                  return (
                    <li key={returnType}>
                      <label>
                        <input
                          name='return-type'
                          type='checkbox'
                          aria-describedby={returnType}
                          value={returnType}
                          aria-checked={!!returnTypes?.includes(returnType)}
                          checked={returnTypes?.includes(returnType)}
                          onChange={handleReturnTypeChange}
                        />
                        <span className='pl-3'>{returnType}</span>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className='drop-box mb-2'>
            <Dropdown.Toggle variant='' className={fc('returnFrequencies')}>
              Return Frequency
            </Dropdown.Toggle>
            <Dropdown.Menu className='mm-dropdown-menu'>
              <ul className='checkbox-list'>
                {enumerateStr(EReturnFrequency).map((returnFrequency) => {
                  return (
                    <li key={returnFrequency}>
                      <label>
                        <input
                          name='return-frequency'
                          type='checkbox'
                          aria-describedby={returnFrequency}
                          value={returnFrequency}
                          aria-checked={!!returnFrequencies?.includes(returnFrequency)}
                          checked={returnFrequencies?.includes(returnFrequency)}
                          onChange={handleReturnFrequencyChange}
                        />
                        <span className='pl-3'>{returnFrequency}</span>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className='drop-box mb-2'>
            <Dropdown.Toggle variant='' className={fc('liquidity')}>
              All Liquidity
            </Dropdown.Toggle>
            <Dropdown.Menu className='mm-dropdown-menu'>
              <ul className='checkbox-list'>
                {enumerateStr(LiquidityEnum).map((liquidityItem) => {
                  return (
                    <li key={liquidityItem}>
                      <label>
                        <input
                          name='liquidity'
                          type='checkbox'
                          aria-describedby={liquidityItem}
                          value={liquidityItem}
                          aria-checked={!!liquidity?.includes(liquidityItem)}
                          checked={liquidity?.includes(liquidityItem)}
                          onChange={handleLiquidityChange}
                        />
                        <span className='pl-3'>{liquidityItem}</span>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className='drop-box'>
            <Dropdown.Toggle variant='' className={fc('investorType')}>
              {investorType}
            </Dropdown.Toggle>
            <Dropdown.Menu className='mm-dropdown-menu dropsm'>
              <ul className='radiolist'>
                {enumerateStr(EInvestorType).map((investorTypeItem) => {
                  return (
                    <li key={investorTypeItem}>
                      <label>
                        <input
                          type='radio'
                          name='investor-type'
                          aria-checked={investorType === investorTypeItem}
                          value={investorTypeItem}
                          checked={investorType === investorTypeItem}
                          onChange={handleInvestorTypeChange}
                        />
                        <span>{investorTypeItem}</span>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  };

  if (!accountTypes.length) {
    return (
      <div className='row'>
        <div className='col-12 dropdowns-container'>
          <div className='dflex-center mb-15'>
            <Skeleton width={265} height={50} count={3} inline />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='discover-top-section'>
      <div className='discover__heading mb-4'>
        <div>
          <h2>Discover investment opportunities</h2>
          <span>Find your next investment</span>
        </div>
      </div>

      <div className='row filter__row'>
        {renderSearch()}

        {hasFiltered() ? (
          <button className='btn btn-outline-danger clear-filter clear-filter__desktop' onClick={handleClearFilter}>
            Clear Filters
          </button>
        ) : null}

        <button type='button' className='discover__filter-btn mb-2 d-md-none' onClick={filterModal.open}>
          <DiscoverFilterIcon />
        </button>

        {renderDesktopFilters()}

        <button
          className='btn btn-outline-primary btn-more-filter ml-md-3 d-none d-md-block'
          onClick={filterModal.open}
        >
          More Filters +
        </button>
      </div>

      <FilterModal filterModal={filterModal} clearFilterModal={handleClearFilter}>
        {renderModalFilters()}
      </FilterModal>
    </div>
  );
};

export default DiscoverTopSection;
