import React, { useRef } from 'react';

import useIntersectionObserver from 'common/hooks/useIntersectionObserver';

interface ILazyLoadProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  placeholderElement?: React.ReactNode;
  rootElement?: Element | Document | null;
}

const LazyLoad: React.FC<ILazyLoadProps> = ({ placeholderElement, rootElement, children, ...rest }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, { root: rootElement, freezeOnceVisible: true });

  const isVisible = !!entry?.isIntersecting;

  return (
    <div ref={ref} {...rest}>
      {isVisible ? children : !!placeholderElement ? placeholderElement : null}
    </div>
  );
};

export default LazyLoad;
