export enum HoldingsModalTabs {
  DETAILS = 'details',
  CLASSIFICATIONS = 'classifications',
  MONTHLYVALUES = 'monthlyValues',
  DELETE = 'delete',
}

export enum AddAccountScreenEnum {
  CHOOSE_ACCOUNT = 'CHOOSE_ACCOUNT',
  MANUAL_ACCOUNTS = 'MANUAL_ACCOUNTS',
  SEPARATE_HOLDINGS = 'SEPARATE_HOLDINGS',
  CONNECTED_ACCOUNTS = 'CONNECTED_ACCOUNTS',
  LEARN_ABOUT_ACCOUNTS = 'LEARN_ABOUT_ACCOUNTS',
  MANUAL_ACCOUNT_HOLDINGS = 'MANUAL_ACCOUNT_HOLDINGS',
  EXISTING_CRYPTO_ACCOUNTS = 'EXISTING_CRYPTO_ACCOUNTS',
  EXISTING_METALS_ACCOUNTS = 'EXISTING_METALS_ACCOUNTS',
}
