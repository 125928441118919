import { IFilterState } from 'filter/filter.type';
import { getDateString, getUTC } from 'common/moment.helper';

import { IFilter, NetworthState } from './networth.type';

export const listingFilters = (filter?: string) => {
  return filter ? filter.split(',') : [];
};

export const listingNumericFilters = (filter?: string) => {
  return filter ? filter.split(',').map((filterItem) => +filterItem) : [];
};

export const stringifyArray = <D>(arr: D[]) => {
  return arr.length ? arr.toString() : undefined;
};

export const getDatePayload = (dateString?: string) => {
  return dateString ? getUTC(dateString) : undefined;
};

export const makeFilterPayload = (filter: IFilter, state: IFilterState): IFilter => {
  const { fCategories, fAccounts, fTypes, fTimeInterval, fEntities, fLiquidity, fFromDate, fToDate } = state;

  return {
    screen: 'net-worth',
    id: filter.id || undefined,
    filterName: filter.filterName || '',
    default: filter.default || false,
    category: stringifyArray(fCategories),
    accountId: stringifyArray(fAccounts),
    accountType: stringifyArray(fTypes),
    timeInterval: fTimeInterval || undefined,
    investingEntityId: stringifyArray(fEntities),
    liquidity: stringifyArray(fLiquidity),
    fromDate: getDatePayload(fFromDate),
    toDate: getDatePayload(fToDate),
  };
};

export const makeNetworthFilters = (filter: IFilter) => {
  const filterObj: Partial<NetworthState> = {
    fCategories: listingFilters(filter.category),
    fTypes: listingFilters(filter.accountType),
    fEntities: listingNumericFilters(filter.investingEntityId),
    fLiquidity: listingFilters(filter.liquidity),
    fAccounts: listingNumericFilters(filter.accountId),
    fFromDate: filter.fromDate ? getDateString(filter.fromDate) : undefined,
    fToDate: filter.toDate ? getDateString(filter.toDate) : undefined,
    fTimeInterval: filter.timeInterval,
  };

  return filterObj;
};
