import React from 'react';
import { Table } from 'react-bootstrap';

import { gc } from 'common/interval-parser';
import classNames from 'common/classes.helper';
import SortIcon from 'networth/components/sort-icon';
import { AccountHoldingItem } from 'account/account.type';
import useSortableData from 'common/hooks/useSortableData';
import { fNumber, numberWithCommas } from 'common/number.helper';
import holdingTableField from 'account/helper/holding-table.helper';

interface IHoldingsTableProps {
  currencySymbol: string;
  holdings: any[];
  mmAccountType: string;
}

const HoldingsTable: React.FC<IHoldingsTableProps> = ({ currencySymbol, holdings, mmAccountType }) => {
  const holdingTable = holdingTableField(mmAccountType);

  const { items: sortedHoldings, sortConfig, requestSort } = useSortableData(holdings || []);

  const renderSortableHeader = (sortKey: keyof AccountHoldingItem, label: string, cn = 'hide-type', show = true) => {
    if (show) {
      return (
        <th className={classNames(cn, 'table-header__item')} onClick={() => requestSort({ key: sortKey })}>
          {label}
          <SortIcon sortConfig={sortConfig} sortKey={sortKey} />
        </th>
      );
    }

    return null;
  };

  const renderHoldings = () => {
    if (!holdings.length) {
      return <span className='no-data'>No holdings found</span>;
    }

    return (
      <div className='ct-box'>
        <div className='table-holder sticky-table-holder'>
          <Table className='tb-responsive account balance-table table-account-xls' id='table-net-xls'>
            <thead>
              <tr>
                {holdingTable.get().map((formField, index) => {
                  const fieldName = formField.name as keyof AccountHoldingItem;

                  return (
                    <React.Fragment key={formField.name}>
                      {renderSortableHeader(fieldName, formField.label, index === 0 ? 's-hide' : 'hide-type')}
                    </React.Fragment>
                  );
                })}

                {holdings[0]?.intervalValues.map((intervalItem: any) => (
                  <th
                    key={intervalItem.interval}
                    className={classNames(gc(intervalItem.interval), 'table-header__item')}
                    onClick={() => requestSort({ key: intervalItem.interval, isDetails: true })}
                  >
                    {intervalItem.interval}
                    <SortIcon sortConfig={sortConfig} sortKey={intervalItem.interval} />
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {sortedHoldings.length > 0 &&
                sortedHoldings.map((item, index) => (
                  <tr key={index}>
                    {holdingTable.get()?.map((formField, idx) => {
                      const value = item[formField.name as keyof AccountHoldingItem];
                      const isDescription = formField.name === 'description';

                      const rowKey = formField.name + index + idx;
                      if (idx === 0) {
                        return (
                          <td className={classNames(!isDescription && 'small')} key={rowKey}>
                            <span>
                              {!!item.logo && (
                                <img src={item.logo} alt={item.description} className='gecko-coin__logo' />
                              )}
                              {value}
                            </span>
                          </td>
                        );
                      }

                      return (
                        <td key={rowKey}>
                          <span>{formField.label}</span>
                          {holdingTable.formatValue(formField.name, value, currencySymbol)}
                          {holdingTable.isMetalWeight(formField.name) ? ` ${item.unit}` : null}
                        </td>
                      );
                    })}

                    {item.intervalValues.map((ins: any, i: number) => (
                      <td
                        key={i}
                        className={[ins.type === `projection` && `projection holdings-table`, gc(ins.interval)].join(
                          ' '
                        )}
                      >
                        <span className={gc(ins.interval)}>{ins.interval}</span>
                        {ins.value || ins.value === 0 ? currencySymbol : ''}
                        {ins.value || ins.value === 0 ? numberWithCommas(fNumber(ins.value, 2)) : '--'}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  };

  return <section>{renderHoldings()}</section>;
};

export default HoldingsTable;
