import React from 'react';
import { useHistory } from 'react-router-dom';

import { Modal, useModal } from 'common/components/modal';
import { appRouteConstants } from 'app/app-route.constant';
import useGetSubscription from 'auth/hooks/useGetSubscription';
import useCurrentSubscription from 'auth/hooks/useCurrentSubscription';

const StripeUpdatePaymentSuccess: React.FC = () => {
  const history = useHistory();

  const updatePaymentMethodModal = useModal(true);

  const { currentSubscription } = useCurrentSubscription();
  useGetSubscription(currentSubscription?.priceId);

  const handleSuccess = () => {
    return history.push(appRouteConstants.dashboard.DASHBOARD);
  };

  return (
    <Modal
      {...updatePaymentMethodModal.props}
      title='Payment Method Updated!'
      size='md'
      canBeClosed
      onClose={handleSuccess}
    >
      <div className='modal-wrapper signup-modal'>
        <p>Your payment method was successfully updated! Thank you for your continue support.</p>
        <div className='modal-btn-wrapper'>
          <button className='mm-btn-animate mm-btn-primary' onClick={handleSuccess}>
            Onwards!
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default StripeUpdatePaymentSuccess;
