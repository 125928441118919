import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';

import moment from 'moment';
import { Formik } from 'formik';
import { logger } from 'common/logger.helper';
import { IHoldingPayload } from 'account/holding.type';
import { Modal, ModalType } from 'common/components/modal';
import { filterObjectPayload } from 'common/object.helper';
import { patchPosition, postPosition } from 'api/request.api';
import useHoldingDetail from 'account/hooks/useHoldingDetail';
import vestingScheduleList from 'account/data/vesting-schedule';
import { EMMHoldingType } from 'account/enum/holding-type.enum';
import addHoldingFields from 'account/helper/add-holding.helper';
import { InvestmentRound, InvestmentType } from 'auth/auth.enum';
import { enumerateNameValue, isExist } from 'common/common-helper';
import CircularSpinner from 'common/components/spinner/circular-spinner';
import { INameValue, MMSelectInput } from 'common/components/input/select.input';
import { getDateWithoutTimezone, getISOString, getUTCDateString, getUTCString } from 'common/moment.helper';

import { addHoldingValidation } from '../account.validation';
import AddEditHoldingModalSkeleton from './add-edit-holding-modal-skeleton';
import { dateFields, numericalFields, selectFields, financialFields } from '../data/add-holding-fields.json';

interface IAddEditHoldingModal {
  accountType?: string;
  accountId?: number;
  currencySymbol: string;
  addEditModal: ModalType;
  handleRefresh: () => void;
  positionId?: number;
}

type TInitialValue = Partial<IHoldingPayload> & { schedule: string; quantity?: number };

const AddEditHoldingModal: React.FC<IAddEditHoldingModal> = ({
  accountType,
  addEditModal,
  handleRefresh,
  accountId,
  currencySymbol,
  positionId,
}) => {
  const [loading, setLoading] = useState(false);
  const { loading: fetchingHoldingDetail, holdingDetail } = useHoldingDetail(positionId);

  const isManual = holdingDetail?.mmHoldingType === EMMHoldingType.MANUAL;
  const isYodleeCash = holdingDetail?.mmHoldingType === EMMHoldingType.YODLEE_CASH;

  const getSchedule = () => {
    if (
      isExist(holdingDetail?.vestingYears) &&
      isExist(holdingDetail?.vestingSchedule) &&
      isExist(holdingDetail?.vestingCliffYear)
    ) {
      return `${holdingDetail?.vestingYears},${holdingDetail?.vestingSchedule},${holdingDetail?.vestingCliffYear}`;
    }

    return '';
  };

  const getQuantity = () => {
    return (
      holdingDetail?.intervalValues.find((intervalValue) => intervalValue.interval === 'Current')?.quantity ?? undefined
    );
  };

  const initialValues: TInitialValue = {
    symbol: holdingDetail?.symbol,
    description: holdingDetail?.description,
    costBasis: holdingDetail?.costBasis,
    grantDate: holdingDetail?.grantDate ? getUTCString(holdingDetail.grantDate) : undefined,
    strikePrice: holdingDetail?.strikePrice,
    investmentRound: holdingDetail?.investmentRound,
    investmentType: holdingDetail?.investmentType,
    cap: holdingDetail?.cap,
    postMoneyValuation: holdingDetail?.postMoneyValuation,
    sharesGranted: holdingDetail?.sharesGranted,
    vestedQuantity: holdingDetail?.vestedQuantity,
    vestingSchedule: holdingDetail?.vestingSchedule,
    vestingCliffYear: holdingDetail?.vestingCliffYear,
    vestingYears: holdingDetail?.vestingYears,
    schedule: getSchedule(),
    quantity: getQuantity(),
    accountId,
  };

  const holdingFields = addHoldingFields(accountType);

  const fields = holdingFields.get();

  if (!fields) {
    return <CircularSpinner />;
  }

  const getSelectData = (key: string) => {
    const selectFieldData: Record<string, INameValue[]> = {
      schedule: vestingScheduleList,
      investmentRound: enumerateNameValue(InvestmentRound),
      investmentType: enumerateNameValue(InvestmentType),
    };

    return selectFieldData[key] || [];
  };

  const getAction = (isButton = false): string => {
    if (!accountType) {
      return '';
    }

    const editPrefix = isButton ? 'Update ' : 'Edit ';
    const holdingAction = holdingFields.getHoldingAction();

    if (!holdingDetail?.id) {
      return 'Add ' + holdingAction;
    }

    return editPrefix + holdingAction;
  };

  const renderModalContent = () => {
    if (fetchingHoldingDetail) {
      return <AddEditHoldingModalSkeleton />;
    }

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={addHoldingValidation}
        onSubmit={async (values) => {
          const [vestingYears, vestingSchedule, vestingCliffYear] = values.schedule.split(',');

          const dateFields = ['grantDate'];

          const data: Record<string, any> = {
            ...values,
            vestingYears: isExist(vestingYears) ? +vestingYears : undefined,
            vestingSchedule: isExist(vestingSchedule) ? vestingSchedule : undefined,
            vestingCliffYear: isExist(vestingCliffYear) ? +vestingCliffYear : undefined,
          };

          if (values.quantity && !positionId) {
            data.values = [
              {
                date: getISOString(),
                quantity: +values.quantity,
                value: 0,
              },
            ];
          }

          Object.entries(data).forEach(([key, value]) => {
            const isDateField = dateFields.find((item) => item === key);

            if (!!isDateField && !!value) {
              data[key] = getUTCDateString(new Date(value));
            }
          });

          if (positionId) {
            const patchResponse = await patchPosition(positionId.toString(), filterObjectPayload(data));

            if (patchResponse.error) {
              setLoading(false);

              return logger.gp('Patch position error', { error: patchResponse.error });
            }

            setLoading(false);
            handleRefresh();

            return addEditModal.close();
          }

          const postPositionResponse = await postPosition(filterObjectPayload(data));

          if (postPositionResponse.error) {
            setLoading(false);

            return logger.gp('Add  position error', { error: postPositionResponse.error });
          }

          setLoading(false);
          handleRefresh();

          return addEditModal.close();
        }}
      >
        {(props) => {
          const { values, handleChange, setFieldValue, setValues, errors, touched } = props;

          const handleSelectChange = (e: React.ChangeEvent<any>) => {
            setValues({ ...values, [e.target.name]: e.target.value });
          };

          const isReadOnly = (field: string) => {
            if (isYodleeCash) {
              return true;
            }

            if (field !== 'costBasis' && !isManual && positionId) {
              return true;
            }

            return false;
          };

          const renderFormFields = () => {
            return fields.map((field, idx) => {
              if (selectFields.includes(field.name)) {
                return (
                  <SelectInput
                    key={idx}
                    label={field.label}
                    name={field.name}
                    isReadOnly={isReadOnly(field.name)}
                    data={getSelectData(field.name)}
                    handleChange={handleSelectChange}
                    value={values[field.name as keyof TInitialValue] ?? ''}
                  />
                );
              }

              if (financialFields.includes(field.name)) {
                if (field.name === 'cap' && values.investmentType !== InvestmentType.CONVERTIBLE_NOTE) {
                  return null;
                }

                if (field.name === 'postMoneyValuation' && values.investmentType !== InvestmentType.PRICED_ROUND) {
                  return null;
                }

                return (
                  <FinancialInput
                    key={idx}
                    name={field.name}
                    label={field.label}
                    handleChange={handleChange}
                    currencySymbol={currencySymbol}
                    value={values[field.name as keyof TInitialValue] ?? ''}
                    isReadOnly={isReadOnly(field.name)}
                  />
                );
              }

              if (numericalFields.includes(field.name)) {
                return (
                  <NumberInput
                    isReadOnly={isReadOnly(field.name)}
                    name={field.name}
                    label={field.label}
                    value={values[field.name as keyof TInitialValue] ?? ''}
                    handleChange={handleChange}
                    key={idx}
                  />
                );
              }

              if (dateFields.includes(field.name)) {
                return (
                  <DateInput
                    key={idx}
                    name={field.name}
                    label={field.label}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    isReadOnly={isReadOnly(field.name)}
                    value={values[field.name as keyof TInitialValue] ?? ''}
                  />
                );
              }

              return (
                <>
                  <TextInput
                    key={idx}
                    name={field.name}
                    label={field.label}
                    handleChange={handleChange}
                    isReadOnly={isReadOnly(field.name)}
                    value={values[field.name as keyof TInitialValue] ?? ''}
                    error={
                      touched[field.name as keyof TInitialValue] ? errors[field.name as keyof TInitialValue] : undefined
                    }
                  />
                </>
              );
            });
          };

          return (
            <div className='holding-modal'>
              <form onSubmit={props.handleSubmit} onReset={props.handleReset}>
                <div className='holding-modal__body'>{renderFormFields()}</div>
                <div className='holding-modal__action'>
                  <button
                    type='button'
                    className='btn-outline-primary mm-btn-animate mr-md-4 mb-2 mb-md-0'
                    onClick={addEditModal.close}
                  >
                    Cancel
                  </button>
                  <button type='submit' className='mm-btn-animate mm-btn-primary ' disabled={isYodleeCash}>
                    {loading ? (
                      <>
                        <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
                        <span className='ml-1'>Saving...</span>
                      </>
                    ) : (
                      <>{getAction(true)}</>
                    )}
                  </button>
                </div>
              </form>
            </div>
          );
        }}
      </Formik>
    );
  };

  return (
    <Modal {...addEditModal.props} title={getAction()} size='xl' canBeClosed onClose={addEditModal.close}>
      <div className='add-edit-holding-modal'>
        {!isManual && positionId ? (
          <div className='sub-title-wrapper'>
            <span className='sub-title'>You can only update the cost of connected accounts.</span>
          </div>
        ) : null}
        {renderModalContent()}
      </div>
    </Modal>
  );
};

export default AddEditHoldingModal;

interface IInput {
  name: string;
  label: string;
  error?: string;
  value: any;
  isReadOnly?: boolean;
  handleChange: (e: React.ChangeEvent<any>) => void;
}

interface ISetFieldValue {
  setFieldValue: (key: string, value: any) => void;
}

interface ISelectInput extends IInput {
  data: INameValue[];
}

interface IFinancialInput extends IInput {
  currencySymbol: string;
}

export const NumberInput: React.FC<IInput> = ({ name, label, value, handleChange, isReadOnly = false }) => {
  return (
    <FormGroup>
      <label className='form-subheading'>{label}</label>
      <FormControl
        name={name}
        step='any'
        type='number'
        value={value}
        onChange={handleChange}
        readOnly={isReadOnly}
        title={isReadOnly ? 'Read only field' : ''}
      />
    </FormGroup>
  );
};

export const DateInput: React.FC<IInput & ISetFieldValue> = ({
  name,
  label,
  value,
  setFieldValue,
  isReadOnly = false,
}) => {
  return (
    <FormGroup>
      <label className='form-subheading'>{label}</label>
      <ReactDatePicker
        name={name}
        selected={value ? getDateWithoutTimezone(value) : null}
        onChange={(val: Date) => {
          setFieldValue(name, moment(val).toISOString());
        }}
        readOnly={isReadOnly}
        title={isReadOnly ? 'Read only' : ''}
      />
    </FormGroup>
  );
};

export const TextInput: React.FC<IInput> = ({ name, label, value, handleChange, isReadOnly = false, error }) => {
  return (
    <FormGroup>
      <label className='form-subheading'>{label}</label>
      <FormControl
        type='text'
        name={name}
        value={value}
        onChange={handleChange}
        readOnly={isReadOnly}
        title={isReadOnly ? 'Read only field' : ''}
      />
      {error && <div className='mt-2 feedback'>{error}</div>}
    </FormGroup>
  );
};

export const SelectInput: React.FC<ISelectInput> = ({ name, label, value, data, handleChange, isReadOnly = false }) => {
  const displayValue = data.find((datum) => datum.value === value)?.name ?? '';

  return (
    <FormGroup>
      <label className='form-subheading'>{label}</label>
      <MMSelectInput
        name={name}
        title={displayValue}
        data={data}
        onChange={handleChange}
        value={value ?? ''}
        readOnly={isReadOnly}
      />
    </FormGroup>
  );
};

export const FinancialInput: React.FC<IFinancialInput> = ({
  name,
  label,
  value,
  handleChange,
  currencySymbol,
  isReadOnly = false,
}) => {
  return (
    <FormGroup className='financial-input-group'>
      <label className='form-subheading'>{label}</label>
      <FormControl
        type='number'
        name={name}
        value={value}
        step='any'
        onChange={handleChange}
        readOnly={isReadOnly}
        title={isReadOnly ? 'Read Only field' : ''}
      />
      <span className='input-add-on'>{currencySymbol || '$'}</span>
    </FormGroup>
  );
};
