import { storage } from 'app/app.storage';
import { ApiResponse } from 'app/app.types';
import * as publicClient from 'common/public.api';
import { EmailSubscriptionPayload } from 'setting/setting.type';
import { RegisterPayload, ResetPasswordPayload, VerifyResetPasswordTokenPayload } from 'auth/auth.types';

import { urls } from './api.url';
import * as http from './http.api';
import { ISaltEdgeReconnectParam, ISaltEdgeRefreshParam, IGetEstimatedMonthlyPaymentParams } from './api.types';

export type TApiResponse = Promise<ApiResponse>;

export const postLogin = (payload: { email: string; password: string }): TApiResponse => {
  return publicClient.post(urls.auth.LOGIN_IN, payload);
};

export const postFacebookLogin = (payload: {
  accessToken: string;
  mailChimpSubscription: boolean;
  subscriptionPriceId: string;
}): TApiResponse => {
  return publicClient.post(`${urls.auth.FACEBOOK_LOGIN}?access_token=${payload.accessToken}`, {
    subscriptionPriceId: payload.subscriptionPriceId,
    mailChimpSubscription: payload.mailChimpSubscription,
  });
};

export const postRegister = (payload: RegisterPayload) => {
  return publicClient.post(urls.auth.REGISTER, payload);
};

export const postFacebookAssociation = (token: string) => {
  return publicClient.post(urls.auth.ASSOCIATE_LOGIN, {}, { access_token: token });
};

export const postForgotPassword = (email: string): TApiResponse => {
  return publicClient.post(urls.auth.FORGOT_PASSWORD, { email });
};

export const postResetPassword = (payload: ResetPasswordPayload): TApiResponse => {
  return publicClient.post(urls.auth.RESET_PASSWORD, payload);
};

export const checkResetPasswordToken = (payload: VerifyResetPasswordTokenPayload): TApiResponse => {
  return publicClient.post(urls.auth.VERIFY_RESET_PASSWORD_TOKEN, payload);
};

export const patchChangePassword = <T>(payload: T): TApiResponse => {
  return http.patch(urls.auth.UPDATE_PASSWORD, payload);
};

export const getSubscription = <P>(params?: P): TApiResponse => {
  return publicClient.get(urls.subscription.SUB, params);
};

export const postSubscriptionCheckout = <T>(payload: T): TApiResponse => {
  return http.post(urls.subscription.STRIPE_CHECKOUT, payload);
};

export const postUpdateStripePaymentMethod = (): TApiResponse => {
  return http.post(urls.subscription.UPDATE_PAYMENT_METHOD, {});
};

export const getCurrentSubscription = (): TApiResponse => {
  return http.get(urls.subscription.CURRENT_SUB);
};

export const patchCancelSubscription = (): TApiResponse => {
  return http.patch(urls.subscription.CANCEL, {});
};

export const getFastlink = () => {
  return http.get(urls.yodlee.FAST_LINK);
};

export const getFastlinkUpdate = (accountId: number, update: boolean, refresh: boolean) => {
  return http.get(
    urls.yodlee.FAST_LINK_UPDATE.replace(':accountId', accountId.toString())
      .replace(':update', update.toString())
      .replace(':refresh', refresh.toString())
  );
};

export const getAllAccounts = (): TApiResponse => {
  return http.get(urls.auth.IGNORED_ACCOUNTS);
};

export const getAccountRefresh = <P>(params?: P) => {
  return http.get(urls.auth.ACCOUNT_REFRESH, params);
};

export const getConnectionInfo = () => {
  return http.get(urls.auth.ACCOUNT_CONNECTION_INFO);
};

export const getAccountsCount = () => {
  return http.get(urls.auth.ACCOUNTS_COUNT);
};

export const getAccount = (): TApiResponse => {
  return http.get(urls.auth.ACCOUNTS);
};

export const getAccountWithProvider = (): TApiResponse => {
  return http.get(urls.auth.ACCOUNTS_WITH_PROVIDER);
};

export const getProfile = () => {
  return http.get(urls.auth.PROFILE);
};

export const getAccountCategory = (): TApiResponse => {
  return http.get(urls.auth.ACCOUNT_CATEGORY);
};

export const getAccountType = <P>(param?: P): TApiResponse => {
  return publicClient.get(urls.auth.ACCOUNT_TYPE, param);
};

export const getManualAccountType = (): TApiResponse => {
  return http.get(urls.auth.MANUAL_ACCOUNT_TYPE);
};

export const postManualAccount = <T>(payload: T): TApiResponse => {
  return http.post(urls.auth.MANUAL_ACCOUNT, payload);
};

export const getAccountSubType = (accountType: string, isManual: boolean): TApiResponse => {
  let url = urls.auth.ACCOUNT_SUBTYPE.replace(':accountType', accountType);
  url = isManual ? url + '&manual=true' : url;

  return http.get(url);
};

export const getAssociateMortgage = (): TApiResponse => {
  return http.get(urls.auth.ASSOCIATE_MORTGAGE);
};

export const getAssociatedMortgageAccounts = (realEstateAccId: number): TApiResponse => {
  return http.get(urls.auth.ASSOCIATED_MORTGAGE_ACCOUNTS.replace(':realEstateId', realEstateAccId.toString()));
};

export const postAssociateMortgageAccount = (mortgageAccId: number, realEstateAccId: number): TApiResponse => {
  return http.post(`${urls.auth.MORTGAGE_ACCOUNT}/${mortgageAccId}/real-estate/${realEstateAccId}`, {});
};

export const deleteMortageAccountAssociation = (mortgageAccId: number, realEstateAccId: number) => {
  return http.remove(`${urls.auth.MORTGAGE_ACCOUNT}/${mortgageAccId}/real-estate/${realEstateAccId}`);
};

export const getAssociatedRealEstateAccounts = (mortgageAccId: number): TApiResponse => {
  return http.get(urls.auth.ASSOCIATED_REAL_ESTATE_ACCOUNTS.replace(':mortgageAccId', mortgageAccId.toString()));
};

export const getLoanAccounts = (): TApiResponse => {
  return http.get(urls.auth.LOAN_ACCOUNT);
};

export const getFormFieldFilter = (accountType: string, accountSubtype: string): TApiResponse => {
  return http.get(
    urls.auth.FORM_FIELD_FILTER.replace(':accountType', accountType).replace(':accountSubType', accountSubtype)
  );
};

export const refreshAccessToken = ({ referenceToken }: { referenceToken: string }): Promise<any> => {
  return Promise.resolve({ referenceToken });
};

export const getCurrentSettings = () => {
  return http.get(urls.auth.SETTINGS);
};

export const patchEmailSubscription = (payload: EmailSubscriptionPayload): TApiResponse => {
  return http.patch(urls.auth.SETTINGS, payload);
};

export const patchProfilePicture = (payload: any): TApiResponse => {
  return http.patch(urls.auth.PROFILE_PICTURE, payload);
};

export const patchProfile = <T>(payload: T): TApiResponse => {
  return http.patch(urls.auth.PATCH_PROFILE, payload);
};

export const patchAccount = <D>(id: string, data: D) => {
  return http.patch(urls.auth.PATCH_ACCOUNT.replace(':id', id), data);
};

export const getAccountDetails = (id: string, baseCurrency: boolean): TApiResponse => {
  return http.get(urls.auth.ACCOUNT_DETAILS.replace(':id', id).replace(':baseCurrency', baseCurrency.toString()));
};

export const getAccountHoldings = <P>(params?: P) => {
  return http.get(urls.auth.ACCOUNT_HOLDINGS, params);
};

export const getAccountActivity = <P>(params?: P) => {
  return http.get(urls.auth.ACCOUNT_ACTIVITY, params);
};

export const getHoldingsDetails = (id: string) => {
  return http.get(urls.auth.HOLDINGS_DETAILS.replace(':positionId', id));
};

export const getActivityDetails = (id: string) => {
  return http.get(urls.auth.ACTIVITY_DETAILS.replace(':activityId', id));
};

export const getClassification = (filter: string) => {
  return http.get(urls.auth.CLASSIFICATION.replace(':filter', filter));
};

export const getHoldingsAccountsByDescription = (description: string) => {
  return http.get(urls.auth.HOLDINGS_ACCOUNTS_BY_DESCRIPTION.replace(':description', description));
};

export const getClassificationStatus = (accountId: string) => {
  return http.get(urls.account.CLASSIFICATION_STATUS.replace(':accountId', accountId));
};

export const getHoldingTypes = () => {
  return http.get(urls.allocations.HOLDING_TYPES);
};

export const getActivityTypes = () => {
  return http.get(urls.auth.ACTIVITY_TYPES);
};

export const patchPosition = (id: string, data: any) => {
  return http.patch(urls.auth.HOLDINGS_DETAILS.replace(':positionId', id), data);
};

export const postPosition = <T>(payload: T): TApiResponse => {
  return http.post(urls.auth.HOLDINGS_DETAILS.replace('/:positionId', ''), payload);
};

export const patchTransaction = (id: string, data: any) => {
  return http.patch(urls.auth.ACTIVITY_DETAILS.replace(':activityId', id), data);
};

export const postTransaction = <T>(payload: T): TApiResponse => {
  return http.post(urls.auth.ACTIVITY_DETAILS.replace('/:activityId', ''), payload);
};

export const deleteAccount = (id: string): TApiResponse => {
  return http.remove(urls.auth.PATCH_ACCOUNT.replace(':id', id));
};

export const toggleIgnoreAccount = (id: string, isIgnored: boolean): TApiResponse => {
  return http.patch(urls.auth.PATCH_ACCOUNT.replace(':id', id), {
    isIgnored,
  });
};

export const getNetworth = <P>(params?: P): TApiResponse => {
  return http.get(urls.networth.NETWORTH, params);
};

export const getNetworthByType = <P>(params?: P): TApiResponse => {
  return http.get(urls.networth.NETWORTH_BY_TYPE, params);
};

export const getNetWorthSimilar = (): TApiResponse => {
  return http.get(urls.networth.NW_SIMILAR);
};

export const getAllocations = <P>(params?: P): TApiResponse => {
  return http.get(urls.allocations.ALLOCATIONS, params);
};

export const getSimilarAllocations = <P>(params?: P): TApiResponse => {
  return http.get(urls.allocations.SIMILAR_ALLOCATIONS, params);
};

export const getAllocationChartSetting = (): TApiResponse => {
  return http.get(urls.allocations.CHART_SETTINGS);
};

export const patchAllocationChartSettings = <D>(data: D): TApiResponse => {
  return http.patch(urls.allocations.CHART_SETTINGS, data);
};

export const postUploadChart = <D>(data: D): TApiResponse => {
  return http.post(urls.allocations.UPLOAD_CHART, data);
};

export const patchCompleteProfile = (): TApiResponse => {
  return http.patch(urls.auth.COMPLETE_PROFILE);
};

export const getNewAccounts = (): TApiResponse => {
  return http.get(urls.auth.NEW_ACCOUNTS);
};

export const getLatestProviderAccounts = (): TApiResponse => {
  return http.get(urls.auth.LATEST_PROVIDER_ACCOUNTS);
};

export const getRealEstateAccounts = (): TApiResponse => {
  return http.get(urls.auth.REAL_ESTATE_ACCOUNTS);
};

export const getAccountDetailBalances = <P>(params?: P): TApiResponse => {
  return http.get(urls.account.BALANCES, params);
};

export const deleteHoldingByPosition = (positionId: string): TApiResponse => {
  return http.remove(urls.auth.ACCOUNT_HOLDINGS + `/${positionId}`);
};

export const deleteActivityTransaction = (transactionId: string): TApiResponse => {
  return http.remove(urls.auth.ACCOUNT_ACTIVITY + `/${transactionId}`);
};

export const putBalanceAccountDetails = <T>(accountId: string, balances: T): TApiResponse => {
  return http.put(urls.account.BALANCE_ACCOUNT.replace(':accountId', accountId), balances);
};

export const getPeerboardToken = (): TApiResponse => {
  return http.post(urls.auth.PEERBOARD_TOKEN, {});
};

export const postZaboAccount = <T>(payload: T): TApiResponse => {
  return http.post(urls.account.ZABO_ACCOUNT, payload);
};

export const getInvestingEntities = (): TApiResponse => {
  return http.get(urls.investingEntity.INVESTING_ENTITY_LIST);
};

export const postInvestingEntity = <D>(data: D): TApiResponse => {
  return http.post(urls.investingEntity.INVESTING_ENTITY, data);
};

export const patchInvestingEntity = <D>(id: string, data: D) => {
  return http.patch(urls.investingEntity.INVESTING_ENTITY + `/${id}`, data);
};

export const deleteInvestingEntity = (investingEntityId: string): TApiResponse => {
  return http.remove(urls.investingEntity.INVESTING_ENTITY + `/${investingEntityId}`);
};

export const getSaltEdgeConnect = (): TApiResponse => {
  return http.get(urls.saltEdge.CONNECT);
};

export const refreshSaltEdgeAccount = (params: ISaltEdgeRefreshParam): TApiResponse => {
  return http.get(urls.saltEdge.CONNECT, params);
};

export const reconnectSaltEdgeAccount = (params: ISaltEdgeReconnectParam): TApiResponse => {
  return http.get(urls.saltEdge.CONNECT, params);
};

export const getPerformance = <P>(params?: P): TApiResponse => {
  return http.get(urls.performance.PERFORMANCE, params);
};

export const getSaltEdgeConnectWorld = (): TApiResponse => {
  return http.get(urls.saltEdge.CONNECT_WORLD);
};

/**
 * Filter API list
 */

export const postFilter = <D>(data: D): TApiResponse => {
  return http.post(urls.filter.FILTER, data);
};

export const patchFilter = <D>(data: D, filterId: string): TApiResponse => {
  return http.patch(urls.filter.FILTER_BY_ID.replace(':id', filterId), data);
};

export const deleteFilter = (filterId: string): TApiResponse => {
  return http.remove(urls.filter.FILTER_BY_ID.replace(':id', filterId));
};

export const getFilter = (filterId: string): TApiResponse => {
  return http.get(urls.filter.FILTER_BY_ID.replace(':id', filterId));
};

export const getScreenFilter = <P>(param?: P): TApiResponse => {
  return http.get(urls.filter.CURRENT_FILTER, param);
};
export const getAllInsights = (): TApiResponse => {
  return http.get(urls.insights.ALL);
};

export const patchInsightsRead = (): TApiResponse => {
  return http.patch(urls.insights.MARK_READ);
};

export const getZestimateValue = (address: string): TApiResponse => {
  return http.get(urls.zestimate.VALUE + address);
};

export const getCoinGecko = (name: string): TApiResponse => {
  return http.get(urls.coinGecko.GET_NAME + name);
};

export const patchCoinGecko = (accountId: string, payload: any): TApiResponse => {
  return http.patch(urls.coinGecko.PATCH_COIN_GECKO.replace(':accountId', accountId), payload);
};

export const getCoinsForAccount = (accountId: string): TApiResponse => {
  return http.get(urls.coinGecko.GET_COINS_FOR_ACCOUNT.replace(':accountId', accountId));
};

export const deleteGeckoCoinForAccount = (accountId: string, coinGeckoId: string): TApiResponse => {
  return http.remove(
    urls.coinGecko.DELETE_GECKO_COIN.replace(':accountId', accountId).replace(':coinGeckoId', coinGeckoId)
  );
};

export const getPreciousMetals = (): TApiResponse => {
  return http.get(urls.preciousMetals.ALL);
};

export const patchPreciousMetal = (accountId: string, payload: any): TApiResponse => {
  return http.patch(urls.preciousMetals.PATCH_PRECIOUS_METAL.replace(':accountId', accountId), payload);
};

export const getMetalsForAccount = (accountId: string): TApiResponse => {
  return http.get(urls.preciousMetals.GET_METALS_FOR_ACCOUNT.replace(':accountId', accountId));
};

export const deleteMetalForAccount = (accountId: string, metalId: string): TApiResponse => {
  return http.remove(
    urls.preciousMetals.DELETE_PRECIOUS_METAL.replace(':accountId', accountId).replace(':metalId', metalId)
  );
};

export const getValueForMetal = (
  metalSymbol: string,
  quantity: string,
  unit: string,
  karat: string,
  usdRate: string | undefined
) => {
  let apiUrl = urls.preciousMetals.GET_METAL_VALUE.replace(':metalSymbol', metalSymbol)
    .replace(':quantity', quantity)
    .replace(':unit', unit)
    .replace(':karat', karat);

  apiUrl = usdRate ? apiUrl + `&usdRate=${usdRate}` : apiUrl;

  return http.get(apiUrl);
};

export const fetchManualCryptoAccounts = (): TApiResponse => {
  return http.get(urls.account.MANUAL_CRYPTO_ACCOUNTS);
};

export const fetchManualMetalAccounts = (): TApiResponse => {
  return http.get(urls.account.MANUAL_METAL_ACCOUNTS);
};

export const getLeasesForAccount = (accountId: number): TApiResponse => {
  return http.get(urls.lease.ALL.replace(':accountId', accountId.toString()));
};

export const deleteLeaseForAccount = (accountId: number, leaseId: number): TApiResponse => {
  return http.remove(
    urls.lease.DELETE_LEASE.replace(':accountId', accountId.toString()).replace(':leaseId', leaseId.toString())
  );
};

export const postLeaseForAccount = <T>(accountId: number, payload: T): TApiResponse => {
  return http.post(urls.lease.POST_LEASE.replace(':accountId', accountId.toString()), payload);
};

export const patchLeaseForAccount = <T>(accountId: number, leaseId: number, payload: T) => {
  return http.patch(
    urls.lease.PATCH_LEASE.replace(':accountId', accountId.toString()).replace(':leaseId', leaseId.toString()),
    payload
  );
};

export const getEstimatedMonthlyPayment = (params: IGetEstimatedMonthlyPaymentParams): TApiResponse => {
  return http.get(urls.account.MORTGAGE_PAYMENT, params);
};

export const patchAccountBalances = <T>(accountId: number, balances: T): TApiResponse => {
  return http.patch(urls.account.UPDATE_ACCOUNT_BALANCE.replace(':accountId', accountId.toString()), {
    balances,
  });
};

export const patchAccountBalanceRange = <T>(accountId: number, payload: T): TApiResponse => {
  return http.patch(urls.account.UPDATE_BALANCE_RANGE.replace(':accountId', accountId.toString()), payload);
};

export const getDailyBalances = (accountId: number, fromDate: string, toDate: string): TApiResponse => {
  return http.get(
    urls.account.DAILY_BALANCES.replace(':accountId', accountId.toString())
      .replace(':fromDate', fromDate)
      .replace(':toDate', toDate)
  );
};

export const deleteDailyBalance = <T>(accountId: number, payload: T) => {
  return http.clear(urls.account.DELETE_DAILY_BALANCE.replace(':accountId', accountId.toString()), {}, payload);
};

export const postEnableMFA = <D>(mfaPayload?: D): TApiResponse => {
  return http.post(urls.mfa.MFA_ENABLE, mfaPayload);
};

export const patchDisableMFA = (): TApiResponse => {
  return http.patch(urls.mfa.MFA_DISABLE, { mfaEnabled: false });
};

export const postVerifyMFA = (code: number): TApiResponse => {
  return http.post(urls.mfa.MFA_VERIFY, { code });
};

export const postResendMFA = (): TApiResponse => {
  const token = storage.accessToken();

  return http.post(urls.mfa.MFA_RESEND, { token });
};

export const postVerifyAuthMFA = <D>(data: D): TApiResponse => {
  return http.post(urls.mfa.MFA_VERIFY_AUTH, data);
};

export const postUserLog = <D>(data: D) => {
  return http.post(urls.log.USER_LOG, data);
};

export const getReferredUsers = (): TApiResponse => {
  return http.get(urls.refer.REFERRED_USERS);
};

// Qroka API
export const postQrokaAccount = <D>(data: D): TApiResponse => {
  return http.post(urls.qroka.QROKA_ACCOUNT, data);
};

export const getQrokaConnectionURL = <P>(params: P): TApiResponse => {
  return http.get(urls.qroka.QROKA_CONNECTION_URL, params);
};

export const getIncome = <P>(params?: P): TApiResponse => {
  return http.get(urls.income.INCOME, params);
};

export const getDiscoverCompanies = <P>(params?: P): TApiResponse => {
  return publicClient.get(urls.discover.COMPANIES, params);
};

export const getNetworthChart = <P>(params?: P): TApiResponse => {
  return http.get(urls.networth.NETWORH_CHART, params);
};

export const getNetworthHistory = <P>(params?: P): TApiResponse => {
  return http.get(urls.networth.NETWORTH_HISTORY, params);
};

export const getPortfolioMoversAccounts = <P>(params?: P): TApiResponse => {
  return http.get(urls.account.PORTFOLIO_MOVERS_ACCOUNTS, params);
};

export const getPortfolioMoversHoldings = <P>(params?: P): TApiResponse => {
  return http.get(urls.account.PORTFOLIO_MOVERS_HOLDINGS, params);
};

export const getAccountsHistory = <P>(params?: P): TApiResponse => {
  return http.get(urls.account.ACCOUNTS_HISTORY, params);
};

export const getAccountAssets = <P>(params?: P): TApiResponse => {
  return http.get(urls.account.ACCOUNTS_ASSETS, params);
};

export const getAccountAssetsHistory = <P>(params?: P): TApiResponse => {
  return http.get(urls.account.ACCOUNTS_ASSETS_HISTORY, params);
};

export const getAccountLiabilities = <P>(params?: P): TApiResponse => {
  return http.get(urls.account.ACCOUNTS_LIABILITIES, params);
};

export const getAccountLiabilitiesHistory = <P>(params?: P): TApiResponse => {
  return http.get(urls.account.ACCOUNTS_LIABILITIES_HISTORY, params);
};

export const getRecentActivities = <P>(params?: P): TApiResponse => {
  return http.get(urls.account.ACCOUNT_TRANSACTIONS, params);
};

export const getFeaturedPartners = (): TApiResponse => {
  return http.get(urls.featured.FEATURED);
};

export const getAccountTypes = (): TApiResponse => {
  return http.get(urls.account.ACCOUNT_TYPES);
};

export const getValueForAccountType = <P>(params?: P): TApiResponse => {
  return http.get(urls.account.ACCOUNT_DATA_BY_TYPE, params);
};

export const getHoldingsForAccountType = <P>(params?: P): TApiResponse => {
  return http.get(urls.account.ACCOUNT_TYPE_HOLDINGS, params);
};
