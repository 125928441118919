import { useState, useEffect, useCallback } from 'react';

import { Account } from 'auth/auth.types';
import { ILease, IManualMortgageAccount } from 'account/account.type';
import { getAssociateMortgage, getLeasesForAccount, getAssociatedMortgageAccounts } from 'api/request.api';

const useRealEstateDetail = (accountId: number) => {
  const [leases, setLeases] = useState<ILease[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [mortgageAccounts, setMortgageAccounts] = useState<IManualMortgageAccount[]>([]);

  const [associatedMortgageAccounts, setAssociatedMortgageAccounts] = useState<Account[]>([]);

  const fetchAssociatedMortgageAccounts = useCallback(async (accId: number) => {
    const { data: associatedMortgageData } = await getAssociatedMortgageAccounts(accId);

    setAssociatedMortgageAccounts(associatedMortgageData);
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data: mortgageData } = await getAssociateMortgage();
      const { data: leasesData } = await getLeasesForAccount(accountId);

      await fetchAssociatedMortgageAccounts(accountId);

      if (!!mortgageData) {
        setMortgageAccounts(mortgageData);
      }

      setLeases(leasesData);
      setLoading(false);
    })();
  }, [accountId, fetchAssociatedMortgageAccounts]);

  return { loading, leases, mortgageAccounts, associatedMortgageAccounts, fetchAssociatedMortgageAccounts };
};

export default useRealEstateDetail;
