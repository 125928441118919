import { ApiResponse } from 'api/api.types';
import { ModalType } from 'common/components/modal';
import { Account, EProviderType } from 'auth/auth.types';

import { EPortfolioUpdateEmailSettings, StripeSubscriptionStatus } from './setting.enum';

export interface SettingsProps {}

export enum SettingPageEnum {
  SETTINGS = 'Settings',
  PROFILE = 'Profile',
  PLAN = 'Plan',
  ACCOUNTS = 'Accounts',
}

export interface SettingTitleProps {
  pageTitle: SettingPageEnum;
  handlePageSelect: (pageName: SettingPageEnum) => void;
}

export interface IInvestingEntities {
  id: number;
  name: string;
  role: string;
  default: boolean;
  profileId: number;
}

export interface SettingType {
  id: number;
  currency: string;
  mfaEnabled: boolean;
  mailChimpSubscription: boolean;
  investingEntities: IInvestingEntities[];
  portfolioUpdateEmailSettings: EPortfolioUpdateEmailSettings;
  showArchivedAccounts: boolean;
}

export interface EmailSubscriptionPayload {
  mailChimpSubscription: boolean;
  currency: string;
  portfolioUpdateEmailSettings: string;
  showArchivedAccounts: boolean;
}

export interface CurrentSubscription {
  priceId: string;
  customerId: string;
  id: string | number;
  subscriptionId: string;
  cancelAt: number;
  subscriptionEnd: number;
  subscriptionStatus: StripeSubscriptionStatus;
  name: string;
}

export interface AccountCardProps {
  accountList: Account[];
  availableAccounts: string | number;
  reviewSubscriptionFlag?: boolean;
  removeAccount: (accId: number) => void;
  removeAccounts: (accIds: number[]) => void;
  handleRefreshAccount: (provider: EProviderType) => void;
  removeProviderAccounts: (account: Account) => void;
}

export interface ManualAccountProps {
  manualAccountList: Account[];
  availableAccounts: string | number;
  reviewSubscriptionFlag?: boolean;
  removeAccount: (accId: number) => void;
  removeAccounts: (accIds: number[]) => void;
}

export interface AccountRowProps {
  account: Account;
  reviewSubscriptionFlag?: boolean;
  removeAccount: (accId: number) => void;
  removeProviderAccounts?: (account: Account) => void;
}

export interface AccountOverviewProps {
  reviewSubscriptionFlag?: boolean;
}

export interface SubscriptionConnectionWarningProps {
  availableConnectedAccounts: string | number;
  availableManualAccounts: string | number;
}

export interface AccountDialogBoxProps {
  availableConnectedAccounts: string | number;
  availableManualAccounts: string | number;
  manualAccountList: Account[];
  accountList: Account[];
  verifyAccountNumbers: (event: any) => void;
}

export interface Plan {
  active: boolean;
  currency: string;
  details: Record<string, any>;
  duration: string;
  name: string;
  nickname: string;
  price: number;
  priceId: string;
  save?: number;
}

export interface IInvestingEntity {
  id: number;
  name: string;
  role: string;
  default: boolean;
  createdAt: string;
  profileId: number;
  updatedAt: string | null;
}

export type TInvestingEntityPayload = Pick<IInvestingEntity, 'name'>;

export interface IAddEditEntityModal {
  addEditEntityModal: ModalType;
  currentEntity?: IInvestingEntity;
  refresh: () => void;
}

export interface IDeleteEntityModal {
  loading: boolean;
  deleteEntityModal: ModalType;
  currentEntity?: IInvestingEntity;
  removeEntity: (id: string) => Promise<ApiResponse>;
}
