import Form from 'react-bootstrap/Form';
import React, { useState, useEffect } from 'react';

import { Formik } from 'formik';
import debounce from 'lodash/debounce';
import useToast from 'common/hooks/useToast';
import { Modal } from 'common/components/modal';
import { IAddMetalsModal } from 'account/account.type';
import useMetalsData from 'account/hooks/useMetalsData';
import { getCurrencySymbol } from 'common/currency-helper';
import { fNumber, numberWithCommas } from 'common/number.helper';
import { SelectInput } from 'common/components/input/select.input';
import { ReactComponent as DeleteIcon } from 'assets/icons/icon-delete.svg';
import { deleteMetalForAccount, getMetalsForAccount, getValueForMetal, patchPreciousMetal } from 'api/request.api';
import { EMMAccountType } from 'account/enum/account-type';

interface IMetalRow {
  id?: number;
  name: string;
  metalSymbol: string;
  weight: string;
  unit: string;
  karat: string;
  price: string | null;
  marketValue: string;
  isManualPrice: boolean;
}

const initialMetalValue: IMetalRow[] = [
  {
    name: '',
    metalSymbol: '',
    weight: '',
    unit: '',
    karat: '24',
    price: '',
    marketValue: '',
    isManualPrice: false,
  },
];

const unitValues = ['oz t', 'g'];
const karatValues = Array.from({ length: 24 }, (_, i) => i + 1);

const AddMetalsModal: React.FC<IAddMetalsModal> = ({
  accountId,
  addMetalsModal,
  currencySymbol,
  isEditMode = false,
  handleRefresh,
}) => {
  const { mmToast } = useToast();
  const { preciousMetals, isFetching } = useMetalsData();
  const [loading, setLoading] = useState<boolean>(false);
  const [existingMetals, setExistingMetals] = useState<IMetalRow[]>([]);

  const [formInitialValues, setFormInitialValues] = useState<{ metals: IMetalRow[] }>({ metals: initialMetalValue });

  useEffect(() => {
    (async () => {
      if (accountId && addMetalsModal.props.open) {
        const { data } = await getMetalsForAccount(accountId.toString());

        if (!data || !data.length) {
          return;
        }

        const existingRows: IMetalRow[] = data.map((metal: any): IMetalRow => {
          const formattedValue = numberWithCommas(fNumber(parseFloat(metal.value), 2));
          const formattedMarketValue = `${currencySymbol} ${formattedValue}`;
          const isManualMetal = preciousMetals.find((item) => item.metalSymbol === metal.metalSymbol);

          return {
            id: metal.id,
            name: metal.name,
            metalSymbol: metal.metalSymbol,
            weight: metal.quantity,
            unit: metal.unit,
            karat: metal.karat,
            price: metal.usdRate,
            marketValue: formattedMarketValue,
            isManualPrice: !!isManualMetal && !!isManualMetal.usdRate ? false : true,
          };
        });

        setExistingMetals(existingRows);

        if (isEditMode) {
          setFormInitialValues({ metals: [...existingRows, ...initialMetalValue] });
        }
      }
    })();
  }, [accountId, addMetalsModal.props.open, isEditMode, currencySymbol, preciousMetals]);

  if (isFetching || !preciousMetals) {
    return <React.Fragment />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={async (values, actions) => {
        if (!accountId) {
          mmToast('Account Id not found', { type: 'error' });
          return;
        }

        try {
          const { metals } = values;

          const postBody = metals
            .filter(
              ({ name, metalSymbol, weight, unit, karat, price }) =>
                !!name && !!metalSymbol && !!weight && !!unit && !!karat && !!price
            )
            .map(({ id, metalSymbol, weight, unit, karat, price, isManualPrice }) => {
              const existingMetal = existingMetals.find((metal) => metal.metalSymbol === metalSymbol);
              const existingId = existingMetal?.id;

              return {
                ...(id ? { id } : existingId ? { id: existingId } : {}),
                quantity: parseFloat(weight),
                metalSymbol,
                unit,
                karat: parseInt(karat, 10),
                ...(isManualPrice && !!price ? { usdRate: parseFloat(price) } : {}),
              };
            });

          if (postBody.length <= 0) {
            return mmToast('Please add needed fields', { type: 'error' });
          }

          setLoading(true);
          await patchPreciousMetal(accountId.toString(), { metals: postBody });

          actions.setFieldValue('metals', initialMetalValue);
          handleRefresh({ mmAccountType: EMMAccountType.PRECIOUS_METALS, accountId, hasHoldings: true });
          addMetalsModal.close();
          mmToast('Successfully Added', { type: 'success' });
        } catch (err) {
          mmToast('Error occurred while adding metals', { type: 'error' });
        } finally {
          setLoading(false);
        }
      }}
    >
      {(props) => {
        const { values, handleSubmit, setFieldValue, resetForm } = props;

        const onCloseModal = () => {
          setFieldValue('metals', initialMetalValue);
          resetForm();
          addMetalsModal.close();
        };

        const onMetalClick = (metalName: string, index: number) => {
          const preciousMetal = preciousMetals.find((metal) => metal.name === metalName);

          if (!preciousMetal) {
            return;
          }

          const { id, weight, unit, karat } = values.metals[index];
          const { name, usdRate, metalSymbol } = preciousMetal;

          const updatedMetalValue: IMetalRow = {
            id,
            name,
            metalSymbol,
            weight,
            unit,
            karat,
            price: usdRate ? usdRate.toString() : null,
            marketValue: '',
            isManualPrice: !usdRate,
          };

          const newMetalsValue = [...values.metals];
          newMetalsValue[index] = updatedMetalValue;

          if (!!newMetalsValue.length && !!newMetalsValue[newMetalsValue.length - 1].name) {
            newMetalsValue.push({
              name: '',
              metalSymbol: '',
              weight: '',
              unit: '',
              karat: '24',
              price: null,
              marketValue: '',
              isManualPrice: false,
            });
          }

          setFieldValue('metals', newMetalsValue);
          onRowValueChange(updatedMetalValue, index);
        };

        const onFormControlChange = (field: keyof IMetalRow, value: string, index: number) => {
          setFieldValue(`metals.${index}.${field}`, value);

          const newMetalValue: any = values.metals[index];
          newMetalValue[field] = value;

          onRowValueChange(newMetalValue, index);
        };

        const onDeleteMetal = async (metalIndex: number, id?: number) => {
          if (id && accountId) {
            try {
              await deleteMetalForAccount(accountId.toString(), id.toString());
              const newMetalValues = existingMetals.filter((existingMetal) => existingMetal.id !== id);
              setExistingMetals(newMetalValues);
              handleRefresh();
              mmToast('Successfully Deleted', { type: 'success' });
            } catch (err) {
              mmToast('Something went wrong', { type: 'error' });
            }
          }

          const newMetalsValue = values.metals.filter((_, index) => index !== metalIndex);
          setFieldValue('metals', newMetalsValue);
        };

        const onRowValueChange = debounce(async (metalValues: Partial<IMetalRow>, index: number) => {
          try {
            const { metalSymbol, weight, unit, karat, price, isManualPrice } = metalValues;

            if (!metalSymbol || !weight || !unit || !karat || !price) {
              return;
            }

            const usdRate = isManualPrice || isEditMode ? price : undefined;

            const { data } = await getValueForMetal(metalSymbol, weight, unit, karat, usdRate);
            const { currency, value } = data;

            const metalCurrencySymbol = getCurrencySymbol(currency);
            const formattedValue = numberWithCommas(fNumber(parseFloat(value), 2));

            setFieldValue(`metals.${index}.marketValue`, `${metalCurrencySymbol} ${formattedValue}`);
          } catch (err) {
            mmToast('Error occurred while updating market value', { type: 'error' });
          }
        }, 500);

        return (
          <Modal
            {...addMetalsModal.props}
            title={isEditMode ? 'Edit Precious Metals' : 'Add Precious Metals'}
            size='xxl'
            canBeClosed
            onClose={() => onCloseModal()}
          >
            <div className='add-metals-modal'>
              <p className='add-metals-modal__description'>
                Individually tracked metals will be organized under a manual cyrpto currency account. All of your
                individual metals will show under the same account.
              </p>

              <form onSubmit={handleSubmit}>
                <div className='add-metals-modal__body'>
                  <div className='row mb-3 md-hide'>
                    <div className='col-12 col-md-2'>
                      <label className='add-metals-modal__label'>Metal</label>
                    </div>
                    <div className='col-12 col-md-2'>
                      <label className='add-metals-modal__label'>Weight</label>
                    </div>
                    <div className='col-12 col-md-2'>
                      <label className='add-metals-modal__label'>Unit</label>
                    </div>
                    <div className='col-12 col-md-2'>
                      <label className='add-metals-modal__label'>Karat</label>
                    </div>
                    <div className='col-12 col-md-2'>
                      <label className='add-metals-modal__label'>Price</label>
                    </div>
                    <div className='col-12 col-md-2'>
                      <label className='add-metals-modal__label'>Market Value</label>
                    </div>
                  </div>

                  {values.metals.map((metal, index) => {
                    const { id, name, weight, unit, karat, price, marketValue, isManualPrice } = metal;
                    return (
                      <div className='row mb-4 align-items-center' key={index}>
                        <div className='col-12 col-md-2 mb-2 mb-md-0'>
                          <label className='add-metal-modal__label md-show'>Metal</label>
                          <SelectInput
                            name={`metals.${index}.name`}
                            value={name}
                            onChange={(e) => onMetalClick(e.target.value, index)}
                            args={preciousMetals.map((preciousMetal) => preciousMetal.name)}
                          />
                        </div>

                        <div className='col-12 col-md-2 mb-2 mb-md-0'>
                          <label className='add-metal-modal__label md-show'>Weight</label>
                          <Form.Control
                            type='number'
                            name={`metals.${index}.weight`}
                            value={weight}
                            onChange={(e) => onFormControlChange(`weight`, e.target.value, index)}
                            step='any'
                          />
                        </div>
                        <div className='col-12 col-md-2 mb-2 mb-md-0'>
                          <label className='add-metal-modal__label md-show'>Unit</label>
                          <SelectInput
                            name={`metals.${index}.unit`}
                            value={unit}
                            onChange={(e) => onFormControlChange(`unit`, e.target.value, index)}
                            args={unitValues}
                          />
                        </div>
                        <div className='col-12 col-md-2 mb-2 mb-md-0'>
                          <label className='add-metal-modal__label md-show'>Karat</label>
                          <SelectInput
                            name={`metals.${index}.karat`}
                            value={karat}
                            onChange={(e) => onFormControlChange(`karat`, e.target.value, index)}
                            args={karatValues}
                            sort={false}
                          />
                        </div>
                        <div className='col-12 col-md-2 mb-2 mb-md-0'>
                          <label className='add-metal-modal__label md-show'>Price</label>
                          {isManualPrice ? (
                            <Form.Control
                              type='number'
                              name={`metals.${index}.price`}
                              value={price || ''}
                              onChange={(e) => onFormControlChange(`price`, e.target.value, index)}
                              step='any'
                            />
                          ) : (
                            <span className='d-block'>
                              {price ? `${currencySymbol} ${numberWithCommas(fNumber(parseFloat(price), 2))}` : ''}
                            </span>
                          )}
                        </div>
                        <div className='col-12 col-md-2 mb-2 mb-md-0 add-metals-modal__market-value-container justify-content-between'>
                          <label className='add-metal-modal__label md-show'>Market Value</label>

                          <span className='d-block'>{marketValue}</span>
                          {!!name && <DeleteIcon onClick={() => onDeleteMetal(index, id)} />}
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className='d-flex-flex-column flex-md-row justify-content-start'>
                  <button
                    type='button'
                    className='btn-outline-primary mm-btn-animate mr-md-4 mb-2 mb-md-0'
                    onClick={() => onCloseModal()}
                  >
                    Cancel
                  </button>
                  <button type='submit' className='mm-btn-animate mm-btn-primary '>
                    {loading ? (
                      <>
                        <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
                        <span className='ml-1'>Saving...</span>
                      </>
                    ) : (
                      <> {isEditMode ? 'Update Metals' : 'Add Metals'}</>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default AddMetalsModal;
