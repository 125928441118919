import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { appRouteConstants } from 'app/app-route.constant';

import ReferAFriend from './views/refer-a-friend';

const NotFound: React.FC = () => {
  return <div>Not Found</div>;
};

const ReferRoute: React.FC = () => {
  return (
    <Switch>
      <Route exact path={appRouteConstants.refer.REFER} component={ReferAFriend} />
      <Route path={appRouteConstants.refer.NOT_FOUND} component={NotFound} />
      <Redirect to={appRouteConstants.refer.NOT_FOUND} />
    </Switch>
  );
};

export default ReferRoute;
