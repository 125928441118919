import { useEffect, useRef } from 'react';

import { ModalType } from 'common/components/modal';

const useInitialModal = (open: boolean, modal: ModalType) => {
  const modalRef = useRef(modal);

  useEffect(() => {
    if (open) {
      modalRef.current.open();
    }
  }, [open, modalRef]);
};

export default useInitialModal;
