import React from 'react';

import useQroka from 'auth/hooks/useQroka';
import WalletCard from 'auth/views/inc/wallet-card';
import { ECryptoConnections } from 'auth/auth.enum';
import useCoinbaseWallet from 'auth/hooks/useCoinbaseWallet';
import { isCoinbaseWalletSupported } from 'auth/auth.helper';
import { cryptoConnections } from 'auth/data/crypto-connections';

interface IConnectCoinbaseWallet {
  onCancel: () => void;
  handleSuccess: () => void;
}

const ConnectCoinbaseWallet: React.FC<IConnectCoinbaseWallet> = (props) => {
  const { onConnect, disconnect, accounts, error } = useCoinbaseWallet();
  const { connectCoinbaseWallet, loading, errors } = useQroka();
  const connection = cryptoConnections.find((con) => con.type === ECryptoConnections.COINBASE_WALLET);

  if (!connection) {
    return <div>Connection not available</div>;
  }

  const handleCoinbaseConnect = async () => {
    await connectCoinbaseWallet(accounts)(props.handleSuccess);
  };

  const renderSupportText = () => {
    if (!isCoinbaseWalletSupported()) {
      return (
        <div className='error-block'>
          To sync with Coinbase Wallet using Safari, please have Coinbase Wallet open your phone and be ready to scan
          the QR code presented in the next step. Click on Connect Wallet to get started.
        </div>
      );
    }

    return <div className='key-info'>Click on Connect Wallet to open Coinbase Wallet and connect your wallet</div>;
  };

  return (
    <div className='connect-crypto-wrapper'>
      <WalletCard
        disabled
        name={connection.name}
        url={connection.url}
        logo={connection.logo}
        connection={connection.type}
      />
      {renderSupportText()}
      <div className='connect-button-wrapper multiple'>
        <button disabled={loading} onClick={onConnect} className='mm-btn-primary mm-btn-animate'>
          Connect Wallet
        </button>
        {accounts.length > 0 && (
          <button disabled={loading} onClick={disconnect} className='btn-outline-primary mm-btn-animate'>
            Disconnect
          </button>
        )}
      </div>
      <div className='chain-accounts-wrapper'>
        {accounts.map((id) => (
          <div key={id}>
            <div className='chain-title'>Ethereum</div>
            <div className='accounts-wrapper'>
              <p>{id}</p>
            </div>
          </div>
        ))}
      </div>
      {error && <div className='error-block'>{error}</div>}
      {errors['Coinbase Wallet'] && <div className='error-block'>{errors['Coinbase Wallet']}</div>}
      <div className='crypto-action-wrapper'>
        <button type='reset' className='btn-outline-primary mm-btn-animate' disabled={loading} onClick={props.onCancel}>
          Cancel
        </button>

        <button
          type='submit'
          className='mm-btn-animate mm-btn-primary '
          disabled={loading}
          onClick={handleCoinbaseConnect}
        >
          {loading ? (
            <>
              <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
              <span className='ml-1'>Adding...</span>
            </>
          ) : (
            <>Add Accounts</>
          )}
        </button>
      </div>
    </div>
  );
};

export default ConnectCoinbaseWallet;
