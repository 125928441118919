import React from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';

import env from 'app/app.env';
import { EAppEnv } from 'app/app.enum';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import UnsecuredPage from 'common/components/unsecured-page';

import App from './app/app';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: env.APP_ENV,
  release: env.APP_VERSION,
  tracesSampleRate: 1.0,

  beforeSend: (e) => (env.APP_ENV === EAppEnv.LOCAL ? null : e),
});

export { Sentry };

ReactGA.initialize(
  [
    {
      trackingId: env.GOOGLE_ANALYTICS_TRACKING_ID,
      gaOptions: {
        siteSpeedSampleRate: 1,
      },
    },
    {
      trackingId: env.GOOGLE_ADS_TRACKING_ID,
      gaOptions: {
        siteSpeedSampleRate: 1,
      },
    },
  ],
  {
    debug: false,
  }
);

ReactGA.set({ page: window.location.pathname + window.location.search });
ReactGA.pageview(window.location.pathname + window.location.search);

if (window.self === window.top) {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(<UnsecuredPage />, document.getElementById('root'));
}

serviceWorker.unregister();
