import { EIntervalOption } from 'dashboard/dashboard.enum';
import { getBeginningOfMonth, getBeginningOfYear, subtractFromDate } from 'common/moment.helper';

import comparisonMap from './data/comparison.json';

export const getComparisonDate = (interval: EIntervalOption) => {
  const comparisonDateMap: Record<EIntervalOption, Date> = {
    [EIntervalOption['1D']]: subtractFromDate(1, 'days'),
    [EIntervalOption['1W']]: subtractFromDate(7, 'days'),
    [EIntervalOption['1M']]: subtractFromDate(1, 'months'),
    [EIntervalOption['1Y']]: subtractFromDate(1, 'years'),
    [EIntervalOption.MTD]: getBeginningOfMonth(),
    [EIntervalOption.YTD]: getBeginningOfYear(),
    [EIntervalOption.ALL]: new Date(),
  };

  return comparisonDateMap[interval];
};

export const getComparisonLabel = (interval: EIntervalOption) => {
  const comparisonItem = comparisonMap.find((item) => item.key === interval);

  return comparisonItem?.label || '';
};
