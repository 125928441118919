import React, { useState } from 'react';

import { events } from '@mm/data/event-list';
import { EProviderType } from 'auth/auth.types';
import { useModal } from 'common/components/modal';
import useAnalytics from 'common/hooks/useAnalytics';
import { getSaltEdgeConnectWorld } from 'api/request.api';
import { ReactComponent as RightArrow } from 'assets/icons/connect-account/right-arrow.svg';

import SaltEdgeModal from './salt-edge.modal';

/**
 * @description React component to show account connection card
 * please consider reusing it if possible with passing props
 * @returns JSX.Element
 */

interface ICardInfo {
  title: string;
  description: string;
  buttonText: string;
}

interface IConnectSaltEdgeWorld {
  cardInfo: ICardInfo;
  icon: React.ReactNode;
  handleSuccess: (provider: EProviderType) => void;
}

export interface ISaltEdgeResponse {
  expiresAt: string;
  connectUrl: string;
}

const ConnectSaltEdgeWorld: React.FC<IConnectSaltEdgeWorld> = ({ cardInfo, icon, handleSuccess }) => {
  const saltEdgeModal = useModal();
  const { event } = useAnalytics();
  const [loading, setLoading] = useState(false);
  const [saltEdge, setSaltEdge] = useState<ISaltEdgeResponse>();

  const handleSaltEdgeConnect = async () => {
    setLoading(true);
    const { data, error }: { data: ISaltEdgeResponse; error: any } = await getSaltEdgeConnectWorld();
    event(events.connectSaltEdgeWorld);
    setLoading(false);

    if (!error) {
      setSaltEdge(data);

      if (data?.connectUrl) {
        return window.open(data?.connectUrl, '_self');
      }
    }
  };

  const handleRefreshAccount = () => {
    handleSuccess(EProviderType.SALT_EDGE_GATEWAY);
  };

  const renderSaltEdgeModal = () => {
    if (saltEdge) {
      return <SaltEdgeModal saltEdge={saltEdge} saltEdgeModal={saltEdgeModal} handleSuccess={handleRefreshAccount} />;
    }
  };

  return (
    <>
      <button className='card-button m-r-4' type='submit' onClick={handleSaltEdgeConnect}>
        <div className='add-account-card blue'>
          <div className='card-body'>
            {loading ? (
              <div className='loading'>
                <span className='spinner-grow' role='status' aria-hidden='true' />
              </div>
            ) : (
              <>
                <div className='card-top'>
                  <span>{icon}</span>
                  <span className='title'>{cardInfo.title}</span>
                  <RightArrow className='arrow' />
                </div>
                <div>
                  <p className='description'>{cardInfo.description}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </button>
      {renderSaltEdgeModal()}
    </>
  );
};

export default ConnectSaltEdgeWorld;
