import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import useAccountTypes from 'account-types/hooks/useAccountTypes';
import AccountTypesHead from 'account-types/inc/account-types-head';
import AccountTypesBody from 'account-types/inc/account-types-body';

import AccountTypesLayout from './account.types.layout';
import AccountTypesLoading from '../components/account-types-loading';

const AccountTypes: React.FC = () => {
  const { type } = useParams<any>();

  const { loading, accountTypes, defaultToDate, defaultFromDate, accountDataByType, accountTypesHoldings } =
    useAccountTypes(type);

  const renderAccountTypes = () => {
    if (loading) {
      return <AccountTypesLoading />;
    }

    return (
      <>
        <AccountTypesHead accountTypes={accountTypes} />
        <hr className='m-0' />

        {accountDataByType && (
          <div className='content-wrapper'>
            <div className='container'>
              <AccountTypesBody
                defaultToDate={defaultToDate}
                defaultFromDate={defaultFromDate}
                accountDataByType={accountDataByType}
                holdingsForAccountType={accountTypesHoldings}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <AccountTypesLayout>
      <HelmetProvider>
        <Helmet>
          <title>Account Types | Money Minx</title>
        </Helmet>
      </HelmetProvider>

      <section>{renderAccountTypes()}</section>
    </AccountTypesLayout>
  );
};

export default AccountTypes;
