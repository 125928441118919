import React from 'react';

import useQroka from 'auth/hooks/useQroka';
import WalletCard from 'auth/views/inc/wallet-card';
import { ECryptoConnections } from 'auth/auth.enum';
import { cryptoConnections } from 'auth/data/crypto-connections';

interface IConnectLedger {
  onCancel: () => void;
  handleSuccess: () => void;
}

const ConnectGemini: React.FC<IConnectLedger> = (props) => {
  const { loading, getConnectionURL } = useQroka();

  const connection = cryptoConnections.find((con) => con.type === ECryptoConnections.GEMINI);

  if (!connection) {
    return <div>Connection not available</div>;
  }

  const initGeminiConnection = async () => {
    const { data, error } = await getConnectionURL(ECryptoConnections.GEMINI);

    if (!error && data) {
      return window.open(data.connectionURL, '_blank');
    }
  };

  const renderSupportText = () => {
    return <div className='key-info'>Click on Connect Gemini button to authenticate with your Gemini account. Please watch for popup blockers.</div>;
  };

  return (
    <div className='connect-crypto-wrapper'>
      <WalletCard
        disabled
        name={connection.name}
        url={connection.url}
        logo={connection.logo}
        connection={connection.type}
      />
      {renderSupportText()}

      <div className='crypto-action-wrapper'>
        <button type='reset' className='btn-outline-primary mm-btn-animate' disabled={loading} onClick={props.onCancel}>
          Cancel
        </button>

        <button
          type='submit'
          className='mm-btn-animate mm-btn-primary '
          disabled={loading}
          onClick={initGeminiConnection}
        >
          {loading ? (
            <>
              <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
              <span className='ml-1'>Connecting...</span>
            </>
          ) : (
            <>Connect Gemini</>
          )}
        </button>
      </div>
    </div>
  );
};

export default ConnectGemini;
