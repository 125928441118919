import { handleStringArrayToggle } from 'common/common-helper';

import { getUpdatedFilter } from './filter.helper';
import { EFilterAction, IFilterAction, IFilterState } from './filter.type';

export function filterReducer(state: IFilterState, action: IFilterAction): any {
  switch (action.type) {
    case EFilterAction.SET_FILTER_CATEGORY: {
      return { ...state, fCategories: handleStringArrayToggle(state.fCategories, action.payload?.fCategory) };
    }

    case EFilterAction.SET_FILTER_ACCOUNT_TYPE: {
      return { ...state, fTypes: handleStringArrayToggle(state.fTypes, action.payload?.fAccountType) };
    }

    case EFilterAction.SET_FILTER_FROM_DATE: {
      return { ...state, fFromDate: action.payload?.fFromDate };
    }

    case EFilterAction.SET_FILTER_TO_DATE: {
      return { ...state, fToDate: action.payload?.fToDate };
    }

    case EFilterAction.SET_FILTER_TIME_INTERVAL: {
      return { ...state, fTimeInterval: action.payload?.fTimeInterval };
    }

    case EFilterAction.SET_FILTER_ACCOUNT: {
      return { ...state, fAccounts: handleStringArrayToggle(state.fAccounts, action.payload?.fAccountId) };
    }

    case EFilterAction.SET_FILTER_ENTITY: {
      return { ...state, fEntities: handleStringArrayToggle(state.fEntities, action.payload?.fEntityId) };
    }

    case EFilterAction.SET_FILTER_LIQUIDITY: {
      return { ...state, fLiquidity: handleStringArrayToggle(state.fLiquidity, action.payload?.fLiquidityItem) };
    }

    case EFilterAction.FILTER_BULK_UPDATE: {
      const filterToUpdate = action.payload?.localFilter;

      return {
        ...state,
        fCategories: filterToUpdate?.fCategories || state.fCategories,
        fTypes: filterToUpdate?.fTypes || state.fTypes,
        fFromDate: filterToUpdate?.fFromDate || state.fFromDate,
        fToDate: filterToUpdate?.fToDate || state.fToDate,
        fTimeInterval: filterToUpdate?.fTimeInterval || state.fTimeInterval,
        fAccounts: filterToUpdate?.fAccounts || state.fAccounts,
        fEntities: filterToUpdate?.fEntities || state.fEntities,
        fLiquidity: filterToUpdate?.fLiquidity || state.fLiquidity,
      };
    }

    case EFilterAction.CLEAR_FILTER: {
      return {
        ...state,
        fTypes: [],
        fAccounts: [],
        fCategories: [],
        fToDate: undefined,
        fFromDate: undefined,
        fTimeInterval: undefined,
        fEntities: [],
        fLiquidity: [],
      };
    }

    case EFilterAction.SET_FILTERS: {
      return { ...state, filters: action.payload?.filters };
    }

    case EFilterAction.ADD_FILTER: {
      const newFilter = action.payload?.filter;
      const modifiedFilters = newFilter ? state.filters.concat(newFilter) : state.filters;

      return { ...state, filters: modifiedFilters };
    }

    case EFilterAction.UPDATE_FILTER: {
      const newFilter = action.payload?.filter;
      const modifiedFilters = newFilter ? getUpdatedFilter(state.filters, newFilter) : state.filters;

      return { ...state, filters: modifiedFilters };
    }

    case EFilterAction.REMOVE_FILTER: {
      const filterId = action.payload?.filterId || '';
      const modifiedFilters = state.filters.filter((filter) => filter.id !== +filterId);

      return { ...state, filters: modifiedFilters };
    }

    case EFilterAction.SET_PROCESSING: {
      return { ...state, processing: true };
    }

    case EFilterAction.RESET_PROCESSING: {
      return { ...state, processing: false };
    }

    default: {
      throw new Error('Unhandled action type in filter reducer!!');
    }
  }
}
