import React, { useState } from 'react';

import classNames from 'common/classes.helper';
import { enumerateStr } from 'common/common-helper';
import { EIntervalOption } from 'dashboard/dashboard.enum';

interface IIntervalSelectorProps {
  defaultInterval?: EIntervalOption;
  onChange: (interval: EIntervalOption) => void;
}

const IntervalSelector: React.FC<IIntervalSelectorProps> = ({ defaultInterval = EIntervalOption['1D'], onChange }) => {
  const [selectedInterval, setSelectedInterval] = useState<EIntervalOption>(defaultInterval);

  const handleChange = (interval: EIntervalOption) => {
    setSelectedInterval(interval);

    if (onChange) {
      onChange(interval);
    }
  };

  const renderIntervalOptions = () => {
    return enumerateStr(EIntervalOption).map((option) => (
      <li
        key={option}
        className={classNames(
          'interval-selector__option',
          option === selectedInterval && 'interval-selector__option--active'
        )}
        role='button'
        onClick={() => handleChange(option)}
      >
        {option}
      </li>
    ));
  };

  return <ul className='interval-selector'>{renderIntervalOptions()}</ul>;
};

export default IntervalSelector;
