import React from 'react';
import { useHistory } from 'react-router-dom';

import { base64Encode } from 'common/helper/string.helper';
import { getCurrencySymbol } from 'common/currency-helper';
import { IRecentActivity } from 'dashboard/dashboard.types';
import { fNumber, numberWithCommas } from 'common/number.helper';

interface IRecentActivityItemProps {
  activity: IRecentActivity;
}

const RecentActivityItem: React.FC<IRecentActivityItemProps> = ({ activity }) => {
  const { description, date, type, amount, amountCurrency, account, accountId } = activity;

  const { providerLogo, providerName } = account;

  const history = useHistory();

  const currencySymbol = getCurrencySymbol(amountCurrency);
  const balanceSymbol = amount <= 0 ? '-' : '';
  const amountText = `${balanceSymbol}${currencySymbol}${numberWithCommas(fNumber(Math.abs(amount), 2))}`;

  const navigateToAccountDetail = () => {
    const encodedAccountId = base64Encode(accountId.toString());
    history.push('/account-details/' + encodedAccountId);
  };

  return (
    <div className='recent-activity-item' role='button' onClick={navigateToAccountDetail}>
      {providerLogo && <img src={providerLogo} alt={providerName || ''} />}
      <div className='recent-activity-item__content'>
        <div className='d-flex justify-content-between mb-1'>
          <p className='recent-activity-item__description'>{description}</p>
          <span className='recent-activity-item__amount'>{amountText}</span>
        </div>
        <div className='recent-activity-item__info'>
          <span className='recent-activity-item__type'>{type}</span> - <span>{date}</span>
        </div>
      </div>
    </div>
  );
};

export default RecentActivityItem;
