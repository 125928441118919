import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Account } from 'auth/auth.types';
import useToast from 'common/hooks/useToast';
import { base64Encode } from 'common/helper/string.helper';
import { IManualMortgageAccount } from 'account/account.type';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { postAssociateMortgageAccount, deleteMortageAccountAssociation } from 'api/request.api';

import AddAssociatedMortgage from './add-associated-mortgage';
import AssociatedMortgageItem from './associated-mortgage-item';

interface IAssociatedMortgageProps {
  accountId: number;
  manualAccounts: IManualMortgageAccount[];
  associatedMortgageAccounts: Account[];
  handleReload: VoidFunction;
  locked: boolean;
}

const AssociatedMortgage: React.FC<IAssociatedMortgageProps> = ({
  accountId,
  manualAccounts,
  associatedMortgageAccounts,
  handleReload,
  locked,
}) => {
  const history = useHistory();
  const { mmToast } = useToast();

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<string>('');
  const [isAddMortgageFormVisible, setIsAddMortgageFormVisible] = useState<boolean>(false);
  const [dropdownMortgageAccounts, setDropdownMortgageAccounts] = useState<IManualMortgageAccount[]>([]);

  const showAddMortgageForm = isAddMortgageFormVisible || associatedMortgageAccounts.length <= 0;
  const showAddNewMortgageButton = !isAddMortgageFormVisible && associatedMortgageAccounts.length > 0;

  useEffect(() => {
    setDefaultMortgageAccount(dropdownMortgageAccounts);
  }, [dropdownMortgageAccounts]);

  useEffect(() => {
    const filteredAccounts = manualAccounts.filter((item) => {
      const isExists = associatedMortgageAccounts.find((associatedAcc) => associatedAcc.id === item.id);

      return !isExists;
    });

    setDropdownMortgageAccounts(filteredAccounts);
  }, [manualAccounts, associatedMortgageAccounts]);

  const setDefaultMortgageAccount = (accounts: IManualMortgageAccount[]) => {
    const dropdownDefaultAccount = accounts.length > 0 ? accounts[0].accountName : '';
    setSelectedAccount(dropdownDefaultAccount);
  };

  const handleSubmit = async (mortgageAccountName: string) => {
    const account = manualAccounts.find((item) => item.accountName === mortgageAccountName);

    if (!account) {
      return mmToast('Something went wrong', { type: 'error' });
    }

    try {
      setLoading(true);

      await postAssociateMortgageAccount(account.id, accountId);

      handleReload();
      mmToast('Mortgage account successfully linked', { type: 'success' });
    } catch (err) {
      mmToast('Something went wrong', { type: 'error' });
    } finally {
      setLoading(false);
      setIsAddMortgageFormVisible(false);
    }
  };

  const handleDeleteMortgageAccountAssociation = async (mortgageAccId: number) => {
    try {
      setLoading(true);

      await deleteMortageAccountAssociation(mortgageAccId, accountId);

      handleReload();
      mmToast('Mortgage account successfully removed', { type: 'success' });
    } catch (err) {
      mmToast('Something went wrong', { type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const navigateToAssociatedAccount = (accId: number) => {
    const encodedAccountId = base64Encode(accId.toString());
    history.push('/account-details/' + encodedAccountId);
  };

  const renderContent = () => {
    return (
      <>
        {associatedMortgageAccounts.map((account) => (
          <AssociatedMortgageItem
            key={account.id}
            associatedAccount={account}
            handleDelete={handleDeleteMortgageAccountAssociation}
            navigateToAssociatedAccount={navigateToAssociatedAccount}
          />
        ))}

        {showAddNewMortgageButton && (
          <button
            className='associated-mortgage__btn-add'
            type='button'
            onClick={() => setIsAddMortgageFormVisible(true)}
          >
            <PlusIcon />
            <span>Add New Mortgage</span>
          </button>
        )}

        {showAddMortgageForm && (
          <AddAssociatedMortgage
            loading={loading}
            manualAccounts={dropdownMortgageAccounts.map((item) => item.accountName)}
            defaultSelectedAccount={selectedAccount}
            handleSubmit={handleSubmit}
          />
        )}
      </>
    );
  };

  return (
    <div className='associated-mortgage'>
      {locked && (
        <div className='locked-area'>
          <div>
            <Link to='/settings?active=Plan' className='purple-links small-bold'>
              Track your real estate mortgages with a pro account.
            </Link>
          </div>
        </div>
      )}
      <h5 className='associated-mortgage__title'>Mortgage</h5>
      <span className='associated-mortgage__description'>Which mortgages are for this property?</span>

      {renderContent()}
    </div>
  );
};

export default AssociatedMortgage;
