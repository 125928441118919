import React, { createContext, useContext, useReducer } from 'react';

import { TimeIntervalEnum } from 'networth/networth.enum';
import { EAccountTypeAction } from 'account-types/account-types.enum';
import { IAccountTypeAction, IAccountTypesState, TAccountTypeDispatch } from 'account-types/account-types.types';

export const initialState: IAccountTypesState = {
  accountType: undefined,
  fromDate: undefined,
  toDate: undefined,
  timeInterval: TimeIntervalEnum.MONTHLY,
};

function accountTypesReducer(state: IAccountTypesState, action: IAccountTypeAction): IAccountTypesState {
  switch (action.type) {
    case EAccountTypeAction.SET_ACCOUNT_TYPE:
      return { ...state, accountType: action.payload?.accountType };

    case EAccountTypeAction.RESET_ACCOUNT_TYPE:
      return { ...state, accountType: undefined };

    case EAccountTypeAction.SET_FROM_DATE:
      return { ...state, fromDate: action.payload?.fromDate };

    case EAccountTypeAction.SET_TO_DATE:
      return { ...state, toDate: action.payload?.toDate };

    case EAccountTypeAction.SET_TIME_INTERVAL:
      return { ...state, timeInterval: action.payload?.timeInterval };

    case EAccountTypeAction.CLEAR_FILTERS:
      return { ...state, fromDate: undefined, toDate: undefined, timeInterval: TimeIntervalEnum.MONTHLY };

    default:
      throw new Error('Unhandled action type in accountTypes reducer');
  }
}

// Provider wrappers
const AccountTypesStateContext = createContext<IAccountTypesState | undefined>(undefined);
const AccountTypesDispatchContext = createContext<TAccountTypeDispatch | undefined>(undefined);

const AccountTypesProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(accountTypesReducer, initialState);

  return (
    <AccountTypesStateContext.Provider value={state}>
      <AccountTypesDispatchContext.Provider value={dispatch}>{children}</AccountTypesDispatchContext.Provider>
    </AccountTypesStateContext.Provider>
  );
};

// Consumer helpers
const useAccountTypesState = () => {
  const context = useContext(AccountTypesStateContext);

  if (!context) {
    throw new Error('useAccountTypesState must be used withing a AccountTypesProvider');
  }

  return context;
};

const useAccountTypesDispatch = () => {
  const context = useContext(AccountTypesDispatchContext);

  if (!context) {
    throw new Error('useAccountTypesDispatch must be used withing a AccountTypesProvider');
  }

  return context;
};

export { AccountTypesProvider, useAccountTypesState, useAccountTypesDispatch };
