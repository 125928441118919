import { Form } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';

import { Formik } from 'formik';
import useToast from 'common/hooks/useToast';
import { logger } from 'common/logger.helper';
import { Modal } from 'common/components/modal';
import { EMMAccountType } from 'account/enum/account-type';
import SearchInput from 'common/components/input/search.input';
import { IAddCoinsModalProps, ICoinGecko } from 'account/account.type';
import { ReactComponent as DeleteIcon } from 'assets/icons/icon-delete.svg';
import { fNumber, formatNumber, numberWithCommas } from 'common/number.helper';
import { deleteGeckoCoinForAccount, getCoinGecko, getCoinsForAccount, patchCoinGecko } from 'api/request.api';

interface ICoinRow {
  coinGeckoId: string;
  name: string;
  quantity: string;
  costPerCoin: string;
  price: string;
  isNew: boolean;
  logo: string | null;
  id?: number;
}

const initialCoinValue: ICoinRow[] = [
  {
    coinGeckoId: '',
    name: '',
    quantity: '',
    costPerCoin: '',
    price: '',
    logo: null,
    isNew: true,
  },
];

const AddCoinsModal: React.FC<IAddCoinsModalProps> = ({
  addCoinsModal,
  accountId,
  currencySymbol,
  isEditMode = false,
  handleRefresh,
}) => {
  const { mmToast } = useToast();

  const [loading, setLoading] = useState<boolean>(false);
  const [existingCoins, setExistingCoins] = useState<ICoinRow[]>([]);
  const [formInitialValues, setFormInitialValues] = useState<{ coins: ICoinRow[] }>({ coins: initialCoinValue });

  useEffect(() => {
    (async () => {
      if (accountId && addCoinsModal.props.open) {
        const { data } = await getCoinsForAccount(accountId.toString());

        if (!data || !data.length) {
          return;
        }

        const existingRows: ICoinRow[] = data.map((coin: any) => ({
          coinGeckoId: coin.geckoCoinId,
          name: coin.geckoCoinId,
          quantity: coin.quantity,
          costPerCoin: coin.cost,
          isNew: false,
          price: coin.usdRate,
          logo: coin.logo,
          id: coin.id,
        }));

        setExistingCoins(existingRows);

        if (isEditMode) {
          setFormInitialValues({ coins: [...existingRows, ...initialCoinValue] });
        }
      }
    })();
  }, [accountId, addCoinsModal.props.open, isEditMode]);

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={async (values, actions) => {
        if (!accountId) {
          return mmToast('Account Id not found', { type: 'error' });
        }

        try {
          const { coins } = values;

          const postBody = coins
            .filter(({ coinGeckoId, quantity }) => !!coinGeckoId && !!quantity)
            .map(({ coinGeckoId, quantity, costPerCoin, id }) => {
              const existingCoin = existingCoins.find((coin) => coin.coinGeckoId === coinGeckoId);
              const existingId = existingCoin?.id;

              return {
                coinGeckoId,
                quantity: parseFloat(quantity),
                cost: costPerCoin ? parseFloat(costPerCoin) : null,
                ...(id ? { id } : existingId ? { id: existingId } : {}),
              };
            });

          if (postBody.length <= 0) {
            // FIXME: This message need to be shown in form, we are not showing toast on production
            return mmToast('Please add needed fields', { type: 'error' });
          }

          setLoading(true);
          await patchCoinGecko(accountId.toString(), { coins: postBody });

          actions.setFieldValue('coins', initialCoinValue);
          handleRefresh({ mmAccountType: EMMAccountType.CRYPTOCURRENCIES, accountId, hasHoldings: true });

          addCoinsModal.close();
          mmToast('Successfully Added', { type: 'success' });
        } catch (err) {
          logger.log('Error adding the coins', err);
          mmToast('Error occurred adding coins', { type: 'error' });
        } finally {
          setLoading(false);
        }
      }}
    >
      {(props) => {
        const { values, setFieldValue, handleChange, handleSubmit, resetForm } = props;

        const onCloseModal = () => {
          setFormInitialValues({ coins: initialCoinValue });
          resetForm();
          addCoinsModal.close();
        };

        const onCoinClick = (index: number, geckoCoin: ICoinGecko) => {
          const { coinGeckoId, name, usdRate, logo } = geckoCoin;
          const { quantity, costPerCoin } = values.coins[index];

          const updatedCoinValue: ICoinRow = {
            coinGeckoId,
            name,
            costPerCoin,
            quantity,
            price: usdRate.toString(),
            isNew: true,
            logo,
          };

          const newCoinsValue = [...values.coins];
          newCoinsValue[index] = updatedCoinValue;
          newCoinsValue.push({
            coinGeckoId: '',
            name: '',
            quantity: '',
            costPerCoin: '',
            price: '',
            isNew: true,
            logo: null,
          });

          setFieldValue('coins', newCoinsValue);
        };

        const onDeleteGeckoCoin = async (coinGeckoId: string, isNew: boolean, id: number | undefined) => {
          if (!accountId) {
            return;
          }

          if (!isNew && id) {
            try {
              await deleteGeckoCoinForAccount(accountId.toString(), id.toString());
              handleRefresh();
              mmToast('Successfully Deleted', { type: 'success' });
            } catch (err) {
              logger.log('Error deleting a Gecko coin', err);
              mmToast('Error Occurred deleting a Gecko coin', { type: 'error' });
            }
          }

          const updatedCoinValues = values.coins.filter((coin) => coin.coinGeckoId !== coinGeckoId);
          setFieldValue('coins', updatedCoinValues);
        };

        const getMarketValue = (quantity: string, costPerCoin: string) => {
          if (!quantity || !costPerCoin) {
            return '';
          }

          const marketValue = parseFloat(quantity) * parseFloat(costPerCoin);
          return `${currencySymbol} ${numberWithCommas(fNumber(marketValue, 2))}`;
        };

        const renderGeckoCoinList = (index: number) => (geckoCoins: ICoinGecko[]) => {
          return (
            <ul className='gecko-coin-container'>
              {geckoCoins.map((geckoCoin) => {
                const { id, logo, name, symbol } = geckoCoin;
                return (
                  <li className='gecko-coin' key={id} onClick={() => onCoinClick(index, geckoCoin)} role='button'>
                    <img src={logo} alt={name} className='gecko-coin__logo' />
                    <span className='gecko-coin__symbol'>{symbol}</span>
                    <span className='gecko-coin__name'>{name}</span>
                  </li>
                );
              })}
            </ul>
          );
        };

        return (
          <Modal
            {...addCoinsModal.props}
            title={isEditMode ? 'Edit Coins' : 'Add Coins'}
            size='xxl'
            canBeClosed
            onClose={() => onCloseModal()}
          >
            <div className='add-coin-modal'>
              <p className='add-coin-modal__description'>
                Add your coin collection to this account. We will keep the values updated for you automatically.
              </p>

              <form onSubmit={handleSubmit}>
                <div className='add-coin-modal__body'>
                  <div className='row mb-3 md-hide'>
                    <div className='col-12 col-md-4'>
                      <label className='add-coin-modal__label'>Coin</label>
                    </div>
                    <div className='col-12 col-md-2'>
                      <label className='add-coin-modal__label'>Quantity</label>
                    </div>

                    <div className='col-12 col-md-2'>
                      <label className='add-coin-modal__label'>Cost per coin</label>
                    </div>

                    <div className='col-12 col-md-2'>
                      <label className='add-coin-modal__label'>Price</label>
                    </div>

                    <div className='col-12 col-md-2'>
                      <label className='add-coin-modal__label'>Market Value</label>
                    </div>
                  </div>

                  {/* Render the dynamic form here */}

                  {values.coins.map(({ name, costPerCoin, quantity, isNew, coinGeckoId, price, logo, id }, index) => (
                    <div className='row mb-4 align-items-center' key={`${coinGeckoId}${index}`}>
                      <div className='col-12 col-md-4 mb-2 mb-md-0'>
                        <label className='add-coin-modal__label md-show'>Coin</label>
                        <SearchInput
                          value={name}
                          placeholder='Type a coin name or symbol'
                          icon={logo}
                          onSearch={getCoinGecko}
                          onTextChange={(e) => setFieldValue(`coins.${index}.name`, e.target.value)}
                          onRenderItemList={renderGeckoCoinList(index)}
                        />
                      </div>
                      <div className='col-12 col-md-2 mb-2 mb-md-0'>
                        <label className='add-coin-modal__label md-show'>Quantity</label>
                        <Form.Control
                          type='number'
                          name={`coins.${index}.quantity`}
                          value={quantity}
                          onChange={handleChange}
                          step='any'
                        />
                      </div>

                      <div className='col-12 col-md-2 mb-2 mb-md-0'>
                        <label className='add-coin-modal__label md-show'>Cost per Coin</label>
                        <Form.Control
                          type='number'
                          name={`coins.${index}.costPerCoin`}
                          value={costPerCoin}
                          onChange={handleChange}
                          step='any'
                        />
                      </div>

                      <div className='col-12 col-md-2 mb-2 mb-md-0'>
                        <label className='add-coin-modal__label md-show'>Price</label>
                        <span className='d-block'>{price ? `${currencySymbol} ${formatNumber(+price)}` : ''}</span>
                      </div>

                      <div className='col-12 col-md-2 mb-2 mb-md-0 coin-market-value justify-content-between'>
                        <label className='add-coin-modal__label md-show'>Market Value</label>
                        <span className='d-block'>
                          {getMarketValue(values.coins[index].quantity, values.coins[index].price)}
                        </span>
                        {!!coinGeckoId && <DeleteIcon onClick={() => onDeleteGeckoCoin(coinGeckoId, isNew, id)} />}
                      </div>
                    </div>
                  ))}
                </div>

                <div className='d-flex flex-column flex-md-row justify-content-start'>
                  <button
                    type='button'
                    className='btn-outline-primary mm-btn-animate mr-md-4 mb-2 mb-md-0'
                    onClick={() => onCloseModal()}
                  >
                    Cancel
                  </button>
                  <button type='submit' className='mm-btn-animate mm-btn-primary '>
                    {loading ? (
                      <>
                        <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
                        <span className='ml-1'>Saving...</span>
                      </>
                    ) : (
                      <> {isEditMode ? 'Update Coins' : 'Add Coins'}</>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default AddCoinsModal;
