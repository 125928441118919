import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import AppFooter from 'common/app.footer';
import { storage } from 'app/app.storage';
import AppHeader from 'common/app.header';
import AppSidebar from 'common/app.sidebar';
import ICONS from 'common/components/icons';
import useToast from 'common/hooks/useToast';
import { events } from '@mm/data/event-list';
import { MMCategories } from 'auth/auth.enum';
import { isNumber } from 'common/number.helper';
import { EProviderType } from 'auth/auth.types';
import MMToolTip from 'common/components/tooltip';
import LoadingScreen from 'common/loading-screen';
import FastLinkModal from 'yodlee/fast-link.modal';
import { useModal } from 'common/components/modal';
import { useAuthDispatch } from 'auth/auth.context';
import { getFastlinkUpdate } from 'api/request.api';
import useSaltEdge from 'account/hooks/useSaltEdge';
import useAnalytics from 'common/hooks/useAnalytics';
import Popup from 'account/components/account-popup';
import { setFastlinkLoading } from 'app/app.actions';
import { getRefreshedAccount } from 'auth/auth.service';
import { EConnectionStatus } from 'common/common.types';
import { FastLinkOptionsType } from 'yodlee/yodlee.type';
import { ETableType } from 'account/enum/table-type.enum';
import { useDispatch, useAppState } from 'app/app.context';
import { parseAmount, shortId } from 'common/common-helper';
import { Placeholder } from 'networth/views/inc/placeholder';
import useAccountDetail from 'account/hooks/useAccountDetail';
import { getDate, getRelativeDate } from 'common/moment.helper';
import AccountMoreInfo from 'account/components/account-more-info';
import AccountSideCard from 'account/components/account-side-card';
import { ReactComponent as ClosedIcon } from 'assets/icons/closed.svg';
import AccountSettingsSideBar from 'auth/views/account-settings-sidebar';
import CircularSpinner from 'common/components/spinner/circular-spinner';
import AccountDetailFilter from 'account/components/account-detail-filter';
import AddEditHoldingModal from 'account/components/add-edit-holding.modal';
import { ReactComponent as PieChart } from 'assets/icons/icon-pie-chart.svg';
import { EMMAccountSubType, EMMAccountType } from 'account/enum/account-type';
import AccountDetailSkeleton from 'account/components/account-detail-skeleton';
import { isRefreshingAccount, isSaltEdgeAccount, isYodleeAccount } from 'account/account.helper';

import AccountTable from './account-table';
import BalanceTable from './balance-table';
import ActivityTable from './activity-table';
import ChartSkeleton from './chart-skeleton';
import AddCoinsModal from './add-coins.modal';
import AddMetalsModal from './add-metals.modal';
import AccountBarGraph from './account-bar-graph';
import RealEstateDetails from './real-estate-details';
import ClassificationsModal from './classifications.modal';
import ActivityDetailsModal from './activity-details.modal';
import AccountSubNavigation from './account-sub-navigation';
import HoldingsDetailsModal from './holdings-details.modal';
import { AccountChartItem, IFixAccountConfig } from '../account.type';

const AccountDetail: React.FC = () => {
  const location = useLocation();
  const { mmToast } = useToast();
  const { event } = useAnalytics();
  const fastlinkModal = useModal();
  const addCoinsModal = useModal();
  const appDispatch = useDispatch();
  const addMetalsModal = useModal();
  const dispatch = useAuthDispatch();
  const holdingsDetailsModal = useModal();
  const activityDetailsModal = useModal();
  const classificationsModal = useModal();
  const addEditHoldingModal = useModal();
  const { fastLinkLoading } = useAppState();
  const { refreshSaltEdge, reconnectSaltEdge } = useSaltEdge();

  const [popup, setPopup] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openLeftNav, setOpenLeftNav] = useState<boolean>(false);
  const [openRightNav, setOpenRightNav] = useState<boolean>(false);
  const [refreshingAccount, setRefreshingAccount] = useState(false);
  const [fastLinkOptions, setFastLinkOptions] = useState<FastLinkOptionsType>({
    fastLinkURL: '',
    token: { tokenType: 'AccessToken', tokenValue: '' },
    config: { flow: '', configName: 'Aggregation', providerAccountId: 0 },
  });

  const [fixAccountConfig, setFixAccountConfig] = useState<IFixAccountConfig | null>(null);

  const {
    tableType,
    accSetting,
    balanceData,
    resetFilter,
    baseCurrency,
    setTableType,
    withHoldings,
    filterLoading,
    handleRefresh,
    setAccSetting,
    accountDetail,
    accountFilter,
    currencySymbol,
    accountHoldings,
    setBaseCurrency,
    setAccountFilter,
    accountActivities,
    handleRefreshAll,
  } = useAccountDetail();

  const isQrokaAccount = accountDetail?.mmAccountProvider === EProviderType.QROKA;
  const searchQuery = queryString.parse(location.search);

  const type = (searchQuery.mmAccountType as string) || '';
  const mmAccountType = accountDetail?.category?.mmAccountType || type;

  const handleConnectAccountSuccess = async (provider: EProviderType) => {
    setLoading(true);
    setRefreshingAccount(true);

    const { error } = await getRefreshedAccount({ dispatch, provider });

    setLoading(false);
    setRefreshingAccount(false);

    if (error) {
      return mmToast('Error occurred on fetching refreshed account', { type: 'error' });
    }

    return handleRefreshAll();
  };

  const handleConnectAccount = async (accId: number, update: boolean, refresh: boolean) => {
    appDispatch(setFastlinkLoading());
    const { data, error } = await getFastlinkUpdate(accId, update, refresh);

    if (error) {
      return mmToast('Error Occurred to Get Fastlink', { type: 'error' });
    }

    const fLinkOptions: FastLinkOptionsType = {
      fastLinkURL: data.fastLinkUrl,
      token: data.accessToken,
      config: data.params,
    };

    setFastLinkOptions(fLinkOptions);

    event(events.connectAccount);

    return fastlinkModal.open();
  };

  const handleOpenNewFixYodleeConnection = async () => {
    if (!fixAccountConfig) {
      return;
    }

    const { accountId, update, refresh } = fixAccountConfig;

    handleConnectAccount(accountId, update, refresh);
  };

  const handleYodleeConnection = (accountId: number, update: boolean, refresh: boolean) => {
    setFixAccountConfig({
      accountId,
      update,
      refresh,
    });

    handleConnectAccount(accountId, update, refresh);
  };

  const clickElement = (dToggle: any) => {
    dToggle.current?.click();
  };

  const onChange = (option: string, date: any) => {
    if (option === 'start') {
      setAccountFilter({ ...accountFilter, fromDate: getDate(new Date(date)) });
    } else if (option === 'end') {
      setAccountFilter({ ...accountFilter, toDate: getDate(new Date(date)) });
    }
  };

  const handleIntervalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountFilter({ ...accountFilter, timeInterval: e.target.value });
  };

  const clearFilters = () => {
    resetFilter();
  };

  const closeSidebar = () => {
    setAccSetting(false);
    storage.clear('isNew');
  };

  const openNewActivityModal = () => {
    activityDetailsModal.open();
  };

  const closeRightNav = () => {
    setOpenRightNav(false);
  };

  const openAddCoinsModal = () => {
    addCoinsModal.open();
  };

  const openAddMetalsModal = () => {
    addMetalsModal.open();
  };

  const renderCharItem = (chartData: AccountChartItem[]) => {
    if (!chartData.length) {
      return <ChartSkeleton />;
    }

    return (
      <AccountBarGraph
        key={shortId()}
        data={chartData}
        curInterval=''
        currencySymbol={currencySymbol}
        mmCategory={accountDetail?.category?.mmCategory || ''}
      />
    );
  };

  const renderChart = () => {
    if (filterLoading) {
      return <ChartSkeleton />;
    }

    const chartItem = balanceData?.balances?.map((b) => ({
      ...b,
      value: isNumber(b.balance) ? +b.balance : 0,
    }));

    if (chartItem?.length) {
      return <div className='chartbox'>{renderCharItem(chartItem || [])}</div>;
    }

    return null;
  };

  const renderChartAmount = () => {
    const curBalanceAmount = balanceData?.balances?.find((balance) => balance.interval === 'Current')?.balance;

    return parseAmount(curBalanceAmount || 0, currencySymbol);
  };

  const hasChartData = balanceData?.balances?.length;

  const connectionStatus = accountDetail?.connectionStatus || EConnectionStatus.ERROR;

  const isAddPositionVisible: boolean =
    !!accountDetail?.isManual &&
    !accountDetail.isArchived &&
    tableType === ETableType.HOLDINGS &&
    accountDetail.category?.mmAccountType !== EMMAccountType.OTHER_ASSETS &&
    accountDetail.category?.mmAccountType !== EMMAccountType.CRYPTOCURRENCIES &&
    accountDetail.category?.mmAccountType !== EMMAccountType.PRECIOUS_METALS;

  const isRealEstateDetailsVisible =
    !!accountDetail?.isManual &&
    accountDetail.category?.mmAccountType === EMMAccountType.REAL_ESTATE &&
    accountDetail.category?.mmAccountSubType !== EMMAccountSubType.REAL_ESTATE_FUND &&
    accountDetail.category?.mmAccountSubType !== EMMAccountSubType.PERSONAL_PROPERTY;

  const isRefreshingState =
    connectionStatus === EConnectionStatus.WAIT || connectionStatus === EConnectionStatus.REFRESHING;

  if (refreshingAccount) {
    return <LoadingScreen onAccountFetching />;
  }

  const providerLastUpdated =
    accountDetail?.providerAccount?.dataset?.[0]?.lastUpdated?.toString() !== null
      ? 'Last updated ' + getRelativeDate(accountDetail?.providerAccount?.dataset?.[0]?.lastUpdated?.toString())
      : 'Not yet updated';

  const firstLoadError =
    accountDetail?.syncError &&
    accountDetail?.mmAccountProvider !== EProviderType.ZABO &&
    accountDetail?.mmAccountProvider !== EProviderType.SALT_EDGE_PARTNER &&
    accountDetail?.mmAccountProvider !== EProviderType.SALT_EDGE_GATEWAY &&
    accountDetail?.holdingsFetchedAt === null;

  const getHoldingLabel = (isAddLabel = false) => {
    const mapper: Record<string, string> = {
      Securities: 'Securities',
      Businesses: 'Businesses',
      Cryptocurrencies: 'Coins',
      Collectibles: 'Collectibles',
      NFTs: 'NFTs',
      'Stock Options': 'Grants',
      Startups: 'Startups',
    };

    const mapValue = mapper[accountDetail?.category?.mmAccountType || ''];
    const label = mapValue ? mapValue : isAddLabel ? 'Position' : 'Holdings';

    return isAddLabel ? 'Add ' + label : label;
  };

  const handleSaltedgeReconnect = async () => {
    if (accountDetail?.connectionStatus === EConnectionStatus.WAIT) return;

    const res = await reconnectSaltEdge(accountDetail?.id);

    if (res) {
      return window.open(res.connectUrl, '_self');
    }
  };

  const handleSaltedgeRefresh = async () => {
    const res = await refreshSaltEdge(accountDetail?.id);

    if (res) {
      return window.open(res.connectUrl, '_self');
    }
  };

  const refreshAccount = async () => {
    if (isRefreshingState) return;
    setLoading(true);

    if (isSaltEdgeAccount(accountDetail?.mmAccountProvider)) {
      handleSaltedgeRefresh();

      return setLoading(false);
    }

    if (isYodleeAccount(accountDetail?.mmAccountProvider)) {
      handleYodleeConnection(accountDetail?.id || 0, false, true);

      return setLoading(false);
    }
  };

  const handleFixConnection = async () => {
    if (isRefreshingState) return;
    setLoading(true);

    if (isYodleeAccount(accountDetail?.mmAccountProvider)) {
      handleYodleeConnection(accountDetail?.id || 0, true, false);

      return setLoading(false);
    }

    if (isSaltEdgeAccount(accountDetail?.mmAccountProvider)) {
      handleSaltedgeReconnect();

      return setLoading(false);
    }
  };

  const renderAccountStatus = () => {
    const hasWarningStatus = [EConnectionStatus.ATTENTION, EConnectionStatus.STALE].includes(connectionStatus);

    if (!accountDetail) {
      return <ProgressBar animated now={100} variant='success' className='mm-progress-bar' />;
    }

    if (accountDetail.isArchived) {
      return null;
    }

    if (connectionStatus === EConnectionStatus.ERROR) {
      return (
        <div className='connection-issue-container error'>
          <span className='connection-status-icon'>
            <ICONS.SUBSCRIPTION_WARNING />
          </span>
          <div className='connection-issue-left'>
            <div className='connection-label-container'>
              <span className='label'>Connection Lost</span>
              <span className='time'>{providerLastUpdated}</span>
            </div>
            <div className='connection-error-msg'>
              <span>Reauthorize your connection to continue syncing your account</span>
            </div>
            <div>
              <button
                type='button'
                className='mm-btn-animate mm-btn-white mm-btn-spinner'
                disabled={fastLinkLoading || loading}
                onClick={handleFixConnection}
              >
                {loading ? <CircularSpinner /> : null}
                <span>Fix Connection</span>
              </button>
            </div>
          </div>
        </div>
      );
    }

    if (isRefreshingState) {
      return (
        <div className='connection-issue-container success'>
          <span className='connection-status-icon'>
            <ICONS.SUBSCRIPTION_WARNING />
          </span>
          <div className='connection-issue-left'>
            <div className='connection-label-container'>
              <span className='label'>Refreshing</span>
              <span className='time'>{providerLastUpdated}</span>
            </div>
            <div className='connection-error-msg'>
              <span>
                Account refresh in progress. Refresh your screen to see the latest data. This can take several minutes.
              </span>
            </div>
          </div>
        </div>
      );
    }

    if (hasWarningStatus) {
      return (
        <div className='connection-issue-container warning'>
          <span className='connection-status-icon'>
            <ICONS.SUBSCRIPTION_WARNING />
          </span>
          <div className='connection-issue-left'>
            <div className='connection-label-container'>
              <span className='label'>Update Connection</span>
              <span className='time'>{providerLastUpdated}</span>
            </div>
            <div className='connection-error-msg'>
              {connectionStatus === 'STALE' ? (
                <span>Refresh your account now if you prefer not to wait until the next sync.</span>
              ) : (
                <span>Additional security information required to complete updating your account.</span>
              )}
            </div>
            <div>
              <button
                type='button'
                disabled={fastLinkLoading || loading}
                className='mm-btn-animate mm-btn-white mm-btn-spinner'
                onClick={refreshAccount}
              >
                {loading ? <CircularSpinner /> : null}
                <span>{connectionStatus === 'STALE' ? 'Refresh Now' : 'Fix Connection'}</span>
              </button>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  const renderStatusPopup = () => {
    if (accountDetail?.isManual) {
      return (
        <>
          <ICONS.CHECK_CIRCLE />
          <span className='manual'>Manual</span>
        </>
      );
    }

    if (connectionStatus === EConnectionStatus.GOOD) {
      return (
        <>
          <ICONS.CHECK_CIRCLE_GREEN />
          <span className='good'>Good</span>
        </>
      );
    }

    if (connectionStatus === EConnectionStatus.WAIT) {
      return (
        <>
          <ICONS.CHECK_CIRCLE_GREEN />
          <span className='good'>Refreshing</span>
        </>
      );
    }

    if ([EConnectionStatus.STALE, EConnectionStatus.ATTENTION, EConnectionStatus.WAIT].includes(connectionStatus)) {
      return (
        <div className='attention-section' onMouseEnter={() => setPopup(true)} onMouseLeave={() => setPopup(false)}>
          <ICONS.NEEDS_INFO />
          <span className='needsInfo'>Attention</span>
          {popup && (
            <Popup
              AccountDetails={accountDetail}
              handleConnectAccount={() => handleYodleeConnection(accountDetail?.id || 0, false, true)}
              connectionStatus={connectionStatus}
            />
          )}
        </div>
      );
    }

    if (accountDetail) {
      return (
        <div className='attention-section' onMouseEnter={() => setPopup(true)} onMouseLeave={() => setPopup(false)}>
          <ICONS.NOT_LINKED />
          <span className='attention'>Error</span>
          {popup && (
            <Popup
              AccountDetails={accountDetail}
              handleConnectAccount={() => handleYodleeConnection(accountDetail.id, true, false)}
              connectionStatus={connectionStatus}
            />
          )}
        </div>
      );
    }

    return null;
  };

  const isSaltEdgeWaitState =
    connectionStatus === EConnectionStatus.WAIT && isSaltEdgeAccount(accountDetail?.mmAccountProvider);

  const hasAppHeaderShadow =
    connectionStatus === EConnectionStatus.GOOD || accountDetail?.isArchived === true || isSaltEdgeWaitState;

  return (
    <div className='mm-setting'>
      <HelmetProvider>
        <Helmet>
          <title>Account Details | Money Minx</title>
        </Helmet>
      </HelmetProvider>
      <aside className='setting-aside' style={{ left: accSetting ? '0' : '-670px' }}>
        <AccountSettingsSideBar
          closeSidebar={closeSidebar}
          selectedAccount={accountDetail}
          handleReload={handleRefreshAll}
        />
      </aside>
      {accSetting && <div className='backdrop' onClick={closeSidebar} role='button' />}
      <AppHeader
        toggleLeftMenu={() => setOpenLeftNav(!openLeftNav)}
        toggleRightMenu={() => setOpenRightNav(!openRightNav)}
        open={openRightNav}
        shadow={hasAppHeaderShadow}
      />
      {renderAccountStatus()}
      {fastlinkModal.props.open ? (
        <FastLinkModal
          fastLinkModal={fastlinkModal}
          fastLinkOptions={fastLinkOptions}
          handleSuccess={handleConnectAccountSuccess}
          handleOpenNewConnection={handleOpenNewFixYodleeConnection}
        />
      ) : null}
      {!loading && accountDetail ? (
        <AccountSubNavigation
          AccountDetails={accountDetail}
          baseCurrency={baseCurrency}
          mmAccountType={mmAccountType}
          toggleBaseCurrency={() => setBaseCurrency(!baseCurrency)}
        />
      ) : null}
      <hr className='mt-0' />
      <AppSidebar openLeft={openLeftNav} openRight={openRightNav} />
      <div className='mm-slider-bg-overlay' onClick={closeRightNav} role='button' />
      {loading ? (
        <AccountDetailSkeleton />
      ) : (
        <div className='content-wrapper'>
          <div className='container'>
            <div className='mm-account'>
              <div className='mm-account__selection mb-3'>
                <div className='mm-account__selection--info mb-3'>
                  {isRefreshingAccount(accountDetail?.mmAccountProvider, accountDetail?.connectionStatus) ? (
                    <ICONS.REFRESH className='float-left mr-2 settings-gear-button' onClick={refreshAccount} />
                  ) : (
                    <MMToolTip
                      message={
                        accountDetail?.isManual
                          ? 'Manual accounts are always up to date.'
                          : isQrokaAccount
                          ? 'Forced refresh for crypto is coming soon.'
                          : 'Fix the connection first.'
                      }
                    >
                      <ICONS.REFRESH className='float-left mr-2 settings-gear-button-disabled' />
                    </MMToolTip>
                  )}
                  <ICONS.SETTINGS_GEAR
                    className='float-left mr-2 settings-gear-button'
                    onClick={() => setAccSetting(true)}
                  />
                  {accountDetail?.category?.mmCategory !== 'Liabilities' ? (
                    <PieChart
                      className='float-left mr-2 settings-gear-button'
                      onClick={() => classificationsModal.open()}
                    />
                  ) : null}
                  <ul>
                    <li>{accountDetail?.accountName}</li>
                    {accountDetail?.accountNumber ? <li>{accountDetail?.accountNumber.slice(4)}</li> : null}
                    <li>{accountDetail?.category?.mmCategory}</li>
                    <li>{accountDetail?.category?.mmAccountType}</li>
                    {accountDetail?.category?.mmAccountSubType && <li>{accountDetail?.category?.mmAccountSubType}</li>}
                    <li>{accountDetail?.accountDetails?.currency}</li>
                    <li>{accountDetail?.investingEntity?.name}</li>
                  </ul>
                </div>
                {accountDetail?.accountDetails ? (
                  <AccountMoreInfo
                    accountType={accountDetail?.category?.mmAccountType}
                    accountDetail={accountDetail?.accountDetails}
                    currencySymbol={currencySymbol}
                    isManual={accountDetail?.isManual}
                  />
                ) : null}
                <div className='mm-account__selection--filter'>
                  <AccountDetailFilter
                    onChange={onChange}
                    clearFilters={clearFilters}
                    clickElement={clickElement}
                    accountFilter={accountFilter}
                    handleIntervalChange={handleIntervalChange}
                  />
                  <div className='mm-account__selection--type'>
                    {accountDetail?.isArchived ? (
                      <>
                        <div className='closed-marker'>
                          <ClosedIcon />
                          <span className='closed'>Closed</span>
                        </div>
                      </>
                    ) : (
                      <>
                        {firstLoadError ? (
                          <div className='attention-section'>
                            <ICONS.NEEDS_INFO />
                            <span className='needsInfo'>Processing</span>
                          </div>
                        ) : (
                          renderStatusPopup()
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className='account-bar-sidecard'>
                <div
                  className={[
                    'col-lg-9 account-ct-box mb-40',
                    balanceData?.balances?.length === 0 ? 'ct-box-placeholder' : '',
                    firstLoadError ? 'sync-error' : '',
                  ].join(' ')}
                >
                  {firstLoadError ? (
                    <Placeholder type='syncError' />
                  ) : (
                    <>
                      {hasChartData ? (
                        <div className='graphbox'>
                          <ul>
                            {accountDetail?.category?.mmCategory === MMCategories.INVESTMENT_ASSETS && (
                              <li className='inv-data'>
                                <span className='graphbox-label'>Value</span>
                                <span className='graphbox-amount'>{renderChartAmount()}</span>
                              </li>
                            )}
                            {accountDetail?.category?.mmCategory === MMCategories.OTHER_ASSETS && (
                              <li className='other-data'>
                                <span className='graphbox-label'>Value</span>
                                <span className='graphbox-amount'>{renderChartAmount()}</span>
                              </li>
                            )}

                            {accountDetail?.category?.mmCategory === MMCategories.LIABILITIES && (
                              <li className='lty-data'>
                                <span className='graphbox-label'>Value</span>
                                <span className='graphbox-amount'>{renderChartAmount()}</span>
                              </li>
                            )}
                          </ul>

                          {renderChart()}
                        </div>
                      ) : (
                        <ChartSkeleton />
                      )}
                    </>
                  )}
                </div>

                {accountDetail?.category?.mmAccountType ? (
                  <AccountSideCard
                    balance={accountDetail.balance}
                    type={accountDetail.category.mmAccountType}
                    subType={accountDetail.category.mmAccountSubType}
                    accountDetails={accountDetail.accountDetails}
                    currencySymbol={currencySymbol}
                    id={accountDetail.id}
                    handleRefresh={handleRefresh}
                    handleValueClick={() => setAccSetting(true)}
                  />
                ) : null}
              </div>

              {firstLoadError ? null : (
                <>
                  <div className='d-flex justify-content-between flex-wrap'>
                    <div className='mm-plan-radios mb-4'>
                      {withHoldings ? (
                        <>
                          <input
                            type='radio'
                            id='mm-account-holding'
                            value='holdings'
                            name='mm-radio-holding-activity'
                            aria-checked='true'
                            checked={tableType === ETableType.HOLDINGS}
                            onChange={() => setTableType(ETableType.HOLDINGS)}
                          />
                          <label className='labels' htmlFor='mm-account-holding'>
                            {getHoldingLabel()}
                          </label>
                        </>
                      ) : (
                        <>
                          <input
                            type='radio'
                            id='mm-account-balance'
                            value='balance'
                            name='mm-radio-holding-activity'
                            aria-checked='true'
                            checked={tableType === ETableType.BALANCE}
                            onChange={() => setTableType(ETableType.BALANCE)}
                          />
                          <label className='labels' htmlFor='mm-account-balance'>
                            Balance
                          </label>
                        </>
                      )}

                      <input
                        type='radio'
                        id='mm-account-activity'
                        value='activity'
                        name='mm-radio-holding-activity'
                        aria-checked='false'
                        checked={tableType === ETableType.ACTIVITY}
                        onChange={(e) => setTableType(ETableType.ACTIVITY)}
                        className={'second'}
                      />
                      <label className='labels' htmlFor='mm-account-activity'>
                        Activity
                      </label>
                      <div className='mm-radio-bg' />
                    </div>

                    {isAddPositionVisible && (
                      <Button
                        className='mb-4 mm-account__btn mm-btn-primary mm-btn-animate'
                        onClick={addEditHoldingModal.open}
                      >
                        {getHoldingLabel(true)}
                      </Button>
                    )}

                    {accountDetail?.isManual &&
                      !accountDetail.isArchived &&
                      tableType === ETableType.HOLDINGS &&
                      accountDetail.category?.mmAccountType === 'Cryptocurrencies' && (
                        <Button
                          className='mb-4 mm-account__btn mm-btn-primary mm-btn-animate'
                          onClick={openAddCoinsModal}
                        >
                          Add Coins
                        </Button>
                      )}

                    {accountDetail?.isManual &&
                      !accountDetail.isArchived &&
                      tableType === ETableType.HOLDINGS &&
                      accountDetail.category?.mmAccountType === 'Precious Metals' && (
                        <Button
                          className='mb-4 mm-account__btn mm-btn-primary mm-btn-animate'
                          onClick={openAddMetalsModal}
                        >
                          Add Metals
                        </Button>
                      )}

                    {accountDetail?.isManual && tableType === ETableType.ACTIVITY && (
                      <Button
                        className='mb-4 mm-account__btn mm-btn-primary mm-btn-animate'
                        onClick={openNewActivityModal}
                      >
                        Add Activity
                      </Button>
                    )}
                  </div>
                  {
                    // FIXME: REMOVE EMPTY FUNCTION CALL
                  }
                  {accountHoldings && tableType === ETableType.HOLDINGS ? (
                    <AccountTable
                      holdingsData={accountHoldings.holdings}
                      openEditPositionModalFun={() => {}}
                      closeEditPositionModalFun={() => {}}
                      accountDetails={accountDetail}
                      currencySymbol={currencySymbol}
                      handleRefresh={() => {
                        handleRefresh('holdings');
                        handleRefresh('balance');
                      }}
                    />
                  ) : null}
                  {balanceData && tableType === ETableType.BALANCE ? (
                    <BalanceTable
                      balanceData={balanceData}
                      currencySymbol={currencySymbol}
                      account={accountDetail}
                      handleRefresh={() => handleRefresh('balance')}
                    />
                  ) : null}
                  {tableType === ETableType.ACTIVITY && (
                    <div className='mm-account-activity-block'>
                      <div className='d-flex align-items-center mb-4'>
                        {!isQrokaAccount && (
                          <>
                            <p className='mb-0'>
                              To properly calculate performance make sure that all withdrawals and deposits are
                              accurately tracked below as Cash Flow
                            </p>

                            <MMToolTip
                              placement='top'
                              message='Performance calculations are coming soon. To ensure proper performance returns please mark cash flow transactions properly.'
                            >
                              <ICONS.INFO className='mt-n1 ml-2' />
                            </MMToolTip>
                          </>
                        )}
                      </div>
                      {accountActivities && (
                        <ActivityTable
                          isQroka={isQrokaAccount}
                          transactionsData={accountActivities.transactions}
                          openEditActivityModalFun={() => {}}
                          closeEditActivityModalFun={() => {}}
                          currencySymbol={currencySymbol}
                          handleRefresh={() => handleRefresh('activities')}
                        />
                      )}
                    </div>
                  )}

                  {isRealEstateDetailsVisible && accountDetail?.id && (
                    <RealEstateDetails
                      accountId={accountDetail.id}
                      currencySymbol={currencySymbol}
                      accountDetails={accountDetail.accountDetails}
                      handleReload={handleRefreshAll}
                      isVisible={tableType === ETableType.BALANCE}
                    />
                  )}

                  <HoldingsDetailsModal
                    holdingsDetailsModal={holdingsDetailsModal}
                    accountId={accountDetail?.id}
                    closeNewPositionModal={() => {}}
                    currencySymbol={currencySymbol}
                    handleRefresh={() => handleRefresh('holdings')}
                  />

                  {addEditHoldingModal.props.open ? (
                    <AddEditHoldingModal
                      accountType={accountDetail?.category?.mmAccountType}
                      addEditModal={addEditHoldingModal}
                      handleRefresh={() => handleRefresh('holdings')}
                      accountId={accountDetail?.id}
                      currencySymbol={currencySymbol}
                    />
                  ) : null}

                  <ActivityDetailsModal
                    activityDetailsModal={activityDetailsModal}
                    accountId={accountDetail?.id}
                    closeNewActivityModal={() => {}}
                    currencySymbol={currencySymbol}
                    handleRefresh={() => handleRefresh('activities')}
                  />

                  <AddCoinsModal
                    addCoinsModal={addCoinsModal}
                    accountId={accountDetail?.id}
                    currencySymbol={currencySymbol}
                    handleRefresh={() => {
                      handleRefresh('holdings');
                      handleRefresh('balance');
                    }}
                  />

                  <AddMetalsModal
                    addMetalsModal={addMetalsModal}
                    accountId={accountDetail?.id}
                    currencySymbol={currencySymbol}
                    handleRefresh={() => {
                      handleRefresh('holdings');
                      handleRefresh('balance');
                    }}
                  />

                  {accountDetail && (
                    <ClassificationsModal
                      classificationsModal={classificationsModal}
                      accountDetails={accountDetail}
                      handleRefresh={() => handleRefresh('detail')}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {!loading && (
        <AppFooter
          showZillowDisclaimer={
            accountDetail
              ? accountDetail.accountDetails.useZestimate === true ||
                accountDetail.accountDetails.useZestimate === 'yes'
              : false
          }
        />
      )}
    </div>
  );
};

export default AccountDetail;
