import { useState, useEffect } from 'react';

import { getComparisonDate } from 'dashboard/dashboard.helper';
import { EIntervalOption, EPortfolioMoverType } from 'dashboard/dashboard.enum';
import { getPortfolioMoversAccounts, getPortfolioMoversHoldings } from 'api/request.api';
import { IPortfolioMoversAccountsResponse, IPortfolioMoversHoldingsResponse } from 'dashboard/dashboard.types';

const usePortfolioMovers = (interval: EIntervalOption, moverType: EPortfolioMoverType) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [portfolioMoversAccs, setPortfolioMoversAccs] = useState<IPortfolioMoversAccountsResponse | null>(null);
  const [portfolioMoversHoldings, setPortfolioMoversHoldings] = useState<IPortfolioMoversHoldingsResponse | null>(null);

  useEffect(() => {
    const fetchPortfolioMoversData = async () => {
      const params: Record<string, string | boolean> = {};

      if (interval === EIntervalOption.ALL) {
        params.lastHistory = true;
      } else {
        params.date = getComparisonDate(interval).toISOString();
      }

      setLoading(true);

      if (moverType === EPortfolioMoverType.ACCOUNTS) {
        const { data, error: accountsErr }: { data: IPortfolioMoversAccountsResponse; error: any } =
          await getPortfolioMoversAccounts(params);

        if (data && !accountsErr) {
          setPortfolioMoversAccs(data);
        }
      }

      if (moverType === EPortfolioMoverType.HOLDINGS) {
        const { data, error: holdingsErr }: { data: IPortfolioMoversHoldingsResponse; error: any } =
          await getPortfolioMoversHoldings(params);

        if (data && !holdingsErr) {
          setPortfolioMoversHoldings(data);
        }
      }

      setLoading(false);
    };

    fetchPortfolioMoversData();

    return () => {
      setPortfolioMoversAccs(null);
      setPortfolioMoversHoldings(null);
    };
  }, [interval, moverType]);

  return { loading, portfolioMoversAccs, portfolioMoversHoldings };
};

export default usePortfolioMovers;
