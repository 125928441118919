import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Dashboard from 'dashboard';
import { Insights } from 'insights';
import Login from 'auth/views/login';
import Setting from 'setting/setting';
import { NotFound } from 'website/views';
import AccountTypes from 'account-types';
import ReferRoute from 'refer/refer.route';
import IncomeRoute from 'income/income.route';
import useProfile from 'auth/hooks/useProfile';
import AccountRoute from 'account/account.route';
import LoadingScreen from 'common/loading-screen';
import NetworthRoute from 'networth/networth.route';
import { CommunityProfile, Community } from 'community';
import AllocationRoute from 'allocation/allocation.route';
import PerformanceRoute from 'performance/performance.route';
import useConnectionInfo from 'common/hooks/useConnectionInfo';
import ConnectAccountRedirect from 'auth/views/connect-account-redirect';
import StripeUpdatePaymentCancel from 'setting/pages/stripe-update-payment-cancel';
import StripeUpdatePaymentSuccess from 'setting/pages/stripe-update-payment-success';

import PrivateRoute from './app.private-route';
import { appRouteConstants } from './app-route.constant';
import { Subscription, StripeSuccess, StripeFailure, AccountSetting, SubscriptionReview } from './app.view';
import { AccountTypesProvider } from 'account-types/context/account-types.context';

const {
  account: { ACCOUNT, ACCOUNT_TYPES },
  settings: { SETTINGS },
  networth: { NET_WORTH },
  allocation: { ALLOCATION },
  performance: { PERFORMANCE },
  subscription: { SUBSCRIPTION, REVIEW },
  misc: { STRIPE_FAILURE, STRIPE_SUCCESS, UPDATE_PAYMENT_METHOD_SUCCESS, UPDATE_PAYMENT_METHOD_CANCEL },
  auth: { CONNECT_ACCOUNT, ACCOUNT_SETTING },
  community: { COMMUNITY, COMMUNITY_PROFILE },
  refer: { REFER },
  income: { INCOME },
  dashboard: { DASHBOARD },
} = appRouteConstants;

function AppRoute() {
  const { loading } = useProfile();
  useConnectionInfo();

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <AccountTypesProvider>
        <Insights />

        <Switch>
          <Route exact path={appRouteConstants.auth.LOGIN} component={Login} />
          <Route exact path={COMMUNITY_PROFILE} component={CommunityProfile} />
          <Route path={COMMUNITY} component={Community} />

          <PrivateRoute path={ACCOUNT} component={AccountRoute} />
          <PrivateRoute path={ALLOCATION} component={AllocationRoute} />
          <PrivateRoute exact path={REFER} component={ReferRoute} />

          <PrivateRoute exact path={SETTINGS} component={Setting} />
          <PrivateRoute exact path={SUBSCRIPTION} component={Subscription} />
          <PrivateRoute exact path={REVIEW} component={SubscriptionReview} />
          <PrivateRoute exact path={STRIPE_SUCCESS} component={StripeSuccess} />
          <PrivateRoute exact path={STRIPE_FAILURE} component={StripeFailure} />
          <PrivateRoute exact path={UPDATE_PAYMENT_METHOD_SUCCESS} component={StripeUpdatePaymentSuccess} />
          <PrivateRoute exact path={UPDATE_PAYMENT_METHOD_CANCEL} component={StripeUpdatePaymentCancel} />

          <Route exact path={CONNECT_ACCOUNT} component={ConnectAccountRedirect} />

          <PrivateRoute exact path={ACCOUNT_SETTING} component={AccountSetting} />

          <PrivateRoute exact path={NET_WORTH} component={NetworthRoute} />
          <PrivateRoute exact path={PERFORMANCE} component={PerformanceRoute} />
          <PrivateRoute exact path={INCOME} component={IncomeRoute} />
          <PrivateRoute exact path={DASHBOARD} component={Dashboard} />

          <PrivateRoute exact path={ACCOUNT_TYPES} component={AccountTypes} />

          <Route exact path='/404' component={NotFound} />
          <Redirect to='/404' />
        </Switch>
      </AccountTypesProvider>
    </>
  );
}

export default AppRoute;
