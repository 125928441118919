import React from 'react';

import { Formik } from 'formik';
import { Modal } from 'common/components/modal';
import withLoading from 'common/hoc/withLoading';
import { IDeleteEntityModal } from 'setting/setting.type';

const DeleteEntityModal: React.FC<IDeleteEntityModal> = ({
  loading,
  removeEntity,
  currentEntity,
  deleteEntityModal,
}) => {
  const title = 'Delete entity';

  return (
    <Modal {...deleteEntityModal.props} title={title} size='sm' canBeClosed>
      <div className='modal-wrapper add-edit-entity-modal-wrapper'>
        <Formik
          enableReinitialize
          initialValues={{ name: currentEntity?.name || '' }}
          onSubmit={async (_, action) => {
            if (currentEntity) {
              const { error } = await removeEntity(`${currentEntity.id}`);

              if (error) {
                action.setFieldError('name', error.message || 'Error occurred on deleting entity');

                return action.setSubmitting(false);
              }

              action.setSubmitting(false);
            }

            return deleteEntityModal.close();
          }}
        >
          {(props) => {
            return (
              <form onSubmit={props.handleSubmit}>
                {withLoading(
                  loading,
                  <div className='input-wrapper'>
                    <label className='form-subheading'>Description</label>
                    <div className='input-wrap'>
                      <input
                        readOnly
                        name='name'
                        onBlur={props.handleBlur}
                        value={props.values.name}
                        placeholder='Entity name'
                        onChange={props.handleChange}
                      />
                      {props.errors.name ? <div className='mt-2 feedback'>{props.errors.name}</div> : null}
                    </div>
                  </div>
                )}
                <div className='action-wrapper'>
                  <button
                    type='reset'
                    className='mm-btn-primary mm-btn-animate'
                    disabled={props.isSubmitting}
                    onClick={deleteEntityModal.close}
                  >
                    Cancel
                  </button>
                  <button type='submit' className='mm-btn-txt-delete' disabled={props.isSubmitting}>
                    Delete Entity
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default DeleteEntityModal;
