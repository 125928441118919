import React, { useState, useRef } from 'react';

import { Form } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import useRefer from 'refer/hooks/useRefer';
import useToast from 'common/hooks/useToast';
import InviteSteps from 'refer/inc/invite-steps';
import ReferredUsers from 'refer/inc/referred-users';
import { validateEmail } from 'common/helper/email.helper';
import CopyButton from 'common/components/buttons/copy-button';
import inviteFriendsImg from 'assets/images/refer/invite-friends.png';
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook-outline.svg';

import * as referConstants from '../refer.constants';
import ReferAFriendLayout from './refer-a-friend.layout';

const ReferAFriend: React.FC = () => {
  const { mmToast } = useToast();

  const [invites, setInvites] = useState<string[]>([]);
  const [emailText, setEmailText] = useState<string>('');
  const referralLinkRef = useRef<HTMLInputElement | null>(null);

  const { loading, inviteLink, referralLink, referredUsers } = useRefer(invites);

  const handleKeyDown = (e: any) => {
    if (e.key !== 'Enter') {
      return;
    }

    const isValidEmail = validateEmail(emailText);

    if (!isValidEmail) {
      return mmToast('Please enter a valid email address', { type: 'error' });
    }

    setInvites((prev) => [...prev, emailText]);
    setEmailText('');
  };

  const handleDeleteInvite = (index: number) => {
    const filteredInvites = invites.filter((_, idx) => idx !== index);
    setInvites(filteredInvites);
  };

  const renderInvites = () => {
    if (!invites.length) {
      return null;
    }

    return (
      <div className='d-flex flex-wrap'>
        {invites.map((invite, index) => (
          <div className='invite' key={invite}>
            <span>{invite}</span>
            <button onClick={() => handleDeleteInvite(index)}>x</button>
          </div>
        ))}
      </div>
    );
  };

  return (
    <ReferAFriendLayout>
      <HelmetProvider>
        <Helmet>
          <title>Refer a friend | Money Minx</title>
        </Helmet>
      </HelmetProvider>

      <section className='refer-container'>
        <div className='invite-friends row'>
          <div className='invite-friends__banner col-12 col-xl-6'>
            <img src={inviteFriendsImg} alt='Invite Friends' className='invite-friends__banner-img' />
            <div className='invite-friends__banner-text'>
              <h4>Invite Friends</h4>
              <span>Get a $10 Amazon Gift Card</span>
            </div>
          </div>

          <div className='invite-friends__form col-12 col-xl-6'>
            <div className='mb-5'>
              <h4>Send invites</h4>

              <div className='form-group d-flex flex-column flex-lg-row'>
                <Form.Control
                  type='email'
                  placeholder='Email Addresses'
                  value={emailText}
                  className='mb-3 mb-lg-0'
                  onChange={(e) => setEmailText(e.target.value)}
                  onKeyDown={handleKeyDown}
                />

                <a href={inviteLink} className='mm-btn-animate mm-btn-primary btn-preview ml-lg-3' role='button'>
                  Preview Invite
                </a>
              </div>

              {renderInvites()}
            </div>

            <div>
              <h4>Share referral link </h4>

              <div className='form-group d-flex flex-column flex-lg-row'>
                <div className='referral-link-container mb-3 mb-lg-0'>
                  <Form.Control type='text' value={referralLink} ref={referralLinkRef} readOnly />
                  <CopyButton elementRef={referralLinkRef} />
                </div>

                <FacebookShareButton
                  quote={referConstants.SOCIAL_INVITATION_LINK.replace(':REFERRAL_LINK', referralLink)}
                  url={referralLink}
                >
                  <span className='mm-btn-animate btn-share btn-facebook ml-lg-3 mb-3 mb-lg-0'>
                    <span>Share on Facebook</span>
                    <FacebookIcon />
                  </span>
                </FacebookShareButton>

                <TwitterShareButton
                  title={referConstants.SOCIAL_INVITATION_LINK.replace(':REFERRAL_LINK', referralLink)}
                  url={referralLink}
                  via='moneyminxapp'
                >
                  <span className='mm-btn-animate btn-share btn-twitter ml-lg-3'>
                    <span>Share on Twitter</span>
                    <TwitterIcon />
                  </span>
                </TwitterShareButton>
              </div>
            </div>
          </div>
        </div>

        <InviteSteps />

        <ReferredUsers users={referredUsers} loading={loading} />
      </section>
    </ReferAFriendLayout>
  );
};

export default ReferAFriend;
