import React from 'react';

import { getHoldingsDetails } from 'api/request.api';
import { IHoldingDetail } from 'account/holding.type';

const useHoldingDetail = (positionId?: number) => {
  const [loading, setLoading] = React.useState(false);
  const [holdingDetail, setHoldingDetail] = React.useState<IHoldingDetail>();

  React.useEffect(() => {
    (async () => {
      if (positionId) {
        setLoading(true);
        const { error, data } = await getHoldingsDetails(positionId + '');
        setLoading(false);

        if (!error) {
          setHoldingDetail(data);
        }
      }
    })();
  }, [positionId]);

  return { loading, holdingDetail };
};

export default useHoldingDetail;
