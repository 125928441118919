import { SettingType } from 'setting/setting.type';

export enum EAppActions {
  SET_SETTING = 'SET_SETTING',
  RESET_SETTING = 'RESET_SETTING',
  SET_FASTLINK_LOADING = 'SET_FASTLINK_LOADING',
  RESET_FASTLINK_LOADING = 'RESET_FASTLINK_LOADING',
}

export interface IAppAction {
  type: EAppActions;
  payload?: SettingType;
}

export const setFastlinkLoading = () => {
  return {
    type: EAppActions.SET_FASTLINK_LOADING,
  };
};

export const resetFastlinkLoading = () => {
  return {
    type: EAppActions.RESET_FASTLINK_LOADING,
  };
};

export const setSetting = (setting: SettingType) => {
  return {
    type: EAppActions.SET_SETTING,
    payload: setting,
  };
};

export const resetSetting = () => {
  return {
    type: EAppActions.RESET_SETTING,
  };
};
