export enum EAccountTypeAction {
  SET_ACCOUNT_TYPE = 'SET_ACCOUNT_TYPE',
  RESET_ACCOUNT_TYPE = 'RESET_ACCOUNT_TYPE',
  SET_FROM_DATE = 'SET_FROM_DATE',
  SET_TO_DATE = 'SET_TO_DATE',
  SET_TIME_INTERVAL = 'SET_TIME_INTERVAL',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
}

export enum ETableType {
  HOLDINGS = 'holdings',
  ACCOUNTS = 'accounts',
}
