const vestingSchedule = [
  {
    value: '1,m,0',
    name: '1 year, vests monthly',
  },
  {
    value: '4,m,0',
    name: '4 years, vests monthly',
  },
  {
    value: '4,m,1',
    name: '4 years, vests monthly, 1 year cliff',
  },
  {
    value: '5,m,0',
    name: '5 years, vests monthly',
  },
  {
    value: '5,m,1',
    name: '5 years, vests monthly, 1 year cliff',
  },
];

export default vestingSchedule;

/*
=======FORMAT========
1 year, vests monthly
"vestingYears": 1,
"vestingSchedule": "m",
"vestingCliffYear": 0,
*/
