import { useState, useEffect } from 'react';

import { EIntervalOption } from 'dashboard/dashboard.enum';
import { CurrencyOptions } from 'auth/enum/currency-options';
import { getComparisonDate } from 'dashboard/dashboard.helper';
import { getAccountAssets, getAccountAssetsHistory } from 'api/request.api';
import { IAsset, IAssetsResponse, IAssetsHistoryResponse } from 'dashboard/dashboard.types';

const useAssets = (interval: EIntervalOption) => {
  const [assets, setAssets] = useState<IAsset[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [historyLoading, setHistoryLoading] = useState<boolean>(false);
  const [currencyOption, setCurrencyOption] = useState<string>(CurrencyOptions.USD);
  const [assetsHistoryMap, setAssetsHistoryMap] = useState<{ [key: string]: number } | null>(null);

  useEffect(() => {
    const fetchAccountAssets = async () => {
      setLoading(true);
      const { data, error }: { data: IAssetsResponse; error: any } = await getAccountAssets();

      if (!error && data) {
        setCurrencyOption(data.currency);
        setAssets(data.assets);
      }

      setLoading(false);
    };

    fetchAccountAssets();
  }, []);

  useEffect(() => {
    const fetchAccountAssetsHistory = async () => {
      const params: Record<string, string | boolean> = {};

      if (interval === EIntervalOption.ALL) {
        params.lastHistory = true;
      } else {
        params.date = getComparisonDate(interval).toISOString();
      }

      setHistoryLoading(true);
      const { data, error }: { data: IAssetsHistoryResponse; error: any } = await getAccountAssetsHistory(params);

      if (!error && data) {
        const historyMap: Record<string, number> = {};

        data.assets.forEach(({ mmAccountType, total }) => {
          historyMap[mmAccountType] = total;
        });

        setAssetsHistoryMap(historyMap);
      }

      setHistoryLoading(false);
    };

    fetchAccountAssetsHistory();
  }, [interval]);

  return { loading, historyLoading, assets, assetsHistoryMap, currencyOption };
};

export default useAssets;
