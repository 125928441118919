import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

interface MMTooltipProps {
  name?: string;
  message: string;
  children: React.ReactElement;
  placement?: 'top' | 'right' | 'bottom' | 'left';
}

const MMToolTip: React.FC<MMTooltipProps> = ({ placement = 'bottom', message, children, name = '' }) => {
  return (
    <OverlayTrigger placement={placement} overlay={<Tooltip id={'tooltip-' + name}>{message}</Tooltip>}>
      {children}
    </OverlayTrigger>
  );
};

export default MMToolTip;
