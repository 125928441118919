import React from 'react';
import Skeleton from 'react-loading-skeleton';

const AddEditHoldingModalSkeleton = () => {
  return (
    <div className='add-edit-holding-skeleton'>
      <Skeleton width={600} height={45} count={4} />
    </div>
  );
};

export default AddEditHoldingModalSkeleton;
