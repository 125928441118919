import { getDateString } from 'common/moment.helper';

import { IFilter, IFilterState } from './filter.type';

export const getUpdatedFilter = (filters: IFilter[], newFilter: IFilter): IFilter[] => {
  const index = filters.findIndex((filter) => filter.id === newFilter.id);

  return [...filters.slice(0, index), newFilter, ...filters.slice(index + 1)];
};

export const listingFilters = (filter?: string) => {
  return filter ? filter.split(',') : [];
};

export const listingNumericFilters = (filter?: string) => {
  return filter ? filter.split(',').map((filterItem) => +filterItem) : [];
};

export const makeLocalFilter = (filter: IFilter) => {
  const filterObj: Partial<IFilterState> = {
    fCategories: listingFilters(filter.category),
    fTypes: listingFilters(filter.accountType),
    fEntities: listingNumericFilters(filter.investingEntityId),
    fLiquidity: listingFilters(filter.liquidity),
    fAccounts: listingNumericFilters(filter.accountId),
    fFromDate: filter.fromDate ? getDateString(filter.fromDate) : undefined,
    fToDate: filter.toDate ? getDateString(filter.toDate) : undefined,
    fTimeInterval: filter.timeInterval,
  };

  return filterObj;
};
