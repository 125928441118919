import React, { useCallback } from 'react';

import { useDispatch } from 'app/app.context';
import { YodleeError } from 'yodlee/yodlee.type';
import { resetFastlinkLoading } from 'app/app.actions';
import { Modal, ModalType } from 'common/components/modal';

interface IYodleeErrorModalProps {
  accProviderName?: string;
  yodleeErrorModal: ModalType;
  yodleeError: YodleeError | undefined;
  handleBaseModalClose: () => void;
  onSuccessClick: (error: YodleeError) => void;
}

const YodleeErrorModal: React.FC<IYodleeErrorModalProps> = ({
  accProviderName = '',
  yodleeError,
  yodleeErrorModal,
  onSuccessClick,
  handleBaseModalClose,
}) => {
  const dispatch = useDispatch();
  const getModalHeader = useCallback(
    (error: YodleeError): string => {
      switch (error) {
        case YodleeError.TECHNICAL_ERROR:
          return `Yodlee is currently experiencing an error with this provider. We've notified our engineering team and will let you know as soon as it is back up. In the meanwhile would you like to add a different account?`;

        case YodleeError.SESSION_ERROR:
          return `The Yodlee connection session expired. We apologize for the inconvenience. Please try adding this account one more time.`;

        case YodleeError.COOKIE_DISABLED:
          return `A connection with this provider requires that you have cookies enabled in your browser. Please enable them and try again.`;

        case YodleeError.DATA_RETRIEVAL_FAILED:
          return `We are unable to retrieve data from this account at this time. Please try again or contact us for support.`;

        case YodleeError.ACCOUNT_REFRESH_IN_PROGRESS:
          return `Account refresh is in progress. Please wait before trying again.`;

        case YodleeError.SOMETHING_WENT_WRONG:
          return `We are unable to retrieve data from this account at this time. Please try again or contact us for support.`;

        case YodleeError.USER_ACTION_NEEDED_AT_SITE:
          return `${accProviderName} has requested information from you. Visit the site to update your account and come back to Money Minx to try again.`;

        default:
          return '';
      }
    },
    [accProviderName]
  );

  const handleCancel = () => {
    yodleeErrorModal.close();
    dispatch(resetFastlinkLoading());

    return handleBaseModalClose();
  };

  if (!yodleeError) {
    return null;
  }

  return (
    <Modal {...yodleeErrorModal.props} title='Need help?' size='md' canBeClosed onClose={handleCancel}>
      <div className='modal-wrapper'>
        <p>{getModalHeader(yodleeError)}</p>
        <div className='modal-btn-wrapper mm-modal-two-buttons'>
          <button
            className='btn-outline-primary mm-btn-animate mr-sm-4 mb-2 mb-md-0'
            onClick={handleCancel}
            type='button'
          >
            Cancel
          </button>
          <button
            className='mm-btn-primary mm-btn-animate gist-open-chat'
            onClick={() => onSuccessClick(yodleeError)}
            type='button'
          >
            {yodleeError === YodleeError.TECHNICAL_ERROR ? 'Add Another Account' : 'Try Again'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default YodleeErrorModal;
