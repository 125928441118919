import React, { useEffect, useState } from 'react';
import { FormControl, Table, Button, Dropdown } from 'react-bootstrap';

import { gc } from 'common/interval-parser';
import useToast from 'common/hooks/useToast';
import classNames from 'common/classes.helper';
import { EProviderType } from 'auth/auth.types';
import { useModal } from 'common/components/modal';
import SortIcon from 'networth/components/sort-icon';
import { HoldingsModalTabs } from 'account/account.enum';
import { EMMAccountType } from 'account/enum/account-type';
import { fNumber, numberWithCommas } from 'common/number.helper';
import holdingTableField from 'account/helper/holding-table.helper';
import AddEditHoldingModal from 'account/components/add-edit-holding.modal';
import { ReactComponent as MoreVertical } from 'assets/icons/more-vertical.svg';
import useSortableData, { defaultDetailSortConfig } from 'common/hooks/useSortableData';
import { deleteHoldingByPosition, getHoldingsDetails, patchAccount } from 'api/request.api';
import DeleteConfirmationModal, { IDeleteConfirmationContent } from 'account/components/delete-confirmation.modal';

import AddCoinsModal from './add-coins.modal';
import AddMetalsModal from './add-metals.modal';
import HoldingsDetailsModal from './holdings-details.modal';
import { AccountHoldingsTableProps, AccountHoldingItem } from '../account.type';

const deleteContent: IDeleteConfirmationContent = {
  cancelText: 'Cancel',
  deleteText: 'Delete Position',
  message: 'Do you really want to delete this position? This process cannot be undone.',
};

export const AccountTable: React.FC<AccountHoldingsTableProps> = ({
  holdingsData,
  handleRefresh,
  currencySymbol,
  accountDetails,
  openEditPositionModalFun,
  closeEditPositionModalFun,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [positionId, setPositionId] = useState<number>();
  const [currentPrice, setCurrentPrice] = useState<string>('');
  const [holdingsDetails, setHoldingsDetails] = useState<any>();
  const [holdings, setHoldings] = useState<AccountHoldingItem[]>([]);
  const [activeTabName, setActiveTabName] = useState<string>(HoldingsModalTabs.DETAILS);

  useEffect(() => {
    if (accountDetails) {
      setCurrentPrice(accountDetails.accountDetails?.currentPrice || '');
    }
  }, [accountDetails]);

  const { mmToast } = useToast();
  const addCoinsModal = useModal();
  const addMetalsModal = useModal();
  const holdingsDetailsModal = useModal();
  const addEditHoldingModal = useModal();
  const deleteConfirmationModal = useModal();

  useEffect(() => {
    setHoldings(holdingsData);
  }, [holdingsData]);

  const fetchHoldingsDetails = async (positionId: string, tabNum: string) => {
    const { data, error } = await getHoldingsDetails(positionId);
    if (!error) {
      setHoldingsDetails(data);
      setActiveTabName(tabNum);
    }
  };

  const handleOpenEditHoldingModal = (posId: number) => {
    setPositionId(posId);

    return addEditHoldingModal.open();
  };

  const openEditPositionModal = async (positionId: number, activeTab: string) => {
    await fetchHoldingsDetails(positionId.toString(), activeTab);

    if (activeTab === 'delete') {
      return deleteConfirmationModal.open();
    }

    holdingsDetailsModal.open();
    openEditPositionModalFun();
  };

  const openEditCoinsModal = () => {
    addCoinsModal.open();
  };

  const openEditMetalsModal = () => addMetalsModal.open();

  const holdingTable = holdingTableField(accountDetails?.category?.mmAccountType);

  const handleDeleteByPosition = async (holdingId: string) => {
    if (holdingId) {
      const { error } = await deleteHoldingByPosition(holdingId);

      if (error) {
        mmToast('Error occurred on deleting holding by position', { type: 'error' });
      } else {
        handleRefresh();
        deleteConfirmationModal.close();
      }

      return deleteConfirmationModal.close();
    }

    return deleteConfirmationModal.close();
  };

  const saveCurrentPrice = async () => {
    if (accountDetails) {
      setLoading(true);
      const res = await patchAccount(accountDetails.id.toString(), { currentPrice: parseFloat(currentPrice) });
      handleRefresh();

      if (res?.error) {
        return mmToast('Error Occurred on saving current price', { type: 'error' });
      }

      setLoading(false);

      mmToast('Successfully updated current price', { type: 'success' });
    }
  };

  const { items: sortedHoldings, sortConfig, requestSort } = useSortableData(holdings || [], defaultDetailSortConfig);

  const renderSortableHeader = (sortKey: keyof AccountHoldingItem, label: string, cn = 'hide-type', show = true) => {
    if (show) {
      return (
        <th className={classNames(cn, 'table-header__item')} onClick={() => requestSort({ key: sortKey })}>
          {label}
          <SortIcon sortConfig={sortConfig} sortKey={sortKey} />
        </th>
      );
    }
    return null;
  };

  const isZaboAccount = accountDetails?.mmAccountProvider === EProviderType.ZABO;
  const isCryptoAccount =
    accountDetails?.category?.mmAccountType === EMMAccountType.CRYPTOCURRENCIES &&
    accountDetails.isManual &&
    !accountDetails.isArchived;
  const isPreciousMetalAccount =
    accountDetails?.category?.mmAccountType === EMMAccountType.PRECIOUS_METALS &&
    !accountDetails.isArchived &&
    accountDetails.isManual;
  const isStockOptionsAccount =
    accountDetails?.category?.mmAccountType === EMMAccountType.STOCK_OPTIONS && !accountDetails.isArchived;

  const renderEditOption = (id: number) => {
    if (isZaboAccount) {
      return <Dropdown.Item onClick={() => handleOpenEditHoldingModal(id)}>Edit</Dropdown.Item>;
    }

    if (isCryptoAccount) {
      return <Dropdown.Item onClick={() => openEditCoinsModal()}>Edit</Dropdown.Item>;
    }

    if (isPreciousMetalAccount) {
      return <Dropdown.Item onClick={() => openEditMetalsModal()}>Edit</Dropdown.Item>;
    }

    return <Dropdown.Item onClick={() => handleOpenEditHoldingModal(id)}>Edit</Dropdown.Item>;
  };

  const renderBalanceOption = (id: number) => {
    if (!isCryptoAccount && !isPreciousMetalAccount && !isStockOptionsAccount) {
      return (
        <Dropdown.Item onClick={() => openEditPositionModal(id, HoldingsModalTabs.MONTHLYVALUES)}>
          Balances
        </Dropdown.Item>
      );
    }

    return null;
  };

  return (
    <section>
      {holdings?.length > 0 ? (
        <div className='row mb-40'>
          <div className='col-12'>
            <div className='ct-box'>
              {isStockOptionsAccount && (
                <div className='current-price-section'>
                  <span className='label'>Current Price</span>
                  <div className='form-field-group'>
                    <FormControl type='number' value={currentPrice} onChange={(e) => setCurrentPrice(e.target.value)} />
                    <span className='input-add-on'>{currencySymbol}</span>
                  </div>
                  <Button className='mm-btn-primary mm-btn-animate' onClick={saveCurrentPrice}>
                    {loading ? (
                      <>
                        <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
                        <span className='ml-1'>Saving...</span>
                      </>
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                </div>
              )}
              <div className='table-holder'>
                <Table className='tb-responsive account balance-table table-account-xls' id='table-net-xls'>
                  <thead>
                    <tr>
                      {holdingTable.get()?.map((formFiled, key) => {
                        const fieldName = formFiled.name as keyof AccountHoldingItem;

                        return (
                          <React.Fragment key={key}>
                            {renderSortableHeader(fieldName, formFiled.label, key === 0 ? 's-hide' : 'hide-type')}
                          </React.Fragment>
                        );
                      })}

                      {holdings?.[0]?.intervalValues.map((item: any, idx: number) => (
                        <th
                          key={idx}
                          className={classNames(gc(item.interval), 'table-header__item')}
                          onClick={() => requestSort({ key: item.interval, isDetails: true })}
                        >
                          {item.interval}
                          <SortIcon sortConfig={sortConfig} sortKey={item.interval} />
                        </th>
                      ))}
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {sortedHoldings?.length > 0 &&
                      sortedHoldings.map((item, index) => (
                        <tr key={index}>
                          {holdingTable.get()?.map((formField, idx) => {
                            const value = item[formField.name as keyof AccountHoldingItem];
                            const isDescription = formField.name === 'description';

                            if (idx === 0) {
                              return (
                                <td
                                  className={classNames(isDescription ? '' : 'small')}
                                  key={formField.name + index + idx}
                                >
                                  <span>
                                    {!!item.logo && (
                                      <img src={item.logo} alt={item.description} className='gecko-coin__logo' />
                                    )}
                                    {value}
                                  </span>
                                </td>
                              );
                            }

                            return (
                              <td key={formField.name + index + idx}>
                                <span>{formField.label}</span>
                                {holdingTable.formatValue(formField.name, value, currencySymbol)}
                                {holdingTable.isMetalWeight(formField.name) ? ` ${item.unit}` : null}
                              </td>
                            );
                          })}

                          {item.intervalValues.map((ins: any, i: number) => (
                            <td
                              key={i}
                              className={[
                                ins.type === `projection` && `projection holdings-table`,
                                gc(ins.interval),
                              ].join(' ')}
                            >
                              <span className={gc(ins.interval)}>{ins.interval}</span>
                              {ins.value || ins.value === 0 ? currencySymbol : ''}
                              {ins.value || ins.value === 0 ? numberWithCommas(fNumber(ins.value, 2)) : '--'}
                            </td>
                          ))}

                          {accountDetails?.isArchived && accountDetails.isManual ? (
                            <></>
                          ) : (
                            <td className='more-icon-dropdown'>
                              <Dropdown>
                                <Dropdown.Toggle variant='light' className='bg-white border-0 p-0 no-arrow'>
                                  <MoreVertical />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {renderEditOption(item.id)}
                                  {renderBalanceOption(item.id)}

                                  <Dropdown.Item
                                    onClick={() => openEditPositionModal(item.id, HoldingsModalTabs.CLASSIFICATIONS)}
                                  >
                                    Classifications
                                  </Dropdown.Item>

                                  {item.mmHoldingType === 'MANUAL' && (
                                    <Dropdown.Item
                                      onClick={() => openEditPositionModal(item.id, HoldingsModalTabs.DELETE)}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <span className='no-data'>No holdings found</span>
      )}

      {holdingsDetails && (
        <>
          <HoldingsDetailsModal
            holdingsDetailsModal={holdingsDetailsModal}
            holdingsDetails={holdingsDetails}
            closeEditPositionModal={closeEditPositionModalFun}
            currencySymbol={currencySymbol}
            handleRefresh={handleRefresh}
            activeTabName={activeTabName}
          />
          <DeleteConfirmationModal
            deleteConfirmationModal={deleteConfirmationModal}
            onSuccess={() => handleDeleteByPosition(holdingsDetails.id)}
            content={deleteContent}
          />
        </>
      )}

      <AddCoinsModal
        addCoinsModal={addCoinsModal}
        accountId={accountDetails?.id}
        currencySymbol={currencySymbol}
        isEditMode
        handleRefresh={handleRefresh}
      />

      <AddMetalsModal
        addMetalsModal={addMetalsModal}
        accountId={accountDetails?.id}
        currencySymbol={currencySymbol}
        isEditMode
        handleRefresh={handleRefresh}
      />

      {addEditHoldingModal.props.open ? (
        <AddEditHoldingModal
          positionId={positionId}
          handleRefresh={handleRefresh}
          accountId={accountDetails?.id}
          currencySymbol={currencySymbol}
          addEditModal={addEditHoldingModal}
          accountType={accountDetails?.category?.mmAccountType}
        />
      ) : null}
    </section>
  );
};

export default AccountTable;
