import { useState, useMemo, useEffect } from 'react';

import { IDashboardAccountWithStatus } from 'dashboard/dashboard.types';

const useSortedAccounts = (accounts: IDashboardAccountWithStatus[], order: 'DESC' | 'ASC' = 'DESC') => {
  const [sortOrder, setSortOrder] = useState<'DESC' | 'ASC'>(order);

  useEffect(() => {
    setSortOrder(order);
  }, [order]);

  const sortedAccounts = useMemo(() => {
    const sortingAccounts = [...accounts];

    return sortingAccounts.sort((a: IDashboardAccountWithStatus, b: IDashboardAccountWithStatus) => {
      const firstAccountBalance = a.balance || 0;
      const secondAccountBalance = b.balance || 0;

      if (firstAccountBalance < secondAccountBalance) {
        return sortOrder === 'ASC' ? -1 : 1;
      }

      if (firstAccountBalance > secondAccountBalance) {
        return sortOrder === 'ASC' ? 1 : -1;
      }

      return 0;
    });
  }, [accounts, sortOrder]);

  const requestSort = () => {
    if (sortOrder === 'DESC') {
      return setSortOrder('ASC');
    }

    return setSortOrder('DESC');
  };

  return { sortedAccounts, sortOrder, requestSort };
};

export default useSortedAccounts;
