import { useEffect } from 'react';

import {
  addFilter,
  setFilters,
  bulkUpdate,
  updateFilter,
  setProcessing,
  resetProcessing,
  removeContextFilter,
} from 'filter/filter.action';
import { IFilter } from 'filter/filter.type';
import { makeLocalFilter } from 'filter/filter.helper';
import { useFilterDispatch } from 'filter/filter.context';
import { deleteFilter, getScreenFilter, patchFilter, postFilter } from 'api/request.api';

type Screen = 'net-worth' | 'performance';

const useFilters = (screen: Screen = 'net-worth') => {
  const dispatch = useFilterDispatch();

  useEffect(() => {
    (async () => {
      dispatch(setProcessing());
      const { data, error } = await getScreenFilter({ screen });

      dispatch(resetProcessing());

      if (!error && data) {
        const filters: IFilter[] = data;
        const defaultFilter = filters.find((filter) => !!filter.default);

        if (defaultFilter) {
          dispatch(bulkUpdate(makeLocalFilter(defaultFilter)));
        }

        return dispatch(setFilters(data));
      }
    })();
  }, [dispatch, screen]);

  const saveFilter = async (filterPayload: IFilter) => {
    const isEdit = !!filterPayload.id;

    dispatch(setProcessing());
    const { data, error } =
      isEdit && filterPayload.id
        ? await patchFilter(filterPayload, filterPayload.id.toString())
        : await postFilter(filterPayload);

    dispatch(resetProcessing());

    if (!error && data) {
      if (isEdit) {
        return dispatch(updateFilter(data));
      }

      return dispatch(addFilter(data));
    }
  };

  const selectFilter = (filter: IFilter) => {
    return dispatch(bulkUpdate(makeLocalFilter(filter)));
  };

  const removeFilter = async (filterId: string) => {
    dispatch(setProcessing());
    const { error } = await deleteFilter(filterId);

    dispatch(resetProcessing());

    if (!error) {
      dispatch(removeContextFilter(filterId));
    }
  };

  return { saveFilter, selectFilter, removeFilter };
};

export default useFilters;
