// tslint:disable: react-a11y-input-elements

import Form from 'react-bootstrap/Form';
import ReactDatePicker from 'react-datepicker';
import FormGroup from 'react-bootstrap/FormGroup';
import React, { useState, useEffect } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import { useHistory, useLocation } from 'react-router-dom';

import {
  patchAccount,
  deleteAccount,
  getZestimateValue,
  patchCompleteProfile,
  getEstimatedMonthlyPayment,
} from 'api/request.api';
import {
  formFieldTopSectionList,
  formFieldLastSectionList,
  formFieldSecondSectionList,
} from 'auth/data/account-settings.data';
import moment from 'moment';
import omit from 'lodash/omit';
import { Formik } from 'formik';
import debounce from 'lodash/debounce';
import useToast from 'common/hooks/useToast';
import { logger } from 'common/logger.helper';
import classNames from 'common/classes.helper';
import { TChangeEvent } from 'common/dom.types';
import { useAuthState } from 'auth/auth.context';
import MMToolTip from 'common/components/tooltip';
import { isUSAText } from 'common/country.helper';
import { useModal } from 'common/components/modal';
import useSettings from 'setting/hooks/useSettings';
import { enumerateStr } from 'common/common-helper';
import useFormFields from 'auth/hooks/useFormFields';
import { StringKeyObject } from 'common/common.types';
import useAccountType from 'auth/hooks/useAccountType';
import useSearchParam from 'auth/hooks/useSearchParam';
import { camelCase } from 'common/helper/string.helper';
import useAccountFilter from 'auth/hooks/useAccountFilter';
import { appRouteConstants } from 'app/app-route.constant';
import { getCurrencySymbol } from 'common/currency-helper';
import { EMMAccountType } from 'account/enum/account-type';
import useAccountSubtype from 'auth/hooks/useAccountSubtype';
import { loginValidationSchema } from 'auth/auth.validation';
import { addMonthToDate, getUTC } from 'common/moment.helper';
import { LiquidityOptions } from 'auth/enum/liquidity-options';
import zillowIcon from 'assets/images/account/zillow_icon.svg';
import { StripeSubscriptionStatus } from 'setting/setting.enum';
import { numberWithCommas, fNumber } from 'common/number.helper';
import useInvestingEntity from 'setting/hooks/useInvestingEntity';
import CurrencyInput from 'common/components/input/currency.input';
import { getInitialAccountSettingFormData } from 'auth/auth.helper';
import useCurrentSubscription from 'auth/hooks/useCurrentSubscription';
import zillowProvided from 'assets/images/account/zillow_provided.svg';
import CircularSpinner from 'common/components/spinner/circular-spinner';
import { ReactComponent as InfoIcon } from 'assets/images/signup/info.svg';
import { CurrencyOptions, CurrencySymbols } from 'auth/enum/currency-options';
import { MMSelectInput, SelectInput } from 'common/components/input/select.input';
import { EmployerMatchLimitOptions } from 'auth/enum/employer-match-limit-options';
import {
  MMCategories,
  PropertyType,
  InvestmentType,
  ECompoundPeriod,
  InvestmentRound,
  ELocalPaymentFrequency,
} from 'auth/auth.enum';
import { Account, EProviderType, IAccountCategory, IAccountDetails, TPartialFormKeys } from 'auth/auth.types';

import DeleteAccountModal, { defaultDeleteAccountContent } from './delete-account.modal';

interface Props {
  currentAccount?: Account;
  handleReload?: () => void;
  closeSidebar?: () => void;
  isFromAccount?: boolean;
}

const AccountSettingForm: React.FC<Props> = ({ currentAccount, handleReload, closeSidebar, isFromAccount }) => {
  const history = useHistory();
  const location = useLocation();
  const { mmToast } = useToast();
  const { accounts } = useAuthState();
  const [accountType, setAccountType] = useState('');
  const [accountSubtype, setAccountSubtype] = useState('');
  const { currentSubscription } = useCurrentSubscription();
  const { data: settingsData } = useSettings();

  const isManual = currentAccount?.isManual;

  const { investingEntities } = useInvestingEntity();
  const { loading: fetchingAccountType, data: accountTypes, error } = useAccountType(isManual);
  const { error: filterError } = useAccountFilter(accountType, accountSubtype);
  const [deleteAccountContent, setDeleteAccountContent] = useState(defaultDeleteAccountContent);
  const { subType: accountSubTypes, error: subTypeError } = useAccountSubtype(accountType, isManual);

  const deleteAccountModal = useModal();

  const from = useSearchParam('from');
  const isFromConnectAccount = 'connectAccount' === from;

  const [zestimateValue, setZestimateValue] = useState<number | null | undefined>(undefined);
  const currencySymbol = getCurrencySymbol(settingsData?.currency || CurrencySymbols.USD);

  const { getFieldTitle, hasFormField } = useFormFields(
    accountType || (currentAccount?.category?.mmAccountType as any) || 'cash'
  );

  const isFreeUser =
    currentSubscription?.subscriptionStatus !== StripeSubscriptionStatus.TRIALING &&
    currentSubscription?.subscriptionStatus !== StripeSubscriptionStatus.ACTIVE;

  /**
   * Set account type and account subtype
   * On current account changes
   */
  useEffect(() => {
    if (currentAccount) {
      setAccountType(currentAccount.category?.mmAccountType || '');
      setAccountSubtype(currentAccount.category?.mmAccountSubType || '');
      setZestimateValue(undefined);
    }
  }, [currentAccount]);

  const hasError = error || subTypeError || filterError;

  const isLoading = fetchingAccountType;

  if (hasError) {
    mmToast(`Error occurred [Account Setting form] [Account type] | [subtype] | [filter Error]`, { type: 'error' });
  }

  if (isLoading) {
    return <CircularSpinner />;
  }

  const hasAccountSubType = accountSubTypes.some(Boolean);

  const isLastAccount = (): boolean => {
    if (accounts && currentAccount) {
      const { length, [length - 1]: last } = accounts;

      if (last.id === currentAccount.id) {
        return true;
      }
    }

    return false;
  };

  const deleteAccountById = async () => {
    if (currentAccount?.id) {
      const { error: delError } = await deleteAccount(currentAccount.id.toString());
      if (!delError) {
        mmToast('Delete Success', { type: 'success' });

        return history.push('/dashboard');
      }

      return mmToast('Delete Failed', { type: 'error' });
    }
  };

  const initDeleteAccount = () => {
    if (
      currentAccount?.mmAccountProvider === EProviderType.SALT_EDGE_PARTNER ||
      currentAccount?.mmAccountProvider === EProviderType.SALT_EDGE_GATEWAY
    ) {
      setDeleteAccountContent({
        ...deleteAccountContent,
        content: (
          <div>
            <p>Do you really want to delete this account? This process cannot be undone.</p>
            <p className='text--redAlert'>Deleting this account will also delete all accounts for this provider</p>
          </div>
        ),
      });

      return deleteAccountModal.open();
    }

    setDeleteAccountContent(defaultDeleteAccountContent);

    return deleteAccountModal.open();
  };

  return (
    <Formik
      initialValues={getInitialAccountSettingFormData(currentAccount)}
      enableReinitialize
      validationSchema={loginValidationSchema}
      onSubmit={async (values) => {
        const mapping: StringKeyObject = {
          yes: true,
          no: false,
        };

        const accountId = currentAccount?.id;

        if (!accountId) {
          return;
        }

        const type = camelCase(accountType || currentAccount?.category?.mmAccountType || '');

        const isCryptoOrMetals =
          values.mmAccountType === EMMAccountType.PRECIOUS_METALS ||
          values.mmAccountType === EMMAccountType.CRYPTOCURRENCIES;

        values.currency = isCryptoOrMetals ? CurrencyOptions.USD : values.currency;

        if ('deposits' === type) {
          values.maturityDate = addMonthToDate(Number(values.term), values.investedDate);
        }

        if ('website' === type) {
          values.website = values.accountName;
        }

        if ('realEstate' === type) {
          values.accountName = values.streetAddress;
        }

        if ('domainNames' === type) {
          values.website = values.accountName;
        }

        // show as investment assets
        values.mmCategory = MMCategories.INVESTMENT_ASSETS;

        // FIXME: till backend is ready to omit.
        values = omit(values, 'amountDue');

        if (!values.showAsInvestmentAsset) {
          values.mmCategory = MMCategories.OTHER_ASSETS;
        }

        const isLiabilities = ['mortgages', 'creditCards', 'loans'].includes(type);

        if (isLiabilities) {
          values.mmCategory = MMCategories.LIABILITIES;
        }

        values = { ...values, investingEntityId: values.investingEntityId ? +values.investingEntityId : 0 };

        let data: any = {
          calculatedEntity:
            values.ownEstimate && values.principalBalance ? +values.ownEstimate - +values.principalBalance : '',
        };

        const dateKeys: TPartialFormKeys[] = ['maturityDate', 'investedDate', 'originationDate', 'businessStartDate'];

        dateKeys.forEach((dateKey) => {
          const dateValue = values[dateKey];

          if (dateValue) {
            data = { ...data, [dateKey]: getUTC(dateValue) };
          }
        });

        Object.keys(values).forEach((key: any) => {
          const value = (values as any)[key];

          if (value === 'yes' || value === 'no') {
            data = { ...data, [key]: mapping[value] };
            return;
          }

          if (value === '') {
            data = { ...data, [key]: undefined };
            return;
          }

          data = { ...data, [key]: value };
        });

        // For Maturity date
        if (!!values.originationDate && !!values.term) {
          const maturityDate = addMonthToDate(values.term, values.originationDate);

          data = { ...data, maturityDate: getUTC(maturityDate) };
        }

        const res = await patchAccount(`${accountId}`, data);
        if (res?.error) {
          return mmToast('Error Occurred on patching error [Account settings form]', { type: 'error' });
        }

        mmToast('Successfully updated', { type: 'success' });

        if (isFromAccount) {
          closeSidebar?.();

          return handleReload?.();
        } else {
          if (isLastAccount()) {
            const { error: err } = await patchCompleteProfile();
            if (err) {
              return mmToast('Error on complete patch profile', { type: 'error' });
            }

            location.pathname = appRouteConstants.dashboard.DASHBOARD;
            location.search = 'from=accountSettings';

            location.state = {
              isFromConnectAccount,
            };

            return history.push(location);
          }

          return handleReload?.();
        }
      }}
    >
      {(props) => {
        const { setFieldValue, values, handleChange, setValues } = props;

        const onChange = (e: TChangeEvent) => {
          const name = e.target.name;
          const value = e.target.value;
          const type = e.target.type;

          if ('number' === type) {
            if (value) {
              return setValues({ ...values, [name]: +value });
            }

            return setValues({ ...values, [name]: undefined });
          }

          handleChange(e);
        };

        const handleAccountChange = (e: React.ChangeEvent<any>) => {
          let newValues: Partial<IAccountDetails & IAccountCategory & Account> = { ...values };

          if (e.target.value === 'Real Estate') {
            newValues = { ...newValues, useZestimate: 'yes' };
          } else {
            newValues = omit(newValues, 'useZestimate');
            setValues(newValues);
          }

          setAccountType(e.target.value);
          setAccountSubtype('');

          setValues({ ...newValues, [e.target.name]: e.target.value });
        };

        const handleSelectChange = (e: React.ChangeEvent<any>) => {
          const name: string = e.target.name;
          let value = e.target.value;
          const numberFields: string[] = ['associatedRealEstate', 'associatedMortgage', 'associatedLoan'];
          value = numberFields.includes(name) ? +value : value;

          return setValues({ ...values, [name]: value });
        };

        const getInvestingEntityData = () => {
          if (investingEntities) {
            return investingEntities.map((entity) => ({
              name: entity.name,
              value: `${entity.id}`,
            }));
          }

          return [
            {
              name: '',
              value: '',
            },
          ];
        };

        const getSelectedEntity = () => {
          if (investingEntities) {
            return investingEntities.find((entity) => +entity.id === Number(values?.investingEntityId))?.name || '';
          }

          return '';
        };

        const hasBooleanTrue = (field: TPartialFormKeys) => values[field] === true || 'yes' === values[field];

        const fetchZestimateValue = async () => {
          try {
            const { streetAddress, city, state, zipCode } = values;

            let address = '';
            address = streetAddress ? address + streetAddress : address;
            address = city ? address + ' ' + city : address;
            address = state ? address + ' ' + state : address;
            address = zipCode ? address + ' ' + zipCode : address;

            const { data: zillowData } = await getZestimateValue(encodeURIComponent(address));

            if (!zillowData) {
              setZestimateValue(null);
              return;
            }

            setZestimateValue(zillowData.zestimate || null);
          } catch (err) {
            logger.log('Could not fetch zillow value', err);
          }
        };

        const calculateEstimatedMonthlyPayments = debounce(
          async (name: keyof Partial<IAccountDetails & IAccountCategory & Account>, value: any) => {
            try {
              const currentValues: Partial<IAccountDetails & IAccountCategory & Account> = { ...values, [name]: value };

              const { term, originalLoanAmount, interestRate, frequency, compoundPeriod, amortizationPeriod } =
                currentValues;

              // FIXME: INCLUDE ALL REQUIRED FIELDS HERE
              if (!term || !originalLoanAmount || !interestRate) {
                return;
              }

              const { data } = await getEstimatedMonthlyPayment({
                term,
                frequency,
                interestRate,
                compoundPeriod,
                originalLoanAmount,
                amortizationPeriod,
              });

              if (!!data) {
                setFieldValue('amountDue', data.amountDue);
              }
            } catch (err) {
              logger.log('Could not fetch estimated monthly payment value', err);
            }
          },
          500
        );

        /**
         * Form fields Listed Here
         */
        const accountTypeElem = (
          <li>
            <span className='form-subheading'>{getFieldTitle('mmAccountType')}</span>
            <SelectInput
              args={accountTypes}
              onChange={handleAccountChange}
              value={values.mmAccountType ?? ''}
              name='mmAccountType'
            />
          </li>
        );

        const accountSubTypeElem = (
          <li className={hasAccountSubType ? '' : 'hidden'}>
            <div className='account-list-content'>
              <span className='form-subheading'>{getFieldTitle('mmAccountSubType')}</span>
              <SelectInput
                args={accountSubTypes}
                onChange={handleSelectChange}
                value={values.mmAccountSubType ?? ''}
                name='mmAccountSubType'
              />
            </div>
          </li>
        );

        const accountNameElem = (
          <li className='w-100'>
            <span className='form-subheading'>{getFieldTitle('accountName')}</span>
            <input
              type='text'
              className='w-100 mb-4'
              onChange={onChange}
              value={values.accountName ?? ''}
              name='accountName'
            />
          </li>
        );

        const entityNameElem = (
          <li className='mm-form-row-double'>
            <Form.Group>
              <Form.Label className='form-subheading'>
                {getFieldTitle('investingEntityId')}
                <MMToolTip
                  placement='top'
                  message='You can update the list of investing entities in the settings screen.'
                >
                  <InfoIcon />
                </MMToolTip>
              </Form.Label>
              <MMSelectInput
                data={getInvestingEntityData()}
                name='investingEntityId'
                onChange={handleSelectChange}
                value={values.investingEntityId ?? ''}
                title={getSelectedEntity()}
              />
            </Form.Group>
          </li>
        );

        const currencyElem = () => {
          const isCryptoOrMetals =
            values.mmAccountType === EMMAccountType.PRECIOUS_METALS ||
            values.mmAccountType === EMMAccountType.CRYPTOCURRENCIES;

          if (isCryptoOrMetals) {
            return <React.Fragment />;
          }

          return (
            <li className='currency-select'>
              <span className='form-subheading'>{getFieldTitle('currency')}</span>
              {isFreeUser ? (
                <span className='mm-form-field-read'>{values.currency}</span>
              ) : (
                <CurrencyInput
                  value={values.currency ?? ''}
                  name='currency'
                  onChange={(value) => setFieldValue('currency', value)}
                />
              )}

              {isFreeUser && (
                <label className='mm-form-field-error text--pink'>
                  Upgrade to pro to to enable multi currency support.
                </label>
              )}
            </li>
          );
        };

        const liquidityElem = (
          <li>
            <span className='form-subheading'>{getFieldTitle('liquidity')}</span>
            <MMToolTip message='When can you liquidate these funds and make them available from today?'>
              <InfoIcon className='sm-hide' />
            </MMToolTip>
            <SelectInput
              args={enumerateStr(LiquidityOptions)}
              onChange={handleSelectChange}
              value={values.liquidity ?? ''}
              name='liquidity'
              sort={false}
            />
          </li>
        );

        const companySymbolElem = (
          <li className='w-100 '>
            <span className='form-subheading'>{getFieldTitle('companySymbol')}</span>
            <input type='text' name='companySymbol' onChange={handleChange} value={values.companySymbol ?? ''} />
          </li>
        );

        const streetAddressElem = (
          <li className='w-100 '>
            <span className='form-subheading'>{getFieldTitle('streetAddress')}</span>
            <input type='text' name='streetAddress' onChange={handleChange} value={values.streetAddress ?? ''} />
          </li>
        );

        const cityElem = (
          <li>
            <span className='form-subheading'>{getFieldTitle('city')}</span>
            <input type='text' name='city' onChange={handleChange} value={values.city ?? ''} />
          </li>
        );

        const stateElem = (
          <li>
            <span className='form-subheading'>{getFieldTitle('state')}</span>
            <input type='text' name='state' onChange={handleChange} value={values.state ?? ''} />
          </li>
        );

        const zipCodeElem = (
          <li>
            <span className='form-subheading'>{getFieldTitle('zipCode')}</span>
            <input type='text' name='zipCode' onChange={handleChange} value={values.zipCode ?? ''} />
          </li>
        );

        const countryElem = (
          <li>
            <span className='form-subheading'>{getFieldTitle('country')}</span>
            <input type='text' name='country' onChange={handleChange} value={values.country ?? ''} />
          </li>
        );

        const showAsInvestmentAssetElem = (
          <li className='w-100'>
            <div className='estimate-annual-block__checkbox'>
              <label className='custom-checkbox'>
                <input
                  type='checkbox'
                  name='showAsInvestmentAsset'
                  aria-checked={values.showAsInvestmentAsset ?? false}
                  checked={values.showAsInvestmentAsset ?? false}
                  onChange={handleChange}
                />
                <span className='checkmark' />
              </label>
              <span className='ml-4 pl-2'>{getFieldTitle('showAsInvestmentAsset')}</span>
            </div>
          </li>
        );

        const isRetirementElem = (
          <li className='w-100'>
            <div className='estimate-annual-block__checkbox'>
              <label className='custom-checkbox'>
                <input
                  type='checkbox'
                  name='isRetirement'
                  aria-checked={values.isRetirement ?? false}
                  checked={values.isRetirement ?? false}
                  onChange={handleChange}
                />
                <span className='checkmark' />
              </label>
              <span className='ml-4 pl-2'>{getFieldTitle('isRetirement')}</span>
            </div>
          </li>
        );

        const is401kElem = (
          <li className={classNames('w-100', !values.isRetirement && 'd-none')}>
            <div className='estimate-annual-block__checkbox'>
              <label className='custom-checkbox'>
                <input
                  type='checkbox'
                  name='is401k'
                  aria-checked={values.is401k ?? false}
                  checked={values.is401k ?? false}
                  onChange={handleChange}
                />
                <span className='checkmark' />
              </label>
              <span className='ml-4 pl-2'>{getFieldTitle('is401k')}</span>
            </div>
          </li>
        );

        const interestRateElem = (
          <li>
            <span className='form-subheading'>{getFieldTitle('interestRate')}</span>
            <div className='form-field-group single-field'>
              <Form.Control
                onChange={(e) => {
                  onChange(e);
                  calculateEstimatedMonthlyPayments('interestRate', e.target.value);
                }}
                type='number'
                name='interestRate'
                value={values.interestRate ?? ''}
                step='any'
              />
              <span className='input-add-on'>%</span>
            </div>
          </li>
        );

        const annualPercentageYieldElem = (
          <li>
            <span className='form-subheading'>{getFieldTitle('annualPercentageYield')}</span>
            <input
              type='text'
              name='annualPercentageYield'
              onChange={handleChange}
              value={values.annualPercentageYield ?? ''}
            />
          </li>
        );

        const investedDateElem = (
          <li>
            <span className='form-subheading'>{getFieldTitle('investedDate')}</span>
            <ReactDatePicker
              name='investedDate'
              selected={values.investedDate}
              onChange={(val: Date) => {
                setFieldValue('investedDate', moment(val).toDate());
              }}
            />
          </li>
        );

        const employerMatchContributionElem = hasBooleanTrue('is401k') ? (
          <li className='input-inline'>
            <span className='form-subheading'>{getFieldTitle('employerMatchContribution')}</span>
            <div className='form-check'>
              <input
                type='radio'
                value='yes'
                onChange={handleChange}
                name='employerMatchContribution'
                checked={values.employerMatchContribution === 'yes' || values.employerMatchContribution === true}
                aria-checked={values.employerMatchContribution === 'yes' || values.employerMatchContribution === true}
              />
              <label className='radio-label'>Yes</label>
            </div>
            <div className='form-check'>
              <input
                onChange={handleChange}
                value='no'
                type='radio'
                name='employerMatchContribution'
                checked={values.employerMatchContribution === 'no' || values.employerMatchContribution === false}
                aria-checked={values.employerMatchContribution === 'no' || values.employerMatchContribution === false}
              />
              <label className='radio-label'>No</label>
            </div>
          </li>
        ) : (
          <React.Fragment />
        );

        const employerMatchElem =
          hasBooleanTrue('is401k') && hasBooleanTrue('employerMatchContribution') ? (
            <li>
              <span className='form-subheading'>{getFieldTitle('employerMatch')}</span>
              <div className='form-field-group single-field w-100'>
                <Form.Control
                  onChange={onChange}
                  type='number'
                  name='employerMatch'
                  value={values.employerMatch ?? ''}
                  step='any'
                />
                <span className='input-add-on'>%</span>
              </div>
            </li>
          ) : (
            <React.Fragment />
          );

        const employerMatchLimitElem =
          hasBooleanTrue('is401k') && hasBooleanTrue('employerMatchContribution') ? (
            <li className='input-inline'>
              <span className='form-subheading'>{getFieldTitle('employerMatchLimitIn')}</span>
              <div className='form-check'>
                <input
                  type='radio'
                  onChange={handleChange}
                  value={EmployerMatchLimitOptions.AMOUNT}
                  name='employerMatchLimitIn'
                  checked={values.employerMatchLimitIn === EmployerMatchLimitOptions.AMOUNT}
                  aria-checked={values.employerMatchLimitIn === EmployerMatchLimitOptions.AMOUNT}
                />
                <label className='radio-label'>{CurrencySymbols[values.currency ?? CurrencySymbols.USD] || '$'}</label>
              </div>
              <div className='form-check'>
                <input
                  type='radio'
                  onChange={handleChange}
                  value={EmployerMatchLimitOptions.PERCENTAGE}
                  name='employerMatchLimitIn'
                  checked={values.employerMatchLimitIn === EmployerMatchLimitOptions.PERCENTAGE}
                  aria-checked={values.employerMatchLimitIn === EmployerMatchLimitOptions.PERCENTAGE}
                />
                <label className='radio-label'>%</label>
              </div>

              <div className='form-field-group'>
                <Form.Control
                  type='number'
                  name='employerMatchLimit'
                  onChange={onChange}
                  value={values.employerMatchLimit ?? ''}
                  step='any'
                />
                <span className='input-add-on'>
                  {values.employerMatchLimitIn === EmployerMatchLimitOptions.AMOUNT
                    ? CurrencySymbols[values.currency ?? ''] || '$'
                    : '%'}
                </span>
              </div>
            </li>
          ) : (
            <React.Fragment />
          );

        const includeEmployerMatchElem =
          hasBooleanTrue('is401k') && hasBooleanTrue('employerMatchContribution') ? (
            <li className='input-inline'>
              <span className='form-subheading'>
                {getFieldTitle('includeEmployerMatch')}
                <MMToolTip message='Some investors think employer match should be counted as income so they do not include it as performance returns, some believe should be counted as a return. The choice is yours'>
                  <InfoIcon className='sm-hide' />
                </MMToolTip>
              </span>
              <div className='form-check'>
                <input
                  type='radio'
                  value='yes'
                  onChange={handleChange}
                  name='includeEmployerMatch'
                  checked={values.includeEmployerMatch === 'yes' || values.includeEmployerMatch === true}
                  aria-checked={values.includeEmployerMatch === 'yes' || values.includeEmployerMatch === true}
                />
                <label className='radio-label'>Yes</label>
              </div>
              <div className='form-check'>
                <input
                  type='radio'
                  value='no'
                  onChange={handleChange}
                  name='includeEmployerMatch'
                  checked={values.includeEmployerMatch === 'no' || values.includeEmployerMatch === false}
                  aria-checked={values.includeEmployerMatch === 'no' || values.includeEmployerMatch === false}
                />
                <label className='radio-label'>No</label>
              </div>
            </li>
          ) : (
            <React.Fragment />
          );

        const originalLoanAmountElem = (
          <li>
            <span className='form-subheading'>{getFieldTitle('originalLoanAmount')}</span>
            <Form.Control
              type='number'
              name='originalLoanAmount'
              onChange={(e) => {
                onChange(e);
                calculateEstimatedMonthlyPayments('originalLoanAmount', e.target.value);
              }}
              value={values.originalLoanAmount ?? ''}
              step='any'
            />
          </li>
        );

        const termElem = (
          <li>
            <span className='form-subheading'>{getFieldTitle('term')}</span>
            <Form.Control
              type='number'
              name='term'
              onChange={(e) => {
                onChange(e);
                calculateEstimatedMonthlyPayments('term', e.target.value);
              }}
              value={values.term ?? ''}
              step='any'
            />
          </li>
        );

        const amountInvestedElem = (
          <li>
            <span className='form-subheading'>{getFieldTitle('amountInvested')}</span>
            <Form.Control
              type='number'
              name='amountInvested'
              onChange={onChange}
              value={values.amountInvested ?? ''}
              step='any'
            />
          </li>
        );

        const estimatedAnnualReturnElem = (
          <li className='w-100'>
            <span className='form-subheading mb-0'>{getFieldTitle('estimatedAnnualReturns')}</span>
            <span className='sub-label mb-4'>Used to show projections on your charts</span>
            <div className='form-field-group single-field mt-2'>
              <Form.Control
                onChange={onChange}
                type='number'
                name='estimatedAnnualReturns'
                value={values.estimatedAnnualReturns ?? ''}
                step='any'
              />
              <span className='input-add-on'>%</span>
            </div>
          </li>
        );

        const propertyTypeElem = (
          <li>
            <div>
              <span className='form-subheading'>{getFieldTitle('propertyType')}</span>
              <SelectInput
                args={enumerateStr(PropertyType)}
                onChange={handleSelectChange}
                value={values.propertyType ?? ''}
                name='propertyType'
              />
            </div>
          </li>
        );

        const frequencyElem = (
          <li>
            <div>
              <span className='form-subheading'>{getFieldTitle('frequency')}</span>
              <SelectInput
                args={enumerateStr(ELocalPaymentFrequency)}
                onChange={(e) => {
                  handleSelectChange(e);
                  calculateEstimatedMonthlyPayments('frequency', e.target.value);
                }}
                value={values.frequency ?? ''}
                name='frequency'
              />
            </div>
          </li>
        );

        const compoundPeriodElem = (
          <li>
            <div>
              <span className='form-subheading'>{getFieldTitle('compoundPeriod')}</span>
              <SelectInput
                args={enumerateStr(ECompoundPeriod)}
                onChange={(e) => {
                  handleSelectChange(e);
                  calculateEstimatedMonthlyPayments('compoundPeriod', e.target.value);
                }}
                value={values.compoundPeriod ?? ''}
                name='compoundPeriod'
              />
            </div>
          </li>
        );

        const amortizationPeriodElem = (
          <li>
            <span className='form-subheading'>{getFieldTitle('amortizationPeriod')}</span>
            <Form.Control
              type='number'
              name='amortizationPeriod'
              onChange={(e) => {
                onChange(e);
                calculateEstimatedMonthlyPayments('amortizationPeriod', e.target.value);
              }}
              value={values.amortizationPeriod ?? ''}
              step='any'
            />
          </li>
        );

        const bedElem = (
          <li>
            <span className='form-subheading'>{getFieldTitle('beds')}</span>
            <Form.Control type='number' name='beds' onChange={onChange} value={values.beds ?? ''} step='any' />
          </li>
        );

        const bathsElem = (
          <li>
            <span className='form-subheading'>{getFieldTitle('baths')}</span>
            <Form.Control type='number' name='baths' onChange={onChange} value={values.baths ?? ''} step='any' />
          </li>
        );

        const ownershipElem = (
          <li>
            <span className='form-subheading'>{getFieldTitle('ownership')}</span>
            <div className='form-field-group single-field w-100'>
              <Form.Control
                onChange={onChange}
                type='number'
                name='ownership'
                value={values.ownership ?? ''}
                step='any'
              />
              <span className='input-add-on'>%</span>
            </div>
          </li>
        );

        const industryElem = (
          <li>
            <span className='form-subheading'>{getFieldTitle('industry')}</span>
            <Form.Control type='text' name='industry' onChange={handleChange} value={values.industry ?? ''} />
          </li>
        );

        const investmentRoundElem = (
          <li>
            <div>
              <span className='form-subheading'>{getFieldTitle('investmentRound')}</span>
              <SelectInput
                args={enumerateStr(InvestmentRound)}
                onChange={handleSelectChange}
                value={values.investmentRound ?? ''}
                name='investmentRound'
              />
            </div>
          </li>
        );

        const investmentTypeElem = (
          <li>
            <div>
              <span className='form-subheading'>{getFieldTitle('investmentType')}</span>
              <SelectInput
                args={enumerateStr(InvestmentType)}
                onChange={handleSelectChange}
                value={values.investmentType ?? ''}
                name='investmentType'
              />
            </div>
          </li>
        );

        const capElem =
          values.investmentType !== InvestmentType.CONVERTIBLE_NOTE ? (
            <li>
              <div>
                <span className='form-subheading'>{getFieldTitle('cap')}</span>
                <Form.Control type='number' name='cap' onChange={onChange} value={values.cap ?? ''} step='any' />
              </div>
            </li>
          ) : (
            <React.Fragment />
          );

        const postMoneyValuationElem =
          values.investmentType === InvestmentType.CONVERTIBLE_NOTE ? (
            <li>
              <div>
                <span className='form-subheading'>{getFieldTitle('postMoneyValuation')}</span>
                <Form.Control
                  type='number'
                  name='postMoneyValuation'
                  onChange={onChange}
                  value={values.postMoneyValuation ?? ''}
                  step='any'
                />
              </div>
            </li>
          ) : (
            <React.Fragment />
          );

        const websiteElem = (
          <li>
            <span className='form-subheading'>{getFieldTitle('website')}</span>
            <Form.Control type='text' name='website' onChange={handleChange} value={values.website ?? ''} />
          </li>
        );

        const originationDateElem = (
          <li>
            <span className='form-subheading'>{getFieldTitle('originationDate')}</span>
            <ReactDatePicker
              name='originationDate'
              selected={values.originationDate}
              onChange={(val: Date) => {
                setFieldValue('originationDate', moment(val).toDate());
                calculateEstimatedMonthlyPayments('originationDate', moment(val).toDate());
              }}
            />
          </li>
        );

        const amountDueElem = (
          <li className='w-100 text--gray'>
            <span>
              {getFieldTitle('amountDue')}:{' '}
              {!!values.amountDue ? (
                <>
                  {CurrencySymbols[values.currency ?? CurrencySymbols.USD] || '$'}
                  {numberWithCommas(fNumber(values.amountDue, 2))}
                </>
              ) : (
                '--'
              )}
            </span>
          </li>
        );

        const useZestimateElem =
          values.mmAccountType === 'Real Estate' && (!values.country || isUSAText(values.country)) ? (
            <li className='w-100 zestimate'>
              <FormGroup>
                <span className='form-subheading'>{getFieldTitle('useZestimate')}</span>

                <div className='row'>
                  <div className='col-12 col-sm-6'>
                    <div className='form-check mb-2'>
                      <FormControl
                        type='radio'
                        value='yes'
                        name='useZestimate'
                        onChange={onChange}
                        checked={
                          values.useZestimate !== undefined &&
                          (values.useZestimate === 'yes' || values.useZestimate === true)
                        }
                        aria-checked={
                          values.useZestimate !== undefined &&
                          (values.useZestimate === 'yes' || values.useZestimate === true)
                        }
                      />
                      <label className='radio-label font-weight-medium'>Use Zestimate® for home value</label>
                    </div>

                    {!!values.useZestimate && (values.useZestimate === 'yes' || values.useZestimate === true) && (
                      <button
                        type='button'
                        className='mm-btn-animate mm-btn-primary w-auto d-flex align-items-center justify-content-center zestimate-button'
                        onClick={() => fetchZestimateValue()}
                      >
                        <img src={zillowIcon} alt='Zillow Logo' className='mr-2' />
                        Check Zestimate
                      </button>
                    )}
                  </div>

                  <div className='col-12 col-sm-6'>
                    <div className='form-check mb-2'>
                      <FormControl
                        type='radio'
                        value='no'
                        name='useZestimate'
                        onChange={onChange}
                        checked={
                          values.useZestimate !== undefined &&
                          (values.useZestimate === 'no' || values.useZestimate === false)
                        }
                        aria-checked={
                          values.useZestimate !== undefined &&
                          (values.useZestimate === 'no' || values.useZestimate === false)
                        }
                      />
                      <label className='radio-label'>Use my own estimate</label>
                    </div>

                    {(values.useZestimate === 'yes' || values.useZestimate === true) && zestimateValue && (
                      <>
                        <span className='zestimate-value d-block'>
                          {currencySymbol} {numberWithCommas(fNumber(zestimateValue, 0))}
                        </span>
                        <img src={zillowProvided} alt='Provided by Zillow' className='zestimate__provided-img' />
                      </>
                    )}

                    {(values.useZestimate === 'yes' || values.useZestimate === true) && zestimateValue === null && (
                      <span className='zestimate-val0ue--not-found'>Value not found</span>
                    )}
                  </div>
                </div>
              </FormGroup>
            </li>
          ) : (
            <React.Fragment />
          );

        const isArchiveElement = (
          <li className='w-100'>
            <span className='archive-account__label'>Closed Account</span>

            <div className='archive-account__checkbox'>
              <label className='custom-checkbox'>
                <input
                  type='checkbox'
                  name='isArchived'
                  aria-checked={values.isArchived ?? false}
                  checked={values.isArchived ?? false}
                  onChange={handleChange}
                />
                <span className='checkmark' />
              </label>
              <span className='ml-4 pl-2 archive-account__checkbox-label'>{getFieldTitle('isArchived')}</span>
            </div>
          </li>
        );

        /**
         * @description Method to return form element
         * @param name
         * @returns
         */

        const renderFormField = (name: string) => {
          const formElementMapper: Record<string, JSX.Element> = {
            accountName: accountNameElem,
            investingEntityId: entityNameElem,
            mmAccountType: accountTypeElem,
            mmAccountSubType: accountSubTypeElem,
            currency: currencyElem(),
            liquidity: liquidityElem,
            companySymbol: companySymbolElem,
            streetAddress: streetAddressElem,
            city: cityElem,
            state: stateElem,
            zipCode: zipCodeElem,
            country: countryElem,
            showAsInvestmentAsset: showAsInvestmentAssetElem,
            isRetirement: isRetirementElem,
            is401k: is401kElem,
            employerMatchContribution: employerMatchContributionElem,
            employerMatch: employerMatchElem,
            employerMatchLimitIn: employerMatchLimitElem,
            includeEmployerMatch: includeEmployerMatchElem,
            interestRate: interestRateElem,
            annualPercentageYield: annualPercentageYieldElem,
            originalLoanAmount: originalLoanAmountElem,
            term: termElem,
            amountInvested: amountInvestedElem,
            investedDate: investedDateElem,
            propertyType: propertyTypeElem,
            beds: bedElem,
            baths: bathsElem,
            ownership: ownershipElem,
            industry: industryElem,
            investmentRound: investmentRoundElem,
            investmentType: investmentTypeElem,
            cap: capElem,
            postMoneyValuation: postMoneyValuationElem,
            website: websiteElem,
            originationDate: originationDateElem,
            amountDue: amountDueElem,
            estimatedAnnualReturns: estimatedAnnualReturnElem,
            useZestimate: useZestimateElem,
            isArchived: isArchiveElement,
            frequency: frequencyElem,
            amortizationPeriod: amortizationPeriodElem,
            compoundPeriod: compoundPeriodElem,
          };

          return hasFormField(name) ? formElementMapper[name] || null : null;
        };

        const renderFormFieldElements = () => {
          return (
            <>
              {formFieldTopSectionList.map((formField, idx) => {
                return <React.Fragment key={idx}>{renderFormField(formField)}</React.Fragment>;
              })}

              <div className='form-divider' />
              {formFieldSecondSectionList.map((formField, idx) => {
                return <React.Fragment key={idx}>{renderFormField(formField)}</React.Fragment>;
              })}

              <div className='form-divider' />

              {formFieldLastSectionList.map((formField, idx) => {
                return <React.Fragment key={idx}> {renderFormField(formField)}</React.Fragment>;
              })}
            </>
          );
        };

        return (
          <>
            <form onSubmit={props.handleSubmit} className='account-setting-form'>
              <div className='account-type'>
                <ul className='account-type-list'>{renderFormFieldElements()}</ul>
              </div>

              <div className='estimate-annual-block mt-3'>
                <div className='estimated-top-content'>
                  <div className='row mt-5'>
                    <div className={`col-12 col-md-4 mb-3 ${isFromAccount ? '' : 'hidden'}`}>
                      <button className='mm-btn-animate btn-danger' type='button' onClick={initDeleteAccount}>
                        Delete Account
                      </button>
                    </div>

                    <div className='col-12 col-md-8 mb-3'>
                      <div className='d-flex justify-content-start'>
                        <button
                          className='mm-btn-signup btn-outline-primary mm-btn-animate estimate-annual-block__btn-cancel'
                          type='button'
                          onClick={() => {
                            closeSidebar?.();
                          }}
                        >
                          {isFromAccount ? 'Cancel' : 'Back'}
                        </button>
                        <button className='mm-btn-animate mm-btn-primary estimate-annual-block__btn-save' type='submit'>
                          {isFromAccount ? 'Save' : isLastAccount() ? 'Finish' : 'Next'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div className='delete-account-modal'>
              <DeleteAccountModal
                deleteAccounts={deleteAccountById}
                deleteAccountModal={deleteAccountModal}
                deleteAccountContent={deleteAccountContent}
              />
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default AccountSettingForm;
