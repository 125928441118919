import { getManualAccountFormField } from 'auth/auth.helper';
import { TKeyFormField, TPartialFormKeys } from 'auth/auth.types';

const useManualAccountFormFields = (type: TKeyFormField) => {
  const formFields = getManualAccountFormField(type) || [];

  const getField = (name: string) => {
    return formFields.find((f) => f.name === name);
  };

  const hasFormField = (name: string) => !!getField(name);

  const getFieldTitle = (name: TPartialFormKeys) => getField(name)?.label || '';

  return { hasFormField, getField, getFieldTitle };
};

export default useManualAccountFormFields;
