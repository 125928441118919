import { useState, useMemo, useEffect } from 'react';

import { Allocation } from 'allocation/allocation.type';

const useAllocationSort = (allocations: Allocation[], order: 'DESC' | 'ASC' = 'DESC') => {
  const [sortOrder, setSortOrder] = useState<'DESC' | 'ASC'>(order);

  useEffect(() => {
    setSortOrder(order);
  }, [order]);

  const sortedAllocations = useMemo(() => {
    const sortingAllocations = [...allocations];

    return sortingAllocations.sort((a: Allocation, b: Allocation) => {
      const firstAllocationValue = a.allocationValue || 0;
      const secondAllocationValue = b.allocationValue || 0;

      if (firstAllocationValue < secondAllocationValue) {
        return sortOrder === 'ASC' ? -1 : 1;
      }

      if (firstAllocationValue > secondAllocationValue) {
        return sortOrder === 'ASC' ? 1 : -1;
      }

      return 0;
    });
  }, [allocations, sortOrder]);

  return { sortedAllocations, sortOrder };
};

export default useAllocationSort;
