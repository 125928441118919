import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';

import { getCurrencySymbol } from 'common/currency-helper';
import { appRouteConstants } from 'app/app-route.constant';
import useLiabilities from 'dashboard/hooks/useLiabilities';
import { fNumber, numberWithCommas } from 'common/number.helper';
import { EIntervalOption, EDiffStatus } from 'dashboard/dashboard.enum';
import { ReactComponent as LiabilitiesLogo } from 'assets/images/dashboard/liabilities.svg';

import IntervalSelector from './interval-selector';
import AssetLiabilityItem from './asset-liability-item';

const Liabilities: React.FC = () => {
  const history = useHistory();

  const [currentInterval, setCurrentInterval] = useState<EIntervalOption>(EIntervalOption['1D']);

  const { loading, historyLoading, currencyOption, liabilities, liabilitiesHistoryMap } =
    useLiabilities(currentInterval);

  const renderLiabilitiesItem = () => {
    if (!liabilitiesHistoryMap || loading) {
      return <Skeleton height={300} />;
    }

    const handleLiabilityItemClick = (mmAccountType: string) => () => {
      const path = appRouteConstants.account.ACCOUNT_TYPES.replace(':type', mmAccountType);

      return history.push(path);
    };

    const currencySymbol = getCurrencySymbol(currencyOption);

    return (
      <div className='liabilities__body'>
        {liabilities.map((liability) => {
          const { mmAccountType, total, emoji } = liability;

          const balanceText = `${currencySymbol}${numberWithCommas(fNumber(total, 2))}`;

          const comparisonBalance = liabilitiesHistoryMap[mmAccountType] || 0;
          const balanceDiff = comparisonBalance - total;

          let balanceDiffPerText = '--';

          if (comparisonBalance) {
            const balanceDiffPer = numberWithCommas(fNumber((balanceDiff / Math.abs(comparisonBalance)) * 100));

            balanceDiffPerText = `${balanceDiffPer}%`;
          }

          const isNegative = balanceDiff < 0;
          const balanceSymbol = isNegative ? '-' : '';
          const diffStatus = isNegative ? EDiffStatus.NEGATIVE : EDiffStatus.POSITIVE;

          const balanceDiffText = `${balanceSymbol}${currencySymbol}${numberWithCommas(
            fNumber(Math.abs(balanceDiff), 2)
          )}`;

          return (
            <AssetLiabilityItem
              emoji={emoji}
              key={mmAccountType}
              name={mmAccountType}
              diffStatus={diffStatus}
              balanceText={balanceText}
              balanceDiff={balanceDiffText}
              balanceDiffPer={balanceDiffPerText}
              isLiability
              loading={historyLoading}
              onClick={handleLiabilityItemClick(mmAccountType)}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className='liabilities'>
      <div className='liabilities__header-container'>
        <div className='liabilities__header'>
          <LiabilitiesLogo />
          <h2>Liabilities</h2>
        </div>

        <IntervalSelector defaultInterval={currentInterval} onChange={setCurrentInterval} />
      </div>

      {renderLiabilitiesItem()}
    </div>
  );
};

export default Liabilities;
