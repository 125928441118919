import React, { Suspense } from 'react';

import { IncomeView } from 'income';
import LoadingScreen from 'common/loading-screen';
import { FilterProvider } from 'filter/filter.context';
import { IncomeProvider } from 'income/income.context';
import { NetworthProvider } from 'networth/networth.context';
import AuthorizedProvider from 'provider/authorized.provider';

const IncomeRoute: React.FC = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <AuthorizedProvider>
        <FilterProvider>
          <NetworthProvider>
            <IncomeProvider>
              <IncomeView />
            </IncomeProvider>
          </NetworthProvider>
        </FilterProvider>
      </AuthorizedProvider>
    </Suspense>
  );
};

export default IncomeRoute;
