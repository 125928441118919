export enum MMCategories {
  INVESTMENT_ASSETS = 'Investment Assets',
  OTHER_ASSETS = 'Other Assets',
  LIABILITIES = 'Liabilities',
}

export enum ProviderAccountStatus {
  LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS',
  USER_INPUT_REQUIRED = 'USER_INPUT_REQUIRED',
  IN_PROGRESS = 'IN_PROGRESS',
  PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum RoleEnum {
  ADMIN,
  GUEST,
}

export enum AuthState {
  INITIAL,
  LOGGED_OUT,
  LOGGING_OUT,
  AUTHENTICATED,
  AUTHENTICATING,
  SIGN_IN_REJECTED,
  LOG_OUT_REJECTED,
  VERIFYING_MFA,
}

export enum ProviderAggregationSource {
  SYSTEM = 'SYSTEM',
  USER = 'USER',
}

export enum PropertyType {
  SINGLE_FAMILY_RESIDENCE = 'Single Family Residence',
  MULTI_FAMILY_RESIDENCE = 'Multi Family Residence',
  CONDOMINIUM = 'Condominium',
  COMMERCIAL = 'Commercial',
  LAND = 'Land',
  OTHER = 'Other',
}

export enum InvestmentRound {
  PRE_SEED = 'Pre-Seed',
  SEED = 'Seed',
  SERIES_A = 'Series A',
  SERIES_B = 'Series B',
  SERIES_C = 'Series C',
  SERIES_D = 'Series D',
}

export enum InvestmentType {
  CONVERTIBLE_NOTE = 'Convertible Note',
  PRICED_ROUND = 'Priced Round',
}

export enum ETokenType {
  BEARER = 'Bearer',
  MFA = 'MFA',
}

export enum EReferLevel {
  REFERRER = 'Referrer',
  SPONSOR = 'Sponsor',
  AFFILIATE = 'Affiliate',
}

export enum ECryptoConnections {
  BINANCE = 'Binance',
  COINBASE = 'Coinbase',
  BINANCE_US = 'Binance US',
  COINBASE_PRO = 'Coinbase Pro',
  EXODUS = 'Exodus',
  FTX = 'FTX',
  FTX_US = 'FTX US',
  GEMINI = 'Gemini',
  HUOBI = 'Huobi',
  KRAKEN = 'Kraken',
  KUCOIN = 'KuCoin',
  LEDGER = 'Ledger',
  METAMASK = 'Metamask',
  TREZOR = 'Trezor',
  BITCOIN = 'Bitcoin',
  BITTREX = 'Bittrex',
  CELSIUS = 'Celsius',
  COINBASE_WALLET = 'Coinbase Wallet',
  CRYPTO_COM = 'Crypto.com',
  ETHEREUM = 'Ethereum',
  BINANCE_SMART_CHAIN = 'Binance Smart Chain',
  TERRA = 'Terra',
  TERRA_STATION_WALLET = 'Terra Station Wallet',
  VINOVEST = 'Vinovest',
  RALLY_RD = 'Rally Rd',
  TRUST_WALLET = 'Trust Wallet',
}

/**
 * @description - Enum for the Frontend Payment Frequency
 */
export enum ELocalPaymentFrequency {
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  ANNUALLY = 'Annually',
  BI_WEEKLY = 'Bi-Weekly',
  SEMI_MONTHLY = 'Semi-Monthly',
  SEMI_ANNUALLY = 'Semi-Annually',
}

export enum EPaymentFrequency {
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  ANNUALLY = 'Annually',
  BI_WEEKLY = 'Bi-Weekly',
  SEMI_MONTHLY = 'Semi-Monthly',
  SEMI_ANNUALLY = 'Semi-Annually',

  // !  we don't allow these in frontend dropdown selection but add a fallback so that yodlee based accounts don't break
  DAILY = 'Daily',
  QUARTERLY = 'Quarterly',
  EVERY_TWO_MONTHS = 'Every-Two-Months',
  EVERY_FOUR_WEEKS = 'Every-Four-Weeks',
}

export enum ECompoundPeriod {
  ANNUALLY = 'Annually',
  SEMI_ANNUALLY = 'Semi-Annually',
  MONTHLY = 'Monthly',
  SEMI_MONTHLY = 'Semi-Monthly',
  BI_WEEKLY = 'Bi-Weekly',
  WEEKLY = 'Weekly',
}
