import { Router } from 'react-router-dom';
import { Suspense, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

import env from 'app/app.env';
import history from 'app/app.history';
import useGist from 'common/hooks/useGist';
import { useAuthState } from 'auth/auth.context';
import useAnalytics from 'common/hooks/useAnalytics';
import useTokenValidation from 'auth/hooks/useTokenValidation';

import Gist from './gist';
import MainRoute from './main.route';

export default function Main() {
  useGist();
  useAnalytics();
  useTokenValidation();

  const { user } = useAuthState();

  useEffect(() => {
    ReactPixel.init(env.FACEBOOK_PIXEL_CODE, {} as any, {
      autoConfig: true,
      debug: false,
    });
  }, []);

  const email = user?.email;

  useEffect(() => {
    if (email && window.$crisp) {
      window.$crisp.push(['set', 'user:email', email]);
      window.$crisp.push(['set', 'session:segments', [['registered']]]);
    }
  }, [email]);

  return (
    <>
      <Router history={history}>
        <Suspense fallback='....'>
          <div className='app-wrapper'>
            <MainRoute />
          </div>
        </Suspense>
      </Router>
      <Gist />
    </>
  );
}
