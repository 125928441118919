import { useEffect, useState } from 'react';

import { getNetworth } from 'api/request.api';
import { getUTC } from 'common/moment.helper';
import useSearchParam from 'auth/hooks/useSearchParam';
import { useFilterState } from 'filter/filter.context';
import { TimeIntervalEnum } from 'networth/networth.enum';
import { useNetworthDispatch } from 'networth/networth.context';
import { NetworthItem, NetworthType } from 'networth/networth.type';
import { parseAccountDetails, parseIntervalList } from 'common/interval-parser';
import { resetProcessing, setAccounts, setNetWorth, setProcessing } from 'networth/networth.actions';

const useNetworth = (groupByCategory = false) => {
  const dispatch = useNetworthDispatch();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<NetworthType>();

  const {
    fTypes,
    fAccounts,
    fEntities,
    fLiquidity,
    fCategories,
    fToDate: toDate,
    fFromDate: fromDate,
    fTimeInterval: timeInterval,
  } = useFilterState();

  const accountType = fTypes.length ? fTypes.toString() : undefined;
  const category = fCategories.length ? fCategories.toString() : undefined;
  const accountId = fAccounts.length ? fAccounts.toString() : undefined;
  const investingEntityId = fEntities.length ? fEntities.toString() : undefined;
  const liquidity = fLiquidity.length ? fLiquidity.toString() : undefined;
  const refetchAccounts = useSearchParam('refetchAccounts');

  useEffect(() => {
    const fetchNetworth = async () => {
      setLoading(true);
      dispatch(setProcessing('networth'));

      const { data, error: networthError } = await getNetworth({
        accountType,
        timeInterval,
        category,
        fromDate: fromDate ? getUTC(fromDate) : undefined,
        toDate: toDate ? getUTC(toDate) : undefined,
        accountId,
        investingEntityId,
        liquidity,
      });

      const isQuarter = timeInterval === TimeIntervalEnum.QUARTERLY;
      dispatch(resetProcessing('networth'));

      if (networthError) {
        setLoading(false);

        return setError(networthError);
      }

      if (data?.networth) {
        const parsedNetworth: NetworthItem[] = parseIntervalList(data.networth, isQuarter) as any;
        dispatch(setNetWorth(parsedNetworth));
      }

      if (data?.accounts) {
        const parsedAccountDetails = parseAccountDetails(data.accounts, isQuarter);

        dispatch(setAccounts(parsedAccountDetails));
      }

      setLoading(false);

      return setResponse(data);
    };

    if (groupByCategory || !!refetchAccounts) {
      fetchNetworth();
    }
  }, [
    toDate,
    category,
    fromDate,
    dispatch,
    accountId,
    liquidity,
    accountType,
    timeInterval,
    groupByCategory,
    investingEntityId,
    refetchAccounts,
  ]);

  return {
    loading,
    error,
    accounts: response?.accounts,
    networth: response?.networth,
    accountWithIssues: response?.accountWithIssues,
  };
};

export default useNetworth;
