import React from 'react';

import { useAccountTypesState } from 'account-types/context/account-types.context';

import AccountsTableSection from './accounts-table-section';
import { Account, IAccountDataForInterval } from '../account-types.types';

interface IAccountTypesTableProps {
  holdingsForAccountType: any[];
  accounts: Account[];
  currencySymbol: string;
  balanceTotal: IAccountDataForInterval[];
}

const AccountTypesTable: React.FC<IAccountTypesTableProps> = ({
  holdingsForAccountType,
  accounts,
  currencySymbol,
  balanceTotal,
}) => {
  const { accountType } = useAccountTypesState();

  return (
    <AccountsTableSection
      accounts={accounts}
      balanceTotal={balanceTotal}
      currencySymbol={currencySymbol}
      holdings={holdingsForAccountType}
      mmAccountType={accountType?.mmAccountType || ''}
    />
  );
};

export default AccountTypesTable;
