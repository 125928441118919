import React from 'react';

import { Account } from 'auth/auth.types';
import { getCurrencySymbol } from 'common/currency-helper';
import { getDateFormattedString } from 'common/moment.helper';
import { fNumber, numberWithCommas } from 'common/number.helper';
import { ReactComponent as DeleteIcon } from 'assets/icons/icon-trash.svg';

interface IAssociatedMortgageItemProps {
  associatedAccount: Account;
  handleDelete: (accId: number) => void;
  navigateToAssociatedAccount: (accId: number) => void;
}

const AssociatedMortgageItem: React.FC<IAssociatedMortgageItemProps> = ({
  associatedAccount,
  handleDelete,
  navigateToAssociatedAccount,
}) => {
  const getPayment = () => {
    const amount = associatedAccount?.accountDetails.amountDue;
    const currency = associatedAccount?.currency;

    if (!amount || !currency) {
      return '-';
    }

    return `${getCurrencySymbol(currency)} ${numberWithCommas(fNumber(amount, 2))}`;
  };

  return (
    <div
      className='associated-mortgage__detail'
      onClick={() => navigateToAssociatedAccount(associatedAccount.id)}
      role='button'
    >
      <div className='associated-mortgage__detail-item'>
        <h6>Mortgage Details</h6>
        <DeleteIcon
          className='btn-delete'
          role='button'
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(associatedAccount.id);
          }}
        />
      </div>

      {!!associatedAccount.providerLogo || !!associatedAccount.providerName ? (
        <div className='d-flex align-items-center mb-4'>
          {!!associatedAccount.providerLogo && (
            <img
              src={associatedAccount.providerLogo}
              alt='Account logo'
              className='associated-mortgage__detail-img mr-4'
            />
          )}
          {!!associatedAccount.providerName && (
            <span className='associated-mortgage__detail-provider'>{associatedAccount.providerName}</span>
          )}
        </div>
      ) : (
        <span className='associated-mortgage__detail-account-name'>{associatedAccount.accountName}</span>
      )}

      <div className='associated-mortgage__detail-item'>
        <label className='associated-mortgage__detail-label'>Payment</label>
        <span className='associated-mortgage__detail-value'>{getPayment()}</span>
      </div>

      <div className='associated-mortgage__detail-item'>
        <label className='associated-mortgage__detail-label'>Due Date</label>
        <span className='associated-mortgage__detail-value'>
          {associatedAccount?.accountDetails.dueDate
            ? getDateFormattedString(associatedAccount?.accountDetails.dueDate)
            : '-'}
        </span>
      </div>

      <div className='associated-mortgage__detail-item'>
        <label className='associated-mortgage__detail-label'>Maturity Date</label>
        <span className='associated-mortgage__detail-value'>
          {associatedAccount?.accountDetails.maturityDate
            ? getDateFormattedString(associatedAccount?.accountDetails.maturityDate)
            : '-'}
        </span>
      </div>

      <div className='d-flex justify-content-between align-items-start'>
        <label className='associated-mortgage__detail-label'>Interest Rate</label>
        <span className='associated-mortgage__detail-value'>
          {associatedAccount?.accountDetails.interestRate ? `${associatedAccount?.accountDetails.interestRate}%` : '-'}
        </span>
      </div>
    </div>
  );
};

export default AssociatedMortgageItem;
