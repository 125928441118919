import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import termData from '@mm/data/terms.json';
import WebsiteLayout from 'website/website.layout';

const { terms } = termData;

const termContentSection = () => {
  const privacyContent = terms.data.map((d, i) => {
    return (
      <div key={i}>
        <h2 className='notice-content-title'>{d.title}</h2>
        <div className='notice-content' dangerouslySetInnerHTML={{ __html: d.content }} />
      </div>
    );
  });
  return <>{privacyContent}</>;
};
const TermOfService = () => {
  return (
    <WebsiteLayout>
      <HelmetProvider>
       <Helmet>
         <title>Terms of Service | Money Minx</title>
         <meta name='description' content='The terms of service you are agreeing to by using Money Minx.' />
         {/*Facebook tags*/}
         <meta property='og:title' content='Terms of Service | Money Minx' />
         <meta property='og:description' content='The terms of service you are agreeing to by using Money Minx.' />
         <meta property='og:url' content='https://www.moneyminx.com/terms' />
         <meta property='og:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
         <meta property='og:image:secure_url' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
         <meta property='og:image:alt' content='The terms of service you are agreeing to by using Money Minx.' />
         {/*Twitter tags*/}
         <meta property='twitter:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
         <meta property='twitter:image:alt' content='The terms of service you are agreeing to by using Money Minx.' />
       </Helmet>
      </HelmetProvider>
      <div className='mm-container wrapper notice-wrapper'>
        <div className='notice-header'>
          <h1>{terms.title}</h1>
          <p>{terms.update}</p>
        </div>
        {termContentSection()}
      </div>
    </WebsiteLayout>
  );
};

export default TermOfService;
