const bittrexApiKeyInstructions = [
  'Log into your Bittrex account.',
  'Click on Account.',
  'Click on API Keys.',
  'Click on Add new key ...',
  'Only turn on the option READ INFO.',
  'Click on Save.',
  'Authenticate your MFA if enabled.',
  'Copy the Key and Secret and enter them in Money Minx',
];

export default bittrexApiKeyInstructions;
