import React from 'react';
import { Link } from 'react-router-dom';

import { useAuthState } from 'auth/auth.context';
import { EMMAccountType } from 'account/enum/account-type';
import { Modal, ModalType } from 'common/components/modal';
import { ReactComponent as LogoImg } from 'assets/icons/logo.svg';

interface Props {
  accountAddedModal: ModalType;
  addedAccountType?: string | null;
  addedAccountHoldings?: string | null;
  handleSuccess: () => void;
  navigateToAccountDetail: VoidFunction;
}

const AccountAddedModal: React.FC<Props> = ({
  accountAddedModal,
  addedAccountType,
  addedAccountHoldings,
  handleSuccess,
  navigateToAccountDetail,
}) => {
  const { accounts } = useAuthState();
  const account = accounts?.[0];

  if (!account) {
    return null;
  }

  const renderModalContent = () => {
    if (
      addedAccountHoldings === 'true' &&
      addedAccountType &&
      addedAccountType !== EMMAccountType.CRYPTOCURRENCIES &&
      addedAccountType !== EMMAccountType.PRECIOUS_METALS
    ) {
      const mapper: Record<string, string> = {
        Securities: 'Securities',
        Businesses: 'Businesses',
        Cryptocurrencies: 'Coins',
        Collectibles: 'Collectibles',
        NFTs: 'NFTs',
        'Stock Options': 'Grants',
        Startups: 'Startups',
      };

      const labelText = mapper[addedAccountType] || '';

      return (
        <div>
          <p className='mb-5'>
            You can now navigate to the account details page and add separate {labelText.toLowerCase()} for this
            account.
          </p>
          <div className='modal-btn-wrapper'>
            <button className='mm-btn-animate mm-btn-primary' onClick={navigateToAccountDetail}>
              Add More Details
            </button>
            <div className='create-new-btn text-center'>
              <Link to='#' onClick={accountAddedModal.close}>
                Skip details, go to dashboard instead
              </Link>
            </div>
          </div>
        </div>
      );
    }

    const showGoToAccount =
      addedAccountHoldings === 'false' ||
      (addedAccountHoldings === 'true' &&
        (addedAccountType === EMMAccountType.PRECIOUS_METALS || addedAccountType === EMMAccountType.CRYPTOCURRENCIES));

    return (
      <div>
        <p className='mb-5'>
          Money Minx works best when you add all of your accounts so you see your full financial picture. Do you want to
          add more accounts now?
        </p>
        <div className='modal-btn-wrapper'>
          <button className='mm-btn-animate mm-btn-primary' onClick={handleSuccess}>
            Add more accounts
          </button>
          <div className='create-new-btn text-center'>
            {showGoToAccount ? (
              <Link to='#' onClick={navigateToAccountDetail}>
                Go to account
              </Link>
            ) : (
              <Link to='#' onClick={accountAddedModal.close}>
                Go to Dashboard instead
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal {...accountAddedModal.props} title='Account Added!' size='lg' onSuccess={handleSuccess} canBeClosed>
      <div className='modal-wrapper signup-modal'>
        <div className='signup-done-modal-logo'>
          {account.providerLogo ? (
            <img src={account.providerLogo} alt={`${account.accountName}`} />
          ) : (
            <LogoImg className='icon auth-logo' />
          )}
        </div>

        {renderModalContent()}
      </div>
    </Modal>
  );
};

export default AccountAddedModal;
