import React, { useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import { appRouteConstants } from 'app/app-route.constant';
import { IAccountType } from 'account-types/account-types.types';
import { ReactComponent as BackImg } from 'assets/icons/back.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { useAccountTypesState } from 'account-types/context/account-types.context';

interface IAccountTypeHeadProps {
  accountTypes: IAccountType[];
}

const AccountTypesHead: React.FC<IAccountTypeHeadProps> = ({ accountTypes }) => {
  const history = useHistory();
  const location = useLocation();
  const dropdownToggle = useRef<any>(null);

  const { accountType } = useAccountTypesState();

  const handleBackClick = () => {
    return history.push(appRouteConstants.dashboard.DASHBOARD);
  };

  const handleAccountTypeClick = (mmAccountType: string) => {
    dropdownToggle.current?.click();

    const path = appRouteConstants.account.ACCOUNT_TYPES.replace(':type', mmAccountType);

    return history.push({
      pathname: path,
      search: location.search,
    });
  };

  const renderAccountTypesHead = () => {
    return (
      <div className='account-types-head'>
        <div className='d-flex align-items-center'>
          <button className='account-types-head__back' onClick={handleBackClick}>
            <BackImg />
          </button>

          <button className='account-types-head__from' onClick={handleBackClick}>
            Dashboard
            <ArrowRight className='ml-3' />
          </button>

          <Dropdown className='drop-box'>
            <Dropdown.Toggle ref={dropdownToggle}>{accountType?.mmAccountType}</Dropdown.Toggle>
            <Dropdown.Menu className='mm-dropdown-menu'>
              <ul className='checkbox-list account-types-dropdown'>
                {accountTypes.map(({ mmAccountType }) => {
                  return (
                    <li key={mmAccountType} role='button' onClick={() => handleAccountTypeClick(mmAccountType)}>
                      {mmAccountType}
                    </li>
                  );
                })}
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className='account-types-head__title'>
          {accountType?.emoji}
          <span>{accountType?.mmAccountType}</span>
        </div>
      </div>
    );
  };

  return <div className='app-subheader-container'>{renderAccountTypesHead()}</div>;
};

export default AccountTypesHead;
