import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Bar, Area, XAxis, YAxis, Tooltip, ComposedChart, CartesianGrid, ResponsiveContainer } from 'recharts';
import { BarChartColors } from 'common/color';
import { NetworthItem } from 'networth/networth.type';
import { formatter, getInterval } from 'common/bar-graph-helper';
import { fNumber, numberWithCommas } from 'common/number.helper';
import BarGraphCustomTick from 'common/components/chart/bargraph-custom-tick';

interface INetworthChartProps {
  currencySymbol: string;
  networth: NetworthItem[];
}

const CustomTooltip: React.FC<any> = (props) => {
  const { active, currencySymbol, label, payload } = props;

  if (!active || !payload.length) {
    return null;
  }

  const networthValue = payload[0]?.value;

  const negativeSymbol = networthValue < 0 ? '-' : null;

  return (
    <div className='bar-tooltip'>
      <div className='item-name'>{label}</div>
      <div className='item-value ml-0'>
        {negativeSymbol}
        {currencySymbol}
        {numberWithCommas(fNumber(Math.abs(networthValue), 0))}
      </div>
    </div>
  );
};

const NetworthChart: React.FC<INetworthChartProps> = ({ networth, currencySymbol }) => {
  if (!networth?.length) {
    return (
      <div className='responsive-container'>
        <Skeleton height={280} />
      </div>
    );
  }

  const maxValue = networth.reduce((acc, current) => {
    const currentValue = current.networth;

    return currentValue > acc ? currentValue : acc;
  }, 0);

  let chartInterval = getInterval(maxValue);

  if (maxValue > chartInterval * 3.5) {
    chartInterval = getInterval(maxValue + chartInterval / 2);
  }

  return (
    <div className='responsive-container'>
      <ResponsiveContainer width='100%' height={320}>
        <ComposedChart
          data={networth}
          barGap={2}
          barCategoryGap={20}
          margin={{
            top: 24,
            right: 5,
            left: 5,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='#8884d8' stopOpacity={0.5} />
              <stop offset='95%' stopColor='#FFFFFF' stopOpacity={0.5} />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id='colorPr' x1='-1' y1='-1' x2='0' y2='0'>
              <stop offset='5%' stopColor='#8884d8' stopOpacity={0.3} />
              <stop offset='95%' stopColor='#FFFFFF' stopOpacity={0.3} />
            </linearGradient>
          </defs>

          <Area dataKey='networth' type='monotone' stroke='#534cea' strokeOpacity='0' fill='url(#colorUv)' />

          <CartesianGrid stroke='#969eac1a' vertical={false} />

          <XAxis
            dataKey='interval'
            tickSize={0}
            tickMargin={0}
            minTickGap={0}
            tick={<BarGraphCustomTick />}
            stroke='#969eac'
            axisLine={{ stroke: 'transparent' }}
          />

          <YAxis
            orientation='right'
            minTickGap={10}
            axisLine={false}
            tickSize={0}
            tickMargin={10}
            tick={{ fontSize: 14 }}
            interval='preserveStartEnd'
            stroke='#969eac'
            tickFormatter={(tick) => formatter(tick, currencySymbol)}
            domain={['auto', chartInterval * 4]}
          />

          <Tooltip separator='' cursor={false} content={<CustomTooltip currencySymbol={currencySymbol} />} />

          <Bar dataKey='networth' barSize={10} fill={BarChartColors.BLUE} radius={[2, 2, 0, 0]} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default NetworthChart;
