import React from 'react';
import { Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { Account } from 'auth/auth.types';
import { groupByProviderName } from 'auth/auth.helper';
import { numberWithCommas } from 'common/number.helper';
import { Modal, ModalType } from 'common/components/modal';
import { getCurrencySymbol } from 'common/currency-helper';
import { base64Encode } from 'common/helper/string.helper';
import { ReactComponent as LogoImg } from 'assets/icons/logo.svg';

interface ISelectAccountModal {
  selectAccountModal: ModalType;
  multiAccounts: Account[];
}

const SelectAccountModal: React.FC<ISelectAccountModal> = ({ selectAccountModal, multiAccounts }) => {
  const history = useHistory();

  const gotoDetailPage = async (id: number) => {
    selectAccountModal.close();
    const encodedAccountId = base64Encode(`${id}`);
    return history.push('/account-details/' + encodedAccountId);
  };

  const accountsByProvider = groupByProviderName(multiAccounts);

  return (
    <Modal
      {...selectAccountModal.props}
      title='Which account?'
      size='md'
      canBeClosed
      onClose={() => selectAccountModal.close()}
    >
      <div className='modal-wrapper'>
        {accountsByProvider && (
          <div className='mm-select-account-modal'>
            <p>This position is held in multiple accounts, which account would you like to open?</p>

            {Object.entries(accountsByProvider).map(([providerName, accounts], index) => (
              <div key={index} className='row account-section mt-3'>
                <div className='col-sm-3'>
                  {accounts[0].providerLogo ? (
                    <Image src={accounts[0].providerLogo} className='providerLogo' />
                  ) : (
                    <LogoImg className='providerLogo' />
                  )}
                </div>
                <div className='col-sm-9'>
                  <p>
                    <span className='provider-name'>{accounts[0].providerName === null ? 'Manual' : providerName}</span>
                  </p>
                  {accounts.map((item, i) => (
                    <p
                      className='d-flex justify-content-between description'
                      onClick={() => gotoDetailPage(item.id)}
                      key={i}
                      role='button'
                    >
                      <span>{item.accountName}</span>
                      <span>
                        {getCurrencySymbol(item.holdingValueCurrency)}
                        {numberWithCommas(item.holdingValue)}
                      </span>
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SelectAccountModal;
