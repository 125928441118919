import React from 'react';

import BBBImg from 'assets/images/home/acc_bus.png';
import BinanceUS from 'assets/logos/crypto/binanceUS.png';
import { ReactComponent as Binance } from 'assets/logos/crypto/binance.svg';
import { ReactComponent as Coinbase } from 'assets/logos/crypto/coinbase.svg';
import { ReactComponent as SSLSecureImage } from 'assets/icons/ssl-secure.svg';
import { ReactComponent as NortonImage } from 'assets/images/account/norton_black.svg';
import { ReactComponent as TrustPilotImage } from 'assets/images/account/trustpilot_black.svg';

interface ISVGImage extends React.SVGProps<SVGSVGElement> {
  title?: string;
  className?: string;
  onClick?: () => void;
}

export const Norton: React.FC<ISVGImage> = (props) => <NortonImage {...props} />;
export const BinanceLogo: React.FC<ISVGImage> = (props) => <Binance {...props} />;
export const CoinbaseLogo: React.FC<ISVGImage> = (props) => <Coinbase {...props} />;
export const SSLSecure: React.FC<ISVGImage> = (props) => <SSLSecureImage {...props} />;
export const TrustPilot: React.FC<ISVGImage> = (props) => <TrustPilotImage {...props} />;

const SVGImages = {
  Norton,
  SSLSecure,
  BinanceUS,
  TrustPilot,
  BinanceLogo,
  CoinbaseLogo,
};

export const PNGImages = {
  BBBImg,
  BinanceUS,
};

export default SVGImages;
