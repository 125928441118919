import React from 'react';

import classNames from 'common/classes.helper';

import MMToolTip from '../tooltip';
import { InfoIcon } from '../icons';
import { InputType } from '../component.type';

const TextInput: React.FC<InputType> = ({
  name,
  title,
  addOn,
  helperText,
  handleChange,
  handleOnBlur,
  error = false,
  type = 'text',
  className = '',
  tooltipMessage,
  disabled = false,
  required = false,
  autoComplete = 'off',
  placeholder = 'Enter value',
}) => {
  return (
    <div className={classNames('mm-form-group pt-2', className)}>
      <label htmlFor={name} className='text-input-label'>
        {title}
      </label>
      <InputTooltip message={tooltipMessage} name={name} />
      <input
        id={name}
        type={type}
        name={name}
        disabled={disabled}
        required={required}
        onBlur={handleOnBlur}
        onChange={handleChange}
        aria-required='true'
        className='form-control'
        placeholder={placeholder}
        autoComplete={autoComplete}
        aria-describedby={`inputHelp-${name}`}
      />
      {addOn ? <span className='input-add-on'>{addOn}</span> : null}
      <InputHelperText name={name} helperText={helperText} error={error} />
    </div>
  );
};

export default TextInput;

interface IInputTooltip {
  name: string;
  message?: string;
}

export const InputTooltip = (props: IInputTooltip) => {
  if (!props.message) {
    return null;
  }

  return (
    <MMToolTip message={props.message} name={props.name}>
      <InfoIcon className='tooltip-icon sm-hide' />
    </MMToolTip>
  );
};

interface IInputHelperText {
  name: string;
  error?: boolean;
  helperText?: string;
}

export const InputHelperText = (props: IInputHelperText) => {
  const helperTextClass = props.error ? 'show invalid-feedback' : 'form-text text-muted';

  if (!props.helperText) {
    return null;
  }

  return (
    <small id={`inputHelp-${props.name}`} className={helperTextClass}>
      {props.helperText}
    </small>
  );
};
