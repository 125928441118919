import React, { useState } from 'react';

import { events } from '@mm/data/event-list';
import { EProviderType } from 'auth/auth.types';
import { useModal } from 'common/components/modal';
import useAnalytics from 'common/hooks/useAnalytics';
import { getSaltEdgeConnectWorld } from 'api/request.api';
import SaltEdgeModal from 'auth/views/inc/salt-edge.modal';
import { ReactComponent as RightArrow } from 'assets/icons/connect-account/right-arrow.svg';

/**
 * @description React component to show account connection card
 * please consider reusing it if possible with passing props
 * @returns JSX.Element
 */

interface ICardInfo {
  title: string;
  description: string;
  buttonText: string;
}

interface IConnectSaltEdgeWorld {
  cardInfo: ICardInfo;
  icon: React.ReactNode;
  handleSuccess: (provider: EProviderType) => void;
}

export interface ISaltEdgeResponse {
  expiresAt: string;
  connectUrl: string;
}

const ConnectSaltEdgeWorld: React.FC<IConnectSaltEdgeWorld> = ({ cardInfo, icon, handleSuccess }) => {
  const saltEdgeModal = useModal();
  const { event } = useAnalytics();
  const [loading, setLoading] = useState(false);
  const [saltEdge, setSaltEdge] = useState<ISaltEdgeResponse>();

  const handleSaltEdgeConnect = async () => {
    setLoading(true);
    const { data, error }: { data: ISaltEdgeResponse; error: any } = await getSaltEdgeConnectWorld();
    event(events.connectSaltEdgeWorld);
    setLoading(false);

    if (!error) {
      setSaltEdge(data);

      if (data?.connectUrl) {
        return window.open(data?.connectUrl, '_self');
      }
    }
  };

  const handleRefreshAccount = () => {
    handleSuccess(EProviderType.SALT_EDGE_GATEWAY);
  };

  const renderSaltEdgeModal = () => {
    if (saltEdge) {
      return <SaltEdgeModal saltEdge={saltEdge} saltEdgeModal={saltEdgeModal} handleSuccess={handleRefreshAccount} />;
    }
  };

  return (
    <>
      <button
        className='connected-account-option connected-account-option--blue px-3 py-4'
        type='submit'
        onClick={handleSaltEdgeConnect}
      >
        {loading ? (
          <div className='loading'>
            <span className='spinner-grow' role='status' aria-hidden='true' />
          </div>
        ) : (
          <>
            <span>
              {icon}
            </span>
            <div className='pl-3'>
              <div className='d-flex justify-content-between align-items-center mb-2'>
                <h2 className='connected-account-option__title' >{cardInfo.title}</h2>
                <RightArrow className='arrow' />
              </div>
              <p className='connected-account-option__description'>{cardInfo.description}</p>
            </div>
          </>
        )}
      </button>

      {renderSaltEdgeModal()}
    </>
  );
};

export default ConnectSaltEdgeWorld;
