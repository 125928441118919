import { FormikProps } from 'formik';
import { IInvestingEntity } from 'setting/setting.type';
import { StripeSubscriptionStatus } from 'setting/setting.enum';
import { EConnectionStatus, StringKeyObject } from 'common/common.types';

import { CurrencyOptions } from './enum/currency-options';
import { LiquidityOptions } from './enum/liquidity-options';
import { EmployerMatchLimitOptions } from './enum/employer-match-limit-options';
import { CalculateRealEstateReturnOptions } from './enum/calculate-real-estate-return-options';
import {
  RoleEnum,
  AuthState,
  ETokenType,
  EReferLevel,
  ECompoundPeriod,
  EPaymentFrequency,
  ProviderAccountStatus,
  ProviderAggregationSource,
} from './auth.enum';

export type Dispatch = (args: StringKeyObject) => void;

export interface LoginPayload {
  email: string;
  password: string;
}

export interface ILoginResponse {
  expires: number;
  token: string;
  onboarded: boolean;
  tokenType: ETokenType;
}

export interface RegisterPayload {
  email: string;
  password: string;
  subscriptionPriceId: string;
  mailChimpSubscription: boolean;
}

export interface ResetPasswordPayload {
  password: string;
  token: string;
}

export interface VerifyResetPasswordTokenPayload {
  token: string;
}

export interface UserType {
  [key: string]: any;
}

export interface ProviderAccount {
  aggregationSource: ProviderAggregationSource;
  createdAt: string | Date;
  createdDate: string | Date;
  dataset: any[];
  id: number;
  isManual: boolean;
  profileId: number;
  providerAccountId: number;
  providerId: number;
  requestId: string;
  status: ProviderAccountStatus;
  updatedAt: string | Date;
  lastUpdated: string | Date;
}

// FIXME: remove older fields
export interface IAccountDetails {
  id: number;
  overridden: boolean;
  companySymbol: string;
  currency: CurrencyOptions;
  employerMatchContribution?: boolean | string;
  employerMatch?: number;
  employerMatchLimitIn?: EmployerMatchLimitOptions;
  employerMatchLimit?: number;
  includeEmployerMatch?: boolean | string;
  calculateReturns: boolean;
  estimatedAnnualReturns?: number;
  estimatedAnnualPrincipalReduction: number;
  streetAddress: string;
  showAsInvestmentAsset: boolean;
  isRetirement: boolean;
  is401k: boolean;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  useZestimate: boolean | string;
  ownEstimate: number;
  associatedMortgage: string | number;
  associatedRealEstate: string;
  principalBalance: number;
  principalBalanceCurrency?: string;
  calculatedEquity?: number;
  calculateReturnsOn?: CalculateRealEstateReturnOptions;
  interestRate?: number;
  originationDate?: Date;
  originalBalance?: number;
  maturityDate?: Date | null;
  paymentsPerYear?: number;
  postMoneyValuation?: number;
  currentValuation?: number;
  investedDate?: Date;
  amountInvested?: number;
  currentMarketValue?: number;
  targetInterestRate?: number;
  termForInvestment?: number;
  businessStartDate?: Date;
  associatedLoan?: number;
  estimatedAnnualRevenues?: number;
  separateLoanBalance?: string | boolean;
  separateShortBalance?: boolean;
  liquidity?: LiquidityOptions;
  annualPercentageYield?: number;
  term?: number;
  maturityAmount?: number;
  maturityAmountCurrency?: string;
  amountDue?: number;
  amountDueCurrency?: string;
  dueDate?: string;
  totalCreditLine?: number;
  totalCreditLineCurrency?: string;
  apr?: number;
  frequency?: EPaymentFrequency;
  compoundPeriod?: ECompoundPeriod;
  amortizationPeriod?: number;
  annuityBalance?: number;
  annuityBalanceCurrency?: string;
  '401kLoan'?: number;
  '401kLoanCurrency'?: string;
  totalVestedBalance?: number;
  totalVestedBalanceCurrency?: string;
  totalUnvestedBalance?: number;
  totalUnvestedBalanceCurrency?: string;
  marginBalance?: number;
  marginBalanceCurrency?: string;
  shortBalance?: number;
  shortBalanceCurrency?: string;
  interestRateType?: string;
  escrowBalance?: number;
  escrowBalanceCurrency?: string;
  originalLoanAmount?: number;
  originalLoanAmountCurrency?: string;
  totalCreditLimit?: number;
  totalCreditLimitCurrency?: string;
  loanPayoffAmount: number;
  loanPayoffAmountCurrency?: string;
  loanPayByDate?: string;
  homeValue?: number;
  homeValueCurrency?: string;
  estimatedDate?: string;
  currentBalance?: number;
  currentBalanceCurrency?: string;
  availableBalance?: number;
  availableBalanceCurrency?: string;
  lastPaymentDate?: string;
  displayedName?: string;
  cash?: number;
  cashCurrency?: string;
  lastEmployeeContributionAmount?: number;
  lastEmployeeContributionAmountCurrency?: string;
  lastEmployeeContributionDate?: string;
  lastPaymentAmount?: number;
  lastPaymentAmountCurrency?: string;
  runningBalance?: number;
  runningBalanceCurrency?: string;
  totalCashLimit?: number;
  totalCashLimitCurrency?: string;
  minimumAmountDue?: number;
  minimumAmountDueCurrency?: string;
  cashApr?: number;
  availableCash?: number;
  availableCashCurrency?: string;
  availableCredit?: number;
  availableCreditCurrency?: string;
  estimatedMonthlyIncome?: number;
  estimatedMonthlyExpenses?: number;
  blockchain?: string;
  mmType?: string;
  mmAssetClass?: string;
  mmCountry?: string;
  mmRisk?: string;
  propertyType?: string;
  beds?: number;
  baths?: number;
  ownership?: number;
  industry?: string;
  investmentRound?: string;
  investmentType?: string;
  cap?: number;
  website?: string;
  estimatedMonthlyPayments?: string; // need to calculate
  currentPrice?: string;
  excludeFromAllocation?: boolean;
  excludeFromIncome?: boolean;
  excludeFromPerformance?: boolean;
  recurringPayment?: number;
  premium?: number;
  policyEffectiveDate?: Date | string;
  deathBenefit?: number;
  netIncome?: number;
  admin?: number;
  insurance?: number;
  managementFees?: number;
  propertyTaxes?: number;
  associationDues?: number;
  utilities?: number;
  mortgage?: number;
}

export interface IAccountCategory {
  id: number;
  mmCategory: string;
  mmAccountType: string;
  yodleeContainer: string;
  mmAccountSubType: string;
  yodleeAccountType: string;
}

export interface Account {
  id: number;
  yodleeId: number;
  accountName: string;
  accountNumber: string;
  providerName: string;
  providerLogo: string;
  profileId: number;
  category?: IAccountCategory;
  accountDetails: IAccountDetails;
  isManual: boolean;
  balance: number;
  balancesFetchedAt: string;
  holdingsFetchedAt: string;
  currency: string;
  syncError: boolean;
  connectionStatus: EConnectionStatus;
  hasHoldings: boolean;
  providerAccount: ProviderAccount | null;
  mmAccountProvider: EProviderType;
  investingEntityId?: string | number;
  investingEntity: IInvestingEntity;
  mmAssetClass: string;
  mmCountry: string;
  mmRisk: string;
  excludeFromAllocation: boolean;
  excludeFromIncome?: boolean;
  excludeFromPerformance?: boolean;
  isIgnored: boolean;
  isArchived: boolean;
  providerId: number;
  holdingValue: number;
  holdingValueCurrency: string;
  providerAccountId?: number;
}

export interface ProfileDetails {
  dependants: number;
  dob: string | Date;
  minxWinks: boolean;
  id: number | string;
  maritalStatus: string;
  riskTolerance: string;
  minxMeasureUp: boolean;
  alreadyRetired: boolean;
  householdIncome: string;
  profileEnabled: boolean;
  spouseDob: string | Date;
  shareAssetValues: boolean;
  countryOfResidence: string;
  shareAssetAllocation: boolean;
  spouseAlreadyRetired: boolean;
  targetedRetirementAge: number;
  spouseTargetedRetirementAge: number;
}

export interface ProfileType {
  roles: any;
  bio: string;
  role: string;
  uuid: string;
  email: string;
  picture: string;
  website: string;
  lastName: string;
  username: string;
  firstName: string;
  id: string | number;
  investingSince: number;
  profileEnabled: boolean;
  profileDetails: ProfileDetails;
  referralName: string;
  referralLevel: EReferLevel;
}

export interface Subscription {
  cancelAt: Date | null;
  customerId: string;
  id: number;
  name: string;
  priceId: string;
  subscriptionEnd: number | string;
  subscriptionId: string;
  subscriptionStatus: string;
}

export interface SubscriptionDetail {
  active: boolean;
  currency: string;
  details: Record<string, string>;
  duration: string;
  name: string;
  nickname: string;
  price: number;
  priceId: string;
}

export interface ICurrentSubscription {
  cancelAt: string | null;
  createdAt: string;
  customerId: string;
  id: number;
  name: string;
  priceId: string;
  subscriptionEnd: number;
  subscriptionId: string;
  subscriptionStatus: StripeSubscriptionStatus;
  updatedAt: string | null;
}

export interface AuthType {
  loading: boolean;
  email: string;
  token?: string;
  expires?: number;
  onboarded?: boolean;
  user?: ProfileType;
  roles?: RoleEnum[];
  authState: AuthState;
  isSigningIn: boolean;
  accounts?: Account[];
  isAuthenticated: boolean;
  currentSubscription?: ICurrentSubscription;
  subscriptionDetail?: SubscriptionDetail;
}

export interface LoginFormProps {
  props: FormikProps<LoginPayload>;
}

export interface RegisterFormProps {
  props: FormikProps<RegisterPayload>;
}

export interface LoginServicePayload {
  dispatch: Dispatch;
  payload: LoginPayload;
}

export interface FBAssociationPayload {
  dispatch: Dispatch;
  token: string;
}
export interface RegisterServicePayload {
  dispatch: Dispatch;
  payload: RegisterPayload;
}

export interface ChangePasswordServicePayload {
  dispatch: Dispatch;
  payload: {
    newPassword: string;
    oldPassword: string;
  };
}

export interface DeleteAccountPayload {
  dispatch: Dispatch;
  accounts: Account[];
}

export interface Mortgage {
  id: number | string;
  accountName: string;
  balance: number;
  principalBalance: number;
}

export type MortgageList = Mortgage[];

export interface LoanAccount {
  accountName: string;
  balance: number;
  id: number;
}

export type loanAccounts = LoanAccount[];

export interface IRealEstateAccount {
  id: number | string;
  balance: number | string;
  accountName: string;
}

export enum EProviderType {
  QROKA = 'QROKA',
  ZABO = 'ZABO',
  YODLEE = 'YODLEE',
  MANUAL = 'MANUAL',
  SALT_EDGE_PARTNER = 'SALT_EDGE_PARTNER',
  SALT_EDGE_GATEWAY = 'SALT_EDGE_GATEWAY',
}

export interface IFormFieldItem {
  label: string;
  name: string;
}

export interface IFormField {
  cash: IFormFieldItem;
  deposits: IFormFieldItem;
  securities: IFormFieldItem;
  cryptocurrencies: IFormFieldItem;
  forex: IFormFieldItem;
  realEstate: IFormFieldItem;
  collectibles: IFormFieldItem;
  nfTs: IFormFieldItem;
  businesses: IFormFieldItem;
  stockOptions: IFormFieldItem;
  domainNames: IFormFieldItem;
  startups: IFormFieldItem;
  crowdfunding: IFormFieldItem;
  creditCards: IFormFieldItem;
  mortgages: IFormFieldItem;
  loans: IFormFieldItem;
  insurance: IFormFieldItem;
  preciousMetals: IFormFieldItem;
  privateEquityFunds: IFormFieldItem;
}

export type TKeyFormField = keyof IFormField;

export type TSettingFormField = Partial<IAccountDetails & IAccountCategory & Account>;

export type TPartialFormKeys = Partial<keyof TSettingFormField>;

export interface IManualAccount {
  mmAccountType: string;
  mmAccountSubType: string;
  investingEntityId: string | number;
  accountName: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  hasHoldings: string | boolean;
  balance?: number;
  currency: string;
  liquidity: string;
  useZestimate: string | boolean;
}
