import React from 'react';
import { Link } from 'react-router-dom';

import { Formik } from 'formik';
import ICONS from 'common/components/icons';
import classNames from 'common/classes.helper';
import { EProviderType } from 'auth/auth.types';
import ISVGImages from 'common/components/images';
import useMetamask from 'common/hooks/useMetamask';
import { ECryptoConnections } from 'auth/auth.enum';
import useQroka, { IAddress } from 'auth/hooks/useQroka';
import TextInput from 'common/components/input/text.input';
import SecurityImages from 'auth/components/security-images';
import { addCryptoAccountValidation } from 'auth/auth.validation';
import ConnectLedger from 'auth/crypto-components/connect-ledger';
import ConnectGemini from 'auth/crypto-components/connect-gemini';
import { Modal, ModalType, useModal } from 'common/components/modal';
import ConnectVinovest from 'auth/crypto-components/connect-vinovest';
import TerraStationConnect from 'auth/crypto-components/terra-station-connect';
import ConnectTrustWallet from 'auth/crypto-components/connect-trust-wallet';
import ConnectCoinbaseWallet from 'auth/crypto-components/connect-coinbase-wallet';
import { cryptoConnections, TCryptoConnection } from 'auth/data/crypto-connections';
import { getNetworkName, getNetworkTitle, isMetamaskSupported } from 'auth/auth.helper';
import bittrexApiKeyInstructions from 'auth/data/connect-instructions/bittrex-apikey-instructions';
import {
  binanceApiKeyInstructions,
  binanceUSKeyInstructions,
  coinbaseProApiInstructions,
  celsiusApiInstructions,
} from 'auth/data/connect-instructions';

import WalletCard from './wallet-card';

interface ICryptoConnectionModal {
  cryptoConnectionModal: ModalType;
  handleSuccess: (provider: EProviderType, isQroka: boolean) => Promise<void>;
}

const CryptoConnectionModalComponent: React.FC<ICryptoConnectionModal> = ({ cryptoConnectionModal, handleSuccess }) => {
  const [connection, setConnection] = React.useState<TCryptoConnection | null>(null);

  const handleConnectionClick = (con: TCryptoConnection) => {
    setConnection(con);
  };

  const handleOnConnectionURL = (url: string) => {
    window.open(url, '_self');
  };

  const handleBackAction = () => {
    if (connection) {
      return setConnection(null);
    }

    return cryptoConnectionModal.close();
  };

  const onSuccess = () => {
    handleSuccess(EProviderType.QROKA, true);
  };

  const renderContent = () => {
    if (connection) {
      const connectionElements: Partial<Record<ECryptoConnections, JSX.Element>> = {
        [ECryptoConnections.COINBASE]: (
          <ConnectCoinbase onConnectionURL={handleOnConnectionURL} onCancel={cryptoConnectionModal.close} />
        ),
        [ECryptoConnections.BINANCE]: (
          <ConnectWithAPIKey connection={connection} onCancel={cryptoConnectionModal.close} handleSuccess={onSuccess} />
        ),
        [ECryptoConnections.CELSIUS]: (
          <ConnectWithAPIKey connection={connection} onCancel={cryptoConnectionModal.close} handleSuccess={onSuccess} />
        ),
        [ECryptoConnections.BINANCE_US]: (
          <ConnectWithAPIKey connection={connection} onCancel={cryptoConnectionModal.close} handleSuccess={onSuccess} />
        ),
        [ECryptoConnections.BITTREX]: (
          <ConnectWithAPIKey connection={connection} onCancel={cryptoConnectionModal.close} handleSuccess={onSuccess} />
        ),
        [ECryptoConnections.COINBASE_PRO]: (
          <ConnectWithAPIKey connection={connection} onCancel={cryptoConnectionModal.close} handleSuccess={onSuccess} />
        ),
        [ECryptoConnections.ETHEREUM]: (
          <ConnectWithAPIKey connection={connection} onCancel={cryptoConnectionModal.close} handleSuccess={onSuccess} />
        ),
        [ECryptoConnections.BITCOIN]: (
          <ConnectWithAPIKey connection={connection} onCancel={cryptoConnectionModal.close} handleSuccess={onSuccess} />
        ),
        [ECryptoConnections.BINANCE_SMART_CHAIN]: (
          <ConnectWithAPIKey connection={connection} onCancel={cryptoConnectionModal.close} handleSuccess={onSuccess} />
        ),
        [ECryptoConnections.TERRA]: (
          <ConnectWithAPIKey connection={connection} onCancel={cryptoConnectionModal.close} handleSuccess={onSuccess} />
        ),
        [ECryptoConnections.METAMASK]: (
          <ConnectMetamask handleSuccess={onSuccess} onCancel={cryptoConnectionModal.close} />
        ),
        [ECryptoConnections.COINBASE_WALLET]: (
          <ConnectCoinbaseWallet handleSuccess={onSuccess} onCancel={cryptoConnectionModal.close} />
        ),
        [ECryptoConnections.TERRA_STATION_WALLET]: (
          <TerraStationConnect handleSuccess={onSuccess} onCancel={cryptoConnectionModal.close} />
        ),
        [ECryptoConnections.TRUST_WALLET]: (
          <ConnectTrustWallet handleSuccess={onSuccess} onCancel={cryptoConnectionModal.close} />
        ),
        [ECryptoConnections.LEDGER]: <ConnectLedger handleSuccess={onSuccess} onCancel={cryptoConnectionModal.close} />,
        [ECryptoConnections.GEMINI]: <ConnectGemini handleSuccess={onSuccess} onCancel={cryptoConnectionModal.close} />,
        [ECryptoConnections.VINOVEST]: (
          <ConnectVinovest handleSuccess={onSuccess} onCancel={cryptoConnectionModal.close} />
        ),
      };

      return connectionElements[connection.type];
    }

    return (
      <>
        <div className='wallet-wrapper'>
          {cryptoConnections.map((con: TCryptoConnection, index) => (
            <WalletCard
              key={index}
              url={con.url}
              name={con.name}
              logo={con.logo}
              contain={false}
              connection={con.type}
              onClick={() => handleConnectionClick(con)}
            />
          ))}
        </div>
        <div className='rest-info'>
          <div className='rest-info-title'>More connections coming soon!</div>
          <div className='rest-info-text'>
            We are adding new connections weekly. To request a specific wallet or alt,{' '}
            <Link to='/community' className='purple-links' target='_blank'>
              post in the community.
            </Link>
          </div>
        </div>
        <div className='security-images-container'>
          <SecurityImages />
        </div>
      </>
    );
  };

  const getCryptoModalContent = () => {
    return (
      <div className='crypto-connection-modal-wrapper'>
        <div className='sub-title'>
          Connect Money Minx with your crypto wallet/address or alt account (scroll for more options)
        </div>
        {renderContent()}
      </div>
    );
  };

  return (
    <Modal
      {...cryptoConnectionModal.props}
      size='lg'
      canBeClosed
      hasBackOption
      title='Crypto & Alts'
      onBack={handleBackAction}
    >
      {getCryptoModalContent()}
    </Modal>
  );
};

interface IConnectCoinbase {
  onCancel: () => void;
  onConnectionURL: (url: string) => void;
}

const ConnectCoinbase = (props: IConnectCoinbase) => {
  const { loading, getConnectionURL } = useQroka();

  const getCoinbaseConnectionURL = async () => {
    const { data, error } = await getConnectionURL();

    if (!error && data) {
      return props.onConnectionURL(data.connectionURL);
    }
  };

  return (
    <div className='connect-crypto-wrapper'>
      <WalletCard
        disabled
        name='Coinbase'
        url='www.coinbase.com'
        logo={<ISVGImages.CoinbaseLogo />}
        connection={ECryptoConnections.COINBASE}
      />

      <div className='key-info'>
        Click on connect account below to open Coinbase and authorize Money Minx to connect to your account.
      </div>

      <div className='crypto-action-wrapper'>
        <button type='reset' className='btn-outline-primary mm-btn-animate' disabled={loading} onClick={props.onCancel}>
          Cancel
        </button>

        <button
          type='submit'
          disabled={loading}
          onClick={getCoinbaseConnectionURL}
          className='mm-btn-animate mm-btn-primary '
        >
          {loading ? (
            <>
              <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
              <span className='ml-1'>Connecting...</span>
            </>
          ) : (
            <> Connect Account</>
          )}
        </button>
      </div>
    </div>
  );
};

interface IConnectMetamask {
  onCancel: () => void;
  handleSuccess: () => void;
}

const supportedChains = [1, 56];

const isValidChainId = (id?: number) => supportedChains.includes(id || 0);

const ConnectMetamask = (props: IConnectMetamask) => {
  const { initialize, accounts, chainId, switchNetwork } = useMetamask();
  const { connectMetamask, loading, errors } = useQroka();

  const connection = cryptoConnections.find((con) => con.type === ECryptoConnections.METAMASK);

  if (!connection) {
    return <div>Connection not available</div>;
  }

  const handleMetamaskConnection = async () => {
    const addresses: IAddress[] = Object.entries(accounts)
      .map(([chain, accountList]: [string, string[]]) => {
        return accountList?.map((acc) => ({
          addressType: getNetworkName(+chain),
          address: acc,
        }));
      })
      .flat();

    await connectMetamask(addresses)(props.handleSuccess);
  };

  const renderSupportText = () => {
    if (!isMetamaskSupported()) {
      return <div className='error-block'>MetaMask is not available in Safari please try another browser</div>;
    }

    if (chainId) {
      return <div className='key-info'>Click on the buttons below to sync your BSC and/or ETH wallets</div>;
    }

    return <div className='key-info'>Click on Connect MetaMask to open MetaMask and connect your wallet</div>;
  };

  const renderAccounts = () => {
    if (!chainId) {
      return null;
    }

    if (isValidChainId(chainId)) {
      return (
        <div className='chain-accounts-wrapper'>
          {Object.keys(accounts).map((id) => (
            <div key={id}>
              <div className='chain-title'>{getNetworkTitle(+id)}</div>
              <div className='accounts-wrapper'>
                {accounts[id as any]?.map((acc) => (
                  <p key={acc}>{acc}</p>
                ))}
              </div>
            </div>
          ))}
        </div>
      );
    }

    return <div className='error-block'>Oops! we don't support this network yet.</div>;
  };

  const handleConnect = (con?: ECryptoConnections) => {
    if (con) {
      return switchNetwork(con);
    }

    return initialize();
  };

  const renderConnectButtons = () => {
    if (chainId) {
      return (
        <div className='connect-button-wrapper multiple'>
          <button
            disabled={loading}
            onClick={() => handleConnect(ECryptoConnections.ETHEREUM)}
            className='mm-btn-primary mm-btn-animate'
          >
            Sync ETH Accounts
          </button>
          <button
            disabled={loading}
            onClick={() => handleConnect(ECryptoConnections.BINANCE_SMART_CHAIN)}
            className='mm-btn-primary mm-btn-animate'
          >
            Sync BSC Accounts
          </button>
        </div>
      );
    }

    return (
      isMetamaskSupported() && (
        <div className='connect-button-wrapper'>
          <button disabled={loading} onClick={() => handleConnect()} className='mm-btn-primary mm-btn-animate'>
            Connect MetaMask
          </button>
        </div>
      )
    );
  };

  return (
    <div className='connect-crypto-wrapper'>
      <WalletCard
        disabled
        name={connection.name}
        url={connection.url}
        logo={connection.logo}
        connection={connection.type}
      />
      {renderSupportText()}
      {renderConnectButtons()}
      {renderAccounts()}
      {errors.Metamask ? <div className='error-block'>{errors.Metamask}</div> : null}
      <div className='crypto-action-wrapper'>
        <button type='reset' className='btn-outline-primary mm-btn-animate' disabled={loading} onClick={props.onCancel}>
          Cancel
        </button>

        <button
          type='submit'
          onClick={handleMetamaskConnection}
          disabled={!isValidChainId(chainId)}
          className='mm-btn-animate mm-btn-primary '
        >
          {loading ? (
            <>
              <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
              <span className='ml-1'>Adding...</span>
            </>
          ) : (
            <>Add Accounts</>
          )}
        </button>
      </div>
    </div>
  );
};

interface IConnectWithAPIKey {
  onCancel: () => void;
  handleSuccess: () => void;
  connection: TCryptoConnection;
}

const ConnectWithAPIKey = (props: IConnectWithAPIKey) => {
  const { connection } = props;
  const detailModal = useModal();

  const isEthereumAddress = ECryptoConnections.ETHEREUM === connection.type;
  const isBitcoinAddress = ECryptoConnections.BITCOIN === connection.type;
  const isBSCAddress = ECryptoConnections.BINANCE_SMART_CHAIN === connection.type;
  const isTerraAddress = ECryptoConnections.TERRA === connection.type;

  const renderInfoSection = () => {
    if (isEthereumAddress) {
      return (
        <div className='key-info'>
          Enter your Ethereum blockchain address and click on Connect Account to start syncing
        </div>
      );
    }

    if (isBitcoinAddress) {
      return (
        <div className='key-info'>
          Enter your Bitcoin (classic, xPub or SegWit) blockchain address and click on Connect Account to start syncing
        </div>
      );
    }

    if (isBSCAddress) {
      return (
        <div className='key-info'>
          Enter your Binance Smart Chain address and click on Connect Account to start syncing
        </div>
      );
    }

    if (isTerraAddress) {
      return <div className='key-info'>Enter your Terra address and click on Connect Account to start syncing</div>;
    }

    return (
      <div className='key-info'>
        Get API keys from {connection.name} and enter them below.{' '}
        <Link to='#' onClick={detailModal.open}>
          Click here
        </Link>{' '}
        for more details.
      </div>
    );
  };

  const renderConnectionContent = () => {
    return (
      <div className='connect-crypto-wrapper'>
        <WalletCard
          disabled
          name={connection.name}
          url={connection.url}
          logo={connection.logo}
          connection={connection.type}
        />
        {renderInfoSection()}
        <ApiKeyForm handleSuccess={props.handleSuccess} onCancel={props.onCancel} connectionType={connection.type} />
      </div>
    );
  };

  return (
    <div className='connect-binance-wrapper'>
      {renderConnectionContent()}
      {detailModal.props.open ? <DetailModal detailModal={detailModal} connection={connection} /> : null}
    </div>
  );
};

interface IDetailModal {
  detailModal: ModalType;
  connection: TCryptoConnection;
}

const DetailModal = (props: IDetailModal) => {
  const { detailModal } = props;

  const getInstructions = () => {
    const instructionsMap: Partial<Record<ECryptoConnections, string[]>> = {
      [ECryptoConnections.BINANCE]: binanceApiKeyInstructions,
      [ECryptoConnections.BINANCE_US]: binanceUSKeyInstructions,
      [ECryptoConnections.BITTREX]: bittrexApiKeyInstructions,
      [ECryptoConnections.COINBASE_PRO]: coinbaseProApiInstructions,
      [ECryptoConnections.CELSIUS]: celsiusApiInstructions,
    };

    return instructionsMap[props.connection.type];
  };

  return (
    <Modal
      {...detailModal.props}
      size='sm'
      canBeClosed
      hasBackOption
      onBack={detailModal.close}
      title={props.connection.name}
    >
      <div className='crypto-connection-details'>
        <div className='key-intro'>
          Use the steps below to get your keys from {props.connection.name}. If you need help please contact us.
        </div>
        <div className='key-steps'>
          <ul>
            {getInstructions()?.map((step, index) => (
              <li key={index}>
                <div className='step-number'>{index + 1}.</div>
                <div className='step-text'>{step}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Modal>
  );
};

interface IApiKeyForm {
  onCancel: () => void;
  handleSuccess: () => void;
  connectionType: ECryptoConnections;
}

export const ApiKeyForm = (comProps: IApiKeyForm) => {
  const { connectionType } = comProps;
  const {
    loading,
    errors,
    resetErrors,
    connectBinance,
    connectBittrex,
    connectBinanceUS,
    connectWithAddress,
    connectCoinbasePro,
    connectCelsiusAccount,
  } = useQroka();

  const handleSuccess = () => {
    comProps.handleSuccess();
  };

  return (
    <Formik
      initialValues={{
        apiKey: '',
        apiSecret: '',
        bscAddress: '',
        terraAddress: '',
        apiPassphrase: '',
        bitcoinAddress: '',
        ethereumBlockchainAddress: '',
        connectionType: comProps.connectionType,
      }}
      validationSchema={addCryptoAccountValidation}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);

        if (connectionType === ECryptoConnections.CELSIUS) {
          await connectCelsiusAccount(values.apiKey)(handleSuccess);
        }

        if (connectionType === ECryptoConnections.BINANCE) {
          await connectBinance(values.apiKey, values.apiSecret)(handleSuccess);
        }

        if (connectionType === ECryptoConnections.BINANCE_US) {
          await connectBinanceUS(values.apiKey, values.apiSecret)(handleSuccess);
        }

        if (connectionType === ECryptoConnections.BITTREX) {
          await connectBittrex(values.apiKey, values.apiSecret)(handleSuccess);
        }

        if (connectionType === ECryptoConnections.COINBASE_PRO) {
          await connectCoinbasePro(values.apiKey, values.apiSecret, values.apiPassphrase)(handleSuccess);
        }

        if (connectionType === ECryptoConnections.ETHEREUM) {
          await connectWithAddress(values.ethereumBlockchainAddress, values.connectionType)(handleSuccess);
        }

        if (connectionType === ECryptoConnections.BITCOIN) {
          await connectWithAddress(values.bitcoinAddress, values.connectionType)(handleSuccess);
        }

        if (connectionType === ECryptoConnections.BINANCE_SMART_CHAIN) {
          await connectWithAddress(values.bscAddress, values.connectionType)(handleSuccess);
        }

        if (connectionType === ECryptoConnections.TERRA) {
          await connectWithAddress(values.terraAddress, values.connectionType)(handleSuccess);
        }

        actions.setSubmitting(false);
      }}
    >
      {(props) => {
        type TKey = keyof typeof props.values;

        const fieldMap: Partial<Record<ECryptoConnections, TKey[]>> = {
          [ECryptoConnections.BITCOIN]: ['bitcoinAddress'],
          [ECryptoConnections.CELSIUS]: ['apiKey'],
          [ECryptoConnections.TERRA]: ['terraAddress'],
          [ECryptoConnections.BINANCE]: ['apiKey', 'apiSecret'],
          [ECryptoConnections.BITTREX]: ['apiKey', 'apiSecret'],
          [ECryptoConnections.BINANCE_US]: ['apiKey', 'apiSecret'],
          [ECryptoConnections.BINANCE_SMART_CHAIN]: ['bscAddress'],
          [ECryptoConnections.ETHEREUM]: ['ethereumBlockchainAddress'],
          [ECryptoConnections.COINBASE_PRO]: ['apiKey', 'apiSecret', 'apiPassphrase'],
        };

        const hasField = (key: TKey) => fieldMap[connectionType]?.includes(key);

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          resetErrors();
          props.handleChange(e);
        };

        const hasSomeValues = () =>
          Object.entries(props.values)
            .filter(([key, _]) => key !== 'connectionType')
            .map(([_, value]) => value)
            .some(Boolean);

        const isSubmitDisabled = props.isSubmitting || !props.isValid || !hasSomeValues();

        return (
          <div className='binance-form-wrapper'>
            <form onSubmit={props.handleSubmit} onReset={props.handleReset}>
              <TextInput
                type='password'
                name='bitcoinAddress'
                autoComplete='new-password'
                handleChange={handleChange}
                handleOnBlur={props.handleBlur}
                addOn={<ICONS.LOCK_NO_CIRCLE />}
                title='Bitcoin Blockchain Address'
                value={props.values.bitcoinAddress}
                error={!!props.errors.bitcoinAddress}
                placeholder='Bitcoin Blockchain Address'
                helperText={props.errors.bitcoinAddress}
                className={classNames(!hasField('bitcoinAddress') && 'hide-me')}
                tooltipMessage='Your address is safely stored in an encrypted database and then encrypted again for added security.'
              />
              <TextInput
                type='password'
                name='bscAddress'
                autoComplete='new-password'
                handleChange={handleChange}
                handleOnBlur={props.handleBlur}
                addOn={<ICONS.LOCK_NO_CIRCLE />}
                title='Binance Smart Chain Address'
                value={props.values.bscAddress}
                error={!!props.errors.bscAddress}
                placeholder='Binance Smart Chain Address'
                helperText={props.errors.bscAddress}
                className={classNames(!hasField('bscAddress') && 'hide-me')}
                tooltipMessage='Your address is safely stored in an encrypted database and then encrypted again for added security.'
              />
              <TextInput
                type='password'
                name='terraAddress'
                autoComplete='new-password'
                handleChange={handleChange}
                handleOnBlur={props.handleBlur}
                addOn={<ICONS.LOCK_NO_CIRCLE />}
                title='Terra Address'
                value={props.values.terraAddress}
                error={!!props.errors.terraAddress}
                placeholder='Terra Address'
                helperText={props.errors.terraAddress}
                className={classNames(!hasField('terraAddress') && 'hide-me')}
                tooltipMessage='Your address is safely stored in an encrypted database and then encrypted again for added security.'
              />
              <TextInput
                type='password'
                autoComplete='new-password'
                handleChange={handleChange}
                handleOnBlur={props.handleBlur}
                name='ethereumBlockchainAddress'
                addOn={<ICONS.LOCK_NO_CIRCLE />}
                title='Ethereum Blockchain Address'
                placeholder='Ethereum Blockchain Address'
                value={props.values.ethereumBlockchainAddress}
                error={!!props.errors.ethereumBlockchainAddress}
                helperText={props.errors.ethereumBlockchainAddress}
                className={classNames(!hasField('ethereumBlockchainAddress') && 'hide-me')}
                tooltipMessage='Your address is safely stored in an encrypted database and then encrypted again for added security.'
              />
              <TextInput
                name='apiKey'
                title='API Key'
                type='password'
                placeholder='API Key'
                autoComplete='new-password'
                value={props.values.apiKey}
                handleChange={handleChange}
                handleOnBlur={props.handleBlur}
                addOn={<ICONS.LOCK_NO_CIRCLE />}
                className={classNames(!hasField('apiKey') && 'hide-me')}
                tooltipMessage='Your keys are safely stored in an encrypted database and then encrypted again for added security.'
              />

              <TextInput
                type='password'
                name='apiSecret'
                title='API Secret'
                placeholder='API Secret'
                autoComplete='new-password'
                handleChange={handleChange}
                value={props.values.apiSecret}
                handleOnBlur={props.handleBlur}
                addOn={<ICONS.LOCK_NO_CIRCLE />}
                className={classNames(!hasField('apiSecret') && 'hide-me')}
                tooltipMessage='Your keys are safely stored in an encrypted database and then encrypted again for added security.'
              />

              <TextInput
                type='password'
                name='apiPassphrase'
                title='API Passphrase'
                autoComplete='new-password'
                handleChange={handleChange}
                placeholder='API Passphrase'
                value={props.values.apiSecret}
                handleOnBlur={props.handleBlur}
                addOn={<ICONS.LOCK_NO_CIRCLE />}
                className={classNames(!hasField('apiPassphrase') && 'hide-me')}
                tooltipMessage='Your keys are safely stored in an encrypted database and then encrypted again for added security.'
              />
              {Object.values(errors)
                .filter(Boolean)
                .map((error, key) => (
                  <div className='error-block' key={key}>
                    {error}
                  </div>
                ))}

              <div className='crypto-action-wrapper'>
                <button
                  type='reset'
                  disabled={props.isSubmitting}
                  onClick={comProps.onCancel}
                  className='btn-outline-primary mm-btn-animate'
                >
                  Cancel
                </button>

                <button className='mm-btn-animate mm-btn-primary ' type='submit' disabled={isSubmitDisabled}>
                  {loading ? (
                    <>
                      <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
                      <span className='ml-1'>Connecting...</span>
                    </>
                  ) : (
                    <> Connect Account</>
                  )}
                </button>
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default CryptoConnectionModalComponent;
