const coinbaseProApiInstructions = [
  'Login to Coinbase Pro',
  'Click on your profile (top right)',
  'Click on API',
  'Click on + New API Key',
  'Enter an API key nickname',
  'Only allow View permission',
  'Copy your passphrase and enter it in Money Minx',
  'Click on Create API key',
  'Authenticate with your Multi Factor',
  'Copy your API Secret and enter it in Money Minx',
  'Click Done',
  'Copy the API Key and enter it in Money Minx',
];

export default coinbaseProApiInstructions;
