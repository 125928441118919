import React from 'react';
import Skeleton from 'react-loading-skeleton';

const OpportunityLoadingSkeleton: React.FC = () => {
  return (
    <div className='row mt-4'>
      <div className='col-12 col-md-6 col-lg-4 mb-4'>
        <Skeleton height={500} />
      </div>
      <div className='col-12 col-md-6 col-lg-4 mb-4'>
        <Skeleton height={500} />
      </div>
      <div className='col-12 col-md-6 col-lg-4 mb-4'>
        <Skeleton height={500} />
      </div>
    </div>
  );
};

export default OpportunityLoadingSkeleton;
