import moment from 'moment';
import groupBy from 'lodash/groupBy';
import { EProviderType } from 'auth/auth.types';
import { camelCase } from 'common/helper/string.helper';
import { EConnectionStatus } from 'common/common.types';

import extraInfoFormFields from './data/extra-info.json';
import { IFormBalance, TExtraInfoKey, IFormFieldItem } from './account.type';

export const groupBalanceByYear = (data: IFormBalance[]) => {
  const groupedBalance = groupBy(data, (formBalance) => getYear(formBalance.date));

  return groupedBalance;
};

export const getYear = (str: string) => str.split('-')[0];

export const parseBalanceData = <T>(data: T[]) => {
  return data.map((datum: any) => ({
    balance: datum.balance,
    date: datum.dateUTC,
  }));
};

export const parseHoldingOriginalValues = <T>(data: T[]) => {
  return data.map((datum: any) => {
    const price = datum.price === null || datum.price === 0 ? datum.price : datum.price || null;
    const quantity = datum.quantity === null || datum.quantity === 0 ? datum.quantity : datum.quantity || null;

    const value = !!price && !!quantity ? price * quantity : 0;

    return {
      date: datum.dateUTC,
      value,
      price,
      quantity,
    };
  });
};

/**
 *
 * @param interval
 * @desc This function will ensure not to pass two dates of same month.
 * This is for rendering on the UI
 */
export const getBalanceDate = (interval: string) => {
  let momentDate = moment();

  if ('Current' !== interval) {
    momentDate = moment(interval, 'MMM YYYY').endOf('month').set('hours', 0);
  }

  return momentDate.format('YYYY-MM-DDTHH:mm:ss');
};

/**
 *
 * @param interval
 * @description This is for sending to the server on its own timezone. (UTC)
 */
export const getBalanceUTCDate = (interval: string) => {
  let momentDate = moment().utc();

  if ('Current' !== interval) {
    momentDate = moment(interval, 'MMM YYYY').endOf('month').set('hours', 0).utc();
  }

  return momentDate.format('YYYY-MM-DDTHH:mm:ss');
};

/**
 * @description Used to get extra info form fields from JSON with account type as key
 * @returns {IFormFieldItem[] | undefined}
 */
export const getExtraInfoFormField = (type: string): IFormFieldItem[] | undefined => {
  const key = camelCase(type) as TExtraInfoKey;

  return extraInfoFormFields[key];
};

export const isRefreshingAccount = (provider?: EProviderType, connectionStatus?: EConnectionStatus) => {
  if (!provider || !connectionStatus) return false;

  const isSaltedgeOrYodlee = [
    EProviderType.YODLEE,
    EProviderType.SALT_EDGE_GATEWAY,
    EProviderType.SALT_EDGE_PARTNER,
  ].includes(provider);
  const hasWaitOrErrorStatus = [EConnectionStatus.WAIT, EConnectionStatus.ERROR].includes(connectionStatus);

  return isSaltedgeOrYodlee && !hasWaitOrErrorStatus;
};

export const isSaltEdgeAccount = (provider?: EProviderType) => {
  if (!provider) return false;

  return provider === EProviderType.SALT_EDGE_GATEWAY || provider === EProviderType.SALT_EDGE_PARTNER;
};

export const isYodleeAccount = (provider?: EProviderType) => {
  if (!provider) return false;

  return provider === EProviderType.YODLEE;
};
