import React from 'react';
import Skeleton from 'react-loading-skeleton';

import useFeaturedPartner from 'dashboard/hooks/useFeaturedPartner';
import { ReactComponent as FeaturedPartnerLogo } from 'assets/images/dashboard/featured-partner.svg';

const FeaturedPartner: React.FC = () => {
  const { loading, featuredPartner } = useFeaturedPartner();

  const renderContent = () => {
    if (loading) {
      return <Skeleton height={400} />;
    }

    if (!featuredPartner) {
      return null;
    }

    const { logoUrl, url, description } = featuredPartner;

    return (
      <div className='featured-partner__content'>
        <a href={url} target='_blank' rel='noreferrer'>
          <img src={logoUrl} alt={description} />
        </a>
      </div>
    );
  };

  return (
    <div className='featured-partner'>
      <div className='featured-partner__header'>
        <FeaturedPartnerLogo />
        <h2>Featured Partner</h2>
      </div>

      {renderContent()}
    </div>
  );
};

export default FeaturedPartner;
