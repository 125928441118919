import React from 'react';

import env from 'app/app.env';

const Gist: React.FC = () => {
  return (
    <form action={`https://data.getgist.com/subscribe/${env.GIST_ID}`} method='post'>
      <div style={{ display: 'none' }}>
        <input name='fields[email]' type='text' placeholder='Enter your email address' required aria-required='true' />
        {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
        <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden='true'>
          <input
            type='text'
            name={`fields[form_${env.GIST_ID}]`}
            tabIndex={-1}
            value=''
            onChange={() => {}}
            placeholder='Gist Input'
          />
        </div>
        <input type='submit' id='gist-form-subscribe' value='Subscribe' className='button' />
      </div>
    </form>
  );
};

export default Gist;
