import React, { useEffect, useRef, useState } from 'react';

import useToast from 'common/hooks/useToast';
import { logger } from 'common/logger.helper';
import { useDispatch } from 'app/app.context';
import { EProviderType } from 'auth/auth.types';
import { IAccountState } from 'account/account.type';
import { parse, serialize, shortId } from 'common/common-helper';
import CircularSpinner from 'common/components/spinner/circular-spinner';
import { resetFastlinkLoading, setFastlinkLoading } from 'app/app.actions';
import { Modal, ModalType, ModalTypeEnum, useModal } from 'common/components/modal';

import useYodlee from './useYodlee';
import { useUserLog } from './useUserLog';
import YodleeErrorModal from './inc/yodlee-error-modal';
import { ECustomYodleeError, getYodleeError } from './helpers';
import ActionAbandonedModal from './inc/action-abandoned.modal';
import { FastLinkOptionsType, YodleeCallBackType, YodleeError, YodleeStatus } from './yodlee.type';

interface Props {
  state?: IAccountState;
  fastLinkModal: ModalType;
  fastLinkOptions: FastLinkOptionsType;
  handleOpenNewConnection?: () => Promise<void>;
  handleSuccess: (provider: EProviderType, isQroka?: boolean, providerAccountId?: number) => void;
}

const FastLinkModal: React.FC<Props> = ({
  state,
  fastLinkModal,
  handleSuccess,
  fastLinkOptions,
  handleOpenNewConnection,
}) => {
  const initRef = useRef<any>();
  const containerRef = useRef<any>(null);

  const { mmToast } = useToast();
  const dispatch = useDispatch();

  const { addUserLog } = useUserLog();
  const yodleeErrorModal = useModal();
  const actionAbandonedModal = useModal();

  const [yodleeError, setYodleeError] = useState<YodleeError | undefined>(undefined);
  const [accountProviderName, setAccountProviderName] = useState<string>('');

  const onSuccess = async (args: YodleeCallBackType) => {
    mmToast('Successfully Logged in with Yodlee', { type: 'success' });
    state?.setStatus('FASTLINK_SUCCESS');
    handleSuccess(EProviderType.YODLEE, false, args.providerAccountId);
    dispatch(resetFastlinkLoading());

    return fastLinkModal.close();
  };

  const onError = (err: any) => {
    dispatch(resetFastlinkLoading());
    state?.setStatus('FASTLINK_ERROR');

    logger.gp('ON ERROR', { err });

    if (err && !err.code) {
      err.code = ECustomYodleeError.UNRESOLVED_ERROR;
    }

    if (err && !err.code && err.errorDetails?.additionalStatus === YodleeError.USER_ACTION_NEEDED_AT_SITE) {
      setYodleeError(YodleeError.USER_ACTION_NEEDED_AT_SITE);
      setAccountProviderName(err.errorDetails?.providerName || '');

      return yodleeErrorModal.open();
    }

    if (err && err.code) {
      const error = getYodleeError(err.code);

      if (!!error) {
        setYodleeError(error);

        if (YodleeError.TECHNICAL_ERROR === error) {
          const { error: parseError, data } = parse(serialize(err));
          const log = parseError ? { message: serialize(err) } : data;
          addUserLog(log);

          return yodleeErrorModal.open();
        }

        return yodleeErrorModal.open();
      }
    }

    const isFailed =
      !err?.isMFAError &&
      err?.status === YodleeStatus.FAILED &&
      err?.additionalStatus !== YodleeError.INCORRECT_CREDENTIALS;

    if (isFailed) {
      setYodleeError(YodleeError.DATA_RETRIEVAL_FAILED);

      return yodleeErrorModal.open();
    }

    const errorList = err
      ? Object.keys(err).map((ek, i) => (
          <li key={i}>
            {[ek]}:{err[ek]}
          </li>
        ))
      : null;

    window.fastlink = undefined;

    return mmToast(<ul>{errorList}</ul>, { type: 'error', autoClose: false });
  };

  const onClose = async (args: any) => {
    logger.gp('on close args', { args });
    // check if yodlee closed the modal with an error
    const additionalStatus = (args?.sites?.[0]?.additionalStatus as string) || '';

    if (additionalStatus === 'TECH_ERROR') {
      setYodleeError(YodleeError.TECHNICAL_ERROR);
      state?.setStatus('FASTLINK_ERROR');

      return yodleeErrorModal.open();
    }

    // check if users closed the modal without adding account
    if (args?.status === YodleeStatus.ACTION_ABANDONED || args?.status === YodleeStatus.USER_CLOSE_ACTION) {
      actionAbandonedModal.open();
    } else {
      dispatch(resetFastlinkLoading());
      state?.setStatus('FASTLINK_CLOSE');

      fastLinkModal.close();
    }

    return (window.fastlink = undefined);
  };

  const handleSuccessClick = (_: YodleeError) => {
    dispatch(resetFastlinkLoading());
    fastLinkModal.close();
    yodleeErrorModal.close();

    if (handleOpenNewConnection) {
      handleOpenNewConnection();
    }

    return (window.fastlink = undefined);
  };

  const onEvent = (args: any) => {
    if ('FASTLINK_INITIATING' === state?.status) {
      state?.setStatus('FASTLINK_INITIATED');
    }
  };

  const { init } = useYodlee({
    fastLinkOptions,
    onSuccess,
    onError,
    onClose,
    containerRef,
    onEvent,
  });

  const token = (fastLinkOptions.token as any) || '';
  const fastLinkURL = fastLinkOptions.fastLinkURL;

  const hasFastlink = !!window.fastlink;

  useEffect(() => {
    if (fastLinkURL !== '' && hasFastlink) {
      initRef?.current?.click();
    }
  }, [fastLinkURL, hasFastlink, fastLinkOptions]);

  const handleInit = () => {
    dispatch(setFastlinkLoading());

    init({ tokenValue: token, tokenType: 'AccessToken' });
  };

  return (
    <>
      <Modal
        title=''
        canBeClosed
        size='fastlink'
        loading={!window.fastlink}
        {...fastLinkModal.props}
        type={ModalTypeEnum.NO_HEADER}
        outsideClickClose={true}
      >
        <div className='fastlink-modal-container'>
          <div id={'fastlinkContainer-' + shortId()} ref={containerRef} />
          {!window.fastlink ? <CircularSpinner /> : <button ref={initRef} onClick={handleInit} className='hidden' />}
        </div>
      </Modal>

      {actionAbandonedModal.props.open && (
        <ActionAbandonedModal actionAbandonedModal={actionAbandonedModal} handleBaseModalClose={fastLinkModal.close} />
      )}

      {yodleeErrorModal.props.open && (
        <YodleeErrorModal
          yodleeError={yodleeError}
          yodleeErrorModal={yodleeErrorModal}
          accProviderName={accountProviderName}
          onSuccessClick={handleSuccessClick}
          handleBaseModalClose={fastLinkModal.close}
        />
      )}
    </>
  );
};

export default FastLinkModal;
