import React from 'react';

import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as LogoImg } from 'assets/icons/logo.svg';
import { ReactComponent as Cancel } from 'assets/icons/cancel.svg';
import { ReactComponent as More } from 'assets/icons/more-icon.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { ReactComponent as Password } from 'assets/icons/password.svg';
import { ReactComponent as Info } from 'assets/images/signup/info.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import { ReactComponent as Refresh } from 'assets/icons/refresh-icon.svg';
import { ReactComponent as Measure } from 'assets/images/networth/measure.svg';
import { ReactComponent as LoginLock } from 'assets/images/login/lock-icon.svg';
import { ReactComponent as LockNoCircle } from 'assets/icons/lock-no-circle.svg';
import { ReactComponent as Norton } from 'assets/images/account/norton_black.svg';
import { ReactComponent as NotLinked } from 'assets/images/account/Not Linked.svg';
import { ReactComponent as NeedsInfo } from 'assets/images/account/Needs Info.svg';
import { ReactComponent as LoginShield } from 'assets/images/login/shield-icon.svg';
import { ReactComponent as SettingsGear } from 'assets/icons/icon-settings-gear.svg';
import { ReactComponent as CheckCircle } from 'assets/images/account/check-circle.svg';
import { ReactComponent as TrustPilot } from 'assets/images/account/trustpilot_black.svg';
import { ReactComponent as ConnectEU } from 'assets/icons/connect-account/connect-eu.svg';
import { ReactComponent as ConnectCHF } from 'assets/icons/connect-account/connect-chf.svg';
import { ReactComponent as ConnectUKDE } from 'assets/icons/connect-account/connect-eu.svg';
import { ReactComponent as ConnectUS } from 'assets/icons/connect-account/connect-us-canada.svg';
import { ReactComponent as SubscriptionWarning } from 'assets/images/subscription/warning.svg';
import { ReactComponent as ConnectWorld } from 'assets/icons/connect-account/connect-world.svg';
import { ReactComponent as CheckCircleGreen } from 'assets/images/account/check-circle-green.svg';

interface IIcon extends React.SVGProps<SVGSVGElement> {
  className?: string;
  onClick?: () => void;
  title?: string;
}

export const MoreIcon: React.FC<IIcon> = (props) => <More {...props} />;
export const InfoIcon: React.FC<IIcon> = (props) => <Info {...props} />;
export const CloseIcon: React.FC<IIcon> = (props) => <Close {...props} />;
export const LogoIcon: React.FC<IIcon> = (props) => <LogoImg {...props} />;
export const CancelIcon: React.FC<IIcon> = (props) => <Cancel {...props} />;
export const NortonIcon: React.FC<IIcon> = (props) => <Norton {...props} />;
export const MeasureIcon: React.FC<IIcon> = (props) => <Measure {...props} />;
export const ArrowUpIcon: React.FC<IIcon> = (props) => <ArrowUp {...props} />;
export const PasswordIcon: React.FC<IIcon> = (props) => <Password {...props} />;
export const LoginLockIcon: React.FC<IIcon> = (props) => <LoginLock {...props} />;
export const ConnectEUIcon: React.FC<IIcon> = (props) => <ConnectEU {...props} />;
export const ArrowDownIcon: React.FC<IIcon> = (props) => <ArrowDown {...props} />;
export const NotLinkedIcon: React.FC<IIcon> = (props) => <NotLinked {...props} />;
export const NeedsInfoIcon: React.FC<IIcon> = (props) => <NeedsInfo {...props} />;
export const ArrowBackIcon: React.FC<IIcon> = (props) => <ArrowBack {...props} />;
export const ConnectCHFIcon: React.FC<IIcon> = (props) => <ConnectCHF {...props} />;
export const TrustPilotIcon: React.FC<IIcon> = (props) => <TrustPilot {...props} />;
export const ConnectUKDEIcon: React.FC<IIcon> = (props) => <ConnectUKDE {...props} />;
export const ConnectUSIcon: React.FC<IIcon> = (props) => <ConnectUS {...props} />;
export const LoginShieldIcon: React.FC<IIcon> = (props) => <LoginShield {...props} />;
export const CheckCircleIcon: React.FC<IIcon> = (props) => <CheckCircle {...props} />;
export const ConnectWorldIcon: React.FC<IIcon> = (props) => <ConnectWorld {...props} />;
export const SettingsGearIcon: React.FC<IIcon> = (props) => <SettingsGear {...props} />;
export const RefreshIcon: React.FC<IIcon> = (props) => <Refresh {...props} />;
export const LockNoCircleIcon: React.FC<IIcon> = (props) => <LockNoCircle {...props} />;
export const CheckCircleGreenIcon: React.FC<IIcon> = (props) => <CheckCircleGreen {...props} />;
export const SubscriptionWarningIcon: React.FC<IIcon> = (props) => <SubscriptionWarning {...props} />;

const ICONS = {
  LOGO: LogoIcon,
  MORE: MoreIcon,
  INFO: InfoIcon,
  CLOSE: CloseIcon,
  CANCEL: CancelIcon,
  NORTON: NortonIcon,
  REFRESH: RefreshIcon,
  ARROW_BACK: ArrowBack,
  ARROW_UP: ArrowUpIcon,
  PASSWORD: PasswordIcon,
  LOGIN_LOCK: LoginLockIcon,
  CONNECT_EU: ConnectEUIcon,
  ARROW_DOWN: ArrowDownIcon,
  NOT_LINKED: NotLinkedIcon,
  NEEDS_INFO: NeedsInfoIcon,
  CONNECT_CHF: ConnectCHFIcon,
  TRUST_PILOT: TrustPilotIcon,
  CONNECT_US: ConnectUSIcon,
  CONNECT_UKDE: ConnectUKDEIcon,
  LOGIN_SHIELD: LoginShieldIcon,
  CHECK_CIRCLE: CheckCircleIcon,
  CONNECT_WORLD: ConnectWorldIcon,
  SETTINGS_GEAR: SettingsGearIcon,
  LOCK_NO_CIRCLE: LockNoCircleIcon,
  CHECK_CIRCLE_GREEN: CheckCircleGreenIcon,
  SUBSCRIPTION_WARNING: SubscriptionWarningIcon,
};

export default ICONS;
