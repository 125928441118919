import { useState, useEffect, useRef, useCallback, useMemo } from 'react';

import {
  IDashboardAccountWithStatus,
  IDashboardAccountHistoryValueMap,
  IDashboardAccountHistoryResponse,
} from 'dashboard/dashboard.types';
import { Account } from 'auth/auth.types';
import useSocket from 'networth/hooks/useSocket';
import useInterval from 'common/hooks/useInterval';
import { EConnectionStatus } from 'common/common.types';
import { getComparisonDate } from 'dashboard/dashboard.helper';
import { EDashboardAccountStatus } from 'dashboard/dashboard.enum';
import { getAccountWithProvider, getAccountsHistory } from 'api/request.api';

import { EIntervalOption } from '../dashboard.enum';

const useDashboardAccounts = (interval: EIntervalOption) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [historyLoading, setHistoryLoading] = useState<boolean>(false);

  const [allAccounts, setAllAccounts] = useState<Account[]>([]);
  const [activeAccounts, setActiveAccounts] = useState<IDashboardAccountWithStatus[]>([]);
  const [archivedAccounts, setArchivedAccounts] = useState<IDashboardAccountWithStatus[]>([]);

  const [dashboardAccHistoryMap, setDashboardAccountHistoryMap] = useState<IDashboardAccountHistoryValueMap | null>(
    null
  );

  const socket = useSocket();

  const updatingAccounts = useRef<any>(null);

  // WS logics
  const updateAccount = ({ accountId, connectionStatus }: Record<any, any>) => {
    if (!updatingAccounts?.current) {
      updatingAccounts.current = {};
    }

    if (updatingAccounts?.current[accountId]) {
      return;
    }

    updatingAccounts.current[accountId] = connectionStatus;
  };

  const updateAccountCallback = useCallback(updateAccount, []);

  useEffect(() => {
    socket?.on('account-status-update', updateAccountCallback);
  }, [socket, updateAccountCallback]);

  const updateAccountOnInterval = () => {
    const updatingAccountList: Account[] = [];
    let accountList = [...allAccounts];

    if (updatingAccounts?.current) {
      Object.keys(updatingAccounts.current).forEach((accountId) => {
        const account = accountList.find((acc) => +acc.id === +accountId);

        if (account) {
          account.connectionStatus = updatingAccounts.current[accountId];
          updatingAccountList.push(account);
          accountList = accountList.filter((acc) => +acc.id !== +accountId);
        }
      });

      updatingAccounts.current = {};
    }

    if (updatingAccountList.length) {
      setAllAccounts([...accountList, ...updatingAccountList]);
    }
  };

  const accMemo = useMemo(() => allAccounts, [allAccounts]);

  useInterval(updateAccountOnInterval, [accMemo]);
  //

  useEffect(() => {
    const fetchAllAccounts = async () => {
      setLoading(true);

      const { data, error }: { data: Account[]; error: any } = await getAccountWithProvider();

      if (!error && data) {
        setAllAccounts(data);
      }

      setLoading(false);
    };

    fetchAllAccounts();
  }, []);

  useEffect(() => {
    const fetchAccountsHistory = async () => {
      const params: Record<string, boolean | string> = {
        baseCurrency: true,
      };

      if (interval === EIntervalOption.ALL) {
        params.lastHistory = true;
      } else {
        params.date = getComparisonDate(interval).toISOString();
      }

      setHistoryLoading(true);
      const { data, error }: { data: IDashboardAccountHistoryResponse; error: any } = await getAccountsHistory(params);

      const accHistoryMap: IDashboardAccountHistoryValueMap = {};

      if (!error && data) {
        data.accounts.forEach((acc) => {
          accHistoryMap[acc.accountId] = acc.balance;
        });

        setDashboardAccountHistoryMap(accHistoryMap);
      }

      setHistoryLoading(false);
    };

    fetchAccountsHistory();
  }, [interval]);

  useEffect(() => {
    const successProviders = ['ZABO', 'SALT_EDGE_PARTNER', 'SALT_EDGE_GATEWAY'];
    const warningStatuses = [EConnectionStatus.ATTENTION, EConnectionStatus.STALE];

    const getDashboardAccStatus = (acc: Account) => {
      if (
        acc.isManual ||
        acc.connectionStatus === EConnectionStatus.GOOD ||
        acc.connectionStatus === EConnectionStatus.WAIT ||
        successProviders.includes(acc.mmAccountProvider)
      ) {
        return EDashboardAccountStatus.SUCCESS;
      }

      if (warningStatuses.includes(acc.connectionStatus as EConnectionStatus)) {
        return EDashboardAccountStatus.WARN;
      }

      if (acc.connectionStatus === EConnectionStatus.ERROR) {
        return EDashboardAccountStatus.ERROR;
      }

      if (acc.connectionStatus === EConnectionStatus.REFRESHING) {
        return EDashboardAccountStatus.REFRESHING;
      }

      if (acc.isArchived) {
        return EDashboardAccountStatus.ARCHIVED;
      }
    };

    const archivedAccs = allAccounts
      .filter((acc: Account) => acc.isArchived)
      .map((item): IDashboardAccountWithStatus => ({ ...item, status: EDashboardAccountStatus.ARCHIVED }));

    const activeAccs: IDashboardAccountWithStatus[] = [];

    allAccounts
      .filter((acc: Account) => !acc.isArchived)
      .forEach((item) => {
        const status = getDashboardAccStatus(item);

        if (status) {
          activeAccs.push({
            ...item,
            status,
          });
        }
      });

    setArchivedAccounts(archivedAccs);
    setActiveAccounts(activeAccs);
  }, [allAccounts]);

  return { loading, historyLoading, allAccounts, archivedAccounts, activeAccounts, dashboardAccHistoryMap };
};

export default useDashboardAccounts;
