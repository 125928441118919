import { storage } from 'app/app.storage';
import { StorageKey } from 'app/app.types';
import { ApiResponse } from 'api/api.types';
import {
  postLogin,
  getProfile,
  getAccount,
  postRegister,
  deleteAccount,
  getAccountRefresh,
  getConnectionInfo,
  patchChangePassword,
  postFacebookAssociation,
} from 'api/request.api';

import {
  Dispatch,
  LoginPayload,
  EProviderType,
  ILoginResponse,
  FBAssociationPayload,
  DeleteAccountPayload,
  RegisterServicePayload,
  ChangePasswordServicePayload,
} from './auth.types';
import { ETokenType } from './auth.enum';
import { auth } from './auth-context.types';
import { resetAuthLoading, setAccountSuccess, setAuthLoading, setLoginSuccess } from './auth.actions';

export const login =
  (dispatch: Dispatch) =>
  async (payload: LoginPayload): Promise<ApiResponse> => {
    dispatch({ type: auth.LOGIN });

    const { data, error } = await postLogin(payload);

    if (error) {
      storage.clear(StorageKey.AUTH);
      dispatch({ type: auth.LOGIN_FAILURE });
    } else {
      if (data?.tokenType === ETokenType.BEARER) {
        storage.set(StorageKey.AUTH, data);
        await getRefreshedAccount({ dispatch });
      }

      if (data) {
        dispatch(
          setLoginSuccess({
            token: data.token,
            expires: data.expires,
            onboarded: data.onboarded,
            tokenType: data.tokenType,
          })
        );
      }
    }

    return { data, error };
  };

export const associateFacebookUser = async ({ dispatch, token }: FBAssociationPayload): Promise<ApiResponse> => {
  dispatch({ type: auth.LOGIN });

  const { data, error } = await postFacebookAssociation(token);
  if (error) {
    storage.clear(StorageKey.AUTH);
    dispatch({ type: auth.LOGIN_FAILURE });
  }

  if (!error && data) {
    dispatch(
      setLoginSuccess({
        token: data.token,
        expires: data.expires,
        onboarded: data.onboarded,
        tokenType: data.tokenType,
      })
    );
  }

  return { data, error };
};

export const signup = async ({ dispatch, payload }: RegisterServicePayload): Promise<ApiResponse> => {
  dispatch({ type: auth.REGISTER });
  const { data, error } = await postRegister(payload);
  if (error) {
    dispatch({ type: auth.REGISTER_FAILURE });
  } else {
    dispatch(
      setLoginSuccess({
        token: data?.token,
        expires: data?.expires,
        onboarded: data?.onboarded,
        tokenType: data?.tokenType,
      })
    );
  }

  return { data, error };
};

/**
 * @description get refreshed account compels the server to hit the yodlee each time
 *  - limiting this to login success and yodlee modal success
 * @param dispatch
 */

interface IGetRefreshAccount {
  dispatch: Dispatch;
  provider?: EProviderType;
  providerAccountId?: number;
}

export const getRefreshedAccount = async ({
  dispatch,
  provider,
  providerAccountId,
}: IGetRefreshAccount): Promise<ApiResponse> => {
  const token = storage.accessToken();

  if (!token) {
    console.error('Get Refresh Account: No token found');
    return { data: null, error: { status: true, message: 'Token not found' } };
  }

  dispatch({ type: auth.FETCH_ACCOUNT });
  const { data, error } = await getAccountRefresh({ mmAccountProvider: provider, providerAccountId });

  if (error) {
    dispatch({ type: auth.FETCH_ACCOUNT_FAILURE });
  } else {
    dispatch(setAccountSuccess(data));
  }

  return { data, error };
};

/**
 * @param dispatch
 * @description Fetch Connection Info will be called for getting connection info
 * fetch the data from the database instead of calling yodlee
 */
export const fetchConnectionInfo = async ({ dispatch }: { dispatch: Dispatch }): Promise<ApiResponse> => {
  const token = storage.accessToken();

  if (!token) {
    console.error('Fetch connection info: No token found');
    return { data: null, error: { status: true, message: 'Token not found' } };
  }

  dispatch({ type: auth.FETCH_ACCOUNT });
  const { data, error } = await getConnectionInfo();

  if (error) {
    dispatch({ type: auth.FETCH_ACCOUNT_FAILURE });
  } else {
    dispatch(setAccountSuccess(data));
  }

  return { data, error };
};

export const deleteAccounts = async ({ dispatch, accounts }: DeleteAccountPayload): Promise<ApiResponse> => {
  const deleteAccountList = async () => {
    return Promise.all(
      accounts.map(async (account) => {
        await deleteAccount(`${account.id}`);
      })
    );
  };

  await deleteAccountList();
  const { data, error } = await getAccount();

  if (error) {
    dispatch({ type: auth.FETCH_ACCOUNT_FAILURE });

    return { data, error };
  }

  dispatch(setAccountSuccess(data));

  return { data, error };
};

export const deleteAccountById = async ({ dispatch, id }: { dispatch: Dispatch; id: number }): Promise<ApiResponse> => {
  await deleteAccount(`${id}`);
  const { data, error } = await getAccount();

  if (error) {
    dispatch({ type: auth.FETCH_ACCOUNT_FAILURE });

    return { data, error };
  }

  dispatch(setAccountSuccess(data));

  return { data, error };
};

export const fetchProfile = async ({ dispatch }: { dispatch: Dispatch }): Promise<ApiResponse> => {
  const token = storage.accessToken();

  if (!token) {
    console.error('Fetch profile: No token found');
    return { data: null, error: { status: true, message: 'Token not found' } };
  }

  dispatch(setAuthLoading());
  dispatch({ type: auth.FETCH_PROFILE });
  const { data, error } = await getProfile();

  if (error) {
    dispatch({ type: auth.FETCH_PROFILE_FAILURE });
    dispatch(resetAuthLoading());

    return { data, error };
  }

  dispatch({
    type: auth.FETCH_PROFILE_SUCCESS,
    payload: { user: data },
  });
  dispatch(resetAuthLoading());

  return { data, error };
};

export const changePassword = async ({ dispatch, payload }: ChangePasswordServicePayload): Promise<ApiResponse> => {
  dispatch({ type: auth.SIGN_OUT });
  const { data, error } = await patchChangePassword(payload);

  if (error) {
    dispatch({ type: auth.SIGN_OUT_FAILURE });
  }

  return { data, error };
};

export const clearStorage = () => {
  const { error } = storage.clear();

  return !error;
};

export const mapLoginSuccessPayload = (data: any): ILoginResponse => ({
  token: data.token,
  expires: data.expires,
  onboarded: data.onboarded,
  tokenType: data.tokenType,
});
