import { useEffect, useState } from 'react';

import { getAccountsCount } from 'api/request.api';
import useSearchParam from 'auth/hooks/useSearchParam';
import { setAccountCount } from 'networth/networth.actions';
import { useNetworthDispatch } from 'networth/networth.context';

const useAccountCount = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useNetworthDispatch();

  const refreshAccounts = useSearchParam('refetchAccounts');

  useEffect(() => {
    const getAccountCount = async () => {
      setLoading(true);
      const { data } = await getAccountsCount();

      setLoading(false);

      if (data?.totalAccounts) {
        dispatch(setAccountCount(data.totalAccounts));
      }
    };

    getAccountCount();
  }, [dispatch, refreshAccounts]);

  return { loading };
};

export default useAccountCount;
