import React from 'react';

import useQroka from 'auth/hooks/useQroka';
import useLedger, { ICryptoAccount } from 'auth/hooks/useLedger';
import WalletCard from 'auth/views/inc/wallet-card';
import { ECryptoConnections } from 'auth/auth.enum';
import { isLedgerSupported } from 'auth/auth.helper';
import { cryptoConnections } from 'auth/data/crypto-connections';

interface IConnectLedger {
  onCancel: () => void;
  handleSuccess: () => void;
}

const hasAddress = (account: ICryptoAccount[]): boolean => {
  return account.some((acc) => !!acc.address);
};

const ConnectLedger: React.FC<IConnectLedger> = (props) => {
  const { connectLedgerWallet, loading, errors: apiErrors } = useQroka();
  const { accounts, getBitcoinAddress, getEthereumAddress, errors } = useLedger();

  const connection = cryptoConnections.find((con) => con.type === ECryptoConnections.LEDGER);

  if (!connection) {
    return <div>Connection not available</div>;
  }

  const handleCoinbaseConnect = async () => {
    if (!hasAddress(accounts)) return;

    await connectLedgerWallet(accounts)(props.handleSuccess);
  };

  const renderSupportText = () => {
    if (!isLedgerSupported()) {
      return (
        <div className='error-block'>Ledger only supports Chrome browsers.</div>
      );
    }

    return <div className='key-info'>Connect your Ledger, unlock it, open the BTC or ETH app then choose an option below.</div>;
  };

  return (
    <div className='connect-crypto-wrapper'>
      <WalletCard
        disabled
        name={connection.name}
        url={connection.url}
        logo={connection.logo}
        connection={connection.type}
      />
      {renderSupportText()}
      {errors.map((error, index) => (
        <div key={index} className='error-block'>
          {error.message}
        </div>
      ))}
      <div className='connect-button-wrapper multiple'>
        <button disabled={loading || !isLedgerSupported()} onClick={getBitcoinAddress} className='mm-btn-primary mm-btn-animate'>
          Connect Bitcoin
        </button>
        <button disabled={loading || !isLedgerSupported()} onClick={getEthereumAddress} className='mm-btn-primary mm-btn-animate'>
          Connect Ethereum
        </button>
      </div>
      <div className='chain-accounts-wrapper'>
        {accounts.map((account, index) => (
          <div key={index}>
            <div className='chain-title'>{account.addressType.toString()}</div>
            <div className='accounts-wrapper'>
              <p>{account.address}</p>
            </div>
          </div>
        ))}
      </div>
      {apiErrors.Ledger && <div className='error-block'>{apiErrors.Ledger}</div>}
      <div className='crypto-action-wrapper'>
        <button type='reset' className='btn-outline-primary mm-btn-animate' disabled={loading} onClick={props.onCancel}>
          Cancel
        </button>

        <button
          type='submit'
          className='mm-btn-animate mm-btn-primary '
          disabled={loading}
          onClick={handleCoinbaseConnect}
        >
          {loading ? (
            <>
              <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
              <span className='ml-1'>Adding...</span>
            </>
          ) : (
            <>Add Accounts</>
          )}
        </button>
      </div>
    </div>
  );
};

export default ConnectLedger;
