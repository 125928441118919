export enum EPerformanceAction {
  SET_CURRENCY = 'SET_CURRENCY',
  SET_PROCESSING = 'SET_PROCESSING',
  RESET_PROCESSING = 'RESET_PROCESSING',
  SET_PERFORMANCE_GROUPS = 'SET_PERFORMANCE_GROUP',
}

export enum EPerformanceShortCut {
  SEVEN_DAY = '7D',
  MONTH_FROM_TODAY = 'MTD',
  ONE_MONTH = '1M',
  YEAR_FROM_TODAY = 'YTD',
  INCEPTION = 'INCEPTION',
  CUSTOM = 'CUSTOM',
}

export enum PerformingEnum {
  OVER_PERFORMING = 'Overperforming',
  PERFORMING = 'Performing',
  UNDER_PERFORMING = 'Underperforming',
}
