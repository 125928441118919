import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import { IManualAccount } from 'auth/auth.types';
import { ReactComponent as BackImg } from 'assets/icons/back.svg';

import ManualAccountsForm from './manual-accounts-form';

interface IManualAccounts {
  isManualLoading: boolean;
  onBack: VoidFunction;
  onClose: VoidFunction;
  handleFormSubmit: (values: Partial<IManualAccount>) => void;
  initialFormValues?: Partial<IManualAccount> | null;
}

const ManualAccounts: React.FC<IManualAccounts> = ({
  isManualLoading,
  initialFormValues,
  onBack,
  onClose,
  handleFormSubmit,
}) => {
  return (
    <div className='manual-account app-modal'>
      <div className='app-modal__header manual-account__header'>
        <div className='d-flex align-items-center mt-2'>
          <BackImg role='button' onClick={() => onBack()} />
          <h2 className='app-modal__title'>Add Manual Accounts</h2>
        </div>

        <button onClick={() => onClose()}>
          <AiOutlineClose className='modal-close' />
        </button>
      </div>

      <p className='app-modal__description'>
        Manual accounts are ones that you manage on your own. No username, no password.
      </p>

      <ManualAccountsForm
        loading={isManualLoading}
        initialFormValues={initialFormValues}
        handleSubmit={handleFormSubmit}
        handleClose={onClose}
      />
    </div>
  );
};

export default ManualAccounts;
