import React from 'react';

import { TimeIntervalEnum } from 'networth/networth.enum';

export interface IFilter {
  id?: number;
  toDate?: Date;
  screen: string;
  fromDate?: Date;
  default: boolean;
  category?: string;
  filterName: string;
  accountId?: string;
  liquidity?: string;
  profileId?: number;
  accountType?: string;
  investingEntityId?: string;
  timeInterval?: TimeIntervalEnum;
}

export interface IFilterState {
  fTypes: string[];
  fToDate?: string;
  filters: IFilter[];
  fFromDate?: string;
  fAccounts: number[];
  fEntities: number[];
  processing: boolean;
  fLiquidity: string[];
  fCategories: string[];
  fTimeInterval?: TimeIntervalEnum;
}

export enum EFilterAction {
  ADD_FILTER = 'ADD_FILTER',
  SET_FILTERS = 'SET_FILTERS',
  CLEAR_FILTER = 'CLEAR_FILTER',
  UPDATE_FILTER = 'UPDATE_FILTER',
  REMOVE_FILTER = 'REMOVE_FILTER',
  SET_PROCESSING = 'SET_PROCESSING',
  RESET_PROCESSING = 'RESET_PROCESSING',
  SET_FILTER_ENTITY = 'SET_FILTER_ENTITY',
  SET_FILTER_TO_DATE = 'SET_FILTER_TO_DATE',
  SET_FILTER_ACCOUNT = 'SET_FILTER_ACCOUNT',
  FILTER_BULK_UPDATE = 'FILTER_BULK_UPDATE',
  SET_FILTER_CATEGORY = 'SET_FILTER_CATEGORY',
  SET_FILTER_FROM_DATE = 'SET_FILTER_FROM_DATE',
  SET_FILTER_LIQUIDITY = 'SET_FILTER_LIQUIDITY',
  SET_FILTER_ACCOUNT_TYPE = 'SET_FILTER_ACCOUNT_TYPE',
  SET_FILTER_TIME_INTERVAL = 'SET_FILTER_TIME_INTERVAL',
}

export interface IFilterPayload {
  fType: string;
  filter: IFilter;
  filterId: string;
  fCategory: string;
  fEntityId: number;
  fAccountId: number;
  fAccountType: string;
  fLiquidityItem: string;
  localFilter: Partial<IFilterState>;
}

export interface IFilterAction {
  type: EFilterAction;
  payload?: Partial<IFilterState & IFilterPayload>;
}

export interface IFilterProvider {
  children: React.ReactNode;
}

export type TFilterDispatch = (action: IFilterAction) => void;

export type TFilterKey = keyof IFilterState;
