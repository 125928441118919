import React from 'react';

import WalletConnect from '@walletconnect/client';
import QRCodeModal from '@walletconnect/qrcode-modal';

import { ICryptoAccount } from './useLedger';
import { ECryptoConnections } from '../auth.enum';

const ETHEREUM_CHAIN_ID = 1;
const BSC_CHAIN_ID = 56;
const WALLET_CONNECT_BRIDGE = 'https://bridge.walletconnect.org';

const chainIdToBlockchain: Record<number, ECryptoConnections> = {
  [ETHEREUM_CHAIN_ID]: ECryptoConnections.ETHEREUM,
  [BSC_CHAIN_ID]: ECryptoConnections.BINANCE_SMART_CHAIN,
};

const connector = new WalletConnect({
  bridge: WALLET_CONNECT_BRIDGE,
  qrcodeModal: QRCodeModal,
  qrcodeModalOptions: {
    desktopLinks: [],
  },
});

const useTrustWallet = () => {
  const [error, setError] = React.useState<string>('');
  const [accounts, setAccounts] = React.useState<ICryptoAccount[]>([]);

  const onConnect = () => {
    setError('');

    try {
      if (!connector.connected) {
        connector.createSession();
      }

      connector.on('connect', (err, payload) => {
        if (err) {
          return setError(err.message || 'User denied account authorization');
        }

        const { accounts: acc, chainId } = payload.params?.[0] || {};

        if (![ETHEREUM_CHAIN_ID, BSC_CHAIN_ID].includes(chainId)) {
          return setError('Invalid chain id, We support only Ethereum and Binance Smart Chain');
        }

        setAccounts(
          acc.map((account: string) => ({ addressType: chainIdToBlockchain[connector.chainId], address: account }))
        );
      });

      if (connector.accounts) {
        return setAccounts(
          connector.accounts.map((acc) => ({ addressType: chainIdToBlockchain[connector.chainId], address: acc }))
        );
      }
    } catch (err: any) {
      setError(err.message || 'Error on getting accounts from Wallet Connect');
    }
  };

  const disconnect = () => {
    setAccounts([]);

    if (connector.connected) {
      connector.killSession();
    }
  };

  return { onConnect, accounts, disconnect, error };
};

export default useTrustWallet;
