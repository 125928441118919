import React, { useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';

import {
  isBefore,
  getNextMonth,
  getPreviousMonth,
  getMonthSubtracted,
  getLastDateOfMonth,
  getMonthYear,
} from 'common/moment.helper';
import { useAuthState } from 'auth/auth.context';
import { useModal } from 'common/components/modal';
import { MMPieChart } from 'common/components/pie-chart';
import SettingModal from 'allocation/modal/setting-modal';
import useAllocation from 'allocation/hooks/useAllocation';
import { mapAllocations } from 'allocation/allocation.helper';
import { pricingDetailConstant } from 'common/common.constant';
import ChartShareModal from 'allocation/modal/chart-share-modal';
import { SelectedAllocationProps } from 'allocation/allocation.type';
import useCurrentSubscription from 'auth/hooks/useCurrentSubscription';
import CircularSpinner from 'common/components/spinner/circular-spinner';
import AllocationSortIcon from 'allocation/components/allocation-sort-icon';
import { ReactComponent as Share } from 'assets/images/allocation/share.svg';
import AllocationTableBody from 'allocation/components/allocation-table-body';
import { ReactComponent as Calendar } from 'assets/images/allocation/calendar.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/allocation/settings.svg';

import AllocationLegend from './allocation-legend';
import RestrictedChartView from './restricted-chart-view';
import { StripeSubscriptionStatus } from '../../setting/setting.enum';

export const SelectedAllocations: React.FC<SelectedAllocationProps> = ({
  filter,
  colorData,
  currencySymbol,
  gotoDetailPage,
  handleChangeColorData,
}) => {
  const { subscriptionDetail } = useAuthState();
  const [hidden, setHidden] = useState<string[]>(['']);
  const [isDateValid, setIsDateValid] = useState<boolean>(true);
  const [date, setDate] = useState<Date>(getPreviousMonth());
  const { currentSubscription } = useCurrentSubscription();
  const { allocations, allocationChartData: chartData, lastAvailableDate } = useAllocation(filter, date);

  const [sortOrder, setSortOrder] = useState<'DESC' | 'ASC'>('DESC');

  const chartShareModal = useModal();
  const chartSettingModal = useModal();

  const allocationsList = useMemo(() => {
    if (allocations) {
      return mapAllocations(allocations);
    }

    return [];
  }, [allocations]);

  const isFreeUser =
    currentSubscription?.subscriptionStatus !== StripeSubscriptionStatus.TRIALING &&
    currentSubscription?.subscriptionStatus !== StripeSubscriptionStatus.ACTIVE;

  if (!allocations || !chartData) {
    return <CircularSpinner />;
  }

  const toggleAllocation = (key: string) => {
    if (hidden.includes(key)) {
      const tempArr = hidden.filter((item) => item !== key);

      return setHidden(tempArr);
    }

    return setHidden([...hidden, key]);
  };

  const isHidden = (key: string) => hidden.includes(key);

  const getTotal = (key: string) => {
    return chartData.find((datum) => datum.group === key);
  };

  const getNumberOfChartHistory = () => {
    if (subscriptionDetail?.details) {
      return +subscriptionDetail?.details[pricingDetailConstant.ALLOCATION_CHART_HISTORY] || 0;
    }

    return 0;
  };

  const validateDate = (_date: Date) => {
    if (getNumberOfChartHistory() && isBefore(_date, getMonthSubtracted(getNumberOfChartHistory()))) {
      setIsDateValid(false);

      return true;
    }

    setIsDateValid(true);

    return true;
  };

  const setPreviousMonth = () => {
    if (validateDate(getPreviousMonth(date))) {
      return setDate(getPreviousMonth(date));
    }
  };

  const setNextMonth = () => {
    if (validateDate(getNextMonth(date))) {
      return setDate(getNextMonth(date));
    }
  };

  return (
    <div className='mm-allocation-overview__block'>
      <div className='allocation-card-top'>
        <div className='mm-allocation-overview__block--date'>
          <div className='selected-date-string'>
            {lastAvailableDate && !isFreeUser && new Date(date) > getLastDateOfMonth(lastAvailableDate) && (
              <span className='arrow-left' role='button' onClick={setPreviousMonth} />
            )}
            {getMonthYear(date || undefined)}
            {getLastDateOfMonth(new Date(date)) < new Date() && (
              <span className='arrow-right' role='button' onClick={setNextMonth} />
            )}
          </div>
          <span className='float-right mm-tooltip'>
            <ReactDatePicker
              selected={date}
              customInput={<Calendar />}
              dateFormat='MM/yyyy'
              showMonthYearPicker
              minDate={getPreviousMonth(lastAvailableDate)}
              maxDate={new Date()}
              disabled={isFreeUser}
              onChange={(val: Date) => {
                if (validateDate(val)) {
                  setDate(val);
                }
              }}
            />
            {!isDateValid || isFreeUser ? (
              <div className='mm-tooltip__body'>
                <div className='mm-tooltip__body--title'>Upgrade Plan</div>
                <div className='mm-tooltip__body--text'>
                  Your plan only allows you to go back {getNumberOfChartHistory()} months. Upgrade for more history.
                </div>
              </div>
            ) : null}
          </span>
        </div>
        <div className='mm-allocation-overview__block--title'>Previous allocations</div>
        <p className='mm-allocation-overview__block--subtitle'>Use the arrows above to see your previous allocations</p>
        <div className='mm-allocation-overview__block--action'>
          <SettingsIcon title='Chart Settings' className='mr-3' onClick={() => chartSettingModal.open()} />
          <Share title='Share Chart' onClick={() => chartShareModal.open()} />
        </div>
      </div>
      {!isDateValid ? (
        <RestrictedChartView noOfMonth={getNumberOfChartHistory()} />
      ) : (
        <div className='allocation-content'>
          <div className='text-center text-md-left d-xl-block d-md-flex align-items-md-center justify-content-md-center mm-allocation-overview__block-chart-overview'>
            {Object.keys(allocations).length === 0 && chartData.length === 0 ? (
              <div className='mm-allocation-overview__block-element text-center'>
                <div className='mm-allocation-overview__block-element--middle'>
                  <div className='d-inline-flex align-items-center'>
                    <div className='mm-allocation-overview__block-element--text ml-2'>Not enough data</div>
                  </div>
                  <p>Historical charts will become available once your cross a month end.</p>
                </div>
              </div>
            ) : (
              <div
                className='text-center text-md-left d-xl-block d-md-flex align-items-md-center justify-content-md-center allocation-page-chart-wrapper p-b-4'
                id='selected-allocation-pie-chart'
              >
                <MMPieChart
                  chartData={chartData}
                  currencySymbol={currencySymbol}
                  colorData={colorData}
                  handleChangeColorData={(changeColorData: Map<string, string>) =>
                    handleChangeColorData(changeColorData)
                  }
                />
                <AllocationLegend chartData={chartData} currencySymbol={currencySymbol} colorData={colorData} />
              </div>
            )}
          </div>

          <div className='mm-allocation-overview__table'>
            <table>
              <thead>
                <tr>
                  <th className='mm-allocation-overview__table--head'>Position</th>
                  <th className='mm-allocation-overview__table--head'>Allocation</th>
                  <th
                    className='mm-allocation-overview__table--head'
                    onClick={() => {
                      setSortOrder(sortOrder === 'DESC' ? 'ASC' : 'DESC');
                    }}
                    role='button'
                  >
                    Value
                    <AllocationSortIcon order={sortOrder} />
                  </th>
                </tr>
              </thead>

              {allocationsList.map((allocationItem) => {
                return (
                  <AllocationTableBody
                    sortOrder={sortOrder}
                    key={allocationItem.allocationKey}
                    getTotal={getTotal}
                    isHidden={isHidden}
                    allocations={allocationItem.allocations}
                    allocationKey={allocationItem.allocationKey}
                    currencySymbol={currencySymbol}
                    gotoDetailPage={gotoDetailPage}
                    toggleAllocation={toggleAllocation}
                  />
                );
              })}
            </table>
          </div>
        </div>
      )}

      <SettingModal settingModal={chartSettingModal} />
      <ChartShareModal
        chartShareModal={chartShareModal}
        chartComponent={
          <MMPieChart
            chartData={chartData}
            currencySymbol={currencySymbol}
            colorData={colorData}
            handleChangeColorData={handleChangeColorData}
            share
          />
        }
        chartLegendComponent={
          <AllocationLegend chartData={chartData} currencySymbol={currencySymbol} colorData={colorData} sharing />
        }
      />
    </div>
  );
};
