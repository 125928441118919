import React from 'react';
import ReactDatePicker from 'react-datepicker';
import Dropdown from 'react-bootstrap/Dropdown';

import {
  useAccountTypesState,
  useAccountTypesDispatch,
  initialState as initialAccountTypesFilterState,
} from 'account-types/context/account-types.context';
import { TimeIntervalEnum } from 'networth/networth.enum';
import { enumerateStr, serialize } from 'common/common-helper';
import { getDateString, getMonthYear } from 'common/moment.helper';
import { TAccountTypeKey } from 'account-types/account-types.types';
import { setTimeInterval, setFromDate, setToDate } from 'account-types/context/account-types.actions';

interface IAccountTypesFilterProps {
  defaultFromDate: string | undefined;
  defaultToDate: string | undefined;
}

const AccountTypesFilter: React.FC<IAccountTypesFilterProps> = ({ defaultFromDate, defaultToDate }) => {
  const dispatch = useAccountTypesDispatch();

  const accountTypesFilterState = useAccountTypesState();
  const { fromDate, toDate, timeInterval } = accountTypesFilterState;

  const fFromDate = defaultFromDate ? new Date(defaultFromDate) : new Date();
  const fToDate = defaultToDate ? new Date(defaultToDate) : new Date();

  const isFiltered = (key: TAccountTypeKey) => {
    if (serialize(accountTypesFilterState[key] as any) !== serialize(initialAccountTypesFilterState[key] as any)) {
      return true;
    }

    return false;
  };

  const fc = (key: TAccountTypeKey) => (isFiltered(key) ? 'filtered' : '');

  // Change handlers
  const handleIntervalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTimeInterval(event.target.value as TimeIntervalEnum));
  };

  const handleFromDateChange = (date: any) => {
    dispatch(setFromDate(getDateString(date)));
  };

  const handleToDateChange = (date: any) => {
    dispatch(setToDate(getDateString(date)));
  };

  const renderFilters = () => {
    return (
      <div className='dflex-center justify-space-between mb-15 desktop-filters'>
        <div className='d-flex'>
          <ReactDatePicker
            selected={fromDate ? new Date(fromDate) : fFromDate}
            onChange={handleFromDateChange}
            // selectsStart
            startDate={fromDate ? new Date(fromDate) : fFromDate}
            dateFormat='MM/yyyy'
            showMonthYearPicker
            minDate={new Date('1900-01-01')}
            maxDate={new Date()}
            // selectsRange
            customInput={
              <div className='drop-box'>
                <div className='date-box'>
                  <input
                    type='text'
                    className={['month_year', fc('fromDate')].join(' ')}
                    value={fromDate ? getMonthYear(fromDate) : getMonthYear(fFromDate)}
                    aria-label='From Date Filter'
                    readOnly
                  />
                </div>
              </div>
            }
          />
          <span className='date-separator'>to</span>

          <ReactDatePicker
            selected={toDate ? new Date(toDate) : fToDate}
            onChange={handleToDateChange}
            // selectsStart
            startDate={toDate ? new Date(toDate) : fToDate}
            dateFormat='MM/yyyy'
            showMonthYearPicker
            minDate={fromDate ? new Date(fromDate) : fFromDate}
            maxDate={new Date()}
            className='mr-3'
            // selectsRange
            customInput={
              <div className='drop-box'>
                <div className='date-box'>
                  <input
                    type='text'
                    className={['month_year', fc('toDate')].join(' ')}
                    value={toDate ? getMonthYear(toDate) : getMonthYear(fToDate)}
                    aria-label='To Date Filter'
                    readOnly
                  />
                </div>
              </div>
            }
            popperClassName='to-date-popper'
          />
        </div>

        <Dropdown className='drop-box'>
          <Dropdown.Toggle variant='' className={fc('timeInterval')}>
            {timeInterval || TimeIntervalEnum.MONTHLY}
          </Dropdown.Toggle>
          <Dropdown.Menu className='mm-dropdown-menu dropsm'>
            <ul className='radiolist'>
              {enumerateStr(TimeIntervalEnum).map((interval) => {
                return (
                  <li key={interval}>
                    <label>
                      <input
                        type='radio'
                        name='m-list'
                        aria-checked={timeInterval === interval}
                        value={interval}
                        checked={timeInterval === interval}
                        onChange={handleIntervalChange}
                      />
                      <span>{interval}</span>
                    </label>
                  </li>
                );
              })}
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  return (
    <div className='filter-wrapper mb-4 mb-lg-0'>
      <div className='d-flex filter__row'>{renderFilters()}</div>
    </div>
  );
};

export default AccountTypesFilter;
