import React from 'react';

import {
  Bar,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ComposedChart,
  CartesianGrid,
  ReferenceArea,
  ResponsiveContainer,
} from 'recharts';
import { BarChartColors } from 'common/color';
import { EChartValueType } from 'income/income.enum';
import { fNumber, numberWithCommas } from 'common/number.helper';
import { formatter, getInterval } from 'common/bar-graph-helper';
import { IAccountDataForInterval } from 'account-types/account-types.types';
import BarGraphCustomTick from 'common/components/chart/bargraph-custom-tick';

interface IAccountDataByTypeChartProps {
  currencySymbol: string;
  balances: IAccountDataForInterval[];
}

const AccountDataByTypeChart: React.FC<IAccountDataByTypeChartProps> = ({ currencySymbol, balances }) => {
  if (!balances.length) {
    return null;
  }

  const graphKey = 'balance';
  const maxBalance = balances.reduce((acc, current) => {
    const currentValue = current.balance || 0;

    return currentValue > acc ? currentValue : acc;
  }, 0);

  const firstProjection = balances.find((item) => item.type === EChartValueType.PROJECTION)?.interval || '';
  let interval = getInterval(maxBalance);

  if (maxBalance > interval * 3.5) {
    interval = getInterval(maxBalance + interval / 2);
  }

  const renderCustomRALabel = (props: any) => {
    const { x, y } = props.viewBox;
    return (
      <text
        style={{
          fontFamily: 'Mulish',
          lineHeight: '150%',
          fontSize: '11px',
          fontWeight: 'bold',
          fontStyle: 'normal',
          textAlign: 'center',
          letterSpacing: '0.2em',
          textTransform: 'uppercase',
        }}
        x={x + 15}
        y={y + 25}
        fill='#534CEA'
        fillOpacity='0.4'
      >
        prjected
      </text>
    );
  };

  return (
    <div className='responsive-container'>
      <ResponsiveContainer width='100%' height={345}>
        <ComposedChart
          width={800}
          height={354}
          data={balances}
          barGap={2}
          barCategoryGap={20}
          margin={{
            top: 24,
            right: 5,
            left: 5,
            bottom: 5,
          }}
        >
          {/* Gradients */}
          <defs>
            <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='#8884d8' stopOpacity={0.5} />
              <stop offset='95%' stopColor='#FFFFFF' stopOpacity={0.5} />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id='colorPr' x1='0' y1='1' x2='1' y2='1'>
              <stop offset='5%' stopColor='#8884d8' stopOpacity={0.3} />
              <stop offset='95%' stopColor='#FFFFFF' stopOpacity={0.3} />
            </linearGradient>
          </defs>

          <Area dataKey={graphKey} type='monotone' stroke='#534cea' strokeOpacity='0' fill='url(#colorUv)' />

          {/* Projection Area */}
          <ReferenceArea x1={firstProjection} label={renderCustomRALabel} fill='url(#colorPr)' />

          {/* Graph Grid Axis */}
          <CartesianGrid stroke='#969eac1a' vertical={false} />

          <XAxis
            dataKey='interval'
            tickSize={0}
            tickMargin={0}
            minTickGap={0}
            tick={<BarGraphCustomTick />}
            stroke='#969eac'
            axisLine={{ stroke: 'transparent' }}
          />

          <YAxis
            orientation='right'
            minTickGap={10}
            axisLine={false}
            tickSize={0}
            tickMargin={10}
            tick={{ fontSize: 14 }}
            interval='preserveStartEnd'
            stroke='#969eac'
            tickFormatter={(tick) => formatter(tick, currencySymbol)}
            domain={['auto', interval * 4]}
          />

          {/* Tooltip */}
          <Tooltip separator='' cursor={false} content={<CustomTooltip currencySymbol={currencySymbol} />} />

          {/* Actual Bar chart */}
          <Bar dataKey={graphKey} barSize={10} fill={BarChartColors.BLUE} radius={[2, 2, 0, 0]} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

const CustomTooltip: React.FC<any> = ({ active, currencySymbol, label, payload }) => {
  if (!active || !payload.length) {
    return null;
  }

  const balance = payload[0]?.value;

  return (
    <div className='bar-tooltip'>
      <div className='item-name'>{label}</div>
      <div className='item-value ml-0'>
        {currencySymbol}
        {numberWithCommas(fNumber(balance, 0))}
      </div>
    </div>
  );
};

export default AccountDataByTypeChart;
