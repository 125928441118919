import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Account } from 'auth/auth.types';
import { storage } from 'app/app.storage';
import { ETableType } from 'account/enum/table-type.enum';
import { appRouteConstants } from 'app/app-route.constant';
import { getCurrencySymbol } from 'common/currency-helper';
import { base64Decode } from 'common/helper/string.helper';
import { AccountHoldingsProps, AccountTransactionsProps, IBalanceData } from 'account/account.type';
import { getAccountActivity, getAccountDetailBalances, getAccountDetails, getAccountHoldings } from 'api/request.api';

export interface IAccountParam {
  accountId: string;
}

export interface IAccountFilter {
  toDate?: string | Date;
  fromDate?: string | Date;
  timeInterval?: string;
}

export interface IInitialCounter {
  detail: number;
  balance: number;
  holdings: number;
  activities: number;
}

const initialAccountFilter: IAccountFilter = {
  toDate: undefined,
  fromDate: undefined,
  timeInterval: 'Monthly',
};

const initialCounter: IInitialCounter = {
  detail: 0,
  balance: 0,
  holdings: 0,
  activities: 0,
};

/**
 *
 * @returns
 * @description This hook will be used to handle all kinds of logic for tab change and api call
 * @FIX eventually we are pulling all balances, holdings and transactions at once
 * need to fix that
 */

const useAccountDetail = () => {
  const history = useHistory();
  const { accountId }: IAccountParam = useParams();
  const [baseCurrency, setBaseCurrency] = useState(false);
  const [tableType, setTableType] = useState<ETableType>();
  const [accSetting, setAccSetting] = useState<boolean>(false);
  const [accountDetail, setAccountDetail] = useState<Account>();
  const [balanceData, setBalanceData] = useState<IBalanceData>();
  const [currencySymbol, setCurrencySymbol] = useState<string>('');
  const [accountHoldings, setAccountHolding] = useState<AccountHoldingsProps>();
  const [accountActivities, setAccountActivity] = useState<AccountTransactionsProps>();
  const [accountFilter, setAccountFilter] = useState<IAccountFilter>(initialAccountFilter);

  const [decodedAccountId, setDecodedAccountId] = useState<string>('');

  // FIXME: with reducer in future.
  const [fetchingDetail, setFetchingDetail] = useState(false);
  const [fetchingBalance, setFetchingBalance] = useState(false);
  const [fetchingHoldings, setFetchingHoldings] = useState(false);
  const [fetchingTransactions, setFetchingTransactions] = useState(false);

  // counter for fetching specific item.
  const [counter, setCounter] = useState(initialCounter);

  // counter for fetching all
  const [refreshCounter, setRefreshCounter] = useState(0);

  const handleRefresh = (key: keyof IInitialCounter) => {
    return setCounter({ ...counter, [key]: counter[key] + 1 });
  };

  const handleRefreshAll = () => setRefreshCounter((c) => c + 1);

  const resetFilter = () => {
    setAccountFilter(initialAccountFilter);
  };

  useEffect(() => {
    try {
      const decoded = base64Decode(accountId);

      setDecodedAccountId(decoded);
    } catch (err) {
      return history.push(appRouteConstants.dashboard.DASHBOARD);
    }
  }, [accountId, history]);

  /**
   * @description Fetch account Details
   */
  useEffect(() => {
    (async () => {
      if (!decodedAccountId) {
        return;
      }

      setFetchingDetail(true);
      const { data, error }: { data: Account; error: any } = await getAccountDetails(decodedAccountId, baseCurrency);
      setFetchingDetail(false);

      if (!error) {
        setAccountDetail(data);
        setCurrencySymbol(getCurrencySymbol(data?.currency || '$'));
      }

      if (403 === error?.statusCode || 400 === error?.statusCode) {
        return history.push(appRouteConstants.dashboard.DASHBOARD);
      }
    })();
  }, [accountId, baseCurrency, history, counter.detail, refreshCounter, decodedAccountId]);

  /**
   * @description fetch holdings
   */
  const { fromDate, toDate, timeInterval } = accountFilter;
  const hasNoDate = !fromDate || !toDate;
  const hasDateFilter = fromDate && toDate;
  const hasValidDate = hasDateFilter && new Date(toDate!) >= new Date(fromDate!);
  const isDateFilter = hasNoDate || hasValidDate;

  useEffect(() => {
    (async () => {
      if (isDateFilter && decodedAccountId) {
        setFetchingHoldings(true);
        const { data, error } = await getAccountHoldings({
          accountId: decodedAccountId,
          fromDate,
          toDate,
          timeInterval,
          baseCurrency,
        });
        setFetchingHoldings(false);

        if (!error) {
          setAccountHolding(data);

          if (storage.get('isNew').data) {
            setAccSetting(true);
          }
        }
      }
    })();
  }, [
    accountId,
    fromDate,
    toDate,
    timeInterval,
    baseCurrency,
    isDateFilter,
    counter.holdings,
    refreshCounter,
    decodedAccountId,
  ]);

  /**
   * @description Get account activity this does not have to fetched on table type changes.
   */
  useEffect(() => {
    (async () => {
      if (isDateFilter && decodedAccountId) {
        setFetchingTransactions(true);
        const { data, error } = await getAccountActivity({
          toDate,
          fromDate,
          accountId: decodedAccountId,
          timeInterval,
          baseCurrency,
        });
        setFetchingTransactions(false);

        if (!error) {
          setAccountActivity(data);
        }
      }
    })();
  }, [
    accountId,
    toDate,
    fromDate,
    timeInterval,
    baseCurrency,
    isDateFilter,
    counter.activities,
    refreshCounter,
    decodedAccountId,
  ]);

  /**
   * @description Get balance at first.
   */
  useEffect(() => {
    (async () => {
      if (isDateFilter && decodedAccountId) {
        setFetchingBalance(true);
        const { data, error } = await getAccountDetailBalances({
          toDate,
          fromDate,
          accountId: decodedAccountId,
          timeInterval,
          baseCurrency,
        });
        setFetchingBalance(false);

        if (!error) {
          setBalanceData(data);
        }
      }
    })();
  }, [
    accountId,
    toDate,
    fromDate,
    timeInterval,
    baseCurrency,
    isDateFilter,
    counter.balance,
    refreshCounter,
    decodedAccountId,
  ]);

  /**
   * @description for checking initial tab holdings or balance
   */
  const withHoldings =
    (accountDetail?.hasHoldings === true && accountDetail?.isManual === true) || accountHoldings?.holdings.length !== 0;

  useEffect(() => {
    if (withHoldings) {
      return setTableType(ETableType.HOLDINGS);
    }

    setTableType(ETableType.BALANCE);
  }, [withHoldings]);

  const filterLoading = fetchingDetail || fetchingBalance || fetchingTransactions || fetchingHoldings;

  return {
    tableType,
    accSetting,
    balanceData,
    resetFilter,
    baseCurrency,
    withHoldings,
    setTableType,
    handleRefresh,
    filterLoading,
    accountDetail,
    setAccSetting,
    accountFilter,
    currencySymbol,
    accountHoldings,
    setBaseCurrency,
    setAccountFilter,
    handleRefreshAll,
    accountActivities,
  };
};

export default useAccountDetail;
