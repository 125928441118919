export enum EDiscoverAction {
  SET_PROCESSING = 'SET_PROCESSING',
  RESET_PROCESSING = 'RESET_PROCESSING',
  SET_OPPORTUNITIES = 'SET_OPPORTUNITIES',
  SET_PAGINATION = 'SET_PAGINATION',
  FETCH_MORE_DATA = 'FETCH_MORE_DATA',
  SET_SEARCH = 'SET_SEARCH',
  PARTIAL_UPDATE = 'PARTIAL_UPDATE',
}

export enum EDiscoverFilterAction {
  SET_TYPE = 'SET_TYPE',
  SET_MINIMUM = 'SET_MINIMUM',
  SET_RISK = 'SET_RISK',
  SET_TARGET_RETURNS = 'SET_TARGET_RETURNS',
  SET_RETURN_TYPE = 'SET_RETURN_TYPE',
  SET_RETURN_FREQUENCY = 'SET_RETURN_FREQUENCY',
  SET_LIQUIDITY = 'SET_LIQUIDITY',
  SET_INVESTOR_TYPE = 'SET_INVESTOR_TYPE',
  SET_SORT_FIELD = 'SET_SORT_FIELD',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  PARTIAL_UPDATE = 'PARTIAL_UPDATE',
}

export enum EOpportunityRisk {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}

export enum EReturnType {
  APPRECIATION = 'Appreciation',
  DIVIDENDS = 'Dividends',
  INTEREST = 'Interest',
  ROYALTIES = 'Royalties ',
}

export enum EReturnFrequency {
  ANNUALLY = 'Annually',
  SEMI_ANNUALLY = 'Semi Annually',
  QUATERLY = 'Quarterly',
  MONTHLY = 'Monthly',
  DAILY = 'Daily',
  VARIES = 'Varies',
}

export enum EInvestorType {
  ACCREDITED_ONLY = 'Accredited Only',
  ALL = 'All Investors',
}

export enum EDiscoverSortKeys {
  COMPANY = 'Company',
  TYPE = 'Type',
  MINIMUM = 'Minimum',
  RISK = 'Risk',
  TARGET_RETURNS = 'Target Returns',
  FEES = 'Fees',
  RETURN_TYPE = 'Return Type',
  RETURN_FREQUENCY = 'Return Frequency',
  LIQUIDITY = 'Liquidity',
  INVESTOR_TYPE = 'Investor Type',
}
