export const websiteRouteConstant = {
  DEF: '/w',
  HOME: '/w/home',
  TERMS: '/w/terms',
  ABOUT: '/w/about',
  MEDIA: '/w/media',
  LOGIN: '/w/login',
  NOT_FOUND: '/w/404',
  NOTICE: '/w/notice',
  PRIVACY: '/w/privacy',
  PRICING: '/w/pricing',
  SECURITY: '/w/security',
  EXPIRED_LINK: '/w/expired-link',
  FORGOT_PASSWORD: '/w/forgot-password',
  CREATE_NEW_PASSWORD: '/w/create-new-password',
  SUBSCRIPTION_ENDED: '/w/subscription-ended-one',
  LOGIN_SESSION_EXPIRED: '/w/login-session-expired',
  SUBSCRIPTION_ENDED_TWO: '/w/subscription-ended-two',
  SUBSCRIPTION_ENDED_THREE: '/w/subscription-ended-three',
  FEATURES: '/w/features',
  FEATURES_CRYPTOS: '/w/features-cryptos',
  FEATURES_NET_WORTH: '/w/features-net-worth',
  FEATURES_ALLOCATIONS: '/w/features-allocations',
  FEATURES_SYNCED_MANUAL: '/w/features-synced-manual',
  FEATURES_MULTICURRENCY: '/w/features-multicurrency',
  FEATURES_TRANSACTION_HISTORY: '/w/features-transaction-history',
  LANDING_NET_WORTH_CALCULATOR: '/w/net-worth-calculator',
};
