import { Link } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';

import useToast from 'common/hooks/useToast';
import { shortId } from 'common/common-helper';
import { ILease, ILeaseFormValue, ILeaseTabItem } from 'account/account.type';
import { deleteLeaseForAccount, patchLeaseForAccount, postLeaseForAccount } from 'api/request.api';

import LeaseForm from './lease-form';

const newLeaseFormValue: ILeaseFormValue = {
  tenant: '',
  moveInDate: undefined,
  leaseExpire: undefined,
  rent: '',
  deposit: '',
};

interface ILeasesProps {
  leases: ILease[];
  accountId: number;
  currencySymbol: string;
  handleReload: VoidFunction;
  locked: boolean;
}

const Leases: React.FC<ILeasesProps> = ({ leases, accountId, currencySymbol, handleReload, locked }) => {
  const { mmToast } = useToast();

  const [tabKey, setTabKey] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [tabItems, setTabItems] = useState<ILeaseTabItem[]>([]);

  useEffect(() => {
    const key = `lease-${shortId()}`;

    if (leases.length <= 0) {
      setTabItems([
        {
          id: null,
          key,
          title: 'Lease',
          values: newLeaseFormValue,
        },
      ]);
      setTabKey(key);
      return;
    }

    const items = leases.map(
      (lease, index): ILeaseTabItem => {
        const { id, tenantName, moveInDate, leaseExpiresDate, rent, deposit } = lease;

        return {
          id,
          key: `lease-${id}`,
          title: leases.length === 1 ? 'Lease' : `Lease ${index + 1}`,
          values: {
            tenant: tenantName || '',
            moveInDate: !!moveInDate ? new Date(moveInDate) : undefined,
            leaseExpire: !!leaseExpiresDate ? new Date(leaseExpiresDate) : undefined,
            rent,
            deposit: deposit || '',
          },
        };
      }
    );

    const activeKey = items[0].key;

    setTabItems(items);
    setTabKey(activeKey);
  }, [leases]);

  const handleAddNewLease = () => {
    let updatedTabItems = [...tabItems];

    const length = updatedTabItems.length;

    if (length === 1) {
      updatedTabItems = updatedTabItems.map((tabItem) => ({
        ...tabItem,
        title: `Lease ${length}`,
      }));
    }

    updatedTabItems.push({
      id: null,
      key: `lease-${shortId()}`,
      title: `Lease ${length + 1}`,
      values: newLeaseFormValue,
    });

    setTabItems(updatedTabItems);
  };

  const handleDeleteLease = (id: number | null, index: number) => async () => {
    if (!!id) {
      const { error } = await deleteLeaseForAccount(accountId, id);
      if (!!error) {
        mmToast('Something went wrong', { type: 'error' });
        return;
      }

      handleReload();
      mmToast('Lease successfully deleted', { type: 'success' });
    }

    const length = tabItems.length;

    if (length === 1) {
      const key = `lease-${shortId()}`;

      setTabItems([
        {
          id: null,
          key,
          title: `Lease`,
          values: newLeaseFormValue,
        },
      ]);
      setTabKey(key);
      return;
    }

    const updatedItems = tabItems
      .filter((_, itemIndex) => itemIndex !== index)
      .map(
        (item, index): ILeaseTabItem => ({
          ...item,
          title: length === 2 ? 'Lease' : `Lease ${index + 1}`,
        })
      );

    const activeKey = updatedItems[0].key;
    setTabItems(updatedItems);
    setTabKey(activeKey);
  };

  const handleSave = (id: number | null, index: number) => async (values: ILeaseFormValue) => {
    const { tenant, moveInDate, leaseExpire, rent, deposit } = values;

    if (!rent) {
      return mmToast('Rent is required', { type: 'error' });
    }

    const body: Partial<ILease> = {
      ...(!!tenant ? { tenantName: tenant } : {}),
      ...(!!moveInDate ? { moveInDate: moveInDate.toISOString() } : {}),
      ...(!!leaseExpire ? { leaseExpiresDate: leaseExpire.toISOString() } : {}),
      ...(!!deposit ? { deposit: parseFloat(deposit.toString()) } : {}),
      rent: parseFloat(rent.toString()),
    };

    if (!!id) {
      handleLeaseUpdate(id, body, index);
      return;
    }

    handleLeaseCreate(body, index);
  };

  const handleLeaseCreate = async (payload: Partial<ILease>, index: number) => {
    try {
      setLoading(true);
      const { data } = await postLeaseForAccount(accountId, payload);
      handleReload();

      const existingKey = tabItems[index].key;
      const existingTitle = tabItems[index].title;

      const { id, tenantName, moveInDate, leaseExpiresDate, rent, deposit } = data;

      const updatedTabItem = {
        id,
        key: existingKey,
        title: existingTitle,
        values: {
          tenant: tenantName || '',
          moveInDate: !!moveInDate ? new Date(moveInDate) : undefined,
          leaseExpire: !!leaseExpiresDate ? new Date(leaseExpiresDate) : undefined,
          rent,
          deposit: deposit || '',
        },
      };

      const updatedTabItems = [...tabItems];
      updatedTabItems[index] = updatedTabItem;

      setTabItems(updatedTabItems);
      mmToast('Lease successfully created', { type: 'success' });
    } catch (err) {
      mmToast('Something went wrong', { type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleLeaseUpdate = async (id: number, payload: Partial<ILease>, index: number) => {
    try {
      setLoading(true);
      const { data } = await patchLeaseForAccount(accountId, id, payload);
      handleReload();

      const { tenantName, moveInDate, leaseExpiresDate, rent, deposit } = data;

      const updatedValues: ILeaseFormValue = {
        tenant: tenantName,
        moveInDate: new Date(moveInDate),
        leaseExpire: new Date(leaseExpiresDate),
        rent,
        deposit,
      };

      const updatedTabItem = {
        ...tabItems[index],
        values: updatedValues,
      };

      const updatedTabItems = [...tabItems];
      updatedTabItems[index] = updatedTabItem;

      mmToast('Lease successfully updated', { type: 'success' });
    } catch (err) {
      mmToast('Something went wrong', { type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='leases'>
      {locked &&
      <div className='locked-area'>
        <div>
          <Link to='/settings?active=Plan' className='purple-links small-bold'>
            Track your real estate leases with a pro account.
          </Link>
        </div>
      </div>
      }
      <Tabs id='leases-tab' activeKey={tabKey} onSelect={(key) => setTabKey(key || '')}>
        {tabItems.map((tabItem, index) => (
          <Tab eventKey={tabItem.key} title={tabItem.title} key={tabItem.key}>
            <LeaseForm
              loading={loading}
              currencySymbol={currencySymbol}
              values={tabItem.values}
              onAddNewLease={handleAddNewLease}
              onSave={handleSave(tabItem.id, index)}
              onDeleteLease={handleDeleteLease(tabItem.id, index)}
            />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default Leases;
