import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import qs from 'query-string';
import useQroka from 'auth/hooks/useQroka';
import useFunctionRef from 'common/hooks/useFunctionRef';
import CircularSpinner from 'common/components/spinner/circular-spinner';

const QrokaCoinbase = () => {
  const code: string = qs.parse(window.location.search).code as string;
  const history = useHistory();
  const [codeError, setCodeError] = React.useState('');

  const { loading, addCoinbaseAccount } = useQroka();
  const addCoinbaseAccountRef = useFunctionRef(addCoinbaseAccount);

  React.useEffect(() => {
    (async () => {
      if (code) {
        const { data, error } = await addCoinbaseAccountRef.current(code);

        if (error || !data) {
          return setCodeError('Code validation failed. Please try again.');
        }

        return history.push('/connect-account?from=qrokaCoinbase&provider=QROKA');
      }
    })();
  }, [code, addCoinbaseAccountRef, history]);

  const renderContent = () => {
    if (loading) {
      return <CircularSpinner />;
    }

    if (codeError) {
      return (
        <div className='qroka-no-code-wrapper'>
          <h3>{codeError}</h3>
          <Link to='/connect-account'>
            <button className='mm-btn-animate btn-outline-primary'>Go to connect account</button>
          </Link>
        </div>
      );
    }

    if (!code) {
      return (
        <div className='qroka-no-code-wrapper'>
          <h3>We can not find code in the url. Please try again.</h3>
          <Link to='/connect-account'>
            <button className='mm-btn-animate btn-outline-primary'>Go to connect account</button>
          </Link>
        </div>
      );
    }
  };

  return <div className='qroka-coinbase-wrapper'>{renderContent()}</div>;
};

export default QrokaCoinbase;
