import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';

import useToast from 'common/hooks/useToast';
import classNames from 'common/classes.helper';
import useAccounts from 'auth/hooks/useAccounts';
import LoadingScreen from 'common/loading-screen';
import FastLinkModal from 'yodlee/fast-link.modal';
import useAnalytics from 'common/hooks/useAnalytics';
import DefaultAvatar from 'assets/icons/default-avatar.svg';
import useGetSubscription from 'auth/hooks/useGetSubscription';
import useCurrentSubscription from 'auth/hooks/useCurrentSubscription';
import {
  AccountRowProps,
  AccountCardProps,
  ManualAccountProps,
  AccountOverviewProps,
  AccountDialogBoxProps,
  SubscriptionConnectionWarningProps,
} from 'setting/setting.type';
import { events } from '@mm/data/event-list';
import Switch from 'common/components/switch';
import { useModal } from 'common/components/modal';
import { useAuthDispatch } from 'auth/auth.context';
import { getRelativeDate } from 'common/moment.helper';
import { EConnectionStatus } from 'common/common.types';
import { Account, EProviderType } from 'auth/auth.types';
import { FastLinkOptionsType } from 'yodlee/yodlee.type';
import { IFixAccountConfig } from 'account/account.type';
import { appRouteConstants } from 'app/app-route.constant';
import { getCurrencySymbol } from 'common/currency-helper';
import { base64Encode } from 'common/helper/string.helper';
import { Placeholder } from 'networth/views/inc/placeholder';
import { pricingDetailConstant } from 'common/common.constant';
import { fNumber, numberWithCommas } from 'common/number.helper';
import { ReactComponent as IconEdit } from 'assets/icons/icon-edit.svg';
import { getFastlinkUpdate, toggleIgnoreAccount } from 'api/request.api';
import { ReactComponent as DeleteIcon } from 'assets/icons/icon-delete.svg';
import { isSaltEdgeAccount, isYodleeAccount } from 'account/account.helper';
import useSaltEdge, { useRemoveSaltEdgeLocation } from 'account/hooks/useSaltEdge';
import { ReactComponent as BackArrow } from 'assets/images/subscription/back-arrow.svg';
import { deleteAccounts, deleteAccountById, fetchConnectionInfo } from 'auth/auth.service';
import { ReactComponent as DefaultProviderLogo } from 'assets/icons/mm-default-provider.svg';
import { ReactComponent as SubscriptionWarning } from 'assets/images/subscription/warning.svg';
import { groupByProviderNameAndProviderAccountId, parseProviderNameFromKey } from 'auth/auth.helper';
import DeleteAccountModal, { defaultDeleteAccountContent } from 'auth/views/inc/delete-account.modal';

export const AccountOverview: React.FC<AccountOverviewProps> = ({ reviewSubscriptionFlag = false }) => {
  useRemoveSaltEdgeLocation();
  const history = useHistory();
  const { mmToast } = useToast();
  const dispatch = useAuthDispatch();

  const [refreshCounter, setRefreshCounter] = useState(0);

  const { fetchingCurrentSubscription, currentSubscription } = useCurrentSubscription();
  const { fetchingSubscription, subscriptionDetail: subscription } = useGetSubscription(currentSubscription?.priceId);

  const {
    accounts,
    removeAccount,
    removeAccounts,
    fetchAllAccounts,
    fetchRefreshedAccounts,
    removeProviderAccounts,
    loading: fetchingAccounts,
  } = useAccounts();

  const accountLength = accounts?.length;
  const fetchAllAccountsRef = useRef(fetchAllAccounts);

  useEffect(() => {
    if (!accountLength) {
      const getUser = async () => {
        await fetchConnectionInfo({ dispatch });
      };

      getUser();
    }
  }, [accountLength, dispatch]);

  useEffect(() => {
    (async () => {
      await fetchAllAccountsRef.current();
    })();
  }, [refreshCounter]);

  const refreshAccount = () => {
    setRefreshCounter((c) => c + 1);
  };

  const handleRefreshAccount = async (provider: EProviderType) => {
    await fetchRefreshedAccounts(dispatch, provider);

    return refreshAccount();
  };

  const loading = fetchingCurrentSubscription || fetchingSubscription || fetchingAccounts;

  if (loading) {
    return <LoadingScreen onAccountFetching />;
  }

  const manualAccounts = accounts?.filter((acc) => acc.isManual) || [];
  const connectedAccounts = accounts?.filter((acc) => !acc.isManual) || [];

  const numberOfConnectedAccounts = subscription?.details?.[pricingDetailConstant.CONNECTED_ACCOUNT] || '0';
  const numberOfManualAccounts = subscription?.details?.[pricingDetailConstant.MANUAL_ACCOUNT] || '0';

  const verifyAccountNumbers = (event: React.ChangeEvent<any>) => {
    event.preventDefault();

    if (
      numberOfConnectedAccounts === 'Unlimited' ||
      (manualAccounts.length <= +numberOfManualAccounts && connectedAccounts.length <= +numberOfConnectedAccounts)
    ) {
      return history.push(appRouteConstants.dashboard.DASHBOARD);
    }

    mmToast('Kindly remove accounts first.', { type: 'error' });
  };

  return (
    <section className='mm-account-overview mm-setting'>
      {reviewSubscriptionFlag && (
        <SubscriptionConnectionWarning
          availableConnectedAccounts={numberOfConnectedAccounts}
          availableManualAccounts={numberOfManualAccounts}
        />
      )}
      <AccountCard
        removeAccount={removeAccount}
        accountList={connectedAccounts}
        removeAccounts={removeAccounts}
        handleRefreshAccount={handleRefreshAccount}
        availableAccounts={numberOfConnectedAccounts}
        reviewSubscriptionFlag={reviewSubscriptionFlag}
        removeProviderAccounts={removeProviderAccounts}
      />
      <ManualAccounts
        manualAccountList={manualAccounts}
        availableAccounts={numberOfManualAccounts}
        removeAccount={removeAccount}
        removeAccounts={removeAccounts}
        reviewSubscriptionFlag={reviewSubscriptionFlag}
      />
      {reviewSubscriptionFlag && (
        <AccountDialogBox
          verifyAccountNumbers={verifyAccountNumbers}
          availableConnectedAccounts={numberOfConnectedAccounts}
          availableManualAccounts={numberOfManualAccounts}
          accountList={connectedAccounts}
          manualAccountList={manualAccounts}
        />
      )}
    </section>
  );
};

export const ManualAccounts: React.FC<ManualAccountProps> = ({
  manualAccountList,
  availableAccounts,
  reviewSubscriptionFlag,
  removeAccount,
  removeAccounts: clearAccounts,
}) => {
  const history = useHistory();
  const { mmToast } = useToast();
  const dispatch = useAuthDispatch();
  const [deleting, setDeleting] = useState<boolean>(false);
  const needUpgrade = manualAccountList.length >= availableAccounts;

  const addAccount = () => {
    if (reviewSubscriptionFlag) {
      return history.push(appRouteConstants.subscription.SUBSCRIPTION);
    }

    if (!needUpgrade) {
      history.push(appRouteConstants.auth.CONNECT_ACCOUNT);
    } else {
      history.push(`${appRouteConstants.settings.SETTINGS}?active=Plan`);
    }
  };

  const removeAccounts = async (accounts: Account[]) => {
    setDeleting(true);
    const { error } = await deleteAccounts({ dispatch, accounts });

    if (error) {
      mmToast('Error occurred deleting account', { type: 'error' });
    }

    clearAccounts(accounts.map(({ id }) => id));
    setDeleting(false);
  };

  return (
    <>
      <div className='card mm-setting-card'>
        <div className='d-md-flex flex-wrap justify-content-between align-items-center'>
          <div className={`mm-account-overview__add-account m-b-8 mb-md-0 ${needUpgrade ? 'text-danger' : ''}`}>
            <span>
              Manual Accounts ({manualAccountList.length}/{availableAccounts})
            </span>
            {needUpgrade ? <span className='upgrade-caption'>Upgrade your account to add more accounts</span> : null}
          </div>
          <div>
            <button type='button' className='btn btn-outline-primary mm-button btn-lg' onClick={addAccount}>
              {needUpgrade || reviewSubscriptionFlag ? 'Upgrade Plan' : 'Add Account'}
            </button>
          </div>
        </div>
      </div>
      <div className='card mm-setting-card mm-account-overview__account'>
        <div className='row pb-2 pt-1'>
          <div className='col-10 col-md-6'>
            <div>
              <DefaultProviderLogo className='mr-3 mr-md-4' />
              <span className='mm-account-overview__block-title'>Money Minx Manual</span>
            </div>
          </div>
        </div>
        {manualAccountList.length === 0 && <Placeholder type='manual' />}
        {manualAccountList.map((acc, index) => {
          return (
            <AccountRow
              key={index}
              account={acc}
              removeAccount={removeAccount}
              reviewSubscriptionFlag={reviewSubscriptionFlag}
            />
          );
        })}
        <div className='row py-3 align-items-center'>
          <div className='col-12 col-md-6' />
          <div className='col-12 col-md-6 text-md-right'>
            <button
              className='btn text-danger mm-button__flat mm-account-overview__delete-link'
              aria-label='Delete Manual Account'
              onClick={() => {
                removeAccounts(manualAccountList);
              }}
              disabled={deleting}
            >
              {deleting ? <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' /> : null}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export interface AccountsByProvider {
  provider_name: string;
  accounts: Account[];
}

export interface AccountByStatus {
  success: AccountsByProvider[];
  warning: AccountsByProvider[];
  warning_wait: AccountsByProvider[];
  warning_stale: AccountsByProvider[];
  error: AccountsByProvider[];
  archived: AccountsByProvider[];
}

export const AccountCard: React.FC<AccountCardProps> = ({
  accountList,
  removeAccount,
  availableAccounts,
  handleRefreshAccount,
  reviewSubscriptionFlag,
  removeProviderAccounts,
  removeAccounts: clearAccounts,
}) => {
  const history = useHistory();
  const { event } = useAnalytics();
  const { mmToast } = useToast();
  const dispatch = useAuthDispatch();
  const [deleting, setDeleting] = useState<boolean>(false);
  const [fastLinkOptions, setFastLinkOptions] = useState<FastLinkOptionsType>({
    fastLinkURL: '',
    token: { tokenType: 'AccessToken', tokenValue: '' },
    config: { flow: '', configName: 'Aggregation', providerAccountId: 0 },
  });
  const fastlinkModal = useModal();
  const deleteAccountModal = useModal();
  const { loading } = useAccounts();
  const { reconnectSaltEdge, refreshSaltEdge } = useSaltEdge();

  const needUpgrade = accountList.length >= +availableAccounts;
  const accountsByProviderNameAndProviderAccountId = groupByProviderNameAndProviderAccountId(accountList);
  const [deletingAccounts, setDeletingAccounts] = useState<Account[]>();
  const [deleteAccountsContent, setDeleteAccountContent] = useState(defaultDeleteAccountContent);

  const [fixAccountConfig, setFixAccountConfig] = useState<IFixAccountConfig | null>(null);

  const handleConnectAccount = async (accId: number, update: boolean, refresh: boolean) => {
    const { data, error } = await getFastlinkUpdate(accId, update, refresh);

    if (error) {
      return mmToast('Error Occurred to Get Fastlink', { type: 'error' });
    }

    const fLinkOptions: FastLinkOptionsType = {
      fastLinkURL: data.fastLinkUrl,
      token: data.accessToken,
      config: data.params,
    };

    setFastLinkOptions(fLinkOptions);

    event(events.connectAccount);

    return fastlinkModal.open();
  };

  const handleOpenNewFixYodleeConnection = async () => {
    if (!fixAccountConfig) {
      return;
    }

    const { accountId, update, refresh } = fixAccountConfig;

    handleConnectAccount(accountId, update, refresh);
  };

  const handleYodleeConnection = (accountId: number, update: boolean, refresh: boolean) => {
    setFixAccountConfig({
      accountId,
      update,
      refresh,
    });

    handleConnectAccount(accountId, update, refresh);
  };

  const handleSaltedgeReconnect = async (accountId: number) => {
    if (!accountId) return;

    const res = await reconnectSaltEdge(accountId);

    if (res) {
      return window.open(res.connectUrl, '_self');
    }
  };

  const handleSaltedgeRefresh = async (accountId: number) => {
    if (!accountId) return;

    const res = await refreshSaltEdge(accountId);

    if (res) {
      return window.open(res.connectUrl, '_self');
    }
  };

  const handleFixConnection = (account: Account) => {
    if (account?.connectionStatus === EConnectionStatus.WAIT || !account?.id) return;

    if (isYodleeAccount(account?.mmAccountProvider)) {
      return handleYodleeConnection(account.id, true, false);
    }

    if (isSaltEdgeAccount(account?.mmAccountProvider)) {
      return handleSaltedgeReconnect(account.id);
    }
  };

  const handleRefreshConnection = (account: Account) => {
    if (account?.connectionStatus === EConnectionStatus.WAIT || !account?.id) return;

    if (isYodleeAccount(account?.mmAccountProvider)) {
      return handleYodleeConnection(account.id, false, true);
    }

    if (isSaltEdgeAccount(account?.mmAccountProvider)) {
      return handleSaltedgeRefresh(account.id);
    }
  };

  const accountsByStatus: AccountByStatus = {
    error: [],
    warning: [],
    warning_wait: [],
    warning_stale: [],
    success: [],
    archived: [],
  };

  for (const pNamePAccId in accountsByProviderNameAndProviderAccountId) {
    if (!accountsByProviderNameAndProviderAccountId.hasOwnProperty(pNamePAccId)) continue;

    const pName = parseProviderNameFromKey(pNamePAccId);

    const connectionStatus = accountsByProviderNameAndProviderAccountId[pNamePAccId][0].connectionStatus;
    const providerAccounts = accountsByProviderNameAndProviderAccountId[pNamePAccId];

    const isArchivedAccounts = providerAccounts.reduce((acc, current) => {
      return acc && current.connectionStatus === EConnectionStatus.ARCHIVED;
    }, true);

    if (isArchivedAccounts) {
      accountsByStatus.archived.push({ provider_name: pName, accounts: providerAccounts });
    } else {
      if (connectionStatus === EConnectionStatus.GOOD ||
          connectionStatus === EConnectionStatus.WAIT ||
          connectionStatus === EConnectionStatus.STALE) {
        accountsByStatus.success.push({ provider_name: pName, accounts: providerAccounts });
      } else if (connectionStatus === EConnectionStatus.ATTENTION) {
        accountsByStatus.warning.push({ provider_name: pName, accounts: providerAccounts });
      } else {
        accountsByStatus.error.push({ provider_name: pName, accounts: providerAccounts });
      }
    }
  }

  const addAccount = () => {
    if (reviewSubscriptionFlag) {
      return history.push(appRouteConstants.subscription.SUBSCRIPTION);
    }

    if (!needUpgrade) {
      history.push(appRouteConstants.auth.CONNECT_ACCOUNT);
    } else {
      history.push(`${appRouteConstants.settings.SETTINGS}?active=Plan`);
    }
  };

  const removeAccounts = async (accounts: Account[]) => {
    setDeleting(true);
    const { error } = await deleteAccounts({ dispatch, accounts });

    if (error) {
      mmToast('Error occurred deleting account', { type: 'error' });
    }

    clearAccounts(accounts.map(({ id }) => id));

    setDeleting(false);
  };

  const getStatusClassName = (status: string, isSaltedge = false) => {
    if (status === 'success') {
      return 'mm-account-overview__connected';
    }

    if (status === 'warning_wait') {
      return 'mm-account-overview__connected';
    }

    if (status === 'warning' || status === 'warning_stale') {
      return 'mm-account-overview__info';
    }

    if (status === 'archived') {
      return 'mm-account-overview__archived';
    }

    return 'mm-account-overview__error';
  };

  const initRemoveAccounts = (accounts: Account[]) => {
    setDeleteAccountContent({
      ...deleteAccountsContent,
      content: <p>Do you really want to delete all these accounts? This process cannot be undone.</p>,
    });

    setDeletingAccounts(accounts);

    deleteAccountModal.open();
  };

  const hasUpdateCredentials = (mmAccountProvider: string) => {
    return ![
      EProviderType.SALT_EDGE_GATEWAY,
      EProviderType.SALT_EDGE_PARTNER,
      EProviderType.ZABO,
      EProviderType.QROKA,
    ].includes(mmAccountProvider as EProviderType);
  };

  if (loading) {
    return <LoadingScreen onAccountFetching />;
  }

  return (
    <>
      <div className='card mm-setting-card'>
        <div className='d-md-flex flex-wrap justify-content-between align-items-center'>
          <div className={`mm-account-overview__add-account m-b-8 mb-md-0 ${needUpgrade ? 'text-danger' : ''}`}>
            <span>
              Connected Accounts ({accountList.length}/{availableAccounts})
            </span>
            {needUpgrade ? <span className='upgrade-caption'>Upgrade your account to add more connections</span> : null}
          </div>
          <div>
            <button type='button' className='btn btn-outline-primary mm-button btn-lg' onClick={addAccount}>
              {needUpgrade || reviewSubscriptionFlag ? 'Upgrade Plan' : 'Add Account'}
            </button>
          </div>
        </div>
      </div>
      {Object.entries(accountsByStatus).map(([status, groupArr], i) => (
        <div key={i}>
          {groupArr.map((group: AccountsByProvider, index: number) => (
            <div key={index}>
              <div
                className={[
                  'card mm-setting-card',
                  getStatusClassName(status, isSaltEdgeAccount(group.accounts[0].mmAccountProvider)),
                ].join(' ')}
              >
                {status === 'error' && (
                  <div className='row pb-3 align-items-center no-gutters fix-connection-sec'>
                    <div className='col-6 text-danger'>
                      <span>Connection Error</span>
                    </div>
                    <div className='col-6 mt-2 text-md-right'>
                      <button
                        type='button'
                        className='btn btn-outline-primary mm-button btn-lg'
                        onClick={() => handleFixConnection(group.accounts[0])}
                      >
                        Fix Connection
                      </button>
                    </div>
                  </div>
                )}

                {status === 'warning_stale' && (
                  <div className='row pb-3 align-items-center no-gutters fix-connection-sec'>
                    <div className='col-12 col-md-6 text-warning'>
                      <span>Needs Attention</span>
                    </div>
                    <div className='col-12 col-md-6 mt-2 text-md-right'>
                      <button
                        type='button'
                        className='btn btn-outline-primary mm-button btn-lg'
                        onClick={() => handleRefreshConnection(group.accounts[0])}
                      >
                        Refresh Account
                      </button>
                    </div>
                  </div>
                )}

                {status === 'warning' && (
                  <div className='row pb-3 align-items-center no-gutters fix-connection-sec'>
                    <div className='col-12 col-md-6 text-warning'>
                      <span>Needs Attention</span>
                    </div>
                    <div className='col-12 col-md-6 mt-2 text-md-right'>
                      <button
                        type='button'
                        className='btn btn-outline-primary mm-button btn-lg'
                        onClick={() => handleRefreshConnection(group.accounts[0])}
                      >
                        Fix Connection
                      </button>
                    </div>
                  </div>
                )}

                {status === 'warning_wait' && (
                  <div className='row pb-3 align-items-center no-gutters fix-connection-sec'>
                    <div className='col-12 col-md-6 text-success'>
                      <span>Refreshing</span>
                    </div>
                    <div className='col-12 col-md-6 mt-2 text-md-right'>
                      <span className='no-update'>
                        Account refresh in progress. Refresh your screen to see the latest data. This can take several
                        minutes.
                      </span>
                    </div>
                  </div>
                )}

                <div className={['row pb-2 pt-1 align-items-center', status === 'error' ? 'pt-4' : ''].join(' ')}>
                  <div className='col-12 col-md-6'>
                    <div>
                      <img
                        src={group.accounts[0]?.providerLogo || DefaultAvatar}
                        className='mr-3 mr-md-4 accounts-provider-logo'
                        alt={`${group.provider_name} logo`}
                      />
                      <span className='mm-account-overview__block-title'>{group.provider_name}</span>
                    </div>
                  </div>
                  <div className='col-12 col-md-6 order-md-1 text-md-right pt-2 pt-md-0'>
                    <small className='text--grayText'>
                      {group.accounts[0]?.providerAccount?.dataset?.[0]?.lastUpdated?.toString() !== null
                        ? 'Last updated ' +
                          getRelativeDate(group.accounts[0]?.providerAccount?.dataset?.[0]?.lastUpdated?.toString())
                        : 'Not yet updated'}
                    </small>
                  </div>
                </div>

                {group.accounts?.map((account: Account, accountIndex: number) => {
                  return (
                    <AccountRow
                      account={account}
                      key={accountIndex}
                      removeAccount={removeAccount}
                      reviewSubscriptionFlag={reviewSubscriptionFlag}
                      removeProviderAccounts={removeProviderAccounts}
                    />
                  );
                })}

                <div className='row py-3 align-items-center no-gutters'>
                  <div className='col-12 col-md-6'>
                    {!reviewSubscriptionFlag && hasUpdateCredentials(group.accounts[0].mmAccountProvider) ? (
                      <div className='mm-account-overview__update-link mb-3 mb-md-0'>
                        <span
                          className='purple-links update-credentials'
                          onClick={() => handleYodleeConnection(group.accounts[0].id, true, false)}
                          role='button'
                        >
                          Update Credentials
                        </span>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='col-12 col-md-6 mt-2 text-md-right hide-me'>
                    <button
                      className='btn text-danger mm-button__flat mm-account-overview__delete-link '
                      onClick={() => initRemoveAccounts(group.accounts)}
                      disabled={deleting}
                    >
                      {deleting ? (
                        <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
                      ) : null}
                      <span className={'ml-1'}> {deleting ? 'Deleting...' : 'Delete account and remove data'}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}

      <DeleteAccountModal
        deleteAccountModal={{ ...deleteAccountModal }}
        deleteAccounts={() => removeAccounts(deletingAccounts || [])}
        deleteAccountContent={deleteAccountsContent}
      />
      {fastlinkModal.props.open ? (
        <FastLinkModal
          fastLinkModal={fastlinkModal}
          fastLinkOptions={fastLinkOptions}
          handleSuccess={handleRefreshAccount}
          handleOpenNewConnection={handleOpenNewFixYodleeConnection}
        />
      ) : null}
    </>
  );
};

export const AccountRow: React.FC<AccountRowProps> = ({
  account,
  removeAccount,
  reviewSubscriptionFlag,
  removeProviderAccounts,
}) => {
  const history = useHistory();
  const { mmToast } = useToast();
  const dispatch = useAuthDispatch();
  const deleteAccountModal = useModal();
  const [deleting, setDeleting] = useState<boolean>(false);
  const currencySymbol = getCurrencySymbol(account.currency);
  const [deleteModalContent, setDeleteModalContent] = useState(defaultDeleteAccountContent);

  const isCurrentSaltedgeAccount =
    account.mmAccountProvider === EProviderType.SALT_EDGE_GATEWAY ||
    account.mmAccountProvider === EProviderType.SALT_EDGE_PARTNER;

  const deleteAccount = async () => {
    setDeleting(true);
    const id = account.id;
    const { error } = await deleteAccountById({ dispatch, id });

    if (error) {
      return mmToast('Error occurred deleting account', { type: 'error' });
    }

    if (isCurrentSaltedgeAccount && removeProviderAccounts) {
      removeProviderAccounts(account);

      return setDeleting(false);
    }

    removeAccount(id);
    setDeleting(false);
  };

  const initDeleteAccount = () => {
    if (isCurrentSaltedgeAccount) {
      setDeleteModalContent({
        ...deleteModalContent,
        content: (
          <div>
            <p>Do you really want to delete this account? This process cannot be undone.</p>
            <p className='text--redAlert'>Deleting this account will also delete all accounts for this provider</p>
          </div>
        ),
      });

      return deleteAccountModal.open();
    }

    setDeleteModalContent(defaultDeleteAccountContent);

    return deleteAccountModal.open();
  };

  const routeToAccountDetail = (accountId: number) => {
    const encodedAccountId = base64Encode(`${accountId}`);
    history.push(`/account-details/${encodedAccountId}`);
  };

  const handleToggleIgnoreAccount = (accountId: number, isIgnored: boolean) => {
    toggleIgnoreAccount(`${accountId}`, isIgnored);
  };

  return (
    <div className='row py-3 align-items-center no-gutters'>
      <div className='col-6 col-md-8 d-flex'>
        <Switch
          name='account-toggle'
          checked={!account.isIgnored}
          className='mr-4'
          onClick={(checked) => handleToggleIgnoreAccount(account.id, !checked)}
        />

        <Link
          className={classNames('gray-links', account.isArchived && 'closed-account')}
          to='#'
          aria-label='Account Details'
          onClick={() => routeToAccountDetail(account.id)}
        >
          {account.accountName} {account.accountNumber ? ` (${account.accountNumber.slice(-4)})` : null}
          {account.isArchived ? ' (closed)' : null}
        </Link>
      </div>
      <div className={classNames('col-3', 'col-md-2', account.isArchived && 'closed-account')}>
        {currencySymbol}
        {numberWithCommas(fNumber(account.balance, 2))}
      </div>
      <div className='col-3 col-md-2'>
        <div className='float-right'>
          {!reviewSubscriptionFlag ? (
            <Link to='#' aria-label='Edit Account' onClick={() => routeToAccountDetail(account.id)}>
              <IconEdit className='edit-icon' />
            </Link>
          ) : null}
          {deleting ? (
            <span className='spinner-grow spinner-grow-sm m-1' role='status' aria-hidden='true' />
          ) : (
            <DeleteIcon className='ml-2 ml-md-3 trash-icon' onClick={initDeleteAccount} />
          )}
        </div>
      </div>
      {deleteAccountModal.props.open ? (
        <DeleteAccountModal
          deleteAccounts={deleteAccount}
          deleteAccountModal={deleteAccountModal}
          deleteAccountContent={deleteModalContent}
        />
      ) : null}
    </div>
  );
};

export const SubscriptionConnectionWarning: React.FC<SubscriptionConnectionWarningProps> = ({
  availableConnectedAccounts,
}) => {
  return (
    <div className='row'>
      <div className='subs-ended-msg-box'>
        <div className='subs-ended-left'>
          <h4>Too many connections for current plan!</h4>
          <p>
            Your current plan only allows for {availableConnectedAccounts} connections, please remove connections to
            continue using Money Minx.
          </p>
        </div>
        <span className='warning-icon'>
          <SubscriptionWarning />
        </span>
      </div>
    </div>
  );
};

const AccountDialogBox: React.FC<AccountDialogBoxProps> = ({
  verifyAccountNumbers,
  availableConnectedAccounts,
  availableManualAccounts,
  manualAccountList,
  accountList,
}) => {
  const disable =
    availableManualAccounts === 'Unlimited' ||
    (manualAccountList.length <= availableManualAccounts && accountList.length <= availableConnectedAccounts)
      ? false
      : true;
  const connectedAccountDiff = accountList.length - parseInt(availableConnectedAccounts as string, 10);
  const manualAccountDiff = manualAccountList.length - parseInt(availableManualAccounts as string, 10);

  return (
    <div className='action-overlay'>
      <div className='subscription-bottom-text'>
        <div className='subs-content one'>
          <a href='/subscription'>
            <span className='btn-change-plan'>
              <BackArrow /> <span className='hide-sm'>Change Plan</span>
            </span>
          </a>
        </div>
        <div className='subs-content three'>
          <p>
            Remove {connectedAccountDiff > 0 ? connectedAccountDiff : 0} connected accounts and{' '}
            {manualAccountDiff > 0 ? manualAccountDiff : 0} manual accounts to keep current plan.
          </p>
        </div>
        <div className='subs-content four'>
          <button
            className='finish-btn'
            disabled={disable}
            onClick={(event) => {
              verifyAccountNumbers(event);
            }}
          >
            Finish
          </button>
        </div>
      </div>
    </div>
  );
};
