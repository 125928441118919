import { useEffect, useState } from 'react';

import { getDiscoverCompanies } from 'api/request.api';
import { EInvestorType } from 'discover/discover.enum';
import { IDiscoverResponse } from 'discover/discover.types';
import { useDiscoverDispatch, useDiscoverState } from 'discover/discover.context';
import { initialDiscoverFilterState, useDiscoverFilterState } from 'discover/context/discover-filter.context';
import { resetProcessing, setOpportunities, setProcessing, updateDiscoverStateValues } from 'discover/discover.actions';

const useDiscover = () => {
  const [error, setError] = useState<any>(null);

  const dispatch = useDiscoverDispatch();
  const { pagination, search } = useDiscoverState();
  const discoverFilterState = useDiscoverFilterState();

  useEffect(() => {
    const { hasMoreData, loadMore, nextId, limit } = pagination;

    const { types, risk, returnTypes, returnFrequencies, liquidity, investorType, minimum, targetReturns, sortField } =
      discoverFilterState;

    const fetchDiscover = async () => {
      if (!loadMore || !hasMoreData) {
        return;
      }

      if (!nextId) {
        dispatch(setProcessing());
      }

      const params = {
        limit,
        ...(search ? { searchParam: search } : {}),
        ...(nextId ? { startId: nextId } : {}),
        sortField: sortField ? sortField : undefined,
        sortOrder: 'ASC',
        type: types.length ? types.toString() : undefined,
        risk: risk ? risk : undefined,
        returnType: returnTypes.length ? returnTypes.toString() : undefined,
        returnFrequency: returnFrequencies.length ? returnFrequencies.toString() : undefined,
        liquidity: liquidity.length ? liquidity.toString() : undefined,
        investorType: investorType === EInvestorType.ACCREDITED_ONLY ? investorType : undefined,
        minPrice: minimum.min !== initialDiscoverFilterState.minimum.min ? minimum.min : undefined,
        maxPrice: minimum.max !== initialDiscoverFilterState.minimum.max ? minimum.max : undefined,
        minTargetReturns:
          targetReturns.min !== initialDiscoverFilterState.targetReturns.min ? targetReturns.min : undefined,
        maxTargetReturns:
          targetReturns.max !== initialDiscoverFilterState.targetReturns.max ? targetReturns.max : undefined,
      };

      const { data, error }: { data: IDiscoverResponse; error: any } = await getDiscoverCompanies(params);

      const { meta, opportunities } = data;

      if (error) {
        dispatch(resetProcessing());

        return setError(error);
      }

      if (opportunities) {
        dispatch(setOpportunities(opportunities));
      }

      dispatch(
        updateDiscoverStateValues({
          processing: false,
          pagination: {
            limit: 10,
            loadMore: false,
            nextId: meta?.nextId,
            hasMoreData: meta?.size > 0 && !!meta?.nextId,
          },
        })
      );
    };

    fetchDiscover();
  }, [dispatch, pagination, search, discoverFilterState]);

  return {
    error,
  };
};

export default useDiscover;
