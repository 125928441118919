import { IFilterState } from './filter.type';

export const initialFilterState: IFilterState = {
  fTypes: [],
  filters: [],
  fAccounts: [],
  fEntities: [],
  fLiquidity: [],
  fCategories: [],
  processing: false,
  fToDate: undefined,
  fFromDate: undefined,
  fTimeInterval: undefined,
};
