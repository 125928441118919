/**
 * Money minx holding types.
 */
export enum EMMHoldingType {
  YODLEE = 'YODLEE',
  /**
   * generated if yodlee has cash field in /accounts response
   * denotes account which can have holdings in future
   * If present we don't generate holding
   */
  YODLEE_CASH = 'YODLEE_CASH',
  MANUAL = 'MANUAL',
  /**
   * automatically generated holding for accounts with no holding(investment-assets/other-assets only)
   */
  GENERATED = 'GENERATED',
  /**
   * Generated from zabo balances
   */
  ZABO = 'ZABO',
  /**
   * Synced with coin gecko coin and rates.
   */
  COIN_GECKO = 'COIN_GECKO',
  /**
   * Synced with exchange rate metal rates.
   */
  PRECIOUS_METAL = 'PRECIOUS_METAL',
}
