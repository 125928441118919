import { logger } from 'common/logger.helper';
import { useAuthState } from 'auth/auth.context';
import { GistEvents } from '@mm/data/gist-events';
import { getAccountsCount, getCurrentSubscription, getProfile } from 'api/request.api';

interface IGistIdentifyPayload {
  email: string;
  name: string | null;
  plan: string;
  base_currency: string;
  referral_name: string;
  referred_by_name: string;
  refer_type: string;
  referral_level: string;
  subscription_status: string;
  subscription_end: number | string | null;
  subscription_cancel: number | string | null;
  connected_accounts: number;
  manual_accounts: number;
  total_accounts: number;
}

interface IGistEventPayload {
  [key: string]: string | number | null;
}

const useGistEvents = () => {
  const { user } = useAuthState();

  const identify = async () => {
    try {
      const { data: profileRes } = await getProfile();
      const { data: subscriptionRes } = await getCurrentSubscription();
      const { data: accountsCountRes } = await getAccountsCount();

      const userId = profileRes.id;
      const name =
        profileRes.firstName || profileRes.lastName
          ? `${profileRes.firstName || ''} ${profileRes.lastName || ''}`
          : null;

      const payload: IGistIdentifyPayload = {
        email: profileRes.email,
        name,
        plan: subscriptionRes.name,
        base_currency: profileRes.settings.currency,
        referral_name: profileRes.referralName,
        referred_by_name: profileRes.referredByName,
        refer_type: profileRes.referType,
        referral_level: profileRes.referralLevel,
        subscription_status: subscriptionRes.subscriptionStatus,
        subscription_end: subscriptionRes.subscriptionEnd,
        subscription_cancel: subscriptionRes.cancelAt,
        connected_accounts: accountsCountRes.connectedAccounts,
        manual_accounts: accountsCountRes.manualAccounts,
        total_accounts: accountsCountRes.totalAccounts,
      };

      window.gist.identify(userId, payload);
    } catch (err) {
      // Log error
      logger.log('Error identifying user with Gist', err);
    }
  };

  const track = (event: GistEvents, payload: IGistEventPayload) => {
    // We need to add delay here to handle cases when track is called immediately
    // identifying with gist
    setTimeout(() => {
      window.gist.track(event, payload);
      identify();
    }, 1000);
  };

  const updateGistUserParams = async (payload: Partial<IGistIdentifyPayload>) => {
    try {
      let userId = user?.id;
      let email = user?.email;

      if (!userId || !email) {
        const { data: profileRes } = await getProfile();
        userId = profileRes.id;
        email = profileRes.email;
      }

      window.gist.identify(userId, {
        email,
        ...payload,
      });
    } catch (err) {
      logger.log('Error updating Gist identity', err);
    }
  };

  return { identify, track, updateGistUserParams };
};

export default useGistEvents;
