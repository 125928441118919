import { useEffect, useState } from 'react';

import { logger } from 'common/logger.helper';

import { ConfigType, TokenType, YodleeHookType } from './yodlee.type';

const useYodlee: YodleeHookType = ({
  containerRef,
  fastLinkOptions: { fastLinkURL, token, config },
  onSuccess,
  onError,
  onClose,
  onEvent,
}) => {
  const [data, setData] = useState(null);
  const [ready, setReady] = useState(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    let script: HTMLScriptElement;
    const body: Node = document.body;

    if (!window.fastlink && fastLinkURL) {
      script = document.createElement('script');

      script.id = 'yodlee-fastlink-script';
      script.src = 'https://cdn.yodlee.com/fastlink/v4/initialize.js';
      script.async = true;
      script.defer = true;
      script.onload = () => setReady(true);
      script.onerror = () => setError('Yodlee FastLink library could not be loaded!');

      const s = document.getElementById('yodlee-fastlink-script');

      if (!s) {
        body.appendChild(script);
      }
    }

    return () => {
      window.fastlink?.close();

      if (script) {
        body.removeChild(script);
      }
    };
  }, [fastLinkURL, config]);

  const init = (
    currentToken?: TokenType,
    options?: {
      configName: ConfigType;
    }
  ) => {
    const getTokenString = (t?: TokenType) => {
      if (!t) {
        return {};
      }

      switch (t.tokenType) {
        case 'AccessToken': {
          return { accessToken: `Bearer ${t.tokenValue}` };
        }
        case 'JwtToken': {
          return { jwtToken: `Bearer ${t.tokenValue}` };
        }
      }
    };

    const getConfig = () => {
      const _config = { ...config };

      if (options?.configName) {
        _config.configName = options.configName;
      }

      logger.log('config', _config);

      return _config;
    };

    if (!containerRef.current) {
      throw new Error('Container not  loaded properly');
    }

    if (!window.fastlink) {
      throw new Error('Fast link is not loaded properly');
    }

    window.fastlink.open(
      {
        fastLinkURL,
        params: getConfig(),
        ...getTokenString(currentToken || token),
        forceIframe: true,
        onSuccess: (customerData: any) => {
          setData(customerData);

          if (onSuccess) {
            onSuccess(customerData);
          }
        },
        onError: (fastLinkError: any) => {
          setError(fastLinkError);

          if (onError) {
            onError(fastLinkError);
          }
        },
        onClose,
        onEvent,
      },
      containerRef.current.id
    );
  };

  return {
    init,
    data,
    error,
    ready,
  };
};

export default useYodlee;
