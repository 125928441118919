import { YodleeError } from 'yodlee/yodlee.type';

export enum ECustomYodleeError {
  UNRESOLVED_ERROR = 'UNRESOLVED_ERROR',
}

export const getYodleeError = (code?: string): YodleeError | undefined => {
  if (!code) {
    return;
  }

  if (code === ECustomYodleeError.UNRESOLVED_ERROR) {
    return YodleeError.TECHNICAL_ERROR;
  }

  if (code === 'E100' || code === 'N100' || code === 'N103' || code.includes('N4') || code.includes('N7')) {
    return YodleeError.TECHNICAL_ERROR;
  }

  if (code.includes('N5')) {
    return YodleeError.SESSION_ERROR;
  }

  if ('N104' === code) {
    return YodleeError.COOKIE_DISABLED;
  }

  if ('E104' === code) {
    return YodleeError.UPDATE_NOT_ALLOWED;
  }

  if ('E103' === code) {
    return YodleeError.ACCOUNT_REFRESH_IN_PROGRESS;
  }

  return YodleeError.SOMETHING_WENT_WRONG;
};
