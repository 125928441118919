import { useEffect } from 'react';
import { useLocation } from 'react-router';

import queryString from 'query-string';
import { ECookie } from 'common/common.types';
import { addDaysToDate } from 'common/moment.helper';

import useAppCookies from './useAppCookies';

const useReferralCookie = () => {
  const { setCookie } = useAppCookies();
  const location = useLocation();

  const referral = queryString.parse(location.search).r as string;

  useEffect(() => {
    if (referral) {
      setCookie(ECookie.REFER, referral, {
        path: '/',
        expires: addDaysToDate(30),
      });
    }
  }, [referral, setCookie]);
};

export default useReferralCookie;
