import React from 'react';
import { Table } from 'react-bootstrap';

import { gc } from 'common/interval-parser';
import classNames from 'common/classes.helper';
import { AccountCategory } from 'networth/networth.enum';
import { Placeholder } from 'networth/views/inc/placeholder';
import { setToggleLiabilities } from 'networth/networth.actions';
import { fNumber, numberWithCommas } from 'common/number.helper';
import { useNetworthDispatch, useNetworthState } from 'networth/networth.context';
import useSortableData, { defaultCurrentSortConfig } from 'common/hooks/useSortableData';

import SortIcon from './sort-icon';
import { INetworthSection } from './other-assets-section';

const LiabilitiesSection: React.FC<INetworthSection> = ({ currencySymbol, handleAccountClick }) => {
  const dispatch = useNetworthDispatch();
  const { accounts, networth, fCategories, groupByCategory, fToggleInvestment, fToggleLiabilities } =
    useNetworthState();

  const toggleLiabilities = () => {
    dispatch(setToggleLiabilities(!fToggleLiabilities));
  };

  const liabilities = accounts?.[AccountCategory.LIABILITIES];

  const {
    sortConfig,
    requestSort,
    items: sortedLiabilities,
  } = useSortableData(liabilities || [], defaultCurrentSortConfig);

  return groupByCategory && (fCategories.length === 0 || fCategories.includes('Liabilities')) ? (
    <div className='row mb-40'>
      <div className='col-12'>
        <div className='ct-box box-r'>
          {accounts && Object.keys(accounts).includes('Liabilities') ? (
            <div className='table-holder'>
              <Table className='tb-responsive' id='table-liabilities-xls'>
                <thead>
                  <tr>
                    <th>
                      <span role='button' className={!fToggleLiabilities ? 't-span' : ''} onClick={toggleLiabilities} />
                      <span
                        role='button'
                        className='table-header__item'
                        onClick={() => requestSort({ key: 'accountName' })}
                      >
                        Liabilities
                        <SortIcon sortConfig={sortConfig} sortKey='accountName' />
                      </span>
                    </th>
                    <th
                      className={classNames(!fToggleLiabilities ? 'd-hide' : '', 'table-header__item')}
                      onClick={() => requestSort({ key: 'accountType' })}
                    >
                      Type
                      <SortIcon sortConfig={sortConfig} sortKey='accountType' />
                    </th>
                    {liabilities?.[0]?.balances.map((item, idx) => (
                      <th
                        key={idx}
                        className={classNames(gc(item.interval), 'table-header__item')}
                        onClick={() => requestSort({ key: item.interval as any, isBalance: true })}
                      >
                        {item.interval}
                        <SortIcon sortConfig={sortConfig} sortKey={item.interval} />
                      </th>
                    ))}
                  </tr>
                </thead>
                {fToggleLiabilities ? (
                  <tbody className={'projection'}>
                    {sortedLiabilities?.map((liability, index) => {
                      return (
                        <tr key={index} onClick={() => handleAccountClick(liability.accountId)}>
                          <td>
                            <span className='table-account-name'>{liability.accountName}</span>
                          </td>
                          <td className={`hide-type`}>{liability.accountType}</td>
                          {liability.balances.map((b, idx) => (
                            <td
                              key={`${index}-${idx}`}
                              className={[b.type === `projection` && `projection`, gc(b.interval)].join(' ')}
                            >
                              <span className={gc(b.interval)}>{b.interval}</span>
                              {b.balance || b.balance === 0 ? currencySymbol : ''}
                              {b.balance || b.balance === 0 ? numberWithCommas(fNumber(b.balance, 2)) : '--'}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                ) : null}
                <tfoot className={'projection'}>
                  <tr>
                    <td className={'text--primary'}>Total</td>
                    <td className={[!fToggleInvestment ? 'd-hide' : '', `hide-type`].join(' ')}>{''}</td>
                    {networth?.map((nItem, idx) => (
                      <td
                        key={idx}
                        className={[nItem.type === `projection` && `projection`, gc(nItem.interval)].join(' ')}
                      >
                        <span className={gc(nItem.interval)}>{nItem.interval}</span>
                        {nItem.liabilities || nItem.liabilities === 0 ? currencySymbol : ''}
                        {nItem.liabilities || nItem.liabilities === 0
                          ? numberWithCommas(fNumber(nItem.liabilities, 2))
                          : '--'}
                      </td>
                    ))}
                  </tr>
                </tfoot>
              </Table>
            </div>
          ) : (
            <Placeholder type='liabilities' />
          )}
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default LiabilitiesSection;
