import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import { ReactComponent as SimpleAccountIcon } from 'assets/icons/simple-account.svg';
import { ReactComponent as DetailedHoldingsIcon } from 'assets/icons/detailed-holdings.svg';

interface ISeparateHoldingsModalProps {
  loading: boolean;
  description: string;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onSimpleClick: VoidFunction;
  onDetailedClick: VoidFunction;
}

const SeparateHoldingsModal: React.FC<ISeparateHoldingsModalProps> = ({
  loading,
  description,
  onBack,
  onClose,
  onSimpleClick,
  onDetailedClick,
}) => {
  return (
    <>
      <div className='separate-holdings app-modal'>
        <div className='app-modal__header'>
          <h2 className='app-modal__title'>Separate Holdings?</h2>

          <button onClick={onClose}>
            <AiOutlineClose className='modal-close' />
          </button>
        </div>

        <p className='app-modal__description'>{description}</p>

        <div className='row mb-4'>
          <div className='col-12 col-md-6 flex-grow-1'>
            <button
              className='d-flex separate-holdings__button separate-holdings__button--detailed'
              onClick={onDetailedClick}
            >
              {loading ? (
                <div className='loading'>
                  <span className='spinner-grow' role='status' aria-hidden='true' />
                </div>
              ) : (
                <>
                  <DetailedHoldingsIcon />
                  <div className='separate-holdings__button-content'>
                    <span>Detailed Holdings</span>
                    <p>
                      Allows you to add separate holdings in this account and track their value and classification
                      separately.
                    </p>
                  </div>
                </>
              )}
            </button>
          </div>
          <div className='col-12 col-md-6 flex-grow-1'>
            <button
              className='d-flex separate-holdings__button separate-holdings__button--simple'
              onClick={onSimpleClick}
            >
              <SimpleAccountIcon />
              <div className='separate-holdings__button-content'>
                <span>Simple Account</span>
                <p>
                  Allows you to track the value at the account level without having to worry about separate positions.
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className='action-wrapper mt-5'>
        <button className='btn-outline-primary mm-btn-animate' type='button' onClick={onBack}>
          Back
        </button>
      </div>
    </>
  );
};

export default SeparateHoldingsModal;
