import { useCookies } from 'react-cookie';

const useAppCookies = <T extends string>(deps?: T[]) => {
  const [cookie, setCookie, removeCookie] = useCookies(deps);

  return {
    cookie,
    setCookie,
    removeCookie,
  };
};

export default useAppCookies;
