import React from 'react';

import * as Sentry from '@sentry/react';
import Eth from '@ledgerhq/hw-app-eth';
import { ECryptoConnections } from 'auth/auth.enum';
import TransportWebHID from '@ledgerhq/hw-transport-webhid';
import { openTransportReplayer, RecordStore } from '@ledgerhq/hw-transport-mocker';

export interface ICryptoAccount {
  addressType: ECryptoConnections;
  address: string;
}

interface IError {
  message: string;
  type: ECryptoConnections;
}

enum ELedgerWallet {
  WRONG_APDU = 'RecordStoreWrongAPDU',
  USER_CANCELLED = 'TransportOpenUserCancelled',
}

interface ILedgerError {
  message: string;
  name: string;
  stack: string;
}

const mapErrorMessage = (error: ILedgerError) => {
  Sentry.captureException(error);

  if (error?.name === ELedgerWallet.USER_CANCELLED) {
    return 'You did not complete the connection. Please try again.';
  }

  return 'Error occurred connecting your ledger wallet.';
};

const useLedger = (mock = false) => {
  const [errors, setErrors] = React.useState<IError[]>([]);
  const [accounts, setAccounts] = React.useState<ICryptoAccount[]>([]);

  const setError = (errList: IError[], error: IError) => {
    const filteredErrors = errList.filter((e) => e.type !== error.type);

    setErrors([...filteredErrors, error]);
  };

  const setAccount = (account: ICryptoAccount) => {
    const filteredAccounts = accounts.filter((a) => a.addressType !== account.addressType);

    setAccounts([...filteredAccounts, account]);
  };

  const resetError = (type: ECryptoConnections) => {
    const filteredErrors = errors.filter((e) => e.type !== type);
    setErrors([...filteredErrors]);

    return filteredErrors;
  };

  const resetErrors = () => {
    setErrors(() => []);
  };

  const getBitcoinAddress = async () => {
    const errList = resetError(ECryptoConnections.BITCOIN);
    const { address, error } = mock ? await getMockBtcAddress() : await getBitcoinAdd();

    const errorMessage = !address ? 'Bitcoin address not found' : error;

    if (error || !address) {
      return setError(errList, {
        type: ECryptoConnections.BITCOIN,
        message: 'Bitcoin Error: ' + errorMessage,
      });
    }

    resetErrors();

    setAccount({
      addressType: ECryptoConnections.BITCOIN,
      address,
    });
  };

  const getEthereumAddress = async () => {
    const errList = resetError(ECryptoConnections.ETHEREUM);

    const { address, error } = mock ? await getMockEthAddress() : await getEthereumAdd();

    const errorMessage = !address ? 'Ethereum address not found' : error;

    if (error || !address) {
      return setError(errList, {
        type: ECryptoConnections.ETHEREUM,
        message: 'Ethereum Error: ' + errorMessage,
      });
    }

    resetErrors();
    setAccount({
      addressType: ECryptoConnections.ETHEREUM,
      address,
    });
  };

  return { getBitcoinAddress, getEthereumAddress, errors, accounts, resetErrors };
};

export default useLedger;

const getBitcoinAdd = async () => {
  try {
    const Btc = await import('@ledgerhq/hw-app-btc');

    const transport = await TransportWebHID.create();
    const btc = new Btc.default(transport);
    const { bitcoinAddress } = await btc.getWalletPublicKey(`44'/0'/0'/0`);

    return { address: bitcoinAddress, error: null };
  } catch (error: any) {
    const message = mapErrorMessage(error);

    return { address: null, error: message };
  }
};

const getEthereumAdd = async () => {
  try {
    const transport = await TransportWebHID.create();
    const eth = new Eth(transport);
    const { address } = await eth.getAddress(`44'/60'/0'/0/0`);

    return { address, error: null };
  } catch (error: any) {
    const message = mapErrorMessage(error);

    return { address: null, error: message };
  }
};

const getMockBtcAddress = async () => {
  try {
    const transportReplayer = await openTransportReplayer(
      RecordStore.fromString(`
        => b001000000
        <= 0107426974636f696e06312e332e323301029000
        => e040000011048000002c800000008000000000000000
        <= 410486b865b52b753d0a84d09bc20063fab5d8453ec33c215d4019a5801c9c6438b917770b2782e29a9ecc6edb67cd1f0fbf05ec4c1236884b6d686d6be3b1588abb2231334b453654666641724c683466564d36756f517a7673597135767765744a63564dbce80dd580792cd18af542790e56aa813178dc28644bb5f03dbd44c85f2d2e7a9000
      `)
    );

    const Btc = await import('@ledgerhq/hw-app-btc');

    const btc = new Btc.default(transportReplayer);
    const result = await btc.getWalletPublicKey(`44'/0'/0'/0`);

    return { address: result.bitcoinAddress, error: null };
  } catch (error: any) {
    const message = mapErrorMessage(error);

    return { address: null, error: message };
  }
};

const getMockEthAddress = async () => {
  try {
    const transport = await openTransportReplayer(
      RecordStore.fromString(`
      => e002000015058000002c8000003c800000008000000000000000
      <= 4104df00ad3869baad7ce54f4d560ba7f268d542df8f2679a5898d78a690c3db8f9833d2973671cb14b088e91bdf7c0ab00029a576473c0e12f84d252e630bb3809b28436241393833363265313939633431453138363444303932334146393634366433413634383435319000
      `)
    );
    const eth = new Eth(transport);
    const { address } = await eth.getAddress(`44'/60'/0'/0/0`);

    return { address, error: null };
  } catch (error) {
    const message = mapErrorMessage(error);

    return { address: null, error: message };
  }
};
