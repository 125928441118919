import React from 'react';

import { shortId } from 'common/common-helper';
import { Allocation } from 'allocation/allocation.type';
import { fNumber, numberWithCommas } from 'common/number.helper';
import useAllocationSort from 'allocation/hooks/useAllocationSort';

interface IAllocationTableBody {
  allocationKey: string;
  currencySymbol: string;
  allocations: Allocation[];
  sortOrder: 'DESC' | 'ASC';
  getTotal: (key: string) => any;
  isHidden: (key: string) => boolean;
  toggleAllocation: (key: string) => void;
  gotoDetailPage: (key: string) => Promise<void>;
}

const AllocationTableBody = (props: IAllocationTableBody) => {
  const {
    isHidden,
    getTotal,
    sortOrder,
    allocations,
    allocationKey,
    gotoDetailPage,
    currencySymbol,
    toggleAllocation,
  } = props;

  const { sortedAllocations } = useAllocationSort(allocations, sortOrder);

  return (
    <React.Fragment>
      <tbody>
        <tr>
          <td colSpan={2} className='mm-allocation-overview__table--title'>
            <span
              className={isHidden(allocationKey) ? 'mm-allocation-overview__table--title-collapse' : ''}
              onClick={() => toggleAllocation(allocationKey)}
              role='button'
            />
            <span onClick={() => toggleAllocation(allocationKey)} role='button'>
              {allocationKey}
            </span>
          </td>
        </tr>
      </tbody>
      <tbody className={isHidden(allocationKey) ? 'hide-me' : ''}>
        {sortedAllocations?.map((al) => {
          return (
            <React.Fragment key={shortId()}>
              <tr
                className='mm-allocation-overview__table--data-row-mobile'
                onClick={() => gotoDetailPage(al.description)}
              >
                <td>
                  <p className='mt-2 mb-0'>{al.description}</p>
                </td>
              </tr>
              <tr className='mm-allocation-overview__table--data-row' onClick={() => gotoDetailPage(al.description)}>
                <td>{al.description}</td>
                <td>
                  <span className='d-block'>Allocation</span>
                  {fNumber(al.per || 0, 2)}%
                </td>
                <td>
                  <span role='button' className='d-block'>
                    Value
                  </span>
                  {al.allocationValue ? `${currencySymbol}${numberWithCommas(fNumber(al.allocationValue, 0))}` : 0}
                </td>
              </tr>
            </React.Fragment>
          );
        })}
      </tbody>
      <tbody>
        <tr className='mm-allocation-overview__table--footer'>
          <td>Total</td>
          <td>{fNumber(getTotal(allocationKey)?.per || 0, 2)}%</td>
          <td>
            {currencySymbol}
            {numberWithCommas(fNumber(getTotal(allocationKey)?.total || 0, 0))}
          </td>
        </tr>
      </tbody>
    </React.Fragment>
  );
};

export default AllocationTableBody;
