import React from 'react';

import ICONS from 'common/components/icons';
import { EProviderType } from 'auth/auth.types';
import LoadingScreen from 'common/loading-screen';
import { IAccountState } from 'account/account.type';
import { accountConnectionCard } from 'auth/data/account-card-data.json';
import { ReactComponent as RightArrow } from 'assets/icons/connect-account/right-arrow.svg';
import { ReactComponent as ConnectCryptoImg } from 'assets/icons/connect-account/connect-crypto.svg';

import AccountConnectionCard from './account-connection-card';
import ConnectSaltEdgeWorld from './account-connection-salt-edge-world';

interface IConnectedAccountsOptionsProps {
  isLoading: boolean;
  autoLoading: boolean;
  zaboLoading: boolean;
  state?: IAccountState;
  handleCryptoExchange: VoidFunction;
  handleFastlinkConnection: VoidFunction;
  handleConnectAccountSuccess: (provider: EProviderType) => Promise<void>;
}

const ConnectedAccountsOptions: React.FC<IConnectedAccountsOptionsProps> = ({
  state,
  isLoading,
  zaboLoading,
  handleCryptoExchange,
  handleFastlinkConnection,
  handleConnectAccountSuccess,
}) => {
  if (isLoading) {
    return <LoadingScreen onAccountFetching />;
  }

  return (
    <>
      <div className='row mb-2 mb-md-4'>
        <div className='col-12 col-sm-6 mb-3'>
          <button
            className='connected-account-option connected-account-option--green px-3 py-4'
            type='submit'
            onClick={handleFastlinkConnection}
          >
            {state?.status === 'FASTLINK_INITIATING' ? (
              <div className='loading'>
                <span className='spinner-grow' role='status' aria-hidden='true' />
              </div>
            ) : (
              <>
                <ICONS.CONNECT_US />
                <div className='pl-3'>
                  <div className='d-flex justify-content-between align-items-center mb-2'>
                    <h2 className='connected-account-option__title'>US, Canada and Others</h2>
                    <RightArrow className='arrow' />
                  </div>
                  <p className='connected-account-option__description'>
                    Majority of US and Canadian institutions, some international.
                  </p>
                </div>
              </>
            )}
          </button>
        </div>

        <div className='col-12 col-sm-6 mb-3'>
          <AccountConnectionCard
            cardInfo={accountConnectionCard.saltEdge}
            icon={<ICONS.CONNECT_UKDE />}
            handleSuccess={handleConnectAccountSuccess}
          />
        </div>

        {/*Crypto*/}
        <div className='col-12 col-sm-6 mb-3'>
          <button
            className='connected-account-option connected-account-option--orange px-3 py-4'
            type='submit'
            onClick={handleCryptoExchange}
          >
            {zaboLoading ? (
              <div className='loading'>
                <span className='spinner-grow' role='status' aria-hidden='true' />
              </div>
            ) : (
              <>
                <ConnectCryptoImg />
                <div className='pl-3'>
                  <div className='d-flex justify-content-between align-items-center mb-2'>
                    <h2 className='connected-account-option__title'>Crypto and Alts</h2>
                    <RightArrow className='arrow' />
                  </div>
                  <p className='connected-account-option__description'>
                    Crypto wallets or address and Alts like Vinovest.
                  </p>
                </div>
              </>
            )}
          </button>
        </div>

        {/*Rest of the World*/}

        <div className='col-12 col-sm-6 mb-3'>
          <ConnectSaltEdgeWorld
            cardInfo={accountConnectionCard.saltEdgeWorld}
            icon={<ICONS.CONNECT_WORLD />}
            handleSuccess={handleConnectAccountSuccess}
          />
        </div>
      </div>
    </>
  );
};

export default ConnectedAccountsOptions;
