import { useEffect, useState } from 'react';

import { getUTC } from 'common/moment.helper';
import { getNetworthByType } from 'api/request.api';
import useSearchParam from 'auth/hooks/useSearchParam';
import { useFilterState } from 'filter/filter.context';
import { TimeIntervalEnum } from 'networth/networth.enum';
import { parseIntervalList } from 'common/interval-parser';
import { useNetworthDispatch } from 'networth/networth.context';
import { INetworthByType, NetworthItem } from 'networth/networth.type';
import { resetProcessing, setNetWorth, setProcessing } from 'networth/networth.actions';

const useNetworthByType = (groupByCategory = false) => {
  const dispatch = useNetworthDispatch();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [refetchCounter, setRefetchCounter] = useState(0);
  const [response, setResponse] = useState<INetworthByType>();

  const {
    fTypes,
    fAccounts,
    fEntities,
    fLiquidity,
    fCategories,
    fToDate: toDate,
    fFromDate: fromDate,
    fTimeInterval: timeInterval,
  } = useFilterState();

  const accountType = fTypes.length ? fTypes.toString() : undefined;
  const category = fCategories.length ? fCategories.toString() : undefined;
  const accountId = fAccounts.length ? fAccounts.toString() : undefined;
  const investingEntityId = fEntities.length ? fEntities.toString() : undefined;
  const liquidity = fLiquidity.length ? fLiquidity.toString() : undefined;
  const refetchAccounts = useSearchParam('refetchAccounts');

  useEffect(() => {
    const fetchNetworth = async () => {
      setLoading(true);

      dispatch(setProcessing('networthByType'));

      const { data, error: networthError } = await getNetworthByType({
        accountType,
        timeInterval,
        category,
        fromDate: fromDate ? getUTC(fromDate) : undefined,
        toDate: toDate ? getUTC(toDate) : undefined,
        accountId,
        investingEntityId,
        liquidity,
      });

      dispatch(resetProcessing('networthByType'));

      const isQuarter = timeInterval === TimeIntervalEnum.QUARTERLY;

      if (networthError) {
        setLoading(false);

        return setError(networthError);
      }

      if (data?.groups) {
        data.groups.map((item: any) => {
          return (item.collapse = true);
        });
      }

      if (data?.networth) {
        const parsedNetworth: NetworthItem[] = parseIntervalList(data.networth, isQuarter) as any;
        dispatch(setNetWorth(parsedNetworth));
      }

      setLoading(false);

      return setResponse(data);
    };

    if (!groupByCategory || !!refetchAccounts) {
      fetchNetworth();
    }
  }, [
    toDate,
    category,
    fromDate,
    dispatch,
    accountId,
    liquidity,
    accountType,
    timeInterval,
    refetchCounter,
    groupByCategory,
    investingEntityId,
    refetchAccounts,
  ]);

  const refresh = () => setRefetchCounter((counter) => counter + 1);

  return {
    error,
    refresh,
    loadingByType: loading,
    groups: response?.groups,
  };
};

export default useNetworthByType;
