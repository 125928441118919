import React from 'react';
import { Link } from 'react-router-dom';

import { events } from '@mm/data/event-list';
import GALink from 'common/components/ga-link';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';

const WebsiteHeader = () => {

  return (
    <nav className='mm-navbar navbar-expand-lg navbar-light'>
      <div className='logo-btn-wrapper'>
        <Link className='navbar-brand' to='/' aria-label='Money Minx'>
          <Logo role='img' aria-labelledby='mmLogoTitle mmLogoDesc'/>
        </Link>
      </div>
      <div className='right-menu-wrapper'>
        <GALink
          className='mm-nav-link'
          role='menuitem'
          to='/signup'
          eventArgs={{
            ...events.ctaSignUp,
            label: 'Sign up',
          }}
        >
          <button className='mm-btn-primary mm-btn-animate'>Sign up</button>
        </GALink>
      </div>
    </nav>
  );
};

export default WebsiteHeader;
