import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { groupByProviderName } from 'auth/auth.helper';
import DefaultAvatar from 'assets/icons/default-avatar.svg';
import { useNetworthState } from 'networth/networth.context';

/*Todo change this logic in the future to show accounts not done with first load*/
const AccountWithFirstLoadIssues = () => {
  const { accountWithIssues } = useNetworthState();

  const [processingCollapse, setProcessingCollapse] = useState<boolean>(false);
  const accountsByProvider = accountWithIssues ? groupByProviderName(accountWithIssues) : {};

  return accountWithIssues && accountWithIssues.length > 100000 ? (
    <div className='card mm-setting-card mt-0 processing-card'>
      <div className='title-section'>
        <span
          className={['processing', processingCollapse ? 'processing-collapse' : ''].join(' ')}
          onClick={() => setProcessingCollapse(!processingCollapse)}
          role='button'
        >
          Processing
        </span>
        <span className='desc'>These accounts are still loading. Check back soon.</span>
      </div>
      <div className={processingCollapse ? 'd-none' : ''}>
        {Object.entries(accountsByProvider).map(([providerName, providerAccounts], index) => (
          <div key={index} className='content-section my-3'>
            <div className='d-flex flex-direction-row justify-content-between'>
              <img
                src={providerAccounts[0].providerLogo || DefaultAvatar}
                className='mr-3 mr-md-4 accounts-provider-logo my-1'
                alt={`${providerName} logo`}
              />
              <div className='provider-name my-1'>{providerName}</div>
            </div>
            {providerAccounts.map((item, key) => (
              <div key={key}>
                <Link to={`/account-details/${item.id}`}>
                  <div className='account-name m-b-2'>{item.accountName}</div>
                </Link>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default AccountWithFirstLoadIssues;
