import React from 'react';

import { StripeSubscriptionStatus } from 'setting/setting.enum';
import { IInitialCounter } from 'account/hooks/useAccountDetail';
import useRealEstateDetail from 'account/hooks/useRealEstateDetail';
import useCurrentSubscription from 'auth/hooks/useCurrentSubscription';

import AssociatedMortgage from '../views/inc/associated-mortgage';

interface IPersonalRealEstateSideCardProps {
  accountId: number;
  handleReload: (key: keyof IInitialCounter) => void;
}

const PersonalRealEstateSideCard: React.FC<IPersonalRealEstateSideCardProps> = ({ accountId, handleReload }) => {
  const { mortgageAccounts, associatedMortgageAccounts, fetchAssociatedMortgageAccounts } =
    useRealEstateDetail(accountId);

  const { currentSubscription } = useCurrentSubscription();

  const isFreeUser =
    currentSubscription?.subscriptionStatus !== StripeSubscriptionStatus.TRIALING &&
    currentSubscription?.subscriptionStatus !== StripeSubscriptionStatus.ACTIVE;

  const handleRefresh = () => {
    fetchAssociatedMortgageAccounts(accountId);
    handleReload('detail');
  };

  return (
    <div className='col-lg-3 account-sidecard-wrapper'>
      <div className='account-sidecard'>
        <AssociatedMortgage
          accountId={accountId}
          manualAccounts={mortgageAccounts}
          associatedMortgageAccounts={associatedMortgageAccounts}
          handleReload={handleRefresh}
          locked={isFreeUser}
        />
      </div>
    </div>
  );
};

export default PersonalRealEstateSideCard;
