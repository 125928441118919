import { makeTypes } from 'common/common-helper';

export const auth: Record<string, string> = {
  ...makeTypes('LOGIN'),
  ...makeTypes('REGISTER'),
  ...makeTypes('SIGN_OUT'),
  ...makeTypes('PROFILE_COMPLETE'),
  ...makeTypes('FETCH_ACCOUNT'),
  ...makeTypes('FETCH_PROFILE'),
  ...makeTypes('UPDATE_EMAIL_ADDRESS'),
  SET_AUTH_LOADING: 'SET_AUTH_LOADING',
  RESET_AUTH_LOADING: 'RESET_AUTH_LOADING',
};

export const subscription = {
  SET_SUBSCRIPTION_DETAIL: 'SET_SUBSCRIPTION_DETAIL',
  SET_CURRENT_SUBSCRIPTION: 'SET_CURRENT_SUBSCRIPTION',
};
