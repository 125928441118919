export type ConfigType = 'Aggregation' | 'AggregationDarkMode' | 'Verification' | 'VerAgg' | 'AggVer';

export type TokenEnumType = 'AccessToken' | 'JwtToken';

export interface TokenType {
  tokenType: TokenEnumType;
  tokenValue: string;
}

export interface FastLinkConfig {
  flow?: string;
  configName: ConfigType;
  providerAccountId?: number;
}

export interface FastLinkOptionsType {
  fastLinkURL: string;
  token?: TokenType;
  config?: FastLinkConfig;
}

export interface YodleeHookPropsType {
  containerId?: string;
  containerRef?: any;
  createScriptTag?: boolean;
  fastLinkOptions: FastLinkOptionsType;
  onSuccess?: (args: any) => void;
  onError?: (args: any) => void;
  onClose?: (args: any) => void;
  onEvent?: (args: any) => void;
}

export interface YodleeHookReturnType {
  init: (
    token?: TokenType,
    options?: {
      configName: ConfigType;
    }
  ) => void;

  data: any;
  error: any;
  ready: boolean;
}

export interface YodleeCallBackType {
  providerAccountId: number;
  providerName: string;
  accountId: number;
  isAlreadyVerified: boolean;
  status: string;
  providerId: number;
  reason: string;
  requestId: string;
  action: string;
  additionalStatus: string;
  accountType: string;
  container: string;
  valuationType: string;
  isDeleted: string;
  isManual: string;
  fnToCall: string;
}

export type YodleeHookType = (props: YodleeHookPropsType) => YodleeHookReturnType;

declare global {
  interface Window {
    fastlink: any;
  }
}

export enum YodleeStatus {
  ACTION_ABANDONED = 'ACTION_ABANDONED',
  USER_CLOSE_ACTION = 'USER_CLOSE_ACTION',
  FAILED = 'FAILED',
}

export enum YodleeError {
  SESSION_ERROR = 'SESSION_ERROR',
  COOKIE_DISABLED = 'COOKIE_DISABLED',
  TECHNICAL_ERROR = 'TECHNICAL_ERROR',
  UPDATE_NOT_ALLOWED = 'UPDATE_NOT_ALLOWED',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  INCORRECT_CREDENTIALS = 'INCORRECT_CREDENTIALS',
  DATA_RETRIEVAL_FAILED = 'DATA_RETRIEVAL_FAILED',
  ACCOUNT_REFRESH_IN_PROGRESS = 'ACCOUNT_REFRESH_IN_PROGRESS',
  USER_ACTION_NEEDED_AT_SITE = 'USER_ACTION_NEEDED_AT_SITE',
}
