import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';

import CopyButton from 'common/components/buttons/copy-button';

import useInviteFriends from '../hooks/useInviteFriends';

const InviteFriends: React.FC = () => {
  const { loading, referralLink } = useInviteFriends();

  const history = useHistory();
  const referralLinkRef = useRef<HTMLInputElement | null>(null);

  return (
    <div className='dashboard-invite-friends'>
      <h2 className='dashboard-invite-friends__title'>Invite Friends</h2>
      <p className='dashboard-invite-friends__description'>
        Get a $10 Amazon Gift Card for each friend that goes Money Minx Pro.
      </p>

      <div className='dashboard-invite-friends__body'>
        <h3>Your Referral Link</h3>

        <div className='referral-link-container'>
          {loading ? (
            <Skeleton />
          ) : (
            <>
              <Form.Control type='text' value={referralLink} ref={referralLinkRef} readOnly />
              <CopyButton elementRef={referralLinkRef} />
            </>
          )}
        </div>

        <button className='mm-btn-animate mm-btn-primary' onClick={() => history.push('refer')}>
          Earn $10
        </button>
      </div>
    </div>
  );
};

export default InviteFriends;
