import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';

import useAssets from 'dashboard/hooks/useAssets';
import { getCurrencySymbol } from 'common/currency-helper';
import { appRouteConstants } from 'app/app-route.constant';
import { fNumber, numberWithCommas } from 'common/number.helper';
import { EIntervalOption, EDiffStatus } from 'dashboard/dashboard.enum';
import { ReactComponent as AssetsLogo } from 'assets/images/dashboard/assets.svg';

import IntervalSelector from './interval-selector';
import AssetLiabilityItem from './asset-liability-item';

const Assets: React.FC = () => {
  const history = useHistory();
  const [currentInterval, setCurrentInterval] = useState<EIntervalOption>(EIntervalOption['1D']);

  const { loading, historyLoading, currencyOption, assets, assetsHistoryMap } = useAssets(currentInterval);

  const renderAssetItems = () => {
    if (!assetsHistoryMap || loading) {
      return <Skeleton height={450} />;
    }

    const handleAssetItemClick = (mmAccountType: string) => () => {
      const path = appRouteConstants.account.ACCOUNT_TYPES.replace(':type', mmAccountType);

      return history.push(path);
    };

    const currencySymbol = getCurrencySymbol(currencyOption);

    return (
      <div className='assets__body'>
        {assets.map((asset) => {
          const { mmAccountType, total, emoji } = asset;

          const balanceText = `${currencySymbol}${numberWithCommas(fNumber(total, 2))}`;

          const comparisonBalance = assetsHistoryMap[mmAccountType] || 0;
          const balanceDiff = total - comparisonBalance;

          let balanceDiffPerText = '--';

          if (comparisonBalance) {
            const balanceDiffPer = numberWithCommas(fNumber((balanceDiff / Math.abs(comparisonBalance)) * 100));

            balanceDiffPerText = `${balanceDiffPer}%`;
          }

          const isNegative = balanceDiff < 0;
          const balanceSymbol = isNegative ? '-' : '';
          const diffStatus = isNegative ? EDiffStatus.NEGATIVE : EDiffStatus.POSITIVE;

          const balanceDiffText = `${balanceSymbol}${currencySymbol}${numberWithCommas(
            fNumber(Math.abs(balanceDiff), 2)
          )}`;

          return (
            <AssetLiabilityItem
              emoji={emoji}
              key={mmAccountType}
              name={mmAccountType}
              diffStatus={diffStatus}
              loading={historyLoading}
              balanceText={balanceText}
              balanceDiff={balanceDiffText}
              balanceDiffPer={balanceDiffPerText}
              onClick={handleAssetItemClick(mmAccountType)}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className='assets'>
      <div className='assets__header-container'>
        <div className='assets__header'>
          <AssetsLogo />
          <h2>Assets</h2>
        </div>

        <IntervalSelector defaultInterval={currentInterval} onChange={setCurrentInterval} />
      </div>

      {renderAssetItems()}
    </div>
  );
};

export default Assets;
