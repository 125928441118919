const celsiusApiInstructions = [
  'Login to Celsius',
  'Click on your profile (top right)',
  'Click on API',
  'Click on Create New Key',
  'Turn on all of the Read options',
  'Click on Generate',
  'Copy your API Key and enter it in Money Minx',
  'Close the Modal in Celsius',
];

export default celsiusApiInstructions;
