import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import AppHeader from 'common/app.header';
import AppSidebar from 'common/app.sidebar';
import ICONS from 'common/components/icons';
import BBBImg from 'assets/images/home/acc_bus.png';
import { AuthLayout } from 'layouts/auth.layout';
import { useAuthState } from 'auth/auth.context';

import AccountSettingsSideBar from './account-settings-sidebar';

const AccountSettings = () => {
  const { onboarded } = useAuthState();

  const [openRightNav, setOpenRightNav] = useState<boolean>(false);
  const [openLeftNav, setOpenLeftNav] = useState<boolean>(false);

  const closeRightNav = () => {
    setOpenRightNav(false);
  };

  return (
    <AuthLayout>
      <HelmetProvider>
        <Helmet>
          <title>Account Settings | Money Minx</title>
        </Helmet>
      </HelmetProvider>
      {onboarded ? (
        <AppHeader
          toggleLeftMenu={() => setOpenLeftNav(!openLeftNav)}
          toggleRightMenu={() => setOpenRightNav(!openRightNav)}
          open={openRightNav}
          shadow={true}
        />
      ) : null}
      <AppSidebar openLeft={openLeftNav} openRight={openRightNav} />
      <div className='mm-slider-bg-overlay' onClick={closeRightNav} role='button' />
      <div className='main-table-wrapper'>
        <div>
          <div className='row login-wrapper'>
            <div className='guide-content'>
              {!onboarded ? (
                <Link to='/net-worth'>
                  <ICONS.LOGO className='icon auth-logo' />
                </Link>
              ) : null}
              <div className='auth-left-content'>
                <h1>Three easy steps to get started with Money Minx</h1>
                <ul>
                  <li>Find your accounts.</li>
                  <li>Connect securely to Money Minx.</li>
                  <li>Let Money Minx do the rest.</li>
                </ul>
                <div className='guide-bottom'>
                  <h2>Serious about security</h2>
                  <div className='guide-icon-wrap'>
                    <span className='locked-icon'>
                      <ICONS.LOGIN_LOCK />
                    </span>
                    <p>The security of your information is our top priority</p>
                  </div>
                  <h2>Trusted by investors</h2>
                  <div className='guide-icon-wrap'>
                    <span className='shield-icon'>
                      <ICONS.LOGIN_SHIELD />
                    </span>
                    <p>Investors from all over the world are using Money Minx</p>
                  </div>
                </div>
                <div className='m-b-3 d-flex flex-row'>
                  <a
                    className='m-r-4'
                    href='https://www.trustpilot.com/review/www.moneyminx.com'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <ICONS.TRUST_PILOT />
                  </a>
                  <ICONS.NORTON className='m-r-4' />
                  <img height='38' src={BBBImg} alt='Money Minx Acc Bus' />
                </div>
              </div>
            </div>

            <AccountSettingsSideBar />
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default AccountSettings;
