import FacebookLogin from 'react-facebook-login';
import { Link, useHistory } from 'react-router-dom';
import React, { ChangeEvent, useState } from 'react';

import env from 'app/app.env';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { storage } from 'app/app.storage';
import { StorageKey } from 'app/app.types';
import { ETokenType } from 'auth/auth.enum';
import useToast from 'common/hooks/useToast';
import { useModal } from 'common/components/modal';
import { useAuthDispatch } from 'auth/auth.context';
import useGistEvents from 'common/hooks/useGistEvents';
import { initProfitWell } from 'common/profitwell.helper';
import { appRouteConstants } from 'app/app-route.constant';
import { postFacebookLogin, postLogin } from 'api/request.api';
import { EMAIL_IS_EMPTY, PWD_IS_EMPTY } from 'lang/en/validation.json';
import { setLoginSuccess, updateEmailAddress } from 'auth/auth.actions';
import { ReactComponent as HiddenIcon } from 'assets/icons/pass-hidden.svg';
import { ReactComponent as VisibleIcon } from 'assets/icons/pass-visible.svg';
import { ReactComponent as LoginFacebookIcon } from 'assets/images/login/facebook-icon.svg';
import { associateFacebookUser, getRefreshedAccount, mapLoginSuccessPayload } from 'auth/auth.service';

import EmailNeededModal from '../views/inc/email-needed.modal';
import AssociateEmailModal from '../views/inc/associate-email.modal';

interface ILoginForm {
  refreshLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginForm: React.FC<ILoginForm> = () => {
  const history = useHistory();
  const { mmToast } = useToast();
  const associateModal = useModal();
  const gistEvents = useGistEvents();
  const dispatch = useAuthDispatch();
  const emailNeededModal = useModal();

  const [fbToken, setFBToken] = useState<string>('');
  const [associateMessage, setAssociateMessage] = useState<string>('');
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const visibilityIcon = passwordVisible ? <VisibleIcon /> : <HiddenIcon />;

  const responseFacebook = async (response: any) => {
    if (response.accessToken) {
      setFBToken(response.accessToken);

      const { error, data } = await postFacebookLogin({
        accessToken: response.accessToken,
        mailChimpSubscription: true,
        subscriptionPriceId: env.STRIPE_DEFAULT_PLAN,
      });

      if (!error && data) {
        mmToast('Successfully logged in', { type: 'success' });
        storage.set(StorageKey.AUTH, data);

        if (ETokenType.MFA === data.tokenType) {
          return history.push('/login?action=mfa');
        }

        dispatch(setLoginSuccess(data));
        getRefreshedAccount({ dispatch });

        gistEvents.identify();

        return history.push(appRouteConstants.dashboard.DASHBOARD);
      }

      if (error?.statusCode === 400 && error?.message) {
        emailNeededModal.open();
      }

      if (error?.statusCode === 409 && error?.message) {
        setAssociateMessage(error?.message);
        associateModal.open();
      }
    }
  };

  const handleFacebookAssociation = async () => {
    const { error } = await associateFacebookUser({ dispatch, token: fbToken });

    if (error) {
      mmToast('Association Failed', { type: 'error' });
    } else {
      history.push(appRouteConstants.auth.CONNECT_ACCOUNT);
      mmToast('Association Success', { type: 'success' });
    }

    associateModal.close();
  };

  return (
    <div className='form-wrap login-form-wrapper'>
      <Formik
        validateOnChange={false}
        initialValues={{ email: '', password: '' }}
        onSubmit={async (values, actions) => {
          const isEmailEmpty = isEmpty(values.email);
          const isPasswordEmpty = isEmpty(values.password);
          const hasEmptyFields = isEmailEmpty || isPasswordEmpty;

          if (hasEmptyFields) {
            if (isEmailEmpty) {
              actions.setFieldError('email', EMAIL_IS_EMPTY);
            }

            if (isPasswordEmpty) {
              actions.setFieldError('password', PWD_IS_EMPTY);
            }

            return false;
          }

          const { error, data: loginResponse } = await postLogin(values);
          initProfitWell(values.email);
          actions.setSubmitting(false);

          if (!error && loginResponse) {
            storage.set(StorageKey.AUTH, loginResponse);

            if (ETokenType.MFA === loginResponse.tokenType) {
              return history.push(`/login?action=mfa`);
            }

            dispatch(setLoginSuccess(mapLoginSuccessPayload(loginResponse)));
            getRefreshedAccount({ dispatch });

            const { error: storageError, data: loggedOutURL } = storage.get(StorageKey.URL);

            if (!storageError && loggedOutURL) {
              storage.clear(StorageKey.URL);

              return history.push(loggedOutURL);
            }
          }

          actions.setFieldError(
            'password',
            error?.message && typeof error.message !== 'object'
              ? error.message
              : `We don't recognize that username and password combination. Please try again.`
          );
        }}
      >
        {(props) => {
          const handleEmailBlur = (event: ChangeEvent<HTMLInputElement>) => {
            const email = event.target.value;
            dispatch(updateEmailAddress(email));

            return props.handleBlur(event);
          };

          const updateEmail = (event: ChangeEvent<HTMLInputElement>) => {
            return props.handleChange(event);
          };

          const { errors } = props;

          const hasError = (field: 'email' | 'password') => errors[field];

          const emailClass = hasError('email') ? 'email invalid' : 'email';
          const passClass = hasError('password') ? 'password invalid' : 'password';

          return (
            <form onSubmit={props.handleSubmit}>
              <div className='align-items-start input-wrapper'>
                <div className='email-wrap'>
                  <label htmlFor='email-field' className='form-subheading'>
                    Email address
                  </label>
                  <input
                    id='email-field'
                    type='email'
                    className={emailClass}
                    onChange={updateEmail}
                    onBlur={handleEmailBlur}
                    value={props.values.email}
                    name='email'
                    placeholder='Email'
                  />
                </div>
                {hasError('email') ? <div className='mt-2 feedback'>{props.errors.email}</div> : null}
              </div>
              <div className='align-items-center'>
                <label htmlFor='password-field' className='form-subheading'>
                  Password
                </label>
                <div className='password-wrap'>
                  <input
                    id='password-field'
                    name='password'
                    className={passClass}
                    placeholder='Password'
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    value={props.values.password}
                    type={passwordVisible ? 'text' : 'password'}
                  />
                  <span className='visibility-icon' onClick={() => setPasswordVisible(!passwordVisible)} role='button'>
                    {visibilityIcon}
                  </span>
                </div>
                {hasError('password') ? <div className='mt-2 feedback'>{props.errors.password}</div> : null}
              </div>

              <p>
                <span className='forgot-pass purple-links'>
                  <Link to={appRouteConstants.auth.FORGOT_PASSWORD}>Forgot Password?</Link>
                </span>
              </p>
              <button className='mm-btn-animate mm-btn-primary' type='submit' disabled={props.isSubmitting}>
                Log in
              </button>
            </form>
          );
        }}
      </Formik>

      <div className='facebook-login'>
        <div className='social-login-options'>
          <span id='social-logins'>Or, log in with:</span>
          <div className='fb-icon-wrap'>
            <FacebookLogin
              authType='rerequest'
              aria-label='Login with Facebook'
              aria-labelledby='social-logins'
              textButton=''
              fields='email'
              isMobile={false}
              autoLoad={false}
              reAuthenticate={true}
              callback={responseFacebook}
              scope='public_profile,email'
              icon={<LoginFacebookIcon className='social-login-fb' arial-label='Login with Facebook' />}
              appId={env.FACEBOOK_APP_ID || ''}
              buttonStyle={{
                background: 'transparent',
                padding: 0,
              }}
            />
          </div>
        </div>
      </div>

      <div>
        <div className='auth-end-element'>
          {'Don’t have an account? '}
          <Link to='/signup' className='purple-links'>
            Sign Up
          </Link>
        </div>
      </div>

      <AssociateEmailModal
        source='login'
        message={associateMessage}
        associateModal={associateModal}
        handleSuccess={handleFacebookAssociation}
      />

      <EmailNeededModal emailNeededModal={emailNeededModal} />
    </div>
  );
};

export default LoginForm;
