import { Link } from 'react-router-dom';
import React, { createRef, useCallback, useEffect, useState } from 'react';

import { Account } from 'auth/auth.types';
import { setAccountSuccess } from 'auth/auth.actions';
import useSearchParam from 'auth/hooks/useSearchParam';
import { fetchConnectionInfo } from 'auth/auth.service';
import { getLatestProviderAccounts } from 'api/request.api';
import useAccountSettings from 'auth/hooks/useAccountSettings';
import { ReactComponent as LogoImg } from 'assets/icons/logo.svg';
import { useAuthState, useAuthDispatch } from 'auth/auth.context';
import { ReactComponent as SecurityIcon } from 'assets/images/signup/security.svg';

import AccountSettingForm from './inc/account-setting-form';
import AccountSettingsSidebarSkeleton from './inc/account-settings-sidebar-skeleton';

interface Props {
  setFinish?: () => void;
  closeSidebar?: () => void;
  selectedAccount?: Account;
  handleReload?: () => void;
}

const AccountSettingsSideBar: React.FC<Props> = ({ closeSidebar, selectedAccount, handleReload }) => {
  const dispatch = useAuthDispatch();
  const { accounts } = useAuthState();
  const [reloadCounter, setReloadCounter] = useState(0);

  const {
    setCurrentAccount,
    completed,
    providerName,
    currentAccount,
    setProviderName,
    completedProviderName,
    accountsByProviderName,
    currentProviderAccounts,
  } = useAccountSettings(accounts);

  const from = useSearchParam('from');
  const isFromConnectAccount = 'connectAccount' === from;

  /**
   * @description we will avoid the fetch connection info call
   * @if it is not from the connect account
   * i.e reload counter will be 0
   */
  useEffect(() => {
    if (!isFromConnectAccount && reloadCounter) {
      const getUser = async () => {
        await fetchConnectionInfo({ dispatch });
      };

      getUser();
    }
  }, [dispatch, reloadCounter, isFromConnectAccount]);

  /**
   * @if from fastlink and hit next so that reload counter is increased
   * Call the latest provider accounts
   */
  useEffect(() => {
    (async () => {
      if (isFromConnectAccount && reloadCounter) {
        const { error, data } = await getLatestProviderAccounts();
        if (!error) {
          dispatch(setAccountSuccess(data));
        }
      }
    })();
  }, [dispatch, isFromConnectAccount, reloadCounter]);

  const handlePageReload = () => {
    setReloadCounter((c) => c + 1);

    handleReload?.();
  };

  if (!accounts || !currentAccount || !currentProviderAccounts) {
    return <AccountSettingsSidebarSkeleton />;
  }

  const handleProviderChange = (provider: string) => {
    // setClickEvent(true);
    setProviderName(provider);
  };

  const handleChangeCurrentAccount = (curAccount: Account) => {
    setCurrentAccount(curAccount);
  };

  const getProviderClass = (pName: string) => {
    if (providerName === pName) {
      return ' selected';
    }

    if (completedProviderName.includes(pName)) {
      return ' completed';
    }

    return '';
  };

  return (
    <div className='bg-white credentials-wrapper account-setting'>
      {selectedAccount && (
        <div className='close-icon' onClick={closeSidebar} role='button'>
          ✕
        </div>
      )}

      <div className='credentials-content'>
        <Link to='/dashboard' className='logo-img-wrapper'>
          <LogoImg className='auth-logo' />
        </Link>
        {selectedAccount ? (
          selectedAccount.isManual ? (
            <div className='top-content-wrap'>
              <h2>Manual accounts</h2>
              <p>
                Manual accounts are offline accounts that you manage. Once you add the account, you will ba able to
                manage the value, holdings and transactions for this account.
              </p>
            </div>
          ) : (
            <div className='top-content-wrap'>
              <h2>Account Settings</h2>
              <p>
                Take the time to update as much of the detail below as possible. The more accurate this information is
                the better your Money Mink dashboard will be.
              </p>
            </div>
          )
        ) : (
          <div className='top-content-wrap'>
            <h2>Organize accounts</h2>
            <p>
              Great! You connected your accounts. Now you can organize them to get better insights into your portfolio.
            </p>
          </div>
        )}

        <div className='form-wrap'>
          {!selectedAccount && (
            <>
              <ul className='bank-list'>
                {accountsByProviderName
                  ? Object.keys(accountsByProviderName).map((pName, index) => {
                      const [account] = accountsByProviderName[pName];

                      return (
                        <li
                          key={index}
                          onClick={() => handleProviderChange(pName)}
                          role='button'
                          className={getProviderClass(pName)}
                        >
                          <Link to='#'>
                            {account.providerName ? (
                              account.providerLogo ? (
                                <img src={account.providerLogo} alt={pName} />
                              ) : (
                                pName
                              )
                            ) : (
                              <LogoImg className='auth-logo' />
                            )}
                          </Link>
                        </li>
                      );
                    })
                  : null}
              </ul>

              <div className='form-heading'>
                <AccountNameList
                  completedIds={completed}
                  currentAccount={currentAccount}
                  currentProviderAccounts={currentProviderAccounts}
                  changeCurrentAccount={handleChangeCurrentAccount}
                />
              </div>
            </>
          )}

          <AccountSettingForm
            currentAccount={selectedAccount ? selectedAccount : currentAccount}
            handleReload={handlePageReload}
            isFromAccount={!!selectedAccount}
            closeSidebar={closeSidebar}
          />

          <p className='flex-box learn-more-security'>
            <SecurityIcon />
            <a href='/security' target='_blank' className='purple-links'>
              Learn about our security
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AccountSettingsSideBar;

interface AccountNameListProps {
  currentProviderAccounts: Account[];
  currentAccount: Account;
  completedIds: number[];
  changeCurrentAccount: (curAccount: Account) => void;
}

export const AccountNameList: React.FC<AccountNameListProps> = ({
  completedIds,
  currentAccount,
  changeCurrentAccount,
  currentProviderAccounts,
}) => {
  const refList: any = [];

  currentProviderAccounts.forEach((currentProviderAccount) => {
    refList[currentProviderAccount.id] = createRef();
  });

  const scrollToCategory = useCallback(
    (id: number) => {
      if (refList.length) {
        refList[id]?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refList.length]
  );

  useEffect(() => {
    scrollToCategory(currentAccount.id);
  }, [scrollToCategory, currentAccount.id]);

  const getAccountClass = (accId: number) => (currentAccount?.id === accId ? 'account-btn active' : 'account-btn');
  const completedClass = (accId: number) => (completedIds.includes(accId) ? 'completed' : '');

  return (
    <ul className='nav'>
      {currentProviderAccounts.map((providerAccount, index) => {
        return (
          <li key={index} ref={refList[providerAccount.id]}>
            <button
              className={`${getAccountClass(providerAccount.id)} ${completedClass(providerAccount.id)}`}
              onClick={() => changeCurrentAccount(providerAccount)}
            >
              {providerAccount.accountName}
            </button>
          </li>
        );
      })}
    </ul>
  );
};
