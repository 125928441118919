import React from 'react';

import { events } from '@mm/data/event-list';
import GALink from 'common/components/ga-link';
import { ReactComponent as GettingStartedImage } from 'assets/images/dashboard/fox.svg';
import { ReactComponent as GettingStartedSteps } from 'assets/images/dashboard/getting-started-steps.svg';

interface IGettingStartedProps {
  onAddAccountClick: VoidFunction;
}

const GettingStarted: React.FC<IGettingStartedProps> = ({ onAddAccountClick }) => {
  return (
    <div className='getting-started'>
      <div className='getting-started__img-container'>
        <GettingStartedImage />
      </div>

      <div className='getting-started__content'>
        <h1>Welcome to Money Minx!</h1>
        <p className='getting-started__description'>Add your first account now - it’s super easy.</p>

        <GALink
          to='#'
          eventArgs={{
            ...events.connectFromGettingStarted,
          }}
          onClick={onAddAccountClick}
        >
          <button type='button' className='mm-btn-primary mm-btn-animate btn-add' onClick={onAddAccountClick}>
            Add an account
          </button>
        </GALink>

        <div className='getting-started__steps'>
          <span className='getting-started__steps-title'>
            <span role='img' aria-label='party'>
              🎉
            </span>{' '}
            <span>Getting Started</span>
          </span>

          <div className='d-flex'>
            <GettingStartedSteps />

            <div className='getting-started__steps-actions'>
              <span className='getting-started__step--completed'>Create your Money Minx account</span>
              <span className='getting-started__step--current'>
                Add your first asset
                <GALink
                  to='#'
                  eventArgs={{
                    ...events.connectFromGettingStarted,
                  }}
                  onClick={onAddAccountClick}
                >
                  <button className='btn-outline-primary mm-btn-animate' type='button' onClick={onAddAccountClick}>
                    Add
                  </button>
                </GALink>
              </span>
              <span className='getting-started__step--remaining'>Connect your first account</span>
              <span className='getting-started__step--remaining'>Watch your money grow</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GettingStarted;
