import React from 'react';

import { Account } from 'auth/auth.types';
import { Modal, ModalType } from 'common/components/modal';

interface Props {
  deleteAccountModal: ModalType;
  deleteAccounts: (accounts?: Account[]) => void;
  deleteAccountContent?: IDeleteAccountContent;
}

interface IDeleteAccountContent {
  delBtnText: string;
  cancelBtnText: string;
  content: React.ReactNode;
}

export const defaultDeleteAccountContent: IDeleteAccountContent = {
  content: <p>Do you really want to delete this account? This process cannot be undone.</p>,
  cancelBtnText: 'Cancel',
  delBtnText: 'Delete Account',
};

const DeleteAccountModal: React.FC<Props> = ({
  deleteAccountModal,
  deleteAccounts,
  deleteAccountContent = defaultDeleteAccountContent,
}) => {
  return (
    <Modal {...deleteAccountModal.props} title='Are you sure?' size='sm'>
      <div className='modal-wrapper'>
        {deleteAccountContent.content}
        <div className='modal-btn-wrapper'>
          <button
            className='mm-btn-signup btn-outline-primary mm-btn-animate estimate-annual-block__btn-cancel'
            onClick={() => deleteAccountModal.close()}
          >
            {deleteAccountContent.cancelBtnText}
          </button>
          <button
            className='mm-btn-animate btn-danger'
            onClick={() => {
              deleteAccounts();
              deleteAccountModal.close();
            }}
          >
            {deleteAccountContent.delBtnText}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAccountModal;
