import React, { createContext } from 'react';

import { filterReducer } from './filter.reducer';
import { initialFilterState } from './filter.data';
import { IFilterProvider, IFilterState, TFilterDispatch } from './filter.type';

const FilterStateContext = createContext<IFilterState | undefined>(undefined);
const FilterDispatchContext = createContext<TFilterDispatch | undefined>(undefined);

function FilterProvider({ children }: IFilterProvider) {
  const [state, dispatch] = React.useReducer(filterReducer, initialFilterState);

  return (
    <FilterStateContext.Provider value={state}>
      <FilterDispatchContext.Provider value={dispatch as any}>{children}</FilterDispatchContext.Provider>
    </FilterStateContext.Provider>
  );
}

function useFilterState() {
  const context = React.useContext(FilterStateContext);

  if (context === undefined) {
    throw new Error('useAppState must be used within a Filter provider');
  }

  return context;
}

function useFilterDispatch() {
  const context = React.useContext(FilterDispatchContext);

  if (context === undefined) {
    throw new Error('Must be used within a Filter provider');
  }

  return context;
}

export { FilterProvider, useFilterDispatch, useFilterState };
