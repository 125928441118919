import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import classNames from 'common/classes.helper';

import ICONS from './icons';

export interface Size {
  xl: string;
  lg: string;
  mdx: string;
  md: string;
  sm: string;
  xs: string;
  xxl: string;
  fastlink: string;
}

export enum ModalTypeEnum {
  NO_HEADER = 'NO_HEADER',
}

interface Props {
  title: string;
  open: boolean;
  bgColor?: string;
  size?: keyof Size;
  loading?: boolean;
  backdrop?: boolean;
  onClose: () => void;
  onBack?: () => void;
  onError?: () => void;
  type?: ModalTypeEnum;
  canBeClosed?: boolean;
  onSuccess?: () => void;
  hasBackOption?: boolean;
  outsideClickClose?: boolean;
}

export interface ModalType {
  open: () => void;
  close: () => void;
  props: {
    open: boolean;
    onClose: () => void;
  };
}

const Modal: React.FC<Props> = ({
  open,
  type,
  title,
  loading,
  bgColor,
  children,
  size = 'md',
  backdrop = true,
  onBack = () => {},
  onClose = () => {},
  canBeClosed = false,
  hasBackOption = false,
  outsideClickClose = false,
}) => {
  const modalBackdropClasses = `${backdrop ? 'modal mm-modal-backdrop' : 'modal'} modal-${open ? 'show' : 'hide'}`;
  const modalClasses = `modal-dialog modal-dialog-centered modal-${size}`;
  const clickOutsideModalHandler = (event: any) => {
    if (!outsideClickClose && event?.target?.className === 'modal mm-modal-backdrop modal-show') {
      onClose();
    }
  };

  return (
    <div
      tabIndex={-1}
      role='dialog'
      aria-hidden='true'
      className={modalBackdropClasses}
      onClick={clickOutsideModalHandler}
    >
      <div className={modalClasses} role='document'>
        <div className={`modal-content ${loading ? 'loading' : ''}`.trim()} style={{ backgroundColor: bgColor }}>
          {type === ModalTypeEnum.NO_HEADER ? null : (
            <div className={classNames('modal-header', hasBackOption && 'has-back')}>
              <BackOption hasBackOption={hasBackOption} onBack={onBack} />
              <span className='modal-title' style={{ color: bgColor ? 'white' : 'inherit' }}>
                {title}
              </span>

              {canBeClosed && (
                <div onClick={onClose} className='close' role='button'>
                  <AiOutlineClose className='modal-close' style={{ color: bgColor ? 'white' : 'inherit' }} />
                </div>
              )}
            </div>
          )}
          <div className='modal-body'>{children}</div>
        </div>
      </div>
    </div>
  );
};

function useModal(state: boolean = false): ModalType {
  const [open, setOpen] = useState(state);
  return {
    open: () => setOpen(true),
    close: () => setOpen(false),
    props: {
      open,
      onClose: () => setOpen(false),
    },
  };
}

export { Modal, useModal };

interface IBackOption {
  onBack?: () => void;
  hasBackOption: boolean;
}

export const BackOption = (props: IBackOption) => {
  if (props.hasBackOption && props.onBack) {
    return (
      <div className='back' onClick={props.onBack} role='button'>
        <ICONS.ARROW_BACK />
      </div>
    );
  }

  return null;
};
