import React from 'react';

import { Account } from 'auth/auth.types';
import { getRelativeDate } from 'common/moment.helper';
import { EConnectionStatus } from 'common/common.types';
import { isSaltEdgeAccount } from 'account/account.helper';
import CircularSpinner from 'common/components/spinner/circular-spinner';

export interface PopupProps {
  AccountDetails?: Account;
  handleConnectAccount: () => void;
  connectionStatus: string;
}

const Popup: React.FC<PopupProps> = ({ AccountDetails, handleConnectAccount, connectionStatus }) => {
  if (!AccountDetails) {
    return <CircularSpinner />;
  }

  const renderWaitState = () => {
    if (isSaltEdgeAccount(AccountDetails.mmAccountProvider)) {
      return <span className='pt-2 pb-3'>Last refresh too recent. Try again in 20 mins.</span>;
    }

    return (
      <span className='pt-2 pb-3'>
        Account refresh in progress. Refresh your screen to see the latest data. This can take several minutes..
      </span>
    );
  };

  return (
    <div className='popup'>
      <span className='pb-2'>Connection Status</span>
      <span className='pb-2'>
        {AccountDetails.providerAccount?.dataset?.[0]?.lastUpdated?.toString() !== null
          ? 'Last updated ' + getRelativeDate(AccountDetails.providerAccount?.dataset?.[0]?.lastUpdated?.toString())
          : 'Not yet updated'}
      </span>
      {connectionStatus === EConnectionStatus.WAIT ? (
        renderWaitState()
      ) : (
        <span className='pt-2 pb-3'>
          {connectionStatus === EConnectionStatus.STALE
            ? 'Refresh your account now if you prefer not to wait until the next sync.'
            : 'Reauthorize your connection to continue syncing your account.'}
        </span>
      )}
      {connectionStatus !== EConnectionStatus.WAIT ? (
        <button type='button' className='mm-btn-animate mm-btn-primary' onClick={handleConnectAccount}>
          {connectionStatus === 'STALE' ? 'Refresh Now' : 'Fix Connection'}
        </button>
      ) : null}
    </div>
  );
};

export default Popup;
