import React, { useState } from 'react';

import HoldingsTable from './holdings-table';
import AccountsTable from './accounts-table';
import { ETableType } from '../account-types.enum';
import { Account, IAccountDataForInterval } from '../account-types.types';

interface IAccountsTableSectionProps {
  mmAccountType: string;
  holdings: any[];
  accounts: Account[];
  currencySymbol: string;
  balanceTotal: IAccountDataForInterval[];
}

const AccountsTableSection: React.FC<IAccountsTableSectionProps> = ({
  holdings,
  accounts,
  mmAccountType,
  currencySymbol,
  balanceTotal,
}) => {
  const [tableType, setTableType] = useState<ETableType>(ETableType.HOLDINGS);

  const getHoldingLabel = () => {
    const mapper: Record<string, string> = {
      Securities: 'Securities',
      Businesses: 'Businesses',
      Cryptocurrencies: 'Coins',
      Collectibles: 'Collectibles',
      NFTs: 'NFTs',
      'Stock Options': 'Grants',
      Startups: 'Startups',
    };

    const mapValue = mapper[mmAccountType || ''];
    return mapValue ? mapValue : 'Holdings';
  };

  const renderTableToggles = () => {
    return (
      <div className='d-flex justify-content-between flex-wrap mm-account account-types__table-toggles'>
        <div className='mm-plan-radios mb-4'>
          <input
            type='radio'
            id='mm-account-holding'
            value='holdings'
            name='mm-radio-holding-activity'
            aria-checked={tableType === ETableType.HOLDINGS}
            checked={tableType === ETableType.HOLDINGS}
            onChange={() => setTableType(ETableType.HOLDINGS)}
          />
          <label className='labels' htmlFor='mm-account-holding'>
            {getHoldingLabel()}
          </label>

          <input
            type='radio'
            id='mm-account-activity'
            value='activity'
            name='mm-radio-holding-activity'
            aria-checked={tableType === ETableType.ACCOUNTS}
            checked={tableType === ETableType.ACCOUNTS}
            onChange={() => setTableType(ETableType.ACCOUNTS)}
            className={'second'}
          />
          <label className='labels' htmlFor='mm-account-activity'>
            Accounts
          </label>
          <div className='mm-radio-bg' />
        </div>
      </div>
    );
  };

  const renderTables = () => {
    if (holdings && tableType === ETableType.HOLDINGS) {
      return <HoldingsTable currencySymbol={currencySymbol} holdings={holdings} mmAccountType={mmAccountType} />;
    }

    if (accounts && tableType === ETableType.ACCOUNTS) {
      return (
        <AccountsTable
          currencySymbol={currencySymbol}
          accounts={accounts}
          mmAccountType={mmAccountType}
          total={balanceTotal}
        />
      );
    }

    return null;
  };

  return (
    <>
      {renderTableToggles()}
      {renderTables()}
    </>
  );
};

export default AccountsTableSection;
