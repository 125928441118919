import { useCallback, useEffect } from 'react';

const useInterval = <D extends () => void>(cb: D, depList: any[], interval = 5000): void => {
  const callback = useCallback(cb, [cb, ...depList]);

  useEffect(() => {
    const timeout = setInterval(() => {
      callback();
    }, interval);

    return () => clearInterval(timeout);
  }, [interval, callback]);
};

export default useInterval;
