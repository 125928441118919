// FIXME: with MMCategories enum

export enum AccountCategory {
  LIABILITIES = 'Liabilities',
  OTHER_ASSETS = 'Other Assets',
  INVESTMENT_ASSETS = 'Investment Assets',
}

export enum TimeIntervalEnum {
  YEARLY = 'Yearly',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
}

export enum NetworthActionEnum {
  SET_F_TO_DATE = 'SET_FILTER_TO_DATE',
  SET_F_ACCOUNT = 'SET_FILTER_ACCOUNT',
  SET_F_CATEGORY = 'SET_FILTER_CATEGORY',
  SET_F_FROM_DATE = 'SET_FILTER_FROM_DATE',
  SET_F_ACCOUNT_TYPE = 'SET_FILTER_ACCOUNT_TYPE',
  SET_F_TIME_INTERVAL = 'SET_FILTER_TIME_INTERVAL',
  SET_F_ENTITY = 'SET_FILTER_ENTITY',
  SET_F_LIQUIDITY = 'SET_FILTER_LIQUIDITY',

  SET_NETWORTH = 'SET_NETWORTH',
  SET_ACCOUNTS = 'SET_ACCOUNTS',

  SET_F_TOGGLE_NET = 'SET_F_TOGGLE_NET',
  SET_F_TOGGLE_OTHER = 'SET_F_TOGGLE_OTHER',
  SET_F_TOGGLE_INVESTMENT = 'SET_F_TOGGLE_INVESTMENT',
  SET_F_TOGGLE_LIABILITIES = 'SET_F_TOGGLE_LIABILITIES',

  SET_TABLE_COLLAPSE = 'SET_TABLE_COLLAPSE',

  SET_ACCOUNT_COUNT = 'SET_ACCOUNT_COUNT',
  TOGGLE_GROUP_BY_CATEGORY = 'TOGGLE_GROUP_BY_CATEGORY',

  SET_PROCESSING = 'SET_PROCESSING',
  RESET_PROCESSING = 'RESET_PROCESSING',

  F_BULK_UPDATE = 'F_BULK_UPDATE',

  SET_FILTERS = 'SET_FILTERS',
  ADD_FILTER = 'ADD_FILTER',
  UPDATE_FILTER = 'UPDATE_FILTER',
  REMOVE_FILTER = 'REMOVE_FILTER',

  CLEAR_FILTER = 'CLEAR_FILTER',
}

export enum LiquidityEnum {
  LESS_ONE = 'Less than 1 year',
  BETWEEM_TWO_AND_FIVE = 'Between 2 and 5 years',
  BETWEEM_FIVE_AND_TEN = 'Between 5 and 10 years',
  OVER_TEN = 'Over 10 years',
}
