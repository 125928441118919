import React from 'react';

import SVGImages, { PNGImages } from 'common/components/images';

const SecurityImages = () => {
  return (
    <div className='security-images-wrapper'>
      <SVGImages.SSLSecure className='m-r-4' />
      <a
        className='m-r-4'
        href='https://www.trustpilot.com/review/www.moneyminx.com'
        target='_blank'
        rel='noopener noreferrer'
      >
        <SVGImages.TrustPilot />
      </a>
      <SVGImages.Norton className='m-r-4' />
      <img height='38' src={PNGImages.BBBImg} alt='Money Minx Acc Bus' />
    </div>
  );
};

export default SecurityImages;
