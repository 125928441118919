import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { base64Encode } from 'common/helper/string.helper';
import { getCurrencySymbol } from 'common/currency-helper';
import { fNumber, numberWithCommas } from 'common/number.helper';
import usePortfolioMovers from 'dashboard/hooks/usePortfolioMovers';
import { ReactComponent as MinxCompare } from 'assets/images/dashboard/minx-compare.svg';
import { IPortfolioMoversAccount, IPortfolioMoversHolding } from 'dashboard/dashboard.types';

import IntervalSelector from './interval-selector';
import PortfolioMoverItem from './portfolio-mover-item';
import { EIntervalOption, EPortfolioMoverType } from '../dashboard.enum';

const PortfolioMovers: React.FC = () => {
  const history = useHistory();

  const [currentInterval, setCurrentInterval] = useState<EIntervalOption>(EIntervalOption['1D']);
  const [currentMoverType, setCurrentMoverType] = useState<EPortfolioMoverType>(EPortfolioMoverType.ACCOUNTS);

  const { loading, portfolioMoversAccs, portfolioMoversHoldings } = usePortfolioMovers(
    currentInterval,
    currentMoverType
  );

  const navigateToAccountDetail = (accId: number) => {
    const encodedAccountId = base64Encode(accId.toString());
    history.push('/account-details/' + encodedAccountId);
  };

  const renderAccountsPortfolioMoverItems = (accs: IPortfolioMoversAccount[]) => {
    return accs.map((acc) => {
      const { id, name, providerLogo, currency, balance, balanceDifference, balanceDifferencePer } = acc;

      const currencySymbol = getCurrencySymbol(currency);
      const symbol = balanceDifference < 0 ? '-' : '+';

      const diffPer = Math.round((balanceDifferencePer || 0) * 100) / 100;

      const description = `${symbol}${currencySymbol}${numberWithCommas(
        fNumber(Math.abs(balanceDifference), 0)
      )} (${symbol}${Math.abs(diffPer)}%)`;

      return (
        <PortfolioMoverItem
          title={name}
          description={description}
          balance={balance}
          currencySymbol={currencySymbol}
          key={id}
          imgSrc={providerLogo}
          onClick={() => navigateToAccountDetail(id)}
        />
      );
    });
  };

  const renderHoldingsPortfolioMoverItems = (accs: IPortfolioMoversHolding[]) => {
    return accs.map((acc, idx) => {
      const { description, balance, currency, difference, differencePer, accountId } = acc;

      const currencySymbol = getCurrencySymbol(currency);
      const symbol = difference < 0 ? '-' : '+';

      const diffPer = Math.round((differencePer || 0) * 100) / 100;

      const itemDescription = `${symbol}${currencySymbol}${numberWithCommas(
        fNumber(Math.abs(difference), 0)
      )} (${diffPer}%)`;

      return (
        <PortfolioMoverItem
          title={description}
          description={itemDescription}
          balance={balance}
          currencySymbol={currencySymbol}
          key={idx}
          onClick={() => navigateToAccountDetail(accountId)}
        />
      );
    });
  };

  const renderAccounts = () => {
    if (currentMoverType !== EPortfolioMoverType.ACCOUNTS) {
      return null;
    }

    const noData = !loading && (!portfolioMoversAccs?.gainers.length && !portfolioMoversAccs?.losers.length);

    if (noData) {
      return (
        <div className='portfolio-movers__content'>
          <span className='no-data'>No results for this date range, try a different date.</span>
        </div>
      );
    }

    return (
      <div className='portfolio-movers__content'>
        <div className='portfolio-movers__content-gainers'>
          <h3>Top Gainers</h3>
          {!loading && !!portfolioMoversAccs?.gainers?.length && renderAccountsPortfolioMoverItems(portfolioMoversAccs?.gainers || [])}

          {!loading && !portfolioMoversAccs?.gainers?.length &&
          <span className='no-data'>There are no gainers for this period.</span>
          }

          {loading && <Skeleton height={320} />}
        </div>

        <div className='portfolio-movers__content-losers'>
          <h3>Top Losers</h3>
          {!loading && !!portfolioMoversAccs?.losers?.length && renderAccountsPortfolioMoverItems(portfolioMoversAccs?.losers || [])}

          {!loading && !portfolioMoversAccs?.losers?.length &&
          <span className='no-data'>There are no losers for this period.</span>
          }

          {loading && <Skeleton height={320}/>}
        </div>
      </div>
    );
  };

  const renderHoldings = () => {
    if (currentMoverType !== EPortfolioMoverType.HOLDINGS) {
      return null;
    }

    const noData = !loading && (!portfolioMoversHoldings?.gainers.length && !portfolioMoversHoldings?.losers.length);

    if (noData) {
      return (
        <div className='portfolio-movers__content'>
          <span className='no-data'>No results for this date range, try a different date.</span>
        </div>
      );
    }

    return (
      <div className='portfolio-movers__content'>
        <div className='portfolio-movers__content-gainers'>
          <h3>Top Gainers</h3>
          {!loading && !!portfolioMoversHoldings?.gainers?.length &&
          renderHoldingsPortfolioMoverItems(portfolioMoversHoldings?.gainers || [])
          }

          {!loading && !portfolioMoversHoldings?.gainers?.length &&
          <span className='no-data'>There are no gainers for this period.</span>
          }

          {loading && <Skeleton height={320}/>}
        </div>

        <div className='portfolio-movers__content-losers'>
          <h3>Top Losers</h3>
          {!loading && !!portfolioMoversHoldings?.losers?.length && (
            renderHoldingsPortfolioMoverItems(portfolioMoversHoldings?.losers || [])
          )}

          {!loading && !portfolioMoversHoldings?.losers?.length && (
            <span className='no-data'>There are no losers for this period.</span>
          )}

          {loading && <Skeleton height={320}/>}
        </div>
      </div>
    );
  };

  return (
    <div className='portfolio-movers'>
      <div className='portfolio-movers__header p-t-2'>
        <div className='portfolio-movers__header-title'>
          <MinxCompare />
          <h2>Portfolio Movers</h2>
        </div>

        <IntervalSelector defaultInterval={currentInterval} onChange={setCurrentInterval} />
      </div>

      <div className='portfolio-movers__buttons'>
        <div className='mm-plan-radios'>
          <>
            <input
              type='radio'
              id='mm-mover-account'
              value='accounts'
              name='mm-radio-mover-type'
              aria-checked={currentMoverType === EPortfolioMoverType.ACCOUNTS}
              checked={currentMoverType === EPortfolioMoverType.ACCOUNTS}
              onChange={() => setCurrentMoverType(EPortfolioMoverType.ACCOUNTS)}
            />
            <label className='labels' htmlFor='mm-mover-account'>
              Accounts
            </label>
          </>

          <>
            <input
              type='radio'
              id='mm-mover-holdings'
              value='holdings'
              name='mm-radio-mover-type'
              aria-checked={currentMoverType === EPortfolioMoverType.HOLDINGS}
              checked={currentMoverType === EPortfolioMoverType.HOLDINGS}
              onChange={() => setCurrentMoverType(EPortfolioMoverType.HOLDINGS)}
            />
            <label className='labels' htmlFor='mm-mover-holdings'>
              Holdings
            </label>
          </>
          <div className='mm-radio-bg' />
        </div>
      </div>

      {renderAccounts()}
      {renderHoldings()}
    </div>
  );
};

export default PortfolioMovers;
