import React from 'react';
import Table from 'react-bootstrap/Table';

import { gc } from 'common/interval-parser';
import { setToggleNet } from 'networth/networth.actions';
import { Placeholder } from 'networth/views/inc/placeholder';
import { fNumber, numberWithCommas } from 'common/number.helper';
import { useNetworthDispatch, useNetworthState } from 'networth/networth.context';

interface INetworthSection {
  currencySymbol: string;
}

const NetworthSection: React.FC<INetworthSection> = ({ currencySymbol }) => {
  const dispatch = useNetworthDispatch();
  const { fCategories, networth, fToggleNet } = useNetworthState();

  const toggleNet = () => {
    dispatch(setToggleNet(!fToggleNet));
  };

  return fCategories.length === 0 || fCategories.length === 3 ? (
    <div className='row mb-40'>
      <div className='col-12'>
        <div className='ct-box box-v'>
          {networth ? (
            <div className='table-holder'>
              <Table className='tb-responsive' id='table-net-xls'>
                <thead onClick={toggleNet}>
                  <tr>
                    <th>
                      <span className={!fToggleNet ? 't-span text--primary' : 'text--primary'}>Net Worth</span>
                    </th>
                    <th className='tab-hide'>{''}</th>
                    {networth.map((nItem, idx) => (
                      <th key={idx} className={gc(nItem.interval)}>
                        {nItem.interval}
                      </th>
                    ))}
                  </tr>
                </thead>
                {fToggleNet ? (
                  <tbody className={'projection networth'}>
                    <tr data-href='#'>
                      <td>Investment Assets</td>
                      <td className='tab-hide'>{''}</td>
                      {networth.map((nItem, idx) => (
                        <td
                          key={idx}
                          className={[nItem.type === `projection` && `projection`, gc(nItem.interval)].join(' ')}
                        >
                          <span className={gc(nItem.interval)}>{nItem.interval}</span>
                          {nItem.investmentAssets || nItem.investmentAssets === 0 ? currencySymbol : ''}
                          {nItem.investmentAssets || nItem.investmentAssets === 0
                            ? numberWithCommas(fNumber(nItem.investmentAssets, 2))
                            : '--'}
                        </td>
                      ))}
                    </tr>
                    <tr data-href='#'>
                      <td>Other Assets</td>
                      <td className='tab-hide'>{''}</td>

                      {networth.map((nItem, idx) => (
                        <td
                          key={idx}
                          className={[nItem.type === `projection` && `projection`, gc(nItem.interval)].join(' ')}
                        >
                          <span className={gc(nItem.interval)}>{nItem.interval}</span>
                          {nItem.otherAssets || nItem.otherAssets === 0 ? currencySymbol : ''}
                          {nItem.otherAssets || nItem.otherAssets === 0
                            ? numberWithCommas(fNumber(nItem.otherAssets, 2))
                            : '--'}
                        </td>
                      ))}
                    </tr>
                    <tr data-href='#'>
                      <td>Liabilities</td>
                      <td className='tab-hide'>{''}</td>

                      {networth.map((nItem, idx) => (
                        <td
                          key={idx}
                          className={[nItem.type === `projection` && `projection`, gc(nItem.interval)].join(' ')}
                        >
                          <span className={gc(nItem.interval)}>{nItem.interval}</span>
                          {nItem.liabilities || nItem.liabilities === 0 ? currencySymbol : ''}
                          {nItem.liabilities || nItem.liabilities === 0
                            ? numberWithCommas(fNumber(nItem.liabilities, 2))
                            : '--'}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                ) : null}
                <tfoot className='projection'>
                  <tr>
                    <td className='text--primary'>Net Worth</td>
                    <td className='tab-hide'>{''}</td>
                    {networth.map((nItem, idx) => (
                      <td
                        key={idx}
                        className={[nItem.type === `projection` && `projection`, gc(nItem.interval)].join(' ')}
                      >
                        <span className={gc(nItem.interval)}>{nItem.interval}</span>
                        {nItem.networth || nItem.networth === 0 ? currencySymbol : ''}
                        {nItem.networth || nItem.networth === 0 ? numberWithCommas(fNumber(nItem.networth, 2)) : '--'}
                      </td>
                    ))}
                  </tr>
                </tfoot>
              </Table>
            </div>
          ) : (
            <Placeholder type='networth' />
          )}
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default NetworthSection;
