import { useState, useMemo } from 'react';

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortConfig<T> {
  key: keyof T;
  isDate: boolean;
  direction: SortDirection;
  isDetails?: boolean;
  isBalance?: boolean;
  isIncome?: boolean;
}

export type SortConfigType<T> = SortConfig<T> | null;

export interface IRequestSort<T> {
  key: keyof T;
  isDate?: boolean;
  ignore?: boolean;
  isDetails?: boolean;
  isBalance?: boolean;
  isIncome?: boolean;
  direction?: SortDirection;
}

export const defaultCurrentSortConfig: SortConfigType<any> = {
  key: 'Current' as any,
  isDate: false,
  isBalance: true,
  direction: SortDirection.DESC,
};

export const defaultDetailSortConfig: SortConfigType<any> = {
  key: 'Current' as any,
  isDate: false,
  isDetails: true,
  direction: SortDirection.DESC,
};

const useSortableData = <T,>(items: T[], initialConfig?: SortConfigType<T>) => {
  const [sortConfig, setSortConfig] = useState<SortConfigType<T>>(initialConfig || null);

  const sortedItems = useMemo(() => {
    const sortableItems = [...items];

    if (sortConfig && sortConfig.isBalance) {
      return sortableItems.sort((a: any, b: any) => {
        const firstItem = a?.balances?.find((bal: any) => bal.interval === sortConfig.key)?.balance || 0;
        const secondItem = b?.balances?.find((bal: any) => bal.interval === sortConfig.key)?.balance || 0;

        if (firstItem < secondItem) {
          return sortConfig.direction === SortDirection.ASC ? -1 : 1;
        }

        if (firstItem > secondItem) {
          return sortConfig.direction === SortDirection.ASC ? 1 : -1;
        }

        return 0;
      });
    }

    if (sortConfig && sortConfig.isIncome) {
      return sortableItems.sort((a: any, b: any) => {
        const firstItem = a?.incomes.find((income: any) => income.interval === sortConfig.key)?.income || 0;
        const secondItem = b?.incomes.find((income: any) => income.interval === sortConfig.key)?.income || 0;

        if (firstItem < secondItem) {
          return sortConfig.direction === SortDirection.ASC ? -1 : 1;
        }

        if (firstItem > secondItem) {
          return sortConfig.direction === SortDirection.ASC ? 1 : -1;
        }

        return 0;
      });
    }

    if (sortConfig?.isDetails) {
      return sortableItems.sort((a: any, b: any) => {
        const firstItem =
          a?.intervalValues.find((intervalValue: any) => intervalValue.interval === sortConfig.key)?.value || 0;
        const secondItem =
          b?.intervalValues.find((intervalValue: any) => intervalValue.interval === sortConfig.key)?.value || 0;

        if (firstItem < secondItem) {
          return sortConfig.direction === SortDirection.ASC ? -1 : 1;
        }

        if (firstItem > secondItem) {
          return sortConfig.direction === SortDirection.ASC ? 1 : -1;
        }

        return 0;
      });
    }

    if (sortConfig !== null) {
      return sortableItems.sort((a, b) => {
        let firstItem = a[sortConfig.key];
        let secondItem = b[sortConfig.key];

        if (sortConfig.isDate) {
          firstItem = new Date(a[sortConfig.key] as any) as any as T[keyof T];
          secondItem = new Date(b[sortConfig.key] as any) as any as T[keyof T];
        }

        if (firstItem < secondItem) {
          return sortConfig.direction === SortDirection.ASC ? -1 : 1;
        }

        if (firstItem > secondItem) {
          return sortConfig.direction === SortDirection.ASC ? 1 : -1;
        }

        return 0;
      });
    }

    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = ({
    key,
    isDate = false,
    ignore = false,
    isDetails = false,
    isBalance = false,
    isIncome = false,
    direction = SortDirection.ASC,
  }: IRequestSort<T>) => {
    if (sortConfig && sortConfig.key === key && sortConfig.direction === SortDirection.ASC) {
      direction = SortDirection.DESC;
    }

    if (!ignore) {
      setSortConfig({ key, direction, isDate, isDetails, isBalance, isIncome });
    }
  };

  return { items: sortedItems, requestSort, sortConfig };
};

export default useSortableData;
