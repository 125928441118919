import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Signup } from 'auth';
import AuthRoute from 'auth/auth.route';
import WebsiteRoute from 'website/website.route';
import QrokaCoinbase from 'qroka/qroka-coinbase';
import {
  Home,
  About,
  Media,
  Notice,
  Privacy,
  Pricing,
  Security,
  Features,
  TermNService,
  FeaturesCryptos,
  FeaturesNetWorth,
  FeaturesAllocations,
  FeaturesSyncedManual,
  FeaturesMulticurrency,
  LandingNetWorthCalculator,
  FeaturesTransactionHistory,
} from 'website/views';
import { ForgotPassword } from 'auth/views';
import QrokaGemini from 'qroka/qroka-gemini';
import { Discover, DiscoverDetail } from 'discover';
import useReferralCookie from 'common/hooks/useReferralCookie';

import AppRoute from './app.route';
import PrivateRoute from './app.private-route';
import { YodleeIntegration } from './app.pages';
import { ResetPassword, TokenExpired } from './app.view';
import { appRouteConstants } from './app-route.constant';

const {
  home,
  yodlee,
  qroka: { QROKA_COINBASE, QROKA_GEMINI },
  mmLink: { MM_LINK_COINBASE, MM_LINK_GEMINI },
  web: { SECURITY, ABOUT, MEDIA, TERMS, NOTICES, PRIVACY, PRICING },
  features: {
    FEATURES,
    FEATURES_CRYPTOS,
    FEATURES_NET_WORTH,
    FEATURES_ALLOCATIONS,
    FEATURES_SYNCED_MANUAL,
    FEATURES_MULTICURRENCY,
    FEATURES_TRANSACTION_HISTORY,
  },
  landing: { LANDING_NET_WORTH_CALCULATOR },
  auth: { DEF, SIGNUP, FORGOT_PASSWORD, TOKEN_EXPIRED, RESET_PASSWORD },
  discover: { DISCOVER, DISCOVER_DETAIL },
} = appRouteConstants;

function MainRoute() {
  useReferralCookie();

  return (
    <>
      <Switch>
        <Route exact path={home}>
          <Home />
        </Route>

        <Route path='/w'>
          <WebsiteRoute />
        </Route>

        <Route path={DEF}>
          <AuthRoute />
        </Route>

        <Route exact path={ABOUT} component={About} />
        <Route exact path={MEDIA} component={Media} />
        <Route exact path={SIGNUP} component={Signup} />
        <Route exact path={NOTICES} component={Notice} />
        <Route exact path={PRIVACY} component={Privacy} />
        <Route exact path={PRICING} component={Pricing} />
        <Route exact path={TERMS} component={TermNService} />
        <Route exact path={SECURITY} component={Security} />
        <Route exact path={FORGOT_PASSWORD} component={ForgotPassword} />
        <Route exact path={TOKEN_EXPIRED} component={TokenExpired} />
        <Route exact path={RESET_PASSWORD} component={ResetPassword} />
        <Route exact path={FEATURES} component={Features} />
        <Route exact path={FEATURES_CRYPTOS} component={FeaturesCryptos} />
        <Route exact path={FEATURES_NET_WORTH} component={FeaturesNetWorth} />
        <Route exact path={FEATURES_ALLOCATIONS} component={FeaturesAllocations} />
        <Route exact path={FEATURES_SYNCED_MANUAL} component={FeaturesSyncedManual} />
        <Route exact path={FEATURES_MULTICURRENCY} component={FeaturesMulticurrency} />
        <Route exact path={FEATURES_TRANSACTION_HISTORY} component={FeaturesTransactionHistory} />
        <Route exact path={LANDING_NET_WORTH_CALCULATOR} component={LandingNetWorthCalculator} />
        <Route exact path={DISCOVER} component={Discover} />
        <Route exact path={DISCOVER_DETAIL} component={DiscoverDetail} />

        <PrivateRoute exact path={QROKA_COINBASE} component={QrokaCoinbase} />
        <PrivateRoute exact path={QROKA_GEMINI} component={QrokaGemini} />

        <PrivateRoute exact path={MM_LINK_COINBASE} component={QrokaCoinbase} />
        <PrivateRoute exact path={MM_LINK_GEMINI} component={QrokaGemini} />
        <PrivateRoute exact path={yodlee.INTEGRATION} component={YodleeIntegration} />

        <Route path='/'>
          <AppRoute />
        </Route>
      </Switch>
    </>
  );
}

export default MainRoute;
