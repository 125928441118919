import { Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { appRouteConstants } from 'app/app-route.constant';
import { useSaltEdgeLocation } from 'account/hooks/useSaltEdge';

const ConnectAccountRedirect: React.FC = () => {
  const [redirectParam, setRedirectParam] = useState<string | null>(null);
  const [fromSaltEdge, setFromSaltEdge] = useState<boolean>(false);

  const { search } = useLocation();
  const { getLocation, isFromSaltEdge, addQuery } = useSaltEdgeLocation();
  const saltEdgeLocation = getLocation();

  const _fromSaltedge = isFromSaltEdge();
  useEffect(() => {
    const queryParam = search ? `${search}&origin=connectAccount` : '?origin=connectAccount';

    if (_fromSaltedge) {
      return setFromSaltEdge(true);
    }

    setRedirectParam(queryParam);
  }, [search, _fromSaltedge]);

  if (fromSaltEdge) {
    if (saltEdgeLocation) {
      const saltedgeSearch = saltEdgeLocation.search;

      const newLocation = {
        ...saltEdgeLocation,
        search: saltedgeSearch ? addQuery(saltedgeSearch, search) : search,
      };

      return <Redirect to={newLocation} />;
    }

    const queryParam = search ? `${search}&origin=connectAccount` : '?origin=connectAccount';

    return <Redirect to={`${appRouteConstants.dashboard.DASHBOARD}${queryParam}`} />;
  }

  if (!redirectParam) {
    return null;
  }

  return <Redirect to={`${appRouteConstants.dashboard.DASHBOARD}${redirectParam}`} />;
};

export default ConnectAccountRedirect;

// TODO: Show Modal on each page according to action performed
// clear the saltedge URL from local storage on modal open.
