import React from 'react';

import ICONS from 'common/components/icons';
import { SortConfigType, SortDirection } from 'common/hooks/useSortableData';

interface ISortIcon<T> {
  sortKey: string;
  sortConfig: SortConfigType<T>;
}

const SortIcon = <T,>({ sortConfig, sortKey }: ISortIcon<T>) => {
  const getIcon = () => {
    if (!sortConfig?.direction) {
      return null;
    }

    if (SortDirection.DESC === sortConfig.direction) {
      return <ICONS.ARROW_DOWN />;
    }

    if (SortDirection.ASC === sortConfig.direction) {
      return <ICONS.ARROW_DOWN className='rotate-180' />;
    }
  };

  if (sortKey !== sortConfig?.key) {
    return null;
  }

  return <div className='sort-icon-container'>{getIcon()}</div>;
};

export default SortIcon;
