// tslint:disable: react-a11y-input-elements

import ReactDatePicker from 'react-datepicker';
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useEffect, useState } from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';

import {
  clearFilter,
  setFilterToDate,
  setFilterAccount,
  setFilterFromDate,
  setFilterLiquidity,
  setFilterCategories,
  setFilterAccountType,
  setFilterTimeInterval,
  setFilterInvestingEntity,
} from 'filter/filter.action';
import { Account } from 'auth/auth.types';
import { useModal } from 'common/components/modal';
import { MoreIcon } from 'common/components/icons';
import useFilters from 'networth/hooks/useFilters';
import useSettings from 'setting/hooks/useSettings';
import { IInvestingEntity } from 'setting/setting.type';
import { getCurrencySymbol } from 'common/currency-helper';
import { makeFilterPayload } from 'networth/networth.helper';
import { numberWithCommas, fNumber } from 'common/number.helper';
import { getAccount, getInvestingEntities } from 'api/request.api';
import SaveEditViewModal from 'networth/components/save-edit-view.modal';
import { useFilterDispatch, useFilterState } from 'filter/filter.context';
import { arrGroupBy, enumerateStr, serialize } from 'common/common-helper';
import { initialState, useNetworthState } from 'networth/networth.context';
import NetworthFilterSkeleton from 'networth/components/networth-filter-skeleton';
import { AccountCategory, TimeIntervalEnum, LiquidityEnum } from 'networth/networth.enum';
import { IFilter, NetworthFilterProps, NetworthState, TFilterKey } from 'networth/networth.type';
import { getDateString, getMonthYear, getRelativeDate, parseDateFromString } from 'common/moment.helper';

import FilterModal from './filter-modal';

const initialModalContent: IFilter = {
  filterName: '',
  default: false,
  screen: 'net-worth',
};

const NetworthFilter = (props: NetworthFilterProps) => {
  const dispatch = useFilterDispatch();
  const { saveFilter, selectFilter, removeFilter } = useFilters();
  const [currentAccount, setCurrentAccount] = useState<Account[]>();
  const [investingEntities, setInvestingEntities] = useState<IInvestingEntity[]>();

  const filterModal = useModal();
  const saveEditViewModal = useModal();

  const { data } = useSettings();
  const filterState = useFilterState();
  const networthState = useNetworthState();
  const { networth, processing } = networthState;

  const { fTypes, fToDate, filters, fAccounts, fFromDate, fEntities, fLiquidity, fCategories, fTimeInterval } =
    filterState;

  // FIXME: TAKE THIS AT THE BEGINNING.
  useEffect(() => {
    const fetchCurrentAccount = async () => {
      const { data: _account, error } = await getAccount();

      if (!error) {
        setCurrentAccount(_account);
      }
    };

    fetchCurrentAccount();
  }, []);

  useEffect(() => {
    const fetchInvestingEntities = async () => {
      const { data: _investingEntities, error } = await getInvestingEntities();

      if (!error) {
        setInvestingEntities(_investingEntities);
      }
    };
    fetchInvestingEntities();
  }, []);

  const [modalContent, setModalContent] = useState(initialModalContent);

  const fromInterval = networth?.[0]?.interval || '';
  const fromDate = parseDateFromString(fromInterval);

  // need to set current time zone value to the filter
  // we will pass the utc to server but need to set the value in current time zone
  const onChange = (option: string, date: any) => {
    if (option === 'start') {
      props.handleLoad();

      return dispatch(setFilterFromDate(getDateString(date)));
    }

    if (option === 'end') {
      if (fFromDate !== undefined && getDateString(date) > fFromDate) {
        props.handleLoad();

        return dispatch(setFilterToDate(getDateString(date)));
      }
    }
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleLoad();

    return dispatch(setFilterCategories(event.target.value));
  };

  const handleAccountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleLoad();
    dispatch(setFilterAccount(+event.target.value));
  };

  const handleAccountTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleLoad();

    dispatch(setFilterAccountType(event.target.value));
  };

  const handleInvestingEntitiesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleLoad();

    dispatch(setFilterInvestingEntity(+event.target.value));
  };

  const handleLiquidityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleLoad();

    dispatch(setFilterLiquidity(event.target.value));
  };

  const handleIntervalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleLoad();

    dispatch(setFilterTimeInterval(event.target.value as TimeIntervalEnum));
  };

  if (!(currentAccount || investingEntities)) {
    return <NetworthFilterSkeleton />;
  }

  const clearNetworthFilter = () => {
    return dispatch(clearFilter());
  };

  const hasFiltered = () => {
    const filterKey: TFilterKey[] = [
      'fCategories',
      'fTypes',
      'fAccounts',
      'fToDate',
      'fFromDate',
      'fTimeInterval',
      'fEntities',
      'fLiquidity',
    ];

    return filterKey.some((key) => isFiltered(key));
  };

  const isFiltered = (key: keyof NetworthState) => {
    if (serialize((filterState as any)[key]) !== serialize((initialState as any)[key])) {
      return true;
    }

    return false;
  };

  const fc = (key: keyof NetworthState) => (isFiltered(key) ? 'filtered' : '');

  const currentAccountByType = arrGroupBy(currentAccount || [], 'category.mmAccountType');

  const renderDesktopFilters = () => {
    return (
      <div className='dflex-center justify-space-between flex-wrap-custom mb-15 desktop-filters'>
        {/* all types */}
        <Dropdown className='drop-box mb-2'>
          <Dropdown.Toggle variant='' className={fc('fTypes')}>
            All Types
          </Dropdown.Toggle>
          <Dropdown.Menu className='mm-dropdown-menu'>
            <ul className='checkbox-list account-types-dropdown'>
              {Object.keys(currentAccountByType).map((accountName, index) => {
                return (
                  <li key={index}>
                    <label>
                      <input
                        name='types'
                        type='checkbox'
                        aria-describedby={accountName}
                        value={accountName}
                        aria-checked={!!fTypes?.includes(accountName)}
                        checked={fTypes?.includes(accountName)}
                        onChange={handleAccountTypeChange}
                      />
                      <span className='pl-3'>{accountName}</span>
                    </label>
                  </li>
                );
              })}
            </ul>
          </Dropdown.Menu>
        </Dropdown>

        {/* interval type */}
        <Dropdown className='drop-box'>
          <Dropdown.Toggle variant='' className={fc('fTimeInterval')}>
            {fTimeInterval || 'Monthly'}
          </Dropdown.Toggle>
          <Dropdown.Menu className='mm-dropdown-menu dropsm'>
            <ul className='radiolist'>
              {enumerateStr(TimeIntervalEnum).map((interval, index) => {
                return (
                  <li key={interval}>
                    <label>
                      <input
                        type='radio'
                        name='m-list'
                        aria-checked={fTimeInterval === interval}
                        value={interval}
                        checked={fTimeInterval === interval}
                        onChange={handleIntervalChange}
                      />
                      <span>{interval}</span>
                    </label>
                  </li>
                );
              })}
            </ul>
          </Dropdown.Menu>
        </Dropdown>
        <div className='datepicker-wrapper'>
          <ReactDatePicker
            selected={fFromDate ? new Date(fFromDate) : fromDate}
            onChange={(date) => onChange('start', date)}
            // selectsStart
            startDate={fFromDate ? new Date(fFromDate) : fromDate}
            dateFormat='MM/yyyy'
            showMonthYearPicker
            minDate={new Date('1900-01-01')}
            maxDate={new Date()}
            className='ml-md-3'
            // selectsRange
            customInput={
              <div className='drop-box'>
                <div className='date-box'>
                  <input
                    type='text'
                    className={['month_year', fc('fFromDate')].join(' ')}
                    value={fFromDate ? getMonthYear(fFromDate) : getMonthYear(fromDate)}
                    aria-label='From Date Filter'
                    readOnly
                  />
                </div>
              </div>
            }
          />
          <span className='date-separator'>to</span>
          <ReactDatePicker
            selected={fToDate ? new Date(fToDate) : null}
            onChange={(date) => onChange('end', date)}
            // selectsStart
            startDate={fToDate ? new Date(fToDate) : null}
            dateFormat='MM/yyyy'
            showMonthYearPicker
            minDate={fFromDate ? new Date(fFromDate) : null}
            maxDate={new Date()}
            className='mr-md-3'
            // selectsRange
            customInput={
              <div className='drop-box'>
                <div className='date-box'>
                  <input
                    type='text'
                    className={['month_year', fc('fToDate')].join(' ')}
                    value={getMonthYear(fToDate)}
                    aria-label='To Date Filter'
                    readOnly
                  />
                </div>
              </div>
            }
            popperClassName='to-date-popper'
          />
        </div>
      </div>
    );
  };

  const filterModalComponent = () => {
    return (
      <div className='dropdowns-container'>
        {renderDesktopFilters()}
        <div className='dflex-center justify-space-between flex-wrap-custom mb-15'>
          {/* all categories */}
          <Dropdown className='drop-box mb-2'>
            <Dropdown.Toggle variant='' className={fc('fCategories')}>
              All Categories
            </Dropdown.Toggle>
            <Dropdown.Menu className='mm-dropdown-menu'>
              <ul className='checkbox-list'>
                {enumerateStr(AccountCategory).map((cat, index) => {
                  return (
                    <li key={index}>
                      <label>
                        <input
                          name='category'
                          type='checkbox'
                          aria-describedby={cat}
                          value={cat}
                          aria-checked={!!fCategories?.includes(cat)}
                          checked={fCategories?.includes(cat)}
                          onChange={handleCategoryChange}
                        />
                        <span className='pl-3'>{cat}</span>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </Dropdown.Menu>
          </Dropdown>

          {/* all accounts */}
          <Dropdown className='drop-box mb-2'>
            <Dropdown.Toggle variant='' className={fc('fAccounts')}>
              All Accounts
            </Dropdown.Toggle>
            <Dropdown.Menu className='mm-dropdown-menu dd-my-accounts'>
              <div className='dropdown-box'>
                <ul className=''>
                  {currentAccount?.map((account, index) => {
                    return (
                      <li key={account.id}>
                        <div className='account-filter-dd-row'>
                          <label>
                            <input
                              name='accBox'
                              type='checkbox'
                              aria-describedby='Investment assets'
                              value={account.id}
                              aria-checked={fAccounts.includes(account.id)}
                              checked={fAccounts.includes(account.id)}
                              onChange={handleAccountChange}
                            />
                            <span />
                          </label>
                          <div className='pr-1'>
                            <h5>{account.accountName}</h5>
                            <span>
                              {getRelativeDate(account.providerAccount?.dataset?.[0]?.lastUpdated?.toString())}
                            </span>
                          </div>
                          <div className='account-filter-dd-balance'>
                            {data?.currency ? getCurrencySymbol(data.currency) : ''}
                            {numberWithCommas(fNumber(account.balance, 2))}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className='drop-box mb-2'>
            <Dropdown.Toggle variant='' className={fc('fEntities')}>
              All Entities
            </Dropdown.Toggle>
            <Dropdown.Menu className='mm-dropdown-menu'>
              <ul className='checkbox-list'>
                {investingEntities?.map((entity, index) => {
                  return (
                    <li key={index}>
                      <label>
                        <input
                          name='entity'
                          type='checkbox'
                          aria-describedby={entity.name}
                          value={entity.id}
                          aria-checked={!!fEntities?.includes(entity.id)}
                          checked={fEntities?.includes(entity.id)}
                          onChange={handleInvestingEntitiesChange}
                        />
                        <span className='pl-3'>{entity.name}</span>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className='drop-box mb-2'>
            <Dropdown.Toggle variant='' className={fc('fLiquidity')}>
              All Liquidity
            </Dropdown.Toggle>
            <Dropdown.Menu className='mm-dropdown-menu'>
              <ul className='checkbox-list'>
                {enumerateStr(LiquidityEnum).map((liquidity, index) => {
                  return (
                    <li key={index}>
                      <label>
                        <input
                          name='entity'
                          type='checkbox'
                          aria-describedby={liquidity}
                          value={liquidity}
                          aria-checked={!!fLiquidity?.includes(liquidity)}
                          checked={fLiquidity?.includes(liquidity)}
                          onChange={handleLiquidityChange}
                        />
                        <span className='pl-3'>{liquidity}</span>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  };

  const getModalContent = () => {
    const handleSaveView = async () => {
      if (modalContent.id) {
        await saveFilter(modalContent);

        return saveEditViewModal.close();
      }

      const filterPayload = makeFilterPayload(modalContent, filterState);
      await saveFilter(filterPayload);

      saveEditViewModal.close();
    };

    const handleRemoveFilter = async () => {
      if (modalContent.id) {
        await removeFilter(modalContent.id!.toString());

        return saveEditViewModal.close();
      }

      return saveEditViewModal.close();
    };

    return (
      <div className='view-modal-form-wrapper'>
        <FormGroup>
          <FormLabel className='form-subheading'>Name</FormLabel>
          <FormControl
            type='text'
            name='name'
            placeholder='View name'
            value={modalContent.filterName}
            onChange={(e) => setModalContent({ ...modalContent, filterName: e.target.value })}
          />
        </FormGroup>
        <div className='checkbox-delete-wrapper'>
          <div className='checkbox-wrapper'>
            <label className='custom-checkbox'>
              <input
                type='checkbox'
                aria-checked={modalContent.default}
                name='default'
                checked={modalContent.default}
                onChange={() => setModalContent({ ...modalContent, default: !modalContent.default })}
              />
              <span className='checkmark' />
            </label>
            <span className='ml-4 pl-2'>Use as default view</span>
          </div>

          {modalContent.id ? (
            <div className='text-center'>
              <button className='mm-btn-txt-delete' onClick={handleRemoveFilter}>
                Delete this view
              </button>
            </div>
          ) : null}
        </div>

        <div className='dropdown-filter__action-wrapper mt-4'>
          <button
            className='btn-outline-primary mm-btn-animate'
            type='button'
            onClick={saveEditViewModal.close}
            disabled={processing.filters}
          >
            Cancel
          </button>
          <button
            className='mm-btn-primary mm-btn-animate'
            type='button'
            onClick={handleSaveView}
            disabled={processing.filters}
          >
            {modalContent.id ? 'Save Changes' : 'Save View'}
          </button>
        </div>
      </div>
    );
  };

  const renderSelectView = () => {
    const handleEditCurrentView = (filter: IFilter) => {
      setModalContent({ ...filter });

      return saveEditViewModal.open();
    };

    const handleSaveCurrentView = () => {
      setModalContent(initialModalContent);

      return saveEditViewModal.open();
    };

    return (
      <Dropdown className='drop-box view-dropdown'>
        <Dropdown.Toggle variant=''>Saved Views</Dropdown.Toggle>
        <Dropdown.Menu className='mm-dropdown-menu'>
          <ul className='view-dropdown__list'>
            {filters?.map((filter) => {
              return (
                <li key={filter.id}>
                  <span onClick={() => selectFilter(filter)} role='button'>
                    {filter.filterName}
                  </span>
                  <button className='btn btn-icon' onClick={() => handleEditCurrentView(filter)}>
                    <MoreIcon />
                  </button>
                </li>
              );
            })}
            <hr className='hr-darkBg' />
            <li className='list-actions'>
              <span onClick={handleSaveCurrentView} role='button'>
                + Save Current View
              </span>
            </li>
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <div className='row'>
      <div className='row filter-saved-view-wrapper'>
        <div className='row filter__row'>
          {hasFiltered() ? (
            <button className='btn btn-outline-danger clear-filter clear-filter__desktop' onClick={clearNetworthFilter}>
              Clear Filters
            </button>
          ) : null}
          <button className='btn btn-outline-primary btn-add-filter' onClick={filterModal.open}>
            {hasFiltered() ? 'Manage Filters' : 'Add Filters'}
          </button>
          {renderDesktopFilters()}
          <button className='btn btn-outline-primary btn-more-filter' onClick={filterModal.open}>
            More Filters +
          </button>
        </div>
        {renderSelectView()}
      </div>
      <FilterModal filterModal={filterModal} clearFilterModal={clearNetworthFilter}>
        {filterModalComponent()}
      </FilterModal>

      <SaveEditViewModal saveEditViewModal={saveEditViewModal} title={modalContent.id ? 'Edit View' : 'Save View'}>
        {getModalContent()}
      </SaveEditViewModal>
    </div>
  );
};

export default NetworthFilter;
