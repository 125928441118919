import React from 'react';

import { EOpportunityRisk } from 'discover/discover.enum';

const BASE_BAR_SIZE = 16;
const BAR_INCREMENT = 6;
const NUMBER_OF_BARS = 3;

interface IOpportunityRiskProps {
  risk: EOpportunityRisk;
}

const OpportunityRisk: React.FC<IOpportunityRiskProps> = ({ risk }) => {
  const getBarClassName = (index: number) => {
    const riskMap: Record<EOpportunityRisk, number> = {
      [EOpportunityRisk.LOW]: 1,
      [EOpportunityRisk.MEDIUM]: 2,
      [EOpportunityRisk.HIGH]: 3,
    };

    const riskClassMap: Record<EOpportunityRisk, string> = {
      [EOpportunityRisk.LOW]: 'risk-low',
      [EOpportunityRisk.MEDIUM]: 'risk-medium',
      [EOpportunityRisk.HIGH]: 'risk-high',
    };

    const riskIndex = riskMap[risk];
    const baseClass = riskClassMap[risk];

    return index < riskIndex ? baseClass : `${baseClass}--faded`;
  };

  const renderRiskBars = () => {
    const bars = [];

    for (let i = 0; i < NUMBER_OF_BARS; i++) {
      const height = BASE_BAR_SIZE + i * BAR_INCREMENT;

      bars.push(
        <span className={`opportunity-risk__bar ${getBarClassName(i)}`}
              style={{ height: `${height}px` }}
              key={i}
  />
      );
    }

    return bars;
  };

  return (
    <div className='opportunity-risk'>
      <div className='opportunity-risk__bars'>{renderRiskBars()}</div>
      {/*<span className='opportunity-risk__label'>Risk</span>*/}
    </div>
  );
};

export default OpportunityRisk;
