export const isNumber = (x: any): x is number => typeof x === 'number';

export const isString = (x: any): x is string => typeof x === 'string';

export const fNumber = (num: number | string, digits = 2) => {
  if (isString(num)) {
    return Number.parseFloat(num).toFixed(digits);
  }
  if (num) {
    return num.toFixed(digits);
  }

  return '0.00';
};

export const numberWithCommas = (num: number | string) => {
  return `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const removeExtraChar = (input: number | string): string => {
  const reg = /[^0-9.]+/;

  if (isString(input)) {
    return input.replace(reg, '');
  }

  return input.toString().replace(reg, '');
};

interface IFormatOption {
  maxFraction?: number;
  minFraction?: number;
  locale?: string;
}

export const formatNumber = (num: number, props?: IFormatOption) => {
  const { maxFraction = 5, minFraction = 0, locale = 'en-US' } = props || {};

  const numberFormatter = new Intl.NumberFormat(locale, {
    maximumFractionDigits: maxFraction,
    minimumFractionDigits: minFraction,
  });

  return numberFormatter.format(num);
};
