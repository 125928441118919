import { useEffect, useState } from 'react';

import appEnv from 'app/app.env';
import { storage } from 'app/app.storage';
import io, { Socket } from 'socket.io-client';

export interface IDefaultEventsMap {
  [event: string]: (...args: any[]) => void;
}

const useSocket = () => {
  const [socket, setSocket] = useState<Socket<IDefaultEventsMap, IDefaultEventsMap>>();

  useEffect(() => {
    const socketIo = io(appEnv.SOCKET_URL, {
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `Bearer ${storage.accessToken()} `,
          },
        },
      },
    });

    setSocket(socketIo);

    function cleanup() {
      socketIo.disconnect();
    }

    return cleanup;
  }, []);

  return socket;
};

export default useSocket;
