import { useHistory, useLocation } from 'react-router';
import React, { useState, useEffect, useCallback } from 'react';

import {
  getFastlink,
  postManualAccount,
  getConnectionInfo,
  fetchManualMetalAccounts,
  fetchManualCryptoAccounts,
  getLatestProviderAccounts,
} from 'api/request.api';
import queryString from 'query-string';
import { events } from '@mm/data/event-list';
import useToast from 'common/hooks/useToast';
import { STATUS_CODE } from 'app/app.status';
import { logger } from 'common/logger.helper';
import useAccounts from 'auth/hooks/useAccounts';
import { GistEvents } from '@mm/data/gist-events';
import LoadingScreen from 'common/loading-screen';
import FastLinkModal from 'yodlee/fast-link.modal';
import { useAuthDispatch } from 'auth/auth.context';
import useAnalytics from 'common/hooks/useAnalytics';
import useGistEvents from 'common/hooks/useGistEvents';
import { getRefreshedAccount } from 'auth/auth.service';
import { FastLinkOptionsType } from 'yodlee/yodlee.type';
import { EMMAccountType } from 'account/enum/account-type';
import { appRouteConstants } from 'app/app-route.constant';
import { getCurrencySymbol } from 'common/currency-helper';
import { AddAccountScreenEnum } from 'account/account.enum';
import UpgradeAccountModal from 'common/upgrade-account.modal';
import { EProviderType, IManualAccount } from 'auth/auth.types';
import { getHoldingsTitle, hasHoldingsField } from 'auth/auth.helper';
import CryptoConnectionModal from 'auth/views/inc/crypto-connection.modal';
import { Modal, ModalType, ModalTypeEnum, Size, useModal } from 'common/components/modal';
import { IManualModalConfig, IExistingManualAccount, IRefreshParams, TAccountStatus } from 'account/account.type';

import AddCoinsModal from './add-coins.modal';
import AddMetalsModal from './add-metals.modal';
import ChooseAccount from './inc/choose-account.modal';
import AccountOptions from './inc/account-options.modal';
import ManualAccounts from './inc/manual-accounts.modal';
import ConnectedAccounts from './inc/connected-accounts.modal';
import ManualAccountHoldings from './inc/manual-account-holdings';
import SeparateHoldingsModal from './inc/separate-holdings.modal';
import ExistingCryptoAccounts from './inc/existing-crypto-accounts';
import ExistingMetalsAccounts from './inc/existing-metals-accounts';

interface IAddAccountsModalProps {
  addAccountModal: ModalType;
}

const AddAccountsModal: React.FC<IAddAccountsModalProps> = ({ addAccountModal }) => {
  const [modalSize, setModalSize] = useState<keyof Size>('lg');
  const [currentScreen, setCurrentScreen] = useState<AddAccountScreenEnum>(AddAccountScreenEnum.CHOOSE_ACCOUNT);

  const history = useHistory();
  const location = useLocation();
  const { mmToast } = useToast();
  const { event } = useAnalytics();
  const fastlinkModal = useModal();
  const dispatch = useAuthDispatch();
  const gistEvents = useGistEvents();
  const upgradeAccountModal = useModal();
  const cryptoConnectionModal = useModal();

  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<TAccountStatus>('INITIAL');

  const zaboLoading = status === 'QROKA_INITIATING';
  const autoLoading = status === 'FASTLINK_INITIATING';

  /*TODO remove me since not in use*/
  const [availableNumber] = useState<number>(0);

  const { loading: accountFetching, fetchLatestProviderAccounts, fetchAccounts } = useAccounts();

  const [fastLinkOptions, setFastLinkOptions] = useState<FastLinkOptionsType>({
    fastLinkURL: '',
    token: { tokenType: 'AccessToken', tokenValue: '' },
    config: { flow: '', configName: 'Aggregation', providerAccountId: 0 },
  });

  /*TODO remove me since not in use*/
  const [manualMax] = useState<boolean>(false);
  const [manualLoading, setManualLoading] = useState<boolean>(false);

  const [accountCurrency, setAccountCurrency] = useState<string>('');
  const [existingManualAccounts, setExistingManualAccounts] = useState<IExistingManualAccount[]>([]);
  const [manualModalConfig, setManualModalConfig] = useState<IManualModalConfig>({
    accountId: undefined,
    isEditMode: false,
  });
  const addCoinsModal = useModal();
  const addMetalsModal = useModal();

  const [isManualLoading, setIsManualLoading] = useState<boolean>(false);
  const [manualAccountFormValues, setManualAccountFormValues] = useState<Partial<IManualAccount> | null>(null);

  const parsedSearch = queryString.parse(location.search);
  const isFromSaltEdge = 'saltedge' === (parsedSearch.from as string)?.toLowerCase();
  const saltEdgeProvider = parsedSearch.apiType as EProviderType;
  const saltEdgeConnectionId = parsedSearch.connection_id;

  const isFromQroka = 'qrokaCoinbase' === (parsedSearch.from as string);
  const qrokaProvider = parsedSearch.provider as EProviderType;

  useEffect(() => {
    if (isFromSaltEdge) {
      addAccountModal.close();
    }
  }, [isFromSaltEdge, addAccountModal]);

  useEffect(() => {
    if (qrokaProvider === EProviderType.QROKA) {
      addAccountModal.close();
    }
  }, [qrokaProvider, addAccountModal]);

  const memoizedHandleConnectAccountSuccess = useCallback(async (provider: EProviderType) => {
    await handleConnectAccountSuccess(provider);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isFromSaltEdge && saltEdgeProvider && saltEdgeConnectionId) {
      memoizedHandleConnectAccountSuccess(saltEdgeProvider);
    }
  }, [isFromSaltEdge, saltEdgeProvider, saltEdgeConnectionId, memoizedHandleConnectAccountSuccess]);

  useEffect(() => {
    if (isFromQroka && qrokaProvider) {
      memoizedHandleConnectAccountSuccess(qrokaProvider);
    }
  }, [memoizedHandleConnectAccountSuccess, isFromQroka, qrokaProvider]);

  useEffect(() => {
    if (!addAccountModal.props.open) {
      setModalSize('lg');
      setCurrentScreen(AddAccountScreenEnum.CHOOSE_ACCOUNT);
    }
  }, [addAccountModal.props.open]);

  // Navigation Handlers
  const onCloseModal = useCallback(() => {
    addAccountModal.close();
  }, [addAccountModal]);

  const navigateToAccountOptions = useCallback(() => {
    setCurrentScreen(AddAccountScreenEnum.LEARN_ABOUT_ACCOUNTS);
    setModalSize('md');
  }, []);

  const navigateBackToChooseAccounts = useCallback(() => {
    setCurrentScreen(AddAccountScreenEnum.CHOOSE_ACCOUNT);
    setModalSize('lg');
  }, []);

  const navigateToConnectedAccounts = useCallback(() => {
    setCurrentScreen(AddAccountScreenEnum.CONNECTED_ACCOUNTS);
    setModalSize('xl');
  }, []);

  const navigateToManualAccounts = useCallback(() => {
    setCurrentScreen(AddAccountScreenEnum.MANUAL_ACCOUNTS);
    setModalSize('xl');
  }, []);

  const navigateToExistingCryptoAccounts = useCallback(() => {
    setCurrentScreen(AddAccountScreenEnum.EXISTING_CRYPTO_ACCOUNTS);
    setModalSize('md');
  }, []);

  const navigateToExistingMetalsAccounts = useCallback(() => {
    setCurrentScreen(AddAccountScreenEnum.EXISTING_METALS_ACCOUNTS);
    setModalSize('md');
  }, []);

  const navigateToSeparateHoldings = useCallback(() => {
    setCurrentScreen(AddAccountScreenEnum.SEPARATE_HOLDINGS);
    setModalSize('lg');
  }, []);

  const navigateToManualAccountHoldings = useCallback(() => {
    setCurrentScreen(AddAccountScreenEnum.MANUAL_ACCOUNT_HOLDINGS);
    setModalSize('xl');
  }, []);

  // Component Event handlers
  const handleFastlinkConnection = async () => {
    return handleConnectAccount();
  };

  const handleConnectAccount = async () => {
    setStatus('FASTLINK_INITIATING');
    const { data, error } = await getFastlink();

    if (error) {
      setStatus('FAILED');

      return mmToast('Error Occurred to Get Fastlink', { type: 'error' });
    }

    const fLinkOptions: FastLinkOptionsType = {
      fastLinkURL: data.fastLinkUrl,
      token: data.accessToken,
      config: data.params,
    };

    setFastLinkOptions(fLinkOptions);

    event(events.connectAccount);

    return fastlinkModal.open();
  };

  const handleConnectAccountSuccess = async (
    provider: EProviderType,
    isQroka?: boolean,
    providerAccountId?: number
  ) => {
    if (cryptoConnectionModal.props.open) {
      cryptoConnectionModal.close();
    }

    setLoading(true);
    const { error, data } = await getRefreshedAccount({ dispatch, provider, providerAccountId });

    if (STATUS_CODE.SERVER_ACCEPTED === error?.code) {
      logger.gp('handleConnectAccountSuccess, [STATUS_CODE.SERVER_ACCEPTED]', { error });

      await fetchAccounts();

      gistEvents.track(GistEvents.ADDED_ACCOUNT, {
        mmAccountProvider: provider,
      });

      setLoading(false);

      return history.push({ pathname: appRouteConstants.dashboard.DASHBOARD });
    }

    await fetchLatestProviderAccounts();

    let refreshParams: IRefreshParams | undefined;

    if (isQroka) {
      const { data: providerData } = await getLatestProviderAccounts();

      const accId = providerData[0].id;

      refreshParams = {
        mmAccountType: EMMAccountType.CRYPTOCURRENCIES,
        accountId: accId,
        hasHoldings: true,
      };
    }

    if (data) {
      setLoading(false);
    }

    addAccountModal.close();

    if (error) {
      mmToast('Error Occurred on Fetching user Details', { type: 'error' });
    }

    gistEvents.track(GistEvents.ADDED_ACCOUNT, {
      mmAccountProvider: provider,
    });

    logger.gp('handleConnectAccountSuccess', { error, data, refreshParams });

    handleRefresh(true, refreshParams);
  };

  // Manual Accounts
  const checkManualAccountLimit = async () => {
    return handleManualAccount();
  };

  const handleManualAccount = () => {
    event(events.manualConnectAccount);
    navigateToManualAccounts();
  };

  const handlePostManualAccount = async (values: Partial<IManualAccount>) => {
    setIsManualLoading(true);
    const { error: err, data: accountData } = await postManualAccount(values);

    if (!err && accountData) {
      await getConnectionInfo();
      await fetchLatestProviderAccounts();
      setIsManualLoading(false);

      return accountData;
    }

    throw err;
  };

  const checkExistingMetalOrCryptoAccounts = async (values: Partial<IManualAccount>) => {
    const { mmAccountType, hasHoldings } = values;

    // Cryptocurrencies
    if (!!hasHoldings && mmAccountType === EMMAccountType.CRYPTOCURRENCIES) {
      setManualLoading(true);
      const { data } = await fetchManualCryptoAccounts();
      setManualLoading(false);
      if (data && data.length) {
        setExistingManualAccounts(data);
        navigateToExistingCryptoAccounts();
        return;
      }

      try {
        const accountData = await handlePostManualAccount(values);

        gistEvents.track(GistEvents.ADDED_ACCOUNT, {
          provider: EProviderType.MANUAL,
        });
        handleExistingAccountClick(false, addCoinsModal)(accountData.id);
        return;
      } catch (err) {
        return mmToast('Crypto currency add Failed', { type: 'error' });
      }
    }

    // Precious Metals
    if (!!hasHoldings && mmAccountType === EMMAccountType.PRECIOUS_METALS) {
      setManualLoading(true);
      const { data } = await fetchManualMetalAccounts();
      setManualLoading(false);
      if (data && data.length) {
        setExistingManualAccounts(data);
        navigateToExistingMetalsAccounts();
        return;
      }

      try {
        const accountData = await handlePostManualAccount(values);

        gistEvents.track(GistEvents.ADDED_ACCOUNT, {
          provider: EProviderType.MANUAL,
        });
        handleExistingAccountClick(false, addMetalsModal)(accountData.id);
        return;
      } catch (err) {
        return mmToast('Precious metals add failed', { type: 'error' });
      }
    }
  };

  const handleManualFormSubmit = async (values: Partial<IManualAccount>) => {
    const { currency, mmAccountType } = values;

    if (currency) {
      setAccountCurrency(getCurrencySymbol(currency));
    }

    setManualAccountFormValues(values);

    const showSeparateHoldings = hasHoldingsField(mmAccountType || '');

    if (showSeparateHoldings) {
      navigateToSeparateHoldings();
      return;
    }

    await addNewManualAccount(values);
  };

  const addNewManualAccount = async (body: Partial<IManualAccount>) => {
    try {
      const newAccount = await handlePostManualAccount(body);
      const { id, hasHoldings, category } = newAccount;

      gistEvents.track(GistEvents.ADDED_ACCOUNT, {
        provider: EProviderType.MANUAL,
      });

      handleRefresh(true, { mmAccountType: category.mmAccountType, accountId: id, hasHoldings });
    } catch (err) {
      mmToast('Manual Account add failed', { type: 'error' });
    } finally {
      onCloseModal();
    }
  };

  const handleDetailedHoldingsClick = () => {
    if (!manualAccountFormValues) {
      return;
    }

    const updatedFormValues = {
      ...manualAccountFormValues,
      hasHoldings: true,
    };

    setManualAccountFormValues(updatedFormValues);

    const { mmAccountType } = manualAccountFormValues;

    if (mmAccountType === EMMAccountType.PRECIOUS_METALS || mmAccountType === EMMAccountType.CRYPTOCURRENCIES) {
      return checkExistingMetalOrCryptoAccounts(updatedFormValues);
    }

    addNewManualAccount(updatedFormValues);
  };

  const handleSimpleAccountClick = () => {
    if (!manualAccountFormValues) {
      return;
    }

    const updatedFormValues = {
      ...manualAccountFormValues,
      hasHoldings: false,
    };

    setManualAccountFormValues(updatedFormValues);

    navigateToManualAccountHoldings();
  };

  const handleAddNewManualAccountClick = (modalType: ModalType) => async () => {
    if (!manualAccountFormValues) {
      return;
    }
    try {
      const accountData = await handlePostManualAccount(manualAccountFormValues);

      gistEvents.track(GistEvents.ADDED_ACCOUNT, {
        provider: EProviderType.MANUAL,
      });

      handleExistingAccountClick(false, modalType)(accountData.id);
    } catch (err) {
      return mmToast('Adding new manual account failed', { type: 'error' });
    }
  };

  const handleExistingAccountClick = (editMode: boolean, modalType: ModalType) => (id: number) => {
    setManualModalConfig({ accountId: id, isEditMode: editMode });
    addAccountModal.close();
    modalType.open();
  };

  const handleRefresh = (newAccount = false, refreshParams?: IRefreshParams) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('from');
    searchParams.set('refetchAccounts', 'true');

    if (newAccount) {
      searchParams.set('from', 'accountSettings');
    }

    if (refreshParams) {
      const { mmAccountType, hasHoldings, accountId } = refreshParams;
      searchParams.set('accountId', accountId.toString());
      searchParams.set('accountType', mmAccountType);
      searchParams.set('holdings', hasHoldings ? 'true' : 'false');
    }

    location.pathname = appRouteConstants.dashboard.DASHBOARD;
    location.search = searchParams.toString();

    return history.push(location);
  };

  const renderScreen = () => {
    switch (currentScreen) {
      case AddAccountScreenEnum.CHOOSE_ACCOUNT:
        return (
          <ChooseAccount
            manualLoading={manualLoading}
            onClose={onCloseModal}
            onLearnMoreClick={navigateToAccountOptions}
            onConnectedAccountClick={navigateToConnectedAccounts}
            onManualAccountClick={checkManualAccountLimit}
          />
        );
      case AddAccountScreenEnum.LEARN_ABOUT_ACCOUNTS:
        return <AccountOptions onBack={navigateBackToChooseAccounts} onClose={onCloseModal} />;
      case AddAccountScreenEnum.CONNECTED_ACCOUNTS:
        return (
          <ConnectedAccounts
            onClose={onCloseModal}
            autoLoading={autoLoading}
            zaboLoading={zaboLoading}
            state={{ status, setStatus }}
            onBack={navigateBackToChooseAccounts}
            isLoading={loading || accountFetching}
            handleCryptoExchange={cryptoConnectionModal.open}
            handleFastlinkConnection={handleFastlinkConnection}
            handleConnectAccountSuccess={handleConnectAccountSuccess}
          />
        );
      case AddAccountScreenEnum.MANUAL_ACCOUNTS:
        return (
          <ManualAccounts
            isManualLoading={isManualLoading}
            initialFormValues={manualAccountFormValues}
            onClose={onCloseModal}
            onBack={navigateBackToChooseAccounts}
            handleFormSubmit={handleManualFormSubmit}
          />
        );
      case AddAccountScreenEnum.EXISTING_CRYPTO_ACCOUNTS:
        return (
          <ExistingCryptoAccounts
            loading={isManualLoading}
            accounts={existingManualAccounts}
            handleAddAccountClick={handleAddNewManualAccountClick(addCoinsModal)}
            handleExistingAccountClick={handleExistingAccountClick(true, addCoinsModal)}
          />
        );
      case AddAccountScreenEnum.EXISTING_METALS_ACCOUNTS:
        return (
          <ExistingMetalsAccounts
            loading={isManualLoading}
            accounts={existingManualAccounts}
            handleAddAccountClick={handleAddNewManualAccountClick(addMetalsModal)}
            handleExistingAccountClick={handleExistingAccountClick(true, addMetalsModal)}
          />
        );
      case AddAccountScreenEnum.SEPARATE_HOLDINGS:
        return (
          <SeparateHoldingsModal
            loading={isManualLoading}
            description={getHoldingsTitle(manualAccountFormValues?.mmAccountType || '')}
            onClose={onCloseModal}
            onBack={navigateToManualAccounts}
            onSimpleClick={handleSimpleAccountClick}
            onDetailedClick={handleDetailedHoldingsClick}
          />
        );
      case AddAccountScreenEnum.MANUAL_ACCOUNT_HOLDINGS:
        if (!manualAccountFormValues) {
          return null;
        }
        return (
          <ManualAccountHoldings
            loading={isManualLoading}
            initialFormValues={manualAccountFormValues}
            onClose={onCloseModal}
            onBack={navigateToSeparateHoldings}
            handleSubmit={addNewManualAccount}
          />
        );

      default:
        return null;
    }
  };

  if (loading || accountFetching) {
    return <LoadingScreen onAccountFetching containerStyle={{ position: 'fixed' }} />;
  }

  return (
    <>
      <Modal
        title=''
        {...addAccountModal.props}
        type={ModalTypeEnum.NO_HEADER}
        size={modalSize}
        canBeClosed
        onClose={onCloseModal}
      >
        {renderScreen()}
      </Modal>

      {fastlinkModal.props.open ? (
        <FastLinkModal
          fastLinkModal={fastlinkModal}
          fastLinkOptions={fastLinkOptions}
          handleSuccess={handleConnectAccountSuccess}
          handleOpenNewConnection={handleConnectAccount}
          state={{ status, setStatus }}
        />
      ) : null}

      {cryptoConnectionModal.props.open ? (
        <CryptoConnectionModal
          cryptoConnectionModal={cryptoConnectionModal}
          handleSuccess={handleConnectAccountSuccess}
        />
      ) : null}

      {(availableNumber || manualMax) && (
        <UpgradeAccountModal
          upgradeAccountModal={upgradeAccountModal}
          availableNumber={availableNumber}
          manualMax={manualMax}
        />
      )}

      <AddCoinsModal
        addCoinsModal={addCoinsModal}
        accountId={manualModalConfig.accountId}
        currencySymbol={accountCurrency}
        isEditMode={manualModalConfig.isEditMode}
        handleRefresh={(params) => handleRefresh(!manualModalConfig.isEditMode, params)}
      />

      <AddMetalsModal
        addMetalsModal={addMetalsModal}
        accountId={manualModalConfig.accountId}
        currencySymbol={accountCurrency}
        isEditMode={manualModalConfig.isEditMode}
        handleRefresh={(params) => handleRefresh(!manualModalConfig.isEditMode, params)}
      />
    </>
  );
};

export default AddAccountsModal;
