import React from 'react';

import SignupToday from 'website/partials/signup-today.footer';
import { ReactComponent as TrustPilotImg } from 'assets/images/home/trustpilot.svg';
import { ReactComponent as NortonImg } from 'assets/images/home/norton.svg';

interface IWebsiteFooter {
  isSignupToday?: boolean;
}

const WebsiteFooter: React.FC<IWebsiteFooter> = ({ isSignupToday = true }) => {
  return (
    <div className='website-footer'>
      {isSignupToday ? <SignupToday footerCTA={true} pricing={false}/> : null}

      <div className='website-footer-wrapper bg-secondary text-white'>
        <div className='footer-end-section p-t-40'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='info-copyright-wrapper'>
                <div className='m-b-3 d-flex flex-row'>
                  <a
                    className='m-r-4'
                    href='https://www.trustpilot.com/review/www.moneyminx.com'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <TrustPilotImg />
                  </a>
                  <NortonImg className='m-r-4' />
                </div>
                <div className='copyright-wrapper'>
                  © {new Date().getFullYear()} Money Minx, Inc. All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteFooter;
