import groupBy from 'lodash/groupBy';
import { getMomentDate } from 'common/moment.helper';
import { camelCase } from 'common/helper/string.helper';
import { AccountCategory } from 'networth/networth.enum';
import { isOperaMini, isSafari, isOpera } from 'common/common-helper';
import cryptoChainParams from 'common/data/crypto-chain-params.json';

import fieldData from './data/field.data.json';
import formFields from './data/form-field.json';
import { ECryptoConnections } from './auth.enum';
import holdingsField from './data/holdings-field.json';
import { CurrencyOptions } from './enum/currency-options';
import manualFormFields from './data/manual-account-form-field.json';
import manualFormModalFields from './data/manual-account-form-modal-field.json';
import { Account, IAccountDetails, TKeyFormField, TSettingFormField } from './auth.types';

export const groupByProviderName = (list: Account[], key: string = 'providerName') => {
  return groupBy(list, key);
};

export const groupByProviderNameAndProviderAccountId = (list: Account[]) => {
  return groupBy(list, (item) => `${item.providerName}--${item.providerAccountId}`);
};

export const parseProviderNameFromKey = (key: string) => {
  return key.split('--')?.[0];
};

export const makeFormFields = (filters: string[]) => {
  return filters.reduce<string[]>((acc, cur) => {
    const all = fieldData.all;
    const [found] = Object.keys(fieldData)
      .filter((key) => key === cur)
      .map((key) => (fieldData as any)[key]);

    acc = found ? [...all, ...acc, ...found] : [...all, ...acc];
    const _formFields = [...new Set(acc)];

    return _formFields;
  }, []);
};

/**
 *
 * @param field
 * @param fields
 * @returns
 */
export const isFieldExist = (field: string, fields: string[]) => fields.includes(field);

export const getInitialAccountSettingFormData = (account?: Account): TSettingFormField => {
  const currentFormFields = account?.accountDetails;

  const getInitialDate = (key: keyof IAccountDetails): Date | undefined => {
    return currentFormFields && currentFormFields[key] ? getMomentDate(currentFormFields[key] as string) : undefined;
  };

  return {
    mmAccountType: account?.category?.mmAccountType || '',
    mmAccountSubType: account?.category?.mmAccountSubType || '',
    investingEntityId: account?.investingEntityId || '',
    accountName: account?.accountName || '',
    streetAddress: currentFormFields?.streetAddress || '',
    city: currentFormFields?.city || '',
    state: currentFormFields?.state || '',
    zipCode: currentFormFields?.state || '',
    country: currentFormFields?.country || '',
    currency: currentFormFields?.currency || CurrencyOptions.USD,
    liquidity: currentFormFields?.liquidity,
    companySymbol: currentFormFields?.companySymbol,
    showAsInvestmentAsset: account?.category?.mmCategory === AccountCategory.INVESTMENT_ASSETS,
    isRetirement: currentFormFields?.isRetirement || false,
    is401k: currentFormFields?.is401k || false,
    employerMatchContribution: currentFormFields?.employerMatchContribution,
    employerMatch: currentFormFields?.employerMatch,
    employerMatchLimitIn: currentFormFields?.employerMatchLimitIn,
    employerMatchLimit: currentFormFields?.employerMatchLimit,
    includeEmployerMatch: currentFormFields?.includeEmployerMatch,
    interestRate: currentFormFields?.interestRate,
    originalBalance: currentFormFields?.originalBalance,
    term: currentFormFields?.term,
    maturityDate: getInitialDate('maturityDate'),
    amountInvested: currentFormFields?.amountInvested,
    amountDue: currentFormFields?.amountDue,
    investedDate: getInitialDate('investedDate'),
    propertyType: currentFormFields?.propertyType || '',
    beds: currentFormFields?.beds,
    baths: currentFormFields?.baths,
    useZestimate: currentFormFields?.useZestimate,
    ownership: currentFormFields?.ownership,
    industry: currentFormFields?.industry || '',
    investmentRound: currentFormFields?.investmentRound || '',
    investmentType: currentFormFields?.investmentType || '',
    cap: currentFormFields?.cap,
    postMoneyValuation: currentFormFields?.postMoneyValuation,
    website: currentFormFields?.website || '',
    estimatedMonthlyPayments: currentFormFields?.estimatedMonthlyPayments || '',
    estimatedAnnualReturns: currentFormFields?.estimatedAnnualReturns,
    originalLoanAmount: currentFormFields?.originalLoanAmount,
    originationDate: getInitialDate('originationDate'),
    isArchived: account?.isArchived,
    frequency: currentFormFields?.frequency,
    amortizationPeriod: currentFormFields?.amortizationPeriod,
    compoundPeriod: currentFormFields?.compoundPeriod,
  };
};

export const getFormField = (type: string) => {
  const key = camelCase(type) as TKeyFormField;

  return formFields[key];
};

export const getManualAccountFormField = (type: string) => {
  const key = camelCase(type) as TKeyFormField;

  return manualFormFields[key];
};

export const getManualAccountFormModalField = (type: string) => {
  const key = camelCase(type) as TKeyFormField;

  return manualFormModalFields[key];
};

export const getHoldingsSubHeading = (accountType: string) => {
  const manualAccountFormFields = getManualAccountFormField(accountType);

  const hasManualHoldingsField = manualAccountFormFields.find((item) => item.name === 'hasHoldings');

  return hasManualHoldingsField?.label || '';
};

export const isSeparateHoldingsAccount = (accountType: string) => {
  const manualAccountFormFields = getManualAccountFormField(accountType);

  const hasManualHoldingsField = manualAccountFormFields.find((item) => item.name === 'hasHoldings');

  return !!hasManualHoldingsField;
};

export const hasHoldingsField = (accType: string) => {
  const exists = Object.keys(holdingsField).find((item) => item === camelCase(accType));

  return !!exists;
};

export const getHoldingsTitle = (accType: string) => {
  const key = camelCase(accType) as keyof typeof holdingsField;

  if (!holdingsField[key]) {
    return '';
  }

  return holdingsField[key].find((item) => item.name === 'hasHoldings')?.label || '';
};

export const getManualAccountHoldingsField = (type: string) => {
  const key = camelCase(type) as keyof typeof holdingsField;

  return holdingsField[key];
};

export const isBalanceFieldRequired = (mmAccountType: string) => {
  const formFields = getManualAccountFormModalField(mmAccountType);

  if (!formFields) {
    return false;
  }

  const balanceField = formFields.find((item) => item.name === 'balance');

  return !!balanceField;
};

const isEthereum = (chainId?: number) => chainId === 1;
const isBSC = (chainId?: number) => chainId === 56;

export const getCryptoConnectText = (chainId?: number) => {
  if (isSafari()) {
    return 'Goto Metamask';
  }

  if (isEthereum(chainId)) {
    return 'Add Binance Smart chain';
  }

  if (isBSC(chainId)) {
    return 'Add Ethereum';
  }

  if (chainId) {
    return 'Add Another Wallet';
  }

  return 'Connect MetaMask';
};

export const getCryptoSwitchPayload = (con: ECryptoConnections) => {
  if (ECryptoConnections.ETHEREUM === con) {
    return cryptoChainParams.switch.ethereum;
  }

  if (ECryptoConnections.BINANCE_SMART_CHAIN === con) {
    return cryptoChainParams.switch.smartChain;
  }

  return {
    chainId: '',
  };
};

export const getNetworkName = (chainId?: number) => {
  if (isEthereum(chainId)) {
    return 'Ethereum';
  }

  if (isBSC(chainId)) {
    return 'Binance Smart Chain';
  }

  return '';
};

export const getNetworkTitle = (chainId?: number) => {
  if (isEthereum(chainId)) {
    return 'MetaMask Ethereum';
  }

  if (isBSC(chainId)) {
    return 'MetaMask Smart Chain';
  }

  return '';
};

export const getAddEthereumChainPayload = (con: ECryptoConnections) => {
  if (ECryptoConnections.BINANCE_SMART_CHAIN === con) {
    return cryptoChainParams.connect.smartChain;
  }
};

export const isMetamaskSupported = () => !(isSafari() || isOperaMini());

export const isCoinbaseWalletSupported = () => !(isSafari() || isOperaMini());

export const isLedgerSupported = () => !(isSafari() || isOperaMini() || isOpera());
