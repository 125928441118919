import Form from 'react-bootstrap/Form';
import React, { useState, useEffect } from 'react';

import { SelectInput } from 'common/components/input/select.input';
import { ReactComponent as InfoIcon } from 'assets/images/signup/info.svg';

interface IAddAssociatedPropertyProps {
  loading: boolean;
  realEstateAccounts: string[];
  defaultSelectedAccount: string;
  handleSubmit: (accountName: string) => void;
}

const AddAssociatedProperty: React.FC<IAddAssociatedPropertyProps> = ({
  loading,
  realEstateAccounts,
  defaultSelectedAccount,
  handleSubmit,
}) => {
  const [selectedAccount, setSelectedAccount] = useState<string>(defaultSelectedAccount);

  useEffect(() => {
    setSelectedAccount(defaultSelectedAccount);
  }, [defaultSelectedAccount]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit(selectedAccount);
  };

  return (
    <form className='associated-real-estate__form' onSubmit={onSubmit}>
      <Form.Group>
        <Form.Label className='form-subheading d-flex justify-content-between'>
          <span>Associated Property</span>
          <InfoIcon />
        </Form.Label>

        <SelectInput
          args={realEstateAccounts}
          value={selectedAccount}
          name='realEstateAccoount'
          onChange={(e) => setSelectedAccount(e.target.value)}
        />

        <button type='submit' className='mm-btn-animate mm-btn-primary __submit mt-4' disabled={!selectedAccount}>
          {loading ? (
            <>
              <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
              <span className='ml-1'>Saving...</span>
            </>
          ) : (
            <span>Save Changes</span>
          )}
        </button>
      </Form.Group>
    </form>
  );
};

export default AddAssociatedProperty;
