import React from 'react';

import { getCurrencySymbol } from 'common/currency-helper';
import { IAccountDataByType } from 'account-types/account-types.types';
import AccountTypesTable from 'account-types/components/account-types-table';
import AccountTypesChart from 'account-types/components/account-types-chart';

import AccountTypesFilter from '../components/account-types-filter';

interface IAccountTypesBodyProps {
  accountDataByType: IAccountDataByType;
  defaultFromDate: string | undefined;
  defaultToDate: string | undefined;
  holdingsForAccountType: any[];
}

const AccountTypesBody: React.FC<IAccountTypesBodyProps> = ({
  defaultToDate,
  defaultFromDate,
  accountDataByType,
  holdingsForAccountType,
}) => {
  const { currency, total, accounts } = accountDataByType;
  const currencySymbol = getCurrencySymbol(currency);

  return (
    <>
      <AccountTypesFilter defaultFromDate={defaultFromDate} defaultToDate={defaultToDate} />
      <AccountTypesChart currencySymbol={currencySymbol} total={total} />
      <AccountTypesTable
        accounts={accounts}
        currencySymbol={currencySymbol}
        holdingsForAccountType={holdingsForAccountType}
        balanceTotal={total}
      />
    </>
  );
};

export default AccountTypesBody;
