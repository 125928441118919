import { enumerateStr } from 'common/common-helper';
import { CurrencyNames, CurrencySymbols } from 'auth/enum/currency-options';

export const getCurrencySymbol = (cur: string) => {
  const CurrencySymbolsArr = enumerateStr(CurrencySymbols);
  let s = '';
  Object.keys(CurrencySymbols).map((k, index) => {
    if (k === cur) {
      s = CurrencySymbolsArr[index];
    }
    return 0;
  });

  return s;
};

export const getCurrencyName = (cur: string) => {
  const currencyNames = enumerateStr(CurrencyNames);

  let name = '';

  Object.keys(CurrencyNames).map((k, index) => {
    if (k === cur) {
      name = currencyNames[index];
    }

    return 0;
  });

  return name;
};
