import React from 'react';
import Skeleton from 'react-loading-skeleton';

import useRecentActivity from 'dashboard/hooks/useRecentActivity';
import { ReactComponent as RecentActivityLogo } from 'assets/images/dashboard/recent-activity.svg';

import RecentActivityItem from './recent-activity-item';

const RecentActivity: React.FC = () => {
  const { loading, recentActivities } = useRecentActivity();

  const renderRecentActivities = () => {
    if (loading) {
      return <Skeleton height={340} />;
    }

    if (!recentActivities.length) {
      return null;
    }

    const activities = [...recentActivities];
    const mid = Math.ceil(recentActivities.length / 2);

    const firstHalf = activities.splice(0, mid);
    const secondHalf = activities.splice(-mid);

    return (
      <div className='recent-activity__list'>
        <div className='recent-activity__list--top'>
          {firstHalf.map((activity) => (
            <RecentActivityItem activity={activity} key={activity.id} />
          ))}
        </div>
        <div>
          {secondHalf.map((activity) => (
            <RecentActivityItem activity={activity} key={activity.id} />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className='recent-activity'>
      <div className='recent-activity__header'>
        <RecentActivityLogo />
        <h2>Recent Activity</h2>
      </div>

      {renderRecentActivities()}

      <p className='recent-activity__footer-text'>Full activity screen is coming soon!</p>
    </div>
  );
};

export default RecentActivity;
