import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import useToast from 'common/hooks/useToast';
import { AuthLayout } from 'layouts/auth.layout';
import { useModal } from 'common/components/modal';
import BBBImg from 'assets/images/home/acc_bus.png';
import { useAuthDispatch } from 'auth/auth.context';
import { appRouteConstants } from 'app/app-route.constant';
import { associateFacebookUser } from 'auth/auth.service';
import { ReactComponent as LogoImg } from 'assets/icons/logo.svg';
import { ReactComponent as LoginLockIcon } from 'assets/images/login/lock-icon.svg';
import { ReactComponent as NortonImg } from 'assets/images/account/norton_black.svg';
import { ReactComponent as LoginShieldIcon } from 'assets/images/login/shield-icon.svg';
import { ReactComponent as TrustPilotImg } from 'assets/images/account/trustpilot_black.svg';

import EmailNeededModal from './inc/email-needed.modal';
import AssociateEmailModal from './inc/associate-email.modal';

const Signup = () => {
  return (
    <AuthLayout>
      <HelmetProvider>
        <Helmet>
          <title>Sign up | Money Minx</title>
          <meta
            name='description'
            content='Sign up for a Money Minx account and start tracking your net worth and investments in one place.'
          />
          {/*Facebook tags*/}
          <meta property='og:title' content='Sign up | Money Minx' />
          <meta
            property='og:description'
            content='Sign up for a Money Minx account and start tracking your net worth and investments in one place.'
          />
          <meta property='og:url' content='https://www.moneyminx.com/signup' />
          <meta
            property='og:image'
            content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png'
          />
          <meta
            property='og:image:secure_url'
            content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png'
          />
          <meta
            property='og:image:alt'
            content='Sign up for a Money Minx account and start tracking your net worth and investments in one place.'
          />
          {/*Twitter tags*/}
          <meta
            property='twitter:image'
            content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png'
          />
          <meta
            property='twitter:image:alt'
            content='Sign up for a Money Minx account and start tracking your net worth and investments in one place.'
          />
        </Helmet>
      </HelmetProvider>
      <SignupMainSection />
    </AuthLayout>
  );
};
export default Signup;
export const SignupMainSection = () => {
  const [fbToken] = useState<string>('');
  const [associateMessage] = useState<string>('');

  const { mmToast } = useToast();
  const associateModal = useModal();
  const dispatch = useAuthDispatch();
  const emailNeededModal = useModal();


  const handleFacebookAssociation = async () => {
    const { error } = await associateFacebookUser({ dispatch, token: fbToken });

    if (error) {
      mmToast('Association Failed', { type: 'error' });
    } else {
      mmToast('Association Success', { type: 'success' });
    }

    associateModal.close();
  };

  return (
    <div className='main-table-wrapper'>
      <div className=''>
        <div className='row login-wrapper'>
          <div className='guide-content'>
            <Link to='/'>
              <LogoImg className='icon auth-logo' />
            </Link>

            <div className='auth-left-content'>
              <h1>Three easy steps to get started with Money Minx</h1>
              <ul>
                <li>Find your accounts.</li>
                <li>Connect securely to Money Minx.</li>
                <li>Let Money Minx do the rest.</li>
              </ul>
              <div className='guide-bottom'>
                <h2>Serious about security</h2>
                <div className='guide-icon-wrap'>
                  <span className='locked-icon'>
                    <LoginLockIcon />
                  </span>
                  <p>The security of your information is our top priority</p>
                </div>
                <h2>Trusted by investors</h2>
                <div className='guide-icon-wrap'>
                  <span className='shield-icon'>
                    <LoginShieldIcon />
                  </span>
                  <p>Investors from all over the world are using Money Minx</p>
                </div>
              </div>
              <div className='m-b-3 d-flex flex-row'>
                <a
                  className='m-r-4'
                  href='https://www.trustpilot.com/review/www.moneyminx.com'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <TrustPilotImg />
                </a>
                <NortonImg className='m-r-4' />
                <img height='38' src={BBBImg} alt='Money Minx Acc Bus' />
              </div>
            </div>
          </div>

          <div className='bg-white credentials-wrapper'>
            <div className='credentials-content'>
              <Link to='/' className='logo-img-wrapper'>
                <LogoImg className='auth-logo' />
              </Link>
              <h2>Sign up for Money Minx</h2>
              <div className='form-wrap'>

                <div className='session-expired'>
                  <p className={'p-b-3'}>Big news! Money Minx is now part of the Arta Finance family. To focus on bringing you exciting new features and services, we are pausing new sign-ups. However, existing users can continue to use Money Minx as usual.</p>
                  <p className={'p-b-3'}>For more details about the acquisition and the transition, please read our <a href={'https://www.moneyminx.com/blog/money-minx-is-joining-arta-financial/'}>previous announcement</a>.</p>
                  <p className={'p-b-3'}>Thank you for your continued support and for joining me on this journey.</p>
                  <p className={'p-b-3'}>All the best,</p>
                  <p className={'p-b-3'}>Hussein Yahfoufi and the Money Minx team.</p>
                </div>

                <div>
                  <div className='auth-end-element'>
                    Already have an account?{' '}
                    <Link to={appRouteConstants.auth.LOGIN} className='purple-links'>
                      Log In
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AssociateEmailModal
        message={associateMessage}
        associateModal={associateModal}
        handleSuccess={handleFacebookAssociation}
      />

      <EmailNeededModal emailNeededModal={emailNeededModal} />
    </div>
  );
};
