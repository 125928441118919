import appEnv from 'app/app.env';
import * as Sentry from '@sentry/react';
import { storage } from 'app/app.storage';
import { StorageKey } from 'app/app.types';
import { STATUS_CODE } from 'app/app.status';
import { GistEvents } from '@mm/data/gist-events';
import { appRouteConstants } from 'app/app-route.constant';
import { withError, withData, wait } from 'common/common-helper';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { urls } from './api.url';

const MAX_TRIES = 10;
const currentRetries: Record<string, number> = {};

const client = axios.create({
  baseURL: appEnv.BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const pending = new Map();

const cancellingURIs = [urls.networth.NETWORTH_BY_TYPE, urls.networth.NETWORTH];
const authURIs = [urls.auth.LOGIN_IN, urls.auth.REGISTER, urls.auth.PROFILE];

const addPending = (config: AxiosRequestConfig) => {
  const url = [config.method, config.url].join('&');

  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pending.has(url)) {
        pending.set(url, cancel);
      }
    });
};

const removePending = (config: AxiosRequestConfig) => {
  const url = [config.method, config.url].join('&');

  if (pending.has(url)) {
    const cancel = pending.get(url);

    cancel({ code: STATUS_CODE.CANCELED, message: 'Request Canceled', url });
    pending.delete(url);
  }
};

export const clearPending = () => {
  for (const [url, cancel] of pending) {
    cancel(url);
  }

  pending.clear();
};

client.interceptors.request.use(
  (config) => {
    if (config.url && cancellingURIs.includes(config.url)) {
      removePending(config);
      addPending(config);
    }

    if (!config.headers?.authorization) {
      return Promise.reject('Token not found');
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response: AxiosResponse): any => {
    const config = response.config;
    const url = config.url;
    const status = response.status;

    removePending(response);

    const retry = async () => {
      await wait(10000);

      return client(config);
    };

    if (urls.auth.ACCOUNT_REFRESH === url && STATUS_CODE.SERVER_ACCEPTED === status) {
      currentRetries[url] = currentRetries[url] ? currentRetries[url] + 1 : 1;

      if (currentRetries[url] <= MAX_TRIES) {
        return retry();
      }

      triggerGistUserSyncErrorEvent();

      return withError({
        message: 'Still getting 202',
        code: STATUS_CODE.SERVER_ACCEPTED,
      });
    }

    return withData(response.data);
  },
  (error: AxiosError): any => {
    if ((error.message as any)?.code === STATUS_CODE.CANCELED) {
      return withError(error.message);
    }

    if (error.message === STATUS_CODE.NETWORK_ERROR) {
      return withError(error.message);
    }

    const status = error.response?.status;
    const url = error.response?.config?.url;
    const isAuthenticating = url === urls.auth.LOGIN_IN || url === urls.auth.REGISTER || url === urls.auth.PROFILE;

    const errorResponse = error.response?.data ? error.response.data : error;

    /**
     * IF the request is Account refresh and the server response is 500
     * It will try for 4 times to get the response 200
     * If not it will return the error response
     */

    if (urls.auth.ACCOUNT_REFRESH === url && STATUS_CODE.SERVER_ERROR === status) {
      currentRetries[url] = currentRetries[url] ? currentRetries[url] + 1 : 1;

      if (currentRetries[url] > MAX_TRIES) {
        triggerGistUserSyncErrorEvent();

        return withError(errorResponse);
      }

      return client(error.config);
    }

    if (status === STATUS_CODE.UNAUTHORIZED && !isAuthenticating) {
      const pathName = window.location.pathname;
      const previousURL = pathName + window.location.search;

      if (!authURIs.includes(pathName)) {
        storage.set(StorageKey.URL, previousURL);
      }

      storage.clear(StorageKey.AUTH);
      storage.set(StorageKey.ERR, error);

      return withError(window.location.replace(`${appRouteConstants.auth.LOGIN}?expired=true`));
    }

    Sentry.captureException(error);

    return withError(errorResponse);
  }
);

const getHeaders = (auth = true) => {
  const accessToken = storage.accessToken();

  return {
    headers: {
      authorization: accessToken && auth ? `Bearer ${accessToken}` : '',
    },
  };
};

export function get<P>(url: string, params?: P): any {
  return client({
    method: 'get',
    url,
    params,
    ...getHeaders(),
  });
}

export function post(url: string, data: any, auth: boolean = true, params?: any): any {
  return client({
    method: 'post',
    url,
    data,
    params,
    ...getHeaders(auth),
  });
}

export function put(url: string, data: any): any {
  return client({
    method: 'put',
    url,
    data,
    ...getHeaders(),
  });
}
export function patch(url: string, data?: any): any {
  return client({
    method: 'patch',
    url,
    data,
    ...getHeaders(),
  });
}

export function remove(url: string, params: object = {}): any {
  return client({
    method: 'delete',
    url,
    params,
    ...getHeaders(),
  });
}

export function clear(url: string, params: object = {}, data?: any) {
  return client({
    method: 'delete',
    url,
    params,
    data,
    ...getHeaders(),
  });
}

const triggerGistUserSyncErrorEvent = async () => {
  const { data, error } = await get(urls.auth.PROFILE);

  if (!error && !!data) {
    // Trigger a user sync error event
    window.gist.track(GistEvents.USER_SYNC_ERROR, data);
  }
};
