import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import ICONS from 'common/components/icons';
import { getRelativeDate } from 'common/moment.helper';
import { base64Encode } from 'common/helper/string.helper';
import { getCurrencySymbol } from 'common/currency-helper';
import { EIntervalOption } from 'dashboard/dashboard.enum';
import { fNumber, numberWithCommas } from 'common/number.helper';
import useSortedAccounts from 'dashboard/hooks/useSortedAccounts';
import { IDashboardAccountWithStatus } from 'dashboard/dashboard.types';
import useDashboardAccounts from 'dashboard/hooks/useDashboardAccounts';
import { ReactComponent as AccountsLogo } from 'assets/images/dashboard/accounts.svg';
import { ReactComponent as AccountsAddLogo } from 'assets/images/dashboard/accounts-add.svg';

import IntervalSelector from './interval-selector';
import DashboardAccountItem from './dashboard-account-item';

interface IDashboardAccountsProps {
  onAddAccountClick: VoidFunction;
}

const DashboardAccounts: React.FC<IDashboardAccountsProps> = ({ onAddAccountClick }) => {
  const history = useHistory();
  const [currentInterval, setCurrentInterval] = useState<EIntervalOption>(EIntervalOption['1D']);

  const { loading, historyLoading, allAccounts, activeAccounts, archivedAccounts, dashboardAccHistoryMap } =
    useDashboardAccounts(currentInterval);

  const {
    sortedAccounts: sortedActiveAccounts,
    sortOrder,
    requestSort: requestActiveSort,
  } = useSortedAccounts(activeAccounts);

  const { sortedAccounts: sortedArchiveAccounts, requestSort: requestArchiveAccountSort } =
    useSortedAccounts(archivedAccounts);

  const requestSort = () => {
    requestActiveSort();
    requestArchiveAccountSort();
  };

  const renderAccountElements = (accounts: IDashboardAccountWithStatus[]) => {
    const navigateToAccountDetail = (accId: number) => {
      const encodedAccountId = base64Encode(accId.toString());
      history.push('/account-details/' + encodedAccountId);
    };

    return accounts.map((acc) => {
      const { status, accountName, id, providerAccount, providerLogo, currency, balance } = acc;

      const currencySymbol = getCurrencySymbol(currency);

      const balanceText = `${currencySymbol}${numberWithCommas(fNumber(balance, 2))}`;
      const historyBalance = dashboardAccHistoryMap ? dashboardAccHistoryMap[id] || 0 : 0;

      const balanceDiff = balance - historyBalance;
      const symbol = balanceDiff < 0 ? '-' : '+';

      let balanceDiffPerText = '--';

      if (historyBalance) {
        const balanceDiffPer = fNumber((balanceDiff / Math.abs(historyBalance)) * 100);
        balanceDiffPerText = `${balanceDiffPer}%`;
      }

      const info = `${symbol}${currencySymbol}${numberWithCommas(
        fNumber(Math.abs(balanceDiff), 0)
      )} (${balanceDiffPerText})`;

      const updatedAt = `${
        providerAccount?.dataset?.[0]?.lastUpdated?.toString() !== null
          ? getRelativeDate(providerAccount?.dataset?.[0]?.lastUpdated?.toString())
          : ''
      }`;

      const className = `dashboard-account--status dashboard-account--${status} ${
        balanceDiff <= 0 ? 'dashboard-account--negative' : 'dashboard-account--positive'
      }`;

      return (
        <DashboardAccountItem
          key={id}
          name={accountName}
          imgSrc={providerLogo}
          balanceText={balanceText}
          updatedAt={updatedAt}
          balanceInfo={info}
          className={className}
          loading={historyLoading}
          onClick={() => navigateToAccountDetail(id)}
        />
      );
    });
  };

  const renderActiveAccounts = () => {
    if (!sortedActiveAccounts || !sortedActiveAccounts.length || !dashboardAccHistoryMap) {
      return null;
    }

    const accs = [...sortedActiveAccounts];
    const mid = Math.ceil(accs.length / 2);

    const firstHalf = accs.splice(0, mid);
    const secondHalf = accs.splice(-mid);

    return (
      <div className='dashboard-accounts__active-accounts-container'>
        <div className='dashboard-accounts__accounts-list'>
          <div className='dashboard-accounts__accounts-list--top'>{renderAccountElements(firstHalf)}</div>
          <div>{renderAccountElements(secondHalf)}</div>
        </div>
      </div>
    );
  };

  const renderArchivedAccounts = () => {
    if (!sortedArchiveAccounts || !sortedArchiveAccounts.length || !dashboardAccHistoryMap) {
      return null;
    }

    const accs = [...sortedArchiveAccounts];
    const mid = Math.ceil(accs.length / 2);

    const firstHalf = accs.splice(0, mid);
    const secondHalf = accs.splice(-mid);

    return (
      <div className='dashboard-accounts__archived-accounts-container'>
        <h3>Closed</h3>
        <div className='dashboard-accounts__accounts-list'>
          <div className='dashboard-accounts__accounts-list--top'>{renderAccountElements(firstHalf)}</div>
          <div>{renderAccountElements(secondHalf)}</div>
        </div>
      </div>
    );
  };

  const renderAccounts = () => {
    if (loading || !allAccounts.length || !dashboardAccHistoryMap) {
      return <Skeleton height={560} />;
    }

    return (
      <>
        {renderActiveAccounts()}
        {renderArchivedAccounts()}
      </>
    );
  };

  return (
    <div className='dashboard-accounts'>
      <div className='dashboard-accounts__header-container'>
        <div className='dashboard-accounts__header'>
          <div className='dashboard-accounts__header-title'>
            <AccountsLogo />
            <h2>My Accounts</h2>
            <SortIcon order={sortOrder} onClick={requestSort} />
          </div>

          <button type='button' className='dashboard-accounts__header-action' onClick={onAddAccountClick}>
            <AccountsAddLogo />
          </button>
        </div>

        <IntervalSelector defaultInterval={currentInterval} onChange={setCurrentInterval} />
      </div>

      <div className='dashboard-accounts__body'>{renderAccounts()}</div>
    </div>
  );
};

export default DashboardAccounts;

interface ISortIcon {
  order: 'ASC' | 'DESC';
  onClick: VoidFunction;
}

const SortIcon = (props: ISortIcon) => {
  if (props.order === 'DESC') {
    return <ICONS.ARROW_DOWN className='ml-1' onClick={props.onClick} role='button' />;
  }

  return <ICONS.ARROW_DOWN className='ml-1 rotate-180' onClick={props.onClick} role='button' />;
};
