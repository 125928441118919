import React from 'react';
import Skeleton from 'react-loading-skeleton';

const NetworthSkeleton = () => {
  return (
    <section className='content-container'>
      <hr className='m-0' />
      <div className='content-wrapper'>
        <div className='container'>
          <div className='row mb-40'>
            <div className='col-lg-9 mob-btm'>
              <Skeleton width={950} height={400} />
            </div>
            <div className='col-lg-3 mob-btm'>
              <Skeleton width={300} height={400} />
            </div>
          </div>
          <div className='row mb-40'>
            <div className='col-12'>
              <Skeleton width={1276} height={400} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NetworthSkeleton;
