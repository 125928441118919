import React from 'react';

import { useDispatch } from 'app/app.context';
import { resetFastlinkLoading } from 'app/app.actions';
import { Modal, ModalType } from 'common/components/modal';

interface IActionAbandonedModalProps {
  actionAbandonedModal: ModalType;
  handleBaseModalClose: () => void;
}

const ActionAbandonedModal: React.FC<IActionAbandonedModalProps> = ({ actionAbandonedModal, handleBaseModalClose }) => {
  const dispatch = useDispatch();

  const handleYesClick = () => {
    window.gist.chat('navigate', 'newConversation', 'I need help connecting my accounts.');
    actionAbandonedModal.close();
    dispatch(resetFastlinkLoading());

    return handleBaseModalClose();
  };

  const closeBothModal = () => {
    actionAbandonedModal.close();
    dispatch(resetFastlinkLoading());

    return handleBaseModalClose();
  };

  return (
    <>
      <Modal {...actionAbandonedModal.props} title='Need help?' size='sm' canBeClosed onClose={closeBothModal}>
        <div className='modal-wrapper'>
          <p>Do you need help connecting your accounts?</p>
          <div className='modal-btn-wrapper mm-modal-two-buttons'>
            <button
              className='btn-outline-primary mm-btn-animate mr-md-4 mb-2 mb-md-0'
              onClick={closeBothModal}
              type='button'
            >
              No
            </button>
            <button className='mm-btn-primary mm-btn-animate gist-open-chat' onClick={handleYesClick} type='button'>
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ActionAbandonedModal;
