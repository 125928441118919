import React from 'react';

import classNames from 'common/classes.helper';
import { IAccountDetails } from 'auth/auth.types';
import useExtraInfo from 'account/hooks/useExtraInfo';
import { addMonthToDate, getDateFormattedString, getUTC } from 'common/moment.helper';
import { fNumber, numberWithCommas } from 'common/number.helper';

import extraInfo from '../data/extra-info.json';

interface IAccountMoreInfo {
  accountType?: string;
  accountDetail: IAccountDetails;
  currencySymbol: string;
  isManual: boolean;
}

const { ratioFields, amountFields, dateFields } = extraInfo;

const AccountMoreInfo: React.FC<IAccountMoreInfo> = ({ accountType, accountDetail, currencySymbol, isManual }) => {
  const { formFields, hasExtraInfo } = useExtraInfo(accountType);

  if (!hasExtraInfo(accountDetail)) {
    return null;
  }

  return (
    <div className='account-more-info'>
      <ul className='account-more-info__list'>
        {formFields.map((item, index) => {
          const isDetail = 'details' === item.name;
          let value = (accountDetail as any)[item.name];

          if (isManual && accountType === 'Mortgages' && item.name === 'dueDate') {
            return null;
          }

          if (
            accountType === 'Mortgages' &&
            item.name === 'maturityDate' &&
            !!accountDetail['originationDate'] &&
            !!accountDetail['term'] &&
            !accountDetail['maturityDate']
          ) {
            const maturityDate = addMonthToDate(accountDetail['term'], accountDetail['originationDate']);

            value = getUTC(maturityDate);
          }

          if (amountFields.includes(item.name)) {
            value = value ? `${currencySymbol || '$'}${numberWithCommas(fNumber(value))}` : null;
          }

          if (ratioFields.includes(item.name)) {
            value = value ? `${value} %` : null;
          }

          if (dateFields.includes(item.name)) {
            value = value ? getDateFormattedString(value) : null;
          }

          return (
            <li key={index} className='account-more-info__list-item'>
              <span className={classNames('account-more-info__list-item--label', isDetail && 'active')}>
                {item.label}
              </span>
              {!isDetail ? <span className='account-more-info__list-item--value'>{value || '--'}</span> : null}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AccountMoreInfo;
