import { IManualAccount } from 'auth/auth.types';

/**
 * This is the form field list in order for manual account
 * @here please don't change the order.
 */
export const manualAccountFormFieldList = [
  'mmAccountType',
  'mmAccountSubType',
  'investingEntityId',
  'accountName',
  'streetAddress',
  'city',
  'state',
  'zipCode',
  'country',
  'hasHoldings',
  'balance',
  'currency',
  'liquidity',
  'useZestimate',
];

export const manualAccountInitialData: Partial<IManualAccount> = {
  mmAccountType: 'Cash',
  hasHoldings: 'no',
  currency: 'USD',
  investingEntityId: undefined,
  accountName: undefined,
  balance: undefined,
};
