import { Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { Formik } from 'formik';
import { ILeaseFormValue } from 'account/account.type';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/icon-trash.svg';

interface ILeaseFormProps {
  loading: boolean;
  values: ILeaseFormValue;
  currencySymbol: string;
  onDeleteLease: VoidFunction;
  onAddNewLease: VoidFunction;
  onSave: (values: ILeaseFormValue) => void;
}

const LeaseForm: React.FC<ILeaseFormProps> = ({
  loading,
  values,
  currencySymbol,
  onAddNewLease,
  onDeleteLease,
  onSave,
}) => {
  const [formInitialValues, setFormInitialValues] = useState<ILeaseFormValue>(values);

  useEffect(() => {
    setFormInitialValues(values);
  }, [values]);

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={(values) => {
        onSave(values);
      }}
    >
      {(props) => {
        const { values: formikValues, setFieldValue, handleChange, handleSubmit } = props;

        return (
          <form className='lease-form' onSubmit={handleSubmit}>
            <span className='lease-form__description'>Add details about your renter below.</span>

            <div className='row align-items-center justify-content-between real-estate-input-group'>
              <label className='col-4 pl-0 lease-form__label'>Tenant</label>
              <div className='col-8 pr-0'>
                <Form.Control type='string' name='tenant' value={formikValues.tenant} onChange={handleChange} />
              </div>
            </div>

            <div className='row align-items-center justify-content-between real-estate-input-group'>
              <label className='col-4 pl-0 lease-form__label'>Move-in Date</label>
              <div className='col-8 pr-0'>
                <ReactDatePicker
                  name='moveInDate'
                  selected={formikValues.moveInDate}
                  onChange={(val: Date) => {
                    setFieldValue('moveInDate', moment(val).toDate());
                  }}
                />
              </div>
            </div>

            <div className='row align-items-center justify-content-between real-estate-input-group'>
              <label className='col-4 pl-0 lease-form__label'>Lease Expires</label>
              <div className='col-8 pr-0'>
                <ReactDatePicker
                  name='leaseExpire'
                  selected={formikValues.leaseExpire}
                  onChange={(val: Date) => {
                    setFieldValue('leaseExpire', moment(val).toDate());
                  }}
                />
              </div>
            </div>

            <div className='row align-items-center justify-content-between real-estate-input-group'>
              <label className='col-4 pl-0 lease-form__label'>Rent<span className='text--redAlert'> *</span></label>
              <div className='col-8 pr-0 currency-input'>
                <Form.Control type='number' name='rent' value={formikValues.rent} onChange={handleChange} step= 'any'/>
                <span className='currency-input__symbol'>{currencySymbol}</span>
              </div>
            </div>

            <div className='row align-items-center justify-content-between real-estate-input-group'>
              <label className='col-4 pl-0 lease-form__label'>Deposit</label>
              <div className='col-8 pr-0 currency-input'>
                <Form.Control type='number' name='deposit' value={formikValues.deposit} onChange={handleChange} step= 'any'/>
                <span className='currency-input__symbol'>{currencySymbol}</span>
              </div>
            </div>

            <button className='lease-form__btn-add' type='button' onClick={onAddNewLease}>
              <PlusIcon />
              <span>Add Another Lease</span>
            </button>

            <div className='d-flex justify-content-between align-items-center lease-form__submit-container'>
              <button type='submit' className='mm-btn-animate mm-btn-primary' disabled={!formikValues.rent}>
                {loading ? (
                  <>
                    <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
                    <span className='ml-1'>Saving...</span>
                  </>
                ) : (
                  <span>Save Changes</span>
                )}
              </button>
              <DeleteIcon className='btn-delete' role='button' onClick={onDeleteLease} />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default LeaseForm;
