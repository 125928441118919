import { useEffect, useCallback, MutableRefObject } from 'react';

const isTarget = <T extends HTMLElement>(ref: MutableRefObject<T | null>, event: any) => {
  return ref && ref.current && ref.current.contains(event.target);
};

const useClickOutside = <T extends HTMLElement>(ref: MutableRefObject<T | null>, onClick: () => void) => {
  const handleClick = useCallback(
    (event: any) => {
      if (isTarget(ref, event)) {
        return;
      }

      onClick();
    },
    [ref, onClick]
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, [handleClick]);
};

export default useClickOutside;
