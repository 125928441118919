import { IFilter } from 'networth/networth.type';
import { TimeIntervalEnum } from 'networth/networth.enum';

import { EFilterAction, IFilterAction, IFilterState } from './filter.type';

export const setFilterFromDate = (fromDate: string): IFilterAction => ({
  type: EFilterAction.SET_FILTER_FROM_DATE,
  payload: {
    fFromDate: fromDate,
  },
});

export const setFilterToDate = (toDate?: string): IFilterAction => ({
  type: EFilterAction.SET_FILTER_TO_DATE,
  payload: {
    fToDate: toDate,
  },
});

export const setFilterCategories = (fCategory: string): IFilterAction => ({
  type: EFilterAction.SET_FILTER_CATEGORY,
  payload: { fCategory },
});

export const setFilterAccount = (fAccountId: number): IFilterAction => ({
  type: EFilterAction.SET_FILTER_ACCOUNT,
  payload: { fAccountId },
});

export const setFilterAccountType = (fAccountType: string): IFilterAction => ({
  type: EFilterAction.SET_FILTER_ACCOUNT_TYPE,
  payload: { fAccountType },
});

export const setFilterTimeInterval = (fTimeInterval: TimeIntervalEnum): IFilterAction => ({
  type: EFilterAction.SET_FILTER_TIME_INTERVAL,
  payload: { fTimeInterval },
});

export const setFilterInvestingEntity = (fEntityId: number): IFilterAction => ({
  type: EFilterAction.SET_FILTER_ENTITY,
  payload: { fEntityId },
});

export const setFilterLiquidity = (fLiquidityItem: string): IFilterAction => ({
  type: EFilterAction.SET_FILTER_LIQUIDITY,
  payload: { fLiquidityItem },
});

export const clearFilter = (): IFilterAction => ({
  type: EFilterAction.CLEAR_FILTER,
});

export const setFilters = (filters: IFilter[]): IFilterAction => ({
  type: EFilterAction.SET_FILTERS,
  payload: { filters },
});

export const addFilter = (filter: IFilter): IFilterAction => ({
  type: EFilterAction.ADD_FILTER,
  payload: { filter },
});

export const updateFilter = (filter: IFilter): IFilterAction => ({
  type: EFilterAction.UPDATE_FILTER,
  payload: { filter },
});

export const removeContextFilter = (filterId: string): IFilterAction => ({
  type: EFilterAction.REMOVE_FILTER,
  payload: { filterId },
});

export const bulkUpdate = (localFilter: Partial<IFilterState>): IFilterAction => ({
  type: EFilterAction.FILTER_BULK_UPDATE,
  payload: { localFilter },
});

export const setProcessing = (): IFilterAction => ({
  type: EFilterAction.SET_PROCESSING,
});

export const resetProcessing = (): IFilterAction => ({
  type: EFilterAction.RESET_PROCESSING,
});
