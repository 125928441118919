import React from 'react';
import { Link } from 'react-router-dom';

import BBBImg from 'assets/images/home/acc_bus.png';
import { ReactComponent as LogoImg } from 'assets/icons/logo.svg';
import { ReactComponent as LoginLockIcon } from 'assets/images/login/lock-icon.svg';
import { ReactComponent as NortonImg } from 'assets/images/account/norton_black.svg';
import { ReactComponent as LoginShieldIcon } from 'assets/images/login/shield-icon.svg';
import { ReactComponent as TrustPilotImg } from 'assets/images/account/trustpilot_black.svg';

const LoginGuideContent = () => {
  return (
    <div className='guide-content'>
      <Link to='/'>
        <LogoImg className='icon auth-logo' />
      </Link>
      <div className='auth-left-content'>
        <h1>Three easy steps to get started with Money Minx</h1>
        <ul>
          <li>Find your accounts.</li>
          <li>Connect securely to Money Minx.</li>
          <li>Let Money Minx do the rest.</li>
        </ul>
        <div className='guide-bottom'>
          <h2>Serious about security</h2>
          <div className='guide-icon-wrap'>
            <span className='locked-icon'>
              <LoginLockIcon />
            </span>
            <p>The security of your information is our top priority</p>
          </div>
          <h2>Trusted by investors</h2>
          <div className='guide-icon-wrap'>
            <span className='shield-icon'>
              <LoginShieldIcon />
            </span>
            <p>Investors from all over the world are using Money Minx</p>
          </div>
        </div>
        <div className='m-b-3 d-flex flex-row'>
          <a
            className='m-r-4'
            href='https://www.trustpilot.com/review/www.moneyminx.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            <TrustPilotImg />
          </a>
          <NortonImg className='m-r-4' />
          <img height='38' src={BBBImg} alt='Money Minx Acc Bus' />
        </div>
      </div>
    </div>
  );
};

export default LoginGuideContent;
