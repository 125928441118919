import React from 'react';
import { Redirect } from 'react-router';

import { appRouteConstants } from 'app/app-route.constant';

const StripeUpdatePaymentCancel: React.FC = () => {
  return <Redirect to={appRouteConstants.settings.SETTINGS} />;
};

export default StripeUpdatePaymentCancel;
