import React from 'react';

import { ECryptoConnections } from 'auth/auth.enum';
import { getQrokaConnectionURL, postQrokaAccount } from 'api/request.api';

import { ICryptoAccount } from './useLedger';

const ConnectionErrorMap: Partial<Record<ECryptoConnections, string>> = {
  [ECryptoConnections.COINBASE]: 'Sorry, we encountered an error, please try again or contact us for help.',
  [ECryptoConnections.BINANCE]: 'Sorry, we encountered an error, please try again or contact us for help.',
  [ECryptoConnections.BITTREX]: 'Sorry, we encountered an error, please try again or contact us for help.',
  [ECryptoConnections.BINANCE_US]: 'Sorry, we encountered an error, please try again or contact us for help.',
  [ECryptoConnections.COINBASE_PRO]: 'Sorry, we encountered an error, please try again or contact us for help.',
  [ECryptoConnections.ETHEREUM]: 'Sorry, we encountered an error, please try again or contact us for help.',
  [ECryptoConnections.BITCOIN]: 'Sorry, we encountered an error, please try again or contact us for help.',
  [ECryptoConnections.BINANCE_SMART_CHAIN]: 'Sorry, we encountered an error, please try again or contact us for help.',
  [ECryptoConnections.TERRA]: 'Sorry, we encountered an error, please try again or contact us for help.',
  [ECryptoConnections.METAMASK]: 'Sorry, we encountered an error, please try again or contact us for help.',
  [ECryptoConnections.COINBASE_WALLET]: 'Sorry, we encountered an error, please try again or contact us for help.',
  [ECryptoConnections.LEDGER]: 'Sorry, we encountered an error, please try again or contact us for help.',
  [ECryptoConnections.VINOVEST]: 'Sorry, we encountered an error, please try again or contact us for help.',
  [ECryptoConnections.CELSIUS]: 'Sorry, we encountered an error, please try again or contact us for help.',
};

// We don't have mechanism to handle conflicts for oauth2 and wallet based connections
const ConflictConnectionErrorMap: Partial<Record<ECryptoConnections, string>> = {
  ...ConnectionErrorMap,
  [ECryptoConnections.BINANCE]: 'You have already added this API Key to Money Minx.',
  [ECryptoConnections.BITTREX]: 'You have already added this API Key to Money Minx.',
  [ECryptoConnections.BINANCE_US]: 'You have already added this API Key to Money Minx.',
  [ECryptoConnections.COINBASE_PRO]: 'You have already added this API Key to Money Minx.',
  [ECryptoConnections.ETHEREUM]: 'You have already added this address to Money Minx.',
  [ECryptoConnections.BITCOIN]: 'You have already added this address to Money Minx.',
  [ECryptoConnections.BINANCE_SMART_CHAIN]: 'You have already added this address to Money Minx.',
  [ECryptoConnections.TERRA]: 'You have already added this address to Money Minx.',
  [ECryptoConnections.VINOVEST]: 'You have already added this account to Money Minx.',
  [ECryptoConnections.CELSIUS]: 'You have already added this API Key to Money Minx.',
};

export interface IAddress {
  address: string;
  addressType: string;
}

const useQroka = () => {
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({
    [ECryptoConnections.LEDGER]: '',
    [ECryptoConnections.BINANCE]: '',
    [ECryptoConnections.BITCOIN]: '',
    [ECryptoConnections.BITTREX]: '',
    [ECryptoConnections.COINBASE]: '',
    [ECryptoConnections.ETHEREUM]: '',
    [ECryptoConnections.METAMASK]: '',
    [ECryptoConnections.BINANCE_US]: '',
    [ECryptoConnections.COINBASE_PRO]: '',
    [ECryptoConnections.COINBASE_WALLET]: '',
    [ECryptoConnections.BINANCE_SMART_CHAIN]: '',
    [ECryptoConnections.VINOVEST]: '',
    [ECryptoConnections.CELSIUS]: '',
    [ECryptoConnections.TERRA_STATION_WALLET]: '',
    [ECryptoConnections.TRUST_WALLET]: '',
  });

  const connectBinance = (apiKey: string, apiSecret: string) => async (handleSuccess: any) => {
    const payload = {
      apiKey,
      apiSecret,
      connectionType: ECryptoConnections.BINANCE,
    };

    setLoading(true);
    const { data, error } = await postQrokaAccount(payload);

    setLoading(false);

    if (error) {
      return setError(ECryptoConnections.BINANCE);
    }

    if (data) {
      handleSuccess(data);
    }
  };
  const connectBinanceUS = (apiKey: string, apiSecret: string) => async (handleSuccess: any) => {
    const payload = {
      apiKey,
      apiSecret,
      connectionType: ECryptoConnections.BINANCE_US,
    };

    setLoading(true);
    const { data, error } = await postQrokaAccount(payload);

    setLoading(false);

    if (error) {
      return setError(ECryptoConnections.BINANCE_US);
    }

    if (data) {
      handleSuccess(data);
    }
  };
  const connectBittrex = (apiKey: string, apiSecret: string) => async (handleSuccess: any) => {
    const payload = {
      apiKey,
      apiSecret,
      connectionType: ECryptoConnections.BITTREX,
    };

    setLoading(true);
    const { data, error } = await postQrokaAccount(payload);

    setLoading(false);

    if (error) {
      return setError(ECryptoConnections.BITTREX);
    }

    if (data) {
      handleSuccess(data);
    }
  };

  const connectCoinbasePro =
    (apiKey: string, apiSecret: string, apiPassphrase: string) => async (handleSuccess: any) => {
      const payload = {
        apiKey,
        apiSecret,
        apiPassphrase,
        connectionType: ECryptoConnections.COINBASE_PRO,
      };

      setLoading(true);
      const { error, data } = await postQrokaAccount(payload);

      setLoading(false);

      if (error) {
        return setError(ECryptoConnections.COINBASE_PRO);
      }

      if (data) {
        handleSuccess(data);
      }
    };

  const resetErrors = () => {
    setErrors({
      [ECryptoConnections.LEDGER]: '',
      [ECryptoConnections.BINANCE]: '',
      [ECryptoConnections.BITCOIN]: '',
      [ECryptoConnections.BITTREX]: '',
      [ECryptoConnections.COINBASE]: '',
      [ECryptoConnections.ETHEREUM]: '',
      [ECryptoConnections.METAMASK]: '',
      [ECryptoConnections.BINANCE_US]: '',
      [ECryptoConnections.COINBASE_PRO]: '',
      [ECryptoConnections.COINBASE_WALLET]: '',
      [ECryptoConnections.BINANCE_SMART_CHAIN]: '',
      [ECryptoConnections.VINOVEST]: '',
      [ECryptoConnections.CELSIUS]: '',
      [ECryptoConnections.TERRA_STATION_WALLET]: '',
      [ECryptoConnections.TRUST_WALLET]: '',
    });
  };

  const setError = (connection: keyof typeof ConnectionErrorMap) => {
    return setErrors({
      ...errors,
      [connection]: ConnectionErrorMap[connection],
    });
  };

  const setConflictError = (connection: keyof typeof ConnectionErrorMap) => {
    return setErrors({
      ...errors,
      [connection]: ConflictConnectionErrorMap[connection],
    });
  };

  const connectWithAddress = (address: string, connectionType: ECryptoConnections) => async (handleSuccess: any) => {
    const payload = {
      address,
      connectionType,
    };

    setLoading(true);
    const { error, data } = await postQrokaAccount(payload);

    setLoading(false);

    if (error?.statusCode === 409) {
      return setConflictError(connectionType);
    }

    if (error) {
      return setError(connectionType);
    }

    if (data) {
      return handleSuccess(data);
    }
  };

  const connectVinovest = (username: string, password: string) => async (handleSuccess: any) => {
    const payload = {
      username,
      password,
      connectionType: ECryptoConnections.VINOVEST,
    };

    setLoading(true);
    const { error, data } = await postQrokaAccount(payload);

    setLoading(false);

    if (error) {
      return setError(ECryptoConnections.VINOVEST);
    }

    if (data) {
      return handleSuccess(data);
    }
  };

  const connectMetamask = (addresses: IAddress[]) => async (handleSuccess: any) => {
    const payload = {
      addresses,
      connectionType: ECryptoConnections.METAMASK,
    };

    setLoading(true);
    const { error, data } = await postQrokaAccount(payload);

    setLoading(false);

    if (error) {
      return setError(ECryptoConnections.METAMASK);
    }

    if (data) {
      return handleSuccess(data);
    }
  };
  const connectCoinbaseWallet = (addresses: string[]) => async (handleSuccess: any) => {
    // FIXME: once we support other addresses.
    const addressList = addresses.map((address) => ({ address, addressType: ECryptoConnections.ETHEREUM }));

    const payload = {
      addresses: addressList,
      connectionType: ECryptoConnections.COINBASE_WALLET,
    };

    setLoading(true);
    const { error, data } = await postQrokaAccount(payload);

    setLoading(false);

    if (error) {
      return setError(ECryptoConnections.COINBASE_WALLET);
    }

    if (data) {
      return handleSuccess(data);
    }
  };

  const connectTerraStationWallet = (addresses: string[]) => async (handleSuccess: any) => {
    const addressList = addresses.map((address) => ({ address, addressType: ECryptoConnections.TERRA }));

    const payload = {
      addresses: addressList,
      connectionType: ECryptoConnections.TERRA_STATION_WALLET,
    };

    setLoading(true);
    const { error, data } = await postQrokaAccount(payload);

    setLoading(false);

    if (error) {
      return setError(ECryptoConnections.TERRA_STATION_WALLET);
    }

    if (data) {
      return handleSuccess(data);
    }
  };

  const connectTrustWallet = (addresses: ICryptoAccount[]) => async (handleSuccess: any) => {
    const payload = {
      addresses,
      connectionType: ECryptoConnections.TRUST_WALLET,
    };

    setLoading(true);
    const { error, data } = await postQrokaAccount(payload);

    setLoading(false);

    if (error) {
      return setError(ECryptoConnections.TRUST_WALLET);
    }

    if (data) {
      return handleSuccess(data);
    }
  };

  const connectLedgerWallet = (addresses: ICryptoAccount[]) => async (handleSuccess: any) => {
    const payload = {
      addresses,
      connectionType: ECryptoConnections.LEDGER,
    };

    setLoading(true);
    const { error, data } = await postQrokaAccount(payload);

    setLoading(false);

    if (error) {
      return setError(ECryptoConnections.LEDGER);
    }

    if (data) {
      return handleSuccess(data);
    }
  };

  const getConnectionURL = async (connectionType = ECryptoConnections.COINBASE) => {
    setLoading(true);
    const param = { connectionType };
    setLoading(false);

    const { data, error } = await getQrokaConnectionURL(param);

    return { data, error };
  };

  const addCoinbaseAccount = async (code: string) => {
    setLoading(true);
    const payload = { connectionType: ECryptoConnections.COINBASE, code };
    const { data, error } = await postQrokaAccount(payload);
    setLoading(false);

    return { data, error };
  };

  const addGeminiAccount = async (code: string) => {
    setLoading(true);
    const payload = { connectionType: ECryptoConnections.GEMINI, code };
    const { data, error } = await postQrokaAccount(payload);
    setLoading(false);

    return { data, error };
  };

  const connectCelsiusAccount = (apiKey: string) => async (handleSuccess: any) => {
    setLoading(true);
    const payload = { connectionType: ECryptoConnections.CELSIUS, apiKey };
    const { data, error } = await postQrokaAccount(payload);
    setLoading(false);

    if (error) {
      return setError(ECryptoConnections.CELSIUS);
    }

    if (data) {
      return handleSuccess(data);
    }
  };

  return {
    errors,
    loading,
    resetErrors,
    connectBinance,
    connectBittrex,
    connectMetamask,
    connectVinovest,
    connectBinanceUS,
    getConnectionURL,
    addGeminiAccount,
    connectCelsiusAccount,
    connectWithAddress,
    addCoinbaseAccount,
    connectCoinbasePro,
    connectLedgerWallet,
    connectCoinbaseWallet,
    connectTerraStationWallet,
    connectTrustWallet,
  };
};

export default useQroka;
