import React from 'react';

import Assets from 'dashboard/components/assets';
import { useModal } from 'common/components/modal';
import Liabilities from 'dashboard/components/liabilities';
import AddAccountsModal from 'account/views/add-account.modal';
import InviteFriends from 'dashboard/components/invite-friends';
import useDashboardBody from 'dashboard/hooks/useDashboardBody';
import DashboardChart from 'dashboard/components/dashboard-chart';
import RecentActivity from 'dashboard/components/recent-activity';
import FeaturedPartner from 'dashboard/components/featured-partner';
import PortfolioMovers from 'dashboard/components/portfolio-movers';
import DashboardAccounts from 'dashboard/components/dashboard-accounts';

import GettingStarted from './getting-started';

const DashboardBody: React.FC = () => {
  const addAccountModal = useModal();
  const { loading, accountsCount } = useDashboardBody();

  const renderContent = () => {
    if (loading) {
      return null;
    }

    if (accountsCount <= 0) {
      return <GettingStarted onAddAccountClick={addAccountModal.open} />;
    }

    return (
      <div className='dashboard'>
        <DashboardChart />
        <PortfolioMovers />
        <DashboardAccounts onAddAccountClick={addAccountModal.open} />
        <Assets />
        <Liabilities />
        <RecentActivity />
        <InviteFriends />
        <FeaturedPartner />
      </div>
    );
  };

  return (
    <>
      {renderContent()}
      {addAccountModal.props.open && <AddAccountsModal addAccountModal={addAccountModal} />}
    </>
  );
};

export default DashboardBody;
