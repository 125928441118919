export enum EMMAccountType {
  CASH = 'Cash',
  FOREX = 'Forex',
  DEPOSITS = 'Deposits',
  INSURANCE = 'Insurance',
  BUSINESSES = 'Businesses',
  REAL_ESTATE = 'Real Estate',
  OTHER_ASSETS = 'Other Assets',
  CROWDFUNDING = 'Crowdfunding',
  DOMAIN_NAMES = 'Domain Names',
  STOCK_OPTIONS = 'Stock Options',
  PRECIOUS_METALS = 'Precious Metals',
  CRYPTOCURRENCIES = 'Cryptocurrencies',
  PRIVATE_EQUITY_FUNDS = 'Private Equity Funds',
}

export enum EMMAccountSubType {
  REAL_ESTATE_FUND = 'Real Estate Fund',
  PERSONAL_PROPERTY = 'Personal Property',
  RENTAL_PROPERTY = 'Rental Property',
}
