export const appRouteConstants = {
  home: '/',
  auth: {
    DEF: '/auth',
    ME: '/auth/me',
    LOGIN: '/login',
    SIGNUP: '/signup',
    LOGOUT: '/logout',
    RESET_PASSWORD: '/password_reset/:token?',
    TOKEN_EXPIRED: '/token-expired',
    CONNECT_ACCOUNT: '/connect-account',
    ACCOUNT_SETTING: '/account-settings',
    FORGOT_PASSWORD: '/forgot-password',
    NET_WORTH: '/net-worth',
  },
  allocation: {
    ALLOCATION: '/allocation',
  },
  settings: {
    SETTINGS: '/settings',
  },
  networth: {
    NET_WORTH: '/net-worth',
  },

  performance: {
    PERFORMANCE: '/performance',
  },

  community: {
    COMMUNITY: '/community',
    COMMUNITY_PROFILE: '/community/profile/edit',
  },

  discover: {
    DISCOVER: '/discover',
    DISCOVER_DETAIL: '/discover/discoverDetail',
  },

  web: {
    ABOUT: '/about',
    MEDIA: '/media',
    TERMS: '/terms',
    NOTICES: '/notices',
    PRIVACY: '/privacy',
    PRICING: '/pricing',
    SECURITY: '/security',
  },
  landing: {
    LANDING_NET_WORTH_CALCULATOR: '/net-worth-calculator',
  },
  features: {
    FEATURES: '/features',
    FEATURES_CRYPTOS: '/features/cryptos',
    FEATURES_NET_WORTH: '/features/net-worth',
    FEATURES_ALLOCATIONS: '/features/allocations',
    FEATURES_MULTICURRENCY: '/features/multicurrency',
    FEATURES_SYNCED_MANUAL: '/features/synced-and-manual',
    FEATURES_TRANSACTION_HISTORY: '/features/transaction-history',
  },
  misc: {
    NOT_FOUND: '/404',
    STRIPE_SUCCESS: '/stripe/success',
    STRIPE_FAILURE: '/stripe/cancel',
    UPDATE_PAYMENT_METHOD_SUCCESS: '/stripe/update-payment/success',
    UPDATE_PAYMENT_METHOD_CANCEL: '/stripe/update-payment/cancel',
  },
  account: {
    ACCOUNT: '/account-details/:accountId',
    ACCOUNT_TYPES: '/account-types/:type',
  },
  subscription: {
    SUBSCRIPTION: '/subscription',
    REVIEW: '/subscription/review',
  },
  refer: {
    REFER: '/refer',
    NOT_FOUND: '/refer/404',
  },
  qroka: {
    QROKA_COINBASE: '/qroka/coinbase',
    QROKA_GEMINI: '/qroka/gemini',
  },
  mmLink: {
    MM_LINK_COINBASE: '/mm-link/coinbase',
    MM_LINK_GEMINI: '/mm-link/gemini',
  },
  income: {
    INCOME: '/income',
  },
  dashboard: {
    DASHBOARD: '/dashboard',
  },
  yodlee: {
    INTEGRATION: '/yodlee-integration',
  },
};
