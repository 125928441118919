import { useState, useEffect } from 'react';

import { getFeaturedPartners } from 'api/request.api';
import { IFeaturedPartner } from 'dashboard/dashboard.types';

const useFeaturedPartner = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [featuredPartner, setFeaturedPartner] = useState<IFeaturedPartner | null>(null);

  useEffect(() => {
    const fetchFeaturedPartners = async () => {
      setLoading(true);

      const { data, error }: { data: IFeaturedPartner; error: any } = await getFeaturedPartners();

      if (!error && data) {
        setFeaturedPartner(data);
      }

      setLoading(false);
    };

    fetchFeaturedPartners();
  }, []);

  return { loading, featuredPartner };
};

export default useFeaturedPartner;
