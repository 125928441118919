import { Range } from 'react-input-range';
import { useState, useEffect } from 'react';

import { getAccountType } from 'api/request.api';

const useDiscoverFilter = (minimum: Range, targetReturns: Range) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [accountTypes, setAccountTypes] = useState<string[]>([]);

  const [minimumRange, setMinimumRange] = useState<Range>(minimum);
  const [targetReturnsRange, setTargetReturnsRange] = useState<Range>(targetReturns);

  useEffect(() => {
    const fetchAccountTypes = async () => {
      const { data, error } = await getAccountType();

      if (!error && data) {
        setAccountTypes(data);
      }
    };

    fetchAccountTypes();
  }, []);

  useEffect(() => {
    setMinimumRange(minimum);
  }, [minimum]);

  useEffect(() => {
    setTargetReturnsRange(targetReturns);
  }, [targetReturns]);

  return {
    searchTerm,
    setSearchTerm,
    accountTypes,
    minimumRange,
    setMinimumRange,
    targetReturnsRange,
    setTargetReturnsRange,
  };
};

export default useDiscoverFilter;
