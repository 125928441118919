import React, { useCallback } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import { ReactComponent as BackImg } from 'assets/icons/back.svg';
import { ReactComponent as TickImg } from 'assets/icons/tick.svg';
import { ReactComponent as CheckImg } from 'assets/icons/check.svg';
import { ReactComponent as LoginLockIcon } from 'assets/images/login/lock-icon.svg';
import { ReactComponent as ManualAccountImg } from 'assets/icons/mm-default-provider.svg';

interface IAccountOptions {
  name: string;
  isConnected: boolean;
  isManual: boolean;
}

const options: IAccountOptions[] = [
  { name: 'See accounts in net worth, allocation and more', isConnected: true, isManual: true },
  { name: 'International accounts, crypto and multi currency supported', isConnected: true, isManual: true },
  { name: 'Full control to change balances, activities and holdings', isConnected: false, isManual: true },
  { name: 'Balances, activites and holdings automatically updated', isConnected: true, isManual: false },
  { name: 'Add crypto by coin and precious metals by weight', isConnected: false, isManual: true },
];

interface IAccountOptionsProps {
  onBack: VoidFunction;
  onClose: VoidFunction;
}

const AccountOptions: React.FC<IAccountOptionsProps> = ({ onBack, onClose }) => {
  const renderOptions = useCallback(() => {
    return options.map(({ name, isConnected, isManual }) => (
      <li className='account-options__item' key={name}>
        <span>{name}</span>
        <div className='account-options__item-check'>{isConnected && <TickImg />}</div>
        <div className='account-options__item-check'>{isManual && <TickImg />}</div>
      </li>
    ));
  }, []);

  return (
    <div className='account-options app-modal'>
      <div className='app-modal__header account-options__header'>
        <div className='d-flex align-items-center mt-2'>
          <BackImg role='button' onClick={() => onBack()} />
          <h2 className='app-modal__title'>Account Options</h2>
        </div>

        <button onClick={() => {}}>
          <AiOutlineClose className='modal-close' onClick={() => onClose()} />
        </button>
      </div>

      <p className='account-options__description'>
        We use third parties to manage your credentials for connected accounts. Money Minx never has access to your
        password.
      </p>

      <div className='d-flex justify-content-end'>
        <div className='d-flex flex-column align-items-center account-options__col'>
          <CheckImg />
          <span className='connected-label'>Connected</span>
        </div>
        <div className='d-flex flex-column align-items-center account-options__col'>
          <ManualAccountImg className='manual-img' />
          <span>Manual</span>
        </div>
      </div>

      <ul className='account-options__items'>{renderOptions()}</ul>

      <div className='d-flex account-options__footer'>
        <span className='locked-icon hide-sm'>
          <LoginLockIcon />
        </span>

        <p>
          The security of your information is our top priority (
          <a className='purple-links' href='/security' target='_blank'>
            learn more
          </a>
          )
        </p>
      </div>
    </div>
  );
};

export default AccountOptions;
