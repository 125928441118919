import { useEffect, useRef } from 'react';

import { storage } from 'app/app.storage';
import { StorageKey } from 'app/app.types';
import { ILoginResponse } from 'auth/auth.types';
import { useAuthDispatch, useAuthState } from 'auth/auth.context';
import { setLoginFailure, setLoginSuccess } from 'auth/auth.actions';

import useProfile from './useProfile';

const useTokenValidation = () => {
  const { loading, response } = useProfile();
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { data, error } = storage.get(StorageKey.AUTH);

  const profileError = response?.error;

  const hasStorageData = !error && !!data;
  const tokenRef = useRef(data);

  useEffect(() => {
    if (token) {
      return;
    }

    if (profileError) {
      return dispatch(setLoginFailure());
    }

    if (hasStorageData) {
      dispatch(setLoginSuccess(tokenRef.current as ILoginResponse));
    }
  }, [dispatch, profileError, hasStorageData, token]);

  return { loading, hasStorageData };
};

export default useTokenValidation;
