import React from 'react';

import env from 'app/app.env';
import { createForum } from '@peerboard/core';
import { useAuthState } from 'auth/auth.context';
import { getPeerboardToken } from 'api/request.api';

const boardID = +env.PEERBOARD_BOARD_ID;
const pathPrefix = '/community';

const usePeerboard = (ref?: React.MutableRefObject<HTMLElement>) => {
  const [error, setError] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(true);
  const { isAuthenticated } = useAuthState();

  const options: any = {
    prefix: pathPrefix,
    minHeight: window.innerHeight,

    onTitleChanged: (title: string) => (window.document.title = title),

    onReady: () => setLoading(false),

    onFail: () => {
      setError('Community failed to load. Please contact us for help.');
      setLoading(false);
    },
  };
  const createPeerBoard = async () => {
    if (isAuthenticated) {
      const { data } = await getPeerboardToken();
      let boardOptions = options;

      if (data) {
        boardOptions = {
          ...options,
          jwtToken: data.token,
        };
      }

      if (ref?.current) {
        await createForum(boardID, ref.current, boardOptions);
      }
    }
  };

  const createPeerboardAnonymously = async () => {
    const peerBoardOptions = {
      ...options,
      anon: true,
    };

    if (ref?.current) {
      await createForum(boardID, ref.current, peerBoardOptions);
    }
  };

  return { createPeerBoard, error, loading, createPeerboardAnonymously, setError };
};

export default usePeerboard;
