import React from 'react';

const InviteSteps: React.FC = () => {
  return (
    <div className='invite-steps'>
      <div className='invite-steps__step'>
        <div className='invite-steps__step-head'>
          <span className='invite-steps__step-number'>1</span>
          <span className='invite-steps__step-title'>Invite friends</span>
        </div>

        <p className='invite-steps__step-description'>
          Share your referral link and get friends to sign up for Money Minx.
        </p>
      </div>

      <div className='invite-steps__step'>
        <div className='invite-steps__step-head'>
          <span className='invite-steps__step-number'>2</span>
          <span className='invite-steps__step-title'>Earn $10</span>
        </div>

        <p className='invite-steps__step-description'>
          Once your friends sign up and pay for their first month membership, we will send you a $10 Amazon Gift Card.
        </p>
      </div>

      <div className='invite-steps__step'>
        <div className='invite-steps__step-head'>
          <span className='invite-steps__step-number'>3</span>
          <span className='invite-steps__step-title'>Track referrals</span>
        </div>

        <p className='invite-steps__step-description'>
          Remind your friends to sign up so you don’t miss out on getting a reward.
        </p>
      </div>
    </div>
  );
};

export default InviteSteps;
