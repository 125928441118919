import { useEffect, useMemo, useState } from 'react';

import useProfile from 'auth/hooks/useProfile';
import { useAuthState } from 'auth/auth.context';
import { IReferredUser } from 'refer/refer.types';
import { getReferredUsers } from 'api/request.api';

import * as referConstants from '../refer.constants';

const useRefer = (invites: string[]) => {
  useProfile();

  const { user } = useAuthState();
  const [loading, setLoading] = useState<boolean>(false);
  const [referralLink, setReferralLink] = useState<string>('');
  const [referredUsers, setReferredUsers] = useState<IReferredUser[]>([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await getReferredUsers();

      if (data && data.length) {
        setReferredUsers(data);
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }

    const { referralName, referralLevel } = user;

    if (!!referralName && !!referralLevel) {
      const link = `${referConstants.BASE_REFERRAL_LINK}?r=${referralName}`;

      setReferralLink(link);
    }
  }, [user]);

  const inviteLink = useMemo(() => {
    if (!user) {
      return '';
    }

    const { firstName } = user;

    const emailAddresses = invites.join(', ');

    return referConstants.INVITATION_LINK.replace(':EMAIL_ADDRESSES', emailAddresses)
      .replace(':REFERRAL_LINK', referralLink)
      .replace(':FIRST_NAME', firstName);
  }, [invites, user, referralLink]);

  return { loading, referralLink, inviteLink, referredUsers };
};

export default useRefer;
