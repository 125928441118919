import React from 'react';

import { events } from '@mm/data/event-list';
import GALink from 'common/components/ga-link';
import { ReactComponent as GettingStartedSteps } from 'assets/images/signup/getting-started-steps.svg';

interface IGeetingStartedProps {
  onAddClick?: VoidFunction;
}

const GettingStarted: React.FC<IGeetingStartedProps> = ({ onAddClick }) => {
  const handleAddClick = () => {
    if (onAddClick) {
      onAddClick();
    }
  };

  return (
    <section className='getting-started-section'>
      <div className='content-wrapper'>
        <div className='getting-started'>
          <div className='card getting-started-card'>
            <span className='getting-started-title'>No Accounts Yet</span>
            <span className='getting-started-subtitle'>Add your first account now. It's super easy.</span>
            <span className='list-title'>
              <span role='img' aria-label='party'>
                🎉
              </span>{' '}
              Getting Started
            </span>
            <div className='getting-started-list'>
              <GettingStartedSteps />
              <div>
                <span className='completed-step'>Create your Money Minx account</span>
                <div className='steps-with-button'>
                  <span className='current-step'>Add your first asset</span>
                  <GALink
                    to='#'
                    eventArgs={{
                      ...events.connectFromGettingStarted,
                    }}
                    onClick={() => handleAddClick()}
                  >
                    <button className='mm-btn-primary mm-btn-animate m-t-4'>Add</button>
                  </GALink>
                </div>
                <span className='next-step m-b-8'>Connect your first account</span>
                <span className='next-step m-t-9'>Watch your money grow</span>
              </div>
            </div>
            <div className='getting-started-button'>
              <GALink
                to='#'
                eventArgs={{
                  ...events.connectFromGettingStarted,
                }}
                onClick={() => handleAddClick()}
              >
                <button className='mm-btn-primary mm-btn-animate'>Add an account</button>
              </GALink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GettingStarted;
