import React from 'react';

import { Formik } from 'formik';
import useQroka from 'auth/hooks/useQroka';
import ICONS from 'common/components/icons';
import { ECryptoConnections } from 'auth/auth.enum';
import WalletCard from 'auth/views/inc/wallet-card';
import TextInput from 'common/components/input/text.input';
import { cryptoConnections } from 'auth/data/crypto-connections';

interface IConnectVinovest {
  onCancel: () => void;
  handleSuccess: () => void;
}

const ConnectVinovest: React.FC<IConnectVinovest> = ({ handleSuccess, onCancel }) => {
  const { connectVinovest, loading, errors } = useQroka();
  const connection = cryptoConnections.find((c) => c.type === ECryptoConnections.VINOVEST);

  if (!connection) {
    return <div> Connection not found </div>;
  }
  return (
    <div className='connect-crypto-wrapper'>
      <WalletCard
        disabled
        contain
        name={connection.name}
        url={connection.url}
        logo={connection.logo}
        connection={connection.type}
      />

      <div className='key-info'>Please enter username and password to connect to your Vinovest account.</div>

      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          await connectVinovest(values.username, values.password)(handleSuccess);
          actions.setSubmitting(false);
        }}
      >
        {(props) => {
          const isSubmitDisabled = props.isSubmitting || !props.isValid;
          return (
            <div className='binance-form-wrapper'>
              <form onSubmit={props.handleSubmit}>
                <TextInput
                  required
                  type='text'
                  name='username'
                  disabled={props.isSubmitting}
                  handleChange={props.handleChange}
                  handleOnBlur={props.handleBlur}
                  title='Vinovest Username'
                  value={props.values.username}
                  error={!!props.errors.username}
                  placeholder='Vinovest Username'
                  helperText={props.errors.username}
                />
                <TextInput
                  required
                  type='password'
                  name='password'
                  autoComplete='new-password'
                  handleChange={props.handleChange}
                  handleOnBlur={props.handleBlur}
                  addOn={<ICONS.LOCK_NO_CIRCLE />}
                  title='Vinovest password'
                  value={props.values.password}
                  error={!!props.errors.password}
                  placeholder='Vinovest password'
                  helperText={props.errors.password}
                  tooltipMessage='Your address is safely stored in an encrypted database and then encrypted again for added security.'
                />
                {errors.Vinovest && <div className='error-block'>{errors.Vinovest}</div>}
                <div className='crypto-action-wrapper'>
                  <button
                    type='reset'
                    disabled={props.isSubmitting}
                    onClick={onCancel}
                    className='btn-outline-primary mm-btn-animate'
                  >
                    Cancel
                  </button>

                  <button className='mm-btn-animate mm-btn-primary ' type='submit' disabled={isSubmitDisabled}>
                    {loading ? (
                      <>
                        <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
                        <span className='ml-1'>Connecting...</span>
                      </>
                    ) : (
                      <> Connect Account</>
                    )}
                  </button>
                </div>
              </form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default ConnectVinovest;
