import React from 'react';

import useQroka from 'auth/hooks/useQroka';
import WalletCard from 'auth/views/inc/wallet-card';
import { ECryptoConnections } from 'auth/auth.enum';
import useTrustWallet from 'auth/hooks/useTrustWallet';
import { cryptoConnections } from 'auth/data/crypto-connections';

interface IConnectTrustWallet {
  onCancel: () => void;
  handleSuccess: () => void;
}

const ConnectTrustWallet: React.FC<IConnectTrustWallet> = (props) => {
  const { onConnect, disconnect, accounts, error } = useTrustWallet();
  const { connectTrustWallet, loading, errors } = useQroka();
  const connection = cryptoConnections.find((con) => con.type === ECryptoConnections.TRUST_WALLET);

  if (!connection) {
    return <div>Connection not available</div>;
  }

  const handleCoinbaseConnect = async () => {
    await connectTrustWallet(accounts)(props.handleSuccess);
  };

  const renderSupportText = () => {
    return (
      <div className='key-info'>
        Click on Connect Wallet to get started. Open the Trust Wallet app, then settings, then WalletConnect to scan the
        QR code.
      </div>
    );
  };

  return (
    <div className='connect-crypto-wrapper'>
      <WalletCard
        disabled
        name={connection.name}
        url={connection.url}
        logo={connection.logo}
        connection={connection.type}
      />
      {renderSupportText()}
      <div className='connect-button-wrapper multiple'>
        <button disabled={loading} onClick={onConnect} className='mm-btn-primary mm-btn-animate'>
          Connect Wallet
        </button>
        {accounts?.length > 0 && (
          <button disabled={loading} onClick={disconnect} className='btn-outline-primary mm-btn-animate'>
            Disconnect
          </button>
        )}
      </div>
      <div className='chain-accounts-wrapper'>
        {accounts?.map((acc) => (
          <div key={acc.address}>
            <div className='chain-title'>{acc.addressType}</div>
            <div className='accounts-wrapper'>
              <p>{acc.address}</p>
            </div>
          </div>
        ))}
      </div>
      {error && <div className='error-block'>{error}</div>}
      {errors['Coinbase Wallet'] && <div className='error-block'>{errors['Coinbase Wallet']}</div>}
      <div className='crypto-action-wrapper'>
        <button type='reset' className='btn-outline-primary mm-btn-animate' disabled={loading} onClick={props.onCancel}>
          Cancel
        </button>

        <button
          type='submit'
          className='mm-btn-animate mm-btn-primary '
          disabled={loading}
          onClick={handleCoinbaseConnect}
        >
          {loading ? (
            <>
              <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
              <span className='ml-1'>Adding...</span>
            </>
          ) : (
            <>Add Accounts</>
          )}
        </button>
      </div>
    </div>
  );
};

export default ConnectTrustWallet;
