import React from 'react';

import { events } from '@mm/data/event-list';
import GALink from 'common/components/ga-link';
import { GistEvents } from '@mm/data/gist-events';
import useGistEvents from 'common/hooks/useGistEvents';
import { getCurrencySymbol } from 'common/currency-helper';
import { IInvestmentOpportunity } from 'discover/discover.types';
import { fNumber, numberWithCommas } from 'common/number.helper';

import OpportunityRisk from './opportunity-risk';

interface IOpportunityProps {
  opportunity: IInvestmentOpportunity;
}

const Opportunity: React.FC<IOpportunityProps> = ({ opportunity }) => {
  const gistEvents = useGistEvents();

  const {
    logo,
    company,
    type,
    description,
    minimum,
    minimumCurrency,
    targetReturnsVisible,
    returnFrequency,
    fees,
    liquidity,
    returnType,
    investorType,
    risk,
    url,
    review,
    insight,
    insightBy,
    insightAvatar,
  } = opportunity;

  const currencySymbol = getCurrencySymbol(minimumCurrency);

  return (
    <div className='opportunity'>
      <div className='opportunity-header-container'>
        <div className='opportunity__header'>
          <img src={logo} alt={company} />

          <div>
            <h6 className='opportunity__company'>
              <GALink
                to={{ pathname: url }}
                className='opportunity__company'
                target='_blank'
                rel='noopener noreferrer'
                eventArgs={events.discoverReview}
                onClick={() => gistEvents.track(GistEvents.DISCOVER_REVIEW, { url })}
              >
                {company}
              </GALink>
            </h6>

            <span className='opportunity__type'>{type}</span>
          </div>
        </div>

        <p className='opportunity__description'>{description}</p>
      </div>

      <div className='d-flex justify-content-between opportunity__info-wrapper'>
        <div className='opportunity__info'>
          <span className='opportunity__info-label'>Minimum</span>
          <span className='opportunity__info-value'>
            {currencySymbol} {numberWithCommas(fNumber(minimum, 0))}
          </span>
        </div>

        <div className='opportunity__info'>
          <span className='opportunity__info-label'>Returns</span>
          <span className='opportunity__info-value'>{targetReturnsVisible}</span>
        </div>

        <div className='opportunity__info'>
          <span className='opportunity__info-label'>Frequency</span>
          <span className='opportunity__info-value'>{returnFrequency}</span>
        </div>

        <div className='opportunity__info'>
          <span className='opportunity__info-label'>Fee</span>
          <span className='opportunity__info-value'>{fees}</span>
        </div>

        <div className='opportunity__info'>
          <span className='opportunity__info-label'>Liquidity</span>
          <span className='opportunity__info-value'>{liquidity}</span>
        </div>

        <div className='opportunity__info'>
          <span className='opportunity__info-label'>Risk</span>
          <span className='opportunity__info-value'>
            <OpportunityRisk risk={risk} />
          </span>
        </div>
      </div>

      <div className='opportunity__labels mb-2'>
        <div className='opportunity__info opportunity__label'>
          <span className='return-type'>{returnType}</span>
        </div>

        <div className='opportunity__info opportunity__label'>
          <span className='investor-type'>{investorType}</span>
        </div>
      </div>

      <div className='opportunity__actions'>
        {review && (
          <div className='opportunity__action'>
            <GALink
              to={{ pathname: review }}
              className='btn-outline-primary mm-btn-animate'
              target='_blank'
              rel='noopener noreferrer'
              eventArgs={events.discoverReview}
              onClick={() => gistEvents.track(GistEvents.DISCOVER_REVIEW, { review })}
            >
              {company} Review
            </GALink>
          </div>
        )}

        <div className='opportunity__action'>
          <GALink
            to={{ pathname: url }}
            className='mm-btn-animate mm-btn-primary'
            target='_blank'
            rel='noopener noreferrer'
            eventArgs={events.discoverLink}
            onClick={() => gistEvents.track(GistEvents.DISCOVER_LINK, { url })}
          >
            Visit Site
          </GALink>
        </div>
      </div>

      {insight && insightBy && insightAvatar && (
        <div className='opportunity__insight'>
          <img src={insightAvatar} alt={insightBy} />

          <p className='opportunity__insight-text'>{insight}</p>
        </div>
      )}
    </div>
  );
};

export default Opportunity;
