import { useState, useEffect } from 'react';

import { getAccountsCount } from 'api/request.api';

const useDashboardBody = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [accountsCount, setAccountsCount] = useState<number>(0);

  useEffect(() => {
    const getAccountCount = async () => {
      setLoading(true);
      const { data, error } = await getAccountsCount();

      if (!error && data?.totalAccounts) {
        setAccountsCount(data.totalAccounts);
      }

      setLoading(false);
    };

    getAccountCount();
  }, []);

  return { loading, accountsCount };
};

export default useDashboardBody;
