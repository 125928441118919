import { useState, useEffect } from 'react';

import useToast from 'common/hooks/useToast';
import { getPreciousMetals } from 'api/request.api';
import { IPreciousMetal } from 'account/account.type';

const useMetalsData = () => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [preciousMetals, setPreciousMetals] = useState<IPreciousMetal[]>([]);

  const { toastRef } = useToast();

  useEffect(() => {
    (async () => {
      try {
        setIsFetching(true);
        const { error, data } = await getPreciousMetals();

        if (!error) {
          setPreciousMetals(data);
        }
      } catch (err) {
        toastRef.current('Error fetching the precious metals list', { type: 'error' });
      } finally {
        setIsFetching(false);
      }
    })();
  }, [toastRef]);

  return { isFetching, preciousMetals };
};

export default useMetalsData;
