import React from 'react';

import { IReferredUser, IReferredUserProps } from 'refer/refer.types';

import ReferredUsersSkeleton from './referred-users-skeleton';

const ReferredUsers: React.FC<IReferredUserProps> = ({ loading, users }) => {
  const getReferredUserStatus = (user: IReferredUser) => {
    const { accountAdded, paidSubscription } = user;

    if (accountAdded && !paidSubscription) {
      return 'On trial, actively using Money Minx.';
    }

    if (!accountAdded && !paidSubscription) {
      return 'On trial, not yet active.';
    }

    if (paidSubscription) {
      return 'Signed up, congratulations you will get a reward.';
    }
  };

  if (loading) {
    return <ReferredUsersSkeleton />;
  }

  return (
    <div className='referred-users'>
      <h4>Invites</h4>

      {!users?.length && <span>None of your referrals signed up yet.</span>}

      {!!users &&
        users.map((user, index) => (
          <div className='referred-users__user' key={index}>
            <span className='referred-users__user-name'>{user.firstName || 'Referral'}</span>
            <span className='referred-users__user-status'>{getReferredUserStatus(user)}</span>
          </div>
        ))}
    </div>
  );
};

export default ReferredUsers;
