import { useEffect } from 'react';

import appEnv from 'app/app.env';

const GIST_CHAT_SELECTOR = '.gist-open-chat';

const useGist = () => {
  useEffect(() => {
    (function (d, h, w) {
      const gist = (w.gist = w.gist || []);
      gist.methods = ['trackPageView', 'identify', 'track', 'setAppId'];
      gist.factory = function (t: any) {
        return function () {
          const e = Array.prototype.slice.call(arguments);

          e.unshift(t);

          if (gist.push) {
            gist.push(e);
          }

          return gist;
        };
      };

      for (let i = 0; i < gist.methods.length; i++) {
        const c = gist.methods[i];
        gist[c] = gist.factory(c);
      }

      const s = d.createElement('script');
      s.src = 'https://widget.getgist.com';
      s.async = true;

      const e = d.getElementsByTagName(h)[0];
      e.appendChild(s);
      s.addEventListener('load', function (e) {}, !1);

      gist.setAppId(appEnv.GIST_APP_ID);
      gist.trackPageView();
    })(document, 'head', window);

    (() => {
      /* Add this class to any elements you want to use to open Gist chat
       *
       * Examples:
       * - <a class="gist-open-chat">Questions? We're here to help!</a>
       * - <button class="gist-open-chat">Chat now!</button>
       *
       */

      /* http://youmightnotneedjquery.com/#ready */
      function ready(fn: any) {
        if (document.readyState !== 'loading') {
          fn();
        } else if (document.addEventListener) {
          document.addEventListener('DOMContentLoaded', fn);
        } else {
          (document as any).attachEvent('onreadystatechange', function () {
            if (document.readyState !== 'loading') fn();
          });
        }
      }

      function forEachElement(selector: any, fn: any) {
        const elements = document.querySelectorAll(selector);
        for (let i = 0; i < elements.length; i++) fn(elements[i], i);
      }

      /* Code to trigger the chat when clicking a link or a button */
      ready(function () {
        document.addEventListener('gistChatReady', function () {
          forEachElement(GIST_CHAT_SELECTOR, function (element: any) {
            element.addEventListener('click', function (event: any) {
              event.preventDefault();
              window.gist.chat('navigate', 'newConversation');
              return false;
            });
          });
        });
      });
    })();
  }, []);
};

export default useGist;
