import React, { useEffect, useState } from 'react';

import moment from 'moment';
import appEnv from 'app/app.env';
import Message from 'auth/views/inc/message';
import useToast from 'common/hooks/useToast';
import { events } from '@mm/data/event-list';
import classNames from 'common/classes.helper';
import { loadStripe } from '@stripe/stripe-js';
import GALink from 'common/components/ga-link';
import { useModal } from 'common/components/modal';
import useAnalytics from 'common/hooks/useAnalytics';
import useSubscriptions from 'auth/hooks/useSubscriptions';
import usePixel, { EPixelTrack } from 'common/hooks/usePixel';
import { pricingDetailConstant } from 'common/common.constant';
import FreePlanFeatures from 'auth/views/inc/free-plan-features';
import { Plan, CurrentSubscription } from 'setting/setting.type';
import useCurrentSubscription from 'auth/hooks/useCurrentSubscription';
import CircularSpinner from 'common/components/spinner/circular-spinner';
import SubscriptionCancelModal from 'setting/inc/subscription-cancel.modal';
import { postSubscriptionCheckout, patchCancelSubscription } from 'api/request.api';
import { ReactComponent as PricingTickIcon } from 'assets/images/pricing/tick-icon.svg';
import { EPlanDuration, EPlanType, StripeSubscriptionStatus } from 'setting/setting.enum';

const stripePromise = loadStripe(appEnv.STRIPE_PUBLIC_KEY);

export const PlanOverview: React.FC = () => {
  const { event } = useAnalytics();
  const { mmToast } = useToast();
  const { fbq } = usePixel();
  const [type, setType] = useState<EPlanDuration>(EPlanDuration.MONTHLY);
  const subscriptionCancelModal = useModal();

  const [cancelSubscriptionError, setCancelSubscriptionError] = useState<boolean>(false);
  const [cancelSubscriptionResponse, setCancelSubscriptionResponse] = useState<CurrentSubscription>();

  const cancelAtDate = cancelSubscriptionResponse?.cancelAt ? cancelSubscriptionResponse?.cancelAt / 86400 : 0;

  const { loading: fetchingSubscription, error: subError, subscriptions } = useSubscriptions();
  const { fetchingCurrentSubscription, currentSubError, currentSubscription } = useCurrentSubscription();

  const loading = fetchingCurrentSubscription || fetchingSubscription || !subscriptions || subError || currentSubError;

  const isTrialing = currentSubscription?.subscriptionStatus === StripeSubscriptionStatus.TRIALING;
  const isActiveSubscription = currentSubscription?.subscriptionStatus === StripeSubscriptionStatus.ACTIVE;
  const isTrialEnded =
    currentSubscription?.subscriptionStatus === StripeSubscriptionStatus.CANCELED ||
    currentSubscription?.subscriptionStatus === StripeSubscriptionStatus.PAST_DUE;

  useEffect(() => {
    if (currentSubscription && subscriptions) {
      const currentType = subscriptions.find((sub: any) => sub.priceId === currentSubscription.priceId)?.duration;

      const planType = currentType === 'month' ? EPlanDuration.MONTHLY : EPlanDuration.YEARLY;

      if (currentType) {
        setType(planType);
      }
    }
  }, [currentSubscription, subscriptions]);

  if (loading) {
    return <CircularSpinner />;
  }

  const isCurrentPlan = (priceId: string) => currentSubscription?.priceId === priceId;
  const ac = (priceId: string) => {
    if (!isTrialing && !isTrialEnded && isCurrentPlan(priceId)) {
      return 'mm-plan-overview__plan-btn--current';
    }
  };

  const changePlan = async (plan: Plan) => {
    const priceId = plan.priceId;

    if (!priceId) {
      return mmToast('Price Id not found', { type: 'error' });
    }

    const stripe = await stripePromise;

    event({
      category: 'Subscription',
      action: 'Initiate Stripe Checkout',
      label: `Checkout for ${plan.name || ''} plan`,
      value: plan.price,
    });

    fbq(EPixelTrack.INITIATE_CHECKOUT, {
      currency: 'USD',
      value: plan.price,
      content_category: plan.name,
      content_ids: priceId,
    });

    const payload = {
      subscriptionPriceId: priceId,
      value: plan.price,
    };

    const { data, error } = await postSubscriptionCheckout(payload);
    if (error) {
      return mmToast('Can not stripe checkout id', { type: 'error' });
    }

    const checkoutId = data?.checkoutId;
    if (checkoutId && stripe) {
      const result = await stripe.redirectToCheckout({
        sessionId: checkoutId,
      });

      if (result.error) {
        return mmToast('Something went wrong with Stripe', { type: 'error' });
      }
    }
  };

  const monthlyPricingList = subscriptions?.filter(
    (sub: any) => sub.duration === EPlanDuration.MONTHLY && sub.active === true && sub.name === EPlanType.PRO
  );
  const annualPricingList = subscriptions?.filter(
    (sub: any) => sub.duration === EPlanDuration.YEARLY && sub.active === true && sub.name === EPlanType.PRO
  );

  const planBtnClasses = 'mm-btn-animate trial-btn ml-3 btn-xs-block';

  const pricingList = type === EPlanDuration.MONTHLY ? monthlyPricingList : annualPricingList;

  // Cancel Subscription
  const handleCancelSubscription = () => {
    return subscriptionCancelModal.open();
  };

  const handleCancelSubscriptionConfirmation = async () => {
    const { error: patchError, data: response } = await patchCancelSubscription();
    if (!patchError) {
      setCancelSubscriptionResponse(response);
    } else {
      setCancelSubscriptionError(true);
    }
    return subscriptionCancelModal.close();
  };

  const handleDismiss = () => {};

  return (
    <>
      <section className='mm-plan-overview my-4'>
        <div className='row mm-plan-overview__switch text-center pt-4 pb-4'>
          <div className='mm-plan-radios m-auto'>
            <input
              type='radio'
              id='mm-plan-month'
              value='monthly'
              name='mm-radio-time-interval'
              checked={type === EPlanDuration.MONTHLY}
              aria-checked={type === EPlanDuration.MONTHLY}
            />
            <label
              className='labels'
              htmlFor='mm-plan-month'
              onClick={() => setType(EPlanDuration.MONTHLY)}
              role='button'
            >
              Monthly
            </label>
            <input
              type='radio'
              id='mm-plan-year'
              value='annually'
              name='mm-radio-time-interval'
              checked={type === EPlanDuration.YEARLY}
              aria-checked={type === EPlanDuration.YEARLY}
            />
            <label
              className='labels'
              htmlFor='mm-plan-year'
              onClick={() => setType(EPlanDuration.YEARLY)}
              role='button'
            >
              Annually
            </label>
            <span className='save-text' />
            <div className='mm-radio-bg' />
          </div>
        </div>

        <div className='container-fluid'>
          <div className='row'>
            <div className='pricing-table-wrapper'>
              <div className='price-table'>
                <div className='price-heading'>
                  <span className='price-plan-name'>Free</span>
                  <p>{type === EPlanDuration.YEARLY ? `$0/Year` : `$0/Month`}</p>
                </div>

                <FreePlanFeatures />

                <GALink
                  to='#'
                  eventArgs={{
                    ...events.trialFromPricing,
                    action: `Clicked on start free plan`,
                    value: 0,
                  }}
                  onClick={handleCancelSubscription}
                >
                  <button
                    className={classNames(
                      'mm-btn-animate trial-btn ml-3 btn-xs-block',
                      isTrialEnded && 'mm-plan-overview__plan-btn--current'
                    )}
                  >
                    {isTrialing
                      ? 'Choose Free'
                      : isActiveSubscription
                      ? 'Downgrade'
                      : isTrialEnded
                      ? 'Current Plan'
                      : ''}
                  </button>
                </GALink>
              </div>
              {pricingList?.map((pt: Plan, index: number) => {
                return (
                  <div className='price-table' key={index}>
                    <div className='price-heading'>
                      <span className='price-plan-name'>{pt.name}</span>
                      <p>
                        {type === EPlanDuration.YEARLY ? `$${pt.price}/Year` : `$${pt.price}/Month`}
                        {type === EPlanDuration.YEARLY ? (
                          <span className='save-percentage'>Save ${pt.save}</span>
                        ) : null}
                      </p>
                    </div>
                    <ul className='features-list p-b-12'>
                      <li>
                        <div className='tick-icon'>
                          <PricingTickIcon />
                        </div>
                        Everything in free
                      </li>
                      <li>
                        <div className='tick-icon'>
                          <PricingTickIcon />
                        </div>
                        {'Current and '}
                        {pt.details[pricingDetailConstant.ALLOCATION_CHART_HISTORY] === 'Unlimited'
                          ? 'historical '
                          : `last ${pt.details[pricingDetailConstant.ALLOCATION_CHART_HISTORY]} months `}
                        asset allocation charts
                      </li>
                      <li>
                        <div className='tick-icon'>
                          <PricingTickIcon />
                        </div>
                        {pt.details[pricingDetailConstant.INVESTING_ENTITY] === '1'
                          ? '1 investing entity'
                          : pt.details[pricingDetailConstant.INVESTING_ENTITY] + ' investing entities'}
                      </li>
                      <li>
                        <div className='tick-icon'>
                          <PricingTickIcon />
                        </div>
                        {pt.details[pricingDetailConstant.CURRENCY] === 'USD'
                          ? 'USD support only '
                          : `Choose from over 130 supported currencies `}
                      </li>
                      {pt.details[pricingDetailConstant.COMPARE_ALLOCATION] === 'Yes' ? (
                        <li>
                          <div className='tick-icon'>
                            <PricingTickIcon />
                          </div>
                          Compare your net worth and asset allocation to others
                        </li>
                      ) : null}
                      <li>
                        <div className='tick-icon'>
                          <PricingTickIcon />
                        </div>
                        Get tips and insights with Money Minx AI
                      </li>
                      <li>
                        <div className='tick-icon'>
                          <PricingTickIcon />
                        </div>
                        Manage real estate leases, mortgages and expense
                      </li>
                      <li>
                        <div className='tick-icon'>
                          <PricingTickIcon />
                        </div>
                        Community {pt.details[pricingDetailConstant.NAME]} room access and badge
                      </li>
                    </ul>
                    <button
                      type='button'
                      disabled={isActiveSubscription}
                      className={classNames(
                        `${planBtnClasses} ${ac(pt.priceId)}`,
                        isActiveSubscription ? 'mm-plan-overview__plan-btn--current' : null
                      )}
                      onClick={() => changePlan(pt)}
                    >
                      {isActiveSubscription ? 'Current Plan' : 'Go Pro!'}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      <SubscriptionCancelModal
        subscriptionCancelModal={subscriptionCancelModal}
        subscriptionEnd={currentSubscription?.subscriptionEnd}
        handleCancelSubscriptionConfirmation={handleCancelSubscriptionConfirmation}
      />

      {cancelSubscriptionError || cancelSubscriptionResponse ? (
        <div className='subscription-cancel-confirmation'>
          <Message
            type={cancelSubscriptionError ? 'error' : 'success'}
            message={
              cancelSubscriptionError
                ? 'Your subscription could not be canceled. Please contact us for support.'
                : `Your subscription is now canceled. You can continue using Money Minx until ${moment(
                    '1970-01-01T00:00:00Z'
                  )
                    .add(cancelAtDate, 'days')
                    .format('MM/DD/YY')}.`
            }
            onDismiss={handleDismiss}
          />
        </div>
      ) : null}
    </>
  );
};

export default PlanOverview;
