/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';

import { parse } from 'common/common-helper';
import { logger } from 'common/logger.helper';
import { Modal, ModalTypeEnum } from 'common/components/modal';

import { ISaltEdgeResponse } from './account-connection-card';

interface Props {
  saltEdgeModal: any;
  handleSuccess: () => void;
  saltEdge: ISaltEdgeResponse;
}

const SaltEdgeModal: React.FC<Props> = ({ saltEdgeModal, saltEdge, handleSuccess }) => {
  useEffect(() => {
    const handlePostMessage = (event: MessageEvent<any>) => {
      if ('https://www.saltedge.com' !== event.origin) {
        return event.origin;
      }

      const possibleCancelEvents = [`cancel`, `"cancel"`, `'cancel'`];

      logger.log('Event', event);

      if (possibleCancelEvents.includes(event.data)) {
        return saltEdgeModal.close();
      }

      const parsedEvent = parse(event.data);
      const saltEdgeData = parsedEvent?.data?.data;
      const isRefreshing = 'success' === saltEdgeData?.stage;
      const isClosing = 'finish' === saltEdgeData?.api_stage || 'disconnect' === saltEdgeData?.api_state;

      if (isRefreshing) {
        handleSuccess();
      }

      if (isClosing) {
        saltEdgeModal.close();
      }
    };

    window.addEventListener('message', handlePostMessage, false);

    return () => window.removeEventListener('message', handlePostMessage);
  }, []);

  return (
    <Modal
      {...saltEdgeModal.props}
      title=''
      size='saltedge'
      canBeClosed
      type={ModalTypeEnum.NO_HEADER}
      outsideClickClose={true}
    >
      <div className='saltedge-modal-wrapper'>
        <iframe src={saltEdge.connectUrl} title={saltEdge.connectUrl} />
      </div>
    </Modal>
  );
};

export default SaltEdgeModal;
