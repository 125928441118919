import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import WebsiteLayout from 'website/website.layout';
import { appRouteConstants } from 'app/app-route.constant';
import { ReactComponent as HomeBannerImg } from 'assets/images/home/home-banner-img.svg';
import { ReactComponent as SyncedOrManual } from 'assets/images/home/synced-or-manual.svg';
import { ReactComponent as HomeNetWorthChart } from 'assets/images/features/net-worth-calculator.svg';
import { ReactComponent as HomeMulticurrency } from 'assets/images/features/multicurrency.svg';
import { ReactComponent as HomeAllocationChart } from 'assets/images/home/allocation-pie-chart.svg';
import { ReactComponent as InvestmentsAndCryptos} from 'assets/images/features/investment-assets-cryptos.svg';
import { ReactComponent as HomeTransactionHistory } from 'assets/images/features/transaction-history.svg';

const Features = () => {
  return (
    <WebsiteLayout>
      <HelmetProvider>
        <Helmet>
          <title>Money Minx Features</title>
          <meta name='description' content='Track, analyze and optimize a diversified portfolio with Money Minx. Our technology gives everyday investors pro-level insights to reach their net worth goals.' />
          {/*Facebook tags*/}
          <meta property='og:title' content='Net Worth Calculator and Investments Tracker | Money Minx' />
          <meta property='og:description' content='Track, analyze and optimize a diversified portfolio with Money Minx. Our technology gives everyday investors pro-level insights to reach their net worth goals.' />
          <meta property='og:url' content='https://www.moneyminx.com' />
          <meta property='og:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
          <meta property='og:image:secure_url' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
          <meta property='og:image:alt' content='Track, analyze and optimize a diversified portfolio with Money Minx. Our technology gives everyday investors pro-level insights to reach their net worth goals.' />
          {/*Twitter tags*/}
          <meta property='twitter:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
          <meta property='twitter:image:alt' content='Track, analyze and optimize a diversified portfolio with Money Minx. Our technology gives everyday investors pro-level insights to reach their net worth goals.' />
        </Helmet>
      </HelmetProvider>
      <div className='mm-new-container'>
        <FeaturesTopSection />
        <FeaturesNetWorthSection />
        <FeaturesAllocationSection />
        <FeaturesMulticurrencySection />
        <FeaturesCryptosSection />
        <FeaturesSyncedManualSection />
        <FeaturesTransactionHistorySection />
      </div>
    </WebsiteLayout>
  );
};
export default Features;

export const FeaturesTopSection = () => {
  return (
    <section>
      <div className='row'>
        <div className='col-xl-7'>
          <div className='mm-home-left-banner'>
            <h1>Money Minx Features</h1>
            <p className='text'>
              The modern financial planning and net worth tracking application.
            </p>
            <Link to={appRouteConstants.auth.SIGNUP}>
              <button className='mm-btn-animate mm-btn-primary'>Get Started</button>
            </Link>
            <small className='d-block mt-3 mt-md-4'>No credit card needed.</small>
          </div>
        </div>
        <div className='col-xl-5'>
          <div className='mm-home-right-img-banner text-center mt-xl-n5'>
            <HomeBannerImg />
          </div>
        </div>
      </div>
      <div className='mm-scroll-container-parent mm-mb-hide'>
        <div className='mm-scroll-container'>
          <div className='mouse'>
            <div className='scroll' />
          </div>
        </div>
      </div>
    </section>
  );
};
export const FeaturesNetWorthSection = () => {
  return (
    <section className='feature-section feature-text-left'>
      <span className='highlighted-text p-b-9'>Highlighted Features</span>
      <div className='row'>
        <div className='col-lg-5 feature-content'>
          <a href='/features/net-worth'>
            <h2>Net Worth Calculator</h2>
          </a>
          <p className='feature-text'>
            Stay up-to-date with your assets and liabilities. Track your wealth with our Net Worth Calculator and see how you compare to others.
          </p>
        </div>
        <div className='col-lg-7 feature-image'>
          <HomeNetWorthChart />
        </div>
      </div>
    </section>
  );
};
export const FeaturesAllocationSection = () => {
  return (
    <section className='feature-section feature-section-reversed'>
      <div className='row'>
        <div className='col-lg-7 feature-image'>
          <HomeAllocationChart />
        </div>
        <div className='col-lg-5 feature-content'>
          <a href='/features/allocations'>
            <h2>Asset Allocation</h2>
          </a>
          <p className='feature-text'>
            With our asset allocation calculator, you will have a 360 degree view of your investments and your portfolio diversification.
          </p>
        </div>
      </div>
    </section>
  );
};
export const FeaturesMulticurrencySection = () => {
  return (
    <section className='feature-section feature-text-left'>
      <div className='row'>
        <div className='col-lg-5 feature-content'>
          <a href='/features/multicurrency'>
            <h2>Multi Currency</h2>
          </a>
          <p className='feature-text'>
            Money Minx is built with the international investor in mind. Choose a home base currency, convert and view your accounts in multiple currencies.
          </p>
        </div>
        <div className='col-lg-7 feature-image'>
          <HomeMulticurrency />
        </div>
      </div>
    </section>
  );
};
export const FeaturesCryptosSection = () => {
  return (
    <section className='feature-section feature-section-reversed'>
      <div className='row'>
        <div className='col-lg-7 feature-image'>
          <InvestmentsAndCryptos className='mm-custom-networth-chart' />
        </div>
        <div className='col-lg-5 feature-content'>
          <a href='/features/cryptos'>
            <h2>Crypto Tracker</h2>
          </a>
          <p className='feature-text'>
            Connect your cryptocurrency wallets, including Coinbase, Binance, Gemini and more.
            Keep an eye on your Bitcoin, Ethereum and other cryptos at all times.
          </p>
        </div>
      </div>
    </section>
  );
};
export const FeaturesSyncedManualSection = () => {
  return (
    <section className='feature-section feature-text-left'>
      <div className='row'>
        <div className='col-lg-5 feature-content'>
          <a href='/features/synced-and-manual'>
            <h2>Synced or Manual Accounts</h2>
          </a>
          <p className='feature-text'>
            Sync with over 24k supported institutions or use manual accounts.
            Manual accounts are a great way to track non-traditional investments, like comic books, art, rare legos and more - no logins necessary.
          </p>
        </div>
        <div className='col-lg-7 feature-image'>
          <SyncedOrManual />
        </div>
      </div>
    </section>
  );
};
export const FeaturesTransactionHistorySection = () => {
  return (
    <section className='feature-section feature-section-reversed'>
      <div className='row'>
        <div className='col-lg-7 feature-image'>
          <HomeTransactionHistory />
        </div>
        <div className='col-lg-5 feature-content'>
          <a href='/features/transaction-history'>
            <h2>Transaction History</h2>
          </a>
          <p className='feature-text'>
            Keep an eye on all of your accounts from one place.
            Review the transaction history and holdings of each account.
          </p>
        </div>
      </div>
    </section>
  );
};
