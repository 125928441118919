import React, { createContext } from 'react';

import { getUpdatedFilter } from 'filter/filter.helper';
import { handleStringArrayToggle } from 'common/common-helper';

import { NetworthActionEnum } from './networth.enum';
import { NetworthDispatch, NetworthState, NetworthProviderProps, Action } from './networth.type';

export const initialState: NetworthState = {
  fTypes: [],
  fAccounts: [],
  fCategories: [],
  fToDate: undefined,
  fFromDate: undefined,
  fTimeInterval: undefined,
  fEntities: [],
  fLiquidity: [],

  accounts: undefined,
  networth: [],
  accountWithIssues: [],

  fToggleInvestment: true,
  fToggleOther: true,
  fToggleLiabilities: true,
  fToggleNet: true,

  accountCount: 0,
  groupByCategory: false,

  processing: {
    networth: false,
    networthByType: false,
    filters: false,
  },

  filters: [],
};

const NetworthStateContext = createContext<NetworthState | undefined>(undefined);
const NetworthDispatchContext = createContext<NetworthDispatch | undefined>(undefined);

function networthReducer(state: NetworthState, action: Action): any {
  switch (action.type) {
    case NetworthActionEnum.SET_F_CATEGORY: {
      return { ...state, fCategories: handleStringArrayToggle(state.fCategories, action.payload?.fCategory) };
    }

    case NetworthActionEnum.SET_F_ACCOUNT_TYPE: {
      return { ...state, fTypes: handleStringArrayToggle(state.fTypes, action.payload?.fAccountType) };
    }

    case NetworthActionEnum.SET_F_FROM_DATE: {
      return { ...state, fFromDate: action.payload?.fromDate };
    }

    case NetworthActionEnum.SET_F_TO_DATE: {
      return { ...state, fToDate: action.payload?.toDate };
    }

    case NetworthActionEnum.SET_F_TIME_INTERVAL: {
      return { ...state, fTimeInterval: action.payload?.fTimeInterval };
    }

    case NetworthActionEnum.SET_F_ACCOUNT: {
      return { ...state, fAccounts: handleStringArrayToggle(state.fAccounts, action.payload?.fAccountId) };
    }

    case NetworthActionEnum.SET_F_ENTITY: {
      return { ...state, fEntities: handleStringArrayToggle(state.fEntities, action.payload?.investingEntityId) };
    }

    case NetworthActionEnum.SET_F_LIQUIDITY: {
      return { ...state, fLiquidity: handleStringArrayToggle(state.fLiquidity, action.payload?.liquidity) };
    }

    case NetworthActionEnum.F_BULK_UPDATE: {
      const networthFilter = action.payload.networthFilter;

      return {
        ...state,
        fCategories: networthFilter.fCategories || state.fCategories,
        fTypes: networthFilter.fTypes || state.fTypes,
        fFromDate: networthFilter.fFromDate || state.fFromDate,
        fToDate: networthFilter.fToDate || state.fToDate,
        fTimeInterval: networthFilter.fTimeInterval || state.fTimeInterval,
        fAccounts: networthFilter.fAccounts || state.fAccounts,
        fEntities: networthFilter.fEntities || state.fEntities,
        fLiquidity: networthFilter.fLiquidity || state.fLiquidity,
      };
    }

    case NetworthActionEnum.SET_NETWORTH: {
      return { ...state, networth: action.payload?.networth };
    }

    case NetworthActionEnum.SET_ACCOUNTS: {
      return { ...state, accounts: action.payload?.accounts };
    }

    case NetworthActionEnum.SET_F_TOGGLE_INVESTMENT: {
      return { ...state, fToggleInvestment: action.payload?.fToggleInvestment };
    }

    case NetworthActionEnum.SET_F_TOGGLE_OTHER: {
      return { ...state, fToggleOther: action.payload?.fToggleOther };
    }

    case NetworthActionEnum.SET_F_TOGGLE_LIABILITIES: {
      return { ...state, fToggleLiabilities: action.payload?.fToggleLiabilities };
    }

    case NetworthActionEnum.SET_F_TOGGLE_NET: {
      return { ...state, fToggleNet: action.payload?.fToggleNet };
    }

    case NetworthActionEnum.SET_ACCOUNT_COUNT: {
      return { ...state, accountCount: action.payload?.accountCount };
    }

    case NetworthActionEnum.TOGGLE_GROUP_BY_CATEGORY: {
      return { ...state, groupByCategory: !state.groupByCategory };
    }

    case NetworthActionEnum.CLEAR_FILTER: {
      return {
        ...state,
        fTypes: [],
        fAccounts: [],
        fCategories: [],
        fToDate: undefined,
        fFromDate: undefined,
        fTimeInterval: undefined,
        fEntities: [],
        fLiquidity: [],
      };
    }

    case NetworthActionEnum.SET_PROCESSING: {
      const key = action.payload?.key;

      return { ...state, processing: { ...state.processing, [key]: true } };
    }

    case NetworthActionEnum.RESET_PROCESSING: {
      const key = action.payload?.key;

      return { ...state, processing: { ...state.processing, [key]: false } };
    }

    case NetworthActionEnum.SET_FILTERS: {
      return { ...state, filters: action.payload.filters };
    }

    case NetworthActionEnum.ADD_FILTER: {
      const newFilter = action.payload.filter;
      const modifiedFilter = state.filters.concat(newFilter);

      return { ...state, filters: modifiedFilter };
    }

    case NetworthActionEnum.UPDATE_FILTER: {
      const newFilter = action.payload.filter;
      const modifiedFilter = getUpdatedFilter(state.filters, newFilter);

      return { ...state, filters: modifiedFilter };
    }

    case NetworthActionEnum.REMOVE_FILTER: {
      const filterId = action.payload.filterId;
      const modifiedFilters = state.filters.filter((filter) => filter.id !== +filterId);

      return { ...state, filters: modifiedFilters };
    }

    default: {
      throw new Error('Unhandled action type');
    }
  }
}

function NetworthProvider({ children }: NetworthProviderProps) {
  const [state, dispatch] = React.useReducer(networthReducer, initialState);

  return (
    <NetworthStateContext.Provider value={state}>
      <NetworthDispatchContext.Provider value={dispatch as any}>{children}</NetworthDispatchContext.Provider>
    </NetworthStateContext.Provider>
  );
}

function useNetworthState() {
  const context = React.useContext(NetworthStateContext);

  if (context === undefined) {
    throw new Error('useAppState must be used within a App Provider');
  }

  return context;
}

function useNetworthDispatch() {
  const context = React.useContext(NetworthDispatchContext);

  if (context === undefined) {
    throw new Error('Must be used within a Networth Provider');
  }

  return context;
}

export { NetworthProvider, useNetworthDispatch, useNetworthState };
