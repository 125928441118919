import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import classNames from 'common/classes.helper';
import NetworthChart from 'dashboard/inc/networth-chart';
import { getCurrencySymbol } from 'common/currency-helper';
import { EIntervalOption } from 'dashboard/dashboard.enum';
import { fNumber, numberWithCommas } from 'common/number.helper';
import useDashboardChart from 'dashboard/hooks/useDashboardChart';

import IntervalSelector from './interval-selector';
import { getComparisonLabel } from '../dashboard.helper';

const DashboardChart: React.FC = () => {
  const [selectedInterval, setSelectedInterval] = useState<EIntervalOption>(EIntervalOption['1D']);

  const { loading, networthValue, networthHistory, currencyOption, monthlyChartData } =
    useDashboardChart(selectedInterval);

  const currencySymbol = getCurrencySymbol(currencyOption);
  const comparisonText = getComparisonLabel(selectedInterval);

  const networthNegativeSymbol = networthValue < 0 ? '-' : '';

  const renderComparisonValues = () => {
    const isLoading = !networthValue || !networthHistory || loading;

    const historyValue = networthHistory?.networth || 0;

    const networthDiff = networthValue - historyValue;

    let percentageText = '--';

    if (historyValue) {
      const networthDiffPer = fNumber((networthDiff / Math.abs(historyValue)) * 100);

      percentageText = `${networthDiffPer}%`;
    }

    const negativeSymbol = networthDiff < 0 ? '-' : '';

    return (
      <p className={classNames(networthDiff < 0 && 'networth--negative')}>
        {!isLoading ? (
          `${negativeSymbol}${currencySymbol}${numberWithCommas(
            fNumber(Math.abs(networthDiff), 0)
          )} (${percentageText})`
        ) : (
          <Skeleton />
        )}
      </p>
    );
  };

  return (
    <div className='dashboard-chart'>
      <div className='dashboard-chart__header'>
        <div className='d-flex align-items-center justify-content-between justify-content-sm-start mb-3 mb-md-0 p-t-3'>
          <div className='dashboard-chart__header-networth'>
            <span>Net Worth</span>
            <span>
              {networthValue ? (
                `${networthNegativeSymbol}${currencySymbol}${numberWithCommas(fNumber(Math.abs(networthValue), 0))}`
              ) : (
                <Skeleton width={80} />
              )}
            </span>
          </div>

          <div className='dashboard-chart__header-comparison'>
            <span>{!loading ? comparisonText : <Skeleton width={150} />}</span>
            {renderComparisonValues()}
          </div>
        </div>

        <div className='p-t-3'>
          <IntervalSelector onChange={setSelectedInterval} />
        </div>
      </div>

      <NetworthChart networth={monthlyChartData} currencySymbol={currencySymbol} />
    </div>
  );
};

export default DashboardChart;
