/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { Dictionary } from 'lodash';
import { Account } from 'auth/auth.types';
import { serialize } from 'common/common-helper';
import { groupByProviderName } from 'auth/auth.helper';

const useAccountSettings = (accounts?: Account[]) => {
  const [completed, setCompleted] = useState<number[]>([]);
  const [providerName, setProviderName] = useState('');
  const [currentAccount, setCurrentAccount] = useState<Account>();
  const [completedProviderName, setCompletedProviderName] = useState<string[]>([]);
  const [currentProviderAccounts, setCurrentProviderAccounts] = useState<Account[]>([]);
  const [accountsByProviderName, setAccountsByProviderName] = useState<Dictionary<Account[]>>({});

  const stringifiedAccounts = accounts ? serialize(accounts) : [];

  useEffect(() => {
    /**
     * variables
     */
    let curProviderName: string;
    let completedProviders: string[];
    let curProviderAccounts: Account[];
    let accountsByProvider: Dictionary<Account[]>;

    /**
     * if accounts
     * accounts[0] for current account
     * get completed provider names
     * set current provider accounts
     */

    if (accounts?.length) {
      if (!currentAccount) {
        setCurrentAccount(accounts[0]);
      }

      accountsByProvider = groupByProviderName(accounts);

      setAccountsByProviderName(accountsByProvider);

      completedProviders = Object.keys(accountsByProvider).filter((pName) =>
        accountsByProvider[pName].every((acc) => acc.accountDetails?.overridden === true)
      );

      setCompletedProviderName(completedProviders);
      curProviderName = providerName ? providerName : Object.keys(accountsByProvider)[0];
      setProviderName(curProviderName);

      curProviderAccounts = accountsByProvider[curProviderName];
      setCurrentProviderAccounts(curProviderAccounts);

      /**
       * Get the first Non overridden account
       */
      const firstNonOverriddenAccount = curProviderAccounts?.find((acc) => acc.accountDetails?.overridden !== true);

      if (firstNonOverriddenAccount) {
        return setCurrentAccount(firstNonOverriddenAccount);
      }

      if (curProviderAccounts) {
        // && clickEvent
        return setCurrentAccount(curProviderAccounts[0]);
      }

      /**
       * set completed IDS
       */
      const completedIds = accounts?.filter((acc) => acc.accountDetails?.overridden).map((item) => item.id);
      setCompleted(completedIds);
    }
  }, [stringifiedAccounts, providerName]);

  return {
    completed,
    providerName,
    currentAccount,
    setProviderName,
    setCurrentAccount,
    completedProviderName,
    accountsByProviderName,
    currentProviderAccounts,
  };
};

export default useAccountSettings;
