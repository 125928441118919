import React, { createContext, useReducer, useContext } from 'react';

import { handleStringArrayToggle } from 'common/common-helper';

import { EDiscoverFilterAction, EInvestorType } from '../discover.enum';
import { IDiscoverFilterAction, IDiscoverFilterState, TDiscoverFilterDispatch } from '../discover.types';

export const initialDiscoverFilterState: IDiscoverFilterState = {
  types: [],
  risk: '',
  returnFrequencies: [],
  returnTypes: [],
  liquidity: [],
  investorType: EInvestorType.ALL,
  minimum: {
    min: 0,
    max: 100000,
  },
  targetReturns: {
    min: 0,
    max: 50,
  },
  sortField: '',
};

function discoverFilterReducer(state: IDiscoverFilterState, action: IDiscoverFilterAction): IDiscoverFilterState {
  switch (action.type) {
    case EDiscoverFilterAction.SET_TYPE:
      return { ...state, types: handleStringArrayToggle(state.types, action.payload?.type) };
    case EDiscoverFilterAction.SET_RISK:
      return { ...state, risk: action.payload?.risk };
    case EDiscoverFilterAction.SET_RETURN_FREQUENCY:
      return {
        ...state,
        returnFrequencies: handleStringArrayToggle(state.returnFrequencies, action.payload?.returnFrequency),
      };
    case EDiscoverFilterAction.SET_RETURN_TYPE:
      return { ...state, returnTypes: handleStringArrayToggle(state.returnTypes, action.payload?.returnType) };
    case EDiscoverFilterAction.SET_LIQUIDITY:
      return { ...state, liquidity: handleStringArrayToggle(state.liquidity, action.payload?.liquidity) };
    case EDiscoverFilterAction.SET_INVESTOR_TYPE:
      return { ...state, investorType: action.payload?.investorType };
    case EDiscoverFilterAction.SET_MINIMUM:
      return {
        ...state,
        minimum: action.payload?.range,
      };
    case EDiscoverFilterAction.SET_TARGET_RETURNS:
      return {
        ...state,
        targetReturns: action.payload?.range,
      };
    case EDiscoverFilterAction.SET_SORT_FIELD:
      return {
        ...state,
        sortField: action.payload?.sortField,
      };
    case EDiscoverFilterAction.PARTIAL_UPDATE:
      return {
        ...state,
        ...action.payload?.values,
      };
    case EDiscoverFilterAction.CLEAR_FILTERS:
      return {
        types: [],
        risk: '',
        returnFrequencies: [],
        returnTypes: [],
        liquidity: [],
        investorType: EInvestorType.ALL,
        minimum: {
          min: 0,
          max: 100000,
        },
        targetReturns: {
          min: 0,
          max: 50,
        },
        sortField: '',
      };
    default:
      throw new Error('Unhandled action type in discover filter reducer');
  }
}

// Provider Wrapper
const DiscoverFilterStateContext = createContext<IDiscoverFilterState | undefined>(undefined);
const DiscoverFilterDispatchContext = createContext<TDiscoverFilterDispatch | undefined>(undefined);

const DiscoverFilterProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(discoverFilterReducer, initialDiscoverFilterState);

  return (
    <DiscoverFilterStateContext.Provider value={state}>
      <DiscoverFilterDispatchContext.Provider value={dispatch}>{children}</DiscoverFilterDispatchContext.Provider>
    </DiscoverFilterStateContext.Provider>
  );
};

// Consumer Helpers
const useDiscoverFilterState = () => {
  const context = useContext(DiscoverFilterStateContext);

  if (context === undefined) {
    throw new Error('useDiscoverFilterState must be used within a DiscoverFilterStateContext.');
  }

  return context;
};

const useDiscoverFilterDispatch = () => {
  const context = useContext(DiscoverFilterDispatchContext);

  if (context === undefined) {
    throw new Error('useDiscoverFilterDispatch must be used within a DiscoverFilterDispatchContext.');
  }

  return context;
};

export { DiscoverFilterProvider, useDiscoverFilterState, useDiscoverFilterDispatch };
