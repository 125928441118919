import React, { useState } from 'react';

import AppHeader from 'common/app.header';
import AppFooter from 'common/app.footer';
import AppSidebar from 'common/app.sidebar';

const ReferAFriendLayout: React.FC = ({ children }) => {
  const [openLeftNav, setOpenLeftNav] = useState<boolean>(false);
  const [openRightNav, setOpenRightNav] = useState<boolean>(false);

  const closeRightNav = () => {
    setOpenRightNav(false);
  };

  return (
    <>
      <AppHeader
        toggleLeftMenu={() => setOpenLeftNav((prev) => !prev)}
        toggleRightMenu={() => setOpenRightNav((prev) => !prev)}
        open={openRightNav}
        shadow
      />

      <AppSidebar openLeft={openLeftNav} openRight={openRightNav} />

      <div className='mm-slider-bg-overlay' onClick={closeRightNav} role='button' />
      {children}

      <AppFooter />
    </>
  );
};

export default ReferAFriendLayout;
