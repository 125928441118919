const binanceUSKeyInstructions = [
  'Log into your Binance.us account.',
  'Click on the profile in the top right corner of the screen and select API Management.',
  'Enter a label for the API Key and click Create.',
  'Verify your API key through two factor authentication.',
  'In the API List screen, confirm that only Can Read is checked. All other permissions should not be checked.',
  'Copy the API key and API secret and enter them in Money Minx. Note that when you close this screen, you will not be able to retrieve the keys again.',
  'Confirm the Money Minx connection is working before closing the API List screen on Binance.us.',
];

export default binanceUSKeyInstructions;
