import { postUserLog } from 'api/request.api';

export interface IUserLog {
  sendEmail: boolean;
  addToLog: boolean;
  log: Record<string, any>;
  logType: 'YODLEE_FASTLINK';
}

export const useUserLog = () => {
  const addUserLog = async (log: Record<string, any>) => {
    const userLog: IUserLog = {
      sendEmail: true,
      addToLog: true,
      log,
      logType: 'YODLEE_FASTLINK',
    };

    postUserLog(userLog);
  };

  return { addUserLog };
};
