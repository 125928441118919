import React from 'react';

import classNames from 'common/classes.helper';
import { IAccountDetails } from 'auth/auth.types';
import { StripeSubscriptionStatus } from 'setting/setting.enum';
import useRealEstateDetail from 'account/hooks/useRealEstateDetail';
import useCurrentSubscription from 'auth/hooks/useCurrentSubscription';

import Leases from './inc/leases';
import MonthlyExpense from './inc/monthly-expense';
import RealEstateSkeleton from './real-estate-skeleton';
import AssociatedMortgage from './inc/associated-mortgage';

interface IRealEstateDetails {
  accountId: number;
  isVisible: boolean;
  currencySymbol: string;
  handleReload: VoidFunction;
  accountDetails: IAccountDetails;
}

const RealEstateDetails: React.FC<IRealEstateDetails> = ({
  accountId,
  isVisible,
  accountDetails,
  currencySymbol,
  handleReload,
}) => {
  const { loading, leases, mortgageAccounts, associatedMortgageAccounts, fetchAssociatedMortgageAccounts } =
    useRealEstateDetail(accountId);
  const { currentSubscription } = useCurrentSubscription();
  const isFreeUser =
    currentSubscription?.subscriptionStatus !== StripeSubscriptionStatus.TRIALING &&
    currentSubscription?.subscriptionStatus !== StripeSubscriptionStatus.ACTIVE;

  const handleRefresh = () => {
    fetchAssociatedMortgageAccounts(accountId);
    handleReload();
  };

  if (loading) {
    return <RealEstateSkeleton />;
  }

  return (
    <div className={classNames('row real-estate-details', isVisible && 'real-estate-details--visible')}>
      <div className='col-12 col-md-6 col-lg-4 mb-4 mb-lg-0'>
        <Leases
          leases={leases}
          accountId={accountId}
          currencySymbol={currencySymbol}
          handleReload={handleReload}
          locked={isFreeUser}
        />
      </div>
      <div className='col-12 col-md-6 col-lg-4 mb-4 mb-lg-0'>
        <MonthlyExpense
          accountId={accountId}
          accountDetails={accountDetails}
          currencySymbol={currencySymbol}
          handleReload={handleReload}
          locked={isFreeUser}
        />
      </div>
      <div className='col-12 col-md-6 col-lg-4 mb-4 mb-lg-0'>
        <AssociatedMortgage
          accountId={accountId}
          manualAccounts={mortgageAccounts}
          associatedMortgageAccounts={associatedMortgageAccounts}
          handleReload={handleRefresh}
          locked={isFreeUser}
        />
      </div>
    </div>
  );
};

export default RealEstateDetails;
