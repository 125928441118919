import { Dropdown } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import React, { useMemo, useCallback, useEffect, useRef, useState } from 'react';

import { Account } from 'auth/auth.types';
import useSocket from 'networth/hooks/useSocket';
import useSearchParam from 'auth/hooks/useSearchParam';
import { getAccountWithProvider } from 'api/request.api';
import AddAccountsModal from 'account/views/add-account.modal';

import { useModal } from './components/modal';
import useInterval from './hooks/useInterval';
import { getRelativeDate } from './moment.helper';
import { EConnectionStatus } from './common.types';
import { getCurrencySymbol } from './currency-helper';
import { base64Encode } from './helper/string.helper';
import { fNumber, numberWithCommas } from './number.helper';
import GradientSpinner from './components/spinner/gradient-spinner';

export interface AppSubHeaderProps {
  AccountDetails?: Account;
}

const AppSubHeader: React.FC<AppSubHeaderProps> = () => {
  const history = useHistory();
  const location = useLocation();
  const socket = useSocket();

  const [loading, setLoading] = useState<boolean>(false);

  // FIXME: Remember to get accounts from the context provider
  const [accounts, setAccounts] = useState<Account[]>([]);

  const dropdownToggle = useRef(null);

  const addAccountModal = useModal();
  const refetchAccounts = useSearchParam('refetchAccounts');

  const updatingAccounts = useRef<any>(null);

  useEffect(() => {
    const fetchCurrentAccount = async () => {
      setLoading(true);
      const { data, error } = await getAccountWithProvider();

      if (!error) {
        setAccounts(data);
      }

      setLoading(false);
    };

    fetchCurrentAccount();
  }, [refetchAccounts]);

  const updateAccount = ({ accountId, connectionStatus }: Record<any, any>) => {
    if (!updatingAccounts?.current) {
      updatingAccounts.current = {};
    }

    if (updatingAccounts?.current[accountId]) {
      return;
    }

    updatingAccounts.current[accountId] = connectionStatus;
  };

  const updateAccountCallback = useCallback(updateAccount, []);

  useEffect(() => {
    socket?.on('account-status-update', updateAccountCallback);
  }, [socket, updateAccountCallback]);

  const updateAccountOnInterval = () => {
    const updatingAccountList: Account[] = [];
    let accountList = [...accounts];

    if (updatingAccounts?.current) {
      Object.keys(updatingAccounts.current).forEach((accountId) => {
        const account = accountList.find((acc) => +acc.id === +accountId);

        if (account) {
          account.connectionStatus = updatingAccounts.current[accountId];
          updatingAccountList.push(account);
          accountList = accountList.filter((acc) => +acc.id !== +accountId);
        }
      });

      updatingAccounts.current = {};
    }

    if (updatingAccountList.length) {
      setAccounts([...accountList, ...updatingAccountList]);
    }
  };

  const accMemo = useMemo(() => accounts, [accounts]);

  useInterval(updateAccountOnInterval, [accMemo]);

  const clickElement = (dropdownToggleElement: any, accountId: number) => {
    dropdownToggleElement.current?.click();

    const encodedAccountId = base64Encode(`${accountId}`);
    history.push(`/account-details/${encodedAccountId}`);
  };

  const onAddAccountClick = () => {
    const params = new URLSearchParams(location.search);
    params.delete('refetchAccounts');
    params.delete('from');
    history.replace({
      search: params.toString(),
    });

    addAccountModal.open();
  };

  const successProviders = ['ZABO', 'SALT_EDGE_PARTNER', 'SALT_EDGE_GATEWAY'];
  const warningStatuses = [EConnectionStatus.ATTENTION, EConnectionStatus.STALE];

  const successAccounts = accounts?.filter(
    (acc: Account) =>
      acc.isManual ||
      acc.connectionStatus === EConnectionStatus.GOOD ||
      acc.connectionStatus === EConnectionStatus.WAIT ||
    successProviders.includes(acc.mmAccountProvider)
  );

  const warningAccounts = accounts?.filter((acc: Account) =>
    warningStatuses.includes(acc.connectionStatus as EConnectionStatus)
  );

  const errorAccounts = accounts?.filter((acc: Account) => acc.connectionStatus === EConnectionStatus.ERROR);
  const archivedAccounts = accounts?.filter((acc: Account) => acc.isArchived);

  const refreshingAccounts = accounts?.filter((acc: Account) => acc.connectionStatus === EConnectionStatus.REFRESHING);

  return (
    <>
      <div className='left-box d-flex align-items-center float-lg-left'>
        <button type='button' className='mm-btn-primary mm-btn-animate plus-btn' onClick={() => onAddAccountClick()}>
          Add Account
        </button>
        <div className='myaccount-drop'>
          <Dropdown className='m-l-4'>
            <Dropdown.Toggle className='dropdown-toggle my-accounts' ref={dropdownToggle}>
              My Accounts
            </Dropdown.Toggle>
            <Dropdown.Menu className='dropdown-menu'>
              {loading ? (
                <div className='spinner-container '>
                  <GradientSpinner />
                </div>
              ) : (
                <div className='dropdown-box'>
                  {refreshingAccounts.length > 0 && (
                    <ul className='refreshing'>
                      {refreshingAccounts.map((account: Account, index: number) => {
                        return (
                          <li key={index}>
                            <span className='mm-loader' />
                            <Link to='#'>
                              <div className='pr-1'>
                                <h5>{account.accountName}</h5>
                                <span>
                                  {account.providerAccount?.dataset?.[0]?.lastUpdated?.toString() !== null
                                    ? getRelativeDate(account.providerAccount?.dataset?.[0]?.lastUpdated?.toString())
                                    : ''}
                                </span>
                              </div>
                              <div>
                                {getCurrencySymbol(account.currency)}
                                {numberWithCommas(fNumber(account.balance, 2))}
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  {errorAccounts.length > 0 && (
                    <div>
                      <div className='dropdown-head'>
                        <h4>Connection Error</h4>
                      </div>
                      <ul className='error'>
                        {errorAccounts.map((account: Account, index: number) => {
                          return (
                            <li key={index}>
                              <Link to='#' onClick={() => clickElement(dropdownToggle, account.id)}>
                                <div className='pr-1'>
                                  <h5>{account.accountName}</h5>
                                  <span>
                                    {account.providerAccount?.dataset?.[0]?.lastUpdated?.toString() !== null
                                      ? getRelativeDate(account.providerAccount?.dataset?.[0]?.lastUpdated?.toString())
                                      : ''}
                                  </span>
                                </div>
                                <div>
                                  {getCurrencySymbol(account.currency)}
                                  {numberWithCommas(fNumber(account.balance, 2))}
                                </div>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                      <hr className='hr-darkBg' />
                    </div>
                  )}
                  {warningAccounts.length > 0 && (
                    <div>
                      <div className='dropdown-head'>
                        <h4 className='attention'>Needs Attention</h4>
                      </div>
                      <ul className='warning'>
                        {warningAccounts.map((account: Account, index: number) => {
                          return (
                            <li key={index}>
                              <Link to='#' onClick={() => clickElement(dropdownToggle, account.id)}>
                                <div>
                                  <h5>{account.accountName}</h5>
                                  <span>
                                    {account.providerAccount?.dataset?.[0]?.lastUpdated?.toString() !== null
                                      ? getRelativeDate(account.providerAccount?.dataset?.[0]?.lastUpdated?.toString())
                                      : ''}
                                  </span>
                                </div>
                                <div>
                                  {getCurrencySymbol(account.currency)}
                                  {numberWithCommas(fNumber(account.balance, 2))}
                                </div>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                      <hr className='hr-darkBg' />
                    </div>
                  )}
                  {successAccounts.length > 0 && (
                    <ul className='success'>
                      {successAccounts.map((account: Account, index: number) => {
                        return (
                          <li key={index}>
                            <Link to='#' onClick={() => clickElement(dropdownToggle, account.id)}>
                              <div className='pr-1'>
                                <h5>{account.accountName}</h5>
                                <span>
                                  {account.providerAccount?.dataset?.[0]?.lastUpdated?.toString() !== null
                                    ? getRelativeDate(account.providerAccount?.dataset?.[0]?.lastUpdated?.toString())
                                    : ''}
                                </span>
                              </div>
                              <div>
                                {getCurrencySymbol(account.currency)}
                                {numberWithCommas(fNumber(account.balance, 2))}
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}

                  {archivedAccounts.length > 0 && (
                    <div>
                      <hr className='hr-darkBg' />
                      <div className='dropdown-head'>
                        <h4 className='closed'>Closed</h4>
                      </div>
                      <ul className='closed'>
                        {archivedAccounts.map((account, index: number) => (
                          <li key={index}>
                            <Link to='#' onClick={() => clickElement(dropdownToggle, account.id)}>
                              <div className='pr-1'>
                                <h5>{account.accountName}</h5>
                                <span>
                                  {account.providerAccount?.dataset?.[0]?.lastUpdated?.toString() !== null
                                    ? getRelativeDate(account.providerAccount?.dataset?.[0]?.lastUpdated?.toString())
                                    : ''}
                                </span>
                              </div>
                              <div>
                                {getCurrencySymbol(account.currency)}
                                {numberWithCommas(fNumber(account.balance, 2))}
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {addAccountModal.props.open && <AddAccountsModal addAccountModal={addAccountModal} />}
    </>
  );
};

export default AppSubHeader;
