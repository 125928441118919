import { Account } from 'auth/auth.types';
import { ModalType } from 'common/components/modal';

import { EMMAccountType } from './enum/account-type';

export interface AccountSubNavigationProps {
  AccountDetails: Account;
  baseCurrency: boolean;
  toggleBaseCurrency: () => void;
  mmAccountType: string;
}

export interface AccountChartItem {
  interval: string;
  type: string;
  value: number;
  price?: number;
  quantity?: number;
}

export interface AccountBarGraphProps {
  data: AccountChartItem[];
  curInterval: string;
  currencySymbol: string;
  mmCategory: string;
}

export interface AccountHoldingItem {
  unit: string;
  accountId: number;
  accruedIncome: any;
  accruedIncomeCurrency: string;
  accruedInterest: any;
  accruedInterestCurrency: string;
  contractQuantity: any;
  costBasis: number;
  costBasisCurrency: string;
  couponRate: any;
  createdDate: string;
  cusipNumber: any;
  description: string;
  exercisedQuantity: any;
  expirationDate: any;
  grantDate: any;
  holdingType: string;
  id: number;
  interestRate: any;
  intervalValues: AccountChartItem[];
  isManual: boolean;
  isShort: boolean;
  isin: any;
  lastUpdated: string;
  logo?: string;
  matchStatus: string;
  maturityDate: any;
  optionType: string;
  price: number;
  priceCurrency: string;
  providerAccountId: number;
  quantity: number;
  securityType: string;
  sedol: any;
  sharesGranted: number;
  spread: any;
  spreadCurrency: string;
  strikePrice: any;
  strikePriceCurrency: string;
  symbol: string;
  term: any;
  unvestedQuantity: any;
  unvestedValue: any;
  unvestedValueCurrency: string;
  vestedDate: any;
  vestedQuantity: any;
  vestedSharesExercisable: any;
  vestedValue: any;
  vestedValueCurrency: string;
  yodleeId: number;
  mmHoldingType: string;
}

export interface AccountHoldingsProps {
  charts: AccountChartItem[];
  holdings: AccountHoldingItem[];
}

export interface HoldingsDetailsModalProps {
  accountId?: number;
  holdingsDetails?: any;
  currencySymbol: string;
  handleRefresh: () => void;
  holdingsDetailsModal: ModalType;
  closeNewPositionModal?: () => void;
  closeEditPositionModal?: () => void;
  activeTabName?: string;
}

export interface AccountHoldingsTableProps {
  currencySymbol: string;
  accountDetails?: Account;
  handleRefresh: () => void;
  holdingsData: AccountHoldingItem[];
  openEditPositionModalFun: () => void;
  closeEditPositionModalFun: () => void;
}

export interface AccountTransactionItem {
  accountId: number;
  quantity: number;
  price: number;
  amount: number;
  amountCurrency: string;
  balance: any;
  balanceCurrency: string;
  baseType: string;
  cashFlow: boolean;
  date: string;
  description: string;
  id: number;
  income: boolean;
  isIgnored: boolean;
  isManual: boolean;
  type: string;
  yodleeId: number;
  createdAt: string;
  updatedAt: string;
}

export interface ActivityDetailsModalProps {
  accountId?: number;
  currencySymbol: string;
  handleRefresh: () => void;
  activityDetailsModal: ModalType;
  closeNewActivityModal?: () => void;
  closeEditActivityModal?: () => void;
  activityDetails?: AccountTransactionItem;
}

export interface ClassificationsModalProps {
  handleRefresh: () => void;
  classificationsModal: ModalType;
  accountDetails: Account;
}

export interface AccountTransactionTableProps {
  isQroka: boolean;
  currencySymbol: string;
  handleRefresh: () => void;
  openEditActivityModalFun: () => void;
  closeEditActivityModalFun: () => void;
  transactionsData: AccountTransactionItem[];
}

export interface AccountTransactionsProps {
  charts: AccountChartItem[];
  transactions: AccountTransactionItem[];
}

export type ClassificationType = 'Asset Class' | 'Country' | 'Risk' | 'Type';

export interface ClassificationItem {
  accountId: number;
  allocation: number;
  classificationType: ClassificationType;
  classificationValue: string;
  createdAt: string;
  id: number;
  positionId: number;
  updatedAt: string;
  yodleeId: number;
}

export interface Classifications {
  Asset_Class: ClassificationItem[];
  Country: ClassificationItem[];
  Risk: ClassificationItem[];
  Type: ClassificationItem[];
}

export interface IBalanceTable {
  balanceData?: IBalanceData;
  currencySymbol: string;
  account?: Account;
  handleRefresh: () => void;
}

export interface IBalance {
  interval: string;
  balance: number | null;
  type: string;
}

export interface IBalanceData {
  currency: string;
  accountName: string;
  balances: IBalance[];
}

export interface IAccountBalanceModal {
  account?: Account;
  accountBalanceModal: ModalType;
  onSuccess: () => void;
}

export interface IFormBalance {
  date: string;
  dateUTC: string;
  balance: number | null;
}
export interface IOriginalValue {
  date: string | Date;
  dateUTC?: string;
  balance?: number | null;
  price?: number | null;
  value?: number | null;
  interval?: string | null;
  quantity?: number | null;
  type?: string;
}

export type TFormBalances = IFormBalance[] | undefined;
export type TOriginalValues = IOriginalValue[] | undefined;

export interface IAddCoinsModalProps {
  accountId?: number;
  isEditMode?: boolean;
  currencySymbol: string;
  addCoinsModal: ModalType;
  handleRefresh: (refreshParams?: IRefreshParams) => void;
}

export interface ICoinGecko {
  coinGeckoId: string;
  createdAt: string;
  id: number;
  logo: string;
  name: string;
  symbol: string;
  updatedAt: string | null;
  usdRate: number;
}

export interface IFormFieldItem {
  label: string;
  name: string;
}

export interface IExtraInfo {
  deposits: IFormFieldItem[];
  creditCards: IFormFieldItem[];
  mortgages: IFormFieldItem[];
  loans: IFormFieldItem[];
  insurance: IFormFieldItem[];
}

export type TExtraInfoKey = keyof IExtraInfo;
export type TPartialExtraInfoKey = Partial<TExtraInfoKey>;

export interface IAddMetalsModal {
  accountId?: number;
  isEditMode?: boolean;
  currencySymbol: string;
  addMetalsModal: ModalType;
  handleRefresh: (refreshParams?: IRefreshParams) => void;
}

export interface IPreciousMetal {
  metalSymbol: string;
  name: string;
  usdRate: number | null;
}

export interface IExistingManualAccount {
  id: number;
  accountName: string;
  balance: number;
  currency?: string;
}

export interface IManualModalConfig {
  accountId: number | undefined;
  isEditMode: boolean;
}

export interface ILease {
  createdAt: string;
  updatedAt: string | null;
  id: number;
  tenantName: string;
  moveInDate: string;
  leaseExpiresDate: string;
  rent: number;
  deposit: number;
  accountId: number;
}

export interface ILeaseFormValue {
  tenant: string;
  moveInDate: Date | undefined;
  leaseExpire: Date | undefined;
  rent: string | number;
  deposit: string | number;
}

export interface ILeaseTabItem {
  id: number | null;
  key: string;
  title: string;
  values: ILeaseFormValue;
}

export interface IMonthlyExpenseFormValue {
  admin: string | number;
  insurance: string | number;
  managementFees: string | number;
  propertyTaxes: string | number;
  associationDues: string | number;
  utilities: string | number;
  mortgage: string | number;
}

export interface IManualMortgageAccount {
  id: number;
  accountName: string;
  balance: number;
  principalBalance: number | null;
  currency: string;
}

export interface IUpdateAccountValueModalProps {
  account?: Account;
  currencySymbol: string;
  updateAccountValueModal: ModalType;
  onSuccess: () => void;
}

export enum EEnterBalance {
  SAME = 'Same balance',
  INCREASE = 'Increase balance',
}

export interface IUpdateAccountFormValues {
  updateForDateRange: boolean;
  asOfDate: Date | undefined;
  accountValue: string | number;
  startingDate: Date | undefined;
  endingDate: Date | undefined;
  howToEnterBalance: EEnterBalance;
  startingValue: string | number;
  endingValue: string | number;
}

export interface IDailyBalancesModalProps {
  account?: Account;
  currencySymbol: string;
  dailyBalancesModal: ModalType;
  onSuccess: () => void;
}

export interface IAccountValue {
  id: number;
  date: string;
  balance: number;
  balanceCurrency: string;
  isAsset: any;
  accountId: number;
  dateString: string;
}

export interface IFixAccountConfig {
  accountId: number;
  update: boolean;
  refresh: boolean;
}

export interface IRefreshParams {
  mmAccountType: EMMAccountType;
  accountId: number;
  hasHoldings: boolean;
}

export interface IAccountActivity {
  date: Date;
  type: string;
  description: string;
  quantity: number | string;
  price: number | string;
  amount: number;
  balance: number;
  income: boolean;
  cashFlow: boolean;
  isIgnored: boolean;
  accountId?: number;
}

export type TAccountStatus =
  | 'INITIAL'
  | 'FASTLINK_INITIATING'
  | 'FASTLINK_INITIATED'
  | 'FASTLINK_CLOSE'
  | 'FASTLINK_ERROR'
  | 'FASTLINK_SUCCESS'
  | 'QROKA_INITIATING'
  | 'INITIATED'
  | 'FAILED'
  | 'SUCCESS';

export interface IAccountState {
  status: TAccountStatus;
  setStatus: React.Dispatch<React.SetStateAction<TAccountStatus>>;
}
