import React from 'react';
import Table from 'react-bootstrap/Table';

import { gc } from 'common/interval-parser';
import classNames from 'common/classes.helper';
import { EConnectionStatus } from 'common/common.types';
import { IAccount, IGroup } from 'networth/networth.type';
import useSortableData from 'common/hooks/useSortableData';
import { fNumber, numberWithCommas } from 'common/number.helper';

import SortIcon from './sort-icon';

interface INetworthByTypeTable {
  item: IGroup;
  currencySymbol: string;
  toggleGroupTable: (item: string) => void;
  handleAccountClick: (accountId: number) => void;
}

const NetworthByTypeTable: React.FC<INetworthByTypeTable> = ({
  item,
  currencySymbol,
  toggleGroupTable,
  handleAccountClick,
}) => {
  const { sortConfig, items: sortedAccounts, requestSort } = useSortableData<IAccount>(item.accounts || []);

  const getStatusClass = (connectionStatus?: string) => {
    if (!connectionStatus) return '';
    const status = connectionStatus as EConnectionStatus;

    const warning = [EConnectionStatus.STALE, EConnectionStatus.ATTENTION].includes(status) ? 'warning' : null;
    const error = [EConnectionStatus.ERROR].includes(status) ? 'error' : null;

    return classNames(warning, error);
  };

  return (
    <Table className='tb-responsive' id='table-other-xls'>
      <thead>
        <tr>
          <th>
            <span
              role='button'
              className={!item.collapse ? 't-span' : ''}
              onClick={() => toggleGroupTable(item.groupName)}
            />
            <span role='button' className='table-header__item' onClick={() => requestSort({ key: 'accountName' })}>
              {item.groupName}
              <SortIcon sortConfig={sortConfig} sortKey='accountName' />
            </span>
          </th>
          {item.accounts?.[0]?.balances.map((acc: any, idx: number) => (
            <th
              key={idx}
              className={classNames(gc(acc.interval), 'table-header__item')}
              onClick={() => requestSort({ key: acc.interval, isBalance: true })}
            >
              {acc.interval}
              <SortIcon sortConfig={sortConfig} sortKey={acc.interval} />
            </th>
          ))}
        </tr>
      </thead>
      {item.collapse ? (
        <tbody>
          {sortedAccounts?.map((account, accIndex: number) => {
            return (
              <tr key={accIndex} onClick={() => handleAccountClick(account.accountId)}>
                <td>
                  <span
                    className={classNames(
                      account.isRefreshing &&
                        !account.connectionStatus &&
                        account.mmAccountProvider === 'YODLEE' &&
                        'mm-loader'
                    )}
                  />
                  <span
                    className={classNames(
                      'table-account-name connection-status',
                      account.isArchived && 'closed-account',
                      getStatusClass(account.connectionStatus)
                    )}
                  >
                    {account.accountName}
                  </span>
                </td>
                {account.balances.map((b: any, idx: number) => (
                  <td
                    key={`${accIndex}-${idx}`}
                    className={[b.type === `projection` && `projection`, gc(b.interval)].join(' ')}
                  >
                    <span className={gc(b.interval)}>{b.interval}</span>
                    {b.balance || b.balance === 0 ? currencySymbol : ''}
                    {b.balance || b.balance === 0 ? numberWithCommas(fNumber(b.balance, 2)) : '--'}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      ) : null}
      <tfoot className='projection'>
        <tr data-href='#'>
          <td className='text--primary'>Total</td>
          {item.total.map((nItem: any, idx: number) => (
            <td key={idx} className={[nItem.type === `projection` && `projection`, gc(nItem.interval)].join(' ')}>
              <span className={gc(nItem.interval)}>{nItem.interval}</span>
              {nItem.balance || nItem.balance === 0 ? currencySymbol : ''}
              {nItem.balance || nItem.balance === 0 ? numberWithCommas(fNumber(nItem.balance, 2)) : '--'}
            </td>
          ))}
        </tr>
      </tfoot>
    </Table>
  );
};

export default NetworthByTypeTable;
