import React from 'react';
import { Table } from 'react-bootstrap';

import { gc } from 'common/interval-parser';
import classNames from 'common/classes.helper';
import { AccountCategory } from 'networth/networth.enum';
import { setToggleOther } from 'networth/networth.actions';
import { Placeholder } from 'networth/views/inc/placeholder';
import { fNumber, numberWithCommas } from 'common/number.helper';
import { useNetworthDispatch, useNetworthState } from 'networth/networth.context';
import useSortableData, { defaultCurrentSortConfig } from 'common/hooks/useSortableData';

import SortIcon from './sort-icon';

export interface INetworthSection {
  currencySymbol: string;
  handleAccountClick: (accountId: number) => void;
}

const OtherAssetSection: React.FC<INetworthSection> = ({ currencySymbol, handleAccountClick }) => {
  const dispatch = useNetworthDispatch();
  const { fToggleOther, accounts, groupByCategory, fCategories, networth } = useNetworthState();

  const toggleOther = () => {
    dispatch(setToggleOther(!fToggleOther));
  };

  const otherAssets = accounts?.[AccountCategory.OTHER_ASSETS];

  const {
    sortConfig,
    requestSort,
    items: sortedOtherAssets,
  } = useSortableData(otherAssets || [], defaultCurrentSortConfig);

  return groupByCategory && (fCategories.length === 0 || fCategories.includes('Other Assets')) ? (
    <div className='row mb-40'>
      <div className='col-12'>
        <div className='ct-box box-g'>
          {accounts && Object.keys(accounts).includes('Other Assets') ? (
            <div className='table-holder'>
              <Table className='tb-responsive' id='table-other-xls'>
                <thead>
                  <tr>
                    <th>
                      <span className={!fToggleOther ? 't-span' : ''} onClick={toggleOther} role='button' />
                      <span
                        role='button'
                        className='table-header__item'
                        onClick={() => requestSort({ key: 'accountName' })}
                      >
                        Other Assets
                        <SortIcon sortConfig={sortConfig} sortKey='accountName' />
                      </span>
                    </th>
                    <th
                      className={classNames(!fToggleOther ? 'd-hide' : '', 'table-header__item')}
                      onClick={() => requestSort({ key: 'accountType' })}
                    >
                      Type
                      <SortIcon sortConfig={sortConfig} sortKey='accountType' />
                    </th>
                    {otherAssets?.[0]?.balances.map((item, idx) => (
                      <th
                        key={idx}
                        className={classNames(gc(item.interval), 'table-header__item')}
                        onClick={() => requestSort({ key: item.interval as any, isBalance: true })}
                      >
                        {item.interval}
                        <SortIcon sortConfig={sortConfig} sortKey={item.interval} />
                      </th>
                    ))}
                  </tr>
                </thead>
                {fToggleOther ? (
                  <tbody>
                    {sortedOtherAssets?.map((oAsset, index) => {
                      return (
                        <tr key={index} onClick={() => handleAccountClick(oAsset.accountId)}>
                          <td>
                            <span className='table-account-name'>{oAsset.accountName}</span>
                          </td>
                          <td className='hide-type'>{oAsset.accountType}</td>
                          {oAsset.balances.map((b, idx) => (
                            <td
                              key={`${index}-${idx}`}
                              className={[b.type === `projection` && `projection`, gc(b.interval)].join(' ')}
                            >
                              <span className={gc(b.interval)}>{b.interval}</span>
                              {b.balance || b.balance === 0 ? currencySymbol : ''}
                              {b.balance || b.balance === 0 ? numberWithCommas(fNumber(b.balance, 2)) : '--'}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                ) : null}
                <tfoot className={'projection'}>
                  <tr data-href='#'>
                    <td className={'text--primary'}>Total</td>
                    <td className={[!fToggleOther ? 'd-hide' : '', `hide-type`].join(' ')}>{''}</td>
                    {networth?.map((nItem, idx) => (
                      <td
                        key={idx}
                        className={[nItem.type === `projection` && `projection`, gc(nItem.interval)].join(' ')}
                      >
                        <span className={gc(nItem.interval)}>{nItem.interval}</span>
                        {nItem.otherAssets || nItem.otherAssets === 0 ? currencySymbol : ''}
                        {nItem.otherAssets || nItem.otherAssets === 0
                          ? numberWithCommas(fNumber(nItem.otherAssets, 2))
                          : '--'}
                      </td>
                    ))}
                  </tr>
                </tfoot>
              </Table>
            </div>
          ) : (
            <Placeholder type='other' />
          )}
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default OtherAssetSection;
