import { useEffect, useState } from 'react';

import { EState } from 'common/common.types';
import { IInvestingEntity, TInvestingEntityPayload } from 'setting/setting.type';
import {
  postInvestingEntity,
  getInvestingEntities,
  patchInvestingEntity,
  deleteInvestingEntity,
} from 'api/request.api';

const useInvestingEntity = () => {
  const [status, setStatus] = useState(EState.INITIAL);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [investingEntities, setInvestingEntities] = useState<IInvestingEntity[]>();

  useEffect(() => {
    (async () => {
      setStatus(EState.LOADING);
      const { data, error } = await getInvestingEntities();

      if (error || !data) {
        return setStatus(EState.WITH_ERROR);
      }
      setStatus(EState.WITH_DATA);

      return setInvestingEntities(data);
    })();

    return () => setStatus(EState.INITIAL);
  }, [refreshCounter]);

  const addInvestingEntity = async (payload: TInvestingEntityPayload) => {
    setStatus(EState.LOADING);
    const { data, error } = await postInvestingEntity(payload);

    if (error || !data) {
      return setStatus(EState.WITH_ERROR);
    }

    setStatus(EState.WITH_DATA);

    return refresh();
  };

  const updateInvestingEntity = async (id: string, payload: TInvestingEntityPayload) => {
    setStatus(EState.LOADING);
    const { data, error } = await patchInvestingEntity(id, payload);

    if (error || !data) {
      return setStatus(EState.WITH_ERROR);
    }

    setStatus(EState.WITH_DATA);

    return refresh();
  };

  const removeInvestmentEntity = async (id: string) => {
    setStatus(EState.LOADING);
    const res = await deleteInvestingEntity(id);

    if (res.error) {
      setStatus(EState.WITH_ERROR);

      return res;
    }

    setStatus(EState.WITH_DATA);
    refresh();

    return res;
  };

  const refresh = () => setRefreshCounter((c) => c + 1);

  return { status, investingEntities, refresh, addInvestingEntity, updateInvestingEntity, removeInvestmentEntity };
};

export default useInvestingEntity;
