import { AccountCategory, NetworthActionEnum, TimeIntervalEnum } from './networth.enum';
import { AccountItem, Action, IFilter, INetworthProcessing, NetworthItem, NetworthState } from './networth.type';

export const setFilterFromDate = (fromDate: string): Action => ({
  type: NetworthActionEnum.SET_F_FROM_DATE,
  payload: {
    fromDate,
  },
});

export const setFilterToDate = (toDate?: string): Action => ({
  type: NetworthActionEnum.SET_F_TO_DATE,
  payload: {
    toDate,
  },
});

export const setFilterCategories = (fCategory: string) => ({
  type: NetworthActionEnum.SET_F_CATEGORY,
  payload: { fCategory },
});

export const setFilterAccount = (fAccountId: number) => ({
  type: NetworthActionEnum.SET_F_ACCOUNT,
  payload: { fAccountId },
});

export const setFilterAccountType = (fAccountType: string) => ({
  type: NetworthActionEnum.SET_F_ACCOUNT_TYPE,
  payload: { fAccountType },
});

export const setFilterTimeInterval = (fTimeInterval: TimeIntervalEnum) => ({
  type: NetworthActionEnum.SET_F_TIME_INTERVAL,
  payload: { fTimeInterval },
});

export const setFilterInvestingEntity = (investingEntityId: number) => ({
  type: NetworthActionEnum.SET_F_ENTITY,
  payload: { investingEntityId },
});

export const setFilterLiquidity = (liquidity: string) => ({
  type: NetworthActionEnum.SET_F_LIQUIDITY,
  payload: { liquidity },
});

export const setNetWorth = (networth: NetworthItem[]) => ({
  type: NetworthActionEnum.SET_NETWORTH,
  payload: { networth },
});

export const setAccounts = (accounts: Record<AccountCategory, AccountItem[]>) => ({
  type: NetworthActionEnum.SET_ACCOUNTS,
  payload: { accounts },
});

export const setToggleInvestment = (fToggleInvestment: boolean) => ({
  type: NetworthActionEnum.SET_F_TOGGLE_INVESTMENT,
  payload: { fToggleInvestment },
});

export const setToggleOther = (fToggleOther: boolean) => ({
  type: NetworthActionEnum.SET_F_TOGGLE_OTHER,
  payload: { fToggleOther },
});

export const setToggleLiabilities = (fToggleLiabilities: boolean) => ({
  type: NetworthActionEnum.SET_F_TOGGLE_LIABILITIES,
  payload: { fToggleLiabilities },
});

export const setToggleNet = (fToggleNet: boolean) => ({
  type: NetworthActionEnum.SET_F_TOGGLE_NET,
  payload: { fToggleNet },
});

export const clearFilter = () => ({
  type: NetworthActionEnum.CLEAR_FILTER,
});

export const setAccountCount = (accountCount: number) => ({
  type: NetworthActionEnum.SET_ACCOUNT_COUNT,
  payload: { accountCount },
});

export const toggleGroupByCategory = () => ({
  type: NetworthActionEnum.TOGGLE_GROUP_BY_CATEGORY,
});

export const setProcessing = (key: keyof INetworthProcessing) => ({
  type: NetworthActionEnum.SET_PROCESSING,
  payload: { key },
});

export const resetProcessing = (key: keyof INetworthProcessing) => ({
  type: NetworthActionEnum.RESET_PROCESSING,
  payload: { key },
});

export const setFilters = (filters: IFilter[]) => ({
  type: NetworthActionEnum.SET_FILTERS,
  payload: { filters },
});

export const addFilter = (filter: IFilter) => ({
  type: NetworthActionEnum.ADD_FILTER,
  payload: { filter },
});

export const updateFilter = (filter: IFilter) => ({
  type: NetworthActionEnum.UPDATE_FILTER,
  payload: { filter },
});

export const removeContextFilter = (filterId: string) => ({
  type: NetworthActionEnum.REMOVE_FILTER,
  payload: { filterId },
});

export const bulkUpdate = (networthFilter: Partial<NetworthState>) => ({
  type: NetworthActionEnum.F_BULK_UPDATE,
  payload: { networthFilter },
});
