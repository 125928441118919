import React from 'react';

import { IAccountDetails } from 'auth/auth.types';
import { camelCase } from 'common/helper/string.helper';
import { fNumber, numberWithCommas } from 'common/number.helper';
import { IInitialCounter } from 'account/hooks/useAccountDetail';
import useAccountSideCard from 'account/hooks/useAccountSideCard';
import { EMMAccountSubType, EMMAccountType } from 'account/enum/account-type';

import MortgageSideCard from './mortgage-side-card';
import BusinessSideCard from './business-side-card';
import PersonalRealEstateSideCard from './personal-real-estate-side-card';

interface IAccountSideCard {
  id: number;
  type: string;
  subType: string;
  balance?: number;
  currencySymbol: string;
  accountDetails: IAccountDetails;
  handleValueClick: VoidFunction;
  handleRefresh: (key: keyof IInitialCounter) => void;
}

enum EAccountType {
  MORTGAGES = 'mortgages',
  BUSINESSES = 'businesses',
}

const AccountSideCard: React.FC<IAccountSideCard> = ({
  id,
  type,
  balance,
  subType,
  handleRefresh,
  accountDetails,
  currencySymbol,
  handleValueClick,
}) => {
  const { formFields, hasSideCard } = useAccountSideCard(type);
  const isRealEstateFundAccount = type === EMMAccountType.REAL_ESTATE && subType === EMMAccountSubType.REAL_ESTATE_FUND;
  const isPersonalRealEsateAccount =
    type === EMMAccountType.REAL_ESTATE && subType === EMMAccountSubType.PERSONAL_PROPERTY;

  const currencyFields = ['balance', 'equity', 'amountInvested', 'netIncome'];

  const getValue = (key: string) => {
    if ('balance' === key && balance) {
      return currencySymbol + numberWithCommas(fNumber(balance));
    }

    const value = accountDetails[key as keyof IAccountDetails] || '';

    if (value && currencyFields.includes(key)) {
      return currencySymbol + numberWithCommas(fNumber(value as string | number, 0));
    }

    return value;
  };

  if (!hasSideCard || isRealEstateFundAccount) {
    return null;
  }

  if (isPersonalRealEsateAccount) {
    return <PersonalRealEstateSideCard accountId={id} handleReload={handleRefresh} />;
  }

  if (EAccountType.MORTGAGES === camelCase(type)) {
    return <MortgageSideCard id={id} balance={balance} handleRefresh={handleRefresh} />;
  }

  if (EAccountType.BUSINESSES === camelCase(type)) {
    return (
      <BusinessSideCard
        id={id}
        formFields={formFields}
        handleRefresh={handleRefresh}
        associatedLoan={accountDetails.associatedLoan}
      />
    );
  }

  return (
    <div className='col-lg-3 account-sidecard-wrapper'>
      <div className='account-sidecard'>
        <ul className='account-sidecard__list'>
          {formFields.map((datum, index) => {
            return (
              <li className='account-sidecard__list-item' key={index}>
                <span className='account-sidecard__list-item--title'>{datum.label}</span>

                {!!getValue(datum.name) ? (
                  <button type='button' className='account-sidecard__list-item--value' onClick={handleValueClick}>
                    {getValue(datum.name)}
                  </button>
                ) : (
                  <span className='account-sidecard__list-item--placeholder'>-</span>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default AccountSideCard;
