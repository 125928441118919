import React from 'react';

import AppSubHeader from 'common/app.sub-header';
import { NetworthHeadProps } from 'networth/networth.type';

const NetworthHead: React.FC<NetworthHeadProps> = ({ groupByCategory, toggleGroupByCategory }) => {
  return (
    <div className='app-subheader-container'>
      <AppSubHeader />
      <div className='right-box'>
        <span className='mm-switch-block d-flex align-items-center base-currency-toggle'>
          <input
            value='true'
            name='baseCurrency'
            type='checkbox'
            aria-checked={groupByCategory}
            className='mm-switch-input'
            checked={groupByCategory}
            onChange={() => {}}
          />
          <label className='mm-switch' onClick={toggleGroupByCategory} role='button' />
          <span className='d-none d-md-block ml-2 view-in-base-currency'>Group by category</span>
          <span className='d-md-none ml-2 view-in-base-currency'> Category</span>
        </span>
      </div>
    </div>
  );
};

export default NetworthHead;
