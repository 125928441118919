import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Formik } from 'formik';
import BBBImg from 'assets/images/home/acc_bus.png';
import { useAuthState } from 'auth/auth.context';
import { AuthLayout } from 'layouts/auth.layout';
import { postForgotPassword } from 'api/request.api';
import { forgotPasswordValidation } from 'auth/auth.validation';
import { ReactComponent as LogoImg } from 'assets/icons/logo.svg';
import { ReactComponent as NortonImg } from 'assets/images/account/norton_black.svg';
import { ReactComponent as LoginLockIcon } from 'assets/images/login/lock-icon.svg';
import { ReactComponent as LoginShieldIcon } from 'assets/images/login/shield-icon.svg';
import { ReactComponent as TrustPilotImg } from 'assets/images/account/trustpilot_black.svg';

import Message from './inc/message';

const ForgotPassword = () => {
  return (
    <AuthLayout>
      <HelmetProvider>
        <Helmet>
          <title>Forgot Password | Money Minx</title>
          <meta name='description' content='Reset your Money Minx password if you forgot it.' />
          {/*Facebook tags*/}
          <meta property='og:title' content='Forgot Password | Money Minx' />
          <meta property='og:description' content='Reset your Money Minx password if you forgot it.' />
          <meta property='og:url' content='https://www.moneyminx.com/forgot-password' />
          <meta property='og:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
          <meta property='og:image:secure_url' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
          <meta property='og:image:alt' content='Reset your Money Minx password if you forgot it.' />
          {/*Twitter tags*/}
          <meta property='twitter:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
          <meta property='twitter:image:alt' content='Reset your Money Minx password if you forgot it.' />
        </Helmet>
      </HelmetProvider>
      <ForgotPasswordMainSection />
    </AuthLayout>
  );
};

export default ForgotPassword;

export const ForgotPasswordMainSection = () => {
  const { email } = useAuthState();
  const [status, setStatus] = useState<string>('initial');
  const [message, setMessage] = useState<string>('');
  const [isMessage, setIsMessage] = useState<boolean>(false);

  const handleDismiss = () => {
    setIsMessage(false);
  };

  const isErrorMessage = status === 'error' && isMessage;
  const isSuccessMessage = status === 'success' && isMessage;

  return (
    <div className='main-table-wrapper'>
      <div className=''>
        <div className='row login-wrapper'>
          <div className='guide-content'>
            <Link to='/'>
              <LogoImg className='icon auth-logo' />
            </Link>

            <div className='auth-left-content'>
              <h1>Three easy steps to get started with Money Minx</h1>
              <ul>
                <li>Find your accounts.</li>
                <li>Connect securely to Money Minx.</li>
                <li>Let Money Minx do the rest.</li>
              </ul>
              <div className='guide-bottom'>
                <h2>Serious about security</h2>
                <div className='guide-icon-wrap'>
                  <span className='locked-icon'>
                    <LoginLockIcon />
                  </span>
                  <p>The security of your information is our top priority</p>
                </div>
                <h2>Trusted by investors</h2>
                <div className='guide-icon-wrap'>
                  <span className='shield-icon'>
                    <LoginShieldIcon />
                  </span>
                  <p>Investors from all over the world are using Money Minx</p>
                </div>
              </div>
              <div className='m-b-3 d-flex flex-row'>
                <a
                  className='m-r-4'
                  href='https://www.trustpilot.com/review/www.moneyminx.com'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <TrustPilotImg />
                </a>
                <NortonImg className='m-r-4' />
                <img height='38' src={BBBImg} alt='Money Minx Acc Bus' />
              </div>
            </div>
          </div>

          <div className='bg-white credentials-wrapper'>
            <div className='credentials-content'>
              <Link to='/' className='logo-img-wrapper'>
                <LogoImg className='auth-logo' />
              </Link>
              <h2>Forgot Password?</h2>
              <p>Can’t log in? No worries, enter your email below and we will send you a password reset link.</p>

              <Formik
                initialValues={{ email }}
                validationSchema={forgotPasswordValidation}
                validateOnChange={false}
                onSubmit={async (values, actions) => {
                  const { error, data } = await postForgotPassword(values.email);

                  if (error) {
                    setStatus('error');
                    setIsMessage(true);
                    setMessage(error?.message || '');
                  } else {
                    setStatus('success');
                    setIsMessage(true);
                    setMessage(data.message || '');
                  }
                  actions.setSubmitting(false);
                }}
              >
                {(props) => (
                  <div className='form-wrap'>
                    <form onSubmit={props.handleSubmit}>
                      <div className='align-items-start input-wrapper'>
                        <div className='email-wrap'>
                          <label htmlFor='email-field' className='form-subheading'>
                            Email address
                          </label>
                          <input
                            id='email-field'
                            type='text'
                            className='email'
                            name='email'
                            value={props.values.email}
                            placeholder='Your Email'
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                          />
                        </div>
                        {props.errors.email && <div className='ml-2 mt-1 mt-md-3 feedback'>{props.errors.email}</div>}
                      </div>
                      <button
                        className='mm-btn-animate mm-btn-primary m-b-5'
                        type='submit'
                        disabled={props.isSubmitting}
                      >
                        Reset Password
                      </button>
                    </form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      {isErrorMessage ? (
        <div>
          <Message type={status} message={message} onDismiss={handleDismiss} />
        </div>
      ) : null}

      {isSuccessMessage ? (
        <div>
          <Message type={status} message={message} onDismiss={handleDismiss} />
        </div>
      ) : null}
    </div>
  );
};
