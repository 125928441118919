import { useEffect, useRef } from 'react';

import { storage } from 'app/app.storage';
import { StorageKey } from 'app/app.types';
import { EProviderType } from 'auth/auth.types';
import useMMLocation from 'common/hooks/useMMLocation';
import { reconnectSaltEdgeAccount, refreshSaltEdgeAccount } from 'api/request.api';

interface IRefreshSaltEdgeData {
  expiresAt: string | Date;
  connectUrl: string;
}

interface IRefreshSaltEdgeResponse {
  data: IRefreshSaltEdgeData;
  error: any;
}

export const useRemoveSaltEdgeLocation = () => {
  const { clearLocation, isFromSaltEdge } = useSaltEdgeLocation();
  const clearSaltedgeLocation = useRef(clearLocation);
  const fromSaltEdge = isFromSaltEdge();

  useEffect(() => {
    if (fromSaltEdge) {
      clearSaltedgeLocation.current();
    }
  }, [fromSaltEdge]);
};

export const useSaltEdgeLocation = () => {
  const { search, location, addQuery } = useMMLocation();

  return {
    addQuery,
    isFromSaltEdge: () =>
      search.get('from') === 'SaltEdge' &&
      (search.get('apiType') === EProviderType.SALT_EDGE_GATEWAY ||
        search.get('apiType') === EProviderType.SALT_EDGE_PARTNER),
    setLocation: () => {
      storage.set(StorageKey.SALT_EDGE_LOCATION, location);
    },
    getLocation: () => {
      const { data, error } = storage.get(StorageKey.SALT_EDGE_LOCATION);

      if (!error && data) {
        return data as unknown as Location;
      }
    },
    clearLocation: () => {
      return storage.clear(StorageKey.SALT_EDGE_LOCATION);
    },
  };
};

const useSaltEdge = () => {
  const { setLocation } = useSaltEdgeLocation();
  const refreshSaltEdge = async (accountId?: number) => {
    if (!accountId) return;
    setLocation();

    const { data, error }: IRefreshSaltEdgeResponse = await refreshSaltEdgeAccount({
      accountId,
      refresh: true,
    });

    if (!error) {
      return data;
    }
  };

  const reconnectSaltEdge = async (accountId?: number) => {
    if (!accountId) return;
    setLocation();

    const { data, error }: IRefreshSaltEdgeResponse = await reconnectSaltEdgeAccount({
      accountId,
      reconnect: true,
    });

    if (!error) {
      return data;
    }
  };

  return { refreshSaltEdge, reconnectSaltEdge };
};

export default useSaltEdge;
