import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/esm/Dropdown';

import { formater } from 'common/common-helper';

interface MMSelectProps {
  title: string;
}

const MMSelect: React.FC<MMSelectProps> = ({ children, title }) => {
  return (
    <Dropdown className='drop-box'>
      <Dropdown.Toggle className='dropdown-toggle'>{title}</Dropdown.Toggle>
      <Dropdown.Menu className='mm-dropdown-menu'>{children}</Dropdown.Menu>
    </Dropdown>
  );
};

export default MMSelect;

interface SelectInputProps {
  args: any[];
  onChange: (e: React.ChangeEvent<any>) => void;
  value: string;
  name: string;
  format?: boolean;
  sort?: boolean;
  displayName?: string;
}

export const SelectInput: React.FC<SelectInputProps> = ({
  name,
  args,
  value,
  format,
  onChange,
  displayName,
  sort = true,
}) => {
  const [show, setShow] = useState(false);

  if (sort && args) {
    args.sort();
  }

  const getTitle = () => {
    if (displayName) {
      return displayName;
    }

    if (format) {
      return formater(value);
    }

    return value;
  };

  return (
    <Dropdown className='drop-box dropdown-select-input' onToggle={(nextShow) => setShow(nextShow)} show={show}>
      <Dropdown.Toggle className='dropdown-toggle'>{getTitle()}</Dropdown.Toggle>
      <Dropdown.Menu className='mm-dropdown-menu'>
        <ul className='checkbox-list single'>
          {args?.map((val, index) => {
            return (
              <li key={index}>
                <label>
                  <input
                    name={name}
                    type='checkbox'
                    aria-describedby={val}
                    value={val}
                    aria-checked={val === value}
                    checked={val === value}
                    onChange={(e) => {
                      onChange(e);
                      setShow(false);
                    }}
                  />
                  <span>{format ? formater(val) : val}</span>
                </label>
              </li>
            );
          })}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
};

/**
 * MMSelect Input will take data with name and value
 * data =[
 * {
 *  name: 'Display Name',
 *  value: 4
 * }
 * ]
 */

export interface INameValue {
  name: string;
  value: any;
}

interface IMMSelectInput extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  title: string;
  data: INameValue[];
  value: any;
  onChange: (e: React.ChangeEvent<any>) => void;
}

export const MMSelectInput: React.FC<IMMSelectInput> = ({ name, value, data, title, onChange, readOnly, ...rest }) => {
  const [show, setShow] = useState(false);

  return (
    <Dropdown className='drop-box dropdown-select-input' onToggle={(nextShow) => setShow(nextShow)} show={show}>
      <Dropdown.Toggle className='dropdown-toggle' disabled={readOnly}>
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu className='mm-dropdown-menu'>
        <ul className='checkbox-list single'>
          {data.map((datum, index) => {
            return (
              <li key={index}>
                <label>
                  <input
                    name={name}
                    type='checkbox'
                    aria-describedby={datum.name}
                    value={datum.value}
                    aria-checked={value.toString() === datum.value.toString()}
                    checked={value.toString() === datum.value.toString()}
                    onChange={(e) => {
                      onChange(e);
                      setShow(false);
                    }}
                    {...rest}
                  />
                  <span>{datum.name}</span>
                </label>
              </li>
            );
          })}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
};
