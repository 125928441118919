import _camelCase from 'lodash/camelCase';
import _startCase from 'lodash/startCase';

export const camelCase = (str: string) => _camelCase(str);

export const startCase = (str: string) => _startCase(str);

export const base64Encode = (text: string) => btoa(text);

export const base64Decode = (text: string) => atob(text);
