import React from 'react';

import useQroka from 'auth/hooks/useQroka';
import { isSafari } from 'common/common-helper';
import WalletCard from 'auth/views/inc/wallet-card';
import { ECryptoConnections } from 'auth/auth.enum';
import { isCoinbaseWalletSupported } from 'auth/auth.helper';
import { cryptoConnections } from 'auth/data/crypto-connections';
import useTerraSupportWallet from 'auth/hooks/useTerraSupportWallet';
import { getChainOptions, WalletControllerChainOptions, WalletProvider } from '@terra-money/wallet-provider';

interface ITerraStationConnect {
  onCancel: () => void;
  handleSuccess: () => void;
}

const TerraStationConnect: React.FC<ITerraStationConnect> = (props) => {
  const { connectWithExtension, connectWithMobile, wallets, error, disconnect } = useTerraSupportWallet();
  const { connectTerraStationWallet, loading, errors } = useQroka();
  const connection = cryptoConnections.find((con) => con.type === ECryptoConnections.TERRA_STATION_WALLET);

  const accounts = wallets.map((wallet) => wallet.terraAddress);
  const hasAccounts = accounts.length > 0;

  if (!connection) {
    return <div>Connection not available</div>;
  }

  const handleCoinbaseConnect = async () => {
    await connectTerraStationWallet(accounts)(props.handleSuccess);
  };

  const renderSupportText = () => {
    if (!isCoinbaseWalletSupported()) {
      return (
        <div className='error-block'>
          Use the buttons below to scan the QR code using the Terra Station mobile app. Then click on Add Accounts.
        </div>
      );
    }

    return (
      <div className='key-info'>
        Use the buttons below to connect with the Terra Station extension or scan the QR code. Then click on Add
        Accounts.
      </div>
    );
  };

  return (
    <div className='connect-crypto-wrapper'>
      <WalletCard
        disabled
        name={connection.name}
        url={connection.url}
        logo={connection.logo}
        connection={connection.type}
      />
      {renderSupportText()}
      <div className='connect-button-wrapper multiple-connections'>
        {!isSafari() && (
          <button disabled={loading} onClick={connectWithExtension} className='mm-btn-primary mm-btn-animate'>
            Link Terra Station Extension
          </button>
        )}
        <button disabled={loading} onClick={connectWithMobile} className='mm-btn-primary mm-btn-animate'>
          Scan QR Code
        </button>
        {hasAccounts && isSafari() && (
          <button disabled={loading} onClick={disconnect} className='btn-outline-primary mm-btn-animate'>
            Disconnect
          </button>
        )}
      </div>
      {hasAccounts && !isSafari() && (
        <button
          disabled={loading}
          onClick={disconnect}
          className='btn-outline-primary mm-btn-animate terra-disconnect-button'
        >
          Disconnect
        </button>
      )}

      <div className='chain-accounts-wrapper'>
        {hasAccounts ? <p className='chain-title'>Terra Wallet Addresses</p> : null}
        {accounts.map((id) => (
          <div key={id}>
            <div className='accounts-wrapper'>
              <p>{id}</p>
            </div>
          </div>
        ))}
      </div>
      {error && <div className='error-block'>{error}</div>}
      {errors['Terra Station Wallet'] && <div className='error-block'>{errors['Terra Station Wallet']}</div>}
      <div className='crypto-action-wrapper'>
        <button type='reset' className='btn-outline-primary mm-btn-animate' disabled={loading} onClick={props.onCancel}>
          Cancel
        </button>

        <button
          type='submit'
          className='mm-btn-animate mm-btn-primary '
          disabled={loading}
          onClick={handleCoinbaseConnect}
        >
          {loading ? (
            <>
              <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
              <span className='ml-1'>Adding...</span>
            </>
          ) : (
            <>Add Accounts</>
          )}
        </button>
      </div>
    </div>
  );
};

const TerraStationConnectProvider = (props: ITerraStationConnect) => {
  const [terraWalletChainOptions, setTerraWalletChainOptions] = React.useState<WalletControllerChainOptions>({
    defaultNetwork: {} as any,
    walletConnectChainIds: {},
  });

  React.useLayoutEffect(() => {
    (async () => {
      const chainOptions = await getChainOptions();
      setTerraWalletChainOptions(chainOptions);
    })();
  }, []);

  const hasChainOptions = !!Object.keys(terraWalletChainOptions.walletConnectChainIds).length;

  // TODO: show proper loading message @hussain
  if (!hasChainOptions) {
    return <div className='terra-station-wallet-loading'>Please wait we are fetching network information...</div>;
  }

  return (
    <WalletProvider {...terraWalletChainOptions}>
      <TerraStationConnect {...props} />
    </WalletProvider>
  );
};

export default TerraStationConnectProvider;
