import React, { useEffect, useState, useCallback } from 'react';

import {
  postAssociateMortgageAccount,
  deleteMortageAccountAssociation,
  getAssociatedRealEstateAccounts,
} from 'api/request.api';
import useToast from 'common/hooks/useToast';
import useAnalytics from 'common/hooks/useAnalytics';
import { base64Encode } from 'common/helper/string.helper';
import { Account, IRealEstateAccount } from 'auth/auth.types';
import { IInitialCounter } from 'account/hooks/useAccountDetail';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import CircularSpinner from 'common/components/spinner/circular-spinner';

import AddAssociatedProperty from './add-associated-property';
import AssociatedRealEstateItem from './associated-real-estate-item';
import useRealEstateAccounts from 'auth/hooks/useRealEstateAccounts';

interface IMortgageSideCard {
  id: number;
  balance?: number;
  handleRefresh: (key: keyof IInitialCounter) => void;
}

const MortgageSideCard: React.FC<IMortgageSideCard> = ({ id, handleRefresh, balance: mortgageBalance }) => {
  const { mmToast } = useToast();
  const { event } = useAnalytics();
  const { fetchingRealEstateAccounts, realEstateAccounts } = useRealEstateAccounts();

  const [loading, setLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<string>('');
  const [isAssociateFormVisible, setIsAssociateFormVisible] = useState<boolean>(false);
  const [associatedRealEstateAccounts, setAssociatedRealEstateAccounts] = useState<Account[]>([]);
  const [dropdownRealEstateAccounts, setDropdownRealEstateAccounts] = useState<IRealEstateAccount[]>([]);

  const showAssociatePropertyForm = isAssociateFormVisible || associatedRealEstateAccounts.length <= 0;
  const showAddNewPropertyButton = !isAssociateFormVisible && associatedRealEstateAccounts.length > 0;

  const fetchAndPopulateAssociatedAccounts = useCallback(async (accId: number) => {
    setLoading(true);
    const { data, error } = await getAssociatedRealEstateAccounts(accId);
    setLoading(false);

    if (!error && data) {
      setAssociatedRealEstateAccounts(data);
    }
  }, []);

  useEffect(() => {
    fetchAndPopulateAssociatedAccounts(id);
  }, [id, fetchAndPopulateAssociatedAccounts]);

  useEffect(() => {
    setDefaultDropdownRealEstateAccount(dropdownRealEstateAccounts);
  }, [dropdownRealEstateAccounts]);

  useEffect(() => {
    if (!realEstateAccounts) {
      return;
    }

    const filteredAccounts = realEstateAccounts.filter((item) => {
      const isExists = associatedRealEstateAccounts.find((associatedAcc) => associatedAcc.id === item.id);

      return !isExists;
    });

    setDropdownRealEstateAccounts(filteredAccounts);
  }, [realEstateAccounts, associatedRealEstateAccounts]);

  const setDefaultDropdownRealEstateAccount = (accounts: IRealEstateAccount[]) => {
    const defaultAccount = accounts.length > 0 ? accounts[0].accountName : '';
    setSelectedAccount(defaultAccount);
  };

  const handleReload = () => {
    fetchAndPopulateAssociatedAccounts(id);
    handleRefresh('detail');
  };

  const navigateToAssociatedAccount = (accId: number) => {
    const encodedAccountId = base64Encode(accId.toString());

    event({
      category: 'Account Detail',
      action: 'Clicked on the Mortgage Side Card',
      label: `Accessed account ${accId} with encoded id ${encodedAccountId}`,
    });

    return window.open(`/account-details/${encodedAccountId}`, '_blank');
  };

  const handleDeleteRealEstateAssociation = async (realEstateAccId: number) => {
    setLoading(true);

    await deleteMortageAccountAssociation(id, realEstateAccId);

    handleReload();
    setLoading(false);
    mmToast('Real estate account successfully removed', { type: 'success' });
  };

  const handleAssociateSubmit = async (realEstateAccName: string) => {
    const account = realEstateAccounts.find((acc) => acc.accountName === realEstateAccName);

    if (!account) {
      return mmToast('Something went wrong', { type: 'error' });
    }

    setLoading(true);

    await postAssociateMortgageAccount(id, parseInt(`${account.id}`));

    handleReload();
    setLoading(false);
    setIsAssociateFormVisible(false);
    mmToast('Real estate account successfully linked', { type: 'success' });
  };

  const renderRealEstateItems = () => {
    if (associatedRealEstateAccounts.length <= 0) {
      return null;
    }

    return (
      <div className='associated-real-estate__details'>
        {associatedRealEstateAccounts.map((account) => (
          <AssociatedRealEstateItem
            key={account.id}
            associatedAccount={account}
            mortgageBalance={mortgageBalance}
            handleDelete={handleDeleteRealEstateAssociation}
            navigateToRealEstateAccount={navigateToAssociatedAccount}
          />
        ))}
      </div>
    );
  };

  const renderContent = () => {
    if (loading || fetchingRealEstateAccounts) {
      return <CircularSpinner />;
    }

    return (
      <div className='account-sidecard'>
        <p className='account-sidecard--title'>Properties</p>

        <div className='account-sidecard__body'>
          {renderRealEstateItems()}

          {showAddNewPropertyButton && (
            <button
              className='associated-real-estate__btn-add'
              type='button'
              onClick={() => setIsAssociateFormVisible(true)}
            >
              <PlusIcon />
              <span>Add New Property</span>
            </button>
          )}

          {showAssociatePropertyForm && (
            <AddAssociatedProperty
              loading={loading}
              realEstateAccounts={dropdownRealEstateAccounts.map((item) => item.accountName)}
              defaultSelectedAccount={selectedAccount}
              handleSubmit={handleAssociateSubmit}
            />
          )}
        </div>
      </div>
    );
  };

  return <div className='col-lg-3 account-sidecard-wrapper'>{renderContent()}</div>;
};

export default MortgageSideCard;
