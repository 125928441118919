import React from 'react';
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { gc } from 'common/interval-parser';
import classNames from 'common/classes.helper';
import { EChartValueType } from 'income/income.enum';
import SortIcon from 'networth/components/sort-icon';
import { EConnectionStatus } from 'common/common.types';
import useSortableData from 'common/hooks/useSortableData';
import { base64Encode } from 'common/helper/string.helper';
import { fNumber, numberWithCommas } from 'common/number.helper';

import { Account, IAccountDataForInterval } from '../account-types.types';

interface IAccountsTableProps {
  accounts: Account[];
  mmAccountType: string;
  currencySymbol: string;
  total: IAccountDataForInterval[];
}

const AccountsTable: React.FC<IAccountsTableProps> = ({ accounts, mmAccountType, currencySymbol, total }) => {
  const history = useHistory();
  const { items: sortedAccounts, sortConfig, requestSort } = useSortableData<Account>(accounts);

  const handleAccountsClick = (accountId: number) => {
    const encodedAccountId = base64Encode(accountId.toString());
    const path = `/account-details/${encodedAccountId}`;

    return history.push({
      pathname: path,
      search: `?from=account-types&mmAccountType=${mmAccountType}`,
    });
  };

  const getAccountStatusClass = (account: Account) => {
    const { connectionStatus, mmAccountProvider, isArchived } = account;

    const successProviders = ['ZABO', 'SALT_EDGE_PARTNER', 'SALT_EDGE_GATEWAY'];
    const successStatuses = [EConnectionStatus.GOOD, EConnectionStatus.WAIT];
    const warningStatuses = [EConnectionStatus.ATTENTION, EConnectionStatus.STALE];

    if (successProviders.includes(mmAccountProvider) || successStatuses.includes(connectionStatus)) {
      return 'account--success';
    }

    if (warningStatuses.includes(connectionStatus)) {
      return 'account--warn';
    }

    if (connectionStatus === EConnectionStatus.ERROR) {
      return 'account--error';
    }

    if (isArchived) {
      return 'account--archived';
    }

    if (connectionStatus === EConnectionStatus.REFRESHING) {
      return 'account--refreshing';
    }

    return '';
  };

  const renderAccounts = () => {
    if (!accounts.length) {
      return <span className='no-data'>No accounts found</span>;
    }

    return (
      <div className='ct-box'>
        <span className='hide-before-elements'></span>
        <div className='table-holder sticky-table-holder'>
          <Table className='tb-responsive account balance-table table-account-xls' id='table-net-xls'>
            <thead>
              <tr>
                <th
                  className={classNames('s-hide', 'table-header__item')}
                  onClick={() => requestSort({ key: 'accountName' })}
                >
                  {mmAccountType}
                  <SortIcon sortConfig={sortConfig} sortKey='accountName' />
                </th>

                {accounts[0]?.balances.map((balance) => (
                  <th
                    key={balance.interval}
                    className={classNames(gc(balance.interval), 'table-header__item')}
                    onClick={() => requestSort({ key: balance.interval as any, isBalance: true })}
                  >
                    {balance.interval}
                    <SortIcon sortConfig={sortConfig} sortKey={balance.interval} />
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {sortedAccounts.length > 0 &&
                sortedAccounts.map((account, index) => (
                  <tr key={index}>
                    <td className={getAccountStatusClass(account)}>
                      {getAccountStatusClass(account) === 'account--refreshing' && <span className='mm-loader'></span>}
                      <button className='account-name' onClick={() => handleAccountsClick(account.accountId)}>
                        {account.accountName}
                      </button>
                    </td>

                    {account.balances.map((balanceItem, idx) => (
                      <td
                        key={idx}
                        className={[
                          balanceItem.type === `projection` && `projection holdings-table`,
                          gc(balanceItem.interval),
                        ].join(' ')}
                      >
                        <span className={gc(balanceItem.interval)}>{balanceItem.interval}</span>

                        {balanceItem.balance || balanceItem.balance === 0 ? currencySymbol : ''}
                        {balanceItem.balance || balanceItem.balance === 0
                          ? numberWithCommas(fNumber(balanceItem.balance, 2))
                          : '--'}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>

            <tfoot className='projection'>
              <tr>
                <td className='text--primary'>Total</td>

                {total.map((totalItem, idx) => (
                  <td
                    key={idx}
                    className={[
                      totalItem.type === EChartValueType.PROJECTION && 'projection',
                      gc(totalItem.interval),
                    ].join(' ')}
                  >
                    <span className={gc(totalItem.interval)}>{totalItem.interval}</span>

                    {totalItem.balance || totalItem.balance === 0
                      ? `${currencySymbol}${numberWithCommas(fNumber(totalItem.balance, 2))}`
                      : '--'}
                  </td>
                ))}
              </tr>
            </tfoot>
          </Table>
        </div>
      </div>
    );
  };

  return <section className='accounts-table'>{renderAccounts()}</section>;
};

export default AccountsTable;
