import { IRealEstateAccount, Mortgage } from 'auth/auth.types';

export const initialMortgage: Mortgage = {
  id: '',
  principalBalance: 0,
  balance: 0,
  accountName: '',
};

export const initialAccount: IRealEstateAccount = {
  id: '',
  accountName: '',
  balance: '',
};

/**
 * This is the form field list in order
 * @here please don't change the order.
 */
export const formFieldList = [
  'mmAccountType',
  'mmAccountSubType',
  'investingEntityId',
  'accountName',
  'streetAddress',
  'city',
  'state',
  'zipCode',
  'country',
  'currency',
  'liquidity',
  'companySymbol',
  'showAsInvestmentAsset',
  'isRetirement',
  'is401k',
  'employerMatchContribution',
  'employerMatch',
  'employerMatchLimitIn',
  'employerMatchLimit',
  'includeEmployerMatch',
  'interestRate',
  'annualPercentageYield',
  'originalBalance',
  'originalLoanAmount',
  'originalLoanAmountCurrency',
  'term',
  'maturityDate',
  'amountInvested',
  'investedDate',
  'propertyType',
  'beds',
  'bath',
  'ownership',
  'industry',
  'investmentRound',
  'investmentType',
  'cap',
  'postMoneyValuation',
  'website',
  'originationDate',
  'amountDue',
  'frequency',
  'estimatedAnnualReturns',
];

export const formFieldTopSectionList = [
  'mmAccountType',
  'mmAccountSubType',
  'investingEntityId',
  'accountName',
  'currency',
  'liquidity',
  'showAsInvestmentAsset',
  'isRetirement',
  'is401k',
  'employerMatchContribution',
  'employerMatch',
  'employerMatchLimitIn',
  'includeEmployerMatch',
];

export const formFieldSecondSectionList = [
  'companySymbol',
  'streetAddress',
  'city',
  'state',
  'zipCode',
  'country',
  'interestRate',
  'annualPercentageYield',
  'originalLoanAmount',
  'term',
  'amountInvested',
  'investedDate',
  'propertyType',
  'beds',
  'baths',
  'ownership',
  'industry',
  'investmentRound',
  'investmentType',
  'cap',
  'postMoneyValuation',
  'website',
  'originationDate',
  'frequency',
  'amortizationPeriod',
  'compoundPeriod',
  'amountDue',
  'useZestimate',
];

export const formFieldLastSectionList = ['estimatedAnnualReturns', 'isArchived'];
