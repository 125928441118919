import React from 'react';

import { ReactComponent as PricingTickIcon } from 'assets/images/pricing/tick-icon.svg';

import freePlanFeatures from '../../data/free-plan-features.json';

const FreePlanFeatures: React.FC = () => {
  return (
    <ul className='features-list p-b-12'>
      {freePlanFeatures.map((feature) => (
        <li key={feature}>
          <div className='tick-icon'>
            <PricingTickIcon />
          </div>
          {feature}
        </li>
      ))}
    </ul>
  );
};

export default FreePlanFeatures;
