import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import queryString from 'query-string';
import { useModal } from 'common/components/modal';
import useInitialModal from 'auth/hooks/useInitialModal';
import DashboardHead from 'dashboard/inc/dashboard.head';
import DashboardBody from 'dashboard/inc/dashboard.body';
import { base64Encode } from 'common/helper/string.helper';
import { appRouteConstants } from 'app/app-route.constant';
import AddAccountsModal from 'account/views/add-account.modal';
import SignUpDoneModal from 'auth/views/inc/signup-done.modal';
import AccountAddedModal from 'auth/views/inc/account-added-modal';

import DashboardLayout from './dashboard.layout';

const Dashboard: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const signupDoneModal = useModal();
  const addAccountModal = useModal();
  const accountAddedModal = useModal();

  const searchQuery = queryString.parse(location.search);
  const isFromAccountSettings = 'accountSettings' === searchQuery.from;
  const isFromConnectAccount = 'connectAccount' === searchQuery.origin;

  const addedAccountId = searchQuery.accountId as string | null;
  const addedAccountType = searchQuery.accountType as string | null;
  const addedAccountHoldings = searchQuery.holdings as string | null;

  useInitialModal(isFromAccountSettings, accountAddedModal);
  useInitialModal(isFromConnectAccount, addAccountModal);

  const getCleanSearchParam = () => {
    const params = new URLSearchParams(location.search);

    params.delete('refetchAccounts');
    params.delete('origin');
    params.delete('provider');
    params.delete('from');
    params.delete('accountId');
    params.delete('accountType');
    params.delete('holdings');

    return params.toString();
  };

  const cleanSearchParams = () => {
    const params = getCleanSearchParam();

    history.replace({
      search: params.toString(),
    });
  };

  const handleOpenAddAccountModal = () => {
    cleanSearchParams();

    accountAddedModal.close();
    addAccountModal.open();
  };

  const handleNavigateToAccountDetail = () => {
    cleanSearchParams();

    if (addedAccountId) {
      const encodedAccountId = base64Encode(addedAccountId);
      return history.push('/account-details/' + encodedAccountId);
    }
  };

  const gotoConnectAccount = () => {
    return history.push(`${appRouteConstants.auth.CONNECT_ACCOUNT}?action=addMoreAccount`);
  };

  const renderDashboard = () => {
    return (
      <>
        <DashboardHead />
        <hr className='m-0' />
        <div className='content-wrapper'>
          <div className='container'>
            <DashboardBody />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <DashboardLayout>
        <HelmetProvider>
          <Helmet>
            <title>Dashboard | Money Minx</title>
          </Helmet>
        </HelmetProvider>
        <section className='dashboard-container'>{renderDashboard()}</section>
      </DashboardLayout>

      <AddAccountsModal addAccountModal={addAccountModal} />

      <AccountAddedModal
        accountAddedModal={accountAddedModal}
        addedAccountType={addedAccountType}
        addedAccountHoldings={addedAccountHoldings}
        handleSuccess={handleOpenAddAccountModal}
        navigateToAccountDetail={handleNavigateToAccountDetail}
      />

      {signupDoneModal.props.open && (
        <SignUpDoneModal signupModal={signupDoneModal} handleSuccess={gotoConnectAccount} />
      )}
    </>
  );
};

export default Dashboard;
