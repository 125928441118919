import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import privacyData from '@mm/data/privacy.json';
import WebsiteLayout from 'website/website.layout';

const { privacy } = privacyData;

const privacyContentSection = () => {
  const privacyContent = privacy.data.map((d, i) => {
    return (
      <div key={i}>
        <h2 className='notice-content-title'>{d.title}</h2>
        <div className='notice-content' dangerouslySetInnerHTML={{ __html: d.content }} />
      </div>
    );
  });
  return <>{privacyContent}</>;
};

const Privacy = () => {
  return (
    <WebsiteLayout>
      <HelmetProvider>
       <Helmet>
         <title>Privacy Policy | Money Minx</title>
         <meta name='description' content='The Privacy Policy for Money Minx.' />
         {/*Facebook tags*/}
         <meta property='og:title' content='Privacy Policy | Money Minx' />
         <meta property='og:description' content='The Privacy Policy for Money Minx.' />
         <meta property='og:url' content='https://www.moneyminx.com/privacy' />
         <meta property='og:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
         <meta property='og:image:secure_url' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
         <meta property='og:image:alt' content='The Privacy Policy for Money Minx.' />
         {/*Twitter tags*/}
         <meta property='twitter:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Main.png' />
         <meta property='twitter:image:alt' content='The Privacy Policy for Money Minx.' />
       </Helmet>
      </HelmetProvider>
      <div className='mm-container wrapper notice-wrapper'>
        <div className='notice-header'>
          <h1>{privacy.title}</h1>
          <p className='notice-date'>{privacy.update}</p>
          <div dangerouslySetInnerHTML={{ __html: privacy.info }}/>
        </div>
        {privacyContentSection()}
      </div>
    </WebsiteLayout>
  );
};

export default Privacy;
