import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { gc } from 'common/interval-parser';
import classNames from 'common/classes.helper';
import { AccountCategory } from 'networth/networth.enum';
import { Placeholder } from 'networth/views/inc/placeholder';
import { setToggleInvestment } from 'networth/networth.actions';
import { fNumber, numberWithCommas } from 'common/number.helper';
import { useNetworthDispatch, useNetworthState } from 'networth/networth.context';
import useSortableData, { defaultCurrentSortConfig } from 'common/hooks/useSortableData';

import SortIcon from './sort-icon';
import { INetworthSection } from './other-assets-section';

const InvestmentAssetSection: React.FC<INetworthSection> = ({ currencySymbol, handleAccountClick }) => {
  const dispatch = useNetworthDispatch();
  const { groupByCategory, fCategories, accounts, fToggleInvestment, networth } = useNetworthState();
  const investmentAssets = accounts?.[AccountCategory.INVESTMENT_ASSETS];

  const toggleInvestment = () => {
    dispatch(setToggleInvestment(!fToggleInvestment));
  };

  const {
    sortConfig,
    requestSort,
    items: sortedInvestmentAssets,
  } = useSortableData(investmentAssets || [], defaultCurrentSortConfig);

  return groupByCategory && (fCategories.length === 0 || fCategories.includes('Investment Assets')) ? (
    <div className='row mb-40'>
      <div className='col-12'>
        <div className='ct-box box-b'>
          {accounts && Object.keys(accounts).includes('Investment Assets') ? (
            <div className='table-holder'>
              <Table className='tb-responsive' id='table-investment-xls'>
                <thead>
                  <tr data-toggle='collapse'>
                    <th>
                      <span className={!fToggleInvestment ? 't-span' : ''} onClick={toggleInvestment} role='button' />
                      <span
                        role='button'
                        className='table-header__item'
                        onClick={() => requestSort({ key: 'accountName' })}
                      >
                        Investment Assets
                        <SortIcon sortConfig={sortConfig} sortKey='accountName' />
                      </span>
                    </th>
                    <th
                      className={classNames(!fToggleInvestment ? 'd-hide' : '', 'table-header__item')}
                      onClick={() => requestSort({ key: 'accountType' })}
                    >
                      Type
                      <SortIcon sortConfig={sortConfig} sortKey='accountType' />
                    </th>

                    {investmentAssets?.[0]?.balances.map((item, idx) => (
                      <th
                        key={idx}
                        className={classNames(gc(item.interval), 'table-header__item')}
                        onClick={() => requestSort({ key: item.interval as any, isBalance: true })}
                      >
                        {item.interval}
                        <SortIcon sortConfig={sortConfig} sortKey={item.interval} />
                      </th>
                    ))}
                  </tr>
                </thead>
                {fToggleInvestment ? (
                  <tbody>
                    {sortedInvestmentAssets
                      ? sortedInvestmentAssets.map((iAsset, index) => {
                          return (
                            <tr key={index} onClick={() => handleAccountClick(iAsset.accountId)}>
                              <td>
                                <span className='table-account-name'>{iAsset.accountName}</span>
                              </td>
                              <td className='hide-type'>{iAsset.accountType}</td>
                              {iAsset.balances.map((b, idx) => (
                                <td
                                  key={`${index}-${idx}`}
                                  className={[b.type === `projection` && `projection`, gc(b.interval)].join(' ')}
                                >
                                  <span className={gc(b.interval)}>{b.interval}</span>
                                  {b.balance || b.balance === 0 ? currencySymbol : ''}
                                  {b.balance || b.balance === 0 ? numberWithCommas(fNumber(b.balance, 2)) : '--'}
                                </td>
                              ))}
                            </tr>
                          );
                        })
                      : // show place holder here
                        null}
                  </tbody>
                ) : null}
                <tfoot className={'projection'}>
                  <tr data-href='#'>
                    <td>
                      <Link
                        to='#'
                        className='warning-popover'
                        data-classname='warning-pop'
                        data-container='body'
                        title='Warning'
                        data-toggle='popover'
                        data-placement='right'
                        data-content=''
                      >
                        Total
                      </Link>
                    </td>
                    <td className={[!fToggleInvestment ? 'd-hide' : '', `hide-type`].join(' ')}>{''}</td>
                    {networth?.map((nItem, idx) => (
                      <td
                        key={idx}
                        className={[nItem.type === `projection` && `projection`, gc(nItem.interval)].join(' ')}
                      >
                        <span className={gc(nItem.interval)}>{nItem.interval}</span>
                        {nItem.investmentAssets || nItem.investmentAssets === 0 ? currencySymbol : ''}
                        {nItem.investmentAssets || nItem.investmentAssets === 0
                          ? numberWithCommas(fNumber(nItem.investmentAssets, 2))
                          : '--'}
                      </td>
                    ))}
                  </tr>
                </tfoot>
              </Table>
            </div>
          ) : (
            <Placeholder type='investment' />
          )}
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default InvestmentAssetSection;
