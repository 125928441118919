export enum StorageKey {
  AUTH = 'auth',
  URL = 'url',
  ERR = '__e',
  SALT_EDGE_LOCATION = '__salt_edge_url',
}

export interface ApiResponse {
  data: any;
  error: any;
}
