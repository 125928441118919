import React, { createContext } from 'react';

import { CurrencyOptions } from 'auth/enum/currency-options';

import {
  IPerformanceState,
  IPerformanceAction,
  TPerformanceDispatch,
  IPerformanceProviderProps,
} from './performance.type';
import { EPerformanceAction } from './performance.enum';

export const initialState: IPerformanceState = {
  processing: false,
  currency: CurrencyOptions.USD,
};

const PerformanceStateContext = createContext<IPerformanceState | undefined>(undefined);
const PerformanceDispatchContext = createContext<TPerformanceDispatch | undefined>(undefined);

function performanceReducer(state: IPerformanceState, action: IPerformanceAction): any {
  switch (action.type) {
    case EPerformanceAction.SET_PERFORMANCE_GROUPS: {
      return { ...state, performanceGroups: action.payload?.performanceGroups };
    }

    case EPerformanceAction.SET_CURRENCY: {
      return { ...state, currency: action.payload?.currency };
    }

    case EPerformanceAction.SET_PROCESSING: {
      return { ...state, processing: true };
    }

    case EPerformanceAction.RESET_PROCESSING: {
      return { ...state, processing: false };
    }

    default: {
      throw new Error('Unhandled action type in performance reducer');
    }
  }
}

function PerformanceProvider({ children }: IPerformanceProviderProps) {
  const [state, dispatch] = React.useReducer(performanceReducer, initialState);

  return (
    <PerformanceStateContext.Provider value={state}>
      <PerformanceDispatchContext.Provider value={dispatch as any}>{children}</PerformanceDispatchContext.Provider>
    </PerformanceStateContext.Provider>
  );
}

function usePerformanceState() {
  const context = React.useContext(PerformanceStateContext);

  if (context === undefined) {
    throw new Error('useAppState must be used within a App Provider');
  }

  return context;
}

function usePerformanceDispatch() {
  const context = React.useContext(PerformanceDispatchContext);

  if (context === undefined) {
    throw new Error('Must be used within a Settings Provider');
  }

  return context;
}

export { PerformanceProvider, usePerformanceDispatch, usePerformanceState };
