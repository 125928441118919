import React from 'react';

import classNames from 'common/classes.helper';
import { ECryptoConnections } from 'auth/auth.enum';

interface IWalletCard {
  url: string;
  name: string;
  contain?: boolean;
  disabled?: boolean;
  logo: React.ReactNode;
  connection: ECryptoConnections;
  onClick?: (connection: ECryptoConnections) => void;
}

const WalletCard = (props: IWalletCard) => {
  const { onClick = (f) => f, disabled = false } = props;

  return (
    <div
      role='button'
      onClick={() => onClick(props.connection)}
      className={classNames('wallet-card', disabled && 'disabled')}
    >
      <div className='logo-wrapper'>{props.logo}</div>
      <div className='wallet-info'>
        <span className='wallet-name'>{props.name}</span>
        <span className='wallet-website'>{props.url}</span>
      </div>
    </div>
  );
};

export default WalletCard;
