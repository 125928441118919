export const events = {
  completeProfile: {
    category: 'Net Worth',
    action: 'Clicked on Complete Profile',
    label: 'Accessed profile tab from Net Worth screen',
  },
  startTrial: {
    category: 'Subscription',
    action: 'Started Trial',
    label: 'Trial for VIP plan',
    value: 15, // edit this if plan price changed
  },
  trialFromPricing: {
    category: 'Pricing',
    action: `Clicked on Start Trial Button`,
    label: `Accessed sign up page from pricing`,
    value: 0,
  },
  manualConnectAccount: {
    category: 'Connect Accounts',
    action: 'Clicked Add Manual Account',
    label: 'Add Manual Account',
  },
  connectAccount: {
    category: 'Connect Accounts',
    action: 'Clicked Add Banks and Investments',
    label: 'Add Banks and Investments',
  },
  connectFromNWSimilar: {
    category: 'Connect Accounts',
    action: 'Clicked Add Accounts',
    label: 'Accessed connect accounts from Net Worth Similar',
  },
  upgradeFromNWSimilar: {
    category: 'Upgrade',
    action: 'Clicked on Upgrade',
    label: 'Accessed upgrade page from Net Worth Similar',
  },
  connectFromAllocationSimilar: {
    category: 'Connect Accounts',
    action: 'Clicked Add Accounts',
    label: 'Accessed connect accounts from Allocation Similar',
  },
  upgradeFromAllocationSimilar: {
    category: 'Upgrade',
    action: 'Clicked on Upgrade',
    label: 'Accessed upgrade page from Allocation Similar',
  },
  connectFromGettingStarted: {
    category: 'Connect Accounts',
    action: 'Clicked Add Accounts',
    label: 'Accessed connect accounts from Getting Start',
  },
  cryptoExchange: {
    category: 'Connect Accounts',
    action: 'Clicked Add Crypto and Alts',
    label: 'Add Crypto and Alts',
  },
  connectSaltEdgeEUUK: {
    category: 'Connect Accounts',
    action: 'Clicked EU Open Banking',
    label: 'Add UK, Spain and Germany Accounts',
  },
  connectSaltEdgeWorld: {
    category: 'Connect Accounts',
    action: 'Clicked World',
    label: 'Add World Accounts',
  },
  ctaSignUp: {
    category: 'CTA',
    action: 'Clicked Sign Up',
    label: 'Sign Up',
  },
  upgradeFromRealEstate: {
    category: 'Upgrade',
    action: 'Clicked on Upgrade',
    label: 'Accessed upgrade page from Real Estate extras',
  },
  discoverReview: {
    category: 'Discover',
    action: 'Review Link',
    label: 'Clicked on company review',
  },
  discoverLink: {
    category: 'Discover',
    action: 'Site Link',
    label: 'Clicked on company link',
  }
};
