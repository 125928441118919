import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import appEnv from 'app/app.env';
import AppHeader from 'common/app.header';
import AppFooter from 'common/app.footer';
import AppSidebar from 'common/app.sidebar';
import useToast from 'common/hooks/useToast';
import { events } from '@mm/data/event-list';
import { loadStripe } from '@stripe/stripe-js';
import GALink from 'common/components/ga-link';
import { postSubscriptionCheckout } from 'api/request.api';
import useSubscriptions from 'auth/hooks/useSubscriptions';
import { pricingDetailConstant } from 'common/common.constant';
import { EPlanDuration, EPlanType } from 'setting/setting.enum';
import CircularSpinner from 'common/components/spinner/circular-spinner';
import { ReactComponent as PricingTickIcon } from 'assets/images/pricing/tick-icon.svg';
import { ReactComponent as SubscriptionWarning } from 'assets/images/subscription/warning.svg';

import FreePlanFeatures from './inc/free-plan-features';

const stripePromise = loadStripe(appEnv.STRIPE_PUBLIC_KEY);

const Subscription = ({ subscriptionEnded = true }) => {
  const [openRightNav, setOpenRightNav] = useState<boolean>(false);
  const [openLeftNav, setOpenLeftNav] = useState<boolean>(false);

  const closeRightNav = () => {
    setOpenRightNav(false);
  };
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Subscription | Money Minx</title>
        </Helmet>
      </HelmetProvider>
      <AppHeader
        toggleLeftMenu={() => setOpenLeftNav(!openLeftNav)}
        toggleRightMenu={() => setOpenRightNav(!openRightNav)}
        open={openRightNav}
        shadow={true}
      />
      <AppSidebar openLeft={openLeftNav} openRight={openRightNav} />
      <div className='mm-slider-bg-overlay' onClick={closeRightNav} role='button' />
      <div className='sub-ended-wrapper'>
        {subscriptionEnded && <PricingTopSection />}
        <SubscriptionPlansTable />
      </div>
    </>
  );
};
export default Subscription;
export const PricingTopSection = () => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='subs-ended-msg-box'>
          <div className='subs-ended-left'>
            <h4>You don't have a Money Minx plan!</h4>
            <p>
              With the free plan you can only access the community. Choose a plan below to use the rest of Money Minx.
            </p>
          </div>
          <span className='warning-icon'>
            <SubscriptionWarning />
          </span>
        </div>
      </div>
    </div>
  );
};

const SubscriptionPlansTable = () => {
  const { mmToast } = useToast();
  const [type, setType] = useState<string>('monthly');
  const { loading, error, subscriptions } = useSubscriptions();

  if (loading && !subscriptions && error) {
    return <CircularSpinner />;
  }

  const monthlyPricingList = subscriptions?.filter(
    (sub: any) => sub.duration === EPlanDuration.MONTHLY && sub.active === true && sub.name === EPlanType.PRO
  );
  const annualPricingList = subscriptions?.filter(
    (sub: any) => sub.duration === EPlanDuration.YEARLY && sub.active === true && sub.name === EPlanType.PRO
  );

  const pricingList = type === 'monthly' ? monthlyPricingList : annualPricingList;

  const connectStripe = async (priceId: string) => {
    if (!priceId) {
      return mmToast('Price Id not found', { type: 'error' });
    }

    const stripe = await stripePromise;

    const payload = {
      subscriptionPriceId: priceId,
    };

    const { data, error: apiError } = await postSubscriptionCheckout(payload);
    if (apiError) {
      return mmToast('Can not stripe checkout id', { type: 'error' });
    }

    const checkoutId = data?.checkoutId;
    if (checkoutId && stripe) {
      const result = await stripe.redirectToCheckout({
        sessionId: checkoutId,
      });

      if (result.error) {
        return mmToast('Something went wrong with Stripe', { type: 'error' });
      }
    }
  };

  const handleBuyPlan = (stripePlan: any) => {
    connectStripe(stripePlan?.priceId);
  };

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='plan-section'>
            <div className='mm-plan-radios'>
              <input
                type='radio'
                id='mm-plan-month'
                value='monthly'
                name='mm-radio-time-interval'
                checked={type === 'monthly'}
                aria-checked={type === 'monthly'}
              />
              <label className='labels' htmlFor='mm-plan-month' onClick={() => setType('monthly')} role='button'>
                Monthly
              </label>
              <input
                type='radio'
                id='mm-plan-year'
                value='annually'
                name='mm-radio-time-interval'
                checked={type === 'yearly'}
                aria-checked={type === 'yearly'}
              />
              <label className='labels' htmlFor='mm-plan-year' onClick={() => setType('yearly')} role='button'>
                Annually
              </label>
              <span className='save-text' />
              <div className='mm-radio-bg' />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='pricing-table-wrapper'>
            <div className='price-table'>
              <div className='price-heading'>
                <span className='price-plan-name'>Free</span>
                <p>{type === 'yearly' ? `$0/Year` : `$0/Month`}</p>
              </div>

              <FreePlanFeatures />
              <GALink
                to={`/auth/signup?priceId=${appEnv.STRIPE_DEFAULT_PLAN}&planName=Free&planPrice=0`}
                eventArgs={{
                  ...events.trialFromPricing,
                  action: `Clicked on start free plan`,
                  value: 0,
                }}
              >
                <button className='mm-btn-animate trial-btn ml-3 btn-xs-block'>Get Started</button>
              </GALink>
            </div>
            {pricingList?.map((pt: any, index: number) => {
              return (
                <div className='price-table' key={index}>
                  <div className='price-heading'>
                    <span className='price-plan-name'>{pt.name}</span>
                    <p>
                      {type === 'yearly' ? `$${pt.price}/Year` : `$${pt.price}/Month`}
                      {type === 'yearly' ? <span className='save-percentage'>Save ${pt.save}</span> : null}
                    </p>
                  </div>
                  <ul className='features-list p-b-12'>
                    <li>
                      <div className='tick-icon'>
                        <PricingTickIcon />
                      </div>
                      Everything in free
                    </li>
                    <li>
                      <div className='tick-icon'>
                        <PricingTickIcon />
                      </div>
                      {'Current and '}
                      {pt.details[pricingDetailConstant.ALLOCATION_CHART_HISTORY] === 'Unlimited'
                        ? 'historical '
                        : `last ${pt.details[pricingDetailConstant.ALLOCATION_CHART_HISTORY]} months `}
                      asset allocation charts
                    </li>
                    <li>
                      <div className='tick-icon'>
                        <PricingTickIcon />
                      </div>
                      {pt.details[pricingDetailConstant.INVESTING_ENTITY] === '1'
                        ? '1 investing entity'
                        : pt.details[pricingDetailConstant.INVESTING_ENTITY] + ' investing entities'}
                    </li>
                    <li>
                      <div className='tick-icon'>
                        <PricingTickIcon />
                      </div>
                      {pt.details[pricingDetailConstant.CURRENCY] === 'USD'
                        ? 'USD support only '
                        : `Choose from over 130 supported currencies `}
                    </li>
                    {pt.details[pricingDetailConstant.COMPARE_ALLOCATION] === 'Yes' ? (
                      <li>
                        <div className='tick-icon'>
                          <PricingTickIcon />
                        </div>
                        Compare your net worth and asset allocation to others
                      </li>
                    ) : null}
                    <li>
                      <div className='tick-icon'>
                        <PricingTickIcon />
                      </div>
                      Get tips and insights with Money Minx AI
                    </li>
                    <li>
                      <div className='tick-icon'>
                        <PricingTickIcon />
                      </div>
                      Manage real estate leases, mortgages and expense
                    </li>
                    <li>
                      <div className='tick-icon'>
                        <PricingTickIcon />
                      </div>
                      Community {pt.details[pricingDetailConstant.NAME]} room access and badge
                    </li>
                  </ul>
                  <button
                    className='mm-btn-animate trial-btn ml-3 btn-xs-block'
                    onClick={() => {
                      handleBuyPlan(pt);
                    }}
                  >
                    Choose Plan
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <AppFooter />
    </>
  );
};
