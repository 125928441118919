import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { AccountOverview } from 'setting/pages/account-overview';

const SubscriptionReview = () => {
  return (
    <section>
      <HelmetProvider>
        <Helmet>
          <title>Review Subscription | Money Minx</title>
        </Helmet>
      </HelmetProvider>
      <div className='subscription-ended bottom p-b-20 pt-5'>
        <div className='container'>
          <AccountOverview reviewSubscriptionFlag={true} />
        </div>
      </div>
    </section>
  );
};

export default SubscriptionReview;
