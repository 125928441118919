import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import omit from 'lodash/omit';
import { Formik } from 'formik';
import useMmRisk from 'auth/hooks/useMmRisk';
import useToast from 'common/hooks/useToast';
import { Modal } from 'common/components/modal';
import MMToolTip from 'common/components/tooltip';
import useMmCountry from 'auth/hooks/useMmCountry';
import useMmAssetClass from 'auth/hooks/useMmAssetClass';
import { ClassificationsModalProps } from 'account/account.type';
import { SelectInput } from 'common/components/input/select.input';
import { getClassificationStatus, patchAccount } from 'api/request.api';
import { ReactComponent as InfoIcon } from 'assets/images/signup/info.svg';

const ClassificationsModal: React.FC<ClassificationsModalProps> = ({
  handleRefresh,
  accountDetails,
  classificationsModal,
}) => {
  const { mmToast } = useToast();
  const { mmAssetRisks } = useMmRisk();
  const { mmAssetCountries } = useMmCountry();
  const { mmAssetClasses } = useMmAssetClass();
  const [loading, setLoading] = useState(false);
  const [hasHoldings, setHasHoldings] = useState(true);

  const handleCancel = () => {
    classificationsModal.close();
  };

  useEffect(() => {
    async function fetchStatus() {
      const allowAccountClassification = await getClassificationStatus(accountDetails.id.toString());
      setHasHoldings(allowAccountClassification.data.hasHoldings);
    }

    fetchStatus();
  }, [accountDetails]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        mmAssetClass: accountDetails.accountDetails.mmAssetClass || '',
        mmCountry: accountDetails.accountDetails.mmCountry || '',
        mmRisk: accountDetails.accountDetails.mmRisk || '',
        excludeFromAllocation: accountDetails.accountDetails.excludeFromAllocation,
        excludeFromIncome: accountDetails.accountDetails.excludeFromIncome,
        excludeFromPerformance: accountDetails.accountDetails.excludeFromPerformance,
      }}
      onSubmit={async (values: any) => {
        const accountId = accountDetails.id;

        let data = {};

        Object.keys(values).forEach((key: any) => {
          const value = (values as any)[key];

          if (value === '') {
            data = { ...data, [key]: undefined };
            return;
          }

          data = { ...data, [key]: value };
        });

        data = hasHoldings ? omit(data, ['mmAssetClass', 'mmCountry', 'mmRisk']) : data;

        setLoading(true);
        if (accountId) {
          const res = await patchAccount(`${accountId}`, data);
          if (res?.error) {
            setLoading(false);
            return mmToast('Error Occurred on patch Account [classification modal]', { type: 'error' });
          }
          setLoading(false);
          handleRefresh();
          classificationsModal.close();
          return mmToast('Successfully updated', { type: 'success' });
        }
      }}
    >
      {(props) => {
        const { values, setValues } = props;

        const handleSelectChange = (e: React.ChangeEvent<any>) => {
          setValues({ ...values, [e.target.name]: e.target.value });
        };

        const excludeFromAllocationChange = (e: React.ChangeEvent<any>) => {
          setValues({ ...values, [e.target.name]: e.target.value === 'true' });
        };

        const excludeFromIncomeChange = (e: React.ChangeEvent<any>) => {
          setValues({ ...values, [e.target.name]: e.target.value === 'true' });
        };

        const excludeFromPerformanceChange = (e: React.ChangeEvent<any>) => {
          setValues({ ...values, [e.target.name]: e.target.value === 'true' });
        };

        return (
          <form onSubmit={props.handleSubmit}>
            <Modal
              {...classificationsModal.props}
              title={accountDetails.accountName}
              size={'lg'}
              canBeClosed
              onClose={() => classificationsModal.close()}
            >
              <div className='modal-wrapper mm-activity-details-modal'>
                <div className='mm-activity-details-modal__title'>
                  <div className='row align-items-center ml-0 mr-0 mb-3'>How do you want to classify this account?</div>
                  {!hasHoldings ? (
                    <Row>
                      <Col xs='12' md='6'>
                        <span className='form-subheading'>Asset Class</span>
                        <SelectInput
                          args={mmAssetClasses}
                          onChange={handleSelectChange}
                          value={values.mmAssetClass}
                          name='mmAssetClass'
                        />
                      </Col>
                      <Col xs='12' md='6'>
                        <span className='form-subheading'>Risk</span>
                        <MMToolTip message='We all have a different tolerance for risk but a good rule of thumb is to use High for investments like crypto, FX, startups, Medium for ETFs, Stocks, Mutual Funds, Low for treasuries, rentals, business.'>
                          <InfoIcon className='sm-hide' />
                        </MMToolTip>
                        <SelectInput
                          args={mmAssetRisks}
                          onChange={handleSelectChange}
                          value={values.mmRisk}
                          name='mmRisk'
                        />
                      </Col>
                      <Col xs='12' md='6'>
                        <span className='form-subheading'>Country</span>
                        <SelectInput
                          args={mmAssetCountries}
                          onChange={handleSelectChange}
                          value={values.mmCountry}
                          name='mmCountry'
                        />
                      </Col>
                    </Row>
                  ) : (
                    <div className='row align-items-center ml-0 mr-0'>
                      <p className='help-text'>
                        This account has underlying positions. To classify click on the menu of each position and choose
                        classify.
                      </p>
                    </div>
                  )}
                  <Row>
                    <Col xs='12' md='6'>
                      <div className='middle-input-wrap'>
                        <div className='input-wrap performance flex-box'>
                          <div className='left-input'>
                            <p>
                              <span className='form-subheading'>Exclude from asset allocation</span>
                            </p>
                          </div>
                          <div className='right-input radio'>
                            <input
                              className='ml-4'
                              type='radio'
                              value='true'
                              onChange={excludeFromAllocationChange}
                              name='excludeFromAllocation'
                              checked={values.excludeFromAllocation}
                              aria-checked={values.excludeFromAllocation}
                            />
                            <label>Yes</label>
                            <input
                              className='ml-4'
                              onChange={excludeFromAllocationChange}
                              value='false'
                              type='radio'
                              name='excludeFromAllocation'
                              checked={!values.excludeFromAllocation}
                              aria-checked={!values.excludeFromAllocation}
                            />
                            <label>No</label>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs='12' md='6'>
                      <div className='middle-input-wrap'>
                        <div className='input-wrap performance flex-box'>
                          <div className='left-input'>
                            <p>
                              <span className='form-subheading'>Exclude from performance</span>
                            </p>
                          </div>
                          <div className='right-input radio'>
                            <input
                              className='ml-4'
                              type='radio'
                              value='true'
                              onChange={excludeFromPerformanceChange}
                              name='excludeFromPerformance'
                              checked={values.excludeFromPerformance}
                              aria-checked={values.excludeFromPerformance}
                            />
                            <label>Yes</label>
                            <input
                              className='ml-4'
                              onChange={excludeFromPerformanceChange}
                              value='false'
                              type='radio'
                              name='excludeFromPerformance'
                              checked={!values.excludeFromPerformance}
                              aria-checked={!values.excludeFromPerformance}
                            />
                            <label>No</label>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs='12' md='6'>
                      <div className='middle-input-wrap'>
                        <div className='input-wrap performance flex-box'>
                          <div className='left-input'>
                            <p>
                              <span className='form-subheading'>Exclude from income</span>
                            </p>
                          </div>
                          <div className='right-input radio'>
                            <input
                              className='ml-4'
                              type='radio'
                              value='true'
                              onChange={excludeFromIncomeChange}
                              name='excludeFromIncome'
                              checked={values.excludeFromIncome}
                              aria-checked={values.excludeFromIncome}
                            />
                            <label>Yes</label>
                            <input
                              className='ml-4'
                              onChange={excludeFromIncomeChange}
                              value='false'
                              type='radio'
                              name='excludeFromIncome'
                              checked={!values.excludeFromIncome}
                              aria-checked={!values.excludeFromIncome}
                            />
                            <label>No</label>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className='d-flex mt-3'>
                    <button className='btn-outline-primary mm-btn-animate mr-3' onClick={handleCancel} type='button'>
                      Cancel
                    </button>
                    <button
                      className='mm-btn-animate mm-btn-primary d-flex align-items-center justify-content-center'
                      type='submit'
                    >
                      {loading ? (
                        <>
                          <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true' />
                          <span className='ml-1'>Saving...</span>
                        </>
                      ) : (
                        <>
                          Save<span className='hide-sm ml-1'>Changes</span>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          </form>
        );
      }}
    </Formik>
  );
};

export default ClassificationsModal;
