import { isNill } from './common-helper';

export const filterObjectPayload = <V extends Record<string, any>>(values: V) => {
  const _values: Record<string, any> = {};

  for (const key in values) {
    if (Object.prototype.hasOwnProperty.call(values, key)) {
      const value = values[key];
      const hasStringValue = value !== '';

      if (!isNill(value) && hasStringValue) {
        _values[key] = value;
      }
    }
  }

  return _values;
};
