export enum CurrencyOptions {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  CAD = 'CAD',
  JPY = 'JPY',
  AUD = 'AUD',
  CHF = 'CHF',
  INR = 'INR',
  LKR = 'LKR',
  SGD = 'SGD',
  ILS = 'ILS',
  KRW = 'KRW',
  PHP = 'PHP',
  PLN = 'PLN',
  THB = 'THB',
  BGN = 'BGN',
  CZK = 'CZK',
  COP = 'COP',
  DKK = 'DKK',
  HUF = 'HUF',
  RON = 'RON',
  SEK = 'SEK',
  ISK = 'ISK',
  NOK = 'NOK',
  HRK = 'HRK',
  RUB = 'RUB',
  TRY = 'TRY',
  BRL = 'BRL',
  CNY = 'CNY',
  HKD = 'HKD',
  IDR = 'IDR',
  MXN = 'MXN',
  MYR = 'MYR',
  NZD = 'NZD',
  ZAR = 'ZAR',
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BSD = 'BSD',
  BTC = 'BTC',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BYR = 'BYR',
  BZD = 'BZD',
  CDF = 'CDF',
  CLF = 'CLF',
  CLP = 'CLP',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  DJF = 'DJF',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  FJD = 'FJD',
  FKP = 'FKP',
  GEL = 'GEL',
  GGP = 'GGP',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HNL = 'HNL',
  HTG = 'HTG',
  IMP = 'IMP',
  IQD = 'IQD',
  IRR = 'IRR',
  JEP = 'JEP',
  JMD = 'JMD',
  JOD = 'JOD',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LRD = 'LRD',
  LSL = 'LSL',
  LTL = 'LTL',
  LVL = 'LVL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRO = 'MRO',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NPR = 'NPR',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PKR = 'PKR',
  PYG = 'PYG',
  QAR = 'QAR',
  RSD = 'RSD',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  STD = 'STD',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  UYU = 'UYU',
  UZS = 'UZS',
  VEF = 'VEF',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XAG = 'XAG',
  XAU = 'XAU',
  XCD = 'XCD',
  XDR = 'XDR',
  XOF = 'XOF',
  XPF = 'XPF',
  YER = 'YER',
  ZMK = 'ZMK',
  ZMW = 'ZMW',
  ZWL = 'ZWL',
}

export const CurrencySymbols: Record<string, string> = {
  USD: '$', // US Dollar
  EUR: '€', // Euro
  GBP: '£', // British Pound Sterling
  CAD: '$', // Canadian Dollar
  JPY: '¥', // Japanese Yen
  AUD: '$', // Australian Dollar
  CHF: 'Fr', // Swiss Franc
  INR: '₹', // Indian Rupee
  LKR: 'Rs', // Sri Lankan Rupee
  SGD: '$', // Singapore Dollar
  ILS: '₪', // Israeli New Sheqel
  KRW: '₩', // South Korean Won
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  THB: '฿', // Thai Baht
  BGN: 'Лв', // Bulgarian lev
  CZK: 'Kč', // Czech koruna
  COP: 'COL$', // Colombian Peso
  DKK: 'Kr', // Danish krone
  HUF: 'Ft', // Hungarian forint
  RON: 'lei', // Romanian leu
  SEK: 'kr', // Swedish krona
  ISK: 'Íkr', // Icelandic króna
  NOK: 'kr', // Norwegian krone
  HRK: 'kn', // Croatian kuna
  RUB: '₽', // Russian ruble
  TRY: '₺', // Turkish lira
  BRL: 'R$', // Brazilian Real
  CNY: '¥', // Renminbi
  HKD: '$', // Honk Kong Dollar
  IDR: 'Rp', // Indonesian rupiah
  MXN: '$', // Mexican peso
  MYR: 'RM', // Malaysian ringgit
  NZD: '$', // New Zealand dollar
  ZAR: 'R', // South African rand
  AED: 'AED', // United Arab Emirates Dirham
  AFN: ' ؋', // Afghan Afghani
  ALL: 'L', // Albanian Lek
  AMD: '֏', // Armenian Dram
  ANG: 'ƒ', // Netherlands Antillean Guilder
  AOA: 'Kz', // Angolan Kwanza
  ARS: '$', // Argentine Peso
  AWG: 'ƒ', // Aruban Florin
  AZN: 'AZN', // Azerbaijani Manat
  BAM: 'KM', // Bosnia-Herzegovina Convertible Mark
  BBD: 'Bds$', // Barbadian Dollar
  BDT: '৳', // Bangladeshi Taka
  BHD: 'BD', // Bahraini Dinar
  BIF: 'FBu', // Burundian Franc
  BMD: '$', // Bermudan Dollar
  BND: 'B$', // Brunei Dollar
  BOB: 'Bs.', // Bolivian Boliviano
  BSD: 'B$', // Bahamian Dollar
  BTC: '₿', // Bitcoin
  BTN: 'Nu.', // Bhutanese Ngultrum
  BWP: 'P', // Botswanan Pula
  BYN: 'BYN', // New Belarusian Ruble
  BYR: 'Br', // Belarusian Ruble
  BZD: 'BZ$', // Belize Dollar
  CDF: 'FC', // Congolese Franc
  CLF: 'CLF', // Chilean Unit of Account (UF)
  CLP: '$', // Chilean Peso
  CRC: '₡', // Costa Rican Colón
  CUC: 'CUC', // Cuban Convertible Peso
  CUP: '₱', // Cuban Peso
  CVE: 'CVE', // Cape Verdean Escudo
  DJF: 'DJF', // Djiboutian Franc
  DOP: 'RD$', // Dominican Peso
  DZD: 'DZD', // Algerian Dinar
  EGP: '£', // Egyptian Pound
  ERN: 'ERN', // Eritrean Nakfa
  ETB: 'ETB', // Ethiopian Birr
  FJD: '$', // Fijian Dollar
  FKP: '£', // Falkland Islands Pound
  GEL: 'GEL', // Georgian Lari
  GGP: '£', // Guernsey Pound
  GHS: '¢', // Ghanaian Cedi
  GIP: '£', // Gibraltar Pound
  GMD: 'GMD', // Gambian Dalasi
  GNF: 'GNF', // Guinean Franc
  GTQ: 'Q', // Guatemalan Quetzal
  GYD: '$', // Guyanaese Dollar
  HNL: 'L', // Honduran Lempira
  HTG: 'HTG', // Haitian Gourde
  IMP: '£', // Manx pound
  IQD: 'IQD', // Iraqi Dinar
  IRR: 'IRR', // Iranian Rial
  JEP: '£', // Jersey Pound
  JMD: 'J$', // Jamaican Dollar
  JOD: 'JOD', // Jordanian Dinar
  KES: 'KES', // Kenyan Shilling
  KGS: 'KGS', // Kyrgystani Som
  KHR: '៛', // Cambodian Riel
  KMF: 'KMF', // Comorian Franc
  KPW: '₩', // North Korean Won
  KWD: 'KWD', // Kuwaiti Dinar
  KYD: '$', // Cayman Islands Dollar
  KZT: 'лв', // Kazakhstani Tenge
  LAK: '₭', // Laotian Kip
  LBP: '£', // Lebanese Pound
  LRD: '$', // Liberian Dollar
  LSL: 'LSL', // Lesotho Loti
  LTL: 'LTL', // Lithuanian Litas
  LVL: 'LVL', // Latvian Lats
  LYD: 'LYD', // Libyan Dinar
  MAD: 'MAD', // Moroccan Dirham
  MDL: 'MDL', // Moldovan Leu
  MGA: 'MGA', // Malagasy Ariary
  MKD: 'ден', // Macedonian Denar
  MMK: 'MMK', // Myanma Kyat
  MNT: '₮', // Mongolian Tugrik
  MOP: 'MOP', // Macanese Pataca
  MRO: 'MRO', // Mauritanian Ouguiya
  MUR: '₨', // Mauritian Rupee
  MVR: 'MVR', // Maldivian Rufiyaa
  MWK: 'MWK', // Malawian Kwacha
  MZN: 'MT', // Mozambican Metical
  NAD: '$', // Namibian Dollar
  NGN: '₦', // Nigerian Naira
  NIO: 'C$', // Nicaraguan Córdoba
  NPR: '₨', // Nepalese Rupee
  OMR: 'OMR', // Omani Rial
  PAB: 'B/.', // Panamanian Balboa
  PEN: 'S/.', // Peruvian Nuevo Sol
  PGK: 'PGK', // Papua New Guinean Kina
  PKR: '₨', // Pakistani Rupee
  PYG: 'Gs', // Paraguayan Guarani
  QAR: 'QAR', // Qatari Rial
  RSD: 'Дин.', // Serbian Dinar
  RWF: 'RWF', // Rwandan Franc
  SAR: 'SAR', // Saudi Riyal
  SBD: '$', // Solomon Islands Dollar
  SCR: '₨', // Seychellois Rupee
  SDG: 'SDG', // Sudanese Pound
  SHP: '£', // Saint Helena Pound
  SLL: 'SLL', // Sierra Leonean Leone
  SOS: 'S', // Somali Shilling
  SRD: '$', // Surinamese Dollar
  STD: 'STD', // São Tomé and Príncipe Dobra
  SVC: 'SVC', // Salvadoran Colón
  SYP: '£', // Syrian Pound
  SZL: 'SZL', // Swazi Lilangeni
  TJS: 'TJS', // Tajikistani Somoni
  TMT: 'TMT', // Turkmenistani Manat
  TND: 'TND', // Tunisian Dinar
  TOP: 'TOP', // Tongan Paʻanga
  TTD: 'TT$', // Trinidad and Tobago Dollar
  TWD: 'TWD', // New Taiwan Dollar
  TZS: 'TZS', // Tanzanian Shilling
  UAH: '₴', // Ukrainian Hryvnia
  UGX: 'UGX', // Ugandan Shilling
  UYU: '$U', // Uruguayan Peso
  UZS: 'лв', // Uzbekistan Som
  VEF: 'Bs', // Venezuelan Bolívar Fuerte
  VND: '₫', // Vietnamese Dong
  VUV: 'VUV', // Vanuatu Vatu
  WST: 'WST', // Samoan Tala
  XAF: 'XAF', // CFA Franc BEAC
  XAG: 'XAG', // Silver (troy ounce)
  XAU: 'XAU', // Gold (troy ounce)
  XCD: '$', // East Caribbean Dollar
  XDR: 'XDR', // Special Drawing Rights
  XOF: 'XOF', // CFA Franc BCEAO
  XPF: 'XPF', // CFP Franc
  YER: 'YER', // Yemeni Rial
  ZMK: 'ZMK', // Zambian Kwacha (pre-2013)
  ZMW: 'ZMW', // Zambian Kwacha
  ZWL: 'Z$', // Zimbabwean Dollar
};

export const CurrencyNames: Record<CurrencyOptions, string> = {
  USD: 'US Dollar',
  EUR: 'Euro',
  GBP: 'British Pound Sterling',
  CAD: 'Canadian Dollar',
  JPY: 'Japanese Yen',
  AUD: 'Australian Dollar',
  CHF: 'Swiss Franc',
  INR: 'Indian Rupee',
  LKR: 'Sri Lankan Rupee',
  SGD: 'Singapore Dollar',
  ILS: 'Israeli New Sheqel',
  KRW: 'South Korean Won',
  PHP: 'Philippine Peso',
  PLN: 'Polish Zloty',
  THB: 'Thai Baht',
  BGN: 'Bulgarian lev',
  CZK: 'Czech koruna',
  COP: 'Colombian Peso',
  DKK: 'Danish krone',
  HUF: 'Hungarian forint',
  RON: 'Romanian leu',
  SEK: 'Swedish krona',
  ISK: 'Icelandic króna',
  NOK: 'Norwegian krone',
  HRK: 'Croatian kuna',
  RUB: 'Russian ruble',
  TRY: 'Turkish lira',
  BRL: 'Brazilian Real',
  CNY: 'Renminbi',
  HKD: 'Honk Kong Dollar',
  IDR: 'Indonesian rupiah',
  MXN: 'Mexican peso',
  MYR: 'Malaysian ringgit',
  NZD: 'New Zealand dollar',
  ZAR: 'South African rand',
  AED: 'United Arab Emirates Dirham',
  AFN: 'Afghan Afghani',
  ALL: 'Albanian Lek',
  AMD: 'Armenian Dram',
  ANG: 'Netherlands Antillean Guilder',
  AOA: 'Angolan Kwanza',
  ARS: 'Argentine Peso',
  AWG: 'Aruban Florin',
  AZN: 'Azerbaijani Manat',
  BAM: 'Bosnia-Herzegovina Convertible Mark',
  BBD: 'Barbadian Dollar',
  BDT: 'Bangladeshi Taka',
  BHD: 'Bahraini Dinar',
  BIF: 'Burundian Franc',
  BMD: 'Bermudan Dollar',
  BND: 'Brunei Dollar',
  BOB: 'Bolivian Boliviano',
  BSD: 'Bahamian Dollar',
  BTC: 'Bitcoin',
  BTN: 'Bhutanese Ngultrum',
  BWP: 'Botswanan Pula',
  BYN: 'New Belarusian Ruble',
  BYR: 'Belarusian Ruble',
  BZD: 'Belize Dollar',
  CDF: 'Congolese Franc',
  CLF: 'Chilean Unit of Account (UF)',
  CLP: 'Chilean Peso',
  CRC: 'Costa Rican Colón',
  CUC: 'Cuban Convertible Peso',
  CUP: 'Cuban Peso',
  CVE: 'Cape Verdean Escudo',
  DJF: 'Djiboutian Franc',
  DOP: 'Dominican Peso',
  DZD: 'Algerian Dinar',
  EGP: 'Egyptian Pound',
  ERN: 'Eritrean Nakfa',
  ETB: 'Ethiopian Birr',
  FJD: 'Fijian Dollar',
  FKP: 'Falkland Islands Pound',
  GEL: 'Georgian Lari',
  GGP: 'Guernsey Pound',
  GHS: 'Ghanaian Cedi',
  GIP: 'Gibraltar Pound',
  GMD: 'Gambian Dalasi',
  GNF: 'Guinean Franc',
  GTQ: 'Guatemalan Quetzal',
  GYD: 'Guyanaese Dollar',
  HNL: 'Honduran Lempira',
  HTG: 'Haitian Gourde',
  IMP: 'Manx pound',
  IQD: 'Iraqi Dinar',
  IRR: 'Iranian Rial',
  JEP: 'Jersey Pound',
  JMD: 'Jamaican Dollar',
  JOD: 'Jordanian Dinar',
  KES: 'Kenyan Shilling',
  KGS: 'Kyrgystani Som',
  KHR: 'Cambodian Riel',
  KMF: 'Comorian Franc',
  KPW: 'North Korean Won',
  KWD: 'Kuwaiti Dinar',
  KYD: 'Cayman Islands Dollar',
  KZT: 'Kazakhstani Tenge',
  LAK: 'Laotian Kip',
  LBP: 'Lebanese Pound',
  LRD: 'Liberian Dollar',
  LSL: 'Lesotho Loti',
  LTL: 'Lithuanian Litas',
  LVL: 'Latvian Lats',
  LYD: 'Libyan Dinar',
  MAD: 'Moroccan Dirham',
  MDL: 'Moldovan Leu',
  MGA: 'Malagasy Ariary',
  MKD: 'Macedonian Denar',
  MMK: 'Myanma Kyat',
  MNT: 'Mongolian Tugrik',
  MOP: 'Macanese Pataca',
  MRO: 'Mauritanian Ouguiya',
  MUR: 'Mauritian Rupee',
  MVR: 'Maldivian Rufiyaa',
  MWK: 'Malawian Kwacha',
  MZN: 'Mozambican Metical',
  NAD: 'Namibian Dollar',
  NGN: 'Nigerian Naira',
  NIO: 'Nicaraguan Córdoba',
  NPR: 'Nepalese Rupee',
  OMR: 'Omani Rial',
  PAB: 'Panamanian Balboa',
  PEN: 'Peruvian Nuevo Sol',
  PGK: 'Papua New Guinean Kina',
  PKR: 'Pakistani Rupee',
  PYG: 'Paraguayan Guarani',
  QAR: 'Qatari Rial',
  RSD: 'Serbian Dinar',
  RWF: 'Rwandan Franc',
  SAR: 'Saudi Riyal',
  SBD: 'Solomon Islands Dollar',
  SCR: 'Seychellois Rupee',
  SDG: 'Sudanese Pound',
  SHP: 'Saint Helena Pound',
  SLL: 'Sierra Leonean Leone',
  SOS: 'Somali Shilling',
  SRD: 'Surinamese Dollar',
  STD: 'São Tomé and Príncipe Dobra',
  SVC: 'Salvadoran Colón',
  SYP: 'Syrian Pound',
  SZL: 'Swazi Lilangeni',
  TJS: 'Tajikistani Somoni',
  TMT: 'Turkmenistani Manat',
  TND: 'Tunisian Dinar',
  TOP: 'Tongan Paʻanga',
  TTD: 'Trinidad and Tobago Dollar',
  TWD: 'New Taiwan Dollar',
  TZS: 'Tanzanian Shilling',
  UAH: 'Ukrainian Hryvnia',
  UGX: 'Ugandan Shilling',
  UYU: 'Uruguayan Peso',
  UZS: 'Uzbekistan Som',
  VEF: 'Venezuelan Bolívar Fuerte',
  VND: 'Vietnamese Dong',
  VUV: 'Vanuatu Vatu',
  WST: 'Samoan Tala',
  XAF: 'CFA Franc BEAC',
  XAG: 'Silver (troy ounce)',
  XAU: 'Gold (troy ounce)',
  XCD: 'East Caribbean Dollar',
  XDR: 'Special Drawing Rights',
  XOF: 'CFA Franc BCEAO',
  XPF: 'CFP Franc',
  YER: 'Yemeni Rial',
  ZMK: 'Zambian Kwacha (pre-2013)',
  ZMW: 'Zambian Kwacha',
  ZWL: 'Zimbabwean Dollar',
};
