import React from 'react';

const UnsecuredPage = () => {
  return (
    <div className='unsecured-wrapper d-block text-center'>
      <p className='error-title'>Your connection is not secure.</p>
      <p>
        <span>
          Please contact <a href='mailto:security@moneyminx.com'>security@moneyminx.com</a> and let us know how you got
          here. To access Money Minx securely, enter https://www.moneyminx.com in your browser search bar or{' '}
        </span>
        <a href={window.self.location.href} title='MoneyMinx' target='_blank' rel='noreferrer noopener'>
          click on this link.
        </a>
      </p>
    </div>
  );
};

export default UnsecuredPage;
