export enum EIntervalOption {
  '1D' = '1D',
  '1W' = '1W',
  '1M' = '1M',
  '1Y' = '1Y',
  'MTD' = 'MTD',
  'YTD' = 'YTD',
  'ALL' = 'ALL',
}

export enum ENetworthChartInterval {
  MONTHLY = 'Monthly',
  DAILY = 'Daily',
}

export enum EPortfolioMoverType {
  ACCOUNTS = 'ACCOUNTS',
  HOLDINGS = 'HOLDINGS',
}

// Do not change enum values.
// They are linked with CSS classes
export enum EDashboardAccountStatus {
  SUCCESS = 'success',
  WARN = 'warn',
  ERROR = 'error',
  REFRESHING = 'refreshing',
  ARCHIVED = 'archived',
}

export enum EDiffStatus {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}
