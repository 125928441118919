import React, { useState } from 'react';

import { EState } from 'common/common.types';
import withLoading from 'common/hoc/withLoading';
import { useModal } from 'common/components/modal';
import { IInvestingEntity } from 'setting/setting.type';
import useGetSubscription from 'auth/hooks/useGetSubscription';
import useInvestingEntity from 'setting/hooks/useInvestingEntity';
import useCurrentSubscription from 'auth/hooks/useCurrentSubscription';
import { ReactComponent as IconEdit } from 'assets/icons/icon-edit.svg';
import CircularSpinner from 'common/components/spinner/circular-spinner';
import { ReactComponent as DeleteIcon } from 'assets/icons/icon-delete.svg';

import DeleteEntityModal from './delete-entity.modal';
import AddEditEntityModal from './add-edit-entity.modal';

const InvestingEntity = () => {
  const addEditEntityModal = useModal();
  const deleteConfirmationModal = useModal();
  const [currentEntity, setCurrentEntity] = useState<IInvestingEntity>();
  const { status, investingEntities, removeInvestmentEntity, refresh } = useInvestingEntity();
  const { fetchingCurrentSubscription, currentSubscription } = useCurrentSubscription();
  const { fetchingSubscription } = useGetSubscription(currentSubscription?.priceId);

  if (fetchingCurrentSubscription || fetchingSubscription) {
    return <CircularSpinner />;
  }

  // used to determine if the user can add more investing entities
  const limited =
    currentSubscription?.subscriptionStatus !== 'active' && currentSubscription?.subscriptionStatus !== 'trialing';

  const handleEdit = (entity: IInvestingEntity) => {
    setCurrentEntity(entity);

    return addEditEntityModal.open();
  };

  const handleAddEntity = () => {
    setCurrentEntity(undefined);

    return addEditEntityModal.open();
  };

  const openDeleteModal = (entity: IInvestingEntity) => {
    setCurrentEntity(entity);

    return deleteConfirmationModal.open();
  };

  const renderInvestingEntities = () => {
    if (investingEntities) {
      return investingEntities.map((entity) => {
        return (
          <div className='mm-setting-form form-group mt-4 row' key={entity.id}>
            <label className='col-8 investing-entity'>{entity.name}</label>
            <div className='col-4'>
              <div className='form-wrap'>
                <div className='float-right'>
                  <IconEdit className='edit-icon' onClick={() => handleEdit(entity)} />
                  {!entity.default ? (
                    <DeleteIcon className='ml-2 ml-md-3 trash-icon' onClick={() => openDeleteModal(entity)} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }

    return null;
  };

  const content = renderInvestingEntities();
  const loading = EState.LOADING === status;

  return (
    <div className='card mm-setting-card'>
      <div className='card-body'>
        <div className='mm-setting-card--title'>Investing Entities</div>
        {withLoading(EState.LOADING === status, content)}
        <div className={[limited ? 'mt-5 add-entity-section' : '', 'action-wrapper'].join(' ')}>
          {limited && (
            <span className='text--pink'>
              You have reached the max number of entities for your plan. <a href={'/settings?active=Plan'} className='pink-links'>Upgrade to pro</a> to add more.
            </span>
          )}
          <button
            className={[limited ? '' : 'mm-btn-animate', 'mm-btn-settings mt-3'].join(' ')}
            onClick={handleAddEntity}
            disabled={limited}
          >
            Add Entity
          </button>
        </div>
      </div>
      <AddEditEntityModal addEditEntityModal={addEditEntityModal} currentEntity={currentEntity} refresh={refresh} />
      <DeleteEntityModal
        loading={loading}
        currentEntity={currentEntity}
        removeEntity={removeInvestmentEntity}
        deleteEntityModal={deleteConfirmationModal}
      />
    </div>
  );
};

export default InvestingEntity;
