import React, { useEffect, useCallback } from 'react';

import { ECryptoConnections } from 'auth/auth.enum';
import MetaMaskOnboarding from '@metamask/onboarding';
import detectEthereumProvider from '@metamask/detect-provider';
import { getAddEthereumChainPayload, getCryptoSwitchPayload } from 'auth/auth.helper';

export interface IChainAccounts {
  [key: number]: string[];
}

const supportedChainIds = [1, 56];

const useMetamask = () => {
  const { ethereum }: { ethereum?: any } = window;
  const [chainId, setChainId] = React.useState<number>();
  const [accounts, setAccounts] = React.useState<IChainAccounts>({});

  const requestPermission = async () => {
    try {
      const permission = await ethereum.request({
        method: 'wallet_requestPermissions',
        params: [
          {
            eth_accounts: {},
          },
        ],
      });

      return permission[0]?.caveats[1]?.value as string[];
    } catch (error) {
      console.error(error);
    }
  };

  const requestPermissionCallback = useCallback(requestPermission, [ethereum]);

  const onChainChanged = async (id: string) => {
    const _id = parseInt(id, 16);
    setChainId(_id);

    if (!_id || supportedChainIds.indexOf(_id) === -1) {
      return;
    }

    const permittedAccounts = await requestPermissionCallback();

    if (permittedAccounts?.length) {
      return setAccounts({ ...accounts, [_id]: permittedAccounts });
    }

    try {
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
        param: {
          chainId: id,
        },
      });

      return setAccounts({
        ...accounts,
        [_id]: newAccounts || [],
      });
    } catch (error) {
      console.error(error);
    }
  };

  const chainChangedCallback = useCallback(onChainChanged, [accounts, ethereum, requestPermissionCallback]);

  const onAccountsChanged = (accList: string[]) => {
    if (!chainId) return;

    const found = accounts[chainId]?.find((acc) => accList.includes(acc));
    const newChainAccounts = found
      ? [...accounts[chainId]]
      : accounts[chainId]
      ? [...accList, ...accounts[chainId]]
      : accList;

    return setAccounts({
      ...accounts,
      [chainId]: newChainAccounts,
    });
  };

  const onAccountChangedCallback = useCallback(onAccountsChanged, [accounts, chainId]);

  useEffect(() => {
    ethereum?.on('chainChanged', chainChangedCallback);
    ethereum?.on('accountsChanged', onAccountChangedCallback);

    const cleanup = () => {
      ethereum?.removeListener('accountsChanged', onAccountChangedCallback);
      ethereum?.removeListener('chainChanged', chainChangedCallback);
    };

    return cleanup;
  }, [ethereum, chainChangedCallback, onAccountChangedCallback]);

  const connect = async () => {
    const permittedAccounts = await requestPermission();
    const _id = await getNetworkAndChainId();

    if (!_id) {
      return;
    }

    if (permittedAccounts?.length) {
      return setAccounts({
        ...accounts,
        [_id]: permittedAccounts,
      });
    }

    try {
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      });

      return setAccounts({
        ...accounts,
        [_id]: newAccounts || [],
      });
    } catch (error) {
      console.error(error);
    }
  };

  const isMetaMaskInstalled = () => {
    return Boolean(ethereum && ethereum.isMetaMask);
  };

  const getNetworkAndChainId = async () => {
    try {
      const id = await ethereum.request({
        method: 'eth_chainId',
      });

      const _id = parseInt(id, 16);

      setChainId(_id);

      return _id;
    } catch (err) {
      console.error(err);
    }
  };

  const install = () => {
    let onboarding: MetaMaskOnboarding;

    try {
      onboarding = new MetaMaskOnboarding();
      onboarding.startOnboarding();
    } catch (error) {
      console.error('Error on boarding metamask', error);
    }
  };

  const switchNetwork = async (con: ECryptoConnections) => {
    const param = getCryptoSwitchPayload(con);

    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [param],
      });

      const permittedAccounts = await requestPermission();
      const _id = await getNetworkAndChainId();

      if (!_id) {
        return;
      }

      if (permittedAccounts?.length) {
        return setAccounts({
          ...accounts,
          [_id]: permittedAccounts,
        });
      }
    } catch (error) {
      console.error(error);
      addEthereumChain(con);
    }
  };

  const addEthereumChain = async (con: ECryptoConnections) => {
    const params = getAddEthereumChainPayload(con);

    if (params) {
      try {
        await ethereum?.request({
          method: 'wallet_addEthereumChain',
          params,
        });
      } catch (error) {
        console.log('Error adding Ethereum Chain=> ', error);
      }
    }
  };

  const initialize = async () => {
    const provider = await detectEthereumProvider();

    if (!provider) {
      return install();
    }

    if (isMetaMaskInstalled()) {
      return connect();
    }

    install();
  };

  const networkMap = {
    1: 'Ethereum',
    56: 'Binance Smart Chain',
  };

  const networkName = chainId ? networkMap[chainId as keyof typeof networkMap] : '';

  return { isMetaMaskInstalled, initialize, install, accounts, chainId, networkName, switchNetwork };
};

export default useMetamask;
