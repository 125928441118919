import { EDiscoverAction } from './discover.enum';
import { IDiscoverAction, IDiscoverPagination, IDiscoverState, IInvestmentOpportunity } from './discover.types';

export const setProcessing = (): IDiscoverAction => ({
  type: EDiscoverAction.SET_PROCESSING,
});

export const resetProcessing = (): IDiscoverAction => ({
  type: EDiscoverAction.RESET_PROCESSING,
});

export const setOpportunities = (opportunities: IInvestmentOpportunity[]): IDiscoverAction => ({
  type: EDiscoverAction.SET_OPPORTUNITIES,
  payload: {
    opportunities,
  },
});

export const setPagination = (discoverPagination: IDiscoverPagination): IDiscoverAction => ({
  type: EDiscoverAction.SET_PAGINATION,
  payload: {
    pagination: discoverPagination,
  },
});

export const fetchMoreData = (): IDiscoverAction => ({
  type: EDiscoverAction.FETCH_MORE_DATA,
  payload: {
    loadMore: true,
  },
});

export const setSearch = (term: string): IDiscoverAction => ({
  type: EDiscoverAction.SET_SEARCH,
  payload: {
    search: term,
  },
});

export const updateDiscoverStateValues = (values: Partial<IDiscoverState>) => ({
  type: EDiscoverAction.PARTIAL_UPDATE,
  payload: {
    values,
  },
});
