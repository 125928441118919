import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { logout } from 'auth/auth.actions';
import usePeerboard from 'community/usePeerboard';
import { useAuthDispatch } from 'auth/auth.context';
import { appRouteConstants } from 'app/app-route.constant';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as Upgrade } from 'assets/icons/upgrade.svg';
import { ReactComponent as Profile } from 'assets/icons/profile.svg';
import { ReactComponent as Support } from 'assets/icons/support.svg';
import { ReactComponent as Settings } from 'assets/icons/settings.svg';
import useCurrentSubscription from 'auth/hooks/useCurrentSubscription';
import { ReactComponent as ResourceCenter } from 'assets/icons/resource-center.svg';
import { ReactComponent as ManageConnection } from 'assets/icons/manage-connection.svg';

interface NetworthSidebarProps {
  openLeft: boolean;
  openRight: boolean;
}

const AppSidebar: React.FC<NetworthSidebarProps> = ({ openLeft, openRight }) => {
  const dispatch = useAuthDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const sidebarClass = (label: string) => (pathname.includes(label) ? 'mm-sidebar-item active' : 'mm-sidebar-item');
  const { createPeerboardAnonymously } = usePeerboard();
  const { currentSubscription } = useCurrentSubscription();

  const handleLogout = () => {
    createPeerboardAnonymously();
    dispatch(logout());

    return history.push({
      pathname: appRouteConstants.auth.LOGIN,
      search: 'action=logout',
    });
  };

  return (
    <>
      <aside
        className={openRight ? 'profilemenu open-slidebar' : 'profilemenu'}
        style={{ right: openRight ? 0 : -300 }}
      >
        {currentSubscription?.subscriptionStatus !== 'active' ? (
          <>
            <ul className='prlist-up mb-0 mt-2'>
              <li>
                <Link to='/settings?active=Plan'>
                  <Upgrade />
                  <i className='icon-upgrade' /> Upgrade
                </Link>
              </li>
            </ul>
            <hr className='sidebar-custom-hr' />
          </>
        ) : null}

        <ul className='prlist-pro mb-0'>
          <li>
            <Link to='/settings?active=Settings'>
              <Settings />
              <i className='icon-settings' />
              Settings
            </Link>
          </li>
          <li>
            <Link to='/settings?active=Profile'>
              <Profile />
              <i className='icon-profile' />
              Profile
            </Link>
          </li>
          <li>
            <Link to='/settings?active=Accounts'>
              <ManageConnection />
              <i className='icon-manage-connections' /> Manage Accounts
            </Link>
          </li>
          <li>
            <a href='mailto:hello@moneyminx.com?subject=Money%20Minx%20Help'>
              <Support />
              <i className='icon-support' />
              Support
            </a>
          </li>
          <li>
            <a target='_blank' href='https://www.moneyminx.com/blog' rel='noopener noreferrer'>
              <ResourceCenter />
              <i className='icon-resource-center' />
              Blog
            </a>
          </li>
        </ul>
        <hr className='sidebar-custom-hr' />
        <ul className='prlist-log mb-0'>
          <li>
            <Link to='#' onClick={handleLogout}>
              <Logout />
              <i className='icon-logout' /> Logout
            </Link>
          </li>
        </ul>
      </aside>
      <aside className='mobmenu collapse' id='headerMenu' style={{ left: openLeft ? 0 : -300 }}>
        <div className='headtab'>
          <Link to={appRouteConstants.dashboard.DASHBOARD} className={sidebarClass('dashboard')}>
            Dashboard
          </Link>
          <Link to='/net-worth' className={sidebarClass('net-worth')}>
            Net Worth
          </Link>
          <Link to='/allocation' className={sidebarClass('allocation')}>
            Allocation
          </Link>
          <Link to='/performance' className={sidebarClass('performance')}>
            Performance
          </Link>
          <Link to='/income' className={sidebarClass('income')}>
            Income
          </Link>
          <Link to='/discover' className={sidebarClass('discover')}>
            Discover
          </Link>
        </div>
      </aside>
    </>
  );
};

export default AppSidebar;
