import React, { useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import Dropdown from 'react-bootstrap/esm/Dropdown';

import { enumerateStr } from 'common/common-helper';
import { getMonthYear } from 'common/moment.helper';
import { TimeIntervalEnum } from 'networth/networth.enum';
import { IAccountFilter } from 'account/hooks/useAccountDetail';

interface IAccountDetailFilter {
  accountFilter?: IAccountFilter;
  clearFilters: () => void;
  clickElement: (arg: any) => void;
  onChange: (option: string, date: any) => void;
  handleIntervalChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AccountDetailFilter: React.FC<IAccountDetailFilter> = ({
  onChange,
  clearFilters,
  clickElement,
  accountFilter,
  handleIntervalChange,
}) => {
  const dropdownToggle = useRef(null);
  const [intervalChangeCounter, setIntervalChangeCounter] = useState(0);

  const dateToFilterOn = !!accountFilter?.toDate;
  const dateFromFilterOn = !!accountFilter?.fromDate;
  const intervalFilterOn = accountFilter?.timeInterval && intervalChangeCounter;

  const hasFilter = accountFilter
    ? Object.keys(accountFilter)
        .filter((key) => {
          const isInterval = 'timeInterval' === key;
          const value = accountFilter[key as keyof IAccountFilter];

          if (isInterval && intervalChangeCounter) {
            return true;
          }

          if (value && !isInterval) {
            return true;
          }

          return false;
        })
        .some(Boolean)
    : false;

  const handleClearFilter = () => {
    setIntervalChangeCounter(0);
    clearFilters();
  };

  return (
    <div className='dflex-center'>
      {hasFilter ? (
        <button type='button' className='btn btn-outline-danger clear-filter' onClick={handleClearFilter}>
          Clear Filters
        </button>
      ) : null}

      <ReactDatePicker
        selected={accountFilter?.fromDate ? new Date(accountFilter.fromDate) : null}
        onChange={(date) => onChange('start', date)}
        // selectsStart
        startDate={accountFilter?.fromDate ? new Date(accountFilter.fromDate) : null}
        dateFormat='MM/yyyy'
        showMonthYearPicker
        minDate={new Date('1900-01-01')}
        maxDate={new Date()}
        // selectsRange
        customInput={
          <div className='drop-box'>
            <div className='date-box'>
              <input
                type='text'
                className={['month_year', dateFromFilterOn ? 'active' : ''].join(' ')}
                value={accountFilter?.fromDate ? getMonthYear(accountFilter.fromDate) : getMonthYear(new Date())}
                readOnly
              />
            </div>
          </div>
        }
      />
      <span className={['date-separator', dateFromFilterOn && dateToFilterOn ? 'active' : ''].join(' ')}>to</span>
      <ReactDatePicker
        selected={accountFilter?.toDate ? new Date(accountFilter.toDate) : null}
        onChange={(date) => onChange('end', date)}
        // selectsStart
        startDate={accountFilter?.toDate ? new Date(accountFilter.toDate) : null}
        dateFormat='MM/yyyy'
        showMonthYearPicker
        minDate={accountFilter?.fromDate ? new Date(accountFilter.fromDate) : null}
        maxDate={new Date()}
        // selectsRange
        customInput={
          <div className='drop-box'>
            <div className='date-box'>
              <input
                type='text'
                className={['month_year', dateToFilterOn ? 'active' : ''].join(' ')}
                value={getMonthYear(accountFilter?.toDate)}
                readOnly
              />
            </div>
          </div>
        }
      />
      <Dropdown className={['drop-box m-l-2', intervalFilterOn ? 'active' : ''].join(' ')}>
        <Dropdown.Toggle variant='' ref={dropdownToggle}>
          {accountFilter?.timeInterval}
        </Dropdown.Toggle>
        <Dropdown.Menu className='mm-dropdown-menu dropsm'>
          <ul className='radiolist'>
            {enumerateStr(TimeIntervalEnum).map((interval, index) => {
              return (
                <li key={index}>
                  <label>
                    <input
                      type='radio'
                      name='m-list'
                      aria-checked={accountFilter?.timeInterval === interval}
                      value={interval}
                      checked={accountFilter?.timeInterval === interval}
                      onChange={(e) => {
                        setIntervalChangeCounter((c) => c + 1);
                        handleIntervalChange(e);
                      }}
                      onClick={() => {
                        clickElement(dropdownToggle);
                      }}
                    />
                    <span>{interval}</span>
                  </label>
                </li>
              );
            })}
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default AccountDetailFilter;
