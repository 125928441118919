import React, { createContext, useContext, useReducer } from 'react';

import { CurrencyOptions } from 'auth/enum/currency-options';

import { EIncomeAction } from './income.enum';
import { IIncomeAction, IIncomeDispatch, IIncomeProviderProps, IIncomeState } from './income.types';

const initialState: IIncomeState = {
  processing: false,
  currency: CurrencyOptions.USD,
  accounts: [],
  totals: [],
};

const IncomeStateContext = createContext<IIncomeState | undefined>(undefined);
const IncomeDispatchContext = createContext<IIncomeDispatch | undefined>(undefined);

function incomeReducer(state: IIncomeState, action: IIncomeAction): IIncomeState {
  switch (action.type) {
    case EIncomeAction.SET_PROCESSING:
      return { ...state, processing: true };

    case EIncomeAction.RESET_PROCESSING:
      return { ...state, processing: false };

    case EIncomeAction.SET_CURRENCY:
      return { ...state, currency: action.payload?.currency };

    case EIncomeAction.SET_ACCOUNTS:
      return { ...state, accounts: action.payload?.accounts };

    case EIncomeAction.SET_TOTALS:
      return { ...state, totals: action.payload?.totals };

    default:
      throw new Error('Unhandled action type in income reducer');
  }
}

const IncomeProvider: React.FC<IIncomeProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(incomeReducer, initialState);

  return (
    <IncomeStateContext.Provider value={state}>
      <IncomeDispatchContext.Provider value={dispatch}>{children}</IncomeDispatchContext.Provider>
    </IncomeStateContext.Provider>
  );
};

function useIncomeState() {
  const context = useContext(IncomeStateContext);

  if (context === undefined) {
    throw new Error('useIncomeState must be used within a IncomeProvider');
  }

  return context;
}

function useIncomeDispatch() {
  const context = useContext(IncomeDispatchContext);

  if (context === undefined) {
    throw new Error('useIncomeDispatch must be used within a IncomeProvider');
  }

  return context;
}

export { IncomeProvider, useIncomeState, useIncomeDispatch };
