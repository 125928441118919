import { IFormFieldItem } from 'account/account.type';
import { camelCase } from 'common/helper/string.helper';

import sideCardFields from '../data/side-card-fields.json';

interface ISideCardField {
  realEstate: IFormFieldItem;
  businesses: IFormFieldItem;
  mortgages: IFormFieldItem;
}

type TSideCardFieldKey = keyof ISideCardField;

const getAccountSideCardFields = (key: string) => {
  const sideCardKey = camelCase(key) as TSideCardFieldKey;

  return sideCardFields[sideCardKey] || [];
};

const useAccountSideCard = (type: string) => {
  const formFields = getAccountSideCardFields(type);

  const hasSideCard = ['realEstate', 'businesses', 'mortgages'].includes(camelCase(type));

  return { formFields, hasSideCard };
};

export default useAccountSideCard;
