import React from 'react';

import { Modal, ModalType } from 'common/components/modal';

interface ISaveEditModal {
  title: string;
  saveEditViewModal: ModalType;
}

const SaveEditViewModal: React.FC<ISaveEditModal> = ({ children, title, saveEditViewModal }) => {
  return (
    <Modal {...saveEditViewModal.props} title={title} canBeClosed>
      <div className='modal-wrapper signup-modal'>{children}</div>
    </Modal>
  );
};

export default SaveEditViewModal;
