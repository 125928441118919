import React from 'react';

import CircularSpinner from 'common/components/spinner/circular-spinner';

const withLoading = (loading: boolean, component: React.ReactNode) => {
  if (loading) {
    return <CircularSpinner />;
  }

  return component;
};

export default withLoading;
