import React from 'react';

import { FilterProvider } from 'filter/filter.context';
import AuthorizedProvider from 'provider/authorized.provider';

import Networth from './views/networth';
import { NetworthProvider } from './networth.context';

const NetworthRoute = () => {
  return (
    <AuthorizedProvider>
      <FilterProvider>
        <NetworthProvider>
          <Networth />
        </NetworthProvider>
      </FilterProvider>
    </AuthorizedProvider>
  );
};

export default NetworthRoute;
