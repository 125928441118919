import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import useDiscover from '../hooks/useDiscover';
import DiscoverTopSection from '../components/discover-top-section';
import DiscoverMainSection from '../components/discover-main-section';

const Discover: React.FC = () => {
  useDiscover();

  const renderDiscover = () => {
    return (
      <div className='content-wrapper'>
        <div className='container discover'>
          <DiscoverTopSection />
          <DiscoverMainSection />
        </div>
      </div>
    );
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Discover | Money Minx</title>
          <meta name='description' content='Discover, discuss and profit from new investment opportunities.' />
          {/*Facebook tags*/}
          <meta property='og:title' content='Discover Investment Opportunities | Money Minx' />
          <meta property='og:description' content='Discover, discuss and profit from your next investment opportunity.' />
          <meta property='og:url' content='https://www.moneyminx.com/discover' />
          <meta property='og:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Discover.png' />
          <meta property='og:image:secure_url' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Discover.png' />
          <meta property='og:image:alt' content='Discover, discuss and profit from new investment opportunities.' />
          {/*Twitter tags*/}
          <meta property='twitter:image' content='https://moneyminx-files.s3.amazonaws.com/assets/Money_Minx-Social-Sharing-Discover.png' />
          <meta
            property='twitter:image:alt'
            content='Discover, discuss and profit from new investment opportunities.'
          />
        </Helmet>
      </HelmetProvider>

      {renderDiscover()}
    </>
  );
};

export default Discover;
